import { MultiMaterial } from "@babylonjs/core/Materials/multiMaterial";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { ColorGradingTexture } from "@babylonjs/core/Materials/Textures/colorGradingTexture";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Quaternion } from "@babylonjs/core/Maths/math";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Node } from "@babylonjs/core/node";

/**
 * This is a base class for extending new Babylon Mesh classes off of our 3d card model
 *
 * baseCardMesh and inactiveColorGradingTexture are both set by the Card system after loading the assets
 */
export class BaseCardMesh extends Mesh {
  static baseCardMesh: Mesh = null;
  static inactiveColorGradingTexture: ColorGradingTexture = null;

  constructor(name: string, parent: Node) {
    // Clone the baseCardMesh into our new CardMesh
    super(name, parent.getScene(), parent, BaseCardMesh.baseCardMesh);

    // Initialize the rotationQuaternion since it defaults to uninitialized
    this.rotationQuaternion = new Quaternion();

    let meshMaterial = this.material.clone("card_multimaterial") as MultiMaterial;
    this.material = meshMaterial;

    let meshSubMaterial0 = meshMaterial.subMaterials[0].clone("card_front_" + name) as StandardMaterial;
    meshMaterial.subMaterials[0] = meshSubMaterial0;
    meshSubMaterial0.imageProcessingConfiguration = meshSubMaterial0.imageProcessingConfiguration.clone();

    // We have to set the cameraColorGradingTexture AFTER cloning the cardMaterial
    // Otherwise, Babylon JS will load and parse the file for all 52 cards, causing a massive stall
    meshSubMaterial0.cameraColorGradingTexture = BaseCardMesh.inactiveColorGradingTexture;
  }

  getFaceMaterial(): StandardMaterial {
    let meshMaterial = this.material as MultiMaterial;
    let faceMaterial = meshMaterial.subMaterials[0] as StandardMaterial;
    return faceMaterial;
  }

  setCustomFaceTexture(texture: Texture) {
    let faceMaterial = this.getFaceMaterial();
    faceMaterial.diffuseTexture = texture;
  }
}
