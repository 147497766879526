import { ILoadingScreen } from "@babylonjs/core/Loading/loadingScreen";

export class LoadingScreen implements ILoadingScreen {
  //optional, but needed due to interface definitions
  public loadingUIBackgroundColor: string;
  constructor(public loadingUIText: string) {
  }

  public displayLoadingUI() {
    // what do we want to do for a custom loading screen?
    // on facebook instant games we shouldn't do anything because facebook has their own loading screen
  }

  public hideLoadingUI() {
  }
}
