import { Matrix } from "@babylonjs/core/Maths/math";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";

import * as Mustache from "mustache";

import { config } from "utils/Config";

import { ArgoSystem } from "components/game/ArgoSystem";
import { game } from "components/game/Game";
import { Commands } from "components/ui/Commands";
import { PlayerGUIObject, SeatColor } from "components/ui/PlayerGUIObject";
import { PlayerInfoPopUp } from "components/ui/PlayerInfoPopUp";
import { disposeGuiControl, disposeToast, findGuiControl, getControlGlobalPosition, toast } from "components/utils/GUI";
import { GAME_STATE_PASS } from "states/game/GameState";
import { ITrickGameState } from "states/game/TrickGameState";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";

import bidMetUrl from "components/game/player-meter/bid-met.png";
//import bidNilFailedUrl from "components/game/player-meter/bid-nil-failed.png";
//import bidWonPartnerUrl from "components/game/player-meter/bid-won-partner.png";
import bidWonPartnerUrl from "components/game/player-meter/bid-won-partner-p.png";
import bidWonUrl from "components/game/player-meter/bid-won.png";
import bidUrl from "components/game/player-meter/bid.png";
import { PLAYER_STATE_LEFT, PLAYER_STATE_PLAYER, PLAYER_STATE_SUB } from "states/game/PlayerState";
import { ISeatState } from "states/game/SeatState";

export class PlayerSystem extends ArgoSystem {
  players: PlayerGUIObject[] = [];
  playerLayer: Container;

  bidBox = false;
  bidBoxAnimatePlayer = false;
  menuSeatId: string; // id of the seat they clicked on to popup menu
  waitingForLoadingScreen = false;

  bidImage: HTMLImageElement = null;
  bidMetImage: HTMLImageElement = null;
  bidWonImage: HTMLImageElement = null;
  bidWonPartnerImage: HTMLImageElement = null;
  //bidNilFailedImage: HTMLImageElement = null;

  // Cache setNameAndImageFromSeat so we can fill in name and imageUrl while waiting for the next game to start
  nameAndImageCache: {[key: string]: {name: string, imageUrl: string}} = {};

  init() {
    // Listen for show game events
    this.game.onShowGameObservable.add((show) => this.onShowGame(show));

    // Listen for the loading screen finishing
    this.waitingForLoadingScreen = true;
    this.game.onLoadingScreenFinishedObservable.add(() => this.onLoadingScreenFinished());
  }

  queueAssets(): void {
    // Bid Images
    this.game.assetsManager.addImageTask("bid_image_task", bidUrl).onSuccess = (task) => {
      this.bidImage = task.image;
    };

    this.game.assetsManager.addImageTask("bid_met_image_task", bidMetUrl).onSuccess = (task) => {
      this.bidMetImage = task.image;
    };

    this.game.assetsManager.addImageTask("bid_won_image_task", bidWonUrl).onSuccess = (task) => {
      this.bidWonImage = task.image;
    };

    this.game.assetsManager.addImageTask("bid_won_partner_image_task", bidWonPartnerUrl).onSuccess = (task) => {
      this.bidWonPartnerImage = task.image;
    };

    /*
    this.game.assetsManager.addImageTask("bid_nil_failed_image_task", bidNilFailedUrl).onSuccess = (task) => {
      this.bidNilFailedImage = task.image;
    };
    */
  }

  createGUI() {
    this.create();

    // update when anything about seats, players, or tricks change, notice no trailing $
    this.rootState.router.addRoute("^\/game\/seats\/", (patch: any, reversePatch: any, params: any) => this.updatePlayers());
    this.rootState.router.addRoute("^\/game\/seats\/(\\d*)\/player", (patch: any, reversePatch: any, params: any) => this.onSeatPlayerChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/seats\/(\\d*)\/roundScore", (patch: any, reversePatch: any, params: any) => this.onSeatRoundScoreChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/teams\/(\\d*)\/score", (patch: any, reversePatch: any, params: any) => this.onTeamScoreChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/players\/", (patch: any, reversePatch: any, params: any) => this.onPlayerChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/players\/(\\d*)/status$", (patch: any, reversePatch: any, params: any) => this.onPlayersStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/seatsTurn$", (patch: any, reversePatch: any, params: any) => this.updatePlayers());
    this.rootState.router.addRoute("^\/applySnapshotStage$", (patch: any, reversePatch: any, params: any) => this.onApplySnapshotStage(patch, reversePatch, params));

    this.rootState.router.addRoute("^\/game\/id$", (patch: any, reversePatch: any, params: any) => this.onGameIdChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/tricks\/", (patch: any, reversePatch: any, params: any) => this.updateWonTricks(patch, reversePatch, params));

    this.rootState.router.addRoute("^\/user\/bidDisplayMode$", (patch: any, reversePatch: any, params: any) => this.onUserBidDisplayMode());
  }

  create() {
    this.playerLayer = new Container("PlayerSystemPlayerLayer");
    this.playerLayer.isHitTestVisible = false;
    this.game.guiTexture.addControl(this.playerLayer);

    for(let seat = 0; seat < 4; seat++) {
      let player = new PlayerGUIObject(this, "player" + seat, seat, this.getSeatColor(String(seat)));
      this.playerLayer.addControl(player);
      player.setBidDisplayMode(this.rootState.user.bidDisplayMode);

      // add a bot when player image is clicked (GameState checks to make sure there isn't already a player in that seat)
      player.onPointerClickObservable.add(() => this.onPlayerClick(seat));

      player.bidDisplayClickObservable.add(() => this.toggleBidDisplayMode());
      player.switchSeatClickObservable.add(() => {
        // Use clicked switch seat icon, confirm that is what they really wanted to do.
        game.modalDialogSystem.showOkCancel("Switch Seat", "Are you sure you want to switch to this seat?", "Switch Seats", "Stay").then( (response) => {
          if(response.button === "ok")
            this.game.gameState.requestSeat(this.rootState.user.id, "" + seat);
        });
      });

      this.players.push(player);
    }

    this.layout();

    // Hide until the loading screen finishes
    this.playerLayer.isVisible = false;
  }

  layout() {
    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiWidth = this.game.guiTexture.getSize().width;
    let guiHeight = this.game.guiTexture.getSize().height - menuBarHeight;

    let k = 6; // "k" is basically how many player boxes should fit vertically on the screen, so bigger k for smaller boxes
    let height = Math.min(guiWidth / k / 2, guiHeight / k);

    for(let player of this.players)
      player.setBaseHeight(height);

    this.positionPlayers();
  }

  getPlayer(seat: number | string | ISeatState) {
    let index = 0;

    if(typeof seat === "number")
      index = seat;
    else if(typeof seat === "string")
      index = parseInt(seat);
    else
      index = parseInt(seat.id);

    return this.playerLayer.children[index] as PlayerGUIObject;
  }

  getPlayerList() {
    return this.playerLayer.children as PlayerGUIObject[];
  }

  /** returns a SeatColor for the seat. If this is a partner game, return red for seat 0 and 2, and blue for seat 1 and 3. If individual all seats are red */
  getSeatColor(seatId: string) {
    let seatColor = SeatColor.Red;
    let team = game.gameState.getTeamForSeat(seatId);
    // if there is more then 1 seat per team, then use different colors per team, ie partners is red and blue, individuals are all red
    if(team && game.gameState.teams.length < game.gameState.seats.length) {
      if(team.id === "0")
        seatColor = SeatColor.Red;
      else
        seatColor = SeatColor.Blue;
    }
    return seatColor;
  }

  positionPlayers() {
    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiWidth = this.game.guiTexture.getSize().width;
    let guiHeight = this.game.guiTexture.getSize().height;

    let guiMidX = guiWidth * 0.5;
    let guiMidY = guiHeight * 0.5;

    // Try to guess the top of the south player's hand
    let hand = this.game.findPile("hand" + this.game.getSouthSeat());
    if(!hand)
      return;
    hand.computeWorldMatrix();
    let cardTop = Vector3.TransformCoordinates(new Vector3(0, 0.64, 0), hand.getWorldMatrix());
    let handPosition = this.game.project(cardTop);
    let handTop = handPosition.y - menuBarHeight;

    // Can the south player fit next to the hand?
    let testLayout = this.game.pileSystem.getFanLayoutForPiece(0, 13, 13);
    let testMatrix = Matrix.RotationZ(testLayout.angle * Math.PI / 180.0);
    let testCorner = Vector3.TransformCoordinates(new Vector3(-0.5, 0.64, 0), testMatrix);
    let testPosition = Vector3.TransformCoordinates(testLayout.position.add(testCorner), hand.getWorldMatrix());
    let testProjection = this.game.project(testPosition);
    let canFitPlayer0 = (this.players[0].widthInPixels < testProjection.x);

    let offset = parseInt(this.game.getSouthSeat());

    for(let player of this.players) {
      let index = parseInt(player.name.slice(-1));

      let position = (index + 4 - offset) % 4;

      let x = 0;
      let y = 0;

      let southY = canFitPlayer0 ? guiHeight - menuBarHeight - player.heightInPixels : handTop - player.textOffset;
      let eastY = Math.min(guiMidY - player.imageOffset - player.imageWidth, southY - player.heightInPixels);

      switch(position){
        case 0:
          x = 0;
          y = southY;
          break;

        case 1:
          x = 0;
          y = eastY;
          break;

        case 2:
          x = guiMidX - player.widthInPixels * 0.5;
          y = 0;
          break;

        case 3:
          x = guiWidth - player.widthInPixels;
          y = guiMidY - player.imageOffset - player.imageWidth;
          break;
      }

      player.left = x;
      player.top = y;

      if(position === 2) {
        // It's our turn to bid, so the bid box is covering the north player, let's scoot them over instead
        let bidBox = findGuiControl("BidBox");
        if(bidBox !== null) {
          let x1 = guiWidth * 0.5 - bidBox.widthInPixels * 0.5 - player.widthInPixels;

          if(this.bidBoxAnimatePlayer) {
            this.bidBoxAnimatePlayer = false;

            if(this.bidBox)
              player.aniMove(x, y, x1, y, 1.0);
            else
              player.aniMove(x1, y, x, y, 1.0);
          } else if(this.bidBox) {
            player.left = x1;
          }
        }
      }
    }
  }

  updatePlayers(animate = true) {
    let turnId = this.game.gameState.seatsTurn ? this.game.gameState.seatsTurn.id : "-1";
    let turnIndex = parseInt(turnId);

    for(let team of this.game.gameState.teams) {
      for(let seatId of team.seats) {
        let seat = this.game.gameState.getSeat(seatId);
        let index = parseInt(seat.id);

        // Set the player text
        let player = this.players[index];
        player.setNameAndImageFromSeat(seat);
        this.updateSeatScore(seat.id);

        // darken name background when it's the users turn, and if they haven't passed yet in hearts
        player.setTurn(index === turnIndex || (this.game.gameState.status === GAME_STATE_PASS && !seat.ready));

        // Update image background - we'll double the thickness if it's the player's turn
        let imagebg: Rectangle = findGuiControl(player.name + "ImageBox", player) as Rectangle;

        let thickness = player.teamThickness;
        let width = player.imageWidth - thickness * 2;
        let pos = player.imageTop - thickness;

        imagebg.top = pos;
        imagebg.width = width + "px";
        imagebg.height = width + "px";
        imagebg.thickness = thickness;

        // Get list of tricks
        let tricks = (this.game.gameState as ITrickGameState).tricks;

        // See if any tricks had been won
        let tricksWon = (tricks.length > 0 && tricks[0].seatWinner != null);

        // don't animate if tricks have been won
        if(tricksWon)
          animate = false;

        // Update Bid, animate only if no tricks have been won
        player.setBid(seat.bid, animate);
      }
    }
  }

  onApplySnapshotStage(patch: any, reversePatch: any, params: any) {
    if(patch.value !== APPLY_SNAPSHOT_STAGE_DONE)
      return;

    disposeToast("PlayerStatusToast"); // clear any toasts from a previous load, such as the Joining Game...
    this.updatePlayers();
  }

  // The game id being set seems to be the most reliable indicator that it's time to animate in the players
  onGameIdChanged(patch: any, reversePatch: any, params: any) {
    if(patch.value) {
      this.layout();
      this.animatePlayersIn();
    }
  }

  onSeatPlayerChanged(patch: any, reversePatch: any, params: any) {
    let seatIndex = params[1];
    let seat = this.game.gameState.seats[seatIndex];

    // when a player takes a seat dismiss playerMenuPopUp for that seat
    if(seat)
      disposeGuiControl("playerMenuPopUpSeat" + seat.id);

    this.layout();
  }

  onSeatRoundScoreChanged(patch: any, reversePatch: any, params: any) {
    let seatIndex = params[1];
    this.updateSeatScore(seatIndex);
  }

  onTeamScoreChanged(patch: any, reversePatch: any, params: any) {
    let teamIndex = params[1];
    let team = this.game.gameState.teams[teamIndex];

    team.seats.forEach((seatId) => {
      this.updateSeatScore(seatId);
    });
  }

  /** updates the score text for a seat on the player gui object if config.playerScoreTemplate is set */
  updateSeatScore(seatId: string) {
    if(!config.playerScoreTemplate)
      return;

    let seat = this.game.gameState.getSeat(seatId);

    // whenever score changes update score underneath player picture
    if(seat) {
      let player = this.players[Number(seatId)];
      let team = game.gameState.getTeamForSeat(seat.id);

      let params = {
        seatRoundScore: seat.roundScore,
        teamScore: team.score,
      };

      let text = Mustache.render(config.playerScoreTemplate, params);
      player.setScoreText(text);
    }
  }

  onPlayerChanged(patch: any, reversePatch: any, params: any) {
    // If we're joining an offline game, it might be awhile before the game is synced, so put up a Joining Game... toast
    if(patch.op === "add") {
      const player = patch.value;
      let seat = this.game.gameState.getPlayerSeat(player.id);
      if(player.id === this.rootState.user.id && this.game.gameState.offline && !seat)
        toast("PlayerStatusToast", `Joining Game...`, 0, -1, false); // make it sticky, we don't need to worry about disposing it, because the game will be reloaded to officially join
    }

    this.updatePlayers();
  }

  onPlayersStatusChanged(patch: any, reversePatch: any, params: any) {
    let playerIndex = params[1];
    if(playerIndex < this.game.gameState.players.length) {
      let player = this.game.gameState.players[playerIndex];
      if(player && !player.bot)
      {
        if(player.id === this.rootState.user.id && player.status === PLAYER_STATE_SUB) {
          toast("PlayerStatusToast", `You are joining mid game, the results will not affect you.`);
        }
        else {
          if(player.status === PLAYER_STATE_LEFT)
            toast("PlayerStatusToast", `${player.name} left the game.`);
          else if(player.status === PLAYER_STATE_PLAYER || player.status === PLAYER_STATE_SUB)
            toast("PlayerStatusToast", `${player.name} joined the game.`);
        }
      }
    }
  }

  updateWonTricks(patch: any, reversePatch: any, params: any) {
    if(patch.op === "replace" && patch.path.endsWith("/seatWinner") && patch.value !== null) {
      let index = parseInt(patch.value);
      let player = this.players[index];
      player.onTrickWon();
    }

    for(let team of this.game.gameState.teams) {
      for(let seatId of team.seats) {
        let wonTricks = this.game.gameState.getWonTricksForSeat(seatId);
        let index = parseInt(seatId);
        let player = this.players[index];
        if(wonTricks)
          player.setWon(wonTricks, -1, true);
      }
    }
  }

  showPlayerInfo(seatId: string) {
    let seat = this.game.gameState.getSeat(seatId);
    if(seat && seat.player !== null) {
      let popUp = new PlayerInfoPopUp(seat.player.id, seat.player.bot);
      let index = parseInt(seat.id);
      let player = this.players[index];
      let globalPos = getControlGlobalPosition(player);
      popUp.left = globalPos.x + player.imageWidth;
      popUp.top = globalPos.y + player.heightInPixels * 0.5 - popUp.heightInPixels * 0.5;
      // We need to manually set the bot's imageUrl
      if(seat.player.bot && player.image.domImage)
        popUp.setImage(player.image.domImage.src);
    }
  }

  animatePlayersIn() {
    for(let player of this.players)
      player.animateIn();
  }

  onPlayerClick(seatNumber: number) {
    let seatId = seatNumber.toString();

    let seat = this.game.gameState.getSeat(seatId);
    if(seat && seat.player !== null) {
      // This seat already has a player
      this.showPlayerInfo(seatId);
      return;
    }
    this.menuSeatId = seatId;
    Commands.onInvite({srcName: "empty_seat", seatId: this.menuSeatId});
  }

  onUserBidDisplayMode() {
    for(let player of this.players) {
      player.setBidDisplayMode(this.rootState.user.bidDisplayMode);
    }
  }

  toggleBidDisplayMode() {
    this.rootState.user.toggleBidDisplayMode(this.rootState.user.id);
  }

  onShowGame(show: boolean) {
    // Don't show if we're still waiting on the loadingScreen
    if(show && this.waitingForLoadingScreen)
      return;

    if(this.playerLayer) {
      this.playerLayer.isVisible = show;

      if(show === true) {
        this.layout();
        this.animatePlayersIn();
      }

      // For the invite button, we want to call show to animate when shown
      // But in this case, we want to hide it immediately by setting isVisible instead of calling hide()
      for(let player of this.players) {
        if(player.inviteButton) {
          if(show)
            player.inviteButton.show();
          else
            player.inviteButton.isVisible = false;
        }
      }
    }
  }

  onLoadingScreenFinished() {
    // We've been ignoring onShowGame(true) waiting on the loadingScreen
    // So call it now if the game is showing
    this.waitingForLoadingScreen = false;
    if(this.game.showingGame)
      this.onShowGame(true);
  }

  onBidBox(bidBox: boolean) {
    this.bidBox = bidBox;
    this.bidBoxAnimatePlayer = true;
    this.positionPlayers();
  }

  afterResized(): void {
    this.layout();
  }
}
