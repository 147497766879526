import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { ElasticEase } from "@babylonjs/core/Animations/easing";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Axis } from "@babylonjs/core/Maths/math";
import { Quaternion } from "@babylonjs/core/Maths/math";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { VertexData } from "@babylonjs/core/Meshes/mesh.vertexData";
import { Tools } from "@babylonjs/core/Misc/tools";
import { Node } from "@babylonjs/core/node";

import { ArgoSystem } from "components/game/ArgoSystem";
import { IPileState } from "states/game/PileState";
import { config } from "utils/Config";

// pile.metadata.sort can also be a compare function taking two values
export const PILE_SORT_NONE = "none";
export const PILE_SORT_VALUE = "value";

import pileMarkerPath from "components/game/pile/marker.svg";

interface ILayoutReturn {position: Vector3; angle: number; }

export class Pile extends ArgoSystem  {
  pileMarkerTexture: Texture;
  selectedPile: AbstractMesh = null;

  init() {
  }

  queueAssets(): void {
    if(config.pileHasMarkerTexture) {
      // Queue the pile marker texture
      let pileMarkerTextureTask = this.game.assetsManager.addTextureTask("PileMarkerTextureTask", pileMarkerPath);
      pileMarkerTextureTask.onSuccess = (task) => {
        this.pileMarkerTexture = task.texture;
        this.pileMarkerTexture.hasAlpha = true;
      };
    }
  }

  createMaterials(): void {
    let pileMaterial = new StandardMaterial("pile", this.game.scene);
    if(this.pileMarkerTexture)
      pileMaterial.diffuseTexture = this.pileMarkerTexture;
  }

  /** creates an empty pile mesh (no visible geometry). pileState is optional because we need to create the drag pile which is not in the GameState.
   *  So instead pass name in params, ie createMesh(this.scene, "down", undefined, "drag")
   */
  createEmptyMesh(parent: Node, layout: string, pileState?: IPileState, ...params: string[]): Mesh  {
    let name = ((pileState) ? pileState.name : params[0]); // if there is no pileState, then first params must be name
    let mesh = new Mesh(name, parent.getScene(), parent);
    mesh.metadata = {
      type: "Pile",
      layout: layout,
      maxHeight: 1.28,
      needsLayout: false,
      sort: PILE_SORT_NONE,   // sort of cards in pile, for trickgames
      visibility: 1,
    };

    // rotate to layflat on ground (x-z plane)
    mesh.rotate(Axis.X, Math.PI / 2); // Math.PI/2 = 90 degrees

    // position above ground a little bit
    // Although this is no longer needed to prevent z-fighting with the ground since it's invisible
    // it is still needed so hit testing can reliably tell the pile and the ground apart
    mesh.position.y = 0.01;

    // We don't really want piles to cast shadows
    // But by adding them, the shadow volume stays consistent
    // instead of shifting when cards are moved to previously empty piles
    this.game.shadowGenerator.addShadowCaster(mesh);

    return mesh;
  }

  /** creates a pile mesh. pileState is optional because we need to create the drag pile which is not in the GameState.
   *  So instead pass name in params, ie createMesh(this.scene, "down", undefined, "drag")
   */
  createMesh(parent: Node, layout: string, pileState?: IPileState, ...params: string[]): Mesh  {
    // Create an empty mesh
    let mesh = this.createEmptyMesh(parent, layout, pileState, ...params);

    // Scale the pile mesh down slightly so it is not visible behind the cards
    let scale = 0.9;
    let pileWidth = 1 * scale;
    let pileHeight = 1.28 * scale;

    // create plane
    let options = { width: pileWidth, height: pileHeight, sideOrientation: Mesh.DOUBLESIDE, updatable: false };
    let vertexData = VertexData.CreatePlane(options);
    vertexData.applyToMesh(mesh, options.updatable);

    // find pile material in scene
    mesh.material = mesh.getScene().materials.filter((material) => material.name === "pile")[0]; // materials is an array but doesn't have the find function? used filter instead

    return mesh;
  }

  getPiece(pile: AbstractMesh, pieceName: string) {
    let meshes = pile.getChildMeshes(true, (mesh) => mesh.name === pieceName);
    if(meshes.length > 0)
      return meshes[0];
    return null;
  }

  /**
   * layout the pile
   * relayout=true means this is an automatic re-layout (window size changed maybe) and not in response to a play
   */
  layout(pile: AbstractMesh, relayout= false) {
    if(!pile)
      return;

    pile.metadata.needsLayout = false;

    let gameState = this.rootState.game;

    let pieceXInc = 0;
    let pieceYIncFaceUp = 0;
    let pieceYIncFaceDown = 0;
    let pieceZInc = 0;
    let pieceXStart = 0;
    let pieceYStart = 0;
    let pieceZStart = 0;
    let pieceYPos = 0;
    let layout = pile.metadata.layout;
    let pieces = pile.getChildMeshes();
    let cardWidth = 1.0;
    let cardHeight = 1.28; // The card textures are proportional to 400 x 512, 512 / 400 gives a height of 1.28
    let cardThickness = 0.025; // The card model is 0.0296
    let tiltX = 0.0;
    let tiltY = 0.0;
    let rotateZ = 0.0;
    let faceDownCards = 0;
    let jitterPosRange = 0;
    let jitterAngleRange = 0;

    // this.game.getAspectRatio() is coming up bogus (actually negative!) in the 1st couple of
    // layouts in facebook_ig mode
    // Since we can't actually support portrait in facebook instant games
    // we'll just force it false for now
    //let portrait = this.game.getAspectRatio() < 1.0;
    let portrait = false;

    // get cardThickness from first card in pile's bounding box
    if (pieces.length > 0) {
      let firstPiece = pieces[0];
      let bbox = firstPiece.getBoundingInfo().boundingBox;
      let meshThickness = bbox.maximum.z - bbox.minimum.z;
      if (meshThickness > cardThickness)
        cardThickness = meshThickness;
      cardWidth = bbox.maximum.x - bbox.minimum.x;
      cardHeight = bbox.maximum.y - bbox.minimum.y;
    }

    // Clone the pieces array before any sorting
    let piecesInOrder = pieces.slice();

    // check if we should sort the ui pile differently the game state
    if(pile.metadata.sort instanceof Function) {
      piecesInOrder.sort((a, b) => pile.metadata.sort(a.metadata.value, b.metadata.value));
    }
    else if(pile.metadata.sort === PILE_SORT_VALUE) {
      // Basically pass sort a function to compare 2 pieces, a and b, then return -1, 0, 1 for less then, equal, greater then.
      piecesInOrder.sort((a, b) => a.metadata.value - b.metadata.value);
    }

    // Count facedown cards
    for (let piece of piecesInOrder) {
      if(this.game.cardSystem.isFaceUp(piece))
        break;
      faceDownCards += 1;
    }

    // Initialize Inc and Start vars based on layout
    if (layout === "down") {
      if (pile.name === "drag") {
        pieceYStart = 0; // start drag pile at 0
      }
      tiltX = -1.5 * Math.PI / 180.0;
      pieceYIncFaceDown = -0.1;
      pieceYIncFaceUp = -0.36;
      pieceZInc = -cardThickness * 0.2;
      pieceZStart = -0.02; // any lower and the foundation shows through when the card is face down

      // Set maxHeight hint for fitting the game into the window
      pile.metadata.maxHeight = (-pieceYIncFaceUp * 12 + cardHeight - pieceYIncFaceUp - cardHeight * 0.75);
      if(!portrait)
        pile.metadata.maxHeight *= 0.667;

      if(layout === "down" && pile.name !== "drag") {
        // AFTER calculating maxHeight, we'll adjust parameters to squeeze the cards together so the last card is visible
        let down = faceDownCards;
        let up = piecesInOrder.length - faceDownCards;
        if(down && !up)
          down -= 1;
        if(up)
          up -= 1;

        let lastY = pieceYStart + down * pieceYIncFaceDown + up * pieceYIncFaceUp;
        let bottom = -pile.metadata.maxHeight + cardHeight * 0.25;
        if(lastY < bottom)
          pieceYIncFaceUp = (bottom - pieceYStart - down * pieceYIncFaceDown) / up;
      }
    }

    if (layout === "across") {
      pieceXInc = 0.5;
      pieceZInc = -cardThickness * 0.7;
      pieceZStart = pieceZInc;
    }

    if (layout === "stack") {
      pieceZInc = -cardThickness * 0.5;
      pieceZStart = pieceZInc;
      pile.metadata.maxHeight = cardHeight;
      jitterPosRange = 0.01;
      jitterAngleRange = 2.0;
    }

    if(layout === "hand") {
      // Hand is similar to across, but centered
      // This is used for opponent hands
      pieceXInc = 0.5;

      // If the 1st card is face down, reduce the increment
      if(piecesInOrder.length > 0 && !this.game.cardSystem.isFaceUp(piecesInOrder[0]))
        pieceXInc *= 0.25;

      tiltY = -4.0 * Math.PI / 180.0;

      let count = piecesInOrder.length;
      let inc = count > 0 ? count - 1 : 0;
      let width = 1.0 + inc * pieceXInc;
      pieceXStart = width * -0.5 + 0.5;
    }

    if(layout === "fan") {
      // Fan the cards out in an arc
      // This is used for the local player's hand
      pieceXInc = 0.5;
      tiltY = -4.0 * Math.PI / 180.0; // any less and cards interpenetrate sliding past each other

      let count = piecesInOrder.length;
      let inc = count > 0 ? count - 1 : 0;
      let width = 1.0 + inc * pieceXInc;
      pieceXStart = width * -0.5 + 0.5;

      // XXX - extend max height to account for the arc?
    }

    // Layout pieces
    piecesInOrder.forEach((piece, index) => {
      // we've had a few reports to sentry that piece.metadata is undefined, I'm hoping this is a weird timing issue and the pile will be relayed out again later when metadata is set
      if(!piece.metadata)
        return;

      let position = new Vector3(0, 0, 0);

      if(layout === "fan") {
        let fan = this.getFanLayoutForPiece(index, piecesInOrder.length, 13, piece);
        position = fan.position;
        rotateZ = fan.angle * Math.PI / 180.0;
      }
      else if(layout === "hand") {
        let fan = this.getHandLayoutForPiece(index, piecesInOrder.length, 13);
        position = fan.position;
        tiltY = fan.angle * Math.PI / 180.0;
      }
      else if(layout === "trick") {
        let r = this.getTrickLayoutForPiece(index, parseInt(piece.metadata.seatId));
        position = r.position;
        position.z *= cardThickness;
        rotateZ = r.angle * Math.PI / 180.0;
      }
      else {
        if (pieceXInc)
          position.x = pieceXStart + (index * pieceXInc);

        if (pieceYIncFaceUp) {
          position.y = pieceYStart + pieceYPos;
          pieceYPos += this.game.cardSystem.isFaceUp(piece) ? pieceYIncFaceUp : pieceYIncFaceDown;
        }
        if (pieceZInc)
        {
          position.z = pieceZStart + (index * pieceZInc);
          if(faceDownCards > 0 && index >= faceDownCards)
            position.z -= cardThickness * 0.5;
        }
      }

      let q = new Quaternion();

      // Don't jitter the bottom most card
      let jitterPieces = (index > 0);

      if(jitterPieces && jitterPosRange) {
        let jitterX = jitterPosRange * piece.metadata.randomX;
        let jitterY = jitterPosRange * piece.metadata.randomY;
        position.x += jitterX;
        position.y += jitterY;
      }

      if(jitterPieces && jitterAngleRange) {
        let angle = (jitterAngleRange * piece.metadata.randomA) * Math.PI / 180.0;
        q.multiplyInPlace(Quaternion.RotationAxis(Axis.Z, angle));
      }

      if(tiltX)
        q.multiplyInPlace(Quaternion.RotationAxis(Axis.X, tiltX));

      if(tiltY)
        q.multiplyInPlace(Quaternion.RotationAxis(Axis.Y, tiltY));

      if(rotateZ)
        q.multiplyInPlace(Quaternion.RotationAxis(Axis.Z, rotateZ));

      if(!this.game.cardSystem.isFaceUp(piece))
        q.multiplyInPlace(Quaternion.RotationAxis(Axis.Y, Math.PI));

      // Save the gameState.status we are being layed out in
      // If this is a relayout, preserve the previous layoutGameStatus instead
      if(!relayout)
        piece.metadata.layoutGameStatus = gameState.status;

      piece.metadata.layoutIndex = index;

      // layoutPosition is where we want the card to be, we'll animate to this position later
      piece.metadata.layoutPosition = position;

      // layoutOrientation
      piece.metadata.layoutRotationQuaternion = q;

      piece.metadata.layoutVisibility = pile.metadata.visibility;
    });

    this.game.animationSystem.queueMeshes(piecesInOrder);
  }

  getFanLayoutForPiece(index: number, length: number, max: number, piece?: AbstractMesh): ILayoutReturn {
    let arc = 30.0;
    let radius = 10.0;

    if(piece && this.game.cardSystem.isSelected(piece))
      radius = 10.5;

    let x = 0;
    let y = -10;
    let z = 0;

    let centerIndex = (length - 1) / 2;
    let divisor = 1 - max;
    let t = (index - centerIndex) / divisor;

    let angle = arc * t;
    let a = (90.0 + angle) * Math.PI / 180;

    x += Math.cos(a) * radius;
    y += Math.sin(a) * radius;

    return {
      position: new Vector3(x, y, z),
      angle: angle,
    };
  }

  getHandLayoutForPiece(index: number, length: number, max: number): ILayoutReturn {
    let arc = 30.0;
    let radius = 3.0;
    let tiltAngle = -8.0;

    let x = 0;
    let y = 0;
    let z = radius;

    let centerIndex = (length - 1) / 2;
    let divisor = 1 - max;
    let t = (index - centerIndex) / divisor;

    let angle = arc * t;
    let a = (270.0 + angle) * Math.PI / 180;

    x += Math.cos(a) * radius;
    z += Math.sin(a) * radius;

    return {
      position: new Vector3(x, y, z),
      angle: -angle + tiltAngle,
    };
  }

  getTrickLayoutForPiece(index: number, seat: number): ILayoutReturn {
    let offset = parseInt(this.game.getSouthSeat());
    seat = (seat + 4 - offset) % 4;

    let x = 0;
    let y = 0;
    let z = -index;
    let angle = 0;
    let radius = 0.5;

    if(seat === 0)
      angle = 180;
    else if(seat === 1)
      angle = 90;
    else if(seat === 2)
      angle = 0;
    else if(seat === 3)
      angle = 270;

    let a = (90.0 + angle) * Math.PI / 180;

    x = Math.cos(a) * radius;
    y = Math.sin(a) * radius;

    return {
      position: new Vector3(x, y, z),
      angle: angle,
    };
  }

  resized() {
    // Layout every pile
    // We can't wait for newFrame because this has to happen before fitInWindow is called
    this.game.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Pile" && mesh.name !== "drag").forEach((mesh) => {
      this.layout(mesh, true);
    });
  }

  newFrame(deltaTime: number) {
    // Re-layout flagged piles in case the spacing has changed due to adding or removing cards
    // This needs to be done between frames otherwise there are errors about the pile state and the pile being out of sync
    this.game.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Pile" && mesh.metadata.needsLayout && mesh.name !== "drag").forEach((mesh) => {
      // In this case, we are responding to a play which set the needsLayout flag so we do not want to set the relayout flag
      this.layout(mesh, false);
    });
  }

  /** This is based off of scene.getWorldExtends, but specific to piles of cards
   */
  getPileExtents(): [Vector3, Vector3] {
    // The pile size is no longer correct (the piles are scaled down a bit)
    // So instead, we'll offset the pile's absolute position by bounds of a card
    // Because the pile is rotated 90 degrees, we need to swap y & z
    let baseCardMesh = this.game.scene.getMeshByID("Card");
    let cardBox = baseCardMesh.getBoundingInfo().boundingBox;
    let cardMin = new Vector3(cardBox.minimum.x, cardBox.minimum.z, cardBox.minimum.y);
    let cardMax = new Vector3(cardBox.maximum.x, cardBox.maximum.z, cardBox.maximum.y);

    // fudge cardMax y to approiximate height of stock pile
    let cardZInc = (cardMax.y - cardMin.y) * 0.5; // Aproximate stack pile z increment with card model
    let fudgeDepth = cardZInc * 24.0; // Approximate height of the stock pile in Klondike
    cardMax.y = fudgeDepth;

    let min = new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
    let max = new Vector3(-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE);

    this.game.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Pile" && mesh.name !== "drag" && mesh.name.substring(0, 5) !== "waste").forEach((mesh) => {
        mesh.computeWorldMatrix(true);

        // Compute virtual bounds for the pile as if it had one card in it
        let pos = mesh.absolutePosition;
        let minBox = pos.add(cardMin);
        let maxBox = pos.add(cardMax);

        // Adjust pile height based on the metadata
        minBox.z = maxBox.z - mesh.metadata.maxHeight;

        // Let Babylon Tools build up our extents
        Vector3.CheckExtends(minBox, min, max);
        Vector3.CheckExtends(maxBox, min, max);
    });

    return [min, max];
  }

  getSelectedPieces(pile: AbstractMesh) {
    return pile.getChildMeshes(true, (mesh) => mesh.metadata.selected);
  }

  deselectPieces(pile: AbstractMesh) {
    for(let piece of pile.getChildMeshes(true))
      this.game.cardSystem.setSelected(piece, false);
  }

  unhighlightPieces(pile: AbstractMesh) {
    for(let piece of pile.getChildMeshes(true))
      this.game.cardSystem.setHighlighted(piece, false);
  }

  animateScaleTo(pile: AbstractMesh, newScaleFactor: number) {
    // Save the scale at this moment
    let oldScale = pile.scaling.clone();

    // Cancel any existing animation
    if (pile.animations.length) {
      pile.getScene().stopAnimation(pile);
      pile.animations.length = 0;
    }

    let newScale = new Vector3(newScaleFactor, newScaleFactor, newScaleFactor);

    // Keys
    let k0 = 0;
    let k1 = k0 + 10;

    let scaleAnimation = new Animation("ScaleSelectedPile", "scaling", 30.0, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let scaleKeys = [];
    scaleKeys.push({ frame: k0, value: oldScale });
    scaleKeys.push({ frame: k1, value: newScale });
    scaleAnimation.setKeys(scaleKeys);
    pile.animations.push(scaleAnimation);

    // Elasic easing overshoots, then springs back
    let easingFunction = new ElasticEase(1, 5);
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
    scaleAnimation.setEasingFunction(easingFunction);

    let speed = 1.0;

    this.game.scene.beginAnimation(pile, 0, k1, false, speed);

  }

}
