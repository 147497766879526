import { Observable } from "@babylonjs/core/Misc/observable";

import { ArgoSystem } from "components/game/ArgoSystem";
import { AdStatus, IAdParams, IAdPreloadStatus, IAdProvider } from "components/ui/ad-system/AdParams";
import { ArgoAdProvider } from "components/ui/ad-system/ArgoAdProvider";
import { Commands } from "components/ui/Commands";
import { ROOT_STATE_GAME } from "states/RootState";
import { config } from "utils/Config";

export class AdSystem extends ArgoSystem {
  adProviders: {[index: string]: IAdProvider} = {};
  roundOverShowCount = 0; // Count of successful roundOver ad shows, used to insert the instaPass ad in place of the 3rd showing.
  preloadStatusChangedObservable: Observable<IAdPreloadStatus>; // Notification if the status of a preload changes

  init() {
    this.preloadStatusChangedObservable = new Observable<IAdPreloadStatus>();

    Commands.onShowAdObservable.add((params: IAdParams) => this.showAd(params));

    // Register the built-in pop up ad provider
    this.registerAdProvider(new ArgoAdProvider());
  }

  /** Preload ads in createGUI
   * an alternative might be queueAssets, but we're already downloading a bunch of stuff then
   */
  createGUI(): void {
    // Errors here will kill Babylon
    //try {

      // Attempt to preload everything in adPlacements
      for(let key of Object.keys(config.adPlacements))
        this.preloadAd(key);

    //} catch(err) {
      //console.error("AdSystem.createGUI", err);
    //}
  }

  /** Register an AdProvider to handle an ad type */
  registerAdProvider(provider: IAdProvider) {
    this.adProviders[provider.type] = provider;
  }

  /** Internal function to get an AdConfig from the placement key */
  getAdConfigForPlacement(key: string) {
    // Get the config
    let adConfig = config.adPlacements[key];
    if(adConfig === undefined || adConfig === null)
      return null;

    // Return the AdConfig
    return adConfig;
  }

  /** Preload an ad so it will be ready when needed */
  preloadAd(key: string) {
    // Get the AdConfig for this placement
    let adConfig = this.getAdConfigForPlacement(key);
    if(!adConfig)
      return;

    // Get the ad provider
    let adProvider = this.adProviders[adConfig.type];
    if(!adProvider)
      return;

    // Let the provider take it from here
    adProvider.preload(key, adConfig);
  }

  isPreloaded(key: string): boolean {
    // Get the AdConfig for this placement
    let adConfig = this.getAdConfigForPlacement(key);
    if(!adConfig)
      return false;

    // Get the ad provider
    let adProvider = this.adProviders[adConfig.type];
    if(!adProvider)
      return false;

    // Let the provider take it from here
    return adProvider.isPreloaded(key, adConfig);
  }

  onPreloadStatusChanged(key: string, status: AdStatus) {
    this.preloadStatusChangedObservable.notifyObservers({key, status});
  }

  /** Internal AdFinished handler for implementing a fallback if a facebook_interstitial ad fails */
  onShownAdFinished(params: IAdParams, status: AdStatus, realOnDoneCallback: (status: AdStatus) => void) {
    // If the ad was successful, just return the result
    if(status === AdStatus.AD_STATUS_FINISHED) {
      if(params.key === "roundOver")
        this.roundOverShowCount += 1;

      if(realOnDoneCallback)
        realOnDoneCallback(status);
      return;
    }

    // Otherwise, try to show a fallback ad
    this.showAd({
      key: "fallback",
      onDoneCallback: realOnDoneCallback,
    });
  }

  /** show an ad - normally called via Commands.onShowAd */
  showAd(params: IAdParams) {
    // We want to show instaPassPopUp in place of the 3rd showing of the roundOver ad
    if(params.key === "roundOver" && this.roundOverShowCount === 2) {
      params.key = "fallback";
      this.roundOverShowCount += 1; // Increment the count so the test won't trigger forever
    }

    // Get the AdConfig for this placement
    let adConfig = this.getAdConfigForPlacement(params.key);
    if(!adConfig)
      return this.onAdClosed(params, AdStatus.AD_STATUS_MISCONFIGURED);

    // Get the ad provider
    let adProvider = this.adProviders[adConfig.type];
    if(!adProvider)
      return this.onAdClosed(params, AdStatus.AD_STATUS_NO_PROVIDER);

    // Hack the onDoneCallback to see if we should show a fallback ad
    if(adProvider.type === "facebook_interstitial" &&  params.key !== "fallback") {
      let realOnDoneCallback = params.onDoneCallback;
      params.onDoneCallback = (status: AdStatus) => this.onShownAdFinished(params, status, realOnDoneCallback);
    }

    // Let the provider take it from here
    adProvider.show(params, adConfig);
  }

  /** Show a specific named ad from the AdProvider - normally we choose one at random */
  showSpecificAd(adProviderName: string, specificAdName: string, onDoneCallback: (status: AdStatus) => void) {
    let params: IAdParams = {key: "", onDoneCallback};

    // Get the argo ad provider
    let adProvider = this.adProviders[adProviderName];
    if(!adProvider)
      return this.onAdClosed(params, AdStatus.AD_STATUS_NO_PROVIDER);

    // See if the provider supports showing a specific ad
    if(adProvider.showSpecificAd === undefined)
      return this.onAdClosed(params, AdStatus.AD_STATUS_UNSUPPORTED);

    // Let the provider take it from here
    adProvider.showSpecificAd(params, specificAdName);
  }

  onAdClosed(params: IAdParams, status: AdStatus) {
    //console.log(`AdSystem.onAdClosed - key ${params.key}`)
    if(params.onDoneCallback)
      params.onDoneCallback(status);
  }

  /** return true if we should show an ad.  */
  shouldShowAd() {
    // don't show ad if more then 1 human this is a reward for inviting to play with a friend, also don't show if they have the pass
    if(config.haveAds && this.rootState.status === ROOT_STATE_GAME && this.rootState.game.getHumanPlayersInSeatsCnt() <= 1 && !this.rootState.user.inventory.hasItem("insta_pass"))
      return true;
    return false;
  }
}
