import { types } from "mobx-state-tree";

import { PlayerState } from "states/game/PlayerState";

// tslint:disable-next-line:variable-name
const SeatState = types
  .model("SeatState", {
    id: types.identifier,  // id is seat#, ie seat 0 id is 0
    player: types.maybeNull(types.reference(PlayerState)),
    score: types.optional(types.number, 0),
    roundScore: types.optional(types.number, 0),
    won: types.optional(types.boolean, false),
    ready: types.optional(types.boolean, false), // flag used for user to indicate they're ready, ie for passing in Hearts

    // trick game specific
    bid: types.maybeNull(types.number),
    tricksWon: types.optional(types.number, 0),

    // When adding more properties be sure to review if they need to be copied in GameState.applySyncToServerSnapshot
  })
  .actions((self) => {
    function reset() {
      self.player = null;
      self.score = 0;
      self.roundScore = 0; // The player's contribution to the round score, without any team penalties
      self.won = false;
      self.bid = null;
      self.tricksWon = 0;
      self.ready = false;
    }

    function addToScore(points: number) {
      self.score += points;
    }

    function addToRoundScore(points: number) {
      self.roundScore += points;
    }

    function setRoundScore(roundScore: number) {
      self.roundScore = roundScore;
    }

    function incTricksWon() {
      self.tricksWon += 1;
    }

    function setBid(bid: number) {
      self.bid = bid;
    }

    return { reset, addToScore, addToRoundScore, setRoundScore, incTricksWon, setBid };
  })
  .views((self) => {
    return { };
  });

// SeatState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type ISeatState = typeof SeatState.Type;

export { SeatState, ISeatState };
