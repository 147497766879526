// import and create global Logger before importing anything else, just incase those other imports use the logger
import { LoggerBrowser } from "utils/logger-browser";
let logger: LoggerBrowser;
if(process.env.NODE_ENV === "production")
  logger = new LoggerBrowser("https://05a9f39214104d8fa63c55ffef0619df@sentry.io/1372482");
else
  logger = new LoggerBrowser();

import { FacebookIGAnalytics } from "components/facebook/FacebookIGAnalytics";
import { FacebookIGStorage } from "components/facebook/FacebookIGStorage";
import { IFacebookAuth } from "components/facebook/IFacebookAuth";
import { IAdPlacementMap } from "components/ui/ad-system/AdParams";
import { IArgoAdMap } from "components/ui/ad-system/ArgoAdInterfaces";
import { IHomeScreenButton } from "components/ui/HomeScreenSystem";
import { BrowserStorage } from "components/utils/BrowserStorage";
import { DummyAnalytics } from "components/utils/IAnalytics";

import { mergeConfig } from "utils/Config";
import { DEBUG} from "utils/logger";

interface IArgoSystemConfig {
  name: string;
  class: any;
}

mergeConfig({
  allowLoadTestClientModeCGI: false, // set true to allow loadTestClientMode to be set in url, ie ?loadTestClientMode=1
  loadTestClientMode: false,  // set true to turn off rendering and auto start and play a quick play game. for headless load testing

  // Name of Game
  longName: "Insta Game",
  shortName: "Game",
  clientName: process.env.PACKAGE_NAME, // name of game client package, ie spades_facebook_ig. currently it is identical to name in package.json

  // 3 values in an array [0, 0, 3.15] are vectors ie BABYLON.Vector3.FromArray(config.cameraTarget())
  // Camera Position
  cameraTarget: [0, 0, 3.15], // Smaller values move the game up on the screen
  cameraPosition: [0, 12, 0], // This is a default, cameraPosition is overridden by cameraAngle
  cameraAngle: 10.0,
  guiTransformNodeDistance: 0.0, // The distance (usually negative) from the origin towards the camera to place the guiRootTransformNode so that it does not intersect the game

  // Lights
  hemisphericLightDirection: [10, 10, -10],

  directionalLightDirection: [-1, -2, -1],
  directionalLightPosition: [10, 10, -10],

  // padding for fitInWindow, 0.1 is a resonable value for card games
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,

  // Animation Lockstep
  deterministicLockstep: false,
  lockstepMaxSteps: 4,

  // Fonts
  // BABYLON.GUI.Control defaults to Arial, so any GUI control with text needs these set manually
  fontFamily: "Saira, sans-serif",
  fontWeight: "bold",

  // Piles
  pileHasMarkerTexture: true, // True if Pile.ts should download the pile marker texture as an asset

  // menu status bar (at bottom)
  haveMenuStatusBar: false,
  haveUndo: false,

  // Sounds
  haveSound: true,
  sounds: {}, // {name: {url: urlOrArray, volume: 1.0}}
  music: {}, // {name: {url: urlOrArray, volume: 1.0}}

  // Fullscreen
  haveFullscreen: true,

  // decks
  defaultDeckUrl: null, // set in game specific argo.config.ts files
  defaultDeckBack: 0, // which back in the deck to use

  // Level Tier Colors
  levelTierColors: [
    {level: 1, color: "WhiteSmoke"},
    {level: 21, color: "DeepSkyBlue"},
    {level: 71, color: "Aqua"},
    {level: 151, color: "gold"},
  ],

  // Menu Colors
  menuTextColor: "white",
  menuBackgroundColor: "#287996",
  menuCheckboxCheckedColor: "red",

  // Pop Up Button colors (stand alone center screen buttons, like Start Game and Pass)
  // The text color is also the outline color
  popUpButtonTextColor: "white",
  popUpButtonBackgroundColor: "#19648b",
  popUpButtonUnderneathTextColor: "white",

  // Screen Footer Buttons
  screenFooterButtonTextColor: "white",
  screenFooterButtonBackgroundColor: "#01003a",

  // Color to use when highlighting the player's score
  scoreHighlightColor: "white",
  scoreHighlightShadowBlur: 16,
  scoreHighlightShadowColor: "gold",

  playerScoreTemplate: null,  // if set, a mustache template to use to format the score underneath the player's name
  showTeamsOnUserStatus: false, // true if Team Names, Scores and Bags are shown on the User Status bar at the top of the screen. (For Spades)

  // Loading screen
  enableLoadingScreen: true,
  haveHomeScreen: true,
  homeScreenButtons: [ ] as IHomeScreenButton[],
  autoRunTutorial: false,  // auto run tutorial first time game is launched

  // Share Image
  // Type and Quality for share images which are generated using an HTML canvas
  // If the share is a single image instead of a canvas drawing, the image type won't be changed
  shareImageType: "image/jpeg", // image/png or image/jpeg
  shareImageQuality: 0.92, // This is the default value listed on MDN
  shareImageLogSize: false, // True to log generated share image size to the console
  shareImageLogURI: false, // True to log generated share images to the console

  // gameOptions will be merged in from game-configs.ts files
  gameOptions: {
    default: {},
  },

  // State Sync
  haveStateSync: false,    // set true if game syncs to socket cluster state server
  autoAuth: true, // tells StateSyncClient to automatically authenticate when socket is connected, set false for facebook to wait for id and signature from fb
  reuseAuth: true, // true socket cluster default behavoir will use cached auth on initial connection. Set to false to deauth before connect, this fixes switch users on Facebook, to prevent it from using wrong users auth.
  autoGetUserState: true,  // tells UserState to auto request UserState from server when UserState.id is set
  offlineSingleplayer: false, // true if a normally multiplayer game should be played offline if there is only 1 human. ie spades
  haveMultiplayer: false,  // set true if this game/platform supports inviting others to play
  canDelayAIPlay: true, // set true if it's ok for the AI to dealy it's play, or if it's required that the ai play immediately when it's turn comes up

  // Socket Cluster
  scHost: "localhost",
  //scHost: "192.168.1.100", // DanPC for testing
  //scHost: "192.168.99.100", // Dan Docker IP
  //scHost: "192.168.1.154", // MannyPC for testing on phone, mac or chromebook
  scPort: 8000,
  scSecure: false,

  // for testing deployed servers
  //scHost: "hearts-api-test.instagames.gg",
  //scPort: 443,
  //scSecure: true,

  // facebook
  facebookAppSecret: "", // The facebook instant games app secret to verify signatures with.
  haveAds: false,
  haveRewardAds: false,
  haveInAppPurchases: false, // be sure Insta Pass is disabled except in games that support it

  // Ad Placements, type AdParams.IAdConfig
  adPlacements: {
  } as IAdPlacementMap,
  // Our ads, type ArgoAdProvider.IArgoAd
  // The ads are first filtered for valid ads, then one is chosen randomly
  argoAds: {
  } as IArgoAdMap,
  offerLifePopUpBackgroundUrl: null,

  facebookAuth: null as IFacebookAuth, // only used on server, so set in sc-server/argo.config

  // Leaderboards
  haveLeaderboards: false,
  facebookAutoLoadLeaderboards: [],  // list of facebook leaderboards to load at startup
  facebookHaveScoreLeaderboard: false, // set true to auto post the score after every game, ie solitaire, but not trickgames.
  defaultLeaderboard: "", // name of leaderboard to show in LeaderboardSidebar by default

  haveCookies: true,

  onClickPreventPlayUntilTurnChanges: false, // see Game.onClick for notes

  // a list of additional systems to load
  additionalSystems: [] as IArgoSystemConfig[], // example [{name: "FacebookIGSystem", system: FacebookIGSystem},],

  // the list of options to show on the in game popup menu
  menuPopUpItems: ["leave-game", "tutorial", "invite", "share", "feedback"],

  // list of other games to cross promote, will exclude current game by matching key to shortName
  // do not cross promote on facebook ios though, check rootState.platform !== "IOS"
  promoGames: {
    Solitaire: {
      longName: "Insta Solitaire",
      facebookAppId: "1943733655679302",
      url: "https://instagames.gg/solitaire/",
    },
    Spades: {
      longName: "Insta Spades",
      facebookAppId: "2451495921534198",
      url: "https://instagames.gg/spades/",
    },
    /* uncomment when hearts is publicly available
    Hearts: {
      longName: "Insta Hearts",
      facebookAppId: "882628892120649",
      url: "https://instagames.gg/hearts/",
    },*/
  },

  // sentry.io/logger
  loggerConsoleLevel: DEBUG,

  // storage class
  storage: null, // set below in platform specific config

  // analytics
  analytics: new DummyAnalytics(), // FacebookIGAnalytics is set below, but GameAnalyticsCom for web is set in each game/web/argo.config.ts to be sure GameAnalytics doesn't get included in facebook version.
  analyticsAdValue: 0.0033, // approxiamate value of 1 ad view in dollas, ie 0.0033 is 1/3rd of a penny
});

// General platform overrides
if(process.env.PLATFORM === "facebook_ig") {
  mergeConfig( {
    enableLoadingScreen: false,
    haveCookies: false,
    autoAuth: false,
    reuseAuth: false,
    haveFullscreen: false,

    storage: new FacebookIGStorage(),
  });

  if(process.env.NODE_ENV === "development") {
    mergeConfig( {
      // in development mode we use JWT for auth instead of facebook's auth, so turn back on reuseAuth, otherwise we'll get a new UserState every time
      reuseAuth: true,
    });
  }
  else if(process.env.NODE_ENV === "production") {
    mergeConfig( {
      analytics: new FacebookIGAnalytics(),
    });
  }
}
else {
  mergeConfig( {
    storage: new BrowserStorage(),
  });
}

if(process.env.NODE_ENV === "production") {
  mergeConfig( {
    // Socket Cluster
    //scHost: "fb.instaspades.com", scHost is now set in game specific argo.config.ts
    scPort: 443,
    scSecure: true,

    // logger
    loggerConsoleLevel: null,
  });
}
