import { Tools } from "@babylonjs/core/Misc/tools";

/** downloads a file from url and converts to a base64 encoded string */
function toDataURL(url: string): Promise<any> {
  return new Promise<any> (
    (resolve, reject) => {
      // setup http request
      const request = new XMLHttpRequest();
      request.onload = function() {
        if (this.status === 200) {
          // download was successful, use FileReader to convert to base64
          let fileReader = new FileReader();
          fileReader.onloadend = (event) => {
            resolve(fileReader.result); // return result via promise
          };
          fileReader.readAsDataURL(this.response);
        } else {
          reject(new Error(this.statusText));
        }
      };
      request.onerror = function() {
        reject(new Error("XMLHttpRequest Error: " + this.statusText));
      };
      request.responseType = "blob";
      request.open("GET", url);
      request.send();
  });
}

/** load a URL to an HTMLImageElement, returns a promise that returns the loaded image or null */
async function loadImageAsync(url: string): Promise<HTMLImageElement> {
  if(url === null || url === undefined || url === "")
    return null;

  return new Promise<HTMLImageElement>((resolve, reject) => {
    let image = new Image();

    // Without this line, Facebook avatars get a SecurityError in the browser
    Tools.SetCorsBehavior(url, image);

    image.onload = () => {
      resolve(image);
    };

    image.onerror = () => {
      resolve(null);
    };

    image.src = url;
  });
}

/** load a URL to an HTMLImageElement, returns a promise that returns the loaded image or null
 * If the URL fails, the fallBackUrl will be tried
 */
async function loadImageAsyncWithFallback(url: string, fallbackUrl: string): Promise<HTMLImageElement> {
  let image = await loadImageAsync(url);

  if(image === null && fallbackUrl)
    image = await loadImageAsync(fallbackUrl);

  return image;
}

export { toDataURL, loadImageAsync, loadImageAsyncWithFallback };
