import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents";
import { ArgoSystem } from "components/game/ArgoSystem";

/** Watch for RootState.paused being set and then listen for any pointer down event to resume. Originaly I was thinking we might grey screen and show a Pause message.
 *  That is why I set this up as a system.
 */
export class PauseSystem extends ArgoSystem {

  init() {
    this.rootState.router.addRoute("^\/paused$", (patch: any, reversePatch: any, params: any) => this.onPausedChanged(patch, reversePatch, params));
  }

  onPausedChanged(patch: any, reversePatch: any, params: any) {
    // if paused then listen for the next pointer down event to unpause. Then the callback below gets auto unregistered
    if(patch.value)
      this.game.scene.onPrePointerObservable.add((evt) => this.rootState.resume(), PointerEventTypes.POINTERDOWN, undefined, undefined, true);
  }
}
