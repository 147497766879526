import { types } from "mobx-state-tree";

// tslint:disable-next-line:variable-name
const TimerState = types
  .model("TimerState", {
    id: types.identifier,
    interval: types.number, // interval of timer in seconds
    next: types.Date, // the date in the future to call the action

    /* action is a JSON encoded string in the format
      {
        "name":"clickPiece",
        "path":"",
        "args": ["us-west-2:945c121f-b38e-444c-9cd8-da8c088f33e1","0","hand0","piece8"]
      }
    */
    action: types.string,
  })
  .actions((self) => {

    /** Gets the time in seconds to the next time this timer should run */
    function getSecondsToNext() {
      let now = new Date();
      let diff = self.next.getTime() - now.getTime();
      if(diff <= 0)
        return 0;
      return diff / 1000; // convert from ms to seconds
    }

    return { getSecondsToNext };
  });

// TimerState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type ITimerState = typeof TimerState.Type;

export { TimerState, ITimerState };
