import { Observable } from "@babylonjs/core/Misc/observable";

import { getSnapshot } from "mobx-state-tree";

import { getRootState, ROOT_STATE_GAME, ROOT_STATE_HOME_SCREEN } from "states/RootState";

import { game } from "components/game/Game";
import { AdStatus, IAdParams } from "components/ui/ad-system/AdParams";
import { SharePopUp } from "components/ui/controls/SharePopUp";
import { DebugMenuPopUp } from "components/ui/DebugMenuPopUp";
import { MenuPopUp } from "components/ui/MenuPopUp";
import { IShareObject } from "components/ui/ShareSystem";
import { disposeGuiControl } from "components/utils/GUI";
import { IPurchaseParams, PurchaseResult } from "components/utils/PurchaseParams";
import { GAME_STATE_CREATE, GAME_STATE_GAME_OVER, IGameStart } from "states/game/GameState";
import { config } from "utils/Config";
import { logger } from "utils/logger";
import { IHelpParams } from "./HelpSystem";

// tslint:disable-next-line:no-var-requires
let feedbackModalUrl = require("file-loader?esModule=false!extract-loader!html-loader!components/ui/modal-dialogs/feedback-modal.html");

/** Global commands generally called by clicking on UI Buttons */
export class Commands {

  // Observable commands, basically any thing can add themselves to these to be notified
  // then to trigger them something else will call ie: Commands.onInviteObservable.notifyObservers(null);
  public static onInviteObservable = new Observable();
  public static onShareObservable = new Observable<IShareObject>();
  public static onCreateShortcutObservable = new Observable();
  public static onHelpObservable = new Observable<IHelpParams>();
  public static onEnableTutorialObservable = new Observable<boolean>();  // pass true to enable, false to disable
  public static onModalButtonObservable = new Observable<string>();
  public static onLaunchGameObservable = new Observable<string>();
  public static onShowAdObservable = new Observable<IAdParams>();
  public static onPurchaseOfferObservable = new Observable<IPurchaseParams>();

  static onInvite(eventData: any = null) {
    Commands.onInviteObservable.notifyObservers(eventData);
  }

  /** Shares game information using whichever service has registered with the observable */
  static onShare(options?: IShareObject) {
    if(Commands.onShareObservable.hasObservers())
      Commands.onShareObservable.notifyObservers(options);
    else {
      let win = window.open("https://www.facebook.com/sharer/sharer.php?u=https://www.silvercrk.com/", "_blank");
      win.focus();
    }
  }

  /** Display a share UI to select what to share from the game
   * If shuffle is true, the passed in shareNames will be shuffled
   * If appendDefault, the default share name is appended to the list after shuffling
   */
  static onShareUI(shareNames?: string[], shuffle = true, appendDefault = true) {
    // Clear out any existing SharePopUp
    disposeGuiControl("SharePopUp");

    // Display the popup to allow user to choose which image to share
    let pop = new SharePopUp("SharePopUp", shareNames, shuffle, appendDefault);
    game.guiTexture.addControl(pop);
    pop.animateScale(0.25, 1.0, 1.0);
  }

  static onCreateShortcut() {
    Commands.onCreateShortcutObservable.notifyObservers(null);
  }

  // Non Observable based Commands
  static onRequestHomeScreen() {
    // If we're currently in a game(most likey at the game over screen) check if we should show an ad first
    Commands.onShowAd("gameOver", game.adSystem.shouldShowAd(), () => Commands.onHomeScreen());
  }

  static onRequestPlayAgain() {
    let rootState = getRootState();
    let gameStart: IGameStart = {
      options: getSnapshot(rootState.game.options),
      gameContextId: rootState.game.gameContextId,
      humansCnt: rootState.game.getHumanPlayersInSeatsCnt(),
    };
    // if the local player is in a seat, set seatId in gameStart to keep them in the same seat
    let seat = rootState.game.getPlayerSeat(rootState.user.id);
    if(seat)
      gameStart.seatId = seat.id;

    // If we're currently in a game(most likey at the game over screen) check if we should show an ad first
    Commands.onShowAd("gameOver", game.adSystem.shouldShowAd(), () => game.requestNextGame(gameStart));
  }

  static onHomeScreen() {
    // When returning to the home screen after a game, be sure to reset the game to clear the round/game summary, and unsubscribe from StateSync
    if(getRootState().game.status !== GAME_STATE_CREATE)
      getRootState().game.resetState();
    getRootState().setStatus(ROOT_STATE_HOME_SCREEN);
  }

  /** Show an Advertisement
   * If an ad is shown, the callback will be called when it is completed
   * Otherwise, the callback will be called immediately
   * The key is expected to be present in config.adPlacements.  Set the id to null to disable the key.
   * Currently it's necessary to add new keys to FacebookIGSystem.onRootStatusChanged to have the ad preload
   * showAd is a convenience to callers to make sure the callback gets called if they are not showing an ad
   */
  static onShowAd(key: string, showAd: boolean, onDoneCallback: (status: AdStatus) => void) {
    // Make sure there's an observer configured
    if(!this.onShowAdObservable.hasObservers()) {
      if(onDoneCallback)
        onDoneCallback(AdStatus.AD_STATUS_UNSUPPORTED); // If we're not showing the ad, we still need to call the callback
      return;
    }

    // Check if the caller has disabled showing the ad
    if(!showAd) {
      if(onDoneCallback)
        onDoneCallback(AdStatus.AD_STATUS_NOT_SHOWING);
      return;
    }

    // Notify whatever's handling ads to show this ad
    this.onShowAdObservable.notifyObservers({key: key, onDoneCallback});
  }

  static onPurchaseOffer(offerName: string, onDoneCallback?: (result: PurchaseResult) => void) {
    this.onPurchaseOfferObservable.notifyObservers({offerName, onDoneCallback});
  }

  /** Ends a game in progress */
  static onEndGame() {
    game.gameState.endGame();
  }

  /** Leave a game in progress */
  static onLeaveGame() {
    game.leaveGame();
  }

  static onConfirmLeaveGame() {
    let status = game.rootState.status;
    let gameStatus = game.gameState.status;

    // If not in a game at all, just ignore it
    if(status !== ROOT_STATE_GAME)
      return;

    // If the game is over, just leave now
    if(gameStatus === GAME_STATE_GAME_OVER)
      Commands.onLeaveGame();

    // Otherwise, confirm first
    game.modalDialogSystem.showOkCancel("Leave Game", "Are you sure you want to leave this game and take a loss?", "Leave Game", "Stay").then( (response) => {
      if(response.button === "ok") {
        // If we're showing roundOver ads, we need to show one now
        // If we're showing gameOver ads, it will be triggered by returning to the HomeScreen
        Commands.onShowAd("roundOver", game.adSystem.shouldShowAd(), () => Commands.onLeaveGame());
      }
    });
  }

  /** Starts a new game. */
  static onNewGame(gameStart: IGameStart = {}) {
    game.newGame(gameStart);
  }

  /** Start a tutorial game */
  static onTutorialGame() {
    let gameStartTutorial: IGameStart = {
      options: config.gameOptions.tutorial,
      tutorial: true,
    };
    Commands.onNewGame(gameStartTutorial);
  }

  static onRequestStartGame() {
    getRootState().game.requestStartGame(getRootState().user.id);
  }

  /** undo the last action in the gameState and the UI will automatically reflect the change */
  static onUndo() {
    game.gameState.undo();
  }

  static onHint() {

  }

  static onAbout() {

  }

  static onProfile() {

  }

  static onQuit() {
    // we must request to quit from the state, this gives it a chance to do something before quitting, such as post a facebook messenger message
    getRootState().requestQuit();
  }

  /** Show or hide options box (side bar) */
  static onOptions() {
    MenuPopUp.toggle();
  }

  /** Show or hide debug options box */
  static onDebugMenu() {
    DebugMenuPopUp.toggle();
  }

  /** open privacy policy webpage in new window */
  static onPrivacyPolicy() {
    let win = window.open("https://www.silvercrk.com/about/privacy/", "_blank");
    win.focus();
  }

  /** Show an Help dialog */
  static onHelp(params?: IHelpParams)  {
    this.onHelpObservable.notifyObservers(params);
  }

  /** Enable tutorial during a regular game. */
  static onEnableTutorial(enable: boolean)  {
    this.onEnableTutorialObservable.notifyObservers(enable);
  }

  static onFeedback() {
    game.modalDialogSystem.showModal(feedbackModalUrl).then( (response) => {
      // send user feedback to sentry as a warning.
      if(response.button === "ok") {
        logger.warn("User Feedback - " + response.formData.subject + " - " + response.formData.message, { subject: response.formData.subject, message: response.formData.message });
        game.modalDialogSystem.showAlert("Feedback Sent", "Thank you for your feedback!");
      }
    });
  }

  /** Enable or disable sound */
  static onEnableSound(enable: boolean) {
    let volume = 0;
    if(enable)
      volume = 1;
    getRootState().user.setSound(getRootState().user.id, volume);
  }

  /** Enable or disable music */
  static onEnableMusic(enable: boolean) {
    let volume = 0;
    if(enable)
      volume = 1;
    getRootState().user.setMusic(getRootState().user.id, volume);
  }

  /** Enable or disable fullscreen */
  static onEnableFullscreen(enable: boolean) {
    game.setFullscreen(enable);
  }

  static toggleFullscreen() {
    game.toggleFullscreen();
  }

  /** Modal Dialog Buttons */
  static onModalButton(button: string) {
    this.onModalButtonObservable.notifyObservers(button);
  }

  static onLaunchGame(gameName: string) {
    // if onLaunchGameObservable has observers, then let it handle it. (Most likely FacebookIGSystem)
    // otherwise fallback to just going to url of game
    if(Commands.onLaunchGameObservable.hasObservers())
      Commands.onLaunchGameObservable.notifyObservers(gameName);
    else {
      window.location.href = config.promoGames[gameName].url;
    }
  }
}

// Add Commands to the window object for access in <script> tags
// Neither import nor require worked in a <script> tag
// Webpack supports libraries, but I couldn't figure out how to use it for this: https://webpack.js.org/guides/author-libraries/
declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    Commands: Commands;
  }
}

// Make Commands available to inline script in HTML
window.Commands = Commands;
