/**
 * StateSyncUserMiddleware extends StateSyncMiddleware to configure it specifically for UserState
 */

import { IPatchQueueState } from "states/PatchQueueState";
import { StateSyncMiddleware } from "states/state-sync/StateSyncMiddleware";

export class StateSyncUserMiddleware extends StateSyncMiddleware {
  constructor(state: IPatchQueueState) {
    super(state);
    // list of actions to intercept and forward to server (_forwardActions still gets ran locally, _forwardOnlyActions does not)
    // it is critical that the first argument to the function is the userId of the player doing the action
    // the server checks that the first argument matches the authenticated user id before running action
    this._forwardActions = [
      // BaseState
      "setClientName",

      // UserState
      "setBidDisplayMode",
      "setName",
      "setImageUrl",
      "setProfile",
      "setGameId",
      "setMusic",
      "setSound",
      "toggleBidDisplayMode",

      // HelpState
      "incSeenCnt",
      "resetSeenCnt",
    ];

    this._forwardOnlyActions = [
      // MetaGameState
      "addToScore",
      "endMetaGame",
      "loseLife",
      "unlockLife",

      // PaymentsState
      "processPayment",
    ];
  }
}
