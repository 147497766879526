import { Animation } from "@babylonjs/core/Animations/animation";
import { Quaternion } from "@babylonjs/core/Maths/math";
import { Vector2, Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Observable } from "@babylonjs/core/Misc/observable";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import { config } from "utils/Config";
import { logger } from "utils/logger";

import { Argo2dParticleSystem, Argo3dParticleSystem } from "components/game/ArgoParticleSystem";
import { game } from "components/game/Game";
import { GameOverCardScreen } from "components/game/GameOverCardScreen";
import { SpadesRoundSummary } from "components/game/SpadesRoundSummary";
import { TurnIndicatorSystem } from "components/game/TurnIndicatorSystem";
import { Commands } from "components/ui/Commands";
import { Carousel } from "components/ui/controls/Carousel";
import { RectangleWithPointerCapture } from "components/ui/controls/RectangleWithPointerCapture";
import { ScrollBox } from "components/ui/controls/ScrollBox";
import { TutorialPopUp } from "components/ui/controls/TutorialPopUp";
import { LeaderboardScreen } from "components/ui/LeaderboardScreen";
import { LevelUpScreen } from "components/ui/LevelUpScreen";
import { debugMissileControl } from "components/ui/MissileControl";
import { UserStatusSystem } from "components/ui/UserStatusSystem";
import { disposeGuiControl, disposeToast, findGuiControl, popUpMenu, promptReload, toast, zIndex } from "components/utils/GUI";
import { GUIAnimationProxy } from "components/utils/GUIAnimationProxy";
import { imageUrlToPngDataURI, svgToPngDataURI } from "components/utils/to-png-data-uri";

import { IGameStart } from "states/game/GameState";
import { IEntryState } from "states/leaderboard/EntryState";
import { getRootState } from "states/RootState";

import pawn0AvatarDataUrl from "!url-loader!components/game/avatars/pawn0.png";
import pawn0AvatarUrl from "components/game/avatars/pawn0.png";
import { LoadTestMonitorSystem } from "components/utils/LoadTestSystem";

// tslint:disable-next-line:no-var-requires
let feedbackModalUrl = require("file-loader?esModule=false!extract-loader!html-loader!components/ui/modal-dialogs/feedback-modal.html");

export class DebugMenuPopUp {
  static onMenuPopUpObservable = new Observable<boolean>();

  /** Create a MenuPopUp */
  static create() {
    if(process.env.NODE_ENV !== "development")
      return;

    let items = ["Auth >", "Start Game >", "Leaderboard >", "Meta Game > ", "GUI >", "GUI Utils >", "Help & Ads >", "Screens >", "Particles & Effects >", "Sync State >", "Settings >", "Misc >", "Load Test >", "Store >"];
    popUpMenu("debugMenuPopUp", items, (item) => this.onDebugMenuClick(item));
  }

  static dispose() {
    disposeGuiControl("debugMenuPopUp");
  }

  /** toggle menuPopUp on and off */
  static toggle() {
    let menuPopUp = findGuiControl("debugMenuPopUp");
    if (menuPopUp)
      DebugMenuPopUp.dispose();
    else
      DebugMenuPopUp.create();
  }

  static onDebugMenuClick(item: string) {
    let items: string[] = null;

    switch(item) {
      case "Auth >":
        items = ["EMPTY"];
        popUpMenu("debugAuthMenuPopUp", items, (itemClicked) => this.onDebugAuthMenuClick(itemClicked));
        break;
      case "Start Game >":
        items = ["Start Tutorial", "Start Joinable QuickPlay", "Join Joinable QuickPlay", "Start Joinable Classic", "Join Joinable Classic", "Start Hand Challenge", "Start Classic Stand Alone", "Start Moon Deal", "Start Bot Moon Deal", "Start Sun Deal", "Start TRAM Deal"];
        popUpMenu("debugStartGameMenuPopUp", items, (itemClicked) => this.onDebugStartGameMenuClick(itemClicked));
        break;
      case "Leaderboard >":
        items = ["Insert Local Player", "New Leader", "Leader to Last", "Swap 2 and 3", "Add 100 points to leader"];
        popUpMenu("debugLeaderboardMenuPopUp", items, (itemClicked) => this.onDebugLeaderboardMenuClick(itemClicked));
        break;
      case "Meta Game > ":
        items = ["Score + 1000", "Score + 100", "Score - 100", "Lose Life", "End Meta Game", "Unlock Life", "Level Tiers", "XP + 100"] ;
        popUpMenu("debugMetaGameMenuPopUp", items, (itemClicked) => this.onDebugMetaGameMenuClick(itemClicked));
        break;
      case "GUI >":
        items = ["Alert", "Double Alert", "Ok/Cancel", "Feedback", "Toast", "Toast - Long", "Toast Custom Width", "Toast Sticky", "Toast Sticky Clear", "TutorialPopUp", "TutorialPopUp Large", "Prompt Reload", "RectangleWithPointerCapture", "ScrollBox", "Carousel", "Missile"];
        popUpMenu("debugGUIMenuPopUp", items, (itemClicked) => this.onDebugGUIMenuClick(itemClicked));
        break;
      case "GUI Utils >":
        items = ["svgToPngDataURI", "imageUrlToPngDataURI", "Share UI"];
        popUpMenu("debugGUIUtilsMenuPopUp", items, (itemClicked) => this.onDebugGUIUtilsMenuClick(itemClicked));
        break;
      case "Help & Ads >":
        items = ["Reset First Time Help", "Help", "Argo Fallback Ad", "Offer Extra Life"];
        popUpMenu("debugHelpAndAdsMenuPopUp", items, (itemClicked) => this.onDebugHelpAndAdsMenuClick(itemClicked));
        break;
      case "Screens >":
        items = ["Spades Round Summary", "Level Up", "Leaderboard", "Game Over Cards"];
        popUpMenu("debugScreensMenuPopUp", items, (itemClicked) => this.onDebugScreensMenuClick(itemClicked));
        break;
      case "Particles & Effects >":
        items = ["BOOM", "Fuse 2d", "Boom Ring"];
        popUpMenu("debugParticlesMenuPopUp", items, (itemClicked) => this.onDebugParticlesAndEffectsMenuClick(itemClicked));
        break;
      case "Sync State >":
        items = ["Move Cards & Resync", "Ping"];
        popUpMenu("debugSyncStateMenuPopUp", items, (itemClicked) => this.onDebugSyncStateMenuClick(itemClicked));
        break;
      case "Settings >":
        items = ["Babylon Debug Layer", "Camera Control"];
        popUpMenu("debugSettingsMenuPopUp", items, (itemClicked) => this.onDebugSettingsMenuClick(itemClicked));
        break;
      case "Misc >":
        items = ["Throw Exception", "Lose WebGL Context", "Game Log"];
        popUpMenu("debugMiscMenuPopUp", items, (itemClicked) => this.onDebugMiscMenuClick(itemClicked));
        break;
      case "Load Test >":
        items = ["Start Monitor", "Stop Test Clients"];
        popUpMenu("debugLoadTestMenuPopUp", items, (itemClicked) => this.onDebugLoadTestMenuClick(itemClicked));
        break;
      case "Store >":
        items = ["Purchase Month Pass"];
        popUpMenu("debugStoreMenuPopUp", items, (itemClicked) => this.onDebugStoreMenuClick(itemClicked));
        break;
      }
    }

    static onDebugAuthMenuClick(item: string) {
      switch(item) {
      }
    }

  static onDebugStartGameMenuClick(item: string) {
    let gameStartQuickPlay: IGameStart = {
      options: config.gameOptions.quickPlay,
      gameContextId: "1234.1234",
    };
    let gameStartClassic: IGameStart = {
      options: config.gameOptions.classic,
      gameContextId: "1235.1235",
    };
    switch(item) {
      case "Start Tutorial":
        let gameStartTutorial: IGameStart = {
          options: config.gameOptions.tutorial,
          tutorial: true,
        };
        Commands.onNewGame(gameStartTutorial);
        break;
      case "Start Joinable QuickPlay":
        Commands.onNewGame(gameStartQuickPlay);
        break;
      case "Join Joinable QuickPlay":
        gameStartQuickPlay.invited = true;
        gameStartQuickPlay.seatId = "2";
        Commands.onNewGame(gameStartQuickPlay);
        break;
      case "Start Joinable Classic":
        Commands.onNewGame(gameStartClassic);
        break;
      case "Join Joinable Classic":
        gameStartClassic.invited = true;
        Commands.onNewGame(gameStartClassic);
        break;
      case "Start Hand Challenge":
        let gameStartHandChallenge: IGameStart = {
          options: { name: config.gameOptions.handChallenge.name },
          minPiles: {
            h0: {p: [52, 40, 1, 50, 35, 7, 31, 22, 9, 16, 30, 33, 17]},
            h1: {p: [49, 48, 25, 45, 46, 36, 39, 21, 11, 15, 18, 23, 20]},
            h2: {p: [42, 51, 12, 8, 34, 3, 37, 14, 2, 10, 28, 32, 6]},
            h3: {p: [41, 43, 5, 44, 47, 4, 29, 19, 13, 24, 27, 38, 26]},
          },
          seatId: "0",
          dealerSeatId: "0",  // spades only
          passDirection: "across",  // hearts only
        };
        Commands.onNewGame(gameStartHandChallenge);
        break;
      case "Start Classic Stand Alone":
        let gameStartClassicStandAlone: IGameStart = {
          options: config.gameOptions.classicStandAlone,
        };
        Commands.onNewGame(gameStartClassicStandAlone);
        break;

      case "Start Moon Deal":
        let gameStartMoon: IGameStart = {
          options: { name: config.gameOptions.quickPlayStandAlone.name },
          minPiles: {
            h0: {p: [40, 1, 2, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52]},
            h1: {p: [3, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39]},
            h2: {p: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]},
            h3: {p: [41, 42, 27, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]},

            // Copied from SGameDeck.cpp
            // XXX - This deck seems to assume, at least without passing, that taking QS with AS will break hearts, but that doesn't work here
            /*
            h0: {p: [52, 51, 50, 49, 48, 47,  7,  6, 27,  4, 14,  1, 40]},
            h1: {p: [26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 41,  2]},
            h2: {p: [39, 38, 37, 36, 35, 34, 33, 32, 43, 42, 29, 28,  5]},
            h3: {p: [13, 12, 11, 10,  9,  8, 46, 45, 44, 31, 30, 15,  3]},
            */
          },
          seatId: "0",
        };
        Commands.onNewGame(gameStartMoon);
        break;

      case "Start Bot Moon Deal":
        let gameStartBotMoon: IGameStart = {
          options: { name: config.gameOptions.quickPlayStandAlone.name },
          // Same as Start Moon Deal but with h0 and h1 swapped
          minPiles: {
            h0: {p: [3, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39]},
            h1: {p: [40, 1, 2, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52]},
            h2: {p: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]},
            h3: {p: [41, 42, 27, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]},
          },
          seatId: "0",
        };
        Commands.onNewGame(gameStartBotMoon);
        break;

        case "Start Sun Deal":
          let gameStartSun: IGameStart = {
            options: { name: config.gameOptions.quickPlayStandAlone.name },
            minPiles: {
              /*h0: {p: [1, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 40]},
              h1: {p: [28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 27]},
              h2: {p: [2,  3,  4,  5,  6,  7,  8,  9,  10, 11, 12, 13, 41]},
              h3: {p: [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 14]},
              */
              /*h0: {p: [27, 1, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39]},
              h1: {p: [40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52]},
              h2: {p: [ 28,  2,  3,  4,  5,  6,  7,  8,  9, 10, 11, 12, 13]},
              h3: {p: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]},
              */
              h0: {p: [40, 1, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52]},
              h1: {p: [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39]},
              h2: {p: [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26]},
              h3: {p: [41, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]},
              // Copied from SGameDeck.cpp (This one gives seat 0 all of the spades, so they can shoot the sun without any hearts)
              // XXX - This deck assumes passing, it needs AC moved to h0 (in place of 2S) to work without passing, in fact it doesn't work in the current rogue hears either (got passed 3 diamonds)
              /*
              h0: {p: [39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27]},
              h1: {p: [52, 51, 50, 49, 48, 47, 46, 45, 44, 43, 42, 41, 40]},
              h2: {p: [13, 12, 11, 10,  9,  8,  7,  6,  5,  4,  3,  2,  1]},
              h3: {p: [26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14]},
              */
            },
            seatId: "0",
          };
          Commands.onNewGame(gameStartSun);
          break;

        case "Start TRAM Deal":
          let gameStartTram: IGameStart = {
            options: { name: config.gameOptions.quickPlay.name },
            minPiles: {
              // Copied from SGameDeck.cpp
              // This one seems to be set up to lose a few hands, then TRAM
              h0: {p: [39, 38, 37, 36, 35, 34, 46, 45, 26, 25, 52, 51, 27]},
              h1: {p: [42, 41, 50, 49, 48, 47, 20, 19,  5,  4, 29, 28, 40]},
              h2: {p: [13, 12, 11, 10,  9,  8,  7,  6, 31, 30,  3,  2,  1]},
              h3: {p: [44, 43, 24, 23, 22, 21, 33, 32, 18, 17, 16, 15, 14]},
            },
            seatId: "0",
          };
          Commands.onNewGame(gameStartTram);
          break;
    }
  }

  static onDebugLeaderboardMenuClick(item: string) {
    if(process.env.NODE_ENV !== "development")
      return;

    let rootState = getRootState();
    let readOnlyEntries = rootState.leaderboards.boards[0].entries;

    // Create a modifiable entries snapshot
    let entries: IEntryState[] = [];
    for(let readOnlyEntry of readOnlyEntries) {
      entries.push({
        rank: readOnlyEntry.rank,
        score: readOnlyEntry.score,
        name: readOnlyEntry.name,
        imageUrl: readOnlyEntry.imageUrl,
        serviceUserId: readOnlyEntry.serviceUserId,
        userId: readOnlyEntry.userId,
      });
    }

    // Sort by rank so the hacks work right
    entries.sort((a, b) => a.rank - b.rank);

    let score = 0;
    let name = "";
    let e: IEntryState = null;
    let rank = 0;

    switch(item) {
      case "Insert Local Player":
        // already inserted?
        let index = entries.findIndex((v) => v.userId === rootState.user.id);
        if(index >= 0) {
          // Remove the player so we can re-rank them easily
          rank = entries[index].rank;
          entries.splice(index, 1);
          for(let entry of entries) {
            if(entry.rank >= rank)
              entry.rank = entry.rank - 1;
          }
        }

        // Find insertion point
        index = entries.findIndex((v) => v.score < rootState.user.metaGame.score);
        if(index < 0)
          index = entries.length;
        rank = index + 1;

        // Increase any rank >= the player
        for(let entry of entries) {
          if(entry.rank >= rank)
            entry.rank = entry.rank + 1;
        }

        // Insert the player
        entries.splice(index, 0, {
          rank: rank,
          score: rootState.user.metaGame.score,
          name: rootState.user.name,
          imageUrl: rootState.user.imageUrl,
          serviceUserId: "0",
          userId: rootState.user.id,
        });
        break;
      case "New Leader":
        // Increase each rank, and save the top score
        for(let entry of entries) {
          entry.rank = entry.rank + 1;
          if(entry.score > score) {
            score = entry.score;
            name = entry.name;
          }
        }
        // New leader
        entries.push({
          rank: 1,
          score: score + 1,
          name: name + "+",
          imageUrl: pawn0AvatarUrl,
          serviceUserId: "0",
          userId: "" + Math.random(),
        });
        break;
      case "Leader to Last":
        // Decrease each rank, and save the bottom score
        score = 1e6;
        for(let entry of entries) {
          entry.rank = entry.rank - 1;
          if(entry.score < score) {
            score = entry.score;
          }
        }
        e = entries.splice(0, 1)[0];
        e.rank = entries.length + 1;
        e.score = score - 1;
        entries.push(e);
        break;
      case "Swap 2 and 3":
        e = entries.splice(1, 1)[0];
        entries.splice(2, 0, e);
        rank = entries[1].rank;
        score = entries[1].score;
        entries[1].rank = entries[2].rank;
        entries[1].score = entries[2].score;
        entries[2].rank = rank;
        entries[2].score = score;
        break;
      case "Add 100 points to leader":
        for(let entry of entries) {
          if(entry.rank === 1) {
            entry.score = entry.score + 100;
            break;
          }
        }
        break;
      default:
        return;
    }

    // Update entries
    rootState.leaderboards.boards[0].importEntries(entries);
  }

  static showLevelTiersBox() {
    let lineHeight = game.getScale() * 60;
    let width = lineHeight * 4;
    let height = lineHeight * config.levelTierColors.length;

    let box = new Rectangle("LevelTiersBox");
    box.width = width + "px";
    box.height = height + "px";
    box.background = "grey";
    box.thickness = 0;
    box.zIndex = zIndex.DEBUG_GUI;
    box.onPointerClickObservable.add(() => box.dispose());
    game.guiTexture.addControl(box);

    let top = 0;

    for(let tierColor of config.levelTierColors) {
      let line = new TextBlock("LevelTiersBoxLevel" + tierColor.level);
      line.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      line.top = top;
      line.width = width + "px";
      line.height = lineHeight + "px";
      line.fontSize = (lineHeight * 0.8) + "px";
      line.color = tierColor.color;
      line.text = `Level ${tierColor.level}`;
      box.addControl(line);

      top += lineHeight;
    }
  }

  static onDebugMetaGameMenuClick(item: string) {
    switch(item) {
      case "Score + 1000":
        game.rootState.user.metaGame.addToScore(game.rootState.user.id, 1000);
        break;
      case "Score + 100":
        game.rootState.user.metaGame.addToScore(game.rootState.user.id, 100);
        break;
      case "Score - 100":
        game.rootState.user.metaGame.addToScore(game.rootState.user.id, -100);
        break;
      case "Lose Life":
        game.rootState.user.metaGame.loseLife(game.rootState.user.id);
        break;
      case "Unlock Life":
        game.rootState.user.metaGame.unlockLife(game.rootState.user.id);
        break;
      case "End Meta Game":
        game.rootState.user.metaGame.endMetaGame(game.rootState.user.id);
        break;
      case "Level Tiers":
        this.showLevelTiersBox();
        break;
      case "XP + 100":
        game.rootState.user.xp.earnXP(100);
        break;
    }
  }

  static onDebugGUIMenuClick(item: string) {
    switch(item) {
      case "Alert":
        game.modalDialogSystem.showAlert("Debug Alert Modal", "OMG!");
        break;
      case "Double Alert":
        game.modalDialogSystem.showAlert("Debug Alert Modal 1", "First!");
        game.modalDialogSystem.showAlert("Debug Alert Modal 2", "Second!");
        break;
      case "Ok/Cancel":
        game.modalDialogSystem.showOkCancel("Debug Ok/Cancel Modal", "Push the Big Red Button?", "PUSH IT", "NO!!!").then((response) => {
            alert(JSON.stringify(response, null, 2));
        });
        break;
      case "Feedback":
        game.modalDialogSystem.showModal(feedbackModalUrl).then( (response) => {
          alert(JSON.stringify(response, null, 2));
        });
        break;
      case "Toast":
        toast("ShortToast", "Short toast, 4 seconds");
        break;
      case "Toast - Long":
        toast("LongToast", "This message will self-destruct in 7 seconds instead of 4 seconds because it is really long.  It's also probably 2 or 3 lines tall.");
        break;
      case "Toast Custom Width":
        toast("CustomToast", "Message should take up about 2/3 of the screen width", game.guiTexture.getSize().width * 0.667);
        break;
      case "Toast Sticky":
        toast("StickyToast", "Message will stay visible until you click Toast Sticky Clear", 0, -1);
        break;
      case "Toast Sticky Clear":
        disposeToast("StickyToast");
        break;
      case "TutorialPopUp":
          let tpu = new TutorialPopUp("TutoralPopUp");
          tpu.setMessage("This is a message in the tutorial pop up.", true, true, true);
          game.guiTexture.addControl(tpu);
          tpu.init();
          break;
      case "TutorialPopUp Large":
          let tpul = new TutorialPopUp("TutoralPopUp");
          const message = "This is a message in the tutorial pop up. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ut pharetra sem. Duis rutrum tincidunt consequat. In hac habitasse platea dictumst. Ut nec posuere felis, a lobortis lacus. Donec in urna id neque finibus scelerisque. Sed fringilla gravida urna, eu semper libero ornare in. Nam posuere, magna sit amet pellentesque cursus, nulla sem viverra tellus, non auctor nisl quam nec lectus. Integer gravida elit vitae pharetra pulvinar.";
          tpul.setMessage(message, true, true, true);
          game.guiTexture.addControl(tpul);
          tpul.init();
          break;
      case "Prompt Reload":
        promptReload();
        break;
      case "RectangleWithPointerCapture":
        let rectangleWithPointerCapture = new RectangleWithPointerCapture("DebugMenuPopUpRectangleWithPointerCapture");
        rectangleWithPointerCapture.width = "200px";
        rectangleWithPointerCapture.height = "200px";
        rectangleWithPointerCapture.background = "yellow";
        rectangleWithPointerCapture.color = "red";
        game.guiTexture.addControl(rectangleWithPointerCapture);
        let rectangleWithPointerCaptureText = new TextBlock("DebugMenuPopUpRectangleWithPointerCaptureTextBlock");
        rectangleWithPointerCapture.addControl(rectangleWithPointerCaptureText);
        rectangleWithPointerCapture.onPointerMoveObservable.add((v) => rectangleWithPointerCaptureText.text = `${v.x}, ${v.y}`);
        break;
      case "ScrollBox":
        let sb = new ScrollBox("DebugMenuPopUpScrollBox");
        sb.width = "500px";
        sb.height = "500px";
        sb.background = "magenta";
        game.guiTexture.addControl(sb);
        let sbContents = new Rectangle("DebugMenuPopUpScrollBoxContents");
        sbContents.width = "1000px";
        sbContents.height = "1000px";
        sbContents.background = "white";
        sbContents.thickness = 0;
        sb.addControl(sbContents);
        for(let sbY = 0; sbY < 10; sbY++) {
          for(let sbX = 0; sbX < 10; sbX++) {
            let sbt = new TextBlock(`DebugMenuPopUpScrollBox_${sbX}_${sbY}`);
            sbt.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
            sbt.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
            sbt.left = sbX * 100;
            sbt.top = sbY * 100;
            sbt.width = "100px";
            sbt.height = "100px";
            sbt.text = `(${sbX}, ${sbY})`;
            sbContents.addControl(sbt);
          }
        }
        break;
      case "Carousel":
        let carousel = new Carousel("DebugMenuPopUpCarousel");
        game.guiTexture.addControl(carousel);
        for(let color of ["red", "green", "blue"]) {
          let page = carousel.addPage(name + "Page_" + color);
          page.background = color;
        }
        break;
      case "Missile":
        debugMissileControl();
        break;
    }
  }

  static onDebugGUIUtilsMenuClick(item: string) {
    let width = 0;
    let height = 0;
    let imageUrl = "";
    let svg = "";

    switch(item) {
      case "svgToPngDataURI":
        width = 200;
        height = 200;
        imageUrl = pawn0AvatarDataUrl;
        svg = `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style> .small { font: italic 13px sans-serif; } .heavy { font: bold 30px sans-serif; } .Rrrrr { font: italic 40px serif; fill: red; }</style><image xlink:href="${imageUrl}" width="${width}" height="${height}"/><text x="5" y="155" class="small">My</text><text x="25" y="155" class="heavy">cat</text><text x="40" y="175" class="small">is</text><text x="50" y="175" class="Rrrrr">Grumpy!</text></svg>`;
        svgToPngDataURI(svg).then((uri: string) => game.modalDialogSystem.showAlert("svgToPngDataURI", `<img src="${uri}" />`));
        break;
      case "imageUrlToPngDataURI":
        width = 200;
        height = 200;
        imageUrlToPngDataURI(pawn0AvatarUrl).then((dataURI) => {
          imageUrl = dataURI;
          svg = `<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style> .small { font: italic 13px sans-serif; } .heavy { font: bold 30px sans-serif; } .Rrrrr { font: italic 40px serif; fill: red; }</style><image xlink:href="${imageUrl}" width="${width}" height="${height}"/><text x="5" y="155" class="small">My</text><text x="25" y="155" class="heavy">cat</text><text x="40" y="175" class="small">is</text><text x="50" y="175" class="Rrrrr">Grumpy!</text></svg>`;
          svgToPngDataURI(svg).then((uri: string) => game.modalDialogSystem.showAlert("imageUrlToPngDataURI", `<img src="${uri}" />`));
        });
        break;
      case "Share UI":
        Commands.onShareUI(["leaderboardRank", "levelUp", "bid", "bid-alt", "shot_moon", "shot_sun"]);
        break;
    }
  }

  static onDebugHelpAndAdsMenuClick(item: string) {
    switch(item) {
      case "Reset First Time Help":
        getRootState().user.help.resetSeenCnt(getRootState().user.id);
        break;
      case "Help":
        Commands.onHelp();
        break;
      case "Argo Fallback Ad":
        Commands.onShowAd("fallback", true, (status) => game.modalDialogSystem.showAlert("AdResponse", status));
        break;
        case "Offer Extra Life":
          (game.systems.get("UserStatusSystem") as UserStatusSystem).offerExtraLife();
          break;
      }
  }

  static onDebugScreensMenuClick(item: string) {
    switch(item) {
      case "Spades Round Summary":
        (game.systems.get("SpadesRoundSummary") as SpadesRoundSummary).test();
        break;
      case "Level Up":
        LevelUpScreen.showLevelUpScreen();
        break;
      case "Leaderboard":
        LeaderboardScreen.showLeaderboardScreen(true);
        break;
      case "Game Over Cards":
        GameOverCardScreen.showGameOverCardScreen();
        break;
    }
  }

  static onDebugParticlesAndEffectsMenuClick(item: string) {
    switch(item) {
      case "BOOM":
        game.argoParticleSystem.boom("DebugParticlesBoomParticleSystem");
        break;
      case "Fuse 2d":
        let particleSystem = new Argo2dParticleSystem("DebugParticlesFuseParticleSystem");

        particleSystem.disposeOnStop = true;
        particleSystem.start();

        let guiWidth = game.guiTexture.getSize().width;
        let guiHeight = game.guiTexture.getSize().height;

        let k0 = 0;
        let k1 = 300;

        let positionAnimation = new Animation("DebugParticlesFuseAnimation", "position", 30.0, Animation.ANIMATIONTYPE_VECTOR2, Animation.ANIMATIONLOOPMODE_CONSTANT);
        let positionKeys = [];
        positionKeys.push({ frame: k0, value: new Vector2(0, 0) });
        positionKeys.push({ frame: k1, value: new Vector2(guiWidth, guiHeight) });
        positionAnimation.setKeys(positionKeys);

        let proxy = new GUIAnimationProxy(particleSystem.emitterControl);

        game.scene.beginDirectAnimation(proxy, [positionAnimation], 0, k1, false, 1, () => particleSystem.stop());

        break;
      case "Boom Ring":
        (game.systems.get("TurnIndicatorSystem") as TurnIndicatorSystem).boom();
        break;
    }
  }

  static onDebugSyncStateMenuClick(item: string) {
    switch(item) {
      case "Move Cards & Resync":
        // Move all cards to the stock
        let stock = game.findPile("stock");
        let cards = game.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Card");
        for (let card of cards) {
          game.cardSystem.reparent(card, stock);
          card.position = new Vector3(0, 0, 0);
          card.rotationQuaternion = new Quaternion(0, 0, 0, 1);
        }
        // Resync the state
        game.gameState.syncState(game.gameState.id);
        break;
      case "Ping":
        game.gameState.ping(getRootState().user.id);
        setInterval(() => game.gameState.ping(getRootState().user.id), 5000);
        break;
    }
  }

  static onDebugSettingsMenuClick(item: string) {
    switch(item) {
      case "Babylon Debug Layer":
        if(game.scene.debugLayer === undefined) {
          game.modalDialogSystem.showAlert("Debug Layer", "To enable the Babylon JS DebugLayer Inspector, uncomment <br><br><pre>import \"@babylonjs/inspector\"</pre> in Game.ts.");
          return;
        }

        if(game.scene.debugLayer.isVisible())
          game.scene.debugLayer.hide();
        else
          game.scene.debugLayer.show();
        break;
      case "Camera Control":
        game.setDebugCameraControl(!game.debugCameraControl);
        game.modalDialogSystem.showAlert("Debug Camera Control", game.debugCameraControl ? "Enabled" : "Disabled");
        break;
    }
  }

  static onDebugMiscMenuClick(item: string) {
    switch(item) {
      case "Throw Exception":
        throw new Error("Test Exception");
        break;
      case "Lose WebGL Context":
        game.debugLoseWebGLContext();
        break;
      case "Game Log":
        game.modalDialogSystem.showAlert("GameLog", JSON.stringify(logger.getGameLog(), null, 2));
        break;
    }
  }

  static onDebugLoadTestMenuClick(item: string) {
    let monitor = game.systems.get("LoadTestMonitorSystem") as LoadTestMonitorSystem;
    if(!monitor) {
      monitor = new LoadTestMonitorSystem(game) as LoadTestMonitorSystem;
      game.systems.set("LoadTestMonitorSystem", monitor);
      monitor.init();
    }

    switch(item) {
      case "Start Monitor":
        break;
      case "Stop Test Clients":
        monitor.stopClients();
        break;
    }
  }

  static onDebugStoreMenuClick(item: string) {
    switch(item) {
      case "Purchase Month Pass":
        Commands.onPurchaseOffer("insta_pass_1_month");
        break;
    }
  }

  static genRandomParagraphs() {
    let s = "";

    for(let paragraph = 0; paragraph < 5; paragraph++) {
      let wordCount = Math.floor(Math.random() * 500) + 500;
      s += "<p>";
      for(let word = 0; word < wordCount; word++) {
        let r = Math.floor(Math.random() * 100);
        if(r === 0)
          s += "X ";
        else if(r < 10)
          s += "XX ";
        else if(r < 75)
          s += "XXX ";
        else if(r < 90)
          s += "XXXX ";
        else
          s += "XXXXX ";
      }
      s += "</p>";
    }

    return s;
  }

  static genRandomParagraphsDataUrl() {
    let s = this.genRandomParagraphs();
    return "data:text/html;charset=utf-8;base64," + btoa(s);
  }
}
