import { ArgoSystem } from "components/game/ArgoSystem";
import { GAME_STATE_GAME_OVER, GAME_STATE_START } from "states/game/GameState";
import { ROOT_STATE_GAME, ROOT_STATE_LOADED } from "states/RootState";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";
import { META_GAME_OVER } from "states/user/MetaGameState";
import { config } from "utils/Config";
import { initTime } from "utils/init";
import { ERROR, FATAL, logger } from "utils/logger";

/** System to listen for game events and report them to analytics */
export class AnalyticsSystem extends ArgoSystem {
  channel: any;

  init() {
    // send analytics event to facebook when ever we send an event to sentry
    logger.beforeSendObservable.add((event: any) => {
      if(event.level === ERROR || event.level === FATAL)
        config.analytics.logEvent("Argo Error Reported");
    });

    this.rootState.router.addRoute("^\/status$", (patch: any, reversePatch: any, params: any) => this.onRootStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.onGameStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/user\/metaGame\/status$", (patch: any, reversePatch: any, params: any) => this.onMetaGameStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/user\/xp\/level$", (patch: any, reversePatch: any, params: any) => this.onXPLevelChanged(patch, reversePatch, params));
  }

  /** Internal function that watches for the RootState.status to change to loaded */
  onRootStatusChanged(patch: any, reversePatch: any, params: any) {
    if (patch.value === ROOT_STATE_LOADED) {
      let now = new Date();
      let loadTime = (now.getTime() - initTime) / 1000; // time from init till now in seconds
      config.analytics.logEvent("Argo Finished Loading", 0, { loadTime });
    }
    else if(patch.value === ROOT_STATE_GAME) {
      // this.rootState.game.options.name is likely not set yet, so use this.game.gameStart.options.name instead if it is set
      let optionsName = this.rootState.game.options.name;
      if(this.game.gameStart && this.game.gameStart.options && this.game.gameStart.options.name)
        optionsName = this.game.gameStart.options.name;

      config.analytics.logEvent("Argo Joined Game", 0, { options: optionsName, fps: this.game.getFps() }); // they either created a new game, or joined an existing game
    }
  }

  /** Internal function that watches for the GameState.status to change to game over */
  onGameStatusChanged(patch: any, reversePatch: any, params: any) {
    // check if we need to report analytics event
    let event: string;
    let gameOrTutorial = "Game";
    if(this.game.gameState.options.name.includes("tutorial"))
      gameOrTutorial = "Tutorial";

    if (patch.value === GAME_STATE_START)
      event = "Argo Started " + gameOrTutorial;   // "Argo Started Game", or "Argo Started Tutorial"
    else if (patch.value === GAME_STATE_GAME_OVER)
      event = "Argo Finished " + gameOrTutorial;  // "Argo Finished Game", or "Argo Finished Tutorial"

    if(event)
      config.analytics.logEvent(event, 0, { humans: this.rootState.game.getHumanPlayersInSeatsCnt(), options: this.rootState.game.options.name, fps: this.game.getFps() });
  }

  /** Internal function that watches for the RootState.user.metaGame.status to change */
  onMetaGameStatusChanged(patch: any, reversePatch: any, params: any) {
    // meta game is over when we run out of lives
    if (patch.value === META_GAME_OVER) {
      config.analytics.logEvent("Argo Finished MetaGame");
    }
  }

  /** Internal function that watches for the RootState.user.xp.level to change */
  onXPLevelChanged(patch: any, reversePatch: any, params: any) {
    // report xp level up to analytics, but be sure this is a notification in response to the level being incremented 1, and it is not the initial load
    // we only ever want to send the Argo XP Level Up once per level.
    if(patch.value > 1 && reversePatch.value + 1 === patch.value && this.rootState.user.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE) {
      config.analytics.logEvent("Argo XP Level Up", 0, { level: patch.value});
    }
  }
}
