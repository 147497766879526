import { logger } from "utils/logger";

// serverOffset is the difference in time in ms between the server and client time.
// If the clients time is set correctly then serverOffset should be very small, since both serverTime and Date.now are a UTC timestamp
let serverOffset = 0;

/** set the last known time on the server. Calculates serverOffset. */
export function setServerTime(serverTime: number) {
  serverOffset = serverTime - Date.now();
  logger.info("Setting Server Time", { clientTime: Date.now(), serverTime, serverOffset });
}

/** Using the client time and serverOffset return the approximate time on the server in ms.
 * This is not super accurate. We could use an NTP/Clock Syncronization library to make it more accurate.
 * This is used on both the client and server, on the server serverOffset should always be 0.
 */
export function serverNow() {
  return Date.now() + serverOffset;
}
