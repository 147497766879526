import { config, mergeConfig } from "utils/Config";
import "../../game-config";

mergeConfig({
  gameOptions: {
    classic: {
      endScore: 500,
    },
  },
});

 // set default game options
mergeConfig({
  gameOptions: {
    default: config.gameOptions.quickPlay,
  },
});
