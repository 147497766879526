export enum PurchaseResult {
  // Errors
  PURCHASE_RESULT_ERROR = "error", // An error occurred making the payment
  PURCHASE_RESULT_CANCELLED = "canceled", // This corresponds to Facebook's USER_INPUT error, which apparenlty isn't always a cancellation

  // Success
  PURCHASE_RESULT_PURCHASED = "purchased",
}

/** Parameters for Commands.onPurchaseOfferObservable  */
export interface IPurchaseParams {
  offerName: string;
  onDoneCallback: (result: PurchaseResult) => void;
}
