import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Axis } from "@babylonjs/core/Maths/math";
import { Quaternion } from "@babylonjs/core/Maths/math";
import { Color4 } from "@babylonjs/core/Maths/math";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { Mesh } from "@babylonjs/core/Meshes/mesh";

import { TrickGameAI } from "ai/TrickGameAI";

import { DealHand } from "components/game/animations/DealHand";
import { TrickGameAnimation } from "components/game/animations/TrickGameAnimation";
import { UpAndOverAnimation } from "components/game/animations/UpAndOverAnimation";
import { Game } from "components/game/Game";
import { PlayerSystem } from "components/game/PlayerSystem";
import { TurnIndicatorSystem } from "components/game/TurnIndicatorSystem";

import { MenuBar } from "components/ui/MenuBar";
import { toast } from "components/utils/GUI";

import { GAME_STATE_BID, GAME_STATE_DEAL, GAME_STATE_PASS, GAME_STATE_PLAY } from "states/game/GameState";
import { ITrickGameState } from "states/game/TrickGameState";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";

import { config } from "utils/Config";

const HIGHLIGHT_WINNING_CARD_COLOR = Color4.FromHexString("#00ff547F"); // minty green
const HIGHLIGHT_HINTED_CARD_COLOR = Color4.FromHexString("#ffff007F"); // yellow

export class TrickGame extends Game {
  prePlayedPieceName: string = null;
  stockPile: Mesh;
  localSeatAI: TrickGameAI;
  gameState: ITrickGameState; // overrides Game.gameState to set game specific type
  pieceValueSortFunction: (a: number, b: number) => number = null;

  initSystems() {
    // be sure to register UpAndOverAnimation before TrickGameAnimation, so that TrickGameAnimation will be the one registered for ANIMATION_MOVE_PIECE
    this.systems.set("UpAndOverAnimation", new UpAndOverAnimation(this));
    this.systems.set("TrickGameAnimation", new TrickGameAnimation(this));
    this.systems.set("DealHand", new DealHand(this));
    this.systems.set("PlayerSystem", new PlayerSystem(this));
    this.systems.set("TurnIndicatorSystem", new TurnIndicatorSystem(this));

    super.initSystems();
  }

  createPiles(): void {
    this.gameState.init(); // create piles, teams, seats in gameState

    // stock
    this.stockPile = this.pileSystem.createEmptyMesh(this.gameRootTransformNode, "stack", this.gameState.getPile("stock"));
    this.stockPile.position.z = -0.25;

    // create cards in stock
    this.gameState.getPile("stock").pieces.forEach((piece) => {
      let card = this.cardSystem.createMesh(this.gameRootTransformNode, piece);
      card.parent = this.stockPile;
    });
    this.pileSystem.layout(this.stockPile);

    // trick
    let trickPile = this.pileSystem.createEmptyMesh(this.gameRootTransformNode, "trick", this.gameState.getPile("trick"));
    trickPile.position.x = 0;
    trickPile.position.z = 0;

    // Hands 0=south, 1=west(left), 2=north, 3=east(right)
    for (let pileIndex = 0; pileIndex < 4; pileIndex++) {
      let layout = "hand";

      // hand
      let pile = this.pileSystem.createEmptyMesh(this.gameRootTransformNode, layout, this.gameState.getPile("hand" + pileIndex));

      // Set cmp function for sorting the hand
      pile.metadata.sort = this.pieceValueSortFunction;

      // waste
      let wastePile = this.pileSystem.createEmptyMesh(this.gameRootTransformNode, "stack", this.gameState.getPile("waste" + pileIndex));
      wastePile.metadata.visibility = 0;
    }
  }

  /** Create 2D GUI buttons and controls */
  createGUI() {
    MenuBar.create();
  }

  createGameOverGUI() {
    //SolitaireGameOver.create();
  }

  disposeGameOverGUI(): void {
    //SolitaireGameOver.dispose();
  }

  layout() {

    let distance = 2.5;
    let opponentDistance = 2.5;
    let wasteDistance = 5;

    let cardMiddle = 1.28 * 0.5;

    let angleEastWest = 60.0;
    let eastWestZ = 0;

    let offset = parseInt(this.getSouthSeat());

    for(let index = 0; index < 4; index++) {
      let pile = this.findPile("hand" + index);
      let wastePile = this.findPile("waste" + index);

      let position = (index + 4 - offset) % 4;

      // South
      if(position === 0) {
        pile.metadata.layout = "fan";

        pile.position.x = 0;
        pile.position.y = cardMiddle;
        pile.position.z = -distance;

        let cameraAngle = config.cameraAngle;
        let handAngle = 90 - cameraAngle;

        let q = new Quaternion();
        q.multiplyInPlace(Quaternion.RotationAxis(Axis.X, handAngle * Math.PI / 180.0));
        pile.rotationQuaternion = q;

        wastePile.position.x = 0;
        wastePile.position.z = -wasteDistance;
      }

      // West
      else if(position === 1) {
        pile.metadata.layout = "hand";

        pile.position.x = -opponentDistance;
        pile.position.y = cardMiddle;
        pile.position.z = eastWestZ;

        let q = new Quaternion();
        q.multiplyInPlace(Quaternion.RotationAxis(Axis.Y, -angleEastWest * Math.PI / 180.0));
        pile.rotationQuaternion = q;

        wastePile.position.x = -wasteDistance;
        wastePile.position.z = pile.position.z;
        wastePile.rotation.z = -90.0 * Math.PI / 180.0;
      }

      // North
      else if(position === 2) {
        pile.metadata.layout = "hand";

        pile.position.x = 0;
        pile.position.y = cardMiddle;
        pile.position.z = opponentDistance;

        let q = new Quaternion();
        //q.multiplyInPlace(Quaternion.RotationAxis(Axis.Y, 30.0 * Math.PI / 180.0));
        pile.rotationQuaternion = q;

        wastePile.position.x = pile.position.x;
        wastePile.position.z = wasteDistance;
        wastePile.rotation.z = 180.0 * Math.PI / 180.0;
      }

      // East
      else if(position === 3) {
        pile.metadata.layout = "hand";

        pile.position.x = opponentDistance;
        pile.position.y = cardMiddle;
        pile.position.z = eastWestZ;

        let q = new Quaternion();
        q.multiplyInPlace(Quaternion.RotationAxis(Axis.Y, angleEastWest * Math.PI / 180.0));
        pile.rotationQuaternion = q;

        wastePile.position.x = wasteDistance;
        wastePile.position.z = pile.position.z;
        wastePile.rotation.z = 90.0 * Math.PI / 180.0;
      }

      this.pileSystem.layout(pile);
    }

    // Also layout trick
    let trick = this.findPile("trick");
    this.pileSystem.layout(trick);
  }

  onApplySnapshotStage(patch: any, reversePatch: any, params: any) {
    super.onApplySnapshotStage(patch, reversePatch, params);

    // refresh a few things after a snapshot is done being applied
    if(patch.value === APPLY_SNAPSHOT_STAGE_DONE) {
      this.setHandActive();
      if(this.localSeatAI && this.localSeat)
        this.localSeatAI.setSeat(this.localSeat);
      (this.systems.get("TurnIndicatorSystem") as TurnIndicatorSystem).onSeatsTurnChanged(); // seatsTurn can be set before localSeat
    }

    this.layout();
  }

  /** onGameStatusChanged called when this.rootState.game.status changes. */
  onGameStatusChanged(patch: any, reversePatch: any, params: any) {
    super.onGameStatusChanged(patch, reversePatch, params);

    if (patch.value === GAME_STATE_BID && this.gameState.options.name === config.gameOptions.handChallenge.name)
      toast("HandChallengeToast", "Playing a copy of your friend's hand.");
  }

  onSeatsTurnChanged(patch: any, reversePatch: any, params: any) {
    super.onSeatsTurnChanged(patch, reversePatch, params);
    this.setHandActive();
  }

  onPieceChanged(patch: any, reversePatch: any, params: any) {
    if(this.prePlayedPieceName !== null && patch.op === "add") {
      // We pre-played a card, so we're watching for when the actual play arrives
      if(patch.value.name !== this.prePlayedPieceName) {
        // This is not the expected play, maybe auto-play triggered before the local play reached the server
        // We need to undo the pre-play
        let card = this.scene.getMeshByName(this.prePlayedPieceName);
        let hand = this.findPile("hand" + this.localSeat);
        if(card && hand) {
          // The problem here is that the gameState does not match the scene pile state
          // If we try to layout here, the layout will fail
          // If we do nothing, the layout will happen as a result of the move
          // And the card will animate back to the hand
          // If we want to avoid animating the card back to the hand
          // it would help considerably to separate the gameState from the scene state
          // so that layout can occur without referring to the gameState
          // Fortunally, this shouldn't come up often
          this.cardSystem.reparent(card, hand);
        }
      }
      // Clear the pre-play
      this.prePlayedPieceName = null;
    }

    super.onPieceChanged(patch, reversePatch, params);
  }

  clickPiece(playerId: string, seatId: string, pileName: string, pieceName: string) {
    super.clickPiece(playerId, seatId, pileName, pieceName);
    // be sure we're actually a player in the game, and not a watcher
    if(!this.localSeat)
      return;

    // If we don't have haveStateSync, the piece is being played localy immediately
    if(!config.haveStateSync)
      return;

    // Currently we don't have passing, but let's make sure we're in the Play state anyway
    if(this.gameState.status !== GAME_STATE_PLAY)
      return;

    // This can cause cards to pile up on top of the previous hand, so let's wait for animations
    if(this.animationSystem.isBlockingPlay())
      return;

    // super.clickPiece sent a request to the server
    // We assume the click will result in a move because canClickPiece said so before this function was called
    // We want to animate the card move now instead of waiting
    // Because this is Spades, the only valid move is from whichever hand the card is in to the trick
    // So our assumption is that the card will move to the trick
    // Also, because the card is from South Hand, which is face up, we assume no facing change will be made

    let trick = this.findPile("trick");
    let piece = this.scene.getMeshByName(pieceName); // This is a linear search through all meshes in scene
    if(piece && piece.metadata.value) {
      // Record the name of the played piece
      this.prePlayedPieceName = piece.name;

      // Re-layout source pile to close gap
      piece.parent.metadata.needsLayout = true;

      // Move the card to the trick
      this.cardSystem.reparent(piece, trick);
      this.pileSystem.layout(trick);

      // Advance turn indicator
      let turnIndicatorSystem = this.systems.get("TurnIndicatorSystem") as TurnIndicatorSystem;
      if(turnIndicatorSystem) {
        let isLastCard = (trick.getChildren().length === 4);
        turnIndicatorSystem.preAdvanceTurn(isLastCard);
      }

      // Pause the patch queue to prevent interference when the last card is autoplayed
      this.pausePatchQueueIfAnimating();
      this.finishedTurn = true;
    }

    //console.log("clickPiece(" + playerId + ", " + seatId + ", " + pileName + ", " + pieceName + ")");
  }

  /** Called when a card has finished animating to the trick pile */
  onCardAnimatedToTrick() {
    // If the card that just arrived at the trick is the last card of the hand
    // Highlight the winning card

    // Are there 4 cards in the trick?
    let trickStatePile = this.gameState.getPile("trick");
    if(trickStatePile.pieces.length !== 4)
      return;

    // Get the winning card
    let winningPiece = (this.gameState as ITrickGameState).getTrickWinningPiece();

    // Find the 3d version
    let trickPile = this.findPile("trick");
    let winningCard = this.pileSystem.getPiece(trickPile, winningPiece.name);
    if(!winningCard)
      return;

    this.cardSystem.setHighlighted(winningCard, true, HIGHLIGHT_WINNING_CARD_COLOR);
  }

  onAnimationBlockingChanged(animatable: Animatable) {
    super.onAnimationBlockingChanged(animatable);

    if(this.animationSystem.isBlockingPlay())
      return;

    // Check for the deal animation ending
    if(animatable &&
      animatable.target.metadata !== undefined &&
      animatable.target.metadata !== null &&
      animatable.target.metadata.layoutGameStatus === GAME_STATE_DEAL
      ) {
      (this.systems.get("TurnIndicatorSystem") as TurnIndicatorSystem).boom();
    }
  }

  /** Highlight an AI hint to play a piece */
  setPieceHint(value: number) {
    let meshes = this.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Card" && mesh.metadata.value === value);
    let card = meshes[0];
    this.cardSystem.setHighlighted(card, true, HIGHLIGHT_HINTED_CARD_COLOR);
    this.cardSystem.sparkleEdge(card);
  }

  /** Get the 2d projected position of the top of the south hand. */
  getSouthHandProjectedTopPosition() {
    let hand = this.findPile("hand" + this.getSouthSeat());
    if(!hand)
      return;

    // Try to guess the top of the hand
    hand.computeWorldMatrix();
    let top3d = Vector3.TransformCoordinates(new Vector3(0, 0.64, 0), hand.getWorldMatrix());
    let top2d = this.project(top3d);

    return top2d;
  }

  setHandActive() {
    // Make sure seatsTurn and localSeat is set
    if(this.gameState.seatsTurn === null || this.localSeat === null)
      return;

    // Find the local hand
    let hand = this.findPile("hand" + this.localSeat);

    let seatsTurn = this.gameState.seatsTurn.id;
    if(this.gameState.status === GAME_STATE_PLAY && this.localSeat === seatsTurn) {
      // Set local hand cards invalid when they are not currently legal for play
      for(let card of hand.getChildMeshes()) {
        let bActive = this.gameState.canClickPiece(this.rootState.user.id, this.localSeat, hand.name, card.name);
        this.cardSystem.setActive(card, bActive);
      }
    } else {
      // Ensure all cards in the local hand are valid
      for(let card of hand.getChildMeshes()) {
        this.cardSystem.setActive(card, true);
      }
    }
  }

  setAutoPlayLocalSeat(autoPlay: boolean) {
    super.setAutoPlayLocalSeat(autoPlay);

    if(autoPlay) {
      if(this.localSeatAI) {
        this.localSeatAI.start();
        let localSeat = this.gameState.getSeat(this.localSeat);

        if(this.gameState.seatsTurn) {
          let seatsTurn = this.gameState.seatsTurn.id;
          if(this.localSeat === seatsTurn)
              this.localSeatAI.playTurn();
        }
        else if(this.gameState.status === GAME_STATE_PASS && !localSeat.ready)
          this.localSeatAI.playTurn();
      }
      else
        this.localSeatAI = new TrickGameAI(this.localSeat, this.gameState);
    }
    else if(this.localSeatAI)
      this.localSeatAI.stop();
  }
}
