/**
 * Results object for getWonTricksForSeat
 * List of tricks won by the seat, as well as an entry for a successful nil bid, if needed
 * The list is used in PlayerSystem for upating players, and especially in the SpadesRoundSummary
 * Each won trick has a type, indicating the meaning of the won trick in the game, and a points value indicating the points contributed to the score
 * Types:
 *   won - the trick is a normal contribution to a successfully made bid
 *   partner - the trick is an overtrick from the partner, but contributed to this seat's total because this seat had not yet made it's bid
 *   miss - the trick is a normal contribution to a failed bid
 *   bag - the trick is an overtrick after the bid was made
 *   nil - a fake won trick indicating a successful nil bid
 *   fail - the trick, which is also a bag, caused a nil bid to fail
 * Note that PlayerSystem assigns images based on these types
 */
export class WonTricks {
  made: boolean = false; // True if the team (or individual if individual bids) made their bid
  points = 0; // Total points
  wonTricksCount = 0; // Total tricks won, includes won, partner and bags, for text mode in PlayerSystem
  bags = 0; // Total bags
  wonTricks: Array<{type: string, points: number}> = []; // The array of entries

  constructor(made = false, bags = 0) {
    this.made = made;
    this.bags = bags;
  }

  /** return the number of entries */
  get length(): number { return this.wonTricks.length; }

  /** return the type at the given index */
  getType(index: number) {
    return this.wonTricks[index].type;
  }

  /** return the points at the given index */
  getPoints(index: number) {
    return this.wonTricks[index].points;
  }

  //
  // Helpers for getWonTricksForSeat to use when assembling the list
  //

  private push(type: string, points: number) {
    this.wonTricks.push({type, points});
    this.points += points;
  }

  setMade(made: boolean) {
    this.made = made;
  }

  setPoints(points: number) {
    this.points = points;
  }

  won() {
    this.push("won", this.made ? 10 : -10);
    this.wonTricksCount += 1;
  }

  partnerWon() {
    this.push("partner", this.made ? 10 : -10);
    this.wonTricksCount += 1;
  }

  miss(summaryMode: boolean) {
    if(summaryMode)
      this.push("miss", -10);
    else
      this.points -= 10;
  }

  addToBags(): number {
    let points = 1;
    this.bags += 1;
    if(this.bags === 10)
    {
      points -= 100;
      this.bags -= 10;
    }
    return points;
  }

  bag() {
    this.wonTricksCount += 1;
    this.push("bag", this.addToBags());
  }

  partnerBag() {
    this.addToBags();
  }

  nil(summaryMode: boolean) {
    if(summaryMode)
      this.push("nil", 100);
    else
      this.points += 100;
  }

  nilFailed() {
    this.wonTricksCount += 1;
    this.push("fail", this.addToBags() - 100);
  }
}
