import { EventState } from "@babylonjs/core/Misc/observable";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";

import { game } from "components/game/Game";
import { Buttons } from "components/ui/Buttons";
import { Commands } from "components/ui/Commands";
import { findGuiControl, zIndex } from "components/utils/GUI";
import { getRootState } from "states/RootState";
import { config } from "utils/Config";

import contract_icon_url from "components/ui/icons/contract.png";
import expand_icon_url from "components/ui/icons/expand.png";
import menu_icon_url from "components/ui/icons/menu.png";
//import hint_icon_url from 'components/ui/icons/hint.png';
import undo_icon_url from "components/ui/icons/undo.png";

export class MenuBar {
  static fps: TextBlock;
  static text: TextBlock;

  static create() {
    if(config.haveMenuStatusBar)
      MenuBar._createMenuBar();
    else
      MenuBar._createMenuButton();

    if(config.haveFullscreen)
      MenuBar._createFullscreenButton();
  }

  /** Create a MenuBar at top of game scene */
  static _createMenuBar() {
    // XXX - The Grid in 3.3 might be better than the StackPanel for this

    // iphone SE: 568 x 320 without scaling, 1126 x 640 with scaling

    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height;

    let height = 30 * game.getScale();

    // create menu bar panel to contain buttons
    let panel = new StackPanel("menuBar");
    panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    panel.width = "100%";
    panel.height = height + "px";
    panel.isVertical = false;
    panel.background = config.menuBackgroundColor;
    panel.zIndex = zIndex.MENU; // On top of everything

    game.guiTexture.addControl(panel);

    // create buttons
    this.createButton(panel, "menuButton", menu_icon_url, "", height * 1.5, height, () => { Commands.onOptions(); });

    if(getRootState().game.allowUndo)
      this.createButton(panel, "undoButton", undo_icon_url, "Undo", height * 3.5, height, () => { Commands.onUndo(); });

    // Score text
    let text = new TextBlock("menuBarText");
    text.text = "";
    text.color = "white";
    text.fontSize = (height * 4 / 5) + "px";
    text.resizeToFit = true;
    panel.addControl(text);
    MenuBar.text = text;
  }

  /** Create a MenuButton at top of game scene */
  static _createMenuButton() {
    let height = 30 * game.getScale();

    // hamburger menu button
    let hamburger = this.createButton(game.guiTexture.rootContainer, "menuButton", menu_icon_url, "", height, height, null);
    hamburger.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    hamburger.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    hamburger.zIndex = zIndex.MENU; // On top of everything

    let debugTimeout: any = null;
    hamburger.onPointerUpObservable.add((vector2WithInfo) => {
      clearTimeout(debugTimeout);
      // only show regular menu if the timeout didn't show the debug menu
      if(!findGuiControl("debugMenuPopUp")) {
        if(vector2WithInfo.buttonIndex === 2) // right click
          Commands.onDebugMenu();
        else
          Commands.onOptions();
      }
    });
    hamburger.onPointerDownObservable.add((vector2WithInfo) => {
      // Show debug menu if they hold pointer down for a second, letting up cancels timer
      debugTimeout = setTimeout(() => { Commands.onDebugMenu(); }, 1000);
    });
  }

  /** Create a MenuButton at top of game scene */
  static _createFullscreenButton() {
    let height = 30 * game.getScale();

    // Expand button
    let expand = this.createButton(game.guiTexture.rootContainer, "FullscreenExpandButton", expand_icon_url, "", height, height, () => { Commands.toggleFullscreen(); });
    expand.height = (height * 2) + "px"; // Double height afterwards so it can be tapped on in iPhone landscape without messing with the icon
    expand.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    expand.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    expand.zIndex = zIndex.MENU; // On top of everything

    // Contract button
    let contract = this.createButton(game.guiTexture.rootContainer, "FullscreenContractButton", contract_icon_url, "", height, height, () => { Commands.toggleFullscreen(); });
    contract.height = (height * 2) + "px"; // Double height afterwards so it can be tapped on in iPhone landscape without messing with the icon
    contract.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    contract.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    contract.zIndex = zIndex.MENU; // On top of everything
    contract.isVisible = false;

    // Show the correct button on a change
    let onFullscreenChange = () => {
      let fullscreen = game.isFullscreen();
      if(fullscreen) {
        expand.isVisible = false;
        contract.isVisible = true;
      } else {
        expand.isVisible = true;
        contract.isVisible = false;
      }
    };

    // We have to listen to the DOM for fullscreen changes because Babylon doesn't provide an observable
    document.addEventListener("fullscreenchange", onFullscreenChange, false);
    document.addEventListener("mozfullscreenchange", onFullscreenChange, false);
    document.addEventListener("webkitfullscreenchange", onFullscreenChange, false);
    document.addEventListener("msfullscreenchange", onFullscreenChange, false);
    game.onFakeFullscreenObservable.add(onFullscreenChange);
  }

  static createButton(parent: Container, name: string, icon_url: string, label: string, width: number, height: number, callback: (eventData: Vector2WithInfo, eventState: EventState) => void): Button {
    let button = Buttons.CreateMenuImageButton(name, label, icon_url, height);
    button.width = width + "px";
    button.onPointerUpObservable.add(callback);
    parent.addControl(button);
    return button;
  }

  static setText(text: string) {
    if(!MenuBar.text)
      return;

    MenuBar.text.text = text;
  }
}
