import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { game } from "components/game/Game";
import { NinePatchImage } from "components/ui/controls/NinePatchImage";
import { config } from "utils/Config";

/**
 * ArgoButton
 * Button with an optional Image and an optional TextBlock
 * Layout is automatic based on a base height, the width of the text and the aspect ratio of the icon
 * Icon can be positioned on left or right
 * baseHeight sets the overall height of the Button
 * borderWidth is a border around the icon and text, but not the background (Control.padding is empty space around the whole Button)
 * iconPosition can be ICON_LEFT or ICON_RIGHT
 */
export class ArgoButton extends Button {
  // Babylon's button now has _image and _textBlock

  static readonly ICON_LEFT = "left";
  static readonly ICON_RIGHT = "right";

  private _baseHeight = 30;
  private _borderWidth = 0;
  private _roundedCorners = true;
  private _iconPosition = ArgoButton.ICON_LEFT;
  private _iconScale = 1.0;

  backgroundImage: NinePatchImage = null;
  inactiveIcon: Image = null;
  overlayIcon: Image = null;
  inactiveOverlayIcon: Image = null;

  enabledAnimation: Animatable = null;
  enabledOverlayAnimation: Animatable = null;

  constructor(name: string, text: string, iconUrl: string = null) {
    super(name);

    if(iconUrl)
      this._addIcon(iconUrl);

    if(text)
      this._addTextBlock(text);

    // This will trigger the initial layout
    // (note that game.getScale can change on a resize when testing)
    this.baseHeight = Math.floor(30 * game.getScale());
  }

  get iconPosition() {
    return this._iconPosition;
  }

  set iconPosition(position: string) {
    if(position !== ArgoButton.ICON_LEFT && position !== ArgoButton.ICON_RIGHT)
      throw new Error(`ArgoButton invalid iconPosition: ${position}`);
    this._iconPosition = position;
    this.layout();
  }

  get iconScale() {
    return this._iconScale;
  }

  set iconScale(scale: number) {
    this._iconScale = scale;
    this.layout();
  }

  get baseHeight() {
    return this._baseHeight;
  }

  set baseHeight(height: number) {
    this._baseHeight = height;
    this.layout();
  }

  get borderWidth() {
    return this._borderWidth;
  }

  set borderWidth(width: number) {
    this._borderWidth = width;
    this.layout();
  }

  get roundedCorners() {
    return this._roundedCorners;
  }

  set roundedCorners(rounded: boolean) {
    this._roundedCorners = rounded;
    this.layout();
  }

  public set isEnabled(value: boolean) {
    if(this.isEnabled === value)
      return;

    super.isEnabled = value;

    this.onEnabled(value);
  }

  /** Set a background nine-patch image for the button */
  setBackgroundImageUrl(imageUrl: string) {
    if(this.backgroundImage) {
      this.backgroundImage.source = imageUrl;
    } else {
      this.backgroundImage = new NinePatchImage(this.name + "BackgroundImage", imageUrl);
      this.backgroundImage.zIndex = -1; // Since we added this after the other componenents, we need to be sure it draws first
      this.addControl(this.backgroundImage);
    }

    // Layout may need the backgroundImage loaded, so if it isn't loaded right away, re-layout when it does load
    if(this.backgroundImage.isLoaded)
      this.layout();
    else
      this.backgroundImage.onImageLoadedObservable.addOnce(() => this.layout());
  }

  /** Set an inactive icon, which will be switched with the main icon when isEnabled is false */
  setInactiveIconUrl(imageUrl: string) {
    if(this.inactiveIcon) {
      this.inactiveIcon.source = imageUrl;
    } else {
      this.inactiveIcon = new Image(this.name + "InactiveIcon", imageUrl);
      this.inactiveIcon.stretch = Image.STRETCH_UNIFORM;
      this.addControl(this.inactiveIcon);
    }

    // If the image isn't loaded yet, we'll need to re-layout when it is
    if(this.inactiveIcon.isLoaded)
      this.layout();
    else
      this.inactiveIcon.onImageLoadedObservable.addOnce(() => this.layout());
  }

  /** Set an overlay icon, which will appear on top of the other icons */
  setOverlayIconUrl(imageUrl: string) {
    if(this.overlayIcon) {
      this.overlayIcon.source = imageUrl;
    } else {
      this.overlayIcon = new Image(this.name + "OverlayIcon", imageUrl);
      this.overlayIcon.stretch = Image.STRETCH_UNIFORM;
      this.addControl(this.overlayIcon);
    }

    // If the image isn't loaded yet, we'll need to re-layout when it is
    if(this.overlayIcon.isLoaded)
      this.layout();
    else
      this.overlayIcon.onImageLoadedObservable.addOnce(() => this.layout());
  }

  /** Set an inactive overlay icon, which will appear on top of the other icons in place of the overlay icon  when isEnabled is false */
  setInactiveOverlayIconUrl(imageUrl: string) {
    if(this.inactiveOverlayIcon) {
      this.inactiveOverlayIcon.source = imageUrl;
    } else {
      this.inactiveOverlayIcon = new Image(this.name + "InactiveOverlayIcon", imageUrl);
      this.inactiveOverlayIcon.stretch = Image.STRETCH_UNIFORM;
      this.addControl(this.inactiveOverlayIcon);
    }

    // If the image isn't loaded yet, we'll need to re-layout when it is
    if(this.inactiveOverlayIcon.isLoaded)
      this.layout();
    else
      this.inactiveOverlayIcon.onImageLoadedObservable.addOnce(() => this.layout());
  }

  private _addIcon(iconUrl: string) {
    if(!iconUrl)
      return;

    let image = new Image(this.name + "Icon", iconUrl);
    image.stretch = Image.STRETCH_UNIFORM;
    this.addControl(image);

    // If the image isn't loaded yet, we'll need to re-layout when it is
    if(!image.isLoaded)
      image.onImageLoadedObservable.addOnce(() => this.layout());

    // XXX - Need to set the private _image, but TypeScript won't allow it, I don't know how it's allowed in Babylon's CreateImageButton
    (this as any)._image = image;
  }

  private _addTextBlock(text: string) {
    let textBlock = new TextBlock(this.name + "Icon", text);
    textBlock.fontFamily = config.fontFamily;
    textBlock.fontWeight = config.fontWeight;
    this.addControl(textBlock);

    // XXX - Need to set the private _textBlock, but TypeScript won't allow it, I don't know how it's allowed in Babylon's CreateImageButton
    (this as any)._textBlock = textBlock;
  }

  layout() {
    this.stopAnimations();

    // Init alignment
    let imageHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    let textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    if(this.iconPosition === ArgoButton.ICON_RIGHT) {
      imageHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
      textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    }

    let elementHeight = this.baseHeight - this.borderWidth * 2;

    let imageWidth = 0;
    let imageHeight = Math.floor(elementHeight * this.iconScale);

    let textWidth = 0;
    let textHeight = elementHeight;
    let textFontHeight = textHeight * 0.8;

    if(this.image) {
      this.image.horizontalAlignment = imageHorizontalAlignment;
      this.image.left = (this.iconPosition === ArgoButton.ICON_RIGHT) ? -this.borderWidth : this.borderWidth;

      if(this.image.isLoaded && this.image.domImage && this.image.domImage.height) {
        imageWidth = Math.floor(imageHeight * this.image.domImage.width / this.image.domImage.height);
      } else {
        imageWidth = imageHeight;
      }

      this.image.width = imageWidth + "px";
      this.image.height = imageHeight + "px";

      // Repeat everything on the inactive icon
      if(this.inactiveIcon) {
        this.inactiveIcon.horizontalAlignment = imageHorizontalAlignment;
        this.inactiveIcon.left = (this.iconPosition === ArgoButton.ICON_RIGHT) ? -this.borderWidth : this.borderWidth;
        this.inactiveIcon.width = imageWidth + "px";
        this.inactiveIcon.height = imageHeight + "px";
      }

      // And again for the overlay icon
      if(this.overlayIcon) {
        this.overlayIcon.horizontalAlignment = imageHorizontalAlignment;
        this.overlayIcon.left = (this.iconPosition === ArgoButton.ICON_RIGHT) ? -this.borderWidth : this.borderWidth;
        this.overlayIcon.width = imageWidth + "px";
        this.overlayIcon.height = imageHeight + "px";
      }

      // And again for the inactive overlay icon
      if(this.inactiveOverlayIcon) {
        this.inactiveOverlayIcon.horizontalAlignment = imageHorizontalAlignment;
        this.inactiveOverlayIcon.left = (this.iconPosition === ArgoButton.ICON_RIGHT) ? -this.borderWidth : this.borderWidth;
        this.inactiveOverlayIcon.width = imageWidth + "px";
        this.inactiveOverlayIcon.height = imageHeight + "px";
      }
    }

    if(this.textBlock) {
      this.textBlock.left = (this.iconPosition === ArgoButton.ICON_RIGHT) ? this.borderWidth : -this.borderWidth;
      this.textBlock.horizontalAlignment = textHorizontalAlignment;
      this.textBlock.fontSize = textFontHeight + "px";

      // Babylon won't tell us the width of the text, but we can get it from the canvas context
      let ctx = game.guiTexture.getContext();
      ctx.font = config.fontWeight + " " + textFontHeight + "px " + config.fontFamily;
      let metrics = ctx.measureText(this.textBlock.text);
      textWidth = metrics.width;

      this.textBlock.width = textWidth + "px";
      this.textBlock.height = textHeight + "px";
    }

    // Add a gap between the text and icon if both are present
    let gap = 0;
    if(imageWidth && textWidth)
      gap = imageHeight * 0.5;

    // Set Button size
    let buttonWidth = imageWidth + textWidth + this.borderWidth * 2 + gap;
    let buttonHeight = this._baseHeight;

    this.width = buttonWidth + "px";
    this.height = buttonHeight + "px";

    if(this.roundedCorners)
      this.cornerRadius = this.baseHeight * 0.25;
    else
      this.cornerRadius = 0;

    // Scale Background Image if needed
    // If the background image is a nine patch, it will grow appropriately
    // (Except we don't handle DPI here yet)
    // But if it's too small, the elements will overlap
    // So instead, we'll draw at full size and scale down to the button size
    // Note that in testing this technique broke down if the ratio was too great (235 down to 30)
    if(
      this.backgroundImage && this.backgroundImage.stretch === Image.STRETCH_NINE_PATCH &&
      this.backgroundImage.isLoaded && this.backgroundImage.domImage &&
      (this.backgroundImage.domImage.width - 2 > buttonWidth || this.backgroundImage.domImage.height - 2 > buttonHeight)
      ) {
        // The button is too small for the nine patch, so we need to scale it
        let domWidth = this.backgroundImage.domImage.width - 2;
        let domHeight = this.backgroundImage.domImage.height - 2;

        // Set the backgroundImage size to match the domImage
        this.backgroundImage.width = domWidth + "px";
        this.backgroundImage.height = domHeight + "px";

        // Then scale it back down to the image dimensions
        this.backgroundImage.scaleX = buttonWidth / domWidth;
        this.backgroundImage.scaleY = buttonHeight / domHeight;
    }
  }

  _onEnabledMainIcon(enabled: boolean) {
    if(!this.image || !this.inactiveIcon)
      return;

    let fromAlpha = this.image.alpha;
    let toAlpha = enabled ? 1.0 : 0.0;

    let imageAnimation = new Animation(this.name + "enabledAnimation", "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    let keyFrames = [];
    let k0 = 0;
    let k1 = 6;
    keyFrames.push({frame: k0, value: fromAlpha });
    keyFrames.push({frame: k1, value: toAlpha });
    imageAnimation.setKeys(keyFrames);

    this.enabledAnimation = game.scene.beginDirectAnimation(this.image, [imageAnimation], 0, k1);

    let inactiveAnimation = new Animation(this.name + "enabledInactiveAnimation", "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    keyFrames = [];
    keyFrames.push({frame: k0, value: 1.0 - fromAlpha });
    keyFrames.push({frame: k1, value: 1.0 - toAlpha });
    inactiveAnimation.setKeys(keyFrames);

    this.enabledAnimation.appendAnimations(this.inactiveIcon, [inactiveAnimation]);
  }

  _onEnabledOverlayIcon(enabled: boolean) {
    if(!this.overlayIcon || !this.inactiveOverlayIcon)
      return;

    let fromAlpha = this.overlayIcon.alpha;
    let toAlpha = enabled ? 1.0 : 0.0;

    let imageAnimation = new Animation(this.name + "enabledAnimation", "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    let keyFrames = [];
    let k0 = 0;
    let k1 = 6;
    keyFrames.push({frame: k0, value: fromAlpha });
    keyFrames.push({frame: k1, value: toAlpha });
    imageAnimation.setKeys(keyFrames);

    this.enabledOverlayAnimation = game.scene.beginDirectAnimation(this.overlayIcon, [imageAnimation], 0, k1);

    let inactiveAnimation = new Animation(this.name + "enabledInactiveAnimation", "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    keyFrames = [];
    keyFrames.push({frame: k0, value: 1.0 - fromAlpha });
    keyFrames.push({frame: k1, value: 1.0 - toAlpha });
    inactiveAnimation.setKeys(keyFrames);

    this.enabledOverlayAnimation.appendAnimations(this.inactiveOverlayIcon, [inactiveAnimation]);
  }

  onEnabled(enabled: boolean) {
    this.stopAnimations();
    this._onEnabledMainIcon(enabled);
    this._onEnabledOverlayIcon(enabled);
  }

  stopAnimations() {
    if(this.enabledAnimation) {
      this.enabledAnimation.goToFrame(this.enabledAnimation.toFrame);
      this.enabledAnimation.stop();
      this.enabledAnimation = null;
    }

    if(this.enabledOverlayAnimation) {
      this.enabledOverlayAnimation.goToFrame(this.enabledOverlayAnimation.toFrame);
      this.enabledOverlayAnimation.stop();
      this.enabledOverlayAnimation = null;
    }
  }
}
