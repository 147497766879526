import { Effect } from "@babylonjs/core/Materials/effect";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { ParticleSystem } from "@babylonjs/core/Particles/particleSystem";
import { Scene } from "@babylonjs/core/scene";
import { Nullable } from "@babylonjs/core/types";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { game } from "components/game/Game";

import { ArgoSystem } from "components/game/ArgoSystem";

// We may want to do helpers like these: https://doc.babylonjs.com/how_to/particlehelper

// tslint:disable-next-line:no-var-requires
const particleTexturePath = require("components/game/particles/sparkle.png");

/** 3d ParticleSystem which does not dispose the particle texture
 *  ParticleSystem, by default, and when auto-disposing, disposes the texture when the system is disposed.  We don't ever want to do that.
 */
export class Argo3dParticleSystem extends ParticleSystem {
  constructor(name: string, capacity: number, scene: Scene, customEffect: Nullable<Effect> = null, isAnimationSheetEnabled: boolean = false, epsilon: number = 0.01) {
    super(name, capacity, scene, customEffect, isAnimationSheetEnabled, epsilon);

    // Initialize the texture
    this.particleTexture = game.argoParticleSystem.particleTexture;
  }

  dispose(disposeTexture?: boolean) {
    // Always set disposeTexture to false so our pre-loaded texture is not disposed
    super.dispose(false);
  }
}

/** 2d ParticleSystem which does not dispose the particle texture
 *  Supports a 2D GUI emitterControl instead of an AbstractMesh or Vector3
 *  A default 1x1 pixel invisible rectangle is created as an emitter control
 */
export class Argo2dParticleSystem extends Argo3dParticleSystem {
  // Optional 2D GUI Control to use as an emitter source
  // Currently TexturePreservingParticleSystem does not attempt to convert it's position to global coordinates
  // This will overwrite any 3d mesh or vector emitter set as the particle system's emitter property
  emitterControl: Control;

  constructor(name: string) {
    // We'll use some default parameters here
    let capacity = 1000;
    let scene = game.scene;
    let customEffect = null;
    let isAnimationSheetEnabled = false;
    let epsilon = null;

    // Create the particle system
    super(name, capacity, scene, customEffect, isAnimationSheetEnabled, epsilon);

    // Set a default Vector3 emitter otherwise animate won't get called and any emitterControl won't be used
    this.emitter = new Vector3(0, 0, 0);

    // Set the texture
    this.particleTexture = this.particleTexture;

    // Assign it to the aboveGUICamera so it is above the GUI
    this.layerMask = game.aboveGUICamera.layerMask;

    // Initialize the particle system with some reasonable defaults
    this.minSize = 0.2;
    this.maxSize = 0.4;
    this.minEmitBox = new Vector3(-0.1, 0, -0.1);
    this.maxEmitBox = new Vector3(0.1, 0, 0.1);
    this.direction1 = new Vector3(-1, 0, -1);
    this.direction2 = new Vector3(1, 0, 1);
    this.minEmitPower = 0.2;
    this.maxEmitPower = 1.0;
    this.minLifeTime = 0.75;
    this.maxLifeTime = 1.25;
    this.emitRate = 100;

    // Create an emitter control
    // Created as a Rectangle instead of a Control so it can be made visible for debugging
    let emitterControl = new Rectangle(name + "EmitterControl");
    emitterControl.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    emitterControl.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    emitterControl.width = "1px";
    emitterControl.height = "1px";
    emitterControl.thickness = 0;
    game.guiTexture.addControl(emitterControl);
    this.emitterControl = emitterControl;
  }

  dispose(disposeTexture?: boolean) {
    // If an emitter control has been set, dispose of it
    if(this.emitterControl) {
      this.emitterControl.dispose();
      this.emitterControl = null;
    }

    super.dispose(disposeTexture);
  }

  public animate(preWarmOnly = false): void {
    // If we have a 2d emitter control, set a 3d vector emitter for the particle system to use for animating this frame
    if(this.emitterControl) {
      let x = this.emitterControl.leftInPixels + this.emitterControl.widthInPixels * 0.5;
      let y = this.emitterControl.topInPixels + this.emitterControl.heightInPixels * 0.5;
      this.emitter = game.unproject(x, y);
    }

    super.animate(preWarmOnly);
  }
}

// Babylon's particle class is ParticleSystem
// To avoid name clashes, this has "Argo" slapped in front of the name
export class ArgoParticleSystem extends ArgoSystem {
  particleTexture: Texture = null;

  queueAssets(): void {
    let textureTask = this.game.assetsManager.addTextureTask("CardTextureTask", particleTexturePath);
    textureTask.onSuccess = (task) => {
      this.particleTexture = task.texture;
    };
  }

  /** Old version, still used by BidSystem and MetaGamePanel */
  createOld2dParticleSystem(name: string) {
    // We'll use some default parameters here
    let capacity = 1000;
    let scene = this.game.scene;
    let customEffect = null;
    let isAnimationSheetEnabled = false;
    let epsilon = null;

    // Create the particle system
    let particleSystem = new Argo3dParticleSystem(name, capacity, scene, customEffect, isAnimationSheetEnabled, epsilon);

    // Set the texture
    particleSystem.particleTexture = this.particleTexture;

    // Assign it to the aboveGUICamera so it is above the GUI
    particleSystem.layerMask = this.game.aboveGUICamera.layerMask;

    // Create an emitter mesh
    particleSystem.emitter = Mesh.CreateBox(name + "Emitter", 0.0, scene);
    particleSystem.emitter.isVisible = false;

    // Size based on GUI scale
    let size = 0.4;
    let range = size * 0.5;

    // Initialize the particle system
    particleSystem.minSize = size;
    particleSystem.maxSize = particleSystem.minSize;
    particleSystem.minEmitBox = new Vector3(-range, 0, -range);
    particleSystem.maxEmitBox = new Vector3(range, 0, range);
    particleSystem.direction1 = new Vector3(-1, 0, -1);
    particleSystem.direction2 = new Vector3(1, 0, 1);
    particleSystem.minEmitPower = size * 0.4;
    particleSystem.maxEmitPower = size * 3.0;
    particleSystem.minLifeTime = 5.0;
    particleSystem.maxLifeTime = 5.0;
    particleSystem.emitRate = 100;

    return particleSystem;
  }

  /** Create a simple full-screen 3d explosion  */
  boom(name = "ArgoParticleSystemBoom") {
    let particleSystem = new Argo3dParticleSystem(name, 2000, game.scene);
    particleSystem.createSphereEmitter(1, 1);
    particleSystem.emitter = new Vector3(0, 0, 1);
    particleSystem.minSize = 0.2;
    particleSystem.maxSize = 0.4;
    particleSystem.minEmitPower = 4.0;
    particleSystem.maxEmitPower = 8.0;
    particleSystem.minLifeTime = 0.75;
    particleSystem.maxLifeTime = 1.25;
    particleSystem.gravity = new Vector3(0, 0, -20);
    particleSystem.disposeOnStop = true;
    particleSystem.manualEmitCount = 2000;
    particleSystem.start();

    return particleSystem;
  }
}
