import { applySnapshot, onPatch, types } from "mobx-state-tree";
import { PilesGameState } from "states/game/PilesGameState";
import { JSONPatchRouter } from "states/JSONPatchRouter";
import { LeaderboardsState } from "states/leaderboard/LeaderboardsState";
import { IBaseStateSync } from "states/state-sync/BaseStateSync";
import { UsersState } from "states/user/UsersState";
import { UserState } from "states/user/UserState";

import { logger } from "utils/logger";
import { APPLY_SNAPSHOT_STAGE_DONE, APPLY_SNAPSHOT_STAGE_START, IChannelPatch } from "./state-sync/BaseStateSync";

// When you add more states be sure to add to state enumeration list, and to RootStateStatus
export const ROOT_STATE_LOADING = "loading";
export const ROOT_STATE_LOADED = "loaded"; // state set when all systems and assets are loaded
export const ROOT_STATE_HOME_SCREEN = "home_screen";
export const ROOT_STATE_GAME = "game"; // currently in a game
//export const ROOT_STATE_REQUEST_QUIT = "request_quit";
//export const ROOT_STATE_QUIT = "quit";
export type RootStateStatus =  typeof ROOT_STATE_LOADING | typeof ROOT_STATE_LOADED | typeof ROOT_STATE_HOME_SCREEN | typeof ROOT_STATE_GAME;

// These are Facebook messenger states, but might be re-usable on other platforms
export const CONTEXT_TYPE_GROUP = "GROUP";
export const CONTEXT_TYPE_POST = "POST";
export const CONTEXT_TYPE_SOLO = "SOLO";
export const CONTEXT_TYPE_THREAD = "THREAD";
export type RootStateContext = typeof CONTEXT_TYPE_GROUP | typeof CONTEXT_TYPE_POST | typeof CONTEXT_TYPE_SOLO | typeof CONTEXT_TYPE_THREAD;

export const CONNECTION_STATUS_DISCONNECTED = "disconnected";
export const CONNECTION_STATUS_CONNECTING = "connecting";
export const CONNECTION_STATUS_CONNECTED = "connected";
export type ConnectionStatus = typeof CONNECTION_STATUS_DISCONNECTED | typeof CONNECTION_STATUS_CONNECTING | typeof CONNECTION_STATUS_CONNECTED;

// tslint:disable-next-line:variable-name
const RootState = types
  .model("RootState", {
    game: types.optional(PilesGameState, {}),
    loadingProgress: types.optional(types.number, 0), // the initial loading progress of assets, ranges from 0-100
    request_quit: types.optional(types.boolean, false),  // this gets set when the user clicked the quit button, FacebookIGSystem listens for it and reports score to facebook then quits
    status: types.optional(types.enumeration("Status", [ROOT_STATE_LOADING, ROOT_STATE_LOADED, ROOT_STATE_HOME_SCREEN, ROOT_STATE_GAME ]), ROOT_STATE_LOADING), // this is more like the app state
    user: types.optional(UserState, {}),
    users: types.optional(UsersState, {}), // list of users we've encountered in game and leaderboard
    leaderboards: types.optional(LeaderboardsState, {}),
    paused: types.optional(types.boolean, false), // flag if game is currently paused
    connectionStatus: types.optional(types.enumeration("ConnectionStatus", [CONNECTION_STATUS_DISCONNECTED, CONNECTION_STATUS_CONNECTING, CONNECTION_STATUS_CONNECTED ]), CONNECTION_STATUS_DISCONNECTED), // Status of StateSyncClient websocket connection
    clientVersion: types.optional(types.string, ""), // The version of the client that loaded this state on the server.

    // facebook specific
    contextId: types.maybeNull(types.string),
    contextType: types.optional(types.enumeration("ContextType",  [CONTEXT_TYPE_GROUP, CONTEXT_TYPE_POST, CONTEXT_TYPE_SOLO, CONTEXT_TYPE_THREAD ]), CONTEXT_TYPE_SOLO),
    interstialAdSupported: types.optional(types.boolean, false),
    rewardAdSupported: types.optional(types.boolean, false),
    paymentsSupported: types.optional(types.boolean, false),
    platform: types.maybeNull(types.string), // platform we're running on, currently only set on Facebook IG. ("IOS" | "ANDROID" | "WEB" | "MOBILE_WEB")
  })
  .volatile((self) => ({
    router: new JSONPatchRouter(),
  }))
  .views((self) => {

    function afterCreate() {
      // pipe state jSON patches to router
      onPatch(self, (patch, reversePatch) => {
        // something in mobx-state-tree appears to catch exceptions and suppress them from being reported to console or sentry
        // This caused us to not see errors in our own code. So to work around put a try/catch around our code and report to logger
        try {
          // if patch is under /game/ and we are playing offline (or stand alone), then filter patch, for example this will not queuePatches to set values/facing of other seats in a local offline spades game.
          // filterPatch returns a channel name, ie null, public, or #{user.id}. Normally this is used by the server to route patches to the right websocket channel, in this case we'll queue public and #{user.id}
          // also only filter if there is a local user, ie don't filter on server
          if(patch.path.startsWith("/game/") && (this.game.offline || this.game.options.standAlone) && this.user.id !== "0") {
            const channelPatches = self.game.filterPatch(patch, reversePatch);
            channelPatches.forEach((channelPatch: IChannelPatch) => {
              const channel = channelPatch.channel;
              if(channel === "public" || channel === `#${this.user.id}`)
                self.router.queuePatch(channelPatch.patch, channelPatch.reversePatch);
            });
          }
          else
            self.router.queuePatch(patch, reversePatch);
        }
        catch(err) {
          logger.error("onPatch handler exception " + patch.path, { err, patch });
        }
      });
    }

    return { afterCreate };
  })
  .actions((self) => {

    function pause() {
      self.paused = true;
    }

    function resume() {
      self.paused = false;
    }

    function requestQuit() {
      self.request_quit = true;
    }

    function setLoadingProgress(progress: number) {
      self.loadingProgress = progress;
    }

    function setContext(contextType: RootStateContext, contextId: string) {
      self.contextId = contextId;
      self.contextType = contextType;
    }

    function setStatus(status: RootStateStatus) {
      self.status = status;
    }

    function setConnectionStatus(status: ConnectionStatus) {
      self.connectionStatus = status;
    }

    function setInterstitialAdSupported(supported: boolean) {
      self.interstialAdSupported = supported;
    }

    function setRewardAdSupported(supported: boolean) {
      self.rewardAdSupported = supported;
    }

    function setPaymentsSupported(supported: boolean) {
      self.paymentsSupported = supported;
    }

    function setClientVersion(version: string) {
      self.clientVersion = version;
    }

    function setPlatform(platform: string) {
      self.platform = platform;
    }

    return { pause, resume, requestQuit, setClientVersion, setConnectionStatus, setContext, setLoadingProgress, setPlatform, setStatus, setInterstitialAdSupported, setPaymentsSupported, setRewardAdSupported };
  });

// RootState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IRootState = typeof RootState.Type;

// global singleton instance of root state
let _rootState: IRootState;
function getRootState() {
  return _rootState;
}

function setRootState(rootState: IRootState) {
  _rootState = rootState;
}

/** safely apply a snapshot to a state that is a child of the root state */
function safeApplySnapshot(state: IBaseStateSync, snapshot: any) {
  state.setApplySnapshotStage(APPLY_SNAPSHOT_STAGE_START);
  // it is important to pause notifiying routes while a snapshot is being applied.
  // because most things will expect the whole state to be up to date before being notified.
  _rootState.router.pause();
  applySnapshot(state, snapshot);
  _rootState.router.unpause();
  state.setApplySnapshotStage(APPLY_SNAPSHOT_STAGE_DONE);
}

export { RootState, IRootState, getRootState, safeApplySnapshot, setRootState };
