import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { CubicEase } from "@babylonjs/core/Animations/easing";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

import { ANIMATION_MOVE_PIECE } from "components/game/AnimationSystem";
import { ArgoSystem } from "components/game/ArgoSystem";
import { Game } from "components/game/Game";

export class UpAndOverAnimation extends ArgoSystem {
  checkCardFlips = false;

  constructor(game: Game, checkCardFlips = false) {
    super(game);
    this.checkCardFlips = checkCardFlips;
  }

  init() {
    this.game.animationSystem.registerAnimation(ANIMATION_MOVE_PIECE, (mesh) => this.buildMovePieceAnimation(mesh));
  }

  /**
   * General animation for moving cards between piles
   * Uses hermetic spline tangents to create a curved up and down path between the two piles
   * Also EASINGMODE_EASEOUT is set
   * If there's a rotation, it's performed in the middle, when the curve is hopefully high enough
   */
  buildMovePieceAnimation(mesh: AbstractMesh, configSound = "playCard"): Animatable {
    //console.log("animate " + mesh.metadata.layoutGameStatus + " " + mesh.name + " from " + mesh.metadata.fromParentName + " " + mesh.absolutePosition + " to " + mesh.parent.name + " " + mesh.metadata.layoutPosition);

    let gameStatus = mesh.metadata.layoutGameStatus;

    let fromName = mesh.metadata.fromParentName;
    let toName = mesh.parent.name;

    let position = mesh.metadata.layoutPosition;
    let rotationQuaternion = mesh.metadata.layoutRotationQuaternion;

    let position0 = mesh.position;
    let position1 = position;

    let rotation0 = mesh.rotationQuaternion;
    let rotation1 = rotationQuaternion;

    let isFlipping = false;

    if(this.checkCardFlips) {
      // XXX - This generates false positives in SPADES, which is why a checkCardFlips flag has been added
      // Try to guess if a flip is taking place by comparing the difference
      // between the y rotations of converting to euler angles
      // There's surely a way to do this without the conversion, though
      let rotationYChange = Math.abs(rotation0.toEulerAngles().y - rotation1.toEulerAngles().y);
      isFlipping = (rotationYChange > Math.PI / 2);
    }

    // XXX - maybe use AreClose instead of equals
    let doRotate = !rotation0.equals(rotation1);

    let distance = Vector3.Distance(position0, position1);

    let k = 0.25;
    if(doRotate)
      k = 0.5;

    let tangent0 = new Vector3(0, 0, -k);
    let tangent1 = new Vector3(0, 0, k);

    if(distance < 3.0 && !isFlipping) {
      let scale = distance / 3.0;
      tangent0.scaleInPlace(scale);
      tangent1.scaleInPlace(scale);
    }

    let startFrame = 0;

    let tangents = true;
    if(toName === "drag" || mesh.parent.metadata.layout === "fan" || mesh.parent.metadata.layout === "hand")
      tangents = false;

    /*
    // Failed attempt to visualize animation path
    // Currently it seems to start and stop at the right spot, but the line curves down instead of up. Negating the tangents didn't help.
    // Confirmed in the source code: the animation cubic interpolation is hermite
    // Specifically: Vector3.Hermite(startValue, outTangent, endValue, inTangent, gradient);
    let lineMesh: LinesMesh = <LinesMesh>game.scene.getMeshByName("AnimationLine");
    if(lineMesh)
      lineMesh.dispose();

    let hermite = Curve3.CreateHermiteSpline((<AbstractMesh>mesh.parent).absolutePosition.add(position0), tangent0, (<AbstractMesh>mesh.parent).absolutePosition.add(position1), tangent1, 60);
    lineMesh = Mesh.CreateLines("AnimationLine", hermite.getPoints(), game.scene);
    lineMesh.color = Color3.Red();
    */

    let positionAnimation = new Animation("PositionMesh", "position", 30.0, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let positionKeys = [];

    if(tangents) {
      positionKeys.push({ frame: startFrame, value: position0, outTangent: tangent0 });
      positionKeys.push({ frame: startFrame + 10, value: position1, inTangent: tangent1 });
    }
    else {
      positionKeys.push({ frame: startFrame, value: position0 });
      positionKeys.push({ frame: startFrame + 10, value: position1 });
    }

    positionAnimation.setKeys(positionKeys);
    mesh.animations.push(positionAnimation);

    if(doRotate) {
      let rotateAnimation = new Animation("RotateMesh", "rotationQuaternion", 30.0, Animation.ANIMATIONTYPE_QUATERNION, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let rotateKeys = [];
      rotateKeys.push({ frame: startFrame + 3, value: rotation0 });
      rotateKeys.push({ frame: startFrame + 7, value: rotation1 });
      rotateAnimation.setKeys(rotateKeys);
      mesh.animations.push(rotateAnimation);
    }

    let easingFunction = new CubicEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
    positionAnimation.setEasingFunction(easingFunction);

    let speed = 1.0;
    if(toName === "drag")
      speed = 2.0;

    //speed = 0.25;

    let playSound = true;

    // Don't play sound while creating game
    if(gameStatus === "create")
      playSound = false;

    // Don't play sound when cards are just moving around in the hand
    // fromName is empty for intra pile moves (fanning the hand, or stretching in Solitaire)
    // fromName is also empty when flipping a card over in Solitaire
    if(fromName === "" && !isFlipping)
      playSound = false;

    if(playSound && configSound)
      this.game.soundSystem.play(configSound);

    let animatable = this.game.animationSystem.begin(mesh, 0, startFrame + 10, false, speed);

    if(toName.substring(0, 10) === "foundation")
      animatable.onAnimationEndObservable.add( () => this.game.cardSystem.sparkleEdge(mesh));

    return animatable;
  }
}
