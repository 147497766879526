import { ArgoSystem } from "components/game/ArgoSystem";
import { game } from "components/game/Game";
import { Screen } from "components/ui/controls/Screen";

/**
 * Maintains a screen stack, and only the most recently created screen is visible
 * Also hides and shows the game based on whether the stack is empty
 */
export class ScreenSystem extends ArgoSystem {
  stack: Screen[] = [];

  /** Return how many screens in stack */
  screenCnt() {
    return this.stack.length;
  }

  /**
   * Push a new screen onto the stack
   * The game and any existing screens will be hidden
   */
  pushScreen(screen: Screen) {
    if(this.stack.length)
      this.stack[this.stack.length - 1].isVisible = false;
    else
      game.hideGame();
    this.stack.push(screen);
  }

  /**
   * Remove a screen from the stack
   * The topmost screen on the stack will be made visible
   * If there are no more screens, the game will be shown
   */
  removeScreen(screen: Screen) {
    let index = this.stack.indexOf(screen);
    if(index < 0)
      return;
    this.stack.splice(index, 1);

    if(this.stack.length)
      this.stack[this.stack.length - 1].isVisible = true;
    else
      game.showGame();
  }

  /** dispose all screens, used when game is starting to clear screens like the leaderboard */
  disposeAllScreens() {
    this.stack.forEach((screen) => screen.dispose());
  }

  /**
   * Layout all screens when the view is resized
   */
  resized(): void {
    for(let screen of this.stack)
      screen.layout();
  }
}
