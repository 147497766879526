import { Control } from "@babylonjs/gui/2D/controls/control";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { game } from "components/game/Game";
import { ScreenWithFooter } from "components/ui/controls/ScreenWithFooter";
import { SharePopUp } from "components/ui/controls/SharePopUp";
import { disposeGuiControl, findGuiControl } from "components/utils/GUI";
import { getXPLevelTierColor } from "components/utils/XPTierColors";
import { config } from "utils/Config";

import shareIconUrl from "components/ui/icons/share.png";

export class LevelUpScreen extends ScreenWithFooter {
  static autoShowSharePopUp = true;

  onDispose: () => void;

  static showLevelUpScreen(): Promise<void> {
    let gui = new LevelUpScreen("LevelUpScreen");
    game.guiTexture.addControl(gui);

    return new Promise<void>((resolve, reject) => {
      gui.onDispose = () => {
        resolve();
      };
    });
  }

  constructor(name: string) {
    super(name);

    let level = game.rootState.user.xp.level;

    this.isPointerBlocker = true;

    this.addDarkBackground();

    let centerStack = new StackPanel(name + "CenterStack");
    this.center.addControl(centerStack);

    let message = new TextBlock(name + "Label");
    message.fontFamily = config.fontFamily;
    message.fontWeight = config.fontWeight;
    message.color = "white";
    message.text = "You gained a level!";
    centerStack.addControl(message);

    let levelText = new TextBlock(name + "LevelText");
    levelText.fontFamily = config.fontFamily;
    levelText.fontWeight = config.fontWeight;
    levelText.color = getXPLevelTierColor(level);
    levelText.text = `Level ${level}`;
    centerStack.addControl(levelText);

    let spacer = new Control(name + "Spacer");
    centerStack.addControl(spacer);

    this.addFooterButton(name + "ShareButton", "Share", shareIconUrl, () => this.onShareButton());
    this.addFooterButton(name + "Continue", "Next", null, () => this.onContinue());

    game.soundSystem.play("goodReaction");

    game.argoParticleSystem.boom();

    this.layout();

    // wait a bit then show the share box
    if(LevelUpScreen.autoShowSharePopUp) {
      setTimeout(() => this.onAutoShareTimer(), 2000);
    }
  }

  layout() {
    super.layout();

    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height - menuBarHeight;

    let ww = guiWidth;
    let wh = guiHeight;

    let k = 8; // "k" is basically how many player boxes should fit vertically on the screen, so bigger k for smaller boxes
    let height = Math.min(guiWidth / k / 2, guiHeight / k);

    let smallHeight = height / 2;
    let smallFontHeight = smallHeight;

    let centerHeight = this.center.heightInPixels;

    let spacerHeight = centerHeight - height - smallFontHeight - height;
    let popTop = this.footer.heightInPixels + (centerHeight - smallFontHeight - height - spacerHeight) * 0.5 + smallHeight + height;

    this.width = ww + "px";
    this.height = wh + "px";

    let message = findGuiControl(this.name + "Label", this);
    message.height = smallHeight + "px";
    message.fontSize = smallFontHeight;

    let levelText = findGuiControl(this.name + "LevelText", this);
    levelText.height = height + "px";
    levelText.fontSize = height;

    let spacer = findGuiControl(this.name + "Spacer", this);
    spacer.height = spacerHeight + "px";

    let pop = findGuiControl("SharePopUp") as SharePopUp;
    if(pop && pop.customPageHeight) {
      pop.top = popTop;
      pop.setCustomPageHeight(spacerHeight * 0.8);
    }
  }

  onAutoShareTimer() {
    if(this.disposed)
      return;

    // Already showing?
    let pop = findGuiControl("SharePopUp") as SharePopUp;
    if(pop)
      return;

    // Show a mini PopUp
    this.showSharePopUp(true);
  }

  showSharePopUp(mini: boolean) {
    // Create a SharePopUp
    let pop = new SharePopUp("SharePopUp", ["levelUp"]);
    pop.disposeOnMiss = !mini;
    pop.centerOnClick = true;
    pop.onDisposeObservable.add((popUp) => this.onSharePopUpDisposed(popUp as SharePopUp));
    game.guiTexture.addControl(pop);

    // Hide the share button
    let shareButton = findGuiControl(this.name + "ShareButton", this);
    if(shareButton)
      shareButton.isVisible = false;

    // Minify it
    if(mini) {
      pop.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      pop.setCustomPageHeight(100);
      this.layout();
    }
  }

  onSharePopUpDisposed(popUp: SharePopUp) {
    if(popUp.disposeReason === "close")
      LevelUpScreen.autoShowSharePopUp = false;

    // Show the share button
    let shareButton = findGuiControl(this.name + "ShareButton", this);
    if(shareButton)
      shareButton.isVisible = true;
  }

  dispose() {
    super.dispose();

    disposeGuiControl("SharePopUp");

    if(this.onDispose) {
      this.onDispose();
      this.onDispose = null;
    }
  }

  onShareButton() {
    this.showSharePopUp(false);
  }

  onContinue() {
    this.dispose();
  }
}
