import * as _ from "lodash";
import seedrandom from "seedrandom"; // use seedrandom npm package because javascript does not have a way to seed Math.random
import { v4 as uuid } from "uuid";

import { destroy, getSnapshot, types } from "mobx-state-tree";
import { UndoManager } from "mst-middlewares";

import { config } from "utils/Config";

import { IMinOptions, OptionsState } from "states/game/OptionsState";
import { IPlayerState, PLAYER_STATE_LEFT, PLAYER_STATE_PLAYER, PLAYER_STATE_SUB, PlayerState } from "states/game/PlayerState";
import { ISeatState, SeatState } from "states/game/SeatState";
import { ITeamState, TeamState } from "states/game/TeamState";
import { WonTricks } from "states/game/WonTricks";
import { PatchQueueState } from "states/PatchQueueState";
import { TimersState } from "./TimersState";

import { logger } from "utils/logger";

export const GAME_STATE_CREATE = "create";
export const GAME_STATE_WAITING_FOR_PLAYERS = "waiting_for_players";
export const GAME_STATE_PRE_START = "pre_start"; // means start has been clicked, but game hasn't actually started yet, this is the state offline games will be in on the server
export const GAME_STATE_START = "start";
export const GAME_STATE_RESET = "reset";
export const GAME_STATE_RESET_ROUND = "reset_round";
export const GAME_STATE_DEAL = "deal";
export const GAME_STATE_SHUFFLE = "shuffle";
export const GAME_STATE_BID = "bid"; // for trick games
export const GAME_STATE_PASS = "pass";
export const GAME_STATE_PLAY = "play";
export const GAME_STATE_AUTO_FINISH = "auto_finish";
export const GAME_STATE_ROUND_OVER = "round_over"; // set when a round/hand is over
export const GAME_STATE_GAME_OVER = "game_over"; // check in seat state if the game is won or lost
export type GameStateStatus = typeof GAME_STATE_CREATE  | typeof GAME_STATE_WAITING_FOR_PLAYERS | typeof GAME_STATE_PRE_START | typeof GAME_STATE_START | typeof GAME_STATE_RESET |
                              typeof GAME_STATE_RESET_ROUND | typeof GAME_STATE_DEAL | typeof GAME_STATE_SHUFFLE | typeof GAME_STATE_BID | typeof GAME_STATE_PASS | typeof GAME_STATE_PLAY |
                              typeof GAME_STATE_AUTO_FINISH | typeof GAME_STATE_ROUND_OVER | typeof GAME_STATE_GAME_OVER;
const joinableStates = [GAME_STATE_WAITING_FOR_PLAYERS, GAME_STATE_PRE_START, GAME_STATE_START, GAME_STATE_DEAL, GAME_STATE_SHUFFLE, GAME_STATE_BID, GAME_STATE_PASS, GAME_STATE_PLAY, GAME_STATE_AUTO_FINISH, GAME_STATE_ROUND_OVER];

const BOT_NAME_LIST = [
  "Aayla", "Charlotte", "Ellen", "Estelle", "Jiemba", "Ju-long", "Lucas", "Noah",
  "Sarah", "Tommen", "Azhar", "Effie", "Enzo", "Imene", "Joe", "Kalama", "Lucy",
  "Odawa", "Sid", "Xiulan",
];

/** IPileMinSnap is a minimum representation of a pile,   */
export interface IPileMinSnap {
  p: number[]; // list of piece values in pile
}

/** IPilesMinSnap is a minimum representation of a group of piles, ie: {
 *   h0: { p: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] },  // h0 is hand0
 *   ...
 * }
 */
export interface IPilesMinSnap {
  [index: string]: IPileMinSnap;
}

export interface IGameMinSnap {
  piles: IPilesMinSnap;
}

// IGameStart is optional properties need to start or find a game
export interface IGameStart {
  gameContextId?: string;
  options?: IMinOptions; // really this should be an IOptionsState, but it wasn't working to just init with name ie: {name:"handChallenge"}
  seatId?: string;  // the seat to try to put the player joining in, used to maintain seat when playing again, and inviting a friend to a specific seat
  invited?: boolean; // true if user is clicking on an invite/share message to join this game. Disables auto creating a new game
  humansCnt?: number; // number of players in previous game, used to signal to user if they should wait
  cloneGameId?: string;
  tutorial?: boolean; // true if the game should show tutorial help popups
  clientName?: string; // the name of the client request to start the game, ie spades_facebook_ig

  // properties for recreating a game (ie clicked on a shared spades/hearts hand on facebook)
  minPiles?: IPilesMinSnap; // minPiles is the starting state of the piles and pieces, if set then skip shuffling/dealing
  dealerSeatId?: string; // seat id of first dealer, spades only
  passDirection?: string;  // initial pass direction, hearts only
}
// add on a reset function to UndoManager so that we can reset it after dealing
// tslint:disable-next-line:variable-name
export const ResettableUndoManager = UndoManager
  .actions((self) => {
    function reset() {
      self.history.length = 0;
      self.undoIdx = 0;
    }
    return { reset };
  });
type IResettableUndoManager = typeof ResettableUndoManager.Type;

// tslint:disable-next-line:variable-name
const GameState = PatchQueueState
  .props({
    stateName: types.optional(types.string, "GameState"),
    // vars
    // gameContextId optional context id in which the game is being played, ie a Facebook messenger chat + a uuid
    // This makes the context specific to the series of games being played, ie starting a new game generates a new uuid,
    // then every game played by playing again will have the same gameContextId
    gameContextId: types.maybeNull(types.string),
    name: types.optional(types.string, ""), // name of game, ie spades or hearts
    seed: types.optional(types.number, 0),
    rngState: types.optional(types.string, ""), // the current internal state of the rng. Only updated right before saving snapshot, and should not be sent to clients.
    status: types.optional(types.enumeration("Status", [GAME_STATE_CREATE, GAME_STATE_WAITING_FOR_PLAYERS, GAME_STATE_PRE_START, GAME_STATE_START, GAME_STATE_RESET, GAME_STATE_RESET_ROUND, GAME_STATE_DEAL, GAME_STATE_SHUFFLE,
                                                        GAME_STATE_BID, GAME_STATE_PASS, GAME_STATE_PLAY, GAME_STATE_AUTO_FINISH, GAME_STATE_ROUND_OVER, GAME_STATE_GAME_OVER]), GAME_STATE_CREATE),
    joinable: types.optional(types.number, 0), // indicate if game is currently in a state that is joinable
    seatsTurn: types.maybeNull(types.reference(SeatState)), // reference to the seat whose turn it currently is, maybe means it's optional, and could be null
    turnStart: types.maybeNull(types.Date), // the time the current seat's turn started
    turnTimerId: types.maybeNull(types.string), // id of turn timeout timer
    turnId: types.optional(types.number, 0), // turnId is incremented every time setSeatsTurn is called, so that the turnTimer can tell if we're on the same turn or not

    allowUndo: types.optional(types.boolean, true),   // default to allowing undo, TrickGameState sets false
    dateCreated: types.maybeNull(types.Date),
    dateCompleted: types.maybeNull(types.Date),
    nextGameId: types.maybeNull(types.string),  // the id of the next game if a player clicked Play Again

    // children
    options: types.optional(OptionsState, {}),
    players: types.array(PlayerState),
    seats: types.array(SeatState),
    teams: types.array(TeamState),
    timers: types.optional(TimersState, {}),

    // When adding more properties be sure to review if they need to be copied in GameState.applySyncToServerSnapshot
  })
  // .volatile see https://github.com/mobxjs/mobx-state-tree#volatile-state
  .volatile((self) => ({
    rng: null, // random number generator
    rngInitialState: null, // initial state of rng after load
    undoManager: null as IResettableUndoManager,
    turnTimedOut: false,  // temporarily track if the current seat's turn timed out
  }))
  .views((self) => {
    const superGetSyncToServerSnapshot = self.getSyncToServerSnapshot;

    function isGameOver(): boolean {
      // overridden by subclass
      return false;
    }

    /** finds a seat by id, or if we're passed a ISeatState just return it. Useful for resolving params like seatOrId
     * @param seatOrId  can be an ISeatState or a id of seat
     */
    function getSeat(seatOrId: ISeatState | string): ISeatState {
      if(typeof seatOrId === "string")
        return self.seats.find((seat) => seat.id === seatOrId);
      else
        return seatOrId;
    }

    /** finds a player's state
     * @param playerId  string
     */
    function getPlayer(playerId: string): IPlayerState {
      return self.players.find((player) => player && player.id === playerId);
    }

    function getPlayerByName(name: string): IPlayerState {
      return self.players.find((player) => player && player.name === name);
    }

    /** returns true if playerId is the original creator of this game, ie player 0 */
    function isPlayerCreator(playerId: string) {
      // If the player is first in the list of players, then assume they are the creator. Hopefully this is good enough and we don't need to add a flag to indicate which player is creator.
      const playerIndex = self.players.findIndex((player) => player.id === playerId);
      return playerIndex === 0;
    }

    /** finds a player's seat
     * @param playerId  string
     */
    function getPlayerSeat(playerId: string): ISeatState {
       return self.seats.find((seat) => seat.player && seat.player.id === playerId);
    }

    /** Returns count of human players in the game, including watchers */
    function getHumanPlayerCnt() {
      let cnt = 0;
      self.players.forEach((player) => {
        if(!player.bot)
          cnt += 1;
      });
      return cnt;
    }

    /** Returns count of human players in seats */
    function getHumanPlayersInSeatsCnt() {
      let cnt = 0;
      self.seats.forEach((seat) => {
        if(seat.player && !seat.player.bot)
          cnt += 1;
      });
      return cnt;
    }

    /**
     * Get a random seat using the seeded rng
     */
    function getRandomSeat() {
      let seatIndex = Math.floor(self.rng() * self.seats.length);
      return self.seats[seatIndex];
    }

    /**
     * Get the next seat from the given seat, wrapping around to 0
     */
    function getNextSeat(lastSeat: ISeatState) {
      // prevent error that was reported to sentry once.
      if(!lastSeat)
        return self.seats[0];

      let lastIndex = self.seats.findIndex((seat) => seat.id === lastSeat.id);
      let nextIndex = (lastIndex + 1) % self.seats.length;
      return self.seats[nextIndex];
    }

    /** finds a team by id, or if we're passed a ITeamState just return it. Useful for resolving params like teamOrId
     * @param teamOrId  can be an ITeamState or a id of team
     */
    function getTeam(teamOrId: ITeamState | string): ITeamState {
      if(typeof teamOrId === "string")
        return self.teams.find((team) => team.id === teamOrId);
      else
        return teamOrId;
    }

    /**
     * Get the team for a given seat
     */
    function getTeamForSeat(seatOrId: ISeatState | string): ITeamState {
      let seatId = (typeof seatOrId === "string") ? seatOrId : seatOrId.id;
      for(let team of self.teams) {
        for(let teamSeatId of team.seats) {
          if(teamSeatId === seatId)
            return team;
        }
      }
      return null;
    }

    /** Finds an available seat, returns null if there is none
     * @param requestedSeatId the user can request a specific seat, they might not get it though
     */
    function findAvailableSeat(requestedSeatId: string = null) {
      let foundSeat = null;
      // check if they requested a specific seat and if it is still available
      if(requestedSeatId !== null && requestedSeatId !== undefined) {
        let seat = getSeat(requestedSeatId);
        if(!seat.player || seat.player.bot)
          foundSeat = seat;
      }

      // next look for any open seat, or seat with a bot
      if(!foundSeat) {
        self.seats.some((seat: any) => {
          if(!seat.player || seat.player.bot) {
            foundSeat = seat;
            return true; // break some loop
          }
        });
      }
      return foundSeat;
    }

    /** getSeatTurnTimeout calculates the timeout length for a seat. It speeds up the timeout if the player in that seat
     * repeatedly doesn't play.
     */
    function getSeatTurnTimeout(seatOrId: ISeatState | string) {
      // overridden by TrickGameState
      // No timeout if there is only 1 human player
      if(getHumanPlayersInSeatsCnt() < 2)
        return 0;

      // get default timeout from options
      let timeout = self.options.turnTimeoutPlay;
      if(self.status === GAME_STATE_ROUND_OVER || self.status === GAME_STATE_BID)
        timeout = self.options.turnTimeoutNewRound;

      // check if we should speed up the timeout
      let seat = getSeat(seatOrId);
      if(timeout && seat && seat.player) {
        if(seat.player.missedTurns) {
          timeout = timeout / (2 * seat.player.missedTurns);
          timeout = Math.max(timeout, 5); // make sure timeout is at least 5 seconds
        }
      }

      return timeout;
    }

    /** Check if the current seat has taken too long to play. */
    function isTurnTimedOut() {
      let timeout = getSeatTurnTimeout(self.seatsTurn);
      if(!self.seatsTurn || !timeout || !self.turnStart)
        return false;

      let timeoutDate = self.turnStart;
      timeoutDate.setSeconds(timeoutDate.getSeconds() + timeout);

      return (timeoutDate <= new Date());
    }

    /** Return a detailed list describing each won trick in the round for the round summary, also used by PlayerSystem */
    function getWonTricksForSeat(seatOrId: ISeatState | string, summaryMode = false): WonTricks {
      return null;
    }

    /** add properties to snapshot to send to server */
    function getSyncToServerSnapshot(): any {
      let snapshot = superGetSyncToServerSnapshot();

      // If the game is over then we only need to send the results of the game
      if(self.status === GAME_STATE_GAME_OVER) {
        snapshot.status = self.status;

        // send team scores
        snapshot.teams = [];
        self.teams.forEach((team) => {
          snapshot.teams.push({
            id: team.id,
            score: team.score,
            bags: team.bags,
            place: team.place,
          });
        });
      } else {
        // we're syncing during the middle of the game, so just send the whole snapshot
        // applySyncToServerSnapshot on the server will decide what to copy out of it
        snapshot = getSnapshot(self);
      }

      return snapshot;
    }

    /** returns true if all seat's ready property is true */
    function areAllSeatsReady(): boolean {
      let ready = true;
      self.seats.forEach((seat) => {
        if(!seat.ready)
          ready = false;
      });
      return ready;
    }

    return { areAllSeatsReady, findAvailableSeat, getHumanPlayerCnt, getHumanPlayersInSeatsCnt, isGameOver, isPlayerCreator, isTurnTimedOut, getPlayer, getPlayerByName, getPlayerSeat, getSeat, getSeatTurnTimeout, getSyncToServerSnapshot, getRandomSeat, getNextSeat, getTeam, getTeamForSeat, getWonTricksForSeat };
  })
  .views((self) =>  {
    function chooseRandomBotName() {
      return BOT_NAME_LIST[Math.floor(Math.random() * BOT_NAME_LIST.length)];
    }

    function chooseRandomBotNameNotTaken() {
      let filteredNames = BOT_NAME_LIST.filter( (name) => !self.getPlayerByName(name) );
      if(!filteredNames.length)
        return "Bot " + Math.floor(Math.random() * 1000000);
      return filteredNames[Math.floor(Math.random() * filteredNames.length)];
    }

    return { chooseRandomBotName, chooseRandomBotNameNotTaken };
  })
  .actions((self) => {
    function afterCreate() {
      // if we already have a seed, then auto create rng
      if(self.rngState) {
        self.rng = seedrandom(self.rngState, {state: true});
      }
      else if(self.seed) {
        self.rng = seedrandom(String(self.seed), {state: true});
      }

      if(self.rng)
        self.rngInitialState = self.rng.state();

      if(config.haveUndo)
        self.undoManager = ResettableUndoManager.create({}, { targetStore: self });
    }

    function initSeed() {
      // generate a random game seed copied to (state-sync.ts)
      // Note that this is 9007199254740991 out of a possible 80658175170943878571660636856403766975289505440883277824000000000000 permutations, which is very close to 0%
      let maxSafeInteger = 9007199254740991; // same as Number.MAX_SAFE_INTEGER from es6
      self.seed = Math.floor(Math.random() * maxSafeInteger);
      //self.seed = 1426947369487267; // AH on tableau4
      //self.seed = 1659135334458734; // AC on tableau4, AS on tableau5
      //self.seed = 8083695376306666; // 3 aces in tableau
      //self.seed = 8187216987220782; // move 7 -> 8 -> 9 -> 10 -> J

      self.rng = seedrandom(String(self.seed), {state: true});
      self.rngInitialState = self.rng.state();
    }

    function createState(snapshot: any): Promise<boolean> {
      // overridden by subclass to start a new game
      initSeed();
      if(snapshot.options)
        self.options = OptionsState.create(snapshot.options);

      return new Promise<boolean> ((resolve, reject) => { resolve(true); });
    }

    /** Starts the game, ie in spades after 4 players have joined shuffle and deal cards */
    function startGame() {
      // overridden by subclass
      setStatus(GAME_STATE_START);
    }

    /** Seat turn timed out, so make a play for them */
    function forcePlay(seat: ISeatState) {
      // overridden by subclass
    }

    function setStatus(status: GameStateStatus) {
      self.status = status;
      updateJoinable();
    }

    /** internal function to update joinable flag. to be joinable there must be at least one human player.
     * so that others won't try to join game after the last person leaves.
     */
    function updateJoinable() {
      if(self.options.multiplayer && joinableStates.indexOf(self.status) !== -1 && self.getHumanPlayersInSeatsCnt())
        self.joinable = 1;
      else
        self.joinable = 0;
    }

    return { afterCreate, forcePlay, initSeed, createState, startGame, setStatus, updateJoinable };
  })
  .actions((self) => {

    /** internal function to start a game in GAME_STATE_WAITING_FOR_PLAYERS when all players are present */
    function startGameIfAllPlayersPresent() {
      // if game has no empty seats then it's full, so auto start
      let emptySeat = self.seats.find((checkSeat) => checkSeat.player === null);
      if(self.status === GAME_STATE_WAITING_FOR_PLAYERS && !emptySeat)
      {
        self.setStatus(GAME_STATE_PRE_START);
        // check if online multiplayer games with only 1 player should be played offline
        // The client will go offline when offline is set true
        if(!self.options.standAlone && config.offlineSingleplayer && self.getHumanPlayerCnt() === 1)
          self.offline = true;
        //else if(!self.offline) // if the game is already offline, don't auto start (This prevented second game from starting. -Dan 2/16/2024)
        self.startGame();
      }
    }

    /** called when all seats ready flag is true */
    function onAllSeatsReady() {
      // overridden by subclass
    }

    return { onAllSeatsReady, startGameIfAllPlayersPresent };
  })
  .actions((self) => {
    const superResetState = self.resetState;

    /** Creates any needed children for the basic structure of a game, ie piles, seats, teams, etc */
    function init() {
      // overridden by subclass
    }

    /** Create the count of seats */
    function createSeats(count: number) {
      for (let id = 0; id < count; id++) {
        let seat = SeatState.create({
          id: id.toString(),
        });
        self.seats.push(seat);
      }
    }

    /** Create the count of teams */
    function createTeams(count: number) {
      for (let id = 0; id < count; id++) {
        let team = TeamState.create({
          id: id.toString(),
        });
        self.teams.push(team);
      }
    }

    /** Add a player to the game state
     * @param userId  - id of the user that is requesting the player to be added
     * @param id - the id of the player being added
     * @param name - name of player being added
     * @param seatId - id of seat to place the player in, if seat is full will look for next open seat. optional maybe null to request any seat
     * @param bot - flag if this is a bot ai player or human
     */
    function addPlayer(userId: string, id: string, name: string, seatId: string, bot: boolean, imageUrl: string = null) {
      let foundSeat = self.findAvailableSeat(seatId);

      // no available seats.
      if(!foundSeat) {
        logger.info("addPlayer no open seats in game", { id: self.id, userId});
        return;
      }

      // check if the player is already in the player list, they may have left and came back.
      let player = self.getPlayer(id);
      if(!player) {

        if(!name) {
          if(bot)
            name = self.chooseRandomBotNameNotTaken();
          else
            name = "Player " + seatId;
        }

        player = PlayerState.create({
            id: id,
            name: name,
            imageUrl: imageUrl,
            bot: bot,
        });
        self.players.push(player);
      }

      // auto request seat if not an offline game, the offline client will see that we're added to the player list,
      // send the offline state to the server, which will then set the game online. At that point the client that just joined
      // will re-join and be added to a seat normally.
      if(!self.offline || bot)
        requestSeat(id, seatId);

      self.updateJoinable();

      return player;
    }

    /** request to join a seat. note they may end up in a different seat if seatId is already taken. */
    function requestSeat(playerId: string, seatId: string = null) {
      let player = self.getPlayer(playerId);
      let seat = self.findAvailableSeat(seatId);
      if(!player) {
        logger.info("requestSeat player not in game", { id: self.id, playerId});
        return;
      }

      if(!seat) {
        logger.info("requestSeat player no open seats in game", { id: self.id, playerId});
        return;
      }

      // if there is a bot in the seat, remove them first.
      if(seat.player && seat.player.bot)
        seat.player.setStatus(PLAYER_STATE_LEFT);

      // check if they are already in a seat
      let oldSeat = self.getPlayerSeat(playerId);
      if(oldSeat) {
        // only allow switching seats before the game has started
        if(self.status === GAME_STATE_WAITING_FOR_PLAYERS) {
          oldSeat.player = null;
        }
        else {
          logger.info("requestSeat player tried to switch seats after game started game", { id: self.id, playerId});
          return;
        }
      }

      // put player in seat
      if(self.status === GAME_STATE_WAITING_FOR_PLAYERS || self.status === GAME_STATE_CREATE)
        player.status = PLAYER_STATE_PLAYER;
      else // if they're not joining at the start of the game, then they're a substitute
        player.status = PLAYER_STATE_SUB;
      seat.player = player;

      self.startGameIfAllPlayersPresent();
    }

    /** action to call when a player wants to leave the game. Auto fills empty seat with bot */
    function leaveGame(userId: string) {
      let player = self.getPlayer(userId);
      let seat = self.getPlayerSeat(userId);
      if(player)
        player.setStatus(PLAYER_STATE_LEFT);

      // remove them from the seat, and replace with bot if there is another human in the game
      if(seat) {
        seat.player = null;
        if(self.getHumanPlayersInSeatsCnt() && self.status !== GAME_STATE_WAITING_FOR_PLAYERS)
          addPlayer(userId, seat.id, null, seat.id, true);

        self.updateJoinable();
      }
    }

    /** update a players name and image url
     * @param userId - this will be verified on the server to be sure that a user can only update their own player info
     */
    function updatePlayerInfo(userId: string, name: string, imageUrl: string, socketId: string) {
      let player = self.getPlayer(userId);
      if(player)
        player.updateInfo(name, imageUrl, socketId);
    }

    function endGame() {
      self.setStatus(GAME_STATE_GAME_OVER);
      self.dateCompleted = new Date();
    }

    function resetState() {
      superResetState();

      // overridden by subclass
      self.setStatus(GAME_STATE_RESET);

      self.seats.forEach((seat) => {
        seat.reset();
      });

      self.teams.forEach((team) => {
        team.reset();
      });

      // remove all players from game
      self.players.forEach((player) => {
        destroy(player);
      });

      self.setStatus(GAME_STATE_CREATE); // return to default state
      self.id = "";
    }

    function undo() {
      // Don't allow undo if the game is ending or ended
      // These states appear to be ok: GAME_STATE_CREATE, GAME_STATE_RESET, GAME_STATE_DEAL
      if(self.status === GAME_STATE_AUTO_FINISH || self.status === GAME_STATE_GAME_OVER || !self.allowUndo)
        return;

      // we need to call undo in withoutUndo, otherwise, the next time undo is called, it undoes the undo! ie loops moving the same card back and forth
      if(self.undoManager && self.undoManager.canUndo)
        self.undoManager.withoutUndo(() => { self.undoManager.undo(); });
    }

    /** Set the seat of the current turn. Will start a timer if they have a limited amount of time to play. */
    function setSeatsTurn(seat: ISeatState) {
      self.turnId += 1;
      // cancel the previous seats turnTimer
      if(self.turnTimerId) {
        self.timers.removeTimer(self.turnTimerId);
        self.turnTimerId = null;
      }

      // if the seat has a turnTimeout then that is the number of seconds they have to make a play. No need to add timers for bots though
      let timeout = self.getSeatTurnTimeout(seat);
      if(timeout && seat.player && !seat.player.bot)
        self.turnTimerId = self.timers.addTimer(timeout, self, "processTurnTimeout", [self.turnId]);

      self.turnStart = new Date();
      self.seatsTurn = seat; // AIs will be notified that it is their seats turn immediatly, so don't do anything after setting seatsTurn
    }

    /** set seats ready property. If all are ready it calls onAllSeatsReady */
    function setSeatReady(userId: string, seatOrId: ISeatState | string, ready: boolean) {
      // todo be sure the user can only set their own seat ready

      let seat = self.getSeat(seatOrId);
      seat.ready = ready;

      if(self.areAllSeatsReady())
        self.onAllSeatsReady();
    }

    /** sets all seats ready propterty to false */
    function resetReady() {
      self.seats.forEach((seat) => {
        seat.ready = false;
      });
    }

    /** goto the next seats turn */
    function nextSeatsTurn() {
      let seatsTurnIndex = self.seats.findIndex((seat) => seat.id === self.seatsTurn.id);

      // check if the current seat's turn timed out to inc or reset that seat's players missed turn count
      if(self.turnTimedOut) {
        self.turnTimedOut = false;
        if(self.seatsTurn.player)
          self.seatsTurn.player.incMissedTurns();
      }
      else if(self.seatsTurn.player)
        self.seatsTurn.player.resetMissedTurns();

      seatsTurnIndex += 1;
      if(seatsTurnIndex >= self.seats.length)
        seatsTurnIndex = 0;

      setSeatsTurn(self.seats[seatsTurnIndex]);
    }

    /** Called on a timer to force a seat to play the current turn
     * @param turn the turn number to timeout, if it is no longer this turn, then ignore
     */
    function processTurnTimeout(turnId: number) {
      // check to make sure we're on the same turn.
      if(turnId === self.turnId && self.seatsTurn !== null) {
        self.turnTimedOut = true;
        self.forcePlay(self.seatsTurn);
      }
    }

    return { init, createSeats, createTeams, addPlayer, requestSeat, leaveGame, updatePlayerInfo, endGame, resetState, undo, resetReady, setSeatsTurn, setSeatReady, nextSeatsTurn, processTurnTimeout };
  })
  .actions((self) => {
    const superApplySyncToServerSnapshot = self.applySyncToServerSnapshot;
    const superPrepForSnapshot = self.prepForSnapshot;

    /** apply snapshot from client to sync offline game */
    function applySyncToServerSnapshot(userId: string, snapshot: any): boolean {
      // super function will verify if it is ok to apply snapshot
      if(!superApplySyncToServerSnapshot(userId, snapshot))
        return false;

      // copy scores from teams
      if(snapshot.teams) {
        snapshot.teams.forEach((team: any) => {
          let teamState = self.getTeam(team.id);
          teamState.setScore(team.score);
          teamState.setBags(team.bags);
          teamState.place = team.place;
        });
      }

      // copy info from seats
      if(snapshot.seats) {
        snapshot.seats.forEach((seat: any) => {
          let seatState = self.getSeat(seat.id);
          seatState.score = seat.score;
          seatState.roundScore = seat.roundScore;
          seatState.ready = seat.ready;

          // trick game specific
          seatState.bid = seat.bid;
          seatState.tricksWon = seat.tricksWon;
        });
      }

      // Check if player left game, this is to watch for players leaving an offline singleplayer game
      if(snapshot.players) {
        snapshot.players.forEach((player: any) => {
          if(player.status === PLAYER_STATE_LEFT)
            self.leaveGame(player.id); // call leaveGame instead of just setting status to also remove them from the seat
        });
      }

      // set game status
      if(snapshot.status === GAME_STATE_GAME_OVER)
        self.endGame(); // sets status and dateCompleted
      else {
        // the game is not over, so copy more of the game state to be able to resume it
        self.setStatus(snapshot.status);

        self.seatsTurn = snapshot.seatsTurn;
        self.turnId = snapshot.turnId;
        self.turnStart = new Date(); // if turnStart isn't set it can cause an error in TurnIndicatorSystem

        if(self.getHumanPlayerCnt() > 1 && self.offline)
          self.offline = false;

        // remember subclasses will load more of the state, like the piles
      }

      return true;
    }

    /** User is requesting the game to start. It will auto fill empty seats with bots, which will trigger the game to start
     * when all seats are full
     */
    function requestStartGame(userId: string) {
      fillEmptySeats(userId);

      // In a one player game, the one player will already be in their seat and the game will be in GAME_STATE_WAITING_FOR_PLAYERS
      // fillEmptySeats won't call addPlayer and the game will never start
      // For this case, we also check for all players here
      self.startGameIfAllPlayersPresent();
    }

    /** auto fill any empty seats with bots, which will trigger the game to auto start when all seats are full if game status is GAME_STATE_WAITING_FOR_PLAYERS */
    function fillEmptySeats(userId: string) {
      self.seats.forEach((seat) => {
        if(!seat.player)
          self.addPlayer(userId, seat.id, null, seat.id, true);
      });
    }

    /** Called when a player wants to play again to generate the next game id. */
    function requestNextGameId(userId: string) {
      if(!self.nextGameId)
        self.nextGameId = uuid();
    }

    function prepForSnapshot(userId?: string) {
      superPrepForSnapshot(userId);
      // don't send seed and rngState to users
      if(userId) {
        self.rngState = "";
        self.seed = 0;
      }
      else
      {
        // if we're not prepping for a user, then we are prepping to save to database.
        // Be sure to only update rngState if self.rng.State() has changed
        if(!self.rngState || !_.isEqual(self.rngInitialState, self.rng.state())) {
          // it would be possible to save self.rng.state(), but it is an array of 256 1 byte numbers, so it's much smaller to just save the next random number
          // and then use that as a seed when this state is loaded next
          self.rngState = String(Math.floor(self.rng() * Number.MAX_SAFE_INTEGER));
        }
      }
    }

    return { applySyncToServerSnapshot, fillEmptySeats, prepForSnapshot, requestNextGameId, requestStartGame };
  });

// GameState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IGameState = typeof GameState.Type;

// Define an error callback for passing an error message back from some actions or views
type IGameStateErrorCallback = (errorMsg: string) => void;

export { GameState, IGameState, IGameStateErrorCallback };
