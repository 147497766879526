import { types } from "mobx-state-tree";

import { LeaderboardState } from "./LeaderboardState";

// tslint:disable-next-line:variable-name
const LeaderboardsState =  types
  .model("LeaderboardsState", {
    boards: types.array(LeaderboardState),
  })
  .actions((self) => {

    /** gets a LeaderboardState by name, if it doesn't exist it will create it */
    function getLeaderboard(leaderboardName: string) {
      let foundBoard = self.boards.find((board) => board.name === leaderboardName);
      if(!foundBoard) {
        let newBoard = LeaderboardState.create({name: leaderboardName});
        self.boards.push(newBoard);
        foundBoard = newBoard;
      }
      return foundBoard;
    }
    return { getLeaderboard };
  })
  .views((self) => {
    return { };
  });

// LeaderboardsState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type ILeaderboardsState = typeof LeaderboardsState.Type;

export { LeaderboardsState, ILeaderboardsState };
