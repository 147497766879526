import { Animation } from "@babylonjs/core/Animations/animation";
import { Color3 } from "@babylonjs/core/Maths/math";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Scene } from "@babylonjs/core/scene";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";

/**
 * Extends Rectangle so that the background color can be animated using Color3
 * Also supports setting animatableWidth && animatableHeight in pixels, animatablePosition and animatableScale
 *
 * NOTE: There is also now GUIAnimationProxy, which wraps any control to make these properties animatable
 */
export class AnimatableRectangle extends Rectangle {
  targetColor: Color3 = new Color3(0, 0, 0);
  animatablePositionOffset: Vector2 = Vector2.Zero();

  get backgroundColor3() {
    return Color3.FromHexString(this.background);
  }

  set backgroundColor3(color: Color3) {
    this.background = color.toHexString();
  }

  set animatableWidth(widthInPixels: number) {
    this.width = widthInPixels + "px";
  }

  set animatableHeight(heightInPixels: number) {
    this.height = heightInPixels + "px";
  }

  set animatablePosition(pos: Vector2) {
    let offsetPos = pos.add(this.animatablePositionOffset);
    this.left = offsetPos.x;
    this.top = offsetPos.y;
  }

  set animatableScale(scale: number) {
    this.scaleX = scale;
    this.scaleY = scale;
  }

  animateBackgroundColor(name: string, color: Color3, durationInSeconds: number, scene: Scene) {
    if(this.targetColor.equals(color))
      return;

    // Make sure this object isn't already animating
    let animateable = scene.getAnimatableByTarget(this);
    if(animateable)
      animateable.stop(); // Animatable.stop also disposes the animation

    this.targetColor = color;

    let a = new Animation(name, "backgroundColor3", 30, Animation.ANIMATIONTYPE_COLOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = Math.floor(durationInSeconds * 30.0);
    keyFrames.push({frame: k0, value: this.backgroundColor3});
    keyFrames.push({frame: k1, value: color});
    a.setKeys(keyFrames);
    scene.beginDirectAnimation(this, [a], 0, k1, false);
  }
}
