import { ArgoSystem } from "components/game/ArgoSystem";
import { disposeToast, toast } from "components/utils/GUI";
import { CONNECTION_STATUS_CONNECTING } from "states/RootState";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";

/** Watch for RootState.connectionStatus being set and show a Connecting toast message
 */
export class ConnectionStatusSystem extends ArgoSystem {
  delayTimeoutId: any; // id timeout to delay showing Connecting toast, used to cancel timeout

  init() {
    this.rootState.router.addRoute("^\/connectionStatus$", (patch: any, reversePatch: any, params: any) => this.updateToast());
    this.rootState.router.addRoute("^\/user\/applySnapshotStage$", (patch: any, reversePatch: any, params: any) => this.updateToast());
  }

  createGUI() {
    this.updateToast();
  }

  afterResized() {
    this.updateToast();
  }

  toastDelayed(name: string, msg: string) {
    if(this.delayTimeoutId)
      clearTimeout(this.delayTimeoutId);
    this.delayTimeoutId = setTimeout(() => {
      toast(name, msg, 0, -1, false);
    }, 2000);
  }

  cancelToast(name: string) {
    if(this.delayTimeoutId) {
      clearTimeout(this.delayTimeoutId);
      this.delayTimeoutId = null;
    }
    disposeToast(name);
  }

  /** Show/Hide Connecting... and Signing In toast messages */
  updateToast() {
    if(this.rootState.connectionStatus === CONNECTION_STATUS_CONNECTING) {
      // delay showing toast message for a few seconds, to avoid flashing it up for real short reconnects
      this.toastDelayed("ConnectingToast", "Connecting...");
    }
    else {
      this.cancelToast("ConnectingToast");

      // Wait for a snapshot to be applied to user to indicate user is fully signed in and UserState loaded
      if(this.rootState.user.applySnapshotStage !== APPLY_SNAPSHOT_STAGE_DONE)
        this.toastDelayed("AuthToast", "Signing In...");
      else
        this.cancelToast("AuthToast");
    }
  }
}
