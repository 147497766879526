import { config, mergeConfig } from "utils/Config";
import "../../help-config";

mergeConfig({
  help: {

    // Introduce quick play on homescreen, remember this is typically shown after they play a tutorial game.
    /*homeScreen: {
      type: "tutorial",
      maxViews: 1,
      message: "Classic Spades is played to 500 points, which can take a while.",
      nextHelp: "homeScreen_1",
    },
    homeScreen_1: {
      type: "tutorial",
      message: "In Quick Play you play 1 hand at a time. The score for each hand is added to your score underneath the hearts.",
      nextHelp: "homeScreen_2",
    },
    homeScreen_2: {
      type: "tutorial",
      message: "Lose a heart when you lose a hand. Lose all hearts and your final score is sent to the leaderboard.",
    },*/

    // Bidding first hand
    tutorial_firstBid: {
      type: "tutorial",
      message: "Welcome to Insta Spades the fun multiplayer trick taking card game. Don't worry if you don't know how to play, we'll teach you.",
      nextHelp: "tutorial_firstBid_1",
    },
    tutorial_firstBid_1: {
      type: "tutorial",
      message: "Select the number of tricks you think you can win. Just select 2 for now and then tap bid.",
    },

    // bidding second hand
    tutorial_secondBid: {
      type: "tutorial",
      message: "Now that you know how to win tricks, think about which cards in your hand could win a trick.",
      nextHelp: "tutorial_secondBid_1",
    },
    tutorial_secondBid_1: {
      type: "tutorial",
      message: "A good start is to add 1 for each Ace and then add a few more if you have lots of Spades.",
      nextHelp: "tutorial_secondBid_2",
    },
    tutorial_secondBid_2: {
      type: "tutorial",
      message: "Bid NIL if you don't think you can win any tricks. This is worth 100 points! Beware if you do end up winning a trick then it is -100!",
    },

    // General info on first play of first hand
    tutorial_firstHandPlay: {
      type: "tutorial",
      message: "Each player plays a card to the middle. This is called a trick.",
      nextHelp: "tutorial_firstHandPlay_1",
    },
    tutorial_firstHandPlay_1: {
      type: "tutorial",
      message: "The highest card of the same suit wins the trick. Aces are high. Highest Spade beats all other cards.",
      nextHelp: "tutorial_firstHandPlay_2",
    },
    tutorial_firstHandPlay_2: {
      type: "tutorial",
      message: "Go ahead and tap on a card now.",
    },

    // General info on first play of second hand
    tutorial_secondHandPlay: {
      type: "tutorial",
      message: "The stack of grey bars next to each Player show how many they bid.",
      nextHelp: "tutorial_secondHandPlay_1",
    },
    tutorial_secondHandPlay_1: {
      type: "tutorial",
      message: "The bar turns yellow when a trick is won. The bars turn blue when you and your partner win enough tricks.",
      nextHelp: "tutorial_secondHandPlay_2",
    },
    tutorial_secondHandPlay_2: {
      type: "tutorial",
      message: "The red dots above the bar graph on the top right shows how many you and your partner bid. The bars indicate how many tricks won.",
    },

    // Playing Cards
    tutorial_leadProtectedSuitNotBroken : {
      type: "tutorial",
      message: "You may start with any card except a Spade. Spades can't be played first until one has been played in another trick.",
    },

    tutorial_protectedSuitBroken: {
      type: "tutorial",
      message: "You may now lead with a Spade if you want to.",
    },

    tutorial_followingSuit: {
      type: "tutorial",
      message: "You may only play the same suit as the first card, a {{extra.leadSuit}}",
    },

    tutorial_notFollowingSuit: {
      type: "tutorial",
      message: "You don't have any {{extra.leadSuit}}s so you may play any card. Remember the highest Spade beats all other cards.",
    },

    // Winning Tricks
    tutorial_playerWonTrick: {
      type: "tutorial",
      message: "You won the trick with {{extra.winPiece}}. That'll be 10 points for your team if you make your bid.",
      pauseGame: true,
    },
    tutorial_partnerWonTrick: {
      type: "tutorial",
      message: "Our partner {{extra.partnerName}} won the trick with {{extra.winPiece}}!",
      pauseGame: true,
    },
    tutorial_opponentWonTrick: {
      type: "tutorial",
      message: "Our opponents won the trick with {{extra.winPiece}}.",
      pauseGame: true,
    },

    tutorial_wonTrickTrump: {
      type: "tutorial",
      message: "{{extra.playerWonTrick}} won the trick with a Spade! Remember the highest Spade beats all other cards. Spades may now be played first.",
      pauseGame: true,
    },

    // Making Bids
    tutorial_playerMadeBid: {
      type: "tutorial",
      message: "You have made your bid of {{extra.bid}} by winning {{extra.tricksWon}} tricks. Your partner {{extra.partnerName}} needs to make their bid for it to count",
      pauseGame: true,
    },
    tutorial_playerFailedNil: {
      type: "tutorial",
      message: "Uh oh you won a trick after bidding NIL. That's -100 points!",
      pauseGame: true,
    },
    tutorial_partnerFailedNil: {
      type: "tutorial",
      message: "Your partner {{extra.partnerName}} bid NIL and just won a trick. That's -100 points!",
      pauseGame: true,
    },
    tutorial_partnerMadeBid: {
      type: "tutorial",
      message: "Your partner {{extra.partnerName}} made their bid of {{extra.partnerBid}} by winning {{extra.partnerTricksWon}} tricks. Now you need to make your bid for it to count",
      pauseGame: true,
    },
    tutorial_teamMadeBid: {
      type: "tutorial",
      message: "Your team made your bids! You bid {{extra.teamBid}} together earning {{extra.teamPoints}} points.",
      pauseGame: true,
    },

    // Bagging
    tutorial_secondHandTookBag: {
      type: "tutorial",
      message: "Your team won more tricks then you bid. Extra tricks are called bags worth 1 point each. Beware if you get 10 bags then it is -100!",
    },
  },
});
