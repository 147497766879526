import { ArgoSystem } from "components/game/ArgoSystem";
import { game } from "components/game/Game";
import { PlayerSystem } from "components/game/PlayerSystem";
import { SpadesRoundSummaryGUI } from "components/game/summary-screen/SpadesRoundSummaryGUI";
import { Commands } from "components/ui/Commands";
import { LeaderboardSidebarSystem } from "components/ui/LeaderboardSidebarSystem";
import { UserStatusSystem } from "components/ui/UserStatusSystem";
import { findGuiControl, getControlGlobalPosition, toast } from "components/utils/GUI";
import { GAME_STATE_GAME_OVER, GAME_STATE_RESET, GAME_STATE_ROUND_OVER } from "states/game/GameState";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";
import { config } from "utils/Config";

export class SpadesRoundSummary extends ArgoSystem {
  showSummary = false;
  pausedPatchQueue = false;
  gui: SpadesRoundSummaryGUI = null;

  isAnimating: boolean = false;

  init() {
    this.rootState.router.addRoute("^\/game\/round$", (patch: any, reversePatch: any, params: any) => this.onRoundChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.onGameStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/user\/bidDisplayMode$", (patch: any, reversePatch: any, params: any) => this.onUserBidDisplayMode());
    this.rootState.router.addRoute("^\/game\/nextGameId$", (patch: any, reversePatch: any, params: any) => this.onNextGameIdChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/applySnapshotStage$", (patch: any, reversePatch: any, params: any) => this.onApplySnapshotStage(patch, reversePatch, params));

    this.game.animationSystem.onAnimationBlockingObservable.add(() => this.onAnimationBlockingChanged()); // Watch for card animations to be done
  }

  onRoundChanged(patch: any, reversePatch: any, params: any) {
    this.dispose();
  }

  // Called when the round is over, but not the game
  onRoundOver() {
    if(this.systemConfig.showRoundSummaries !== false) {
      if(this.game.animationSystem.isBlockingRoundEnd())
        this.showSummary = true;
      else
        this.create();
    } else {
      // If we're not showing the summary, we still need to show an ad

      // Pause Patches?
      Commands.onShowAd("roundOver", game.adSystem.shouldShowAd(), () => {
        // Resume patches?
      });
    }
  }

  checkGameOver() {
    if(this.game.gameState.status !== GAME_STATE_GAME_OVER)
      return;

    if(this.game.animationSystem.isBlockingRoundEnd())
      return;

    let summary = findGuiControl("SpadesRoundSummary");
    if(summary !== null)
      return;

    this.create();
  }

  onGameStatusChanged(patch: any, reversePatch: any, params: any) {
    if(patch.value === GAME_STATE_ROUND_OVER)
    {
      this.onRoundOver();
      return;
    }

    if (patch.value === GAME_STATE_GAME_OVER) {
      this.checkGameOver();
    }

    if (patch.value === GAME_STATE_RESET) {
      this.pausedPatchQueue = false; // state reset clears the pause count, so we shouldn't call unpause
      this.dispose();
    }
  }

  onApplySnapshotStage(patch: any, reversePatch: any, params: any) {
    if(patch.value === APPLY_SNAPSHOT_STAGE_DONE) {
      if(!this.gui && this.game.gameState.status === GAME_STATE_ROUND_OVER) {
        // We have crash reports from creating the summary GUI onApplySnapshotStage, apparently without a WebGL context (create dynmaic vertex buffer fails)
        // If we call onRoundOver instead, create will be delayed until the initial layout animations complete, which happens in the render loop
        // Since the render loop doesn't run without a WebGL context, this will hopefully prevent the errors
        this.onRoundOver();
      }
    }
  }

  onNextGameIdChanged(patch: any, reversePatch: any, params: any) {
    if(this.game.gameState.nextGameId && patch.op === "replace") {
      // Let the player know others are starting a new game
      if(!this.game.requestedNextGame) {
        // Watch out for the buttons
        let width = 0;
        if(this.gui) {
          let control = findGuiControl("SpadesRoundSummaryExit", this.gui);
          if(!control)
            control = findGuiControl("SpadesRoundSummaryContinue", this.gui);
          if(control) {
            let pos = getControlGlobalPosition(control);
            width = pos.x - control.heightInPixels;
          }
        }
        toast("PlayAgainToast", "Others want to Play Again.", width);
      }
    }
  }

  /** This watches specifically for card animations to be done */
  onAnimationBlockingChanged() {
    if(this.game.animationSystem.isBlockingRoundEnd())
      return;

    if(this.showSummary)
      this.create();
    else
      this.checkGameOver();
  }

  onUserBidDisplayMode() {
    if(this.gui)
      this.gui.setBidDisplayMode(this.rootState.user.bidDisplayMode);
  }

  create(test = false) {
    this.showSummary = false;

    if(this.gui) {
      // error?
      return;
    }

    let playerSystem = this.game.systems.get("PlayerSystem") as PlayerSystem;

    // check locally this.gameState.isGameOver() to see if the game is over
    // Normally, because we've paused the patch queue, the game state won't reflect game over yet
    const gameOverMode = game.gameState.status === GAME_STATE_GAME_OVER || game.gameState.isGameOver();

    // Pause patches on the round summary screen so the game state won't advance in the background
    // We don't pause on the game over since it shouldn't be necessary
    if(!this.pausedPatchQueue && !test && !gameOverMode) {
      this.pausedPatchQueue = true;
      this.rootState.game.pausePatchQueue();
    }

    // Auto press the Continue/Play Again button if we're in debug auto play mode
    // Or the user is in a multiplayer game with a turn timeout.
    let continueTimeout: number;
    if(game.debugAutoPlayLocalSeat)
      continueTimeout = 1;
    else {
      let localSeat = game.gameState.getSeat(game.localSeat);
      let seatTimeout = game.gameState.getSeatTurnTimeout(localSeat);
      if(localSeat && seatTimeout && !gameOverMode)
        continueTimeout = seatTimeout * 0.5; // Split time in half, so they get half the time on the summary screen, and half bidding.
    }

    // Create the GUI Summary Screen
    this.gui = new SpadesRoundSummaryGUI(playerSystem, test, gameOverMode, continueTimeout);

    // Listen for events
    this.gui.onDoneObservable.add(() => this.onDone());
    this.gui.onContinueObservable.add(() => this.onContinue());

    // Add User Status bar
    let userStatusSystem = this.game.systems.get("UserStatusSystem") as UserStatusSystem;
    userStatusSystem.create(true, true);

    // Highlight the score
    if(userStatusSystem.metaGamePanel)
      userStatusSystem.metaGamePanel.setScoreColor(config.scoreHighlightColor, config.scoreHighlightShadowBlur, config.scoreHighlightShadowColor);
  }

  dispose(unpausePatchQueue = true) {
    this.showSummary = false;

    if(this.gui) {
      this.gui.dispose();
      this.gui = null;

      // Unfortunately Control doesn't have onDisposeObservable like the 3d objects
      if(unpausePatchQueue && this.pausedPatchQueue) {
        this.pausedPatchQueue = false;
        this.rootState.game.unpausePatchQueue();
      }

      // dispose User Status bar
      (this.game.systems.get("UserStatusSystem") as UserStatusSystem).dispose();
      (this.game.systems.get("LeaderboardSidebarSystem") as LeaderboardSidebarSystem).dispose();
    }
  }

  onContinue() {
    this.dispose();

    Commands.onShowAd("roundOver", game.adSystem.shouldShowAd(), () => {
      if(game.gameState.status === GAME_STATE_GAME_OVER)
        Commands.onRequestPlayAgain();
    });
  }

  onDone() {
    this.dispose();

    Commands.onShowAd("roundOver", game.adSystem.shouldShowAd(), () => {
      Commands.onRequestHomeScreen();
    });
  }

  afterResized(): void {
    let summary = findGuiControl("SpadesRoundSummary");
    if(summary)
    {
      this.dispose(false);
      this.create();
      this.gui.onClick(false);
    }
  }

  newFrame(deltaTime: number): void {
    if(this.gui)
      this.gui.newFrame(deltaTime);
  }

  test(): void {
    this.dispose();
    this.create(true);
  }
}
