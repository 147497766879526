import { getSnapshot } from "mobx-state-tree";
import { getRootState } from "states/RootState";

import { GAME_STATE_BID, GAME_STATE_PASS, GAME_STATE_PLAY } from "states/game/GameState";
import { IPieceState } from "states/game/PieceState";
import { IPileState } from "states/game/PileState";
import { ISeatState } from "states/game/SeatState";
import { ITrickGameState } from "states/game/TrickGameState";
import { config } from "utils/Config";

import { logger } from "utils/logger";

export class TrickGameAI  {
  gameState: ITrickGameState;
  seat: ISeatState;
  hand: IPileState;
  seatsTurnRouteId: number = null;
  statusRouteId: number = null;

  static loggedFallbackWarning = false; // static - we'll log 1 fallback warning per run

  static readonly AUTO_START = "autostart";
  static readonly PLAY_ONCE = "play_once";

  /**
   * @param seatId seat to play as
   * @param gameState
   * @param autoPlay: null to wait for caller to take action, or PLAY_ONCE to make a single play (used for timed out players), or AUTO_START to listen for the seats turn and play automatically
   */
  constructor(seatId: string, gameState: ITrickGameState, autoplay: string = TrickGameAI.AUTO_START) {
    this.gameState = gameState;
    this.setSeat(seatId);

    // Only start listening for this seat's turn if requested
    if(autoplay === TrickGameAI.AUTO_START)
      this.start();

    // Check if it's currently this player's turn
    if(autoplay !== null && (gameState.seatsTurn === this.seat || (gameState.status === GAME_STATE_PASS && !this.seat.ready)))
      this.playTurn();
  }

  /** update which seat/hand the ai is playing for. This can change when starting a new game/playing again */
  setSeat(seatId: string) {
    this.seat = this.gameState.getSeat(seatId);
    this.hand = this.gameState.getPile("hand" + this.seat.id);
  }

  /** start listening for this seats turn */
  start() {
    if(!this.seatsTurnRouteId)
      this.seatsTurnRouteId = getRootState().router.addRoute("^\/game\/seatsTurn$", (patch: any, reversePatch: any, params: any) => this._onSeatsTurnChanged(patch, reversePatch, params));

    if(!this.statusRouteId)
      this.statusRouteId = getRootState().router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this._onStatusChanged(patch, reversePatch, params));
  }

  /** stop listening for this seats turn */
  stop() {
    if(this.seatsTurnRouteId)
      getRootState().router.removeRoute(this.seatsTurnRouteId);
    this.seatsTurnRouteId = null;

    if(this.statusRouteId)
      getRootState().router.removeRoute(this.statusRouteId);
    this.statusRouteId = null;
  }

  /** Internal function that watches for changes to seatsTurn */
  _onSeatsTurnChanged(patch: any, reversePatch: any, params: any) {
    // check if it's our turn and which state the game is in
    if(patch.value === this.seat.id) {
      this.playTurn();
    }
  }

  /** Internal function that watches for changes to game status */
  _onStatusChanged(patch: any, reversePatch: any, params: any) {
    // Check if it's time to pass and we haven't yet
    if(patch.value === GAME_STATE_PASS && !this.seat.ready) {
      this.playTurn();
    }
  }

  logFallbackWarning() {
    if(TrickGameAI.loggedFallbackWarning)
      return;

    TrickGameAI.loggedFallbackWarning = true;

    logger.warn("TrickGameAI made fallback play");
  }

  /** Return true if the AI is ready to make plays
   * Override in subclass
   */
  isAIReady() {
    return true;
  }

  /** Async function to wait for the AI to be ready to make plays
   * Override in subclass
   * Returns true if the AI is ready, or false if it timed out
   */
  async waitForAIReady(timeout = true) {
    return true;
  }

  /** Play the current turn using the AI */
  playTurn() {
    if(this.gameState.status === GAME_STATE_BID)
      this.aiBid();
    else if(this.gameState.status === GAME_STATE_PLAY)
      this.aiPlay();
    else if(this.gameState.status === GAME_STATE_PASS)
      this.aiPass();
  }

  /** Get a snapshot of the game state, excluding some unneeded elements, for use in the AI
   * Override in subclass
   */
  getSnapshotJsonForAI() {
    // Get a snapshot of the gameState
    let snap = getSnapshot(this.gameState);
    // Convert to a JSON string, but excluding undoManager, which can grow too large for emscripten's 16mb memory limit
    let json = JSON.stringify(snap, (k, v) => k === "undoManager" ? undefined : v);
    return json;
  }

  /** get a random fallback bid */
  getFallbackBid(): number {
    let bid = Math.floor(Math.random() * 6);
    return bid;
  }

  /** get a bid from the AI
   * Override in subclass to customize
   * default AI is to bid randomly.
   * snap is an optional ai snapshot to use, otherwise the current state will be used
   * returns a number indicating the number of tricks the ai selected to bid
   */
  getBid(stateJson?: string): number {
    let bid = this.getFallbackBid();
    return bid;
  }

  /**
   * Make a Bid, using the AI if it's available, else a fallback
   * If config.canDelayAIPlay (on the client), we'll wait for either the AI to be ready or a timeout before making the bid
   * Otherwise (server), if the AI is unavailable it will fallback immediately
   */
  aiBid() {
    // Bid immediately, if able
    if(this.isAIReady())
    {
      let bid = this.getBid();
      this.bid(bid);
      return;
    }

    // Fallback immediately if delaying the play is not supported
    if(!config.canDelayAIPlay)
    {
      let bid = this.getFallbackBid();
      this.bid(bid, true);
      return;
    }

    // Otherwise, try to wait, with a timeout
    this.waitForAIReady().then((loaded) => {
      if(loaded) {
        let bid = this.getBid();
        this.bid(bid);
      } else {
        let bid = this.getFallbackBid();
        this.bid(bid, true);
      }
    });
  }

  /** make a bid with the game state
   * isFallback is only set true for the random bids made by the TraickGameAI base class
   */
  bid(bid: number, isFallback = false) {
    // Log an entry to the gameLog if this was a fallback play.  Because the action is queued, we need to log the full play so it can be connected to the action when it is logged
    if(isFallback)
      logger.appendToGameLog(`AI Fallback Bid,${this.seat.id}`);

    this.gameState.setBid(this.seat.player.id, this.seat.id, bid);

    if(isFallback)
      this.logFallbackWarning();
  }

  /** get a random fallback play */
  getFallbackPlay(): number {
    // first find all valid pieces to play
    let validPieces = this.hand.pieces.filter((piece: IPieceState) => {
      return this.gameState.canClickPiece(this.seat.player.id, this.seat, this.hand, piece);
    });

    // Return null if we have no valid play
    if(!validPieces.length)
      return null;

    // pick a random piece to play
    let index = Math.floor(Math.random() * validPieces.length);
    let value = validPieces[index].value;
    return value;
  }

  /** get a play from the AI
   * Override in subclass to customize
   * default AI is to play a random valid piece.
   * snap is an optional ai snapshot to use, otherwise the current state will be used
   * returns a number indicating the value of the ai's selected play, may return null if no valid play can be found
   */
  getPlay(stateJson?: string): number {
    let value = this.getFallbackPlay();
    return value;
  }

  /**
   * Make a Play, using the AI if it's available, else a fallback
   * If config.canDelayAIPlay (on the client), we'll wait for either the AI to be ready or a timeout before making the play
   * Otherwise (server), if the AI is unavailable it will fallback immediately
   */
  aiPlay() {
    // Play immediately, if able
    if(this.isAIReady())
    {
      let value = this.getPlay();
      this.play(value);
      return;
    }

    // Fallback immediately if delaying the play is not supported
    if(!config.canDelayAIPlay)
    {
      let value = this.getFallbackPlay();
      this.play(value, true);
      return;
    }

    // Otherwise, try to wait, with a timeout
    this.waitForAIReady().then((loaded) => {
      if(loaded) {
        let value = this.getPlay();
        this.play(value);
      } else {
        let value = this.getFallbackPlay();
        this.play(value, true);
      }
    });
  }

  /** make a play with the game state, first checking to be sure it's valid
   * isFallback is only set true for the random plays made by the TraickGameAI base class
   */
  play(value: number, isFallback = false) {
    let seat = parseInt(this.seat.id);
    let snap = this.getSnapshotJsonForAI();
    let validPlay = false;

    let piecePlayed = this.hand.getPieceByValue(value);
    if(piecePlayed) {
      if(this.gameState.canClickPiece(this.seat.player.id, this.seat, this.hand, piecePlayed)) {
        // Log an entry to the gameLog if this was a fallback play.  Because the action is queued, we need to log the full play so it can be connected to the action when it is logged
        if(isFallback)
          logger.appendToGameLog(`AI Fallback Play,${this.seat.id}`);

        this.gameState.queueAction({name: "clickPiece", args: [this.seat.player.id, this.seat.id, this.hand.name, piecePlayed.name]}); // queueAction instead of directly calling clickPiece to allow the action to be paused when playing offline
        validPlay = true;
      }
      else
        logger.warn("AI attempted illegal play", { seat, snap, piece: piecePlayed.valueShortName(), isFallback});
    }
    else
      logger.warn("AI attempted to play card it doesn't have", { seat, snap, piece: value, isFallback});

    // No valid play, so play first valid card
    if(!validPlay) {
      for(let piece of this.hand.pieces) {
        if(this.gameState.canClickPiece(this.seat.player.id, this.seat, this.hand, piece)) {
          this.gameState.queueAction({name: "clickPiece", args: [this.seat.player.id, this.seat.id, this.hand.name, piece.name]}); // queueAction instead of directly calling clickPiece to allow the action to be paused when playing offline
          break;
        }
      }
    }

    if(isFallback)
      this.logFallbackWarning();
  }

  /** ai needs to select cards to pass, then set their seat ready */
  /*
  aiPass() {
    // todo implement real ai and fallback
    // for now just pass first 3 cards in hand, then indicate we're ready
    let PASS_COUNT = 3;
    let selectedPieceNames: string[] = [];
    for(let i = 0; i < PASS_COUNT ; i ++)
      selectedPieceNames.push(this.hand.pieces[i].name);

    // selectPieces sends the pieces to be selected, and flags that we're ready in one action. This fixes timing problems
    // with sending multiple actions too fast.
    this.gameState.selectPieces(this.seat.player.id, this.seat, selectedPieceNames, true);
  }
  */

  /** get a random fallback pass */
  getFallbackPass(): number[] {
    // todo implement real ai and fallback
    // for now just pass first 3 cards in hand, then indicate we're ready
    let PASS_COUNT = 3;
    let passedCards: number[] = [];
    for(let i = 0; i < PASS_COUNT ; i ++)
      passedCards.push(this.hand.pieces[i].value);

    return passedCards;
  }

  /** get a pass from the AI
   * Override in subclass to customize
   * default AI is to pass random cards
   * snap is an optional ai snapshot to use, otherwise the current state will be used
   * returns a number indicating the value of the ai's selected play, may return null if no valid play can be found
   */
  getPass(stateJson?: string): number[] {
    let passedCards = this.getFallbackPass();
    return passedCards;
  }

  /**
   * Make a Pass, using the AI if it's available, else a fallback
   * If config.canDelayAIPlay (on the client), we'll wait for either the AI to be ready or a timeout before making the play
   * Otherwise (server), if the AI is unavailable it will fallback immediately
   */
  aiPass() {
    // Play immediately, if able
    if(this.isAIReady())
    {
      let passedCards = this.getPass();
      this.pass(passedCards);
      return;
    }

    // Fallback immediately if delaying the play is not supported
    if(!config.canDelayAIPlay)
    {
      let passedCards = this.getFallbackPass();
      this.pass(passedCards, true);
      return;
    }

    // Otherwise, try to wait, with a timeout
    this.waitForAIReady().then((loaded) => {
      if(loaded) {
        let passedCards = this.getPass();
        this.pass(passedCards);
      } else {
        let passedCards = this.getFallbackPass();
        this.pass(passedCards, true);
      }
    });
  }

  /** make a pass with the game state
   * isFallback is only set true for the random passes made by the TraickGameAI base class
   */
  pass(passedCards: number[], isFallback = false) {
    // Log an entry to the gameLog if this was a fallback play.  Because the action is queued, we need to log the full play so it can be connected to the action when it is logged
    if(isFallback)
      logger.appendToGameLog(`AI Fallback Pass,${this.seat.id}`);

    // Get the name of each passed piece value
    let PASS_COUNT = 3;
    let selectedPieceNames: string[] = [];
    for(let i = 0; i < PASS_COUNT ; i ++) {
      let pieceValue = passedCards[i]; // Note this will be 0 in pass direction "none"
      if(!pieceValue)
        continue;
      let piece = this.hand.getPieceByValue(pieceValue);
      selectedPieceNames.push(piece.name);
    }

    // selectPieces sends the pieces to be selected, and flags that we're ready in one action. This fixes timing problems
    // with sending multiple actions too fast.
    this.gameState.selectPieces(this.seat.player.id, this.seat, this.hand, selectedPieceNames, true);

    if(isFallback)
      this.logFallbackWarning();
  }

  /** Get an AI bid, returns a promise with the AI selected bid
   * Does not check config.canDelayAIPlay, and does not fallback to random
   */
  async getAIBidAsync(): Promise<number> {
    // Record an ai snapshot in case the game advances while we're waiting
    let stateJson = this.getSnapshotJsonForAI();

    // If we don't have an AI instance yet, wait for it
    if(!this.isAIReady())
      await this.waitForAIReady(false);

    // Get the AI to select a bid for us
    let bid = this.getBid(stateJson);

    return bid;
  }

  /** Get an AI pass, returns a promise with the AI selected pass card values
   * Does not check config.canDelayAIPlay, and does not fallback to random
   */
  async getAIPassAsync(): Promise<number[]> {
    // Record an ai snapshot in case the game advances while we're waiting
    let stateJson = this.getSnapshotJsonForAI();

    // If we don't have an AI instance yet, wait for it
    if(!this.isAIReady())
      await this.waitForAIReady(false);

    // Get the AI to select a play for us
    let passedCards = this.getPass(stateJson);
    return passedCards;
  }

  /** Get an AI pass, returns a promise with the AI selected value
   * Does not check config.canDelayAIPlay, and does not fallback to random
   */
  async getAIPlayAsync(): Promise<number> {
    // Record an ai snapshot in case the game advances while we're waiting
    let stateJson = this.getSnapshotJsonForAI();

    // If we don't have an AI instance yet, wait for it
    if(!this.isAIReady())
      await this.waitForAIReady(false);

    // Get the AI to select a play for us
    let value = this.getPlay(stateJson);
    return value;
  }
}
