import { ArgoSystem } from "components/game/ArgoSystem";
import { IStartup } from "components/game/Game";
import { loadImageAsync, loadImageAsyncWithFallback } from "components/utils/Image";
import { imageUrlToPngDataURI } from "components/utils/to-png-data-uri";
import { IEntryState } from "states/leaderboard/EntryState";
import { config } from "utils/Config";
import { logger } from "utils/logger";

import pawn0AvatarUrl from "components/game/avatars/pawn0.png";
import shareBackgroundUrl from "components/ui/share/facebook-share-leaderboard.png";
import defaultShareUrl from "components/ui/share/share-image.jpg";

// Because we currently load the deck image directly into a texture, we don't have a useable image for the canvas
const CARD_SOURCE_WIDTH = 200;
const CARD_SOURCE_HEIGHT = 256;
const CARD_WIDTH = 100;
const CARD_HEIGHT = 128;

// The parameters match FBInstant.shareAsync, image is expected to be a base64 data URI
export interface IShareObject {
  intent?: string; // "INVITE" | "REQUEST" | "CHALLENGE" | "SHARE"
  image?: string;
  text?: string;
  data?: any;
}

/** Create an IShareObject for sharing game events
 * Most will be game specific and generated by a subclass
 */
export class ShareSystem extends ArgoSystem {
  // 1200 x 630 is the recommended share size for Facebook, we're using 1/4 size to save space
  shareWidth = 600;
  shareHeight = 315;

  /** Make a share object for the given name
   * Override _makeShareObject in subclasses instead, this is a wrapper with try/catch
   */
  makeShareObject(shareName: string): Promise<IShareObject> {
    try {
      return this._makeShareObject(shareName);
    } catch (e) {
      logger.error("ShareSystem makeShareObject exception", { error: e });
      return null;
    }
  }

  /** Generic and default shares */
  _makeShareObject(shareName: string): Promise<IShareObject> {
    switch(shareName) {
      case "leaderboardRank":
        return this.makeShareRankObject();
      case "levelUp":
        return this.makeShareLevelObject();
      default:
        return this.makeDefaultShareObject();
    }
  }

  /** Create a canvas and return the associated rendering context */
  protected getCanvasContext() {
    // Create a canvas
    let canvas = document.createElement("canvas") as HTMLCanvasElement;
    canvas.width = this.shareWidth;
    canvas.height = this.shareHeight;

    // Get the context
    // The context has a canvas property, so we don't need any other reference to it
    let context = canvas.getContext("2d");

    return context;
  }

  /** Get a dataURL for the canvas associated with the given context, optionally doing some debug logging with the result */
  protected contextToDataURL(context: CanvasRenderingContext2D, logName = "share") {
    let dataURL = context.canvas.toDataURL(config.shareImageType, config.shareImageQuality);

    if(process.env.NODE_ENV === "development") {
      if(config.shareImageLogSize) {
        // tslint:disable-next-line:no-console
        console.log(`${logName} size: ${dataURL.length} bytes`);
      }

      if(config.shareImageLogURI) {
        // tslint:disable-next-line:no-console
        console.log(dataURL);
      }
    }

    return dataURL;
  }

  private async makeShareRankObject(): Promise<IShareObject> {
    const AVATAR_X = 142;
    const AVATAR_Y = 70;
    const AVATAR_WIDTH = 75;

    const TEXT_X = 395;
    const TEXT_Y = 52;
    const TEXT_LINE_HEIGHT = 32;
    const TEXT_COLOR = "#00fff9";

    let userId = this.game.rootState.user.id;
    let imageURL = this.game.rootState.user.imageUrl || pawn0AvatarUrl;

    let rank = 0;
    let score = 0;

    // Look for a leaderboard entry for our userId
    let board = this.game.rootState.leaderboards.getLeaderboard(config.defaultLeaderboard);
    if(!board)
      return null;

    let entry = board.entries.find((e: IEntryState) => e.userId === userId);
    if(entry) {
      rank = entry.rank;
      score = entry.score;
    } else {
      // Assume last place
      rank = board.entries.length;
    }

    const startupData: IStartup = {
      srcType: "share",
      srcName: "leaderboard_rank_1",
      startupAction: "none",
    };

    let avatarImage = await loadImageAsyncWithFallback(imageURL, pawn0AvatarUrl);
    if(!avatarImage)
      return null;

    let backgroundImage = await loadImageAsync(shareBackgroundUrl);
    if(!backgroundImage)
      return null;

    let context = this.getCanvasContext();

    context.drawImage(backgroundImage, 0, 0);

    // Draw Avatar in a circle
    context.save();
    context.beginPath();
    context.arc(AVATAR_X, AVATAR_Y, AVATAR_WIDTH * 0.5, 0, Math.PI * 2, true);
    context.clip();
    context.drawImage(avatarImage, AVATAR_X - AVATAR_WIDTH * 0.5, AVATAR_Y - AVATAR_WIDTH * 0.5, AVATAR_WIDTH, AVATAR_WIDTH);
    context.stroke();
    context.restore();

    // Draw the Text
    context.save();
    context.font = `${config.fontWeight} ${TEXT_LINE_HEIGHT}px ${config.fontFamily}`;
    context.fillStyle = TEXT_COLOR;
    context.textAlign = "center";
    context.fillText(`I shot up to #${rank}`, TEXT_X, TEXT_Y);
    context.fillText(`On the ${config.longName}`, TEXT_X, TEXT_Y + TEXT_LINE_HEIGHT * 1);
    context.fillText("Leaderboard.", TEXT_X, TEXT_Y + TEXT_LINE_HEIGHT * 2);
    context.fillText("Let's Play!", TEXT_X, TEXT_Y + TEXT_LINE_HEIGHT * 4);
    context.restore();

    let image = this.contextToDataURL(context, "leaderboardRank");

    return {
      image,
      data: startupData,
    };
  }

  private async makeShareLevelObject(): Promise<IShareObject> {
    const AVATAR_X = 142;
    const AVATAR_Y = 70;
    const AVATAR_WIDTH = 75;

    const TEXT_X = 395;
    const TEXT_Y = 52;
    const TEXT_LINE_HEIGHT = 32;
    const TEXT_COLOR = "#ffffff";

    let smallFont = `${config.fontWeight} ${TEXT_LINE_HEIGHT}px ${config.fontFamily}`;
    let largeFont = `${config.fontWeight} ${TEXT_LINE_HEIGHT * 2}px ${config.fontFamily}`;

    let userId = this.game.rootState.user.id;
    let imageURL = this.game.rootState.user.imageUrl || pawn0AvatarUrl;
    let level = this.game.rootState.user.xp.level;

    const startupData: IStartup = {
      srcType: "share",
      srcName: "leaderboard_level_1",
      startupAction: "none",
    };

    let avatarImage = await loadImageAsyncWithFallback(imageURL, pawn0AvatarUrl);
    if(!avatarImage)
      return null;

    let backgroundImage = await loadImageAsync(shareBackgroundUrl);
    if(!backgroundImage)
      return null;

    let context = this.getCanvasContext();

    context.drawImage(backgroundImage, 0, 0);

    // Draw Avatar in a circle
    context.save();
    context.beginPath();
    context.arc(AVATAR_X, AVATAR_Y, AVATAR_WIDTH * 0.5, 0, Math.PI * 2, true);
    context.clip();
    context.drawImage(avatarImage, AVATAR_X - AVATAR_WIDTH * 0.5, AVATAR_Y - AVATAR_WIDTH * 0.5, AVATAR_WIDTH, AVATAR_WIDTH);
    context.stroke();
    context.restore();

    // Draw the Text
    context.save();
    context.font = smallFont;
    context.fillStyle = TEXT_COLOR;
    context.textAlign = "center";
    context.fillText("I've launched to", TEXT_X, TEXT_Y);

    context.font = largeFont;
    context.fillText(`Level ${level}`, TEXT_X, TEXT_Y + TEXT_LINE_HEIGHT * 2.4);
    context.font = smallFont;

    context.fillText("Come with me!", TEXT_X, TEXT_Y + TEXT_LINE_HEIGHT * 4);
    context.restore();

    let image = this.contextToDataURL(context, "leaderboardRank");

    return {
      image,
      text: `I've launched to Level ${level}. Come With me!`,
      data: startupData,
    };
  }

  private async makeDefaultShareObject(): Promise<IShareObject> {
    let image = await imageUrlToPngDataURI(defaultShareUrl);

    const startupData: IStartup = {
      srcType: "share",
      srcName: "default_1",
      startupAction: "none",
    };

    return {
      image,
      data: startupData,
    };
  }

  protected async drawHand(context: CanvasRenderingContext2D, cards: number[]) {
    // Load the deckImage
    let deckImage = await loadImageAsync(config.defaultDeckUrl);

    let arc = 30.0 * Math.PI / 180;
    let radius = 950.0;
    let centerIndex = 6;
    let divisor = 12;
    let cx = this.shareWidth * 0.5;
    let cy = 1212;

    // tslint:disable:prefer-for-of
    for(let index = 0; index < cards.length; index++) {
      let cardIndex = cards[index] - 1;
      let sx = (cardIndex % 10) * CARD_SOURCE_WIDTH;
      let sy = Math.floor(cardIndex / 10) * CARD_SOURCE_HEIGHT;

      let t = (index - centerIndex) / divisor;

      let angle = arc * t;
      let a = angle - Math.PI / 2;

      let x = cx + Math.cos(a) * radius;
      let y = cy + Math.sin(a) * radius;

      context.save();
      context.translate(x, y);
      context.rotate(angle);
      context.translate(CARD_WIDTH * -0.5, CARD_HEIGHT * -0.5);
      context.drawImage(deckImage, sx, sy, CARD_SOURCE_WIDTH, CARD_SOURCE_HEIGHT, 0, 0, CARD_WIDTH, CARD_HEIGHT);
      context.restore();
    }
  }
}
