import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Axis, Quaternion } from "@babylonjs/core/Maths/math";
import { Observer } from "@babylonjs/core/Misc/observable";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { ArgoSystem } from "components/game/ArgoSystem";
import { game } from "components/game/Game";
import { PointTokenSystem } from "components/game/PointTokenSystem";
import { RoundSystem, RoundSystemEvent } from "components/game/RoundSystem";
import { Commands } from "components/ui/Commands";
import { ScreenWithFooter } from "components/ui/controls/ScreenWithFooter";
import { ScoreCard3d } from "components/ui/meshes/ScoreCard3d";
import { UserStatusSystem } from "components/ui/UserStatusSystem";
import { disposeGuiControl, findGuiControl, getControlGlobalPosition, toast } from "components/utils/GUI";
import { GAME_STATE_GAME_OVER } from "states/game/GameState";
import { ITrickGameState } from "states/game/TrickGameState";
import { config } from "utils/Config";

import shareIconUrl from "components/ui/icons/share.png";
import winnerBackgroundUrl from "components/ui/meshes/score-card/score-card-background-gold.png";

let CARD_BASE_SCALE = 2.5; // The default CardButton scale is 2.0
let CARD_SCALES = [1.0, 0.8, 0.8, 0.8];
let CARD_ANGLES = [-10, 7, -1, 3];
let CARD_ANGLE_Y = 10;
let CARD_SPACING = 2.0;

/**
 * The GameOverCardScreen GUI is implemented with 3d cards
 * This is an invisible 2d GUI Screen object to take advantage of the automatic hiding/showing of the game and other Screen objects
 */
export class GameOverCardScreen extends ScreenWithFooter {
  gameEndText: TextBlock;

  cards: ScoreCard3d[];
  roundSystemEventObserver: Observer<RoundSystemEvent>;

  static showGameOverCardScreen() {
    disposeGuiControl("GameOverCardScreen");
    let gui = new GameOverCardScreen("GameOverCardScreen");
    game.guiTexture.addControl(gui);
  }

  constructor(name: string) {
    super(name);

    this.cards = [];

    // Footer
    this.addFooterButton(name + "ShareButton", "Share", shareIconUrl, () => this.onShareButton());
    this.addFooterButton(name + "DoneButton", "Done", null, () => this.onDoneButton());
    this.addFooterButton(name + "NextButton", "Play Again", null, () => this.onContinueButton());

    this.createScoreCards();

    this.layout();

    // Add User Status bar
    let userStatusSystem = game.systems.get("UserStatusSystem") as UserStatusSystem;
    userStatusSystem.create(true, true);

    if(userStatusSystem.metaGamePanel) {
      // Highlight the score
      userStatusSystem.metaGamePanel.setScoreColor(config.scoreHighlightColor, config.scoreHighlightShadowBlur, config.scoreHighlightShadowColor);

      // set custom animateScore
      userStatusSystem.metaGamePanel.customAnimateScore = (from, to) => this.animateMetaGameScore(userStatusSystem, from, to);
    }

    // Listen for RoundSystem events
    let roundSystem = game.systems.get("RoundSystem") as RoundSystem;
    this.roundSystemEventObserver = roundSystem.eventObservable.add((evt) => this.onRoundSystemEvent(evt));

    // auto press continue button after 2 seconds if debug auto play is on
    if(game.debugAutoPlayLocalSeat)
      setTimeout(() => this.onContinueButton(), 2 * 1000);
  }

  createScoreCards() {
    // Sort teams by place
    // If place is not yet set, sorting by score is game dependent (low for hearts, high for spades, for example)
    let sortedTeams = game.gameState.teams.slice().sort((a, b) => a.place - b.place);

    for(let team of sortedTeams) {
      for(let seatId of team.seats) {
        let seat = game.gameState.getSeat(seatId);
        let index = this.cards.length;
        let place = team.place;

        // Place should only be 0 when testing, fake a tie in this case
        if(place === 0)
          place = index < 3 ? 1 : 2;

        let name = "";
        let imageUrl = "";
        let score = 0;

        if(seat.player) {
          name = seat.player.name;
          imageUrl = seat.player.imageUrl;
          score = team.score;
        } else {
          name = `Seat ${seatId}`;
        }

        let card = new ScoreCard3d("ScoreScard_Seat" + seatId, null);
        this.cards.push(card);

        card.cardBaseScale = CARD_BASE_SCALE;

        if(place === 1)
          card.setBackground(winnerBackgroundUrl);

        card.idleRangeZ = 0.1; // limit the idle animation z range to avoid clipping

        card.setName(name);
        card.setImage(imageUrl);
        card.setScore(score);
        card.setMessage(place === 1 ? "Winner!" : "");

        let x = CARD_SPACING * -1.5 + index * CARD_SPACING;
        let y = 0;
        let z = -0.5;
        card.move(x, y, z);

        let scale = card.cardBaseScale * CARD_SCALES[index];
        let angle = CARD_ANGLES[index] * Math.PI / 180.0;

        card.scaling.x = card.scaling.y = card.scaling.z = scale;

        let qRotation = new Quaternion();

        qRotation.multiplyInPlace(Quaternion.RotationAxis(Axis.Y, CARD_ANGLE_Y * Math.PI / 180));
        qRotation.multiplyInPlace(Quaternion.RotationAxis(Axis.Z, angle));

        card.rotationQuaternion = qRotation;

        card.slideIn(3.0 * index, () => this.onIntroAnimationEnd());
      }

    }
  }

  layout() {
    super.layout();
  }

  getCardForSeat(seatId: string) {
    let name = "ScoreScard_Seat" + seatId;
    for(let card of this.cards) {
      if(card.name === name)
        return card;
    }

    return null;
  }

  onRoundSystemEvent(evt: RoundSystemEvent) {
    switch(evt){
      case RoundSystemEvent.ROUND_START:
      case RoundSystemEvent.ROUND_OVER:
        // We aren't doing round summaries in hearts
        break;

      case RoundSystemEvent.GAME_STARTED_BY_OTHERS:
        this.onGameStartedByOthers();
        break;

      case RoundSystemEvent.GAME_RESET:
        this.onGameReset();
        break;

      case RoundSystemEvent.GAME_OVER:
        // We shouldn't get this here, GameOverCardScreenSystem gets the event and creates this object in response
        break;
    }
  }

  onShareButton() {
    let shares = [];

    // Check for a shoot
    let trickGameState = (game.gameState as ITrickGameState);

    let type = "sun";
    let shooterSeat = trickGameState.getShootSunSeat();
    if(!shooterSeat) {
      type = "moon";
      shooterSeat = trickGameState.getShootMoonSeat();
    }

    if(shooterSeat)
      shares.push(`shot_${type}`);

    Commands.onShareUI(shares, false);
  }

  onDoneButton() {
    this.dispose();

    Commands.onShowAd("roundOver", game.adSystem.shouldShowAd(), () => {
      Commands.onRequestHomeScreen();
    });
  }

  onContinueButton() {
    this.dispose();

    Commands.onShowAd("roundOver", game.adSystem.shouldShowAd(), () => {
      if(game.gameState.status === GAME_STATE_GAME_OVER)
        Commands.onRequestPlayAgain();
    });
  }

  onGameStartedByOthers() {
    // Watch out for the buttons
    let width = 0;
    let control = findGuiControl(this.name + "ShareButton", this);
    if(!control)
      control = findGuiControl(this.name + "DoneButton", this);
    if(!control)
      control = findGuiControl(this.name + "NextButton", this);
    if(control) {
      let pos = getControlGlobalPosition(control);
      width = pos.x - control.heightInPixels;
    }
    toast("PlayAgainToast", "Others want to Play Again.", width);
  }

  onGameReset() {
    this.dispose();
  }

  onIntroAnimationEnd() {
    // Wait to animate UserStatus
    setTimeout(() => {
      (game.systems.get("UserStatusSystem") as UserStatusSystem).animateChanges(() => this.onAnimateUserStatusChangesDone());
    }, 1000);
  }

  onAnimateUserStatusChangesDone() {
    // Share?
  }

  animateMetaGameScore(userStatusSystem: UserStatusSystem, from: number, to: number): Animatable {
    // If the score isn't going up, call the default routine, especially to handle the score returning to zero at Meta Game Over
    if(to <= from) {
      return userStatusSystem.metaGamePanel.defaultAnimateScore(from, to);
    }

    // The seat roundScore appears to be correct at this point
    // For each seat, if the seat is not the localSeat, send tokens from that card to the metaGamePanel scoreText
    // The sum of each other player's round scores should match (from - to)

    let pointTokenSystem = game.systems.get("PointTokenSystem") as PointTokenSystem;

    // Find the target position
    let scorePos = getControlGlobalPosition(userStatusSystem.metaGamePanel.scoreText);
    scorePos.x += userStatusSystem.metaGamePanel.scoreText.widthInPixels * 0.5;
    scorePos.y += userStatusSystem.metaGamePanel.scoreText.heightInPixels * 0.5;

    let tokenWidth = 30.0 * game.getScale();
    let tokenVelocity = 200.0 * game.getScale();

    let longestAnimatable: Animatable = null;

    for(let seat of game.gameState.seats) {
      // Ignore the local seat
      if(seat.id === game.localSeat)
        continue;

      // Get the card
      let card = this.getCardForSeat(seat.id);
      let pos = game.project(card.position);

      let roundScore = seat.roundScore;

      let arrivalDelay = 0.0;

      // if the score is large, then send a combination of regular and big tokens. 1 big token = 5 regular
      let tokens = roundScore;
      let bigTokens = 0;
      if(tokens > 15) {
        bigTokens = Math.trunc(tokens / 5);
        tokens = tokens - (bigTokens * 5);
      }

      let totalTokens = tokens + bigTokens;

      let arrivalDelayIncrement = 0.15;
      let arrivalDelayMax = 2.0;

      // Don't let the arrival delay get too large
      if(arrivalDelayIncrement * totalTokens > arrivalDelayMax)
        arrivalDelayIncrement = arrivalDelayMax / totalTokens;

      for(let i = 0; i < totalTokens; i++) {
        let scale = 1.0;
        let points = 1;
        // send all regular tokens first, then scale up when we get to big tokens
        if(i >= tokens) {
          scale = 2.0;
          points = 5;
        }

        let startX = pos.x + (Math.random() - 0.5) * tokenWidth * 3;
        let startY = pos.y + (Math.random() - 0.5) * tokenWidth * 3;
        let targetX = scorePos.x + (Math.random() - 0.5) * tokenWidth * 3;
        let targetY = scorePos.y + (Math.random() - 0.5) * tokenWidth * 3;

        let animatable = pointTokenSystem.launchToken(null, startX, startY, targetX, targetY, scale, tokenVelocity, arrivalDelay);
        animatable.onAnimationEndObservable.add(() => {
          let score = userStatusSystem.metaGamePanel.scoreText.value;
          userStatusSystem.metaGamePanel.scoreText.value = score + points;
        });

        // Keep track of the longest animatable so MetaGamePanel can wait for it
        if(longestAnimatable === null || animatable.toFrame > longestAnimatable.toFrame)
          longestAnimatable = animatable;

        arrivalDelay += arrivalDelayIncrement;
      }
    }

    return longestAnimatable;
  }

  // It turns out if you overide a setter, you have to also override the getter or else it returns undefined
  public get isVisible(): boolean {
    return super.isVisible;
  }

  // Override Control.isVisible to hide/show the 3d CardButtons when the Screen is hidden or shown
  public set isVisible(value: boolean) {
    super.isVisible = value;
    for(let card of this.cards)
      card.setEnabled(value);
  }

  dispose() {
    super.dispose();

    for(let card of this.cards)
      card.dispose();
    this.cards.length = 0;

    // Dispose the UserStatusSystem
    // This was calling animateOut, but that conflicts with HomeScreen showing the UserStatusSystem and you end up with no status bar
    // SpadesRoundSummary is also calling dispose.
    (game.systems.get("UserStatusSystem") as UserStatusSystem).dispose();

    // Dispose the Leaderboard and/or LevelUp screens, if showing
    disposeGuiControl("LevelUpScreen");
    disposeGuiControl("LeaderboardScreen");

    // Stop listening for RoundSystem events
    let roundSystem = game.systems.get("RoundSystem") as RoundSystem;
    roundSystem.eventObservable.remove(this.roundSystemEventObserver);
    this.roundSystemEventObserver = null;
  }
}

/**
 * Mini system to listen for game over event from RoundSystem
 */
export class GameOverCardScreenSystem extends ArgoSystem {
  init() {
    let roundSystem = this.game.systems.get("RoundSystem") as RoundSystem;
    roundSystem.eventObservable.add((evt) => {
      if(evt === RoundSystemEvent.GAME_OVER)
        GameOverCardScreen.showGameOverCardScreen();
    });
  }
}
