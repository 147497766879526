import { mergeConfig } from "utils/Config";

import cardShuffleUrl from "./card-shuffle.mp3";
import playcard1Url from "./playcard1.mp3";

mergeConfig({
  sounds: {
    // Animation.ts, when animateDeal is called
    animateDeal: {url: cardShuffleUrl, volume: 1.0},

    playCard: {url: playcard1Url, volume: 1.0},
  },
});
