import { types } from "mobx-state-tree";

import { serverNow } from "components/utils/ServerTime";

import { ICatalogOffer } from "../../../catalog-config";

// tslint:disable-next-line:variable-name
const InventoryItemState = types
  .model("InventoryItemState", {
    name: types.string, // ie pass
    expires: types.optional(types.number, 0), // timestamp in seconds of when this item expires, ie when a 1 month pass ends
    quantity: types.optional(types.number, 0), // count of how many owned
  })
  .views((self) => {

    /** Check if item is expired using server time */
    function isExpired(): boolean {
      // 0 means it doesn't expire
      if(self.expires === 0)
        return false;

      // check if expires timestamp was in the past, relative to the server time
      return self.expires < (serverNow() / 1000);
    }

    /** Return the number of seconds until expired, or 0 if expired */
    function secondsUntilExpired(): number {
      return Math.max(0, self.expires - (serverNow() / 1000));
    }

    return { isExpired, secondsUntilExpired };
  })
  .actions((self) => {
    /** Adds quantity and/or extends expires */
    function applyOffer(offer: ICatalogOffer) {
      const now = Math.floor(serverNow() / 1000); // divide by 1000 to convert from ms to seconds
      if(offer.term) {
        // item may already be expired(or 0), so start from now and add term, which is in seconds
        self.expires = Math.max(now, self.expires) + offer.term;
        self.quantity = 1; // an expiring item always has a quantity of 1
      }
      else
        self.quantity += offer.quantity;
    }
    return { applyOffer };
  });

// InventoryItemState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IInventoryItemState = typeof InventoryItemState.Type;

export { InventoryItemState, IInventoryItemState };
