import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { game } from "components/game/Game";
import { AnimatableRectangle } from "components/ui/controls/AnimatableRectangle";
import { makeAnimationPath2d } from "components/utils/AnimationPaths";
import { disposeGuiControl, zIndex } from "components/utils/GUI";

interface IMissileControlOptions {
  url?: string;
  image?: HTMLImageElement;
  background?: string;

  startX: number;
  startY: number;

  targetX: number;
  targetY: number;

  sideScale?: number; // Scaling for side vector, default of 1.0 is left

  width: number;
  height: number;

  animate?: boolean; // default true
  duration?: number; // default 30 frames (1 second)
  timeScale?: number;

  startScale?: number;
  targetScale?: number;

  startAlpha?: number; // Animates Alpha across the whole animation
  targetAlpha?: number;

  fadeInDuration?: number; // Animates Alpha 0 to 1 at the beginning of the animation
  fadeOutDuration?: number; // Animates Alpha 1 to 0 at the end of the animation

  debugLines?: boolean;
}

// The Missile Control is based on AnimatableRectangle for it's animatable parameters
export class MissileControl extends AnimatableRectangle {
  options: IMissileControlOptions;
  animatable: Animatable;

  constructor(public name: string, options: IMissileControlOptions) {
    super(name);
    this.options = options;

    this.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.width = options.width + "px";
    this.height = options.height + "px";
    this.background = options.background;
    this.thickness = 0;
    this.zIndex = zIndex.ABOVE_GAME;
    this.animatablePositionOffset = new Vector2(options.width * -0.5, options.height * -0.5);

    this.setupImage();
    this.setupFlightPlan();
  }

  setupImage() {
    if(!this.options.image && !this.options.url)
      return;

    let image = new Image(name + "Image");
    if(this.options.image)
      image.domImage = this.options.image;
    else
      image.source = this.options.url;

    this.addControl(image);
  }

  setupFlightPlan() {
    if(this.options.animate === false)
      return;

    let kStep = Math.floor((this.options.duration || 30) * 0.5);
    let timeScale = this.options.timeScale || 1.0;

    let k0 = 0;
    let k1 = k0 + kStep;
    let k2 = k1 + kStep;

    let animations = [];

    let move = new Animation("MetaGamePanelFlyingHeartAnimation", "animatablePosition", 30, Animation.ANIMATIONTYPE_VECTOR2, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let moveKeys = makeAnimationPath2d({
      startX: this.options.startX,
      startY: this.options.startY,
      targetX: this.options.targetX,
      targetY: this.options.targetY,
      sideScale: this.options.sideScale,
      duration: this.options.duration,
      debugLines: this.options.debugLines,
    });
    move.setKeys(moveKeys);
    animations.push(move);

    if(this.options.startScale !== undefined) {
      let scale = new Animation("MetaGamePanelFlyingHeartAnimation", "animatableScale", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let scaleKeys = [];
      scaleKeys.push({frame: k0, value: this.options.startScale });
      scaleKeys.push({frame: k2, value: this.options.targetScale });
      scale.setKeys(scaleKeys);
      animations.push(scale);
    }

    if(this.options.startAlpha !== undefined) {
      let fade = new Animation("MetaGamePanelFlyingHeartFade", "alpha", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let fadeKeys = [];
      fadeKeys.push({ frame: k0, value: this.options.startAlpha });
      fadeKeys.push({ frame: k1, value: this.options.targetAlpha });
      fade.setKeys(fadeKeys);
      animations.push(fade);
    } else if(this.options.fadeInDuration !== undefined || this.options.fadeOutDuration !== undefined) {
      let fade = new Animation("MetaGamePanelFlyingHeartFade", "alpha", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let fadeKeys = [];

      if(this.options.fadeInDuration) {
        fadeKeys.push({ frame: k0, value: 0 });
        fadeKeys.push({ frame: k0 + this.options.fadeInDuration * 30 * timeScale, value: 1 });
      } else {
        fadeKeys.push({ frame: k0, value: 1 });
      }

      if(this.options.fadeOutDuration) {
        fadeKeys.push({ frame: k2 - this.options.fadeOutDuration * 30 * timeScale, value: 1 });
        fadeKeys.push({ frame: k2, value: 0 });
      } else {
        fadeKeys.push({ frame: k2, value: 1 });
      }

      fade.setKeys(fadeKeys);
      animations.push(fade);
    }

    this.animatable = game.scene.beginDirectAnimation(this, animations, 0, k2, false, timeScale, () => this.dispose());
  }
}

export function LaunchMissile(name: string, options: IMissileControlOptions) {
  let m = new MissileControl(name, options);
  game.guiTexture.addControl(m);
  return m;
}

export function debugMissileControl() {
  disposeGuiControl("debugRandomMissileControl");

  let guiWidth = game.guiTexture.getSize().width;
  let guiHeight = game.guiTexture.getSize().height;

  LaunchMissile("debugRandomMissileControl", {
    background: "magenta",

    startX: 64,
    startY: guiHeight * 0.75,
    targetX: guiWidth - 64,
    targetY: guiHeight * 0.75,

    width: 64,
    height: 64,

    duration: 30,
    timeScale: 0.25,

    debugLines: true,
  });
}
