import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";

import { ArgoSystem } from "components/game/ArgoSystem";
import { game } from "components/game/Game";
import { Commands } from "components/ui/Commands";
import { ArgoButton } from "components/ui/controls/ArgoButton";
import { Screen } from "components/ui/controls/Screen";
import { CardButton3d } from "components/ui/meshes/CardButton3d";
import { MetaGamePanel } from "components/ui/MetaGamePanel";
import { findGuiControl } from "components/utils/GUI";
import { config } from "utils/Config";

//import { ROOT_STATE_HOME_SCREEN } from "states/RootState";
let ROOT_STATE_HOME_SCREEN = "home_screen";

import instaPassBuyButtonUrl from "components/ui/home-screen/instapass-buy-button.png";
import instaPassBuyButtonSaleUrl from "components/ui/home-screen/instapass-buy-button_sale.png";

export interface IHomeScreenButton {
  name: string;
  imageUrl: string;
  onClick: () => void;

  // internal
  texture?: Texture;
}

/**
 * The HomeScreen GUI is implemented with 3d cards
 * This is an invisible 2d GUI Screen object to take advantage of the automatic hiding/showing of the game and other Screen objects
 */
class HomeScreen extends Screen {
  homeScreenSystem: HomeScreenSystem;
  instaPassStack: StackPanel;
  instaPassBuyButton: ArgoButton;
  instaPassExpirationText: ArgoButton;

  constructor(name: string, homeScreenSystem: HomeScreenSystem) {
    super(name);
    this.homeScreenSystem = homeScreenSystem;

    if(game.rootState.paymentsSupported) {
      this.instaPassStack = new StackPanel("HomeScreenInstaPassStackPanel");
      this.instaPassStack.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
      this.instaPassStack.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      this.instaPassStack.isVertical = true;
      this.instaPassStack.adaptWidthToChildren = true;
      this.addControl(this.instaPassStack);

      // Create an InstaPass buy button, optionally using a sale button
      let buttonUrl = instaPassBuyButtonUrl;
      if(config.catalogOffers.insta_pass_1_month.sale)
        buttonUrl = instaPassBuyButtonSaleUrl;
      this.instaPassBuyButton = new ArgoButton("HomeScreenInstaPassBuyButton", null, buttonUrl);
      this.instaPassBuyButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
      this.instaPassBuyButton.roundedCorners = false;
      this.instaPassBuyButton.thickness = 0;
      this.instaPassStack.addControl(this.instaPassBuyButton);

      this.instaPassExpirationText = new ArgoButton("HomeScreenInstaPassExpirationText", " ");
      this.instaPassExpirationText.roundedCorners = false;
      this.instaPassExpirationText.thickness = 0;
      this.instaPassExpirationText.textBlock.color = "white";
      this.instaPassStack.addControl(this.instaPassExpirationText);

      // When the pass button is clicked, we'll show the instaPass ad
      this.instaPassBuyButton.onPointerClickObservable.add(() => {
        game.adSystem.showSpecificAd("argo", "instaPass", null);
      });

      // Update the expiration text
      this.updateExpirationText();

      // Watch for future changes
      game.rootState.router.addRoute("^\/user\/inventory\/items\/", (patch: any, reversePatch: any, params: any) => this.onInventoryChanged(patch, reversePatch, params)); // there is purposely no $ terminator to match any change to inventory
    }

    this.layout();
  }

  layout() {
    super.layout();

    if(!this.instaPassBuyButton)
      return;

    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height - menuBarHeight;

    let userStatusBoxHeight = Math.min(guiWidth / 8, guiHeight / 5);

    this.instaPassStack.top = userStatusBoxHeight;

    this.instaPassBuyButton.baseHeight = 96 * game.getScale();

    this.instaPassExpirationText.baseHeight = 24 * game.getScale();
  }

  updateExpirationText() {
    // Is there any text to update?
    if(!this.instaPassExpirationText)
      return;

    let text = "";

    // Check for an instaPass in the inventory
    let passItem = game.rootState.user.inventory.getItem("insta_pass");
    if(passItem) {
      // Display when it expires
      let secondsUntilExpired = passItem.secondsUntilExpired();
      if(secondsUntilExpired === 0) {
        // Text is already blank
      } else if(secondsUntilExpired < 24 * 3600) {
        text = "Expires today";
      } else {
        let days = Math.floor(secondsUntilExpired / (24 * 3600));
        text = `${days} day${days > 1 ? "s" : ""} left`;
      }
    }

    this.instaPassExpirationText.textBlock.text = text;
    this.layout();
  }

  onInventoryChanged(patch: any, reversePatch: any, params: any) {
    this.updateExpirationText();
  }

  // It turns out if you overide a setter, you have to also override the getter or else it returns undefined
  public get isVisible(): boolean {
    return super.isVisible;
  }

  // Override Control.isVisible to hide/show the 3d CardButtons when the Screen is hidden or shown
  public set isVisible(value: boolean) {
    super.isVisible = value;
    this.homeScreenSystem.onScreenVisibility(value);
  }
}

export class HomeScreenSystem extends ArgoSystem {
  screen: HomeScreen = null;
  selectionMade = false;
  cardButtons: CardButton3d[] = [];
  metaGamePanel: MetaGamePanel; // The hearts and score on the bottom of the quick play card

  init() {
    this.rootState.router.addRoute("^\/status$", (patch: any, reversePatch: any, params: any) => this.onRootStatusChanged(patch, reversePatch, params));
  }

  queueAssets(): void {
    config.homeScreenButtons.forEach((homeScreenButton: IHomeScreenButton) => {
      this.game.assetsManager.addTextureTask(homeScreenButton.name + "TextureTask", homeScreenButton.imageUrl).onSuccess = (task) => {
        task.texture.uScale = 400 / 512;
        task.texture.uOffset = 56 / 512;
        homeScreenButton.texture = task.texture;
      };
    });
  }

  createSceneElements(): void {
    // homeScreenButtons is a list of game option cards to show on the home screen, ie quickPlay, classic, classicStandAlone.
    config.homeScreenButtons.forEach((homeScreenButton: IHomeScreenButton) => {

      // Create Card object
      let cardButton = new CardButton3d("HomeScreenCard_" + homeScreenButton.name, homeScreenButton.texture, () => this.onButtonClick(homeScreenButton)); // HomeScreenCard_classic
      cardButton.disableLighting();
      cardButton.addHelpButton(() => Commands.onHelp({helpKey: "main_" + homeScreenButton.name})); // main_classic
      cardButton.setEnabled(false);
      this.cardButtons.push(cardButton);

      // Add MetaGame Hearts to bottom of Quick Play Card
      if(homeScreenButton.name === "quickPlay") {

        cardButton.addBottomGUI("HomeScreenMeta");

        let heartSize = Math.floor(cardButton.guiControl3d.guiHeight / 4);

        this.metaGamePanel = new MetaGamePanel("HomeScreenMeta", 4, heartSize, true);
        this.metaGamePanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
        cardButton.guiControl3d.addControl(this.metaGamePanel);

        this.metaGamePanel.setControl3d(cardButton.guiControl3d);
      }
    });
  }

  /**
   * Be sure to close the BidScreen if the game leaves the bid state (new game, for example)
   */
  onRootStatusChanged(patch: any, reversePatch: any, params: any) {
    if (patch.value === ROOT_STATE_HOME_SCREEN)
      this.create();
    else
      this.dispose();
  }

  /*
  getSafeWidth() {
    // Copied from LeaderboardSidebarSystem to avoid a dependency
    let guiWidth = this.game.guiTexture.getSize().width;
    let guiHeight = this.game.guiTexture.getSize().height;

    let panelHeight = Math.floor(guiHeight * 3 / 5);
    let panelWidth = Math.floor(panelHeight * 376 / 1267);
    let panelBorder = Math.floor(panelWidth / 9);

    let leaderboardSidebarWidth = panelWidth + panelBorder;

    // Unproject positions leaderboardSidebarWidth away from each side of the screen
    let left = this.game.unprojectToGUIRootTransform(leaderboardSidebarWidth, guiHeight * 0.5);
    let right = this.game.unprojectToGUIRootTransform(guiWidth - leaderboardSidebarWidth, guiHeight * 0.5);

    // The area in between should be safe to use
    let ww = right.x - left.x;

    return ww;
  }
  */

  create() {
    /*
    // XXX - This was because in Facebook mode the HomeScreen gets created while the canvas is hidden
    //       So when we try to get the screen size to fit the cards, we get 1x1
    //       Since we ended up sizing the cards smaller instead of fitting, we don't need to worry about that for now
    //       The problem with enabling this, is there's a 1.5 second delay before onLoadingScreenFinishedObservable is notified
    // Don't create until the loading screen is finished
    if(!this.game.loadingScreenFinished) {
      this.game.onLoadingScreenFinishedObservable.addOnce(() => this.create());
      return;
    }
    */

    // Make sure we're starting fresh
    this.dispose();

    this.selectionMade = false;

    // Create an invisible 2d Screen for automatic hiding and showing of screens and the game
    this.screen = new HomeScreen("HomeScreen", this);
    this.game.guiTexture.addControl(this.screen);

    if(this.metaGamePanel)
      this.metaGamePanel.prepareToAnimateIn();

    let buttonCount = this.cardButtons.length;
    /*
    let spaceCount = buttonCount - 1;

    let safeWidth = this.getSafeWidth();
    let {min, max} = this.cardButtons[0].getHierarchyBoundingVectors();
    let cardWidth = (max.x - min.x) * this.cardButtons[0].cardBaseScale;
    let freeSpace = safeWidth - cardWidth * buttonCount;
    let spacing = freeSpace / spaceCount;
    console.log(`XXX - ${min}, ${max}`)
    console.log(`XXX - safeWidth: ${safeWidth}, cardWidth: ${cardWidth}, spacing: ${spacing}`)
    */

    // AbstractMesh.getHierarchyBoundingVectors is not returning the overall width of the card + help bar
    // So for now, just make the cards a little smaller
    // Since the layout was done at scale 2.0, we'll scale the final position by our new scale
    let cardBaseScale = 1.75;
    let xScale = cardBaseScale / 2.0;

    this.cardButtons.forEach((cardButton, index) => {
      cardButton.setEnabled(true);
      cardButton.cardBaseScale = cardBaseScale;

      // calculate final position of cards based on how many there are. Currently only supports 1 or 2
      let angle = -15;

      let x = 0; // center for buttonCnt === 0
      if(buttonCount === 2) {
        if(index === 0)
          x = -1.5;
        else
          x = 1.5;
      }
      else if(buttonCount === 3) {
        if(index === 0)
          x = -2.75;
        else if(index === 2)
          x = 2.75;
      }
      let y = 0.0;
      let z = -0.5;

      x *= xScale;

      cardButton.move(x, y, z);
      cardButton.setRotation(0, angle, 0);

      // animate card onto the screen
      cardButton.slideIn(5 * index, () => {
        cardButton.registerPointerObserver();

        // if this is the quickplay card then also animate the metaGamePanel
        if(cardButton.name === "HomeScreenCard_quickPlay" && this.metaGamePanel)
          this.metaGamePanel.animateIn();
      });
    });
  }

  /**
   * Dispose the Home Screen
   */
  dispose() {
    if(this.screen) {
      for(let cardButton of this.cardButtons) {
        cardButton.unregisterPointerObserver();
        cardButton.stopAnimations();
        cardButton.resetScale();
        cardButton.setEnabled(false);
      }

      if(this.metaGamePanel) {
        this.metaGamePanel.stopAnimations();
        this.metaGamePanel.clearObservables();
      }

      this.screen.dispose();
      this.screen = null;

      this.selectionMade = false;
    }
  }

  onScreenVisibility(visible: boolean) {
    for(let button of this.cardButtons)
      button.setEnabled(visible); // AbstractMesh.isVisible doesn't affect children, but setEnabled does
  }

  /** Called when a card button is clicked */
  onButtonClick(homeScreenButton: IHomeScreenButton) {
    if(this.selectionMade)
      return;

    this.selectionMade = true;

    let buttonName = "HomeScreenCard_" + homeScreenButton.name;
    let delay = 0;
    for(let cb of this.cardButtons) {
      cb.unregisterPointerObserver();
      if(cb.name === buttonName) {
        this.game.cardSystem.sparkleEdge(cb);
        // animate offscreen, then call onClick callback
        cb.slideDown(this.cardButtons.length * 5, () => {
          homeScreenButton.onClick();
        });
        delay += 5;
      } else {
        cb.slideDown(delay);
        delay += 5;
      }
    }
  }
}
