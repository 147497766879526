import { Control } from "@babylonjs/gui/2D/controls/control";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { game } from "components/game/Game";
import { Commands } from "components/ui/Commands";
import { ScreenWithFooter } from "components/ui/controls/ScreenWithFooter";
import { LeaderboardList } from "components/ui/LeaderboardList";
import { disposeGuiControl, findGuiControl } from "components/utils/GUI";
import { config } from "utils/Config";

import shareIconUrl from "components/ui/icons/share.png";

export class LeaderboardScreen extends ScreenWithFooter {
  onDispose: () => void;
  leaderboardList: LeaderboardList;

  static showLeaderboardScreen(highlightLocalUser = false, nextButtonLabel = "Next"): Promise<void> {
    if(!config.haveLeaderboards)
      return Promise.resolve();

    disposeGuiControl("LeaderboardScreen");

    let gui = new LeaderboardScreen("LeaderboardScreen", highlightLocalUser, nextButtonLabel);
    game.guiTexture.addControl(gui);

    return new Promise<void>((resolve, reject) => {
      gui.onDispose = () => {
        resolve();
      };
    });
  }

  constructor(name: string, highlightLocaluser: boolean, nextButtonLabel = "Next") {
    super(name);

    this.isPointerBlocker = true;

    this.addDarkBackground();

    let centerStack = new StackPanel("LavelUpGUICenterStack");
    this.addControl(centerStack);

    let message = new TextBlock(name + "Label");
    message.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    message.fontFamily = config.fontFamily;
    message.fontWeight = config.fontWeight;
    message.color = "white";
    message.text = "Quickplay Leaderboard";
    centerStack.addControl(message);

    this.leaderboardList = new LeaderboardList("LeaderboardScreenLeaderboard", highlightLocaluser);
    centerStack.addControl(this.leaderboardList);

    this.addFooterButton(name + "ShareButton", "Share", shareIconUrl, () => this.onShareButton());
    this.addFooterButton(name + "InviteButton", "Invite", null, () => this.onInviteButton());
    this.addFooterButton(name + "NextButton", nextButtonLabel, null, () => this.onNextButton());

    game.argoParticleSystem.boom();

    this.layout();

    if(highlightLocaluser)
      this.leaderboardList.scrollToLocalUser();
  }

  layout() {
    super.layout();

    this.leaderboardList.layout();

    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height - menuBarHeight;

    let ww = guiWidth;
    let wh = guiHeight;

    let k = 8; // "k" is basically how many player boxes should fit vertically on the screen, so bigger k for smaller boxes
    let height = Math.min(guiWidth / k / 2, guiHeight / k);

    let smallHeight = height / 2;
    let smallFontHeight = smallHeight * 0.8;

    this.width = ww + "px";
    this.height = wh + "px";

    let lw = this.leaderboardList.contentWidthInPixels;
    let lh = this.center.heightInPixels - smallHeight;
    this.leaderboardList.width = lw + "px";
    this.leaderboardList.height = lh + "px";

    let message = findGuiControl(this.name + "Label", this);
    message.height = smallHeight + "px";
    message.fontSize = smallFontHeight;
  }

  dispose() {
    if(this.leaderboardList) {
      this.leaderboardList.dispose();
      this.leaderboardList = null;
    }

    super.dispose();

    if(this.onDispose) {
      this.onDispose();
      this.onDispose = null;
    }
  }

  onShareButton() {
    Commands.onShareUI(["leaderboardRank"], false);
  }

  onInviteButton() {
    Commands.onInvite({ srcName: "leaderboard" });
  }

  onNextButton() {
    this.dispose();
  }
}
