import { types } from "mobx-state-tree";

export const PLAYER_STATE_PLAYER = "player";
export const PLAYER_STATE_LEFT = "left";
export const PLAYER_STATE_SUB = "sub";  // Subs are players that joined after the game started. They should not be affected by the results of the game, ie in a quick play game don't lose a life, or gain score.
//export const PLAYER_STATE_WATCHER = "watcher"; // Currently anyone can watch a game, but are not added to the list of players, so no need for a WATCHER status yet.
export type PlayerStateStatus =  typeof PLAYER_STATE_PLAYER  | typeof PLAYER_STATE_LEFT | typeof PLAYER_STATE_SUB;

// tslint:disable-next-line:variable-name
const PlayerState = types
  .model("PlayerState", {
    id: types.identifier,
    name: types.string,
    imageUrl: types.maybeNull(types.string),
    bot: types.boolean,
    status: types.optional(types.enumeration("Status", [PLAYER_STATE_PLAYER, PLAYER_STATE_LEFT, PLAYER_STATE_SUB]), PLAYER_STATE_PLAYER),
    missedTurns: types.optional(types.number, 0),  // the number of consecutive times the users turn timed out and didn't make a play
    socketId: types.maybeNull(types.string),  // the web socket.id of the last user to connect as this player, used by client to not allow multiple user to be connected at the same time.
  })
  .actions((self) => {

    function incMissedTurns() {
      self.missedTurns += 1;
    }

    function resetMissedTurns() {
      self.missedTurns = 0;
    }

    function setStatus(status: PlayerStateStatus) {
      self.status = status;
    }

    function updateInfo(name: string, imageUrl: string, socketId: string) {
       self.name = name;
       self.imageUrl = imageUrl;
       self.socketId = socketId;
    }

    return { incMissedTurns, resetMissedTurns, setStatus, updateInfo };
  })
  .views((self) => {
    return { };
  });

// PlayerState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IPlayerState = typeof PlayerState.Type;

export { PlayerState, IPlayerState };
