import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AnimationGroup } from "@babylonjs/core/Animations/animationGroup";
import { Observer } from "@babylonjs/core/Misc/observable";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Ellipse } from "@babylonjs/gui/2D/controls/ellipse";
import { Grid } from "@babylonjs/gui/2D/controls/grid";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import { ArgoSystem } from "components/game/ArgoSystem";
import { AdStatus } from "components/ui/ad-system/AdParams";
import { OfferLifePopUp } from "components/ui/ad-system/ads/OfferLifePopUp";
import { Commands } from "components/ui/Commands";
import { ImageWithFallback, ProgressBar } from "components/ui/Controls";
import { HomeScreenSystem } from "components/ui/HomeScreenSystem";
import { LeaderboardScreen } from "components/ui/LeaderboardScreen";
import { LevelUpScreen } from "components/ui/LevelUpScreen";
import { MenuPopUp } from "components/ui/MenuPopUp";
import { MetaGamePanel } from "components/ui/MetaGamePanel";
import { disposeGuiControl, findGuiControl, toast, zIndex } from "components/utils/GUI";
import { getXPLevelTierColor } from "components/utils/XPTierColors";
import { ROOT_STATE_HOME_SCREEN } from "states/RootState";
import { META_GAME_INPROGRESS, META_GAME_OFFER_LIFE, META_GAME_OVER } from "states/user/MetaGameState";
import { config } from "utils/Config";

import pawn0AvatarUrl from "components/game/avatars/pawn0.png";
import bagIconUrl from "components/ui/icons/bag.png";

const ZOOM_SCALE = 1.75;

interface IUserStatusStateChange {
  variable: string;
  priority: number;
  fromValue: number;
  toValue: number;
}

interface IMetaXpSnapshot {
  lives: number;
  unlockedLives: number;
  score: number;
  points: number;
  level: number;
}

enum ChangePriority {
  XP_POINTS,
  LEVEL,
  ZOOM_OUT,
  SCORE,
  LIVES_UNLOCKED_INCREASE,
  LIVES,
  LIVES_UNLOCKED_DECREASE,
  ZOOM_IN,
  GAME_OVER,
  OFFER_LIFE,
  NEW_GAME,
}

export class UserStatusSystem extends ArgoSystem {
  metaXpSnapshot: IMetaXpSnapshot;
  changeQueue: IUserStatusStateChange[] = [];
  basePriority = 0;
  quickPlayMode: boolean;
  animateChangesFlag = false; // True if changes should be animated
  animatingChanges = false; // True if changes are currently being animated
  _currentAnimation: Animatable | AnimationGroup;
  inOutAnimation: Animatable;
  pauseAnimation = false;
  onAnimateChangesDoneCallback: () => void;
  metaGamePanel: MetaGamePanel;
  metaGamePanelIsChild = false;
  metaGamePanelOnAnimatedInObserver: Observer<void>;
  metaGamePanelOnHeartAdClickedObserver: Observer<void>;
  metaGamePanelOnDisposeObserver: Observer<Control>;

  userLevel: TextBlock;
  userLevelBar: ProgressBar;

  init() {
    // Watch for displaying the status gui
    this.rootState.router.addRoute("^\/status$", (patch: any, reversePatch: any, params: any) => this.check());

    // Watch for changes to metaGame lives and score
    this.rootState.router.addRoute("^\/user\/metaGame\/?", (patch: any, reversePatch: any, params: any) => this.onMetaGameChanged(patch, reversePatch, params));

    // Watch for changes to xp
    this.rootState.router.addRoute("^\/user\/xp\/?", (patch: any, reversePatch: any, params: any) => this.onXPChanged(patch, reversePatch, params));

    MenuPopUp.onMenuPopUpObservable.add((showMenu) => this.onMenuPopup(showMenu));
  }

  check() {
    if(this.rootState.status === ROOT_STATE_HOME_SCREEN)
      this.create();
    else
      this.animateOut();
  }

  onMenuPopup(showMenu: boolean) {
    if(this.rootState.status === ROOT_STATE_HOME_SCREEN)
      return;

    // XXX - Maybe there's a less hacky way to prevent interfering with the Spades Round Summary?
    if(findGuiControl("SpadesRoundSummary") || findGuiControl("GameOverCardScreen"))
      return;

    if(showMenu)
      this.create(true, false, false);
    else
      this.animateOut();
  }

  create(animateIn = true, initFromSnapshot = false, showTitle = true) {
    // Make sure we're starting fresh
    this.dispose();

    // Determine if quickPlay information should be included
    let homeScreen = (this.rootState.status === ROOT_STATE_HOME_SCREEN);
    this.quickPlayMode = (homeScreen || this.game.gameState.options.name === "quickPlay");

    // Default to the current state
    let lives = this.rootState.user.metaGame.lives;
    let unlockedLives = this.rootState.user.metaGame.unlockedLives;
    let score = this.rootState.user.metaGame.score;
    let points = this.rootState.user.xp.points;
    let level = this.rootState.user.xp.level;

    // If we're animating, start at the most recent snapshot
    if(initFromSnapshot && this.metaXpSnapshot) {
      lives = this.metaXpSnapshot.lives;
      unlockedLives = this.metaXpSnapshot.unlockedLives;
      score = this.metaXpSnapshot.score;
      points = this.metaXpSnapshot.points;
      level = this.metaXpSnapshot.level;
    } else {
      // Clear any queued changes
      this.resetChangeQueueAndUpdateSnapshot();

      // Check for META_GAME_OFFER_LIFE
      // If we're in that state at this point, we want to simply end the previous metagame
      this._endMetaGameIfOfferLife();
    }

    if(!initFromSnapshot) {
      // Flag to animate any further changes that come in
      this.animateChangesFlag = true;
    }

    let box = new Rectangle("UserStatusGUI");
    box.zIndex = zIndex.ABOVE_GAME; // Keep above bidbox
    box.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    box.thickness = 0;
    this.game.guiTexture.addControl(box);

    // Background
    let background = new Rectangle("UserStatusGUIBackground");
    background.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    background.background = "black";
    background.thickness = 0;
    background.alpha = 0.5;
    box.addControl(background);

    // Grid
    let grid = new Grid("UserStatusGUIGrid");
    grid.addRowDefinition(1, true);
    grid.addColumnDefinition(1, true); // Spacer for menu button
    grid.addColumnDefinition(0.25);
    grid.addColumnDefinition(0.4);
    grid.addColumnDefinition(0.35);
    box.addControl(grid);

    // Game Title
    let title = new TextBlock("UserStatusGUIGameTitle");
    title.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    title.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    title.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    title.fontFamily = config.fontFamily;
    title.fontWeight = config.fontWeight;
    title.text = config.longName;
    title.color = "white";
    title.isVisible = showTitle;
    grid.addControl(title, 0, 1);

    let centerBox = new Rectangle("UserStatusGUICenterBox");
    centerBox.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    centerBox.thickness = 0;
    centerBox.isVisible = !homeScreen;
    grid.addControl(centerBox, 0, 2);

    let centerTitleStack = new StackPanel("UserStatusGUICenterStack");
    centerTitleStack.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    centerTitleStack.isVertical = false;
    centerTitleStack.adaptHeightToChildren = true;
    centerBox.addControl(centerTitleStack);

    let centerTitle = new TextBlock("UserStatusGUICenterTitle");
    centerTitle.fontFamily = config.fontFamily;
    centerTitle.fontWeight = config.fontWeight;
    centerTitle.text = "Classic Play";
    centerTitle.color = "white";
    centerTitle.resizeToFit = true;
    centerTitleStack.addControl(centerTitle);

    if(homeScreen) {
      // Try to find the Home Screen metaGamePanel
      let homeScreenSystem = this.game.systems.get("HomeScreenSystem") as HomeScreenSystem;
      if(homeScreenSystem)
        this.metaGamePanel = homeScreenSystem.metaGamePanel;
      if(this.metaGamePanel) {
        this.pauseAnimation = true;
        this.metaGamePanelIsChild = false;
        this.connectMetaGamePanel();
        this.metaGamePanel.update(lives, unlockedLives, score);
      }
    } else {
      if(this.quickPlayMode) {
        centerTitle.text = "Quick Play Score";

        this.metaGamePanel = new MetaGamePanel("UserStatusMeta", 4, 32, false);
        this.metaGamePanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        centerBox.addControl(this.metaGamePanel);

        this.metaGamePanelIsChild = true;
        this.connectMetaGamePanel();
        this.metaGamePanel.update(lives, unlockedLives, score);
      } else {
        if(this.game.gameState.options.name === "classicStandAlone")
          centerTitle.text = "Classic Stand Alone";
        else if(this.game.gameState.options.name === "handChallenge")
          centerTitle.text = "Hand Challenge";
        else if(this.game.gameState.options.name === "spot")
          centerTitle.text = "Spot Hearts";

        // optionally show list of Teams, Score and Bags (Spades Only)
        if(config.showTeamsOnUserStatus) {
          let classicGrid = new Grid("UserStatusGUIClassicGrid");
          classicGrid.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
          classicGrid.addRowDefinition(1, true);
          classicGrid.addRowDefinition(1, true);
          classicGrid.addColumnDefinition(0.2);  // padding?
          classicGrid.addColumnDefinition(0.3);  // Blue Team/RedTeam
          classicGrid.addColumnDefinition(0.2);  // Score Bags
          classicGrid.addColumnDefinition(0.2);  // padding?
          centerBox.addControl(classicGrid);

          // Row 0
          let classicTeam0 = new TextBlock("UserStatusGUIClassicTeam0");
          classicTeam0.fontFamily = config.fontFamily;
          classicTeam0.fontWeight = config.fontWeight;
          classicTeam0.color = "white";
          classicTeam0.text = "Red Team";
          classicGrid.addControl(classicTeam0, 0, 1);

          // Stack Score, BagIcon, and Bags in one cell
          let scoreStack0 = new StackPanel("UserStatusGUIScoreStack0");
          scoreStack0.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
          scoreStack0.isVertical = false;
          scoreStack0.adaptHeightToChildren = true;
          classicGrid.addControl(scoreStack0, 0, 2);

          let classicScore0 = new TextBlock("UserStatusGUIClassicScore0");
          classicScore0.fontFamily = config.fontFamily;
          classicScore0.fontWeight = config.fontWeight;
          classicScore0.color = "white";
          classicScore0.resizeToFit = true;
          scoreStack0.addControl(classicScore0);

          let classicBagIcon0 = new Image("UserStatusGUIClassicBagIcon0", bagIconUrl);
          scoreStack0.addControl(classicBagIcon0);

          let classicBags0 = new TextBlock("UserStatusGUIClassicBags0");
          classicBags0.fontFamily = config.fontFamily;
          classicBags0.fontWeight = config.fontWeight;
          classicBags0.color = "white";
          classicBags0.resizeToFit = true;
          scoreStack0.addControl(classicBags0);

          // Row 1
          let classicTeam1 = new TextBlock("UserStatusGUIClassicTeam1");
          classicTeam1.fontFamily = config.fontFamily;
          classicTeam1.fontWeight = config.fontWeight;
          classicTeam1.color = "white";
          classicTeam1.text = "Blue Team";
          classicGrid.addControl(classicTeam1, 1, 1);

          // Stack Score, BagIcon, and Bags in one cell
          let scoreStack1 = new StackPanel("UserStatusGUIScoreStack1");
          scoreStack1.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
          scoreStack1.isVertical = false;
          scoreStack1.adaptHeightToChildren = true;
          classicGrid.addControl(scoreStack1, 1, 2);

          let classicScore1 = new TextBlock("UserStatusGUIClassicScore1");
          classicScore1.fontFamily = config.fontFamily;
          classicScore1.fontWeight = config.fontWeight;
          classicScore1.color = "white";
          classicScore1.resizeToFit = true;
          scoreStack1.addControl(classicScore1);

          let classicBagIcon1 = new Image("UserStatusGUIClassicBagIcon1", bagIconUrl);
          scoreStack1.addControl(classicBagIcon1);

          let classicBags1 = new TextBlock("UserStatusGUIClassicBags1");
          classicBags1.fontFamily = config.fontFamily;
          classicBags1.fontWeight = config.fontWeight;
          classicBags1.color = "white";
          classicBags1.resizeToFit = true;
          scoreStack1.addControl(classicBags1);

          this.updateClassicScore();
        }
      }
    }

    let titleInfoSpacer = new Control("UserStatusGUITitleInfoSpacer");
    centerTitleStack.addControl(titleInfoSpacer);

    let titleInfoButton = new Button("UserStatusGUITitleInfoButton");
    titleInfoButton.thickness = 0;
    centerTitleStack.addControl(titleInfoButton);

    let titleInfoButtonEllipse = new Ellipse("UserStatusGUITitleInfoButtonEllipse");
    titleInfoButton.addControl(titleInfoButtonEllipse);

    let titleInfoButtonText = new TextBlock("UserStatusGUITitleInfoButtonText");
    titleInfoButtonText.fontFamily = config.fontFamily;
    titleInfoButtonText.fontWeight = config.fontWeight;
    titleInfoButtonText.fontSize = (titleInfoButton.heightInPixels * 0.8) + "px";
    titleInfoButtonText.text = "?";
    titleInfoButtonText.color = "white";
    titleInfoButton.addControl(titleInfoButtonText);
    titleInfoButton.onPointerClickObservable.add(() => Commands.onHelp());

    let imageStack = new StackPanel("UserStatusGUIImageStack");
    imageStack.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    imageStack.isVertical = false;
    imageStack.adaptHeightToChildren = true;
    grid.addControl(imageStack, 0, 3);

    // User Info
    let userStack = new StackPanel("UserStatusGUIUserStack");
    userStack.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    userStack.adaptWidthToChildren = true;
    imageStack.addControl(userStack);

    /*
    let userName = new TextBlock("UserStatusGUIUserName");
    userName.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    userName.fontFamily = config.fontFamily;
    userName.fontWeight = config.fontWeight;
    userName.color = "white";
    userName.text = this.rootState.user.name;
    userStack.addControl(userName);
    */

    this.userLevel = new TextBlock("UserStatusGUIUserLevel");
    this.userLevel.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.userLevel.fontFamily = config.fontFamily;
    this.userLevel.fontWeight = config.fontWeight;
    this.userLevel.color = "white";
    userStack.addControl(this.userLevel);

    this.userLevelBar = new ProgressBar("UserStatusGUIUserLevelBar");
    this.userLevelBar.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.userLevelBar.background = "#3609cd";
    userStack.addControl(this.userLevelBar);

    this.userLevelBar.bar.background = "#03b6dd";

    this.userLevelBar.text.fontFamily = config.fontFamily;
    this.userLevelBar.text.fontWeight = config.fontWeight;
    this.userLevelBar.text.color = "white";

    let imageSpacer = new Control("UserStatusGUIImageSpacer");
    imageStack.addControl(imageSpacer);

    // Image Background - clips the image
    let imagebg = new Rectangle("UserStatusGUIImageBackground");
    imagebg.background = "black";
    imagebg.thickness = 0;
    imageStack.addControl(imagebg);

    // User Image
    let imageUrl = this.game.rootState.user.imageUrl || pawn0AvatarUrl;
    let image = new ImageWithFallback("UserStatusGUIImage", imageUrl, pawn0AvatarUrl);

    imagebg.addControl(image);

    // Size and position the controls
    this.layout();
    this.updateXP(points, level);

    if(animateIn) {
      this.cancelInOutAnimation();

      let a = new Animation("UserStatusIntro", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let keyFrames = [];
      let k0 = 0;

      if(this.rootState.status === ROOT_STATE_HOME_SCREEN)
        k0 = 25; // Waiting for Home Screen cards to slide in - (assumes the sidebar is always displayed with the home screen!)

      let k1 = k0 + 5;
      keyFrames.push({frame: k0, value: -box.heightInPixels});
      keyFrames.push({frame: k1, value: 0});
      a.setKeys(keyFrames);

      // No elastic easing, because it would pull away from the edge of the screen, we'd need the box to be oversized to do a bounce

      this.inOutAnimation = this.game.scene.beginDirectAnimation(box, [a], 0, k1, false, 1.0, () => this.inOutAnimation = null);
    }
  }

  updateSnapshot() {
    this.metaXpSnapshot = {
      lives: this.rootState.user.metaGame.lives,
      unlockedLives: this.rootState.user.metaGame.unlockedLives,
      score: this.rootState.user.metaGame.score,
      points: this.rootState.user.xp.points,
      level: this.rootState.user.xp.level,
    };
  }

  layout() {
    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let menuButton = findGuiControl("menuButton");
    let menuButtonWidth = menuButton ? menuButton.widthInPixels : 0;

    let guiWidth = this.game.guiTexture.getSize().width;
    let guiHeight = this.game.guiTexture.getSize().height - menuBarHeight;

    let boxWidth = guiWidth;
    let boxHeight = Math.min(guiWidth / 8, guiHeight / 5); // width/8 protects against boxy aspect ratios (iPad's 4:3)

    let backgroundHeight = boxHeight * 0.7;

    let spacerWidth = boxHeight * 0.25;

    let titleWidth = boxHeight * 4;
    let titleHeight = backgroundHeight * 0.5;

    let quickPlayWidth = boxHeight * 4;
    let quickPlayHeight = backgroundHeight;

    let centerTitleHeight = quickPlayHeight * 0.333;

    let classicTextHeight = centerTitleHeight * 0.8;

    let userTextHeight = backgroundHeight * 0.333;

    let heartWidth = backgroundHeight * 0.667;

    let imageWidth = boxHeight * 0.75;
    let imageHeight = imageWidth;

    let box = findGuiControl("UserStatusGUI") as Rectangle;
    box.width = boxWidth + "px";
    box.height = boxHeight + "px";

    // Background
    let background = findGuiControl("UserStatusGUIBackground") as Rectangle;
    background.width = boxWidth + "px";
    background.height = backgroundHeight + "px";

    // Grid
    let grid = findGuiControl("UserStatusGUIGrid") as Grid;
    grid.setRowDefinition(0, boxHeight, true);
    grid.setColumnDefinition(0, menuButtonWidth * 1.1, true);

    // Game Title
    let title = findGuiControl("UserStatusGUIGameTitle") as TextBlock;
    title.width = titleWidth + "px";
    title.height = backgroundHeight + "px";
    title.fontSize = (titleHeight * 0.8) + "px";

    let centerBox = findGuiControl("UserStatusGUICenterBox") as Rectangle;
    centerBox.width = quickPlayWidth + "px";
    centerBox.height = quickPlayHeight + "px";

    let centerTitle = findGuiControl("UserStatusGUICenterTitle") as TextBlock;
    centerTitle.fontSize = (centerTitleHeight * 0.8) + "px";

    if(this.quickPlayMode) {
      if(this.metaGamePanel && this.metaGamePanelIsChild)
        this.metaGamePanel.setHeartSize(heartWidth);
    } else {
      // It appears a resize at the wrong time can result in quickplay being false without a classicGrid
      let classicGrid = findGuiControl("UserStatusGUIClassicGrid") as Grid;
      if(classicGrid) {
        classicGrid.height = (classicTextHeight * 2) + "px";
        classicGrid.setRowDefinition(0, classicTextHeight, true);
        classicGrid.setRowDefinition(1, classicTextHeight, true);

        // Row 0 Team0
        let classicTeam0 = findGuiControl("UserStatusGUIClassicTeam0") as TextBlock;
        classicTeam0.fontSize = (classicTextHeight * 0.8) + "px";

        let classicScore0 = findGuiControl("UserStatusGUIClassicScore0") as TextBlock;
        classicScore0.fontSize = (classicTextHeight * 0.8) + "px";

        let classicBagIcon0 = findGuiControl("UserStatusGUIClassicBagIcon0") as TextBlock;
        classicBagIcon0.width = (classicTextHeight * 0.8) + "px";
        classicBagIcon0.height = (classicTextHeight * 0.8) + "px";

        let classicBags0 = findGuiControl("UserStatusGUIClassicBags0") as TextBlock;
        classicBags0.fontSize = (classicTextHeight * 0.8) + "px";

        // Row 1 Team 1
        let classicTeam1 = findGuiControl("UserStatusGUIClassicTeam1") as TextBlock;
        classicTeam1.fontSize = (classicTextHeight * 0.8) + "px";

        let classicScore1 = findGuiControl("UserStatusGUIClassicScore1") as TextBlock;
        classicScore1.fontSize = (classicTextHeight * 0.8) + "px";

        let classicBagIcon1 = findGuiControl("UserStatusGUIClassicBagIcon1") as TextBlock;
        classicBagIcon1.width = (classicTextHeight * 0.8) + "px";
        classicBagIcon1.height = (classicTextHeight * 0.8) + "px";

        let classicBags1 = findGuiControl("UserStatusGUIClassicBags1") as TextBlock;
        classicBags1.fontSize = (classicTextHeight * 0.8) + "px";
      }
    }

    let titleInfoSpacer = findGuiControl("UserStatusGUITitleInfoSpacer") as Control;
    titleInfoSpacer.width = (centerTitleHeight * 0.667) + "px";
    titleInfoSpacer.height = (centerTitleHeight * 0.667) + "px";

    let titleInfoButton = findGuiControl("UserStatusGUITitleInfoButton") as Ellipse;
    titleInfoButton.width = (centerTitleHeight * 0.667) + "px";
    titleInfoButton.height = (centerTitleHeight * 0.667) + "px";

    let titleInfoButtonText = findGuiControl("UserStatusGUITitleInfoButtonText") as TextBlock;
    titleInfoButtonText.fontSize = (titleInfoButton.heightInPixels * 0.8) + "px";

    // User Info
    /*
    let userName = findGuiControl("UserStatusGUIUserName") as TextBlock;
    userName.width = quickPlayWidth + "px";
    userName.height = userTextHeight + "px";
    userName.fontSize = (userTextHeight * 0.8) + "px";
    */

    let userLevel = findGuiControl("UserStatusGUIUserLevel") as TextBlock;
    userLevel.width = quickPlayWidth + "px";
    userLevel.height = userTextHeight + "px";
    userLevel.fontSize = (userTextHeight * 0.8) + "px";

    let userLevelBar = findGuiControl("UserStatusGUIUserLevelBar") as Rectangle;
    userLevelBar.width = (quickPlayWidth * 0.5) + "px";
    userLevelBar.height = userTextHeight + "px";

    let userLevelBarProgress = findGuiControl("UserStatusGUIUserLevelBarProgress") as Rectangle;
    userLevelBarProgress.left = userLevelBar.widthInPixels - userLevelBar.widthInPixels;
    userLevelBarProgress.height = userTextHeight + "px";

    let userLevelProgressText = findGuiControl("UserStatusGUIUserLevelBarProgressText") as TextBlock;
    userLevelProgressText.width = (quickPlayWidth * 0.4) + "px";
    userLevelProgressText.height = userTextHeight + "px";
    userLevelProgressText.fontSize = (userTextHeight * 0.8) + "px";

    let imageSpacer = findGuiControl("UserStatusGUIImageSpacer") as Control;
    imageSpacer.width = spacerWidth + "px";
    imageSpacer.height = imageHeight + "px";

    // Image Background - clips the image
    let imagebg = findGuiControl("UserStatusGUIImageBackground") as Rectangle;
    imagebg.width = imageWidth + "px";
    imagebg.height = imageHeight + "px";
    imagebg.cornerRadius = imageHeight / 8;
  }

  updateClassicScore() {
    for(let team of this.game.gameState.teams) {
      let score = team.score;
      let bags = team.bags;

      /*score = -999;
      bags = 9;*/

      let classicScore = findGuiControl("UserStatusGUIClassicScore" + team.id) as TextBlock;
      if(classicScore)
         classicScore.text = `${score} `;

      let classicBags = findGuiControl("UserStatusGUIClassicBags" + team.id) as TextBlock;
      if(classicBags)
        classicBags.text = `${bags}`;
      }
  }

  pushChange(change: IUserStatusStateChange) {
    // Add basePriority to the incoming change
    change.priority += this.basePriority;

    for(let entry of this.changeQueue) {
      // Ignore the change if it is already in the queue
      if(
        entry.variable === change.variable &&
        entry.priority === change.priority &&
        entry.fromValue === change.fromValue &&
        entry.toValue === change.toValue
        )
        return;
      // If just the variable and priority match up, try to merge the new entry into the old one
      if(
        entry.variable === change.variable &&
        entry.priority === change.priority
        ) {
          // We'll keep the older from value and use the newer to value
          entry.toValue = change.toValue;
          return;
        }
    }

    // If the metagame has ended, increase the base priority for any changes that come in for the next metagame
    if(change.variable === "metaGameOver" && change.toValue === 1)
      this.basePriority += 100;

    // if(change.variable === "lives") console.log(`XXX - [${this.game.scene.getRenderId()}] pushChange [${change.priority}] ${change.variable} ${change.fromValue} -> ${change.toValue}`)

    this.changeQueue.push(change);
    this.changeQueue.sort((a, b) => a.priority - b.priority);
  }

  resetChangeQueueAndUpdateSnapshot() {
    // Empty the queue
    this.changeQueue.length = 0;

    // Reset the base priority
    this.basePriority = 0;

    // Update snapshot
    this.updateSnapshot();

    // The queue might have contained "offerExtraLife", in which case the meta game won't end
    // We don't need to search, however, because we already have a helper function
    // to check for the offer state and end the metagame if needed
    this._endMetaGameIfOfferLife();
  }

  animateChanges(onAnimateChangesDoneCallback: () => void = null) {
    this.animateChangesFlag = true;

    this.onAnimateChangesDoneCallback = onAnimateChangesDoneCallback;

    if(!this.changeQueue.length) {
      // If there's a callback, run a dummy animation so it gets called next frame
      if(onAnimateChangesDoneCallback)
        this.animatingChanges = true;
      return;
    }

    this.animatingChanges = true;
  }

  animateNextChange() {
    if(this.currentAnimation || this.pauseAnimation)
      return;

    if(this.changeQueue.length === 0) {
      // Done
      this.animatingChanges = false;
      this.basePriority = 0;

      // Update snapshot
      this.updateSnapshot();

      // Callback
      if(this.onAnimateChangesDoneCallback) {
        this.onAnimateChangesDoneCallback();
        this.onAnimateChangesDoneCallback = null;
      }

      return;
    }

    let change = this.changeQueue.shift();
    switch(change.variable) {
      case "lives":
        this.animateLives(change.fromValue, change.toValue);
        break;
      case "livesUnlocked":
        this.animateLivesUnlocked(change.fromValue, change.toValue);
        break;
      case "score":
        this.animateScore(change.fromValue, change.toValue);
        break;
      case "offerExtraLife":
        this.offerExtraLife();
        break;
      case "metaGameOver":
        this.animateMetaGameOver(change.fromValue, change.toValue);
        break;
      case "zoom":
        this.animateZoom(change.fromValue, change.toValue);
        break;
      case "xp_points":
        this.animateXPPoints(change.fromValue, change.toValue);
        break;
      case "xp_level":
        this.animateXPLevel(change.fromValue, change.toValue);
        break;
    }
  }

  animateZoom(from: number, to: number) {
    let gui = findGuiControl("UserStatusGUI");
    if(!gui)
      return;

    // Currently, we only zoom to highlight the embedded metaGamePanel
    if(!this.metaGamePanelIsChild)
      return;

    let fromTop = (from - 1) * gui.heightInPixels * 0.5;
    let toTop = (to - 1) * gui.heightInPixels * 0.5;

    // XXX - It turns out Animatable has an appendAnimations function that may be good enough for this, so we don't need Animatable | AnimationGroup
    let animationGroup = new AnimationGroup("UserStatusGUILivesAnimationGroup");

    let ax = new Animation("UserStatusZoomAnimationX", "scaleX", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = k0 + 10;
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    ax.setKeys(keyFrames);
    animationGroup.addTargetedAnimation(ax, gui);

    let ay = new Animation("UserStatusZoomAnimationY", "scaleY", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    ay.setKeys(keyFrames);
    animationGroup.addTargetedAnimation(ay, gui);

    let am = new Animation("UserStatusZoomAnimationTop", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let moveKeyFrames = [];
    moveKeyFrames.push({frame: k0, value: fromTop});
    moveKeyFrames.push({frame: k1, value: toTop});
    am.setKeys(moveKeyFrames);
    animationGroup.addTargetedAnimation(am, gui);

    animationGroup.start(false, 1.0, 0, k1);

    this.currentAnimation = animationGroup;
  }

  animateXPPoints(from: number, to: number) {
    if(!this.userLevel) // no userLevel means UserStatus is not currently shown
      return;

    // XXX - If the level has advanced, we actually want to use level -1
    let level = this.rootState.user.xp.level;

    let pointsCurLevel = this.rootState.user.xp.xpForLevel(level);
    let pointsNeeded = this.rootState.user.xp.xpForLevel(level + 1);
    let fromProgress = (from - pointsCurLevel) / (pointsNeeded - pointsCurLevel); // we need to subtract pointsCurLevel so the progress every level will be 0 - 100
    let toProgress = (to - pointsCurLevel) / (pointsNeeded - pointsCurLevel); // we need to subtract pointsCurLevel so the progress every level will be 0 - 100

    this.updateXPText(to, level);

    // XXX - If the level has advanced, we want to animate to 100%, reset to 0, then animate to any progress into the next level
    this.currentAnimation = this.userLevelBar.animateProgress(fromProgress, toProgress, this.game.scene);
  }

  animateXPLevel(from: number, to: number) {
    if(!this.userLevel) // no userLevel means UserStatus is not currently shown
      return;

    let level = to;

    this.userLevel.color = getXPLevelTierColor(level);
    this.userLevel.text = "Level " + level;

    if(from === 0) {
      // This is the initial level report, not a levelup
      return;
    }

    // Display the level up screen
    this.pauseAnimation = true;
    LevelUpScreen.showLevelUpScreen().then( () => {
      this.pauseAnimation = false;
    });
  }

  animateLives(from: number, to: number) {
    if(this.metaGamePanel)
      this.currentAnimation = this.metaGamePanel.animateLives(from, to);
  }

  animateLivesUnlocked(from: number, to: number) {
    if(this.metaGamePanel)
      this.currentAnimation = this.metaGamePanel.animateLivesUnlocked(from, to);
  }

  animateScore(from: number, to: number) {
    if(this.metaGamePanel)
      this.currentAnimation = this.metaGamePanel.animateScore(from, to);
  }

  /** Present the player with an opportunity to get an extra life and continue playing the same Meta Game.  Called when losing the last life
   * In this case the changeQueue should be empty since the Meta Game did not end, so do not need to worry about pausing animations
   * We do, however, need to make sure to call endMetaGame if the player does not extend the game.
   */
  offerExtraLife() {
    let popUp = OfferLifePopUp.show();
    popUp.onDisposeObservable.add(() => {
      if(popUp.disposeReason === "watchAd") {
        this._showHeartRewardAd();
      } else if(popUp.disposeReason === "purchasePass") {
        this._showInstaPassAd();
      } else {
        // End the metagame now
        this._endMetaGameIfOfferLife();
      }
    });
  }

  /** Helper for offerExtraLife to show a reward ad and award an extra life if it succeeds */
  _showHeartRewardAd() {
    Commands.onShowAd("heartReward", true, (status: AdStatus) => {
      if(status === AdStatus.AD_STATUS_FINISHED) {
        this.rootState.user.metaGame.unlockLife(this.rootState.user.id);
      } else {
        // End the metagame now
        this._endMetaGameIfOfferLife();

        let message = "Reward ad unavailable.";
        if(status === AdStatus.AD_STATUS_REWARD_NOT_COMPLETED)
          message = "Reward ad not completed.";
        if(this.rootState.paymentsSupported) {
          toast("HeartAdUnavailable", `${message} Get an Insta Pass for a bonus life!`, 0, 0, true, "Insta Pass", () => {
            this._showInstaPassAd();
          });
        }
        else {
          toast("HeartAdUnavailable", message);
        }
      }
    });
  }

  /** Helper for offerExtraLife to show the insta pass ad */
  _showInstaPassAd() {
    this.game.adSystem.showSpecificAd("argo", "instaPass", (status) => {
      // If the InstaPass was successfully purchased, the meta game should resume
      // In all other cases, we need to end the meta game
      if(status !== AdStatus.AD_STATUS_ACTION_SUCCEEDED) {
        this._endMetaGameIfOfferLife();
      }
    });
  }

  /** Helper to end the meta game if it is currently in the offer life state */
  _endMetaGameIfOfferLife() {
    if(this.rootState.user.metaGame.status === META_GAME_OFFER_LIFE)
      this.rootState.user.metaGame.endMetaGame(this.rootState.user.id);
  }

  animateMetaGameOver(from: number, to: number) {
    // Only go to the leaderboard screen from the SpadesRoundSummary or GameOverCardScreen (not the HomeScreen)
    if(!findGuiControl("SpadesRoundSummary") && !findGuiControl("GameOverCardScreen"))
      return;

    if(to) {
      // Display the Leaderboard screen
      this.pauseAnimation = true;
      LeaderboardScreen.showLeaderboardScreen(true).then( () => {
        this.pauseAnimation = false;
      });
    }
  }

  get currentAnimation(): Animatable | AnimationGroup {
    return this._currentAnimation;
  }

  set currentAnimation(animation: Animatable | AnimationGroup) {
    if(this._currentAnimation)
      this._currentAnimation.stop();

    this._currentAnimation = animation;

    if(animation instanceof Animatable) {
      let animatable = animation as Animatable;
      animatable.onAnimationEndObservable.add(() => {
        this._currentAnimation = null;
      });
    } else if(animation instanceof AnimationGroup) {
      let animationGroup = animation as AnimationGroup;
      animationGroup.onAnimationGroupEndObservable.add(() => {
        this._currentAnimation = null;
      });
    }
  }

  onMetaGameChanged(patch: any, reversePatch: any, params: any) {
    if(patch.op !== "replace")
      return;

    switch(patch.path) {
      case "/user/metaGame":
      case "/user/xp":
        this.resetChangeQueueAndUpdateSnapshot();
        break;
      case "/user/xp/points":
        this.pushChange({variable: "xp_points", priority: ChangePriority.XP_POINTS, fromValue: reversePatch.value, toValue: patch.value});
        break;
      case "/user/xp/level":
        this.pushChange({variable: "xp_level", priority: ChangePriority.LEVEL, fromValue: reversePatch.value, toValue: patch.value});
        break;
      case "/user/metaGame/score":
        this.pushChange({variable: "zoom", priority: ChangePriority.ZOOM_OUT, fromValue: 1, toValue: ZOOM_SCALE });
        this.pushChange({variable: "score", priority: ChangePriority.SCORE, fromValue: reversePatch.value, toValue: patch.value});
        this.pushChange({variable: "zoom", priority: ChangePriority.ZOOM_IN, fromValue: ZOOM_SCALE, toValue: 1});
        break;
      case "/user/metaGame/lives":
        this.pushChange({variable: "zoom", priority: ChangePriority.ZOOM_OUT, fromValue: 1, toValue: ZOOM_SCALE });
        this.pushChange({variable: "lives", priority: ChangePriority.LIVES, fromValue: reversePatch.value, toValue: patch.value});
        this.pushChange({variable: "zoom", priority: ChangePriority.ZOOM_IN, fromValue: ZOOM_SCALE, toValue: 1});
        break;
      case "/user/metaGame/unlockedLives":
        this.pushChange({variable: "zoom", priority: ChangePriority.ZOOM_OUT, fromValue: 1, toValue: ZOOM_SCALE });
        this.pushChange({variable: "livesUnlocked", priority: patch.value > reversePatch.value ? ChangePriority.LIVES_UNLOCKED_INCREASE : ChangePriority.LIVES_UNLOCKED_DECREASE, fromValue: reversePatch.value, toValue: patch.value});
        this.pushChange({variable: "zoom", priority: ChangePriority.ZOOM_IN, fromValue: ZOOM_SCALE, toValue: 1});
        break;
      case "/user/metaGame/status":
        if(patch.value === META_GAME_OVER) {
          this.pushChange({variable: "metaGameOver", priority: ChangePriority.GAME_OVER, fromValue: 0, toValue: 1});
        } else if(reversePatch.value === META_GAME_OVER && patch.value === META_GAME_INPROGRESS) {
          this.pushChange({variable: "metaGameOver", priority: ChangePriority.NEW_GAME, fromValue: 1, toValue: 0});
        } else if(patch.value === META_GAME_OFFER_LIFE) {
          // If we're on the home screen, end the meta game now, otherwise queue up the offer to display when the hearts finish animating
          if(this.rootState.status === ROOT_STATE_HOME_SCREEN)
            this._endMetaGameIfOfferLife();
          else
            this.pushChange({variable: "offerExtraLife", priority: ChangePriority.OFFER_LIFE, fromValue: 0, toValue: 0});
        }
        break;
    }
  }

  onXPChanged(patch: any, reversePatch: any, params: any) {
    this.onMetaGameChanged(patch, reversePatch, params);
    //this.updateXP();
  }

  /** Updates the text and progress bar with the current xp info from User */
  updateXP(points?: number, level?: number) {
    if(!this.userLevel) // no userLevel means UserStatus is not currently shown
      return;

    if(points === undefined)
      points = this.rootState.user.xp.points;

    if(level === undefined)
      level = this.rootState.user.xp.level;

    let pointsCurLevel = this.rootState.user.xp.xpForLevel(level);
    let pointsNeeded = this.rootState.user.xp.xpForLevel(level + 1);
    let progress = (points - pointsCurLevel) / (pointsNeeded - pointsCurLevel); // we need to subtract pointsCurLevel so the progress every level will be 0 - 100

    this.userLevel.color = getXPLevelTierColor(level);
    this.userLevel.text = "Level " + level;
    this.updateXPText(points, level);

    this.userLevelBar.setProgress(progress);
  }

  /** Update xp progress text. We show the number of points earned in the current level, and the number of points needed to get to next level from last, ie 100 / 750 xp */
  updateXPText(points?: number, level?: number) {
    if(!this.userLevel) // no userLevel means UserStatus is not currently shown
      return;

    if(points === undefined)
      points = this.rootState.user.xp.points;

    if(level === undefined)
      level = this.rootState.user.xp.level;

    const pointsCur = points - this.rootState.user.xp.xpForLevel(level);
    const pointsNeeded = this.rootState.user.xp.xpForLevel(level + 1) - this.rootState.user.xp.xpForLevel(level);

    this.userLevelBar.text.text = "" + pointsCur + " / " + pointsNeeded + " xp";
  }

  cancelInOutAnimation() {
    if(!this.inOutAnimation)
      return;

    this.inOutAnimation.stop();
    this.inOutAnimation = null;
  }

  animateOut() {
    this.cancelInOutAnimation();

    let box = findGuiControl("UserStatusGUI") as Rectangle;
    if(!box)
      return;

    let a = new Animation("UserStatusOutro", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = k0 + 5;
    keyFrames.push({frame: k0, value: 0});
    keyFrames.push({frame: k1, value: -box.heightInPixels});
    a.setKeys(keyFrames);

    this.inOutAnimation = this.game.scene.beginDirectAnimation(box, [a], 0, k1, false, 1.0, () => this.dispose());
  }

  onHomeScreenMetaGamePanelAnimatedIn() {
    this.pauseAnimation = false;
  }

  onMetaGamePanelHeartAdClicked() {
    // Ignore the button in the offer life state since the user will be prompted momentarily with the choice between reward ad or insta pass
    if(this.rootState.user.metaGame.status === META_GAME_OFFER_LIFE)
      return;

    this._showHeartRewardAd();
  }

  onHomeScreenMetaGamePanelDisposed() {
    this.metaGamePanel = null;
    this.disconnectMetaGamePanel();
  }

  connectMetaGamePanel() {
    this.metaGamePanelOnHeartAdClickedObserver = this.metaGamePanel.onHeartAdClickedObservable.add(() => this.onMetaGamePanelHeartAdClicked());

    if(!this.metaGamePanelIsChild) {
      this.metaGamePanelOnAnimatedInObserver = this.metaGamePanel.onAnimatedInObservable.add(() => this.onHomeScreenMetaGamePanelAnimatedIn());
      this.metaGamePanelOnDisposeObserver = this.metaGamePanel.onDisposeObservable.add(() => this.onHomeScreenMetaGamePanelDisposed());
    }
  }

  disconnectMetaGamePanel() {
    if(!this.metaGamePanel) {
      this.metaGamePanelOnHeartAdClickedObserver = null;
      this.metaGamePanelOnAnimatedInObserver = null;
      this.metaGamePanelOnDisposeObserver = null;
      return;
    }

    if(this.metaGamePanelOnHeartAdClickedObserver) {
      this.metaGamePanel.onHeartAdClickedObservable.remove(this.metaGamePanelOnHeartAdClickedObserver);
      this.metaGamePanelOnHeartAdClickedObserver = null;
    }

    if(this.metaGamePanelOnAnimatedInObserver) {
      this.metaGamePanel.onAnimatedInObservable.remove(this.metaGamePanelOnAnimatedInObserver);
      this.metaGamePanelOnAnimatedInObserver = null;
    }

    if(this.metaGamePanelOnDisposeObserver) {
      this.metaGamePanel.onDisposeObservable.remove(this.metaGamePanelOnDisposeObserver);
      this.metaGamePanelOnDisposeObserver = null;
    }
  }

  dispose() {
    if(findGuiControl("UserStatusGUI")) {
      if(this.metaGamePanel) {
        this.disconnectMetaGamePanel();

        if(this.metaGamePanelIsChild) {
          // I don't know why metagamePanel.dispose isn't called when UserStatusGUI is disposed, but we do need to call it ourselves
          this.metaGamePanel.dispose();
        }

        this.metaGamePanel = null;
      }

      this.metaGamePanelIsChild = false;

      disposeGuiControl("UserStatusGUI");

      // Reset
      this.resetChangeQueueAndUpdateSnapshot();
      this.animateChangesFlag = false;
      this.animatingChanges = false;
      this.onAnimateChangesDoneCallback = null;

      this.userLevel = null;
      this.userLevelBar = null;
    }
  }

  newFrame(deltaTime: number): void {
    // Sometimes changes will arrive after animateChanges is called
    if(this.animateChangesFlag && !this.animatingChanges && this.changeQueue.length > 0 && !this.inOutAnimation)
      this.animateChanges();

    if(this.animatingChanges)
      this.animateNextChange();
  }

  resized(): void {
    if(findGuiControl("UserStatusGUI")) {
      this.layout();
      this.updateXP();
    }
  }
}
