import { CLUBS, DIAMONDS, HEARTS, SPADES } from "states/game/PieceState";

function rankFromValue(value: number) {
  return (value - 1) % 13 + 1;
}

function rankFromValueAceHigh(value: number) {
  let r = rankFromValue(value);
  if(r === 1)
    return 14;
  return r;
}

function suitFromValue(value: number) {
  return Math.floor((value - 1) / 13);
}

/** sorts cards best for Insta Spades. Diamonds, Clubs, Hearts, Spades, Ace High  */
export function spadesPieceValueCompare(a: number, b: number) {
  let getKey = (value: number) => {
    if(value === 0)
      return 0;

    let suit = suitFromValue(value);
    let rank = rankFromValueAceHigh(value);

    let key = 0;
    if(suit === DIAMONDS)
      key = 0;
    else if(suit === CLUBS)
      key = 100;
    else if(suit === HEARTS)
      key = 200;
    else if(suit === SPADES)
      key = 300;

    key += rank;

    return key;
  };

  return getKey(a) - getKey(b);
}

/** sorts cards best for Insta Hearts. Clubs, Diamonds, Spades, Hearts, Ace High  */
export function heartsPieceValueCompare(a: number, b: number) {
  let getKey = (value: number) => {
    if(value === 0)
      return 0;

    let suit = suitFromValue(value);
    let rank = rankFromValueAceHigh(value);

    let key = 0;
    if(suit === CLUBS)
      key = 0;
    else if(suit === DIAMONDS)
      key = 100;
    else if(suit === SPADES)
      key = 200;
    else if(suit === HEARTS)
      key = 300;

    key += rank;

    return key;
  };

  return getKey(a) - getKey(b);
}
