import { mergeConfig } from "utils/Config";

import alertBroken1Url from "./alert-broken.mp3";
import alertBroken2Url from "./alert-broken2.mp3";
import alertBroken3Url from "./alert-broken3.mp3";
import alertBroken4Url from "./alert-broken4.mp3";
import clearboard1Url from "./clearboard1.mp3";
import dinkUrl from "./dink.mp3";

mergeConfig({
  sounds: {
    // TrickGameAnimations.ts, when trick is won
    trickWon: {url: clearboard1Url, volume: 0.5},

    // TrickGameAnimations.ts, when a played card is the new trick winner
    trickWinning1: {url: alertBroken1Url, volume: 1.0},
    trickWinning2: {url: alertBroken2Url, volume: 1.0},
    trickWinning3: {url: alertBroken3Url, volume: 1.0},
    trickWinning4: {url: alertBroken4Url, volume: 1.0},

    // PointTokenSystem
    pointTokenArrive: {url: dinkUrl, volume: 0.25},
  },
});
