import { config } from "utils/Config";

import { ArgoSystem } from "components/game/ArgoSystem";
import { Commands } from "components/ui/Commands";
import { HelpSystem } from "components/ui/HelpSystem";
import { ROOT_STATE_GAME } from "states/RootState";

/** Base System class for tutorials. Tutorial sub classes watch for the game state changing and show help popup messages in response. */
export class TutorialSystem extends ArgoSystem {
  enabled: boolean = false;
  helpShown: string[] = []; // keep track of which tutorial helpKeys have been shown, so that we only show them once.
  helpSystem: HelpSystem;

  init() {
    this.helpSystem = this.game.systems.get("HelpSystem") as HelpSystem;

    // onEnableTutorialObservable is triggered when the user wants to start the tutorial during another game
    Commands.onEnableTutorialObservable.add((enable: boolean) => {
      this.resetTutorial();

      if(enable) {
        this.enabled = true;
        this.showTurnHelp();
      }
      else
        this.enabled = false;
    });

    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.onGameStatusChanged(patch, reversePatch, params));

    // overridden by subclass
    this.rootState.router.addRoute("^\/status$", (patch: any, reversePatch: any, params: any) => this.onRootStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/seatsTurn$", (patch: any, reversePatch: any, params: any) => this.onSeatsTurnChanged(patch, reversePatch, params));
  }

  resetTutorial() {
    // overridden by subclass
    this.helpShown = [];
  }

  /** returns true if we would show the helpKey */
  willShowHelp(helpKey: string): boolean {
    if(helpKey && config.help.hasOwnProperty(helpKey) && !this.helpShown.includes(helpKey)) {
      return true;
    }
    return false;
  }

  /** only show each helpKey once. */
  maybeShowHelp(helpKey: string, extra: any): boolean {
    if(this.willShowHelp(helpKey)) {
      this.helpShown.push(helpKey);
      Commands.onHelp({ helpKey, extra });
      return true;
    }
    return false;
  }

  /** onRootStatusChanged called when this.rootState.status changes. */
  onRootStatusChanged(patch: any, reversePatch: any, params: any) {
    // reset tutorial when ever we enter or leave a game.
    this.resetTutorial();

    if(patch.value === ROOT_STATE_GAME) {
      // Enable tutorial if a new game is starting with the tutorial option on
      if(this.game.gameStart && this.game.gameStart.tutorial) {
        this.enabled = true;
      }
      else
        this.enabled = false;
    }
  }

  onSeatsTurnChanged(patch: any, reversePatch: any, params: any) {
    // over ridden by subclass
    let seatsTurn = patch.value; // patch value is the id of the seat, not an ISeatState

    // Suggest to start tutorial if local seat is idle, delay is defined in help-config for myTurn_bid and myTurn_play
    if(!this.enabled && this.game.localSeat === seatsTurn)
      Commands.onHelp({ helpKey: `myTurn_${this.game.gameState.status}` });
  }

  onGameStatusChanged(patch: any, reversePatch: any, params: any) {
    this.showTurnHelp();
  }

  /** Shows help needed on your turn */
  showTurnHelp() {
    // over ridden by subclass
  }
}
