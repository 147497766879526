import { getParent, types } from "mobx-state-tree";

import { IGameState } from "states/game/GameState";
import { ISeatState } from "states/game/SeatState";

// tslint:disable-next-line:variable-name
const TeamState = types
  .model("TeamState", {
    id: types.identifier,  // id is team#, ie team 0 id is 0
    score: types.optional(types.number, 0),
    place: types.optional(types.number, 0),

    // trick game specific
    bags: types.optional(types.number, 0),

    // children
    //seats: types.array(types.reference(SeatState))this was the preferable way to define seats, but seats wouldn't restore from AppSync snapshots
    seats: types.array(types.string), // this is an array of seat.id's as strings, which does restore from snapshots ok.

    // When adding more properties be sure to review if they need to be copied in GameState.applySyncToServerSnapshot
  })
  .views((self) => {

    /** returns total bid of all seats on team */
    function getBid() {
      let gameState = getParent(getParent(self)) as IGameState;

      let bid = 0;
      self.seats.forEach((seatId) => {
        let seat = gameState.getSeat(seatId);
        bid += seat.bid;
      });
      return bid;
    }

    /** returns total tricks won of all seats on team */
    function getTricksWon() {
      let gameState = getParent(getParent(self)) as IGameState;

      let tricksWon = 0;
      self.seats.forEach((seatId) => {
        let seat = gameState.getSeat(seatId);
        tricksWon += seat.tricksWon;
      });
      return tricksWon;
    }

    /** Returns the 1st team member who's id does not match the given id  */
    function getPartnerSeat(seatOrId: ISeatState | string): ISeatState | null {
      let seatId = (typeof seatOrId === "string") ? seatOrId : seatOrId.id;
      let gameState = getParent(getParent(self)) as IGameState;
      for(let teamSeatId of self.seats) {
        if(teamSeatId !== seatId)
          return gameState.getSeat(teamSeatId);
      }
      return null;
    }

    return { getBid, getTricksWon, getPartnerSeat };
  })
  .actions((self) => {

    function reset() {
      self.place = 0;
      self.score = 0;
      self.bags = 0;
    }

    function setScore(score: number) {
      self.score = score;
    }

    function setBags(bags: number) {
      self.bags = bags;
    }

    return { reset, setBags, setScore };
  })
  .views((self) => {
    return { };
  });

// TeamState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type ITeamState = typeof TeamState.Type;

export { TeamState, ITeamState };
