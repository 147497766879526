import { game } from "components/game/Game";
import { AdStatus, IAdParams, IAdPlacement, IAdProvider } from "components/ui/ad-system/AdParams";
import { InstaPassPopUp } from "components/ui/ad-system/ads/InstaPassPopUp";
import { ArgoAdActionName, IArgoAd, IArgoAdAction } from "components/ui/ad-system/ArgoAdInterfaces";
import { Commands } from "components/ui/Commands";
import { toast } from "components/utils/GUI";
import { PurchaseResult } from "components/utils/PurchaseParams";
import { config } from "utils/Config";

// tslint:disable-next-line:no-var-requires
let thankYouContentUrl = require("file-loader?esModule=false!extract-loader!html-loader!components/ui/modal-dialogs/insta-pass-thank-you.html");

/** ArgoAdProvider
 * Displays an invisible bootstrap modal with the ad contents centered in the screen. A close button is in the top right.
 * In config.adPlacements, set contentUrl to the url for the html to insert into the dialog body
 * In config.adPlacements, a button action handler function can be provided
 * The ad button calls Commands.onModalButton with a key, which is looked up in buttonActions to execute any configured action
 * Action handlers are responsible for calling onDoneCallback with an appropriate status
 */
export class ArgoAdProvider implements IAdProvider {
  type = "argo";

  constructor() {
  }

  /** prelod an argo ad */
  preload(key: string, adConfig: IAdPlacement) {
    // We could preload the html, but preloading any images referenced by the html would involve finding urls in the html after it loaded

    // Notify the ad is "preloaded", after a short delay
    setTimeout(() => game.adSystem.onPreloadStatusChanged(key, AdStatus.AD_STATUS_READY), 0);
  }

  isPreloaded(key: string, adConfig: IAdPlacement): boolean {
    // We aren't preloading, so just return true
    return true;
  }

  /** show a pop up ad for the given ad placement */
  show(params: IAdParams, adConfig: IAdPlacement) {
    // Get the content URL
    let ad = this.getRandomAd(params, adConfig);
    if(ad === null)
      return this.reportStatus(params, AdStatus.AD_STATUS_NO_FILL);

    // Load the ad HTML
    this._showAdModal(params, ad);
  }

  /** show a specific named pop up ad */
  showSpecificAd(params: IAdParams, specificAdName: string) {
    // Make sure it exists
    let argoAd = config.argoAds[specificAdName];
    if(argoAd === undefined)
      return this.reportStatus(params, AdStatus.AD_STATUS_NO_FILL);

    // Load the ad HTML
    this._showAdModal(params, argoAd);
  }

  /** Internal helper to show the ad in pop up modal */
  _showAdModal(params: IAdParams, ad: IArgoAd) {
    let bodyUrl = ad.contentUrl;

    // XXX - Temporary hard-coded fake url to trigger the InstaPassPopUp
    if(bodyUrl === "popUp://instaPass") {
      let popUp = InstaPassPopUp.show();
      popUp.onDisposeObservable.add(() => {
        if(popUp.disposeReason === "close") {
          // Return a normal finished status
          this.reportStatus(params, AdStatus.AD_STATUS_FINISHED);
        } else if(ad.buttonActions !== undefined && ad.buttonActions !== null) {
          let action = ad.buttonActions[popUp.disposeReason];
          this._performAction(params, action);
        } else {
          // Treat anything else as an error
          this.reportStatus(params, AdStatus.AD_STATUS_ERROR);
        }
      });
      return;
    }

    // Create the dialog
    game.modalDialogSystem.showFramelessHtmlModal({bodyUrl}).then((response: any) => {
      if(response.button === "close") {
        // Return a normal finished status
        this.reportStatus(params, AdStatus.AD_STATUS_FINISHED);
      } else if(ad.buttonActions !== undefined && ad.buttonActions !== null) {
        let action = ad.buttonActions[response.button];
        this._performAction(params, action);
      } else {
        // Treat anything else as an error
        this.reportStatus(params, AdStatus.AD_STATUS_ERROR);
      }
    });
  }

  /** Internal helper to perform a configured click action for an ad */
  _performAction(params: IAdParams, action: IArgoAdAction) {
    if(action.action === ArgoAdActionName.PURCHASE_OFFER)
      this._performPurchase(params, action);
    else
      this.reportStatus(params, AdStatus.AD_STATUS_ERROR);
  }

  /** Internal helper to perform a purchase action for an ad */
  _performPurchase(params: IAdParams, action: IArgoAdAction) {
    if(action.offerName === undefined || action.offerName === null)
    {
      this.reportStatus(params, AdStatus.AD_STATUS_ERROR);
      return;
    }

    // Make the purchase and report the result back to the ad caller as an ad action result
    Commands.onPurchaseOffer(action.offerName, (result) => {
      if(result === PurchaseResult.PURCHASE_RESULT_PURCHASED) {
        let bodyUrl = thankYouContentUrl;
        game.modalDialogSystem.showFramelessHtmlModal({bodyUrl});
      }

      if(params && params.onDoneCallback) {
        // Report the purchase result as an ad action result
        if(result === PurchaseResult.PURCHASE_RESULT_PURCHASED) {
          params.onDoneCallback(AdStatus.AD_STATUS_ACTION_SUCCEEDED);
        } else if(result === PurchaseResult.PURCHASE_RESULT_CANCELLED) {
          params.onDoneCallback(AdStatus.AD_STATUS_ACTION_CANCELED);
        } else {
          toast("AdPurchaseFailed", "Purchase Failed");
          params.onDoneCallback(AdStatus.AD_STATUS_ACTION_ERROR);
        }
      }
    });
  }

  getRandomAd(params: IAdParams, adConfig: IAdPlacement) {
    let paymentsSupported = game.rootState.paymentsSupported;

    // Build a list of valid ads
    let validAds: IArgoAd[] = [];
    for(let key of Object.keys(config.argoAds)) {
      let configAd = config.argoAds[key];
      if(configAd.paymentsRequired && !paymentsSupported)
        continue;
      validAds.push(configAd);
    }

    // Is anything valid?
    if(!validAds.length)
      return null;

    // Randomly select an ad
    let ad = validAds[Math.floor(Math.random() * validAds.length)];

    // Return the ad
    return ad;
  }

  /** report a status back to the caller */
  reportStatus(params: IAdParams, status: AdStatus) {
    if(params.onDoneCallback)
      params.onDoneCallback(status);
  }
}
