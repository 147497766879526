import * as Sentry from "@sentry/minimal";
import * as jscookie from "js-cookie";
import { v4 as uuid } from "uuid";

import { ActionEvent } from "@babylonjs/core/Actions/actionEvent";
import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { Animatable } from "@babylonjs/core/Animations/animatable";
import { FreeCamera } from "@babylonjs/core/Cameras/freeCamera";
import { TargetCamera } from "@babylonjs/core/Cameras/targetCamera";
import { BoundingBox } from "@babylonjs/core/Culling/boundingBox";
import { DebugLayer } from "@babylonjs/core/Debug/debugLayer";
import { Engine } from "@babylonjs/core/Engines/engine";
import { NullEngine } from "@babylonjs/core/Engines/nullEngine";
import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents";
import { PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import { Layer } from "@babylonjs/core/Layers/layer";
import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { ShadowGenerator } from "@babylonjs/core/Lights/Shadows/shadowGenerator";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Axis, Color3, Color4, Matrix, Quaternion, Vector2, Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import { AbstractAssetTask } from "@babylonjs/core/Misc/assetsManager";
import { AssetsManager } from "@babylonjs/core/Misc/assetsManager";
import { AssetTaskState } from "@babylonjs/core/Misc/assetsManager";
import { EventState } from "@babylonjs/core/Misc/observable";
import { Observable } from "@babylonjs/core/Misc/observable";
import { SceneSerializer } from "@babylonjs/core/Misc/sceneSerializer";
import { Tools } from "@babylonjs/core/Misc/tools";
import { Node } from "@babylonjs/core/node";
import { Scene } from "@babylonjs/core/scene";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Line } from "@babylonjs/gui/2D/controls/line";
import { MultiLine } from "@babylonjs/gui/2D/controls/multiLine";

import { ShadowOnlyMaterial } from "@babylonjs/materials/shadowOnly/shadowOnlyMaterial";

// Imports needed for their side effects
import "@babylonjs/core/Audio/audioEngine";
import "@babylonjs/core/Audio/audioSceneComponent";
import "@babylonjs/core/Culling/ray";
import "@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent";
import "@babylonjs/core/Loading/loadingScreen";
import "@babylonjs/core/Loading/Plugins/babylonFileLoader";
import "@babylonjs/core/Misc/screenshotTools";
import "@babylonjs/core/Rendering/edgesRenderer";

// Babylon JS DebugLayer Inspector won't work without this, but we don't want it distributed either
//import "@babylonjs/inspector" // No semicolon on purpose - don't check in uncommented  Our index.css messes up the inspectors layout, so search for and remove index.css for a quick fix

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
document.body.style.background = "black"; // override bootstrap white to black to handle letterboxing on iPhone X (Messenger does this already, so this is mainly for local testing).
let bootstrapHideElement = document.querySelector("#bootstrap-hide") as HTMLElement;
if(bootstrapHideElement !== null)
  bootstrapHideElement.style.display = "block";

document.body.style.display = "block"; // Show web platform page after bootstrap css initialized
import "jquery-serializejson";
import { getSnapshot } from "mobx-state-tree";
import "pepjs"; // Fixes touch pointer events on mobile safari

import { disposeGuiControl, toast } from "components/utils/GUI";
import { config } from "utils/Config";
import { ERROR, FATAL, logger } from "utils/logger";

import { GAME_STATE_BID, GAME_STATE_CREATE, GAME_STATE_DEAL, GAME_STATE_GAME_OVER, GAME_STATE_PASS, GAME_STATE_PLAY, GAME_STATE_RESET, GAME_STATE_ROUND_OVER, GAME_STATE_WAITING_FOR_PLAYERS, IGameStart } from "states/game/GameState";
import { IPilesGameState } from "states/game/PilesGameState";
import { getRootState, IRootState, ROOT_STATE_GAME, ROOT_STATE_HOME_SCREEN, ROOT_STATE_LOADED } from "states/RootState";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";

import { BotFactory } from "ai/BotFactory";

import { AnimationSystem } from "components/game/AnimationSystem";
import { ArgoParticleSystem } from "components/game/ArgoParticleSystem";
import { ArgoSystem } from "components/game/ArgoSystem";
import { Card } from "components/game/Card";
import { LoadingScreen } from "components/game/LoadingScreen";
import { OfflineSystem } from "components/game/OfflineSystem";
import { Pile } from "components/game/Pile";
import { RoundSystem } from "components/game/RoundSystem";
import { SoundSystem } from "components/game/SoundSystem";
import { AdSystem } from "components/ui/ad-system/AdSystem";
import { ConnectionStatusSystem } from "components/ui/ConnectionStatusSystem";
import { ModalDialogSystem } from "components/ui/ModalDialogSystem";
import { PauseSystem } from "components/ui/PauseSystem";
import { ScreenSystem } from "components/ui/ScreenSystem";
import { ShareSystem } from "components/ui/ShareSystem";
import { AnalyticsSystem } from "components/utils/AnalyticsSystem";
import { dumpGuiControlTree, findGuiControl, promptReload } from "components/utils/GUI";
import { LoadTestSystem } from "components/utils/LoadTestSystem";

// resources
// tslint:disable-next-line:no-var-requires
import backgroundTexturePath from "components/game/background/background.jpg";
import { Commands } from "components/ui/Commands";
import { PLAYER_STATE_LEFT, PLAYER_STATE_PLAYER, PLAYER_STATE_SUB } from "states/game/PlayerState";

import { CLIENT_VERSION_ERROR, STATE_NOT_FOUND_ERROR, STATE_RESET_ERROR } from "states/state-sync/errors";

import { StateSyncGameMiddleware } from "states/state-sync/StateSyncGameMiddleware";
import { StateSyncUserMiddleware } from "states/state-sync/StateSyncUserMiddleware";

import { startSimpleTimerProcessor } from "states/game/TimersState";
import { getStateSyncClient } from "states/state-sync/StateSyncClient";

const ASSETS_MANAGER_MAX_RETRIES = 3; // How many times to retry loading assetsManager assets if there are failed tasks
const ASSETS_MANAGER_RETRY_DELAY_MS = 250; // How many milliseconds to wait before retrying

const HIGHLIGHT_PASSED_CARD_COLOR = Color4.FromHexString("#00ff547F"); // minty green color to highlight cards that were passed to local seat.

//const socket = new WebSocket("ws://localhost:4001")

// IStartup tells the game what to do when it starts, passed from facebook invites and shares to game.
export interface IStartup {
  // srcType and srcName are used for reporting to analytics when a user clicks on an invite or share
  srcType?: string; // share, invite
  srcName?: string; // name of share image, or name of invite button clicked on, ie menu, leaderboard, invite_fx_button (fancy in game invite button)

  startupAction: string; // name of action to do, ie join-context-game(version 1.0.15 and older), or start-game(version 1.0.16 and newer)
  gameStart?: IGameStart; // options and info needed to join or start a game

  // version 1.0.15 and older properties, could be removed eventually
  options?: any;
  gameContextId?: any;
  seatId?: any;
}

export let game: Game = null;

export class Game {
  private _canvas: HTMLCanvasElement;
  private _engine: Engine;
  private _camera: TargetCamera;
  private _light: DirectionalLight;
  aboveGUICamera: TargetCamera;
  projectedOrigin: Vector3;
  projectedGuiOrigin: Vector3;
  systems = new Map<string, ArgoSystem>();
  background: Layer;
  scene: Scene;
  gameRootTransformNode: TransformNode;
  guiRootTransformNode: TransformNode;
  guiRootTransformNodeBoundingBox: BoundingBox;
  actionManager: ActionManager;
  assetsManager: AssetsManager;
  assetsManagerRetryCount = 0;
  guiTexture: AdvancedDynamicTexture;
  ground: Mesh;
  dragPile: AbstractMesh; // pile pieces are moved to, to drag around screen
  sourcePile: AbstractMesh; // the pile dragged pieces came from
  shadowGenerator: ShadowGenerator;
  requestedNextGame = false; // Set true if the user clicked Play Again and we're waiting for GameState.nextGameId to be created
  rootState: IRootState;
  localSeat: string;  // the seat id string of the local player, null if they're a watcher
  preventPlayUntilAnimationsAreComplete = false;
  preventPlayUntilTurnChanges = false;
  pausedPatchQueueUntilAnimationsAreComplete = false; // Track whether pausePatchQueue has been paused during animations, so it can be unpaused when animations complete
  resizeTimerId = -1;
  callEngineResize = false; // true if we should call engine.resize on the next render loop
  callEngineResizeRequested = false; // true if we've already requested callEngineResize be set after a delay
  hasResized = false; // true if the engine has been resized
  paused = false;
  showingGame = true; // true if game elements should be shown on screen
  private _needRender = false; // true if we should render even if we don't detect any changes
  private _needRenderTime = 0; // time _needRender was last set, rendering will continue for a while after it is set
  assetsLoaded = false;
  loadingScreenFinished = false; // False until the loading screen has finished

  // card selection
  maxCardSelectionCount = 1; // max number of cards a user can select. Gets set to 3 during Hearts passing

  // Debug
  debugCameraControl = false; // Allow controlling the camera with mouse and keyboard
  debugAutoPlayLocalSeat = false; // true auto playing the local player's turn for testing

  // Startup
  gameStart: IGameStart;  // the parameters used to start the current game
  startupData: IStartup; // startup data from facebook
  started = false; // set true when everything has loaded and checkReady completed.
  gameCnt = 0; // count of games created/joined

  // State
  gameState: IPilesGameState; // overriden by subclass to game specific GameState, ie IKlondikeGameState
  gameStateSyncMiddleware: StateSyncGameMiddleware;
  userStateSyncMiddleware: StateSyncUserMiddleware;
  leaveGameTimeOutId: any; // id of timer set when user clicks Leave Game to force leave if server doesn't respond
  botFactory: BotFactory = undefined;
  finishedTurn: boolean = false; // indicate if the user already completed this turn and is waiting for a response from the server

  // Events
  onLoadingScreenFinishedObservable = new Observable<void>(); // sent approximately after the loading screen stops obscuring the screen
  onShowGameObservable = new Observable<boolean>();
  onResizedObservable = new Observable<void>(); // sent after system.afterResized() is called
  onFakeFullscreenObservable = new Observable<boolean>(); // sent when entering or exiting the fake fullscreen mode

  // Systems
  cardSystem: Card;
  pileSystem: Pile;
  animationSystem: AnimationSystem;
  argoParticleSystem: ArgoParticleSystem;
  modalDialogSystem: ModalDialogSystem;
  screenSystem: ScreenSystem;
  shareSystem: ShareSystem;
  soundSystem: SoundSystem;
  offlineSystem: OfflineSystem;
  adSystem: AdSystem;

  constructor(canvasElement: string) {
    // If any errors are reported to sentry then prompt user to reload/refresh browser window
    logger.beforeSendObservable.add((event: any) => {

      // add extra data to send to sentry, we have a max payload of 200kb
      if(!event.extra)
        event.extra = {};

      // include rootState which will include UserState and GameState about 8kb
      let snapshot = getSnapshot(this.rootState);
      // remove list of other users and leaderboards to save space
      const {
        users,
        leaderboards,
        // tslint:disable-next-line:trailing-comma
        ...partialSnapshot
      } = snapshot;
      event.extra.rootState = partialSnapshot;

      // include the logger game log
      event.extra.gameLog = logger.getGameLog();

      // Try to capture a screenshot with the error, about 30kb, but can vary a lot.
      try {
        Tools.CreateScreenshot(this._engine, this._camera, {height: 120}, (data) => {
          // In testing, the screenshot is about 30k, depending on aspect ratio and JPEG
          // There's a limit of "about 16k characters" for an entry
          // So we'll try breaking up into exactly 16k characters
          const CHUNK_SIZE = 16 * 1024;
          let keyId = 0;
          for(let startIndex = 0; startIndex < data.length; startIndex += CHUNK_SIZE) {
            let chunk = data.slice(startIndex, startIndex + CHUNK_SIZE);
            let key = `screenshot_${keyId}`;
            event.extra[key] = chunk;
            keyId += 1;
          }
        }, "image/jpeg");
      } catch (err) {
        event.extra.screenshot_error = err.toString();
      }

      if(event.level === ERROR || event.level === FATAL)
        promptReload();
    });

    // See if Babylon will even work
    if(!config.loadTestClientMode) {
      if(!Engine.isSupported()) {
        alert("Sorry, this web browser is not able to run the game.");
        return;
      }
    }

    // forward errors caught by babylon to sentry. These errors don't contain the call stack though and might not be very useful.
    // This ended up being a bad idea because babylon sometimes logs errors that it recovers from, like shadows falling back to a simpler mode after errors
    /*Tools.OnNewCacheEntry = (entry: string) => {
      // <div style='color:red'>[14:29:30]: Error: 0:43(114): error: No precision specified in this scope for type `sampler2DShadow'</div><br>
      // chop off prefix: <div style='color:red'>[14:29:30]:     and suffix: </div><br>
      const i = entry.indexOf("]: ");
      let msg = entry.slice(i + 3);
      msg = msg.replace("</div><br>", "");
      logger.error("BABYLON OnNewCacheEntry: " + msg);
    };*/

    this.localSeat = null;
    game = this; // set global instance of game
    this.rootState = getRootState();
    this.gameState = this.rootState.game;

    this.initStateSync();

    let engineConfig = {
      // deterministicLockstep helps clean up the initial dealing animation in Klondike when the page loads
      // Unfortunately, it also makes the particles go into slow motion if the framerate is low
      deterministicLockstep: config.deterministicLockstep,
      lockstepMaxSteps: config.lockstepMaxSteps,
      preserveDrawingBuffer: true,
      useHighPrecisionFloats: true,
    };

    // Create canvas and engine.
    this._canvas = document.getElementById(canvasElement) as HTMLCanvasElement;
    if(config.loadTestClientMode) {
      this._engine = new NullEngine();
    } else {
      this._engine = new Engine(this._canvas, true, engineConfig, true);
      let glInfo = this._engine.getGlInfo();
      logger.info("glInfo", glInfo);

      // Some older devices (Moto E5 with Adreno 308, for example) silently fail with useHighPrecisionFloats=true (Babylon's default) and render black cards instead
      // We'll use webGLVersion 1 as a sort of test for older devices and fall back to useHighPrecisionFloats=false
      // An exception is iOS, which does not provide WebGL 2 at all, and which has shadow glitches if useHighPrecisionFloats is false
      // If there are other devices that are like iOS, providing only WebGL 1, but needing useHighPrecisionFloats we can switch to a black list
      // The Moto E5 has the renderer string "Adreno (TM) 308"
      if(this._engine.webGLVersion === 1) {
        if(glInfo.vendor !== "Apple Inc.") {
          logger.info("Recreating engine with highp disabled");
          this._engine.dispose();
          engineConfig.useHighPrecisionFloats = false;
          this._engine = new Engine(this._canvas, true, engineConfig, true);
        }
      }
    }

    // Stop babylon trying to load a manifest file for loaded meshes
    this._engine.enableOfflineSupport = false;

    // XXX - Currently, adding a loadingScreen is DISABLING the loading screen
    //       When loadingScreen actualy does something, we'll need to change how this flag is handled
    if(!config.enableLoadingScreen)
      this._engine.loadingScreen  = new LoadingScreen("Loading");

    this._engine.onResizeObservable.add((eventData: Engine, eventState: EventState) => {this.onEngineResize(); });
    this._engine.onBeginFrameObservable.add((eventData: Engine, eventState: EventState) => {this.onEngineBeginFrame(); });

    // Init the canvas/engine size
    this.engineResize();

    // Create a basic BJS Scene object.
    this.scene = new Scene(this._engine);

    // Create a root transform node for 3d game elements
    this.gameRootTransformNode = new TransformNode("gameRootTransformNode", this.scene);

    // Create a root transform node for 3d gui elements (it will be positioned with the camera setup in fitInWindow)
    this.guiRootTransformNode = new TransformNode("guiRootTransformNode", this.scene);

    // Create GUI Texture
    this.guiTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");

    this.initSystems();
    for(let system of this.systems.values())
      system.init();

    // Restore volume
    /*
    let volume = Storage.getNumber("volume", 0.7);
    if(volume !== undefined){
      // XXX - undocumented
      // setGlobalVolume does nothing if audioContext is not initialized
      // accessing the audioContext property initializes the audioContext
      let dummy = Engine.audioEngine.audioContext;
      Engine.audioEngine.masterGain.gain.value = volume;
    }
    */

    // init scene
    this.initScene();
  }

  /** setups up StateSyncMiddleware on UserState and GameState. If we're not using StateSync then it uses a cookie to set user id */
  initStateSync() {
    if(config.haveStateSync) {
      // create StateSync middleware that will handle interfacing states with StateSync services
      this.gameStateSyncMiddleware = new StateSyncGameMiddleware(this.rootState.game);
      this.userStateSyncMiddleware = new StateSyncUserMiddleware(this.rootState.user);

      this.gameStateSyncMiddleware.errorObservable.add((eventData: any, eventState: EventState) => { this.onStateSyncError(eventData); });
      this.userStateSyncMiddleware.errorObservable.add((eventData: any, eventState: EventState) => { this.onStateSyncError(eventData); });
    }
    else {
      // get local player id from cookie, if not there then generate one
      if(config.haveCookies && process.env.PLATFORM !== "facebook_ig") {
        let userId = jscookie.get("user-id");
        if(!userId) {
          userId = uuid();
          jscookie.set("userId-id", userId);
        }
        this.rootState.user.setId(userId);
        logger.info("cookie userId", { userId});
      }

      startSimpleTimerProcessor(this.rootState.game.timers);
    }
  }

  initSystems() {
    this.cardSystem = new Card(this);
    this.pileSystem = new Pile(this);
    this.animationSystem = new AnimationSystem(this);
    this.argoParticleSystem = new ArgoParticleSystem(this);
    this.modalDialogSystem = new ModalDialogSystem(this);
    this.screenSystem = new ScreenSystem(this);
    this.soundSystem = new SoundSystem(this);
    this.adSystem = new AdSystem(this);

    this.systems.set("AnalyticsSystem", new AnalyticsSystem(this));
    this.systems.set("Card", this.cardSystem);
    this.systems.set("Pile", this.pileSystem);
    this.systems.set("AnimationSystem", this.animationSystem);
    this.systems.set("ArgoParticleSystem", this.argoParticleSystem);
    this.systems.set("ModalDialogSystem", this.modalDialogSystem);
    this.systems.set("ScreenSystem", this.screenSystem);
    this.systems.set("PauseSystem", new PauseSystem(this));
    this.systems.set("RoundSystem", new RoundSystem(this));
    this.systems.set("SoundSystem", this.soundSystem);
    this.systems.set("AdSystem", this.adSystem);

    if(config.haveStateSync)
      this.systems.set("ConnectionStatusSystem", new ConnectionStatusSystem(this));

    if(config.offlineSingleplayer) {
      this.offlineSystem = new OfflineSystem(this);
      this.systems.set("OfflineSystem", this.offlineSystem);
    }

    // create any additional systems listed in config
    for(let system of config.additionalSystems) {
      this.systems.set(system.name, new system.class(this));
    }

    if(config.loadTestClientMode)
      this.systems.set("LoadTestSystem", new LoadTestSystem(this));
  }

  /** used by subclasses to set the global instance of game */
  setGameInstance(gameInstance: Game) {
    game = gameInstance;
  }

  initScene(): void {
    // the following matches paths like: /piles/tableau6/pieces/6, /piles/tableau6/pieces/6/facing
    // () in regexp defines a capture group to extract a param out of path, for example /piles/tableau6/pieces/6/facing params = ["/piles/tableau6/pieces/6/facing", "tableau6", "6"]
    // RootState routes
    this.rootState.router.addRoute("^\/status$", (patch: any, reversePatch: any, params: any) => this.onRootStatusChanged(patch, reversePatch, params));

    // GameState routes
    this.rootState.router.addRoute("^\/game\/piles\/(.*)\/pieces\/(\\d*)(\/[a-z]*)?$", (patch: any, reversePatch: any, params: any) => this.onPieceChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/players\/(\\d*)/status$", (patch: any, reversePatch: any, params: any) => this.onPlayersStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/players\/(\\d*)/socketId$", (patch: any, reversePatch: any, params: any) => this.onPlayersSocketIdChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.onGameStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/seatsTurn$", (patch: any, reversePatch: any, params: any) => this.onSeatsTurnChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/seats\/(\\d*)(\/[a-z]*)?\/player$", (patch: any, reversePatch: any, params: any) => this.onSeatPlayerChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/applySnapshotStage$", (patch: any, reversePatch: any, params: any) => this.onApplySnapshotStage(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/nextGameId$", (patch: any, reversePatch: any, params: any) => this.onNextGameIdChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/id$", (patch: any, reversePatch: any, params: any) => this.onGameIdChanged(patch, reversePatch, params));

    // UserState routes
    this.rootState.router.addRoute("^\/user\/applySnapshotStage$", (patch: any, reversePatch: any, params: any) => this.onUserApplySnapshotStage(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/user\/id$", (patch: any, reversePatch: any, params: any) => this.onUserIdChange(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/user\/name$", (patch: any, reversePatch: any, params: any) => this.onUserNameChange(patch, reversePatch, params));

    // Create a scene Action Manager
    this.scene.actionManager = new ActionManager(this.scene);
    this.scene.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnKeyDownTrigger, (evt) => this.onKeyDown(evt)));

    // Add a background Layer, texture will be set latter when asset is loaded
    this.background = new Layer("back", null, this.scene);
    this.background.isBackground = true;
    //background.texture.level = 0;
    //background.texture.wAng = .2;

    // Create a FreeCamera, and set its position to (x:0, y:5, z:-10).
    //this._camera = new TargetCamera('camera1', new Vector3(0, 10, -5), this.scene); // fixed camera
    this._camera = new FreeCamera("camera1", new Vector3(0, 12, 0), this.scene);  // move camera with mouse and keyboard arrows
    this.scene.activeCameras.push(this._camera);
    this.scene.cameraToUseForPointers = this._camera;

    // Target the camera to scene origin.
    this._camera.setTarget(new Vector3(0, 0, 2));

    // Watch for changes
    this._camera.onViewMatrixChangedObservable.add(() => this.needRender());

    // Create a camera above the GUI
    // Babylon's scene clears the depth buffer between cameras.  There's no option to prevent the clear.
    this.aboveGUICamera = new TargetCamera("AboveGUICamera", new Vector3(0, 12, 0), this.scene);
    this.aboveGUICamera.layerMask = 0x10000000;
    this.scene.activeCameras.push(this.aboveGUICamera);

    /*
    let c = new Color4(0, 0, 1, 1);
    let box = MeshBuilder.CreateBox("bbox", {size: 2, faceColors: [c, c, c, c, c, c]}, this.scene);
    box.layerMask = this.aboveGUICamera.layerMask;
    box.isPickable = false;
    */

    // Default Pipeline, for effects
    /*
    var pipeline = new DefaultRenderingPipeline("default", true, this.scene, [this._camera]);
    pipeline.fxaaEnabled = true;
    pipeline.samples = 4;
    pipeline.chromaticAberrationEnabled = true;
    */

    // Create a hemispheric/ambient light
    let lightAmbient = new HemisphericLight("light1", Vector3.FromArray(config.hemisphericLightDirection), this.scene);
    lightAmbient.diffuse = new Color3(0.8, 0.8, 1);
    lightAmbient.specular = new Color3(0.25, 0.25, 0.25);

    // setup directional light that casts shadows
    this._light = new DirectionalLight("dir01", Vector3.FromArray(config.directionalLightDirection), this.scene);
    this._light.position = Vector3.FromArray(config.directionalLightPosition);
    //this._light.intensity = 0.25;
    this._light.specular = new Color3(0.25, 0.25, 0.25);

    // Create a built-in "ground" shape.
    this.ground = MeshBuilder.CreateGround("ground", { width: 256, height: 256, subdivisions: 16 }, this.scene);
    this.ground.parent = this.gameRootTransformNode;
    this.ground.receiveShadows = true;
    let groundMat = new ShadowOnlyMaterial("ground_shadowOnly", this.scene);
    groundMat.activeLight = this._light; // ShadowOnlyMaterial only works with the 1st light, we can order things carefully or just specify the light we want here
    this.ground.material = groundMat;
    groundMat.alpha = 0.25; // For some reason the ground shadows come out pure black this way. Alpha 0.25 is a workaround

    this.shadowGenerator = new ShadowGenerator(1024, this._light);
    // see https://doc.babylonjs.com/babylon101/shadows   for shadow options
    //this.shadowGenerator.usePoissonSampling = true;
    //this.shadowGenerator.useExponentialShadowMap = true;
    /*this.shadowGenerator.useBlurExponentialShadowMap = true;
    this.shadowGenerator.blurScale =2; // Define the scale used to downscale the shadow map before applying the blur postprocess. By default, the value is 2
    this.shadowGenerator.blurBoxOffset = 1; // Define the offset of the box's edge used to apply the blur. By default, the value is 1 (Meaning the box will go from -1 to 1 in both directions resulting in 9 values read by the blur postprocess).
    this.shadowGenerator.useKernelBlur = true; // You can decide to use kernel blur instead of box blur. While a bit more expensive, the quality of the shadow is far better with kernel blur. You can control the kernel size with shadowGenerator.blurKernel, which default value is 1.
    */
    this.shadowGenerator.usePercentageCloserFiltering = true; // Soft shadows for WebGL 2, fallback to Poisson for WebGL 1
    this.shadowGenerator.bias = 0; // Fix shadows disappear near ground
    this.shadowGenerator.normalBias = 0.02; // Fix bias fix causing lines in the cards (0.01 worked on desktop, but 0.02 was needed on iPhone)

    let fallbackFromPercentageCloserFiltering = () => {
      this.shadowGenerator.usePercentageCloserFiltering = false;
      this.shadowGenerator.bias = 0.000001; // We also need a larger bias in this case
    };

    if(this._engine.webGLVersion === 1) {
      // PercentageCloserFiltering requires WebGL2, if we're running with WebGL1, fallback now.
      fallbackFromPercentageCloserFiltering();
    } else {
      groundMat.onError = (effect, errors) => {
        // percentageCloserFiltering fails to compile on some devices (our Chromebook is one)
        // http://www.html5gamedevs.com/topic/38818-usepercentagecloserfiltering-shadow-doesnt-work-on-samsung-s8/
        groundMat.onError = null;
        fallbackFromPercentageCloserFiltering();
        logger.info("Ground material error: disabling percentage closer filtering", { effectName: effect.name, errors});
      };
    }

    // Create drag pile used for animations and user dragging pieces
    this.dragPile = this.pileSystem.createEmptyMesh(this.gameRootTransformNode, "down", undefined, "drag");
    this.dragPile.isPickable = false; // fixes dragPile being picked instead of pile under dragPile
    this.dragPile.setEnabled(false); // we use the enabled flag to indicate if the dragPile is currently in use, either being dragged by user, or used to move pieces between piles
    this.dragPile.visibility = 0;

    // Predownload required assets
    this.loadAssets();
  }

  loadAssets() {
    this.assetsManager = new AssetsManager(this.scene);

    this.assetsManager.onTaskErrorObservable.add( (task) => {
      logger.info("AssetManager Task Error", { errorObject: task.errorObject });
    });

    this.assetsManager.onProgress = (remainingCount, totalCount, lastFinishedTask) => {
      try {
        this.onAssetsProgress(remainingCount, totalCount, lastFinishedTask);
      }
      catch(err) {
        logger.error("assetsManager.onProgress handler exception ", { err });
      }
    };

    // Continue when done
    this.assetsManager.onFinish = (tasks: AbstractAssetTask[]) => {
      try {
        this.onAssetsManagerFinished(tasks);
      }
      catch(err) {
        logger.error("assetsManager.onFinish handler exception ", { err });
      }
    };

    // Queue assets
    this.queueAssets();
    for(let system of this.systems.values())
      system.queueAssets();

    // Load Queued Assets
    this.assetsManager.load();
  }

  onAssetsProgress(remainingCount: number, totalCount: number, lastFinishedTask: any) {
    let progress = Math.ceil(((totalCount - remainingCount) / totalCount) * 100);
    progress = Math.min(progress, 90); // don't set 100% yet, wait for onAssetsLoaded
    this.rootState.setLoadingProgress(progress);
  }

  onAssetsManagerFinished(tasks: AbstractAssetTask[]): void {
    // Check for errors
    // We'll also reset the tasks here
    let errorCount = 0;
    for(let task of tasks.slice()) {
      if(task.taskState === AssetTaskState.ERROR) {
        errorCount += 1;
        task.reset(); // Will cause the task to retry if we call assetsManager.load again
      }
    }

    if(errorCount) {
      // Retry if we haven't retried to many times yet
      if(this.assetsManagerRetryCount < ASSETS_MANAGER_MAX_RETRIES) {
        this.assetsManagerRetryCount += 1;
        logger.info("AssetManagerRetrying", { errorCount, retryCount: this.assetsManagerRetryCount });
        // Restart assetsManager on a timer because it calls onAssetsManagerFinished before setting _isLoading = false, which causes our load to fail
        // A little delay might be a good idea anyway
        setTimeout(() => this.assetsManager.load(), ASSETS_MANAGER_RETRY_DELAY_MS);
      } else {
        logger.warn("AssetManagerFinishedWithErrors", { errorCount, retryCount: this.assetsManagerRetryCount });
        // We're out of retries, but failed to load all of the needed assets
        // Prompt the user to refresh.
        if(window.confirm("Download error.  Click OK to try reloading the page."))
          logger.safeReloadBrowser();
      }
      return;
    }

    if(this.assetsManagerRetryCount)
      logger.info("AssetManagerSucceededAfterRetrying", { retryCount: this.assetsManagerRetryCount });

    this.onAssetsLoaded();
  }

  onAssetsLoaded(): void {
    // be sure onAssetsLoaded is only ever called once
    if(this.assetsLoaded)
      return;

    this.assetsLoaded = true;

    // We'll create the game after the 1st render
    this.scene.onAfterRenderObservable.addOnce((scene) => this.onAfterFirstRender());

    // Start render loop.
    this.startRenderLoop();

    // The default loading screen (div id = babylonjsLoadingDiv) transitions opacity for 1.5s
    // Without direct support, listening for the div opacity transition to finish seems risky
    // But we can wait 1.5s safely
    // Facebook has it's own loading screen, so we'll wait until showRenderCanvas is called
    if(process.env.PLATFORM !== "facebook_ig")
      setTimeout(() => this.onLoadingScreenFinished(), 1500);
  }

  onLoadingScreenFinished() {
    this.loadingScreenFinished = true;
    this.onLoadingScreenFinishedObservable.notifyObservers();
  }

  onAfterFirstRender() {
    this.createGame();
  }

  createGame() {
    // tell subclass to create materials and piles
    this.createMaterials();
    for(let system of this.systems.values())
    system.createMaterials();

    this.createPiles();
    for(let system of this.systems.values())
      system.createSceneElements();

    // Observe pointer up, down, move events
    this.scene.onPointerObservable.add((evt) => this.onPointerDown(evt), PointerEventTypes.POINTERDOWN);
    this.scene.onPointerObservable.add((evt) => this.onPointerUp(evt), PointerEventTypes.POINTERUP);
    this.scene.onPointerObservable.add((evt) => this.onPointerMove(evt), PointerEventTypes.POINTERMOVE);
    this.scene.onPointerObservable.add((evt) => this.onPointerTap(evt), PointerEventTypes.POINTERTAP);

    // Observe animations done
    this.animationSystem.onAnimationBlockingObservable.add((animatable) => this.onAnimationBlockingChanged(animatable));

    // Ensure the initial position of every piece is applied
    this.applyCurrentLayout();

    this.fitInWindow();

    // createGUI after initial fitInWindow so projections to line GUI up with 3d objects work
    this.createGUI(); // tell subclass to create GUI elements such as deal/undo buttons
    for(let system of this.systems.values())
      system.createGUI();

    this.hideGame(); // be default hide game, so that if we don't get user state right away, they won't be sitting at an empty game
    //this.exportScene();

    // Scene Optimizer
    // XXX - Disabled this for now, it seems maybe overzealous in disabing shadows, maybe we can tweak the parameters
    /*
    let targetFrameRate = 30.0;
    let trackerDuration = 2000;
    let optimizerOptions = new SceneOptimizerOptions(targetFrameRate, trackerDuration);
    // This will disable the shadows on slower devices
    optimizerOptions.optimizations.push(new ShadowsOptimization(0));
    // This renders at up to 1/2 size to make the scene run faster, but blurrier
    // 2 problems: The GUI doesnt adapt, so it grows as the scale increases,
    // and, more seriously, the game breaks if you're dragging a card when it switches
    // There may be workarounds, but we probably don't want things to get blurry
    //optimizerOptions.optimizations.push(new HardwareScalingOptimization(1, 2));
    // TextureOptimization doesn't help, nothing else appears to be relevent
    // We could, however, make a CustomOptimization to switch to the low poly cards
    SceneOptimizer.OptimizeAsync(this.scene, optimizerOptions);
    */

    this.rootState.setLoadingProgress(100);
    this.rootState.setStatus(ROOT_STATE_LOADED);

    this.checkReady();
  }

  /** set startupData, for example from facebook getEntryPointData. It contains info needed to join an invited game. and indicates facebook is ready */
  setStartupData(startupData: IStartup) {
    this.startupData = startupData;
    this.checkReady();
  }

  /** Check if we have everything to start a game, assets loaded, have userState and optionally facebook startupData */
  checkReady() {
    const waitingForStartupData = process.env.PLATFORM === "facebook_ig" && !this.startupData;
    const haveUserState = !config.haveStateSync || (config.haveStateSync && this.rootState.user.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE);

    if(this.started || // If we've already readied and started then return
      !haveUserState ||
      !this.assetsLoaded || // can't do anything until we have assets
      this.rootState.status !== ROOT_STATE_LOADED || // be sure we are in the loaded state first
      waitingForStartupData) // we have to wait for startup data before doing anything because on facebook that indicates startGameAsync finished
        return;

    let lastGameId = this.rootState.user.gameId;

    // if we're in load test client mode, then auto start and auto play a quick play game
    if(config.loadTestClientMode) {
      this.startupData = {
        startupAction: "start-game",
        gameStart: {
          gameContextId: "__LOAD_TEST__." + uuid(),
          options: { name: config.gameOptions.quickPlay.name },
          seatId: "0",
        },
      };
      this.debugAutoPlayLocalSeat = true;
    }

    logger.info("Starting with startupData", { startupData: this.startupData });
    // startupAction === "join-context-game"(version 1.0.15 and older) or start-game (version 1.0.16 or newer) means the user is responding to an invite to join a game in a context
    if(this.startupData && this.startupData.startupAction && this.startupData.startupAction !== "none") {
      // if startupData is set then the user clicked on an invite or share, so take them directly to that game
      if(this.startupData.startupAction === "join-context-game")
        this.newGame({options: this.startupData.options, gameContextId: this.startupData.gameContextId, seatId: this.startupData.seatId, invited: true});
      else if(this.startupData.startupAction === "start-game")
        this.newGame(this.startupData.gameStart);
    }
    else if(config.haveStateSync && lastGameId) {
      // resume last game user was playing.
      this.joinGame(lastGameId);
    }
    else {
      // do default thing, which is either auto start tutorial game, goto home screen or auto start a new game
      if(config.autoRunTutorial && this.rootState.user.help.shouldShow("tutorial")) {
        this.rootState.user.help.incSeenCnt(this.rootState.user.id, "tutorial");
        let gameStartTutorial: IGameStart = {
          options: config.gameOptions.tutorial,
          tutorial: true,
        };
        Commands.onNewGame(gameStartTutorial);
      }
      else if(config.haveHomeScreen)
        Commands.onHomeScreen();
      else
        this.newGame({options: config.gameOptions.default}); // if no home screen then auto start a game (solitaire)
    }

    this.started = true;
  }

  /** shows the renderCanvas div on the web page. On Facebook it starts out hidden. */
  showRenderCanvas() {
    document.getElementById("renderCanvas").style.display = "block"; // show renderCanvas
    this.onWindowResize(); // get rendering to work again after showing renderCanvas

    // We've delayed the Loading Screen Finished event until now
    setTimeout(() => this.onLoadingScreenFinished(), 1500);
  }

  /** override in subclasses  */
  queueAssets(): void {
    // Add a texture task to load the background texture
    let backgroundTextureTask = this.assetsManager.addTextureTask("BackgroundTextureTask", backgroundTexturePath);
    backgroundTextureTask.onSuccess = (task) => {
      // set texture to background that was already created
      this.background.texture = task.texture;
    };
  }

  /** override in subclasses  */
  createMaterials(): void {
  }

  /** override in subclasses  */
  createPiles(): void {
  }

  /** override in subclasses  */
  createGUI(): void {
  }

  /** override in subclasses  */
  resized(): void {
  }

  /** attempt to join a game that has already been created. */
  joinGame(gameId: string) {
    this.requestedNextGame = false;
    this.rootState.setStatus(ROOT_STATE_GAME);
    this.gameStateSyncMiddleware.setEnabled(true);
    if(this.botFactory)
      this.botFactory.setEnabled(false);
    this.gameState.resetState();
    this.gameState.syncState(gameId).then().catch((err) => {
      Commands.onHomeScreen(); // go back to home screen on any errors loading a game
    });
  }

  leaveGame() {
    // only attempt to call leaveGame if the gameState is actually loaded, if the game failed to load
    // they may be sitting at an empty game trying to get out of it. In that case just go back to home screen
    if(game.gameState.id) {
      let player = game.gameState.getPlayer(getRootState().user.id);
      if(player && (player.status === PLAYER_STATE_PLAYER || player.status === PLAYER_STATE_SUB)) {
        // set timer to force going back to home screen if player isn't removed from game in time (likely due to connection loss)
        clearTimeout(this.leaveGameTimeOutId);
        this.leaveGameTimeOutId = setTimeout(() => {
          Commands.onHomeScreen();
        }, 2 * 1000);

        game.gameState.leaveGame(getRootState().user.id);
      }
      else
        Commands.onHomeScreen();
    }
    else
      Commands.onHomeScreen();
  }

  /** Used to start the game, and to restart.
   * @param gameStart
   * @param joinExisiting set true to search for an existing joinable game in gameStart.gameContextId, defaults to false which always creates a new game
   */
  newGame(gameStart: IGameStart = {}): void {
    this.gameStart = gameStart;
    this.gameStart.clientName = config.clientName;
    this.rootState.setStatus(ROOT_STATE_GAME);

    // The user should only be providing the name to options, then we override with the actual options from config
    // the server does the same thing in GameStateService.createGame, this is for standAlone games like handChallenge
    if(gameStart.options && gameStart.options.name) {
        gameStart.options = {
          ...config.gameOptions[gameStart.options.name],  // copy default options from config

          // allow the following options to be overriden, this list should match the list in GameStateService.createGame
          multiplayer: gameStart.options.multiplayer,
      };
    }
    else if(!gameStart.options)
      gameStart.options = config.gameOptions.default;

    // turn off multiplayer if client doesn't support it
    if(!config.haveMultiplayer && gameStart.options.multiplayer)
      gameStart.options.multiplayer = false;

    // If a context wasn't specified then use current context + a uuid to uniquely identify this series of games
    // ie clicking Quick Play should generate a new gameContextId, then each game started with Play Again will have same gameContextId
    if(!gameStart.gameContextId && this.rootState.contextId)
      gameStart.gameContextId = this.rootState.contextId + "." + uuid();

    // if we still don't have a context, then use the user.id as context.
    // we do this so that findOrCreateState below will always have a context to find games. If we didn't then
    // it could find any open game and allow for multiplayer with non-friends
    if(!gameStart.gameContextId)
      gameStart.gameContextId = this.rootState.user.id + "." + uuid();

    this.localSeat = null; // reset localSeat to be a watcher
    this.requestedNextGame = false;

    this.gameState.resetState(); // moves all cards back to stock, resets scores, resets StateSync
    if(config.haveStateSync && !gameStart.options.standAlone) {
      this.gameStateSyncMiddleware.setEnabled(true);
      if(this.botFactory)
        this.botFactory.setEnabled(false);
      logger.info("newGame findOrCreateState", { gameStart });
      // note findOrCreateState does not create a game if gameStart.invited is true
      this.gameState.findOrCreateState(gameStart).catch((err) => {
        // The most likely reason findOrCreateState fails is if the game they were invited to has finished.
        // That might not always be true, but the message below should be good enough.
        if(err.name === STATE_NOT_FOUND_ERROR)
          this.displayErrorMessage("That game is over.");
        else if(err.name !== STATE_RESET_ERROR) { // don't go to homescreen if state was reset, because it was likely another start game request that caused reset, so let that game to loadup
          if(err.name !== "BadConnectionError")
            logger.warn("newGame findOrCreateState. Error calling findOrCreateState returning to home screen", { gameStart, err });
          this.displayErrorMessage("We were unable to start a new game.");
        }

        Commands.onHomeScreen(); // always return to Home screen on errors.
      });
    }
    else { // game is standAlone, meaning it doesn't use or report the game to the server at all.
      if(this.gameStateSyncMiddleware)
        this.gameStateSyncMiddleware.setEnabled(false);
      if(this.botFactory)
        this.botFactory.setEnabled(true);
      logger.info("newGame createState", gameStart);

      // add local player before createState so that if gameStart contains minPiles + pieces that filterPatch will know the local player
      let seatId = (this.gameStart && this.gameStart.seatId) ? this.gameStart.seatId : "0";
      this.gameState.addPlayer(this.rootState.user.id, this.rootState.user.id, this.rootState.user.name, seatId, false, this.rootState.user.imageUrl);
      this.gameState.createState(gameStart).then(() => {
        // some things wait for applySnapshotStage to be done, such as launching heart tokens.
        // But in the case of standalone games, a snapshot is never applied, so the stage isn't set to done.
        // So instead manually set it done here
        this.gameState.setApplySnapshotStage(APPLY_SNAPSHOT_STAGE_DONE);
      })
      .catch((err) => {
        // If there was a problem starting a game go back to home screen
        if(err.name !== STATE_RESET_ERROR) { // don't go to homescreen if state was reset, because it was likely another start game request that caused reset, so let that game to loadup
          logger.warn("newGame createState. Error calling createState returning to home screen", { gameStart, err });
          Commands.onHomeScreen();
        }
      });

      // Auto start stand alone games
      this.gameState.requestStartGame(this.rootState.user.id);
    }

    if(this.gameState.undoManager)
      this.gameState.undoManager.reset(); // reset undoManager so that the user can't undo past the deal, I tried moving this to GameState.createState but then you could still undo deal.
  }

  /**
   * Fullscreen
   * We are not using Babylon's fullscreen, because that sets the canvas element fullscreen
   * which means our html dialogs are not visible
   * instead, we have a div id "game" we set fullscreen, and the dialogs will be placed there as well
   */
  isFullscreenSupported() {
    let d = document as any;
    if (
      document.fullscreenEnabled || // This alone misses iPad
      d.webkitFullscreenEnabled ||
      d.mozFullScreenEnabled ||
      d.msFullscreenEnabled
    ) {
      return true;
    } else {
      return false;
    }
  }

  _IsElementHidden(id: string) {
    let element = document.getElementById(id);
    if(element === null)
      return false;

    return element.classList.contains("d-none");
  }

  _HideElement(id: string) {
    let element = document.getElementById(id);
    if(element === null)
      return false;

    element.classList.add("d-none");
  }

  _ShowElement(id: string) {
    let element = document.getElementById(id);
    if(element === null)
      return false;

    element.classList.remove("d-none");
  }

  _IsFullscreenFake() {
    return this._IsElementHidden("header_block");
  }

  _RequestFullscreenFake() {
    this._HideElement("header_block");
    this._HideElement("help_block");
    this._HideElement("footer_block");
    document.body.style.overflow = "hidden";
    this.onFakeFullscreenObservable.notifyObservers(true);
  }

  _ExitFullscreenFake() {
    this._ShowElement("header_block");
    this._ShowElement("help_block");
    this._ShowElement("footer_block");
    document.body.style.overflow = "auto";
    this.onFakeFullscreenObservable.notifyObservers(false);
  }

  _RequestFullscreen() {
    if(!this.isFullscreenSupported())
      return this._RequestFullscreenFake();

    let element = document.getElementById("game") as any;
    if(element === null)
      return;
    let requestFunction = element.requestFullscreen || element.msRequestFullscreen || element.webkitRequestFullscreen || element.mozRequestFullScreen;
    if(!requestFunction)
      return;
    requestFunction.call(element);
  }

  _ExitFullscreen() {
    if(!this.isFullscreenSupported())
      return this._ExitFullscreenFake();

    let anyDoc = document as any;

    if(document.exitFullscreen) {
        document.exitFullscreen();
    }
    else if(anyDoc.mozCancelFullScreen) {
        anyDoc.mozCancelFullScreen();
    }
    else if(anyDoc.webkitCancelFullScreen) {
        anyDoc.webkitCancelFullScreen();
    }
    else if(anyDoc.msCancelFullScreen) {
        anyDoc.msCancelFullScreen();
    }
  }

  isFullscreen() {
    if(!this.isFullscreenSupported())
      return this._IsFullscreenFake();

    let anyDoc = document as any;
    if (
      document.fullscreenElement ||
      anyDoc.webkitFullscreenElement ||
      anyDoc.mozFullScreenElement ||
      anyDoc.msFullscreenElement
    ) {
      return true;
    } else {
      return false;
    }
  }

  setFullscreen(fullscreen: boolean) {
    if(fullscreen && !this.isFullscreen()) {
      this._RequestFullscreen();
    }

    if(!fullscreen && this.isFullscreen()) {
      this._ExitFullscreen();
    }
  }

  toggleFullscreen() {
    this.setFullscreen(!this.isFullscreen());
  }

  /**
   * Flag that rendering should take place even if no changes have been detected
   * Rendering will continue for a short while after the flag is set
   */
  needRender() {
    this._needRender = true;
    this._needRenderTime = Date.now();
  }

  /**
   * Determine if we should render this frame or skip it to save power
   */
  shouldRender() {
      // Check if rendering is being forced
      if(this._needRender) {
        // If rendering has been forced, continue rendering for a while before clearing the flag
        // This keeps the debug camera controls and momentum working
        let delta = Date.now() - this._needRenderTime;
        if(delta >= 1000)
          this._needRender = false;
        return true;
      }

      // Render if we're animating
      if(this.scene.animatables.length > 0)
        return true;

      // Render if there are particles
      if(this.scene.particleSystems.length > 0)
        return true;

      // Render if we're draging a piece
      if(this.dragPile.isEnabled())
        return true;

      // Render if the GUI has changed
      // Hack the guiTexture private dirty flag
      // XXX - I couldn't find another way to tell if the guiTexture needs rendering
      //       As of Babylon 4.0, this still isn't publically accesible
      let guiDirty = (this.guiTexture as any)._isDirty;
      if(guiDirty)
        return true;

      // Render if the DebugLayer is visible
      if(this.scene.debugLayer !== undefined && this.scene.debugLayer.isVisible())
        return true;

      // Nothing seems to be going on, so we'll skip rendering to save power
      // This can sort of mess things up internally for Babylon, but it doesn't affect us outside of development mode
      // The framerate will continue to be reported as high because it's the time to render the frame, not the time since the last frame
      // The camera controls don't work without rendering, but calling camera update here helps, along with the delay above
      this._camera.update();
      return false;
  }

  startRenderLoop(): void {
    // Run the render loop.
    // Note that the render loop function won't get called while the WebGL context is lost.
    this._engine.runRenderLoop(() => {
      // this is for local single player games, we call applyQueuedActions externally from this.gameState to unwind the stack
      // we only want to apply patches and actions on states inside runRenderLoop to avoid in the hopes of avoiding events while the WebGL Context is lost
      // We need to be sure to call applyAll on every state in the tree that subscribes to a state on the server
      this.gameState.applyAll();
      this.rootState.user.applyAll();
      this.rootState.users.users.forEach((user) => {
        user.applyAll();
      });

      // Resize the engine, if needed
      this.maybeEngineResize();
      if(this.callEngineResize)
        this.engineResize();

      // Give all registered systems a chance to handle the new frame
      let delta = this._engine.getDeltaTime() * 0.001;
      for(let system of this.systems.values())
        system.newFrame(delta);

      if(this.shouldRender()) {
        try {
          this.scene.render();
        } catch(err) {
          // This is a workaround for Babylon JS 4.0 breaking the usePercentageCloser filtering fallback onError
          // We'll catch, log, and attempt to ignore uncaught exceptions in render()
          logger.info("babylon.js scene.render exception ", { err });
        }
      }

      // This simulates a very low framerate
      /*
      let t = Date.now() // No semicolon so lint will fail
      while(Date.now() - t < 100);
      */
    });

    // Setup event listeners here
    // If we do it sooner, Babylon GUI can crash on an engine.resize()
    // Before things are setup properly

    window.addEventListener("resize", () => {
      game.onWindowResize();
    });

    window.addEventListener("orientationchange", () => {
      game.onWindowResize();
    });

    // Facebook provides an onPause event
    // If we're not on Facebook, set up something similar
    if(process.env.PLATFORM !== "facebook_ig") {
      let checkIfDocumentHidden = () => {
        if(document.hidden)
          this.rootState.pause();
        else
          this.rootState.resume();
      };

      document.addEventListener("visibilitychange", checkIfDocumentHidden);

      // Check now in case we loaded while backgrounded
      checkIfDocumentHidden();
    }
  }

  /* Project a 3d absolutePosition to screen space
  */
  project(absolutePosition: Vector3): Vector3 {
    return Vector3.Project(
      absolutePosition,
      Matrix.Identity(),
      this._camera.getTransformationMatrix(),
      this._camera.viewport.toGlobal(this._engine.getRenderWidth(), this._engine.getRenderHeight()),
      );
  }

  projectToGUIRootTransform(absolutePosition: Vector3): Vector3 {
    return Vector3.Project(
      absolutePosition,
      this.guiRootTransformNode.getWorldMatrix(),
      this._camera.getTransformationMatrix(),
      this._camera.viewport.toGlobal(this._engine.getRenderWidth(), this._engine.getRenderHeight()),
      );
  }

  /** Unproject a 2d point to 3d coordinates
   * If z isn't provided, the z from projecting (0, 0, 0) will be used
   * unproject(Vector3)
   * unproject(Vector2)
   * unproject(x, y)
   */
  unproject(screenPositionIn: Vector2 | Vector3 | number, y?: number): Vector3 {
    let screenPosition: Vector3 = null;
    if(typeof screenPositionIn === "number")
      screenPosition = new Vector3(screenPositionIn, y, this.projectedOrigin.z);
    else if(screenPositionIn instanceof Vector2)
      screenPosition = new Vector3(screenPositionIn.x, screenPositionIn.y, this.projectedOrigin.z);
    else
      screenPosition = screenPositionIn;
    let viewport = this._camera.viewport.toGlobal(this._engine.getRenderWidth(), this._engine.getRenderHeight());

    // BABYLON does not account for the viewport position in unproject
    screenPosition = screenPosition.subtract(new Vector3(viewport.x, viewport.y, 0));
    return Vector3.Unproject(
      screenPosition,
      viewport.width, viewport.height,
      Matrix.Identity(),
      this._camera.getViewMatrix(),
      this._camera.getProjectionMatrix(),
      );
  }

  /** Unproject a 2d point to 3d coordinates relative to the guiRootTransformNode
   */
  unprojectToGUIRootTransform(screenPositionIn: Vector2 | Vector3 | number, y?: number): Vector3 {
    let screenPosition: Vector3 = null;
    if(typeof screenPositionIn === "number")
      screenPosition = new Vector3(screenPositionIn, y, this.projectedGuiOrigin.z);
    else if(screenPositionIn instanceof Vector2)
      screenPosition = new Vector3(screenPositionIn.x, screenPositionIn.y, this.projectedGuiOrigin.z);
    else
      screenPosition = screenPositionIn;
    let viewport = this._camera.viewport.toGlobal(this._engine.getRenderWidth(), this._engine.getRenderHeight());

    // BABYLON does not account for the viewport position in unproject
    screenPosition = screenPosition.subtract(new Vector3(viewport.x, viewport.y, 0));
    return Vector3.Unproject(
      screenPosition,
      viewport.width, viewport.height,
      this.guiRootTransformNode.getWorldMatrix(),
      this._camera.getViewMatrix(),
      this._camera.getProjectionMatrix(),
      );
  }

  /** Return the aspect ratio of the camera viewport
   */
  getAspectRatio(): number {
    return this._engine.getAspectRatio(this._camera);
  }

  /** return the Babylon engine hardware scaling level
   */
  getScale(): number {
    // Engine has getHardwareScalingLevel, but it's 1/scale.  So if the pixel scale is 2x, getHardwareScalingLevel returns 0.5
    return 1.0 / this._engine.getHardwareScalingLevel();
  }

  /** return the Babylon engine FPS
   */
  getFps(): number {
    return this._engine.getFps();
  }

  getGuiPointerCoordinates(): Vector2 {
    // From advancedDynamicTexture.attach, scene.onPrePointerObservable handler
    let viewport = this._camera.viewport;
    let x = (this.scene.pointerX / this._engine.getHardwareScalingLevel() - viewport.x * this._engine.getRenderWidth()) / viewport.width;
    let y = (this.scene.pointerY / this._engine.getHardwareScalingLevel() - viewport.y * this._engine.getRenderHeight()) / viewport.height;

    // From advancedDynamicTexture._doPicking
    // _isFullscreen is private, but we know it is
    let textureSize = this.guiTexture.getSize();
    x = x * (textureSize.width / this._engine.getRenderWidth());
    y = y * (textureSize.height / this._engine.getRenderHeight());

    // Hopefully these coordinates will work for control.contains(x, y)
    return new Vector2(x, y);
  }

  /**
   * Adjust the camera distance so that the entire game is in the window
   * If there is a menuBar, the game is fit into the space excluding the menuBar
   *
   * config.cameraAngle: specifies the angle for the camera, in degrees, from vertical towards the -y axis
   *                     positive values rotate the top of the game away from the screen
   * config.cameraTarget: specifies the target position the camera looks at, for adjusting the final position of the game on screen
   *                      positive z moves the game down on the screen (XXX - I forget why it's z)
   * config.padding(Top|Bottom|Left|Right): specifies padding to add to the computed bbox to correct perspective and other inaccuracies in the scheme
   *
   * XXX - Don't use - this will currently break at least the 3d gui
   * config.cameraPosition: alternatively, if cameraAngle is 0, a cameraPosition can be specified instead
   */
  fitInWindow(): void {
    // The current scheme is
    //   1. Set up the camera a test distance from the configured camera target
    //   2. Ask every pile how much space it needs and build a bounding box
    //   3. Project the bounding box to 2d
    //   4. Search each bbox corner point
    //      Each point has the viewport center subtracted, then is divided by the viewport width or height, to get the percentage of screen space it is taking up
    //      The largest value is taken to be the percentage of the viewport the entire game is taking up
    //   5. A new camera distance is computed by scaling the test distance by the viewport percentage, theoretically raising the viewport percentage to 100%

    // See if this game has a menuBar taking up part of the viewport
    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    // Get the configured camera target
    let cameraTarget = Vector3.FromArray(config.cameraTarget);

    // Set the cameraDirection vector
    let cameraDirection = Axis.Y;
    if(config.cameraAngle) {
      let c = Math.cos(config.cameraAngle * Math.PI / 180.0);
      let s = Math.sin(config.cameraAngle * Math.PI / 180.0);
      cameraDirection = new Vector3(0, c, -s);
    } else {
      let cameraPosition = Vector3.FromArray(config.cameraPosition);
      cameraDirection = cameraPosition.subtract(cameraTarget).normalize();
    }

    // Update camera position and target to a test distance
    // The test distance does affect the outcome
    // 5.0 seems to work pretty well for klondike and spades
    let cameraTestDistance = 5.0;
    this._camera.position = cameraTarget.add(cameraDirection.scale(cameraTestDistance));
    this._camera.setTarget(cameraTarget);

    // Force the camera update to take effect immediately
    this._camera.getProjectionMatrix(true); // Force the projection matrix to update
    this._camera.computeWorldMatrix(); // Force the transformation matrix to update

    // Get the world extents of every pile
    let pileExtents = this.pileSystem.getPileExtents();
    let min = pileExtents[0];
    let max = pileExtents[1];

    // Apply padding
    min.x -= config.paddingLeft;
    max.x += config.paddingRight;
    min.z -= config.paddingBottom;
    max.z += config.paddingTop;

    // Get the main camera's viewport and make sure its not 0
    let viewport = this._camera.viewport.toGlobal(this._engine.getRenderWidth(), this._engine.getRenderHeight());
    if(viewport.width <= 0 || viewport.height <= 0) {
      // We got a crash report related to a bad camera position: "The provided float value is non-finite"
      // The division by viewport size seems like the only way that would happen
      // So if the viewport is bogus, bail here and hope there's another resize event coming with proper dimensions
      //logger.warn("Bad viewport in fitInWindow", {viewportWidth: viewport.width, viewportHeight: viewport.height});
      return;
    }

    // At a cameraTestDistance what percentage of the viewport does the projected bbox take up?
    let vy = menuBarHeight;
    let vh = viewport.height - vy;
    let viewportCenter = new Vector3(viewport.width * 0.5, vy + vh * 0.5, 1.0);
    let viewportScale = new Vector3(2.0 / viewport.width, 2.0 / vh, 0);
    let bb = new BoundingBox(min, max);
    let maxScreen = -Number.MAX_VALUE;
    for(let v of bb.vectors) {
      v = this.project(v).subtract(viewportCenter).multiply(viewportScale);
      maxScreen = Math.max(maxScreen, Math.abs(v.x), Math.abs(v.y));
    }

    // XXX - Fudge factor to avoid overlaping the menubar
    // XXX - Is this needed when there's no menubar?
    maxScreen = maxScreen * 1.05;

    // maxScreen is how much of the screen is being taken up by the view
    // Theoretically, if we scale the distance by this amount, we'll fit the view
    // Perspective messes that up, especially with shallower camera angles
    let cameraDistance = cameraTestDistance * maxScreen;

    if(isNaN(cameraDistance))
      return;

    // Position the camera along our view line
    this._camera.position = cameraTarget.add(cameraDirection.scale(cameraDistance));
    this._camera.setTarget(cameraTarget);

    // Force the camera update to take effect
    // This helps debug visualizations and, more importantly, GUI elements that need to align with 3d elements
    this._camera.getProjectionMatrix(true); // Force the projection matrix to update
    this._camera.computeWorldMatrix(); // Force the transformation matrix to update

    // Set the aboveGUICamera to match
    this.aboveGUICamera.position = cameraTarget.add(cameraDirection.scale(cameraDistance));
    this.aboveGUICamera.setTarget(cameraTarget);
    this.aboveGUICamera.getProjectionMatrix(true); // Force the projection matrix to update
    this.aboveGUICamera.computeWorldMatrix(); // Force the transformation matrix to update

    // Position the guiRootTransformNode in front of the camera
    this.positionGuiRootTransformNode(cameraDistance);

    // Update the origin projection (for unprojecting)
    this.projectedOrigin = this.project(new Vector3(0, 0, 0));
    this.projectedGuiOrigin = this.projectToGUIRootTransform(new Vector3(0, 0, 0));

    ///////////////////////////////////////////////////////////////
    // Debug Visualizers
    ///////////////////////////////////////////////////////////////
    let drawProjection = false; // Draw the projected bbox
    let draw_bbox = false; // Visualize the bounds computed by getPileExtents
    let draw_target_box = false; // Visualize the cameraTarget
    let drawCenterLines = false; // Visualize the screen center lines

    // Draw the projected bbox
    if(drawProjection) {
      disposeGuiControl("DrawProjection");
      let lines = new MultiLine("DrawProjection");
      lines.color = "yellow";
      this.guiTexture.addControl(lines);
      for(let v of bb.vectors) {
        v = this.project(v);
        lines.add({x: v.x, y: v.y});
      }
    }

    // Visualize the bounds computed by getPileExtents
    if(draw_bbox) {
      let box = this.scene.getMeshByName("bbox");
      if(box)
        box.dispose();

      let mid = min.add(max.subtract(min).scale(0.5));

      let c = new Color4(0, 0, 1, 1);
      box = MeshBuilder.CreateBox("bbox", {width: max.x - min.x, height: max.y - min.y, depth: max.z - min.z, faceColors: [c, c, c, c, c, c]}, this.scene);
      box.position = mid;
      box.visibility = 0.5;
      box.isPickable = false;
    }

    // Visualize the cameraTarget
    if(draw_target_box) {
      let box = this.scene.getMeshByName("targetbox");
      if(box)
        box.dispose();

      let c = new Color4(1, 1, 0, 1);
      box = MeshBuilder.CreateBox("targetbox", {width: 0.1, height: 1.0, depth: 0.1, faceColors: [c, c, c, c, c, c]}, this.scene);
      box.position = cameraTarget.add(new Vector3(0, 0.5, 0));
      box.isPickable = false;
    }

    // Visualize the screen center lines
    if(drawCenterLines) {
      disposeGuiControl("DrawCenterLineHorizontal");
      disposeGuiControl("DrawCenterLineVertical");

      let line = new Line("DrawCenterLineHorizontal");
      line.color = "yellow";
      line.x1 = 0;
      line.y1 = viewportCenter.y;
      line.x2 = viewport.width;
      line.y2 = viewportCenter.y;
      this.guiTexture.addControl(line);

      line = new Line("DrawCenterLineVertical");
      line.color = "yellow";
      line.x1 = viewportCenter.x;
      line.y1 = 0;
      line.x2 = viewportCenter.x;
      line.y2 = viewport.height;
      this.guiTexture.addControl(line);
    }
  }

  /** Position the guiRootTransformNode in front of the camera
   *  Currently this is simply rotated the opposite of config.cameraAngle
   *  It doesn't appear to be possible to compute cameraDistance from a FreeCamera or subclasses, so it had to be passsed in
   *  (https://forum.babylonjs.com/t/why-gettarget-dosnt-give-me-the-value-set-buy-settarget/4014/9)
   *
   *  config.guiTransformNodeDistance can be used to move the gui closer using negative values
   *  The idea is to specify the least distance that places the gui plane completely in front of the game
   *  The gui is scaled to counter the closer distance, so that the scale should be about the same as the game itself
   *  If the gui is moved too close to the camera, the 3d perspective will become stronger and make rotated objects look stretched
   *
   *  game.guiRootTransformNodeBoundingBox contains the bounds that should be on screen for objects parented to the guiRootTransformNode
   */
  positionGuiRootTransformNode(cameraDistance: number) {
    // Align the guiRootTransformNode with the camera
    // XXX - billboard mode breaks picking (in particular the HelpButton in the HomeScreen CardButton) - it's subtle, the coordinates are off
    //       so we'll use a fixed opposite rotation of the fixed camera angle
    //       If we want the camera to move at runtime, we'll need to do something different
    //this.guiRootTransformNode.billboardMode = TransformNode.BILLBOARDMODE_ALL;
    this.guiRootTransformNode.rotationQuaternion = Quaternion.RotationAxis(Axis.X, (90 - config.cameraAngle) * Math.PI / 180.0);

    // Position the guiRootTransformNode guiTransformNodeDistance away from the origin along the rotated -z axis
    let guiPos = new Vector3(0, 0, config.guiTransformNodeDistance);
    guiPos.rotateByQuaternionToRef(this.guiRootTransformNode.rotationQuaternion, guiPos);
    this.guiRootTransformNode.position = guiPos;

    // Scale to counter the distance ratio
    let guiAxisLength = guiPos.length();
    let guiScale = (cameraDistance - guiAxisLength) / cameraDistance;
    this.guiRootTransformNode.scaling = new Vector3(guiScale, guiScale, guiScale);

    // Update the world matrix
    this.guiRootTransformNode.computeWorldMatrix();

    // Project the gui position to get z for unproject
    let guiScreenPos = this.project(guiPos);

    // Unproject the upper left and lower right screen corners into 3d world space using the z value from the projected gui position
    // In order to the the final min and max result with the correct signs, we need to project the lower left and upper right
    let guiMinWorld = this.unproject(new Vector3(0, this._engine.getRenderHeight(), guiScreenPos.z));
    let guiMaxWorld = this.unproject(new Vector3(this._engine.getRenderWidth(), 0, guiScreenPos.z));

    // Transform the unprojected world coordinates into gui local coordinates
    let guiMatrix = this.guiRootTransformNode.getWorldMatrix();
    let inverseGuiMatrix = guiMatrix.clone().invert();
    let guiMinLocal = Vector3.TransformCoordinates(guiMinWorld, inverseGuiMatrix);
    let guiMaxLocal = Vector3.TransformCoordinates(guiMaxWorld, inverseGuiMatrix);

    // Create the gui bounding box
    this.guiRootTransformNodeBoundingBox = new BoundingBox(guiMinLocal, guiMaxLocal, guiMatrix);

    // Debug option to visualize the guiRootTransformNode
    let visualizeGuiRootTransformNode = false;
    if(visualizeGuiRootTransformNode) {
      let mesh = this.scene.getMeshByName("bbox");
      if(mesh)
        mesh.dispose();
      let exts = this.guiRootTransformNodeBoundingBox.extendSize;
      mesh = MeshBuilder.CreatePlane("guiRootTransformNode", {width: exts.x * 2, height: exts.y * 2});
      mesh.parent = this.guiRootTransformNode;
      let mat = new StandardMaterial("guiRootTransformNode", this.scene);
      mat.diffuseColor = new Color3(1, 0, 1);
      mesh.material = mat;
    }
  }

  showGame(show = true) {
    if(this.showingGame === show)
      return;

    this.showingGame = show;
    this.gameRootTransformNode.setEnabled(show);

    this.onShowGameObservable.notifyObservers(this.showingGame);
  }

  hideGame() {
    this.showGame(false);
  }

  onEngineResize() {
    // We need the guiTexture resized before handling resize
    // Unfortunately, the guiTexture is listening to this same event and we get it first
    // So we'll set a flag here and check it in onEngineBeginFrame
    this.hasResized = true;
  }

  onEngineBeginFrame() {
    if(this.hasResized) {
      this.hasResized = false;

      this.resized();

      for(let system of this.systems.values())
        system.resized();

      this.fitInWindow();

      for(let system of this.systems.values())
        system.afterResized();

      this.onResizedObservable.notifyObservers(null);

      if(this.showingGame === false) {
        this.showingGame = undefined;
        this.hideGame();
      }

      this.needRender();
    }
  }

  displayErrorMessage(errorMsg: string): void {
    toast("ErrorToast", errorMsg);
  }

  onPointerDown(evt: PointerInfo) {
    //console.log("onPointerDown      " + ((evt.pickInfo.hit && evt.pickInfo.pickedMesh) ? "mesh: " + evt.pickInfo.pickedMesh.name : "MISS") + (this.preventPlayUntilAnimationsAreComplete ? " preventPlayUntilAnimationsAreComplete" : ""));
    this.needRender(); // This is only needed for the development camera

    // Support a custom onPointerDown for 3d GUI elements
    if(evt.pickInfo.pickedMesh && evt.pickInfo.pickedMesh.metadata && evt.pickInfo.pickedMesh.metadata.onPointerDown) {
      evt.pickInfo.pickedMesh.metadata.onPointerDown(evt);
      return;
    }

    if (!this.dragPile.isEnabled() && evt.pickInfo.pickedMesh && evt.pickInfo.pickedMesh.metadata) {
      if (evt.pickInfo.pickedMesh.metadata.type === "Card" || evt.pickInfo.pickedMesh.metadata.type === "Pile") {
        if(this.debugCameraControl)
          this._camera.detachControl(this._canvas); // detach camera from mouse/keyboard control so that the camera doesn't move while dragging
      }

      if(this.gameState.status !== GAME_STATE_PLAY)
        return;

      // Check if play is blocked after preventing unwanted camera motion
      if(this.preventPlayUntilAnimationsAreComplete || this.preventPlayUntilTurnChanges)
        return;

      if (evt.pickInfo.pickedMesh.metadata.type === "Card") {
        // Don't allow actions on animating cards (we empty mesh.animations when the animation completes)
        if(evt.pickInfo.pickedMesh.animations.length)
          return;

        // if they clicked down on a removeable piece, begin dragging it by createing a drag pile and adding piece to it
        let piece = evt.pickInfo.pickedMesh;
        let pieceStates = this.gameState.canRemovePiece(this.rootState.user.id, this.localSeat, piece.parent.name, piece.name); // returns an array of IPieceStates to be removed, or  null

        if(pieceStates) {
          if(evt.event.button !== 0)
            return;

          // set dragPile enabled to indicate that we're using it
          this.dragPile.setEnabled(true);

          // updateDragPilePos so reparent will preserve the piece position
          this.updateDragPilePos();

          this.sourcePile = evt.pickInfo.pickedMesh.parent as AbstractMesh;

          // move pieces to dragPile
          //for (let pieceState in pieceStates) {
          pieceStates.forEach((pieceState) => {
            piece = this.pileSystem.getPiece(this.sourcePile, pieceState.name);
            this.cardSystem.reparent(piece, this.dragPile);
            piece.isPickable = false;
          });
          this.pileSystem.layout(this.dragPile);

          // Hide cursor
          document.getElementsByTagName("html")[0].style.cursor = "none";
        }
      }
    }
  }

  onPointerUp(evt: PointerInfo) {
    //console.log("onPointerUp        " + ((evt.pickInfo.hit && evt.pickInfo.pickedMesh) ? "mesh: " + evt.pickInfo.pickedMesh.name : "MISS") + (this.preventPlayUntilAnimationsAreComplete ? " preventPlayUntilAnimationsAreComplete" : ""));
    this.needRender(); // This is only needed for the development camera

    if(evt.event.button !== 0)
      return;

    if(this.debugCameraControl)
      this._camera.attachControl(this._canvas, false); // reattach camera to mouse/keyboard controls

    if(this.gameState.status !== GAME_STATE_PLAY && this.gameState.status !== GAME_STATE_PASS) {
      if(this.gameState.status === GAME_STATE_BID && !this.finishedTurn) {
        // If a card belonging to the local player was clicked, display an error message
        if(evt.pickInfo.pickedMesh && evt.pickInfo.pickedMesh.metadata && evt.pickInfo.pickedMesh.metadata.type === "Card") {
          let piece = evt.pickInfo.pickedMesh;
          if(piece && piece.metadata.seatId === this.localSeat) {
            let seat = this.gameState.getSeat(this.localSeat);
            if(this.gameState.seatsTurn === seat)
              this.displayErrorMessage("Place bid before playing cards");
            else
              this.displayErrorMessage("Waiting for others to bid");
          }
         }
      }
      return;
    }

    if(this.preventPlayUntilAnimationsAreComplete || this.preventPlayUntilTurnChanges)
    {
      if(evt.pickInfo.pickedMesh && evt.pickInfo.pickedMesh.metadata && evt.pickInfo.pickedMesh.metadata.type === "Card") {
        let piece = evt.pickInfo.pickedMesh;
        this.trySelectingPiece(piece);
      }
      return;
    }

    // if they were dragging a pile see if they dropped it on another, or return pieces to source pile
    if (this.dragPile.isEnabled()) {
      // Restore cursor
      document.getElementsByTagName("html")[0].style.cursor = "default";

      // build list of piece names to pass to canAddPieces
      let pieceNames: string[] = [];
      let meshes = this.dragPile.getChildMeshes();
      let topPiece = meshes[0];
      meshes.forEach((piece) => {
        piece.isPickable = true;
        pieceNames.push(piece.name);
      });

      let pileDropped = this.findNearestPileToPiece(topPiece, pieceNames);

      // check if we can add pieces to pile droped on
      if (pileDropped && this.gameState.canAddPieces(this.rootState.user.id, this.localSeat, pileDropped.name, pieceNames)) {
        // we don't directly move the piece to the new pile in the UI, we request the gameState to do it
        // which will then notify the UI via onPieceChanged, so leave the pieces in the dragPile and onPieceChanged will finish
        this.gameState.requestMovePieces(this.rootState.user.id, this.localSeat, this.sourcePile.name, pileDropped.name, pieceNames);
      }
      else {
        // can't drop, move back to source pile
        this.dragPile.getChildren().forEach((pieceNode) => {
          let piece = pieceNode as AbstractMesh;
          this.cardSystem.reparent(piece, this.sourcePile);
        });
        this.pileSystem.layout(this.sourcePile);
      }

      this.dragPile.setEnabled(false); // we're down with drag pile so disable it
      this.sourcePile = null;

    }
    else if(evt.pickInfo.hit && evt.pickInfo.pickedMesh && evt.pickInfo.pickedMesh.metadata) {
      // they clicked on a piece
      if(evt.pickInfo.pickedMesh.metadata.type === "Card") {
        let piece = evt.pickInfo.pickedMesh;
        if(this.gameState.canClickPiece(this.rootState.user.id, this.localSeat, piece.parent.name, piece.name, this.displayErrorMessage))
          this.clickPiece(this.rootState.user.id, this.localSeat, piece.parent.name, piece.name);
        else
          this.trySelectingPiece(piece);
      }
      else if(evt.pickInfo.pickedMesh.metadata.type === "Pile") {  // they clicked on a pile
        let pile = evt.pickInfo.pickedMesh;
        if(this.gameState.canClickPile(this.rootState.user.id, this.localSeat, pile.name))
          this.gameState.clickPile(this.rootState.user.id, this.localSeat, pile.name);
      }
    }
  }

  onPointerMove(evt: PointerInfo) {
    //console.log("onPointerMove      " + ((evt.pickInfo.hit && evt.pickInfo.pickedMesh) ? "mesh: " + evt.pickInfo.pickedMesh.name : "MISS") + (this.preventPlayUntilAnimationsAreComplete ? " preventPlayUntilAnimationsAreComplete" : ""));
    this.needRender(); // This is only needed for the development camera

    if (this.dragPile.isEnabled()) {
      this.updateDragPilePos();
    }
  }

  onPointerTap(evt: PointerInfo) {
    //console.log("onPointerDoubleTap " + ((evt.pickInfo.hit && evt.pickInfo.pickedMesh) ? "mesh: " + evt.pickInfo.pickedMesh.name : "MISS") + (this.preventPlayUntilAnimationsAreComplete ? " preventPlayUntilAnimationsAreComplete" : ""));
    //console.log("tap")
    if(this.gameState.status !== GAME_STATE_PLAY)
      return;

    if(this.preventPlayUntilAnimationsAreComplete || this.preventPlayUntilTurnChanges)
      return;

    // evt.event.button === 2
    let pickInfo = evt.pickInfo;

    if(this.dragPile.isEnabled())
    {
      // Drop dragPile back to source
      document.getElementsByTagName("html")[0].style.cursor = "default";
      this.dragPile.getChildMeshes().forEach((piece) => {
        piece.isPickable = true;
        this.cardSystem.reparent(piece, this.sourcePile);
      });
      this.pileSystem.layout(this.sourcePile);

      this.dragPile.setEnabled(false); // we're down with drag pile so disable it
      this.sourcePile = null;

      // evt.pickInfo.pickedMesh is the top piece of the pile below the drag pile
      // NOT the piece we're trying to autoplay
      // So let's re-run the picking
      pickInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, null, null, this._camera);
    }

    // Check if they are double tapping/clicking on a piece, then check with game state if there is a pile to auto move this piece to, if there is, then request to move it
    if (pickInfo.pickedMesh && pickInfo.pickedMesh.metadata && pickInfo.pickedMesh.metadata.type === "Card" ) {
      let piece = pickInfo.pickedMesh;
      this.autoplay(piece);
    }
  }

  setDebugCameraControl(debugCameraControl: boolean) {
    if(debugCameraControl !== this.debugCameraControl) {
      this.debugCameraControl = debugCameraControl;

      if(debugCameraControl) {
        // Attach the camera to the canvas.
        this._camera.attachControl(this._canvas, false);
      } else {
        // Detach camera from mouse/keyboard control
        this._camera.detachControl(this._canvas);
      }
    }
  }

  debugLoseWebGLContext(restoreInMs = 5000) {
    // tslint:disable-next-line:no-console
    console.log(`Losing WebGL Context, will restore in ${restoreInMs / 1000} seconds`);
    let gl = (this._engine as any)._gl; // this._engine.getRenderingCanvas().getContext("webgl") returned NULL, so we'll get the engine's WebGL context
    let glWEBGL_lose_context = gl.getExtension("WEBGL_lose_context");
    glWEBGL_lose_context.loseContext();
    setTimeout(() => glWEBGL_lose_context.restoreContext(), restoreInMs);

    // There's also this: https://www.khronos.org/webgl/wiki/HandlingContextLost
    // Though I don't know where WebGLDebugUtils comes from
    // var canvas = document.getElementById("canvas");
    // canvas = WebGLDebugUtils.makeLostContextSimulatingCanvas(canvas);
    // canvas.loseContextInNCalls(5);
  }

  onKeyDown(evt: ActionEvent) {
    if(process.env.NODE_ENV !== "development")
      return;

    // DEBUG - x to view game from positive x axis (right of game)
    //         X to view game from negative x axis (left of game)
    if (evt.sourceEvent.key === "x" || evt.sourceEvent.key === "X") {
      let x = 7.0;
      let y = 0.03;
      let z = 3.5;

      if(evt.sourceEvent.key === "X")
        x = -x;

      this._camera.position = new Vector3(x, y, z);
      this._camera.setTarget(new Vector3(0, y, z));
    }

    if (evt.sourceEvent.key === "y" || evt.sourceEvent.key === "Y") {
      let x = 0.0;
      let y = 0.03;
      let z = 7.0;

      if(evt.sourceEvent.key === "Y")
        z = -z;

      this._camera.position = new Vector3(x, y, z);
      this._camera.setTarget(new Vector3(0, y, z));
    }

    if (evt.sourceEvent.key === "z" || evt.sourceEvent.key === "Z") {
      let x = 0.0;
      let y = 7.0;
      let z = 0.0;

      if(evt.sourceEvent.key === "Z")
        y = -y;

      this._camera.position = new Vector3(x, y, z);
      this._camera.setTarget(new Vector3(0, 0, 0));
    }

    // DEBUG - r to reset camera to original view
    if(evt.sourceEvent.key === "r") {
      this.fitInWindow();
    }

    // DEBUG - R to simulate a resize
    if(evt.sourceEvent.key === "R") {
      this.callEngineResize = true;
      this.hasResized = true;
    }

    // DEBUG - n to deal a new game
    if(evt.sourceEvent.key === "n")
      this.newGame();

    // DEBUG - a to pause animations
    if(evt.sourceEvent.key === "p") {
      this.paused = !this.paused;

      if(this.paused) {
        for(let a of this.scene.animatables)
          a.pause();
      }
      else {
        for(let a of this.scene.animatables)
          a.restart();
      }
    }

    // Log config as an object that can be examined with the console object tree thingy
    if(evt.sourceEvent.key === "c") {
      logger.info("Config", { config });
    }

    // DEBUG - a for Fast auto play
    if(evt.sourceEvent.key === "a") {
      this.setAutoPlayLocalSeat(!this.debugAutoPlayLocalSeat);
    }

    // DEBUG - e to test error logging/reporting to sentry.io
    if(evt.sourceEvent.key === "e") {
      logger.error("Test Error", { config });
    }

    // DEBUG - f for Animation Fast Mode
    if(evt.sourceEvent.key === "f") {
      this.animationSystem.debugFastMode = !this.animationSystem.debugFastMode;
      logger.info("Animation.debugFastMode", { debugFastMode: this.animationSystem.debugFastMode });
    }

    // DEBUG - F to turn on FPS in title bar
    if(evt.sourceEvent.key === "F")
      game.scene.onBeforeAnimationsObservable.add(() => document.title = `${config.longName} - ${game.getFps().toFixed() + " fps"}`);

    // Log rootState snapshot as an object that can be examined with the console object tree thingy
    // Trying to copy this as text just ends up truncated with "..."
    if(evt.sourceEvent.key === "s") {
      logger.info("RootState snapshot", { rootState: getSnapshot(this.rootState) });
    }

    // Log rootState snapshot as text that can be copied
    // Initially, the text is truncated with "...", but clicking on it will expand it and allow it to be copied
    if(evt.sourceEvent.key === "S") {
      // Need a simple console log here or we get a javascript object instead of a JSON string
      // tslint:disable-next-line:no-console
      console.log(JSON.stringify(getSnapshot(getRootState()), null, 2));
    }

    if(evt.sourceEvent.key === "m") {
      // Dump all mesh metadata
      let metadata: any = {};
      for(let pile of this.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Pile")) {
        let d: any = {
          metadata: pile.metadata,
          children: {},
        };

        metadata[pile.name] = d;

        for(let piece of pile.getChildren())
          d.children[piece.name] = piece.metadata;
      }
      // tslint:disable-next-line:no-console
      console.log(JSON.stringify(metadata, null, 2));
    }

    if(evt.sourceEvent.key === "g")
      dumpGuiControlTree();

    this.needRender();
  }

  pausePatchQueueIfAnimating(alsoCheckRoundEnd = false) {
    if(this.animationSystem.isBlockingPlay() || (alsoCheckRoundEnd && this.animationSystem.isBlockingRoundEnd())) {
      // pause the patchQueue
      // XXX - pausing on any change in turn works, but is probably overkill
      //       The only known case where a pause is needed is after the trick is won,
      //       before the won cards are moved to the waste piles
      //       A couple of Solitaire items might be refactored to use the pause as well: autoflip and autofinish
      if(!this.pausedPatchQueueUntilAnimationsAreComplete) {
        this.pausedPatchQueueUntilAnimationsAreComplete = true;
        this.rootState.game.pausePatchQueue();
      }

      // Whenever we're pausing for animations, we also need to prevent plays
      // Otherwise in an offline game, the play will take place immediately and there will be overlapped animations
      this.preventPlayUntilAnimationsAreComplete = true;
    }
  }

  onAnimationBlockingChanged(animatable: Animatable) {
    // Check if play is still blocked
    if(this.animationSystem.isBlockingPlay())
      return;

    // If we're at the GAME_STATE_ROUND_OVER status, also check blocks on round end
    if(this.gameState.status === GAME_STATE_ROUND_OVER && this.animationSystem.isBlockingRoundEnd())
      return;

    // Allow play to continue if it was waiting
    this.preventPlayUntilAnimationsAreComplete = false;

    // Unpause the patchQueue, if it was paused
    if(this.pausedPatchQueueUntilAnimationsAreComplete) {
      this.pausedPatchQueueUntilAnimationsAreComplete = false;
      this.rootState.game.unpausePatchQueue();

      // unpausePatchQueue may have generated new animations
      // XXX - Do we sometimes also need to check isBlockingRoundOver?
      if(this.animationSystem.isBlockingPlay()) {
        this.preventPlayUntilAnimationsAreComplete = true;
        return;
      }
    }

    // Autofinish if we can
    if(this.localSeat && this.gameState.canAutoFinish(this.rootState.user.id, this.localSeat))
      this.gameState.requestAutoFinish(this.rootState.user.id, this.localSeat);

    // AutoPlay a selected card
    // XXX - This is not necessarily correct, for example if you are selecting cards to pass, but maybe canClickPiece will return false in that case so it's ok.
    if(!this.preventPlayUntilAnimationsAreComplete && !this.preventPlayUntilTurnChanges) {
      if(this.gameState.seatsTurn && this.localSeat && this.gameState.seatsTurn.id === this.localSeat) {
        let hand = this.findPile("hand" + this.localSeat);
        if(hand) {
          let selected = this.pileSystem.getSelectedPieces(hand);
          if(selected.length === 1) {
            let piece = selected[0];
            hand.metadata.needsLayout = true;
            if(this.gameState.canClickPiece(this.rootState.user.id, this.localSeat, hand.name, piece.name))
              this.clickPiece(this.rootState.user.id, this.localSeat, hand.name, piece.name);
            this.cardSystem.setSelected(piece, false);
          }
        }
      }
    }
  }

  /** Click Piece */
  clickPiece(playerId: string, seatId: string, pileName: string, pieceName: string) {
    // The intent here is to prevent playing again until the turn changes, unless the game isn't turn based
    // Note that this only works in Spades because in spades you click one card on your turn to play it
    // If we support passing this won't be good enough.
    // It should support queing, because clicks are allowed when the game switches to the next player's turn, not when it comes back to the local seat
    // The actual problem here is that actions like clickPiece are forwared to the server.
    // In response, at sometime in the future, assuming the play was valid, patches will roll in modfying the local state as a result
    // In the meantime, the local state thinks it's still the local player's turn to make a move, so canClickPiece returns true
    // This means the player can click the same card or another card which forwards a bogus move to the server
    // The result is at best a server exception and at worst the client gets out of sync, resyncs, and there can be local exceptions and breakage
    // So I'm not sure of the best solution, but this currently works for Spades and Klondike
    if(config.onClickPreventPlayUntilTurnChanges) {
      // Prevent play until the turn changes
      this.preventPlayUntilTurnChanges = true;
    }

    // Deselect any selected cards in the localSeat's hand
    this.deselectLocalHandPieces();

    // Also unhighlight
    this.unhighlightLocalHandPieces();

    // Click the piece in the game state, it's import this is after setting this.preventPlayUntilTurnChanges
    // because in offline games calling clickPiece could immediatly set seatsTurn, which sets preventPlayUntilTurnChanges false
    this.gameState.clickPiece(playerId, seatId, pileName, pieceName);
  }

  /** Try to select the given piece, if it's currently allowed */
  trySelectingPiece(piece: AbstractMesh) {
    // The card needs to belong to the local seat
    if(!this.localSeat || piece.metadata.seatId !== this.localSeat)
      return;

    // don't allow selecting a piece if we don't know it's value yet
    if(!piece.metadata.value)
      return;

    // don't allow selecting or deselecting if max is 0. This prevents deselecting after clicking pass in hearts
    if(this.maxCardSelectionCount === 0)
      return;

    // It needs to either be another seats turn, or waiting on a pre-played card, but always allow selecting when passing
    if(this.gameState.seatsTurn && this.gameState.seatsTurn.id === this.localSeat && !this.preventPlayUntilTurnChanges && this.gameState.status !== GAME_STATE_PASS)
      return;

    // Get the parent pile & flag it to layout
    let pile = piece.parent as AbstractMesh;
    pile.metadata.needsLayout = true;

    if(this.cardSystem.isSelected(piece)) {
      // A selected card was clicked, so deselect it
      this.cardSystem.setSelected(piece, false);
    } else {
      // If we can only select 1 card, then auto deselect it if we're selecting another
      if(this.maxCardSelectionCount === 1)
        this.deselectLocalHandPieces(pile);
      else {
        // If we can select more then 1, then just prevent more then maxCardSelectionCount from being selected
        let hand = this.findPile("hand" + this.localSeat);
        let selected = this.pileSystem.getSelectedPieces(hand);
        if(selected.length >= this.maxCardSelectionCount)
          return;
      }

      // Don't allow selecting if a card belonging to the local seat is animating either within the hand, or to the trick
      for(let mesh of this.scene.meshes) {
        if(
          mesh.metadata &&
          mesh.metadata.type === "Card" &&
          mesh.metadata.seatId === this.localSeat &&
          (mesh.parent.name.startsWith("hand") || mesh.parent.name === "trick") &&
          mesh.animations &&
          mesh.animations.length
        ) {
          return;
        }
      }

      // Select the clicked card
      this.cardSystem.setSelected(piece, true);
    }
  }

  /** Deselect any selected cards in the localSeat's hand */
  deselectLocalHandPieces(pileThatMightBeLocalHand?: AbstractMesh) {
    let localSeatHandName = "hand" + this.localSeat;
    let hand = pileThatMightBeLocalHand || this.findPile(localSeatHandName);
    if(hand && hand.name === localSeatHandName)
      this.pileSystem.deselectPieces(hand);
  }

  /** unhighlight any highlighted cards in the localSeat's hand */
  unhighlightLocalHandPieces(pileThatMightBeLocalHand?: AbstractMesh) {
    let localSeatHandName = "hand" + this.localSeat;
    let hand = pileThatMightBeLocalHand || this.findPile(localSeatHandName);
    if(hand && hand.name === localSeatHandName)
      this.pileSystem.unhighlightPieces(hand);
  }

  /** autoplay the given piece */
  autoplay(piece: AbstractMesh) {
    let destPileName = this.gameState.getAutoMoveDest(this.rootState.user.id, this.localSeat, piece.parent.name, piece.name);
    if(destPileName) {
      this.gameState.requestMovePieces(this.rootState.user.id, this.localSeat, piece.parent.name, destPileName, [piece.name]);
    }
  }

  /** toggle auto playing the local seats turns */
  setAutoPlayLocalSeat(autoPlay: boolean) {
    // implemented by sub class
    this.debugAutoPlayLocalSeat = autoPlay;
  }

  /** Highlight an AI hint to play a piece */
  setPieceHint(value: number) {
  }

  /** moves the drag pile to the current position of the mouse pointer on the ground  */
  updateDragPilePos(): void {
    if (this.dragPile.isEnabled()) {
      let groundPosition = this.getGroundPosition(); // of pointer
      if (groundPosition)
        this.dragPile.position = groundPosition.add(new Vector3(0, 1, 0));
    }
  }

  /** Get position of mouse pointer on the ground mesh  */
  getGroundPosition(): Vector3 {
    // Use a predicate to get position of pointer on the ground
    let pickinfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, (mesh) => mesh === this.ground, null, this._camera);
    if (pickinfo.hit) {
      return pickinfo.pickedPoint;
    }
    return null;
  }

  /** get the pile which is nearest to the given piece in screen space
   *  This is computing the average distance from the piece center point and the 4 top corners to the pile's center line segment
   *  The comparison is performed in screen space in the hopes of matching expectations from the visual presentation
   *  (If it's not good enough, the next step would be to compute the pile's screen space polygon and compare overlap area. This looks useful for that https://github.com/vrd/js-intersect)
   *  @param pieceNames optionally filter out invalid piles using gameState.canAddPieces
   */
 findNearestPileToPiece(piece: AbstractMesh, pieceNames: string[]= null) {
  if(!piece)
    return;

  // Get the bounding box of the dragged piece
  let cardBbox = piece.getBoundingInfo().boundingBox;

  // Project the 4 top corners of the card's bounding box to screen space
  let tl = this.project(new Vector3(cardBbox.minimumWorld.x, cardBbox.maximumWorld.y, cardBbox.maximumWorld.z));
  let tr = this.project(new Vector3(cardBbox.maximumWorld.x, cardBbox.maximumWorld.y, cardBbox.maximumWorld.z));
  let bl = this.project(new Vector3(cardBbox.minimumWorld.x, cardBbox.maximumWorld.y, cardBbox.minimumWorld.z));
  let br = this.project(new Vector3(cardBbox.maximumWorld.x, cardBbox.maximumWorld.y, cardBbox.minimumWorld.z));

  // Compute the screen space center point
  let center = tl.add(tr).add(bl).add(br).scale(0.25);

  // Build an array of Vector2 for the line segment test
  let cardPoints = [
    new Vector2(tl.x, tl.y),
    new Vector2(tr.x, tr.y),
    new Vector2(br.x, br.y),
    new Vector2(bl.x, bl.y),
    new Vector2(center.x, center.y),
  ];

  let found = null; // Current found piece
  let distance = 1e6; // distance to current found piece
  let maxDistance = (tr.x - tl.x) * 1.1; // Compute a reasonable max distance from the pile based on the piece width

  // Check each pile
  let piles = this.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Pile");
  for (let pile of piles) {
    if(pile.name === "drag")
      continue;

    // If we have pieceNames, reject any pile that can't accept the pieces
    if(pieceNames && ! this.gameState.canAddPieces(this.rootState.user.id, this.localSeat, pile.name, pieceNames))
      continue;

    // Get the pile height
    let pileBbox = pile.getBoundingInfo().boundingBox;
    let pileHeight = pileBbox.maximum.y - pileBbox.minimum.y;
    let pileMaxHeight = pile.metadata.maxHeight;

    // Find the top and bottom center points of the pile
    let top = pile.absolutePosition.add(new Vector3(0, 0, 0.64));
    let bot = top.subtract(new Vector3(0, 0, pileMaxHeight));

    // Project to screen space
    let tc = this.project(top);
    let bc = this.project(bot);

    // Create a line segment representing the center line of the pile in screen space
    let a = new Vector2(tc.x, tc.y);
    let b = new Vector2(bc.x, bc.y);

    // Compute the average distance from each card cornder to the pile's center line segment
    let d = 0;
    for(let pt of cardPoints) {
      d += Vector2.DistanceOfPointFromSegment(pt, a, b);
    }
    d /= cardPoints.length;

    // Ignore piles that are too far away from the piece
    if(d > maxDistance)
      continue;

    // Update the selected pile if the new pile is closer
    if(d < distance)
    {
      found = pile;
      distance = d;
    }
}

  return found;
}

  /** Get pile under screen coordinates x, y
   *   Currently unused
   */
  getPile(x: number, y: number): Node {
    let pickinfo = this.scene.pick(x, y, (mesh) => mesh.isPickable, null, this._camera);
    if (pickinfo.hit) {
      let mesh = pickinfo.pickedMesh;
      if (mesh.metadata && mesh.metadata.type === "Pile")
        return mesh;
      else if (mesh.metadata && mesh.metadata.type === "Card")
        return mesh.parent;
    }
    return null;
  }

  /** find a pile in scene  */
  findPile(pileName: string): AbstractMesh {
    // todo optimize this to only look for piles, maybe save an array of just piles, or is there a way to not search children of piles?
    // basically I don't want it to waste time looking through all the cards
    // Node has getChildMeshes with a directDescendantsOnly option, so maybe we should make piles a child of a mesh instead of scene to use that.
    let piles = this.scene.meshes.filter((mesh) => mesh.name === pileName);
    if (piles.length > 0)
      return piles[0];
    return null;
  }

  /** Ensure the current layout for all pieces is applied */
  applyCurrentLayout() {
    // Stop any existing animations
    this.animationSystem.stopAll();

    // Layout all piles
    let piles = this.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Pile");
    for(let pile of piles) {
      this.pileSystem.layout(pile);
    }

    // Stop any newly queued animations
    this.animationSystem.stopAll();

    // animationSystem.stopAll calls applyLayout on every card
    // so we don't actually need to call applyLayout even thought that's what the function is for
  }

  /** call to relayout piles, for example relaying out spades hands after setting localseat. */
  layout() {
    // overridden by subclass
  }

  onPieceChanged(patch: any, reversePatch: any, params: any) {
    let pileIndex = params[1];
    let pieceIndex = params[2]; // this is index in state, not meshes

    //console.log(this.scene.getRenderId() + ": " + this.gameState.status + ", " + patch.op + ", " + patch.path + ", " + JSON.stringify(patch.value));

    /*console.log("PATCH");
    console.log(patch);
    console.log("REVERSE PATCH");
    console.log(reversePatch);
    */
    let pileState = this.gameState.piles.get(pileIndex);
    let pile = this.findPile(pileState.name);
    if(!pile) {
      // XXX - This happens every run in gameState.init, which happens before the scene piles are created
      //console.log("onPieceChange failed to find pile in scene: " + pileName);
      return;
    }

    if(patch.op === "remove")
    {
      // Assuming there's an add coming right away, we'll move the piece then

      // 9/20/2019 - this used to be restricted to GAME_STATE_PLAY, but that could leave a pile unlayed out after passing, so instead we'll try always flagging needsLayout
      // Flag the pile for re-layout at the beginning of the next frame
      // in case spacing has changed due to the removed piece
      pile.metadata.needsLayout = true;
    }
    else if(patch.op === "add")
    {
      // piece was added to a pile, it should be in dragPile, so move it from there to new pile
      //let piece = Pile.getPiece(this.dragPile, patch.value.name);
      let piece = this.scene.getMeshByName(patch.value.name); // This is a linear search through all meshes in scene
      if (piece && pieceIndex < pileState.pieces.length) {
        // If the played piece belongs to the localSeat, make sure pieces are deselected
        if(piece && piece.metadata.seatId === this.localSeat)
          this.deselectLocalHandPieces(piece.parent as AbstractMesh);

        this.cardSystem.reparent(piece, pile);
        let pieceState = pileState.pieces[pieceIndex];
        if(pieceState) {
          this.cardSystem.updateState(piece, patch.value);
          this.pileSystem.layout(pile);

          // Highlight cards that were just passed to the local seat, check for snapshot to be done to avoid highlighting cards when reloading in the pass state
          if(this.gameState.status === GAME_STATE_PASS && piece.metadata.seatId === this.localSeat && this.gameState.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE) {
            this.cardSystem.setHighlighted(piece, true, HIGHLIGHT_PASSED_CARD_COLOR);
          }
        }
      }
    }
    else if(patch.op === "replace")
    {
      // a property of the piece changed, such as facing (UP or DOWN), call this.cardSystem.updateState to reflect change
      if(pieceIndex < pileState.pieces.length) {
        let pieceState = pileState.pieces[pieceIndex];
        if(pieceState) {
          let piece = this.pileSystem.getPiece(pile, pieceState.name);
          if (piece) {
            this.cardSystem.updateState(piece, pieceState);
            this.pileSystem.layout(pile);
          }
        }
      }
    }
  }

  /** onRootStatusChanged called when this.rootState.status changes. */
  onRootStatusChanged(patch: any, reversePatch: any, params: any) {
    Sentry.configureScope((scope) => {
      scope.setExtra("rootState.status", this.rootState.status);
    });

    if(patch.value === ROOT_STATE_GAME) {
      // be sure all screens such as the leaderboard are closed when starting a game
      this.screenSystem.disposeAllScreens();
      this.showGame();
    }
    else if(patch.value === ROOT_STATE_HOME_SCREEN)
      clearTimeout(this.leaveGameTimeOutId);
  }

  /** request to join or create a new game at end of a game, ie Play Again */
  requestNextGame(gameStart: IGameStart) {
    // if they're currently in a game that is over, and the nextGameId has already been created, then join that game
    if(this.gameState.status === GAME_STATE_GAME_OVER) {
      this.gameStart = gameStart;
      // If nextGameId is already set, then that means another player already started a new game, so join it
      if(this.gameState.nextGameId) {
        this.joinGame(this.gameState.nextGameId);
      }
      else if(this.gameState.options.standAlone || !config.haveStateSync) {
        // if this is a standAlone offline game then just start a new game with the same options
        this.newGame(this.gameStart);
      }
      else {
        // else it's an online multiplayer game, so request a new game id, which will trigger the server to setup a new game, which we'll join when gameState.nextGameId is set
        this.gameState.requestNextGameId(this.rootState.user.id); // request a new game to be created, we'll watch for nextGameId to be set
        this.requestedNextGame = true;
      }
    }
  }

  onPlayersStatusChanged(patch: any, reversePatch: any, params: any) {
    let playerIndex = params[1];
    let player = this.gameState.players[playerIndex];
    // if the local players status says they left, then return to the home screen
    if(player && player.status === PLAYER_STATE_LEFT && player.id === this.rootState.user.id) {

      // report to server if the local player left an offline single player game
      if(this.gameState.offline)
        this.offlineSystem.reportOfflineGame();

      Commands.onHomeScreen();

      // cancel time out to force leave
      clearTimeout(this.leaveGameTimeOutId);
      this.leaveGameTimeOutId = null;
    }
  }

  onPlayersSocketIdChanged(patch: any, reversePatch: any, params: any) {
    let playerIndex = params[1];
    let player = this.gameState.players[playerIndex];
    // if the local players socketId doesn't match the socketId the player was just set too. Then that means
    // some one else connected to this game with the same user. Either multiple tabs or devices. So disconnect.
    if(!this.gameState.offline && player && player.id === this.rootState.user.id && getStateSyncClient().id !== patch.value) {
      this.modalDialogSystem.showAlert("Uh oh", "Another device has connected to this game.").then((response) => {
        Commands.onHomeScreen();
      });
    }
  }

  /** onGameStatusChanged called when this.rootState.game.status changes. */
  onGameStatusChanged(patch: any, reversePatch: any, params: any) {
    //console.log("onStateChange: " + this.gameState.status);

    Sentry.configureScope((scope) => {
      scope.setExtra("gameState.status", this.gameState.status);
    });

    if (patch.value === GAME_STATE_RESET) {
      this.pausedPatchQueueUntilAnimationsAreComplete = false;
      this.animationSystem.stopAll();
      this.cardSystem.reparentAllAndResetPosition(this.findPile("stock"));
      this.cardSystem.resetAll();
      this.applyCurrentLayout();
    }
    else if(patch.value === GAME_STATE_DEAL) {
      // Apply the reset state before the deal moves arrive
      this.applyCurrentLayout();

      // Prevent play until all pending animations have completed
      this.preventPlayUntilAnimationsAreComplete = true;
    }
    else if(patch.value === GAME_STATE_ROUND_OVER) {
      // Let the animation of the last hand complete
      this.pausePatchQueueIfAnimating(true);
    }
  }

  /** this is called when the seat is assigned to a player.  */
  onSeatPlayerChanged(patch: any, reversePatch: any, params: any) {
    let seatIndex = params[1];
    let seat = this.gameState.seats[seatIndex];
    // check to see if this is the local player's seat
    if(seat && seat.player && seat.player.id === this.rootState.user.id) {
      // If we're sitting in a seat after the game has started, and after the initial load. Then we need to re join the game which reloads the whole snapshot
      // Most likely we're a watcher taking a seat, and need to resync to get private seat info such as the values of the cards in our hand
      if(this.gameState.status !== GAME_STATE_CREATE && this.gameState.status !== GAME_STATE_WAITING_FOR_PLAYERS &&
          this.gameState.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE && this.gameState.options.multiplayer) {
        this.joinGame(this.gameState.id);
      }
      else {
        this.localSeat = seat.id;
        this.layout(); // re orients hands to localSeat
      }
    }
  }

  onSeatsTurnChanged(patch: any, reversePatch: any, params: any) {
    this.preventPlayUntilTurnChanges = false;
    this.pausePatchQueueIfAnimating();
    this.finishedTurn = false;
  }

  onApplySnapshotStage(patch: any, reversePatch: any, params: any) {
    if(patch.value === APPLY_SNAPSHOT_STAGE_DONE) {
      // after a snapshot is done being applied, check to see if we are already in the game
      let userId = this.rootState.user.id;
      let seatState = this.gameState.getPlayerSeat(userId);
      let playerState = this.gameState.getPlayer(userId);
      let humansCnt = this.gameState.getHumanPlayersInSeatsCnt();

      if(seatState) {
        // We're already assigned assigned a seat
        this.localSeat = seatState.id;
        // update our name, image, socketId if it differs
        let socketId: string;
        if(getStateSyncClient())
          socketId = getStateSyncClient().id;

        // Check if our player info is up to date, if not update it
        if(playerState && (playerState.name !== this.rootState.user.name || playerState.imageUrl !== this.rootState.user.imageUrl || playerState.socketId !== socketId) ) {
          // I'm not sure if this needs to be in a timeout anymore, I think that was leftover from appsync
          setTimeout(() => {
            if(this.gameState.id)
              this.gameState.updatePlayerInfo(userId, this.rootState.user.name, this.rootState.user.imageUrl, socketId);
          }, 500);
        }

        if(this.debugAutoPlayLocalSeat)
          this.setAutoPlayLocalSeat(this.debugAutoPlayLocalSeat);
      }
      else if(this.gameState.joinable && this.gameState.findAvailableSeat()) {
        // if there is an available seat then take it. This might be taking over a bots seat.
        // for now we must call addPlayer on a delay, because the subscription to patches is not yet setup
        // so we won't get notification of joining if we do it to quickly.
        let seatId: string = null;
        if(this.gameStart && this.gameStart.seatId)
          seatId = this.gameStart.seatId;
        setTimeout(() => {
          if(this.gameState.id)
            this.gameState.addPlayer(userId, userId, this.rootState.user.name, seatId, false, this.rootState.user.imageUrl);
        }, 500);
        humansCnt += 1;
      }

      // gameStart.humansCnt is the number of humans in the previous game, if they aren't already in this new game, then prompt the user to wait for them.
      if(this.gameStart && this.gameStart.humansCnt && humansCnt < this.gameStart.humansCnt)
        toast("WaitForPlayersToast", "Wait for other players to Play Again.");

      // if we're resyncing after we played but before getting the onSeatsTurnChanged notification, and it's already the next seats turn
      // then the game can get stuck, resetting preventPlayUntilTurnChanges fixes this.
      this.preventPlayUntilTurnChanges = false;

      // Update pieces to the new state
      // Don't do it for offline spades because it sets opponents cards face up and values
      if(!this.gameState.offline && !this.gameState.options.standAlone) {
        this.gameState.piles.forEach((pile) => {
          let pileMesh = this.findPile(pile.name);
          pile.pieces.forEach((piece) => {
            let pieceMesh = this.scene.getMeshByName(piece.name);
            this.cardSystem.updateState(pieceMesh, piece);
            this.cardSystem.reparent(pieceMesh, pileMesh);
          });
        });
      }
      this.applyCurrentLayout();

      // log count of games joined and memory usage in development mode to help keep an eye out for memory leaks.
      if(process.env.NODE_ENV === "development") {
        this.gameCnt += 1;
        let heapUsed = 0;
        if((window.performance as any).memory) // this only exists in chrome
          heapUsed = (window.performance as any).memory.usedJSHeapSize.toLocaleString();
        logger.debug(`joined game  Game Cnt: ${this.gameCnt}   Memory Used(Chrome only): ${heapUsed}`);
      }
    }
  }

  onUserApplySnapshotStage(patch: any, reversePatch: any, params: any) {
    if(patch.value === APPLY_SNAPSHOT_STAGE_DONE) {
      this.checkReady();

      // set user name to __LOAD_TEST__ for load testing, this is so we can easily find and delete them to cleanup after a test
      if(config.loadTestClientMode && this.rootState.user.name !== "__LOAD_TEST__")
        this.rootState.user.setName(this.rootState.user.id, "__LOAD_TEST__");

      // if clientName isn't set in UserState yet, set it now.
      if(!this.rootState.user.clientName)
        this.rootState.user.setClientName(this.rootState.user.id, config.clientName);
    }
  }

  onUserIdChange(patch: any, reversePatch: any, params: any) {
    this.checkReady();

    Sentry.configureScope((scope) => {
      scope.setUser({id: this.rootState.user.id, name: this.rootState.user.name});
    });
  }

  onUserNameChange(patch: any, reversePatch: any, params: any) {
    Sentry.configureScope((scope) => {
      scope.setUser({id: this.rootState.user.id, name: this.rootState.user.name});
    });
  }

  onGameIdChanged(patch: any, reversePatch: any, params: any) {
    Sentry.configureScope((scope) => {
      scope.setExtra("gameState.id", this.gameState.id);
    });
  }

  onNextGameIdChanged(patch: any, reversePatch: any, params: any) {
    // if we already called requestNextGame watch for nextGameId to be set and then join it
    if(this.gameState.nextGameId && patch.op === "replace") {
      if(this.requestedNextGame)
        this.joinGame(this.gameState.nextGameId);
    }
  }

  onWindowResize() {
    // Delay calling engine.resize to workaround iOS glitches
    // We clear any existing timeout to "debounce" multiple calls to only resize once
    window.clearTimeout(this.resizeTimerId);
    this.resizeTimerId = window.setTimeout(() => this.callEngineResize = true, 250);

    // Flag that we're going to eventually set callEngineResize
    this.callEngineResizeRequested = true;
  }

  getDesiredCanvasSize() {
    // Get the window size
    let ww = document.body.clientWidth; // this excludes the scrollbar width and keeps us from getting a horizontal scrollbar
    let wh = window.innerHeight;

    // Get the canvas position
    let cy = this._canvas.offsetTop;

    // Compute the canvas size to fill the remainder of the window
    let cw = ww;
    let ch = wh - cy;

    return [cw, ch];
  }

  engineResize()
  {
    // Clear the flags
    this.callEngineResize = false;
    this.callEngineResizeRequested = false;

    // Get the new size
    let [cw, ch] = this.getDesiredCanvasSize();

    // Set the canvas to the new size
    this._canvas.style.width = cw + "px";
    this._canvas.style.height = ch + "px";

    // Let BabylonJS know
    this._engine.resize();
  }

  maybeEngineResize() {
    // XXX - ideally, this ridiculous every frame check wouldn't exist
    //       but, even with the 250ms delay already in place, even with upping it to 1000ms,
    //       there's still a lag after a resize before _canvas.offsetTop is correct

    // Don't spam the debounce function or it will never fire
    if(this.callEngineResizeRequested)
      return;

    // Get the new size
    let [cw, ch] = this.getDesiredCanvasSize();

    // Check
    if(this._canvas.style.width !== cw + "px" || this._canvas.style.height !== ch + "px") {
      // If there's a change, we'll actually call onWindowResize to "debounce" multiple calls to one
      this.onWindowResize();
    }
  }

  objectUrl: string = "";
  exportScene() {
    let filename = "argo-scene.babylon";
    if(this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl);
    }
    let serializedScene = SceneSerializer.Serialize(this.scene);

    let strScene = JSON.stringify(serializedScene);

    if (filename.toLowerCase().lastIndexOf(".babylon") !== filename.length - 8 || filename.length < 9) {
        filename += ".babylon";
    }

    let blob = new Blob ( [ strScene ], { type : "octet/stream" } );

    // turn blob into an object URL; saved as a member, so can be cleaned out later
    this.objectUrl = (window.webkitURL || window.URL).createObjectURL(blob);

    let link = window.document.createElement("a");
    link.href = this.objectUrl;
    link.download = filename;
    let click = document.createEvent("MouseEvents");
    click.initEvent("click", true, false);
    link.dispatchEvent(click);
  }

  /** default south seat to hand 0, if the local player has a seat, then their hand is south */
  getSouthSeat() {
    let southSeat = "0";
    if(this.localSeat)
      southSeat = this.localSeat;
    return southSeat;
  }

  /** onStateSyncError is called when StateSyncUserMiddleware and StateSyncGameMiddleware  */
  onStateSyncError(eventData: any) {
    // There was some sort of error with StateSync, it might be an authentication/connection problem
    // So prompt the user to try reloading/refreshing the browser page. I didn't want to automatically
    // reload because if another error happened on reload it could get in a loop.
    let reloadMsg: string;
    let reload = false;
    if(eventData.err.name === CLIENT_VERSION_ERROR)
      reloadMsg = "There is a new version required. Click Reload to get it now.";
    else {
      // syncState is called in response to other errors such as missing patches or subscription closed.
      // So nothing else is likely handling the error. So prompt to reload. (this was orignally from AppSync days and may no longer be needed.)
      // However if there is an error when we're syncing to the last played game when reloading, then this could get in a loop of prompting the user to reload every time.
      if(eventData.extra.function === "syncState" && eventData.err.name !== STATE_NOT_FOUND_ERROR)
        reload = true;

      // If there was an error applying an action to the state then nothing handles that error. So prompt to reload.
      if(eventData.extra.function === "forwardAction")
        reload = true;
    }
    if(reloadMsg || reload)
      promptReload(reloadMsg);
  }
}

document.addEventListener("contextmenu", (e) => {
  e.preventDefault();
});
