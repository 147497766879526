// tslint:disable:no-console
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import { Event, EventHint } from "@sentry/types";

import { config } from "utils/Config";
import { Logger } from "utils/logger";

export class LoggerBrowser extends Logger {

  constructor(sentryDsn: string = null) {
    super(sentryDsn);
    // report errors to sentry.io in production
    if(sentryDsn) {
      Sentry.init({
        dsn: sentryDsn,
        integrations: [
          new Integrations.Dedupe(),
          new Integrations.ExtraErrorData(),
        ],
        release: process.env.SENTRY_RELEASE,
        maxBreadcrumbs: 1000,  // there appears to be a hard limit of 100
        //debug: true,

        // filter out unuseful breadcrumbs to avoid filling up our 100 breadcrumb limit, Instead include the last of each event in extra data.
        beforeBreadcrumb: (breadcrumb, hint) => {
          // don't report successfully http requests
          if((breadcrumb.category === "fetch" || breadcrumb.category === "xhr") && breadcrumb.data && breadcrumb.data.status_code === 200) {
            Sentry.configureScope((scope) => {
              scope.setExtra("lastFetch", breadcrumb);
            });
            return null;
          }

          // don't report clicks (maybe we could save the last click, then report it in )
          if(breadcrumb.category === "ui.click") {
            Sentry.configureScope((scope) => {
              scope.setExtra("lastClick", breadcrumb);
            });
            return null;
          }

          // ignore form input, it gets repeated a lot when user is filling out user feedback.
          if(breadcrumb.category === "ui.input")
            return null;

          return breadcrumb;
        },

        // sentry calls beforeSend right before it sends an event to sentry.io
        beforeSend: (event: Event, hint?: EventHint): Event => {
          // don't need to report ClientVersionError errors/warnings
          // commented out after upgrading to sentry 6.16.1 cause it got an error
          //if(event.extra && event.extra.err && event.extra.err.name && event.extra.err.name === "ClientVersionError")
            //return null;
          this.normalizeStackPaths(event); // simplifies paths in stacktrace to match sourcemap uploaded to sentry.io
          this.beforeSendObservable.notifyObservers(event); // forward on to our observers
          return event;
        },
      });

      Sentry.configureScope((scope) => {
        scope.setTag("game", config.shortName);
        scope.setTag("platform", process.env.PLATFORM);
      });
    }
    this.info("Environment", { NODE_ENV: process.env.NODE_ENV, PLATFORM: process.env.PLATFORM, STAGE: process.env.STAGE, SENTRY_RELEASE: process.env.SENTRY_RELEASE });
  }

  safeReloadBrowser() {
    // safely close sentry client to flush any pending events to sentry.io, then reload/refresh browser window
    // note there is a 2000ms timeout that will reload even if sentry is not done sending.
    let client = Sentry.getCurrentHub().getClient();
    if(client) {
      client.close(2000).then(() => {
        window.location.reload();
      });
    }
    else
      window.location.reload();
  }
}
