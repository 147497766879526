import { EventState } from "@babylonjs/core/Misc/observable";
import { Observable } from "@babylonjs/core/Misc/observable";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";

import { config } from "utils/Config";

import { game } from "components/game/Game";
import { Buttons } from "components/ui/Buttons";
import { Commands } from "components/ui/Commands";
import { disposeGuiControl, findGuiControl, zIndex } from "components/utils/GUI";

import { getRootState, ROOT_STATE_GAME, ROOT_STATE_HOME_SCREEN } from "states/RootState";

import feedbackIconUrl from "components/ui/icons/feedback.png";
import menuIconUrl from "components/ui/icons/menu.png";
import newGameIconUrl from "components/ui/icons/new_game.png";
import shareIconUrl from "components/ui/icons/share.png";
import tutorialIconUrl from "components/ui/icons/tutorial.png";

export class MenuPopUp {
  static onMenuPopUpObservable = new Observable<boolean>();

  /** Create a MenuPopUp */
  static create() {
    let status = game.rootState.status;
    let inGame = (status === ROOT_STATE_GAME);

    let height = 30 * game.getScale();

    let rectangle: Rectangle = null;

    // create a "pointer miss" layer
    // This has to be independent of the menuPopUp so UserStatus and LeaderboardSidebar will interleve properly
    let pointerMiss = new Container("menuPopUpPointerMiss");
    pointerMiss.zIndex = zIndex.ABOVE_GAME_SPACER;
    pointerMiss.isPointerBlocker = true;
    pointerMiss.onPointerDownObservable.add(() => MenuPopUp.onPointerMiss());
    game.guiTexture.addControl(pointerMiss);

    // create panel to contain buttons
    let panel = new StackPanel("menuPopUp");
    panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;

    if(config.haveMenuStatusBar) {
      panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    } else {
      panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    }

    panel.isVertical = true;
    panel.adaptWidthToChildren = true;
    panel.background = config.menuBackgroundColor;
    panel.paddingBottom = height + "px";
    panel.zIndex = zIndex.MENU; // On top of everything
    game.guiTexture.addControl(panel);

    this.createButton(panel, "closeMenuButton", menuIconUrl, "Close Menu", height, () => { MenuPopUp.toggle(); });

    // get list of menu items to show from config
    for(let item of config.menuPopUpItems) {
      // create buttons
      if(item === "end-game")
        this.createButton(panel, "endGameButton", newGameIconUrl, "End", height, () => { Commands.onEndGame(); MenuPopUp.toggle(); });
      else if(item === "leave-game" && inGame)
        this.createButton(panel, "leaveGameButton", newGameIconUrl, "Leave Game", height, () => { Commands.onConfirmLeaveGame(); MenuPopUp.toggle(); });
      else if(item === "share")
        this.createButton(panel, "shareButton", shareIconUrl, "Share", height, () => { Commands.onShareUI(); MenuPopUp.toggle(); });
      else if(item === "invite" && getRootState().status === ROOT_STATE_GAME && config.haveMultiplayer) // invite only works right while in a game
        this.createButton(panel, "inviteButton", shareIconUrl, "Invite", height, () => { Commands.onInvite({ srcName: "menu" }); MenuPopUp.toggle(); });
      else if(item === "feedback")
        this.createButton(panel, "feedbackButton", feedbackIconUrl, "Feedback", height, () => { Commands.onFeedback(); MenuPopUp.toggle(); });
      else if(item === "tutorial" && getRootState().status === ROOT_STATE_HOME_SCREEN)
        this.createButton(panel, "tutorialButton", tutorialIconUrl, "Tutorial", height, () => { Commands.onTutorialGame(); MenuPopUp.toggle(); });
    }

    // Sound Menu Item
    if(config.haveSound) {
      this.createCheckbox(panel, "soundMenuButton", "Sound", height, Boolean(getRootState().user.sound), (value) => { Commands.onEnableSound(value); });
      if(Object.keys(config.music).length > 0)
        this.createCheckbox(panel, "musicMenuButton", "Music", height, Boolean(getRootState().user.music), (value) => { Commands.onEnableMusic(value); });
    }

    // Append on our other games to link to, but not allowed on IOS
    if(getRootState().platform !== "IOS") {
      for(let gameName in config.promoGames) {
        // don't list game they're currently playing
        if(gameName !== config.shortName) {
          let promoGame = config.promoGames[gameName];
          this.createButton(panel, gameName + "Button", null, promoGame.longName, height, () => { Commands.onLaunchGame(gameName); MenuPopUp.toggle(); });
        }
      }
    }

    // add a copyright notice
    let copyright = new TextBlock("copyright");
    copyright.text = "©2020 Silver Creek Entertainment";
    copyright.color = config.menuTextColor;
    copyright.fontSize = (height * 0.4) + "px";
    copyright.width = (height * 8) + "px";
    copyright.height = (height * 0.5) + "px"; // BabylonJS 3.3 adds TextBlock.computeExpectedHeight()
    panel.addControl(copyright);

    let version = new TextBlock("version");
    version.text = "v" + process.env.VERSION;
    version.color = config.menuTextColor;
    version.fontSize = (height * 0.4) + "px";
    version.width = (height * 8) + "px";
    version.height = (height * 0.5) + "px";
    panel.addControl(version);

    // The StackPanel has padding to position it above the MenuBar
    // But isn"t smart enough to take the padding into consideration for layout
    // So this spacer compensates for the padding
    rectangle = new Rectangle("bottomspacer");
    rectangle.width = height + "px";
    rectangle.height = height + "px";
    rectangle.thickness = 0;
    panel.addControl(rectangle);

    MenuPopUp.onMenuPopUpObservable.notifyObservers(true);
  }

  static createButton(parent: Container, name: string, icon_url: string, label: string, height: number, callback: (eventData: Vector2WithInfo, eventState: EventState) => void): Button {
    let button = Buttons.CreateMenuImageButton(name, label, icon_url, height);
    button.width = (height * 8) + "px";
    button.onPointerUpObservable.add(callback);
    parent.addControl(button);
    return button;
  }

  static createCheckbox(parent: Container, name: string, label: string, height: number, value: boolean, callback: (value: boolean) => void): Button {
    let button = Buttons.CreateMenuCheckboxButton(name, label, value, height, callback);
    button.width = (height * 8) + "px";
    parent.addControl(button);
    return button;
  }

  static dispose() {
    disposeGuiControl("menuPopUpPointerMiss");

    if(disposeGuiControl("menuPopUp"))
      MenuPopUp.onMenuPopUpObservable.notifyObservers(false);
  }

  /** toggle menuPopUp on and off */
  static toggle() {
    let menuPopUp = findGuiControl("menuPopUp");
    if (menuPopUp)
      MenuPopUp.dispose();
    else
      MenuPopUp.create();
  }

  static onPointerMiss() {
    MenuPopUp.dispose();
  }
}
