// Bootstrap works through jquery
import $ from "jquery";

import { IFileRequest } from "@babylonjs/core/Misc/fileRequest";
import { Tools } from "@babylonjs/core/Misc/tools";
import { WebRequest } from "@babylonjs/core/Misc/webRequest";
import * as Mustache from "mustache";

import { ArgoSystem } from "components/game/ArgoSystem";
import { Commands } from "components/ui/Commands";

// tslint:disable:no-var-requires
let alertModalUrl = require("file-loader?esModule=false!extract-loader!html-loader!components/ui/modal-dialogs/alert-modal.html");
let okCancelModalUrl = require("file-loader?esModule=false!extract-loader!html-loader!components/ui/modal-dialogs/ok-cancel-modal.html");
let framelessHtmlModalUrl = require("file-loader?esModule=false!extract-loader!html-loader!components/ui/modal-dialogs/frameless-html-modal.html");
// tslint:enable:no-var-requires

interface IModalResponse {
  button: string;
  formData: any;
}

export class ModalDialogSystem extends ArgoSystem {
  fileRequest: IFileRequest;
  modalElement: JQuery;
  resolve: (p: any) => void;
  view: any = {};
  button: string = null;

  init() {
    Commands.onModalButtonObservable.add((button) => this.onModalButton(button));
  }

  /**
   * Show modal dialog using the HTML at the url provided
   * view is a dictionary passed to the Mustache template renderer
   * example: {title: "Title", body: "Body", ok: "Ok", cancel: "Cancel"}
   * if the view contains the key "bodyUrl", the url is loaded first and the content is passed to Mustache then set to "bodyHtml"
   * Returns a promise.  Because rejecting the promise is considered an uncaught exception if the return value is ignored,
   * the promise is always resolved with an IModalResponse
   * Normally, button will be "ok", "cancel" or "close"
   */
  showModal(url: string, view?: any) {
    if(this.modalElement || this.fileRequest) {
      this.button = "replaced";
      this.resolvePromise();
      this.cancelRequest();
      this.hideModal();
    }

    // view is optional, but Mustache actually needs an object
    if(view === undefined || view === null)
      view = {};

    this.view = view;

    // First load any bodyUrl
    if(view.bodyUrl)
      this.fileRequest = Tools.LoadFile(view.bodyUrl, (data: string) => this.onBodyLoaded(url, data), undefined, undefined, undefined, (request, exception) => this.onLoadError(request, exception));
    // Otherwise, load the modal url now
    else
      this.fileRequest = Tools.LoadFile(url, (data: string) => this.onLoaded(data), undefined, undefined, undefined, (request, exception) => this.onLoadError(request, exception));

    return new Promise<IModalResponse>((resolve, reject) => {
      this.resolve = resolve;
    });
  }

  /** Helper for standard alert dialog */
  showAlert(title: string, body: string) {
    let view = {title, body};
    return this.showModal(alertModalUrl, view);
  }

  /** Helper for standard ok/cancel dialog */
  showOkCancel(title: string, body: string, ok: string, cancel: string) {
    let view = {title, body, ok, cancel};
    return this.showModal(okCancelModalUrl, view);
  }

  /** Helper for standard frameless dialog with a custom html body, set bodyUrl in the view */
  showFramelessHtmlModal(view: any) {
    return this.showModal(framelessHtmlModalUrl, view);
  }

  onBodyLoaded(url: string, bodyData: string) {
    this.fileRequest = null;

    // Render the html with the view
    let bodyHtml = Mustache.render(bodyData, this.view);

    // Set the result in the view
    this.view.bodyHtml = bodyHtml;

    // Load the modal html
    this.fileRequest = Tools.LoadFile(url, (data: string) => this.onLoaded(data), undefined, undefined, undefined, (request, exception) => this.onLoadError(request, exception));
  }

  onLoaded(data: string) {
    this.fileRequest = null;

    let html = Mustache.render(data, this.view);

    // Support specifying a title in the given html using the class "argo-modal-title"
    this.modalElement = $(html).appendTo("#game");
    let e = this.modalElement.find(".argo-modal-title");
    if(e) {
      let argoTitle = e.html();
      e.remove();
      this.modalElement.find(".modal-title").html(argoTitle);
    }

    this.modalElement.modal({
      backdrop: "static", // Disable tap outside to close: on computer touch screens (thinkpad, chromebook), but not mobile, the dialog closes immediately without this
    });

    this.modalElement.on("hide.bs.modal", () => this.onHide());
  }

  onLoadError(request: WebRequest, exception: any) {
    this.fileRequest = null;
    this.button = "error";
    this.resolvePromise();
  }

  getFormData() {
    if(!this.modalElement)
      return {};
    return this.modalElement.find("form").serializeJSON();
  }

  onModalButton(button: string) {
    this.button = button;
  }

  resolvePromise() {
    let response = {
      button: this.button || "close",
      formData: this.getFormData(),
    };

    // If we call reject
    this.resolve(response);
  }

  onHide() {
    this.resolvePromise();
    this.disposeModal();
  }

  cancelRequest() {
    if(this.fileRequest) {
      this.fileRequest.abort();
      this.fileRequest = null;
    }
  }

  hideModal() {
    if(this.modalElement)
      this.modalElement.modal("hide");
  }

  disposeModal() {
    this.cancelRequest();

    if(this.modalElement) {
      this.modalElement.remove();
      this.modalElement = null;
      this.resolve = null;
      this.view = {};
      this.button = null;
    }
  }
}
