import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { AnimationGroup } from "@babylonjs/core/Animations/animationGroup";
import { Color4 } from "@babylonjs/core/Maths/math";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Observable } from "@babylonjs/core/Misc/observable";
import { Observer } from "@babylonjs/core/Misc/observable";
import { ParticleSystem } from "@babylonjs/core/Particles/particleSystem";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";

import { game } from "components/game/Game";
import { SoundAnimationTrigger } from "components/game/SoundSystem";
import { IAdPreloadStatus } from "components/ui/ad-system/AdParams";
import { IntegerTextBlock } from "components/ui/Controls";
import { Control3d } from "components/ui/meshes/Control3d";
import { LaunchMissile } from "components/ui/MissileControl";
import { getControlGlobalPosition, toast } from "components/utils/GUI";
import { getRootState, ROOT_STATE_HOME_SCREEN } from "states/RootState";
import { config } from "utils/Config";
import { logger } from "utils/logger";

import heartEmptyAdUrl from "components/ui/user-status/heart-empty-ad.png";
import heartEmptyUrl from "components/ui/user-status/heart-empty.png";
import heartUrl from "components/ui/user-status/heart.png";

export class MetaGamePanel extends StackPanel {
  heartSize: number;
  heartBox: Rectangle;
  scoreText: IntegerTextBlock;
  hearts: Array<{empty: Image; full: Image}> = [];
  heartAdButton: Button;
  heartParticleSystem: ParticleSystem;
  metaGameRouterId = 0;
  control3d: Control3d;
  lives = 0;
  unlockedLives = 0;
  score = 0;
  animateInAnimation: Animatable;
  animateLivesAnimation: AnimationGroup;
  animateScoreAnimation: Animatable;
  flyingHeartAnimation: Animatable;

  onAnimatedInObservable: Observable<void>;
  onHeartAdClickedObservable: Observable<void>;
  onDisposeObservable: Observable<Control>;

  preloadStatusChangedObserver: Observer<IAdPreloadStatus>;

  customAnimateScore: (from: number, to: number) => Animatable;

  constructor(name: string, hearts: number, heartSize: number, vertical: boolean) {
    super(name);

    this.onAnimatedInObservable = new Observable<void>();
    this.onHeartAdClickedObservable = new Observable<void>();
    this.onDisposeObservable = new Observable<Control>();

    this.heartSize = heartSize;
    this.isVertical = vertical;
    this.adaptWidthToChildren = vertical;
    this.adaptHeightToChildren = !vertical;

    this.lives = getRootState().user.metaGame.lives;
    this.unlockedLives = getRootState().user.metaGame.unlockedLives;
    this.score = getRootState().user.metaGame.score;

    this.heartBox = new Rectangle("MetaGamePanelHeartBox");
    this.heartBox.thickness = 0;
    this.addControl(this.heartBox);

    for(let heartIndex = 0; heartIndex < hearts; heartIndex++) {
      let empty = new Image("MetaGamePanelEmptyHeart" + heartIndex, heartEmptyUrl);
      empty.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      empty.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      this.heartBox.addControl(empty);

      let full = new Image("MetaGamePanelHeart" + heartIndex, heartUrl);
      full.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      full.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      this.heartBox.addControl(full);

      this.hearts.push({empty, full});
    }

    this.heartAdButton = Button.CreateImageOnlyButton("MetaGamePanelHeartAdButton", heartEmptyAdUrl);
    this.heartAdButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.heartAdButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.heartAdButton.thickness = 0;
    this.heartAdButton.onPointerClickObservable.add(() => this.onHeartAdClickedObservable.notifyObservers(null));
    this.heartAdButton.isVisible = false;
    this.heartBox.addControl(this.heartAdButton);

    this.scoreText = new IntegerTextBlock("MetaGamePanelScore", this.score);
    this.scoreText.color = "white";
    this.scoreText.fontFamily = config.fontFamily;
    this.scoreText.fontWeight = config.fontWeight;
    this.addControl(this.scoreText);

    this.layout();

    this.updateHeartAdButtonVisiblity();

    // Listen for ad preload changes to we can show the heartAdButton when the reward ad is preloaded
    this.preloadStatusChangedObserver = game.adSystem.preloadStatusChangedObservable.add(() => this.updateHeartAdButtonVisiblity());
  }

  setControl3d(control3d: Control3d) {
    this.control3d = control3d;
  }

  setScoreColor(color: string, shadowBlur: number = 0, shadowColor?: string) {
    this.scoreText.color = color;
    this.scoreText.shadowBlur = shadowBlur;
    this.scoreText.shadowColor = shadowColor || color;
  }

  layout() {
    // Count up visible empty hearts, in case the last heart has been disabled
    let hearts = 0;
    for(let heart of this.hearts) {
      if(heart.empty.isVisible)
        hearts += 1;
    }

    this.heartBox.width = (this.heartSize * hearts) + "px";
    this.heartBox.height = this.heartSize + "px";

    for(let heartIndex = 0; heartIndex < hearts; heartIndex++) {
      let empty = this.hearts[heartIndex].empty;
      empty.left = heartIndex * this.heartSize;
      empty.width = this.heartSize + "px";
      empty.height = this.heartSize + "px";

      let full = this.hearts[heartIndex].full;
      full.left = heartIndex * this.heartSize;
      full.width = this.heartSize + "px";
      full.height = this.heartSize + "px";
    }

    this.heartAdButton.left = 3 * this.heartSize;
    this.heartAdButton.width = this.heartSize + "px";
    this.heartAdButton.height = this.heartSize + "px";

    this.scoreText.width = (this.heartSize * 2.5) + "px";
    this.scoreText.height = this.heartSize + "px";
    this.scoreText.fontSize = (this.heartSize * 0.8) + "px";
}

  setHeartSize(heartSize: number) {
    this.heartSize = heartSize;
    this.layout();
  }

  update(lives: number, unlockedLives: number, score: number) {
    this.stopAnimations();

    this.lives = lives;
    this.unlockedLives = unlockedLives;
    this.score = score;

    for(let heartIndex = 0; heartIndex < this.hearts.length; heartIndex++) {
      this.hearts[heartIndex].full.isVisible = (heartIndex < lives);
      this.hearts[heartIndex].full.alpha = 1.0;
    }

    this.scoreText.value = score;

    this.updateHeartAdButtonVisiblity();
  }

  /** Hide elements until animateIn is called */
  prepareToAnimateIn() {
    let lives = getRootState().user.metaGame.lives;
    let unlockedLives = getRootState().user.metaGame.unlockedLives;
    let score = getRootState().user.metaGame.score;
    this.update(lives, unlockedLives, score);

    this.top = -this.heightInPixels;
    this.alpha = 0;
  }

  /** Animate the panel into view
   * Only suports the vertical layout used by the HomeScreen CardButton
   */
  animateIn() {
    if(!this.isVertical) {
      logger.warn("MetaGamePanel.animateIn does not support horizontal layout");
      this.onAnimatedIn();
      return;
    }

    this.stopAnimations();

    // Animate In
    let panelMove = new Animation("MetaGamePanelHeartAnimation", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = k0 + 10;
    keyFrames.push({frame: k0, value: -this.heightInPixels });
    keyFrames.push({frame: k1, value: 0 });
    panelMove.setKeys(keyFrames);

    let panelAlpha = new Animation("MetaGamePanelHeartFade", "alpha", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let alphaKeys = [];
    alphaKeys.push({ frame: k0, value: 0.0 });
    alphaKeys.push({ frame: k1, value: 1.0 });
    panelAlpha.setKeys(alphaKeys);

    this.animateInAnimation = game.scene.beginDirectAnimation(this, [panelMove, panelAlpha], 0, k1);

    this.animateInAnimation.onAnimationEndObservable.add(() => this.onAnimatedIn());
  }

  animateLives(from: number, to: number) {
    this.stopLivesAnimation();

    // XXX - It turns out Animatable has an appendAnimations function that may be good enough for this, so we don't need Animatable | AnimationGroup

    // This appears to be to match the flying heart animation (30 frames is the default LaunchMissile animation time)
    // and be used to delay the lose heart animation until the flying heart arrives
    // but it also delays gaining hearts
    let delay = 30;
    let animationGroup = new AnimationGroup("MetaGamePanelLivesAnimationGroup");

    for(let heartIndex = 0; heartIndex < this.hearts.length; heartIndex ++) {
      let show = heartIndex < to;
      let heart = this.hearts[heartIndex].full;
      heart.isVisible = true;

      if(show) {
        if(heartIndex < from)
          continue;

        let a = new Animation("MetaGamePanelHeartAlphaAnimation" + heartIndex, "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        let keyFrames = [];
        let k0 = delay;
        let k1 = k0 + 10;
        delay = k1;
        keyFrames.push({frame: 0, value: 0});
        keyFrames.push({frame: k0, value: 0});
        keyFrames.push({frame: k1, value: 1});
        a.setKeys(keyFrames);

        animationGroup.addTargetedAnimation(a, heart);
      } else {
        if(heartIndex >= from) {
          heart.isVisible = false;
          continue;
        }

        let a = new Animation("MetaGamePanelHeartAlphaAnimation" + heartIndex, "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        let keyFrames = [];
        let k0 = delay;
        let k1 = k0 + 15;
        let k2 = k1 + 15;
        let k3 = k2 + 15;
        let k4 = k3 + 15;
        let k5 = k4 + 15;
        //let k6 = k5 + 15;
        //let k7 = k6 + 15;
        //delay = k7;
        delay = k5;

        keyFrames.push({frame: 0, value: 1});
        keyFrames.push({frame: k0 - 1, value: 1});

        keyFrames.push({frame: k0, value: 0});
        keyFrames.push({frame: k1 - 1, value: 0});

        keyFrames.push({frame: k1, value: 1});
        keyFrames.push({frame: k2 - 1 , value: 1});

        keyFrames.push({frame: k2, value: 0});
        keyFrames.push({frame: k3 - 1, value: 0});

        keyFrames.push({frame: k3, value: 1});
        keyFrames.push({frame: k4 - 1 , value: 1});

        keyFrames.push({frame: k5, value: 0});

        /*
        keyFrames.push({frame: k4, value: 0});
        keyFrames.push({frame: k5 - 1, value: 0});

        keyFrames.push({frame: k5, value: 1});
        keyFrames.push({frame: k6 - 1 , value: 1});

        keyFrames.push({frame: k7, value: 0});
        */

        a.setKeys(keyFrames);

        animationGroup.addTargetedAnimation(a, heart);

        animationGroup.onAnimationGroupEndObservable.add(() => heart.isVisible = false);

        // Heartbeat Sound
        let heartbeatSoundTrigger = new SoundAnimationTrigger("heartbeat");
        let heartbeatSoundAnimation = new Animation("heartbeatSoundAnimation", "value", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        let heartbeatKeys = [];
        heartbeatKeys.push({ frame: 0, value: 0.0 });
        heartbeatKeys.push({ frame: k1, value: 1.0});
        heartbeatKeys.push({ frame: k3, value: 2.0});
        heartbeatSoundAnimation.setKeys(heartbeatKeys);
        animationGroup.addTargetedAnimation(heartbeatSoundAnimation, heartbeatSoundTrigger);

        // Lose Heart Sound
        let loseHeartSoundTrigger = new SoundAnimationTrigger("heartLost");
        let loseHeartSoundAnimation = new Animation("loseHeartSoundAnimation", "value", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        let loseHeartKeys = [];
        loseHeartKeys.push({ frame: 0, value: 0.0 });
        loseHeartKeys.push({ frame: k4, value: 1.0});
        loseHeartSoundAnimation.setKeys(loseHeartKeys);
        animationGroup.addTargetedAnimation(loseHeartSoundAnimation, loseHeartSoundTrigger);

        // Get center of heart in global coordinates
        let pos = getControlGlobalPosition(heart);
        let heartX = pos.x + heart.widthInPixels * 0.5;
        let heartY = pos.y + heart.heightInPixels * 0.5;
        let heartWidth = heart.widthInPixels;

        // UserStatusSystem is scaled up when animateLives is called
        // Babylon applies scale when drawing, not in layout
        // So our position is the unscaled position
        // Here we attempt to apply any parent's scale to the position
        let parent = this.parent;
        while(parent) {
          if(parent.scaleX > 1) {
            let parentPos = getControlGlobalPosition(parent);
            let offsetX = parentPos.x + parent.widthInPixels * 0.5;
            let offsetY = parentPos.y + parent.heightInPixels * 0.5;

            heartX = offsetX + (heartX - offsetX) * parent.scaleX;
            heartY = offsetY + (heartY - offsetY) * parent.scaleY;
            break;
          }

          parent = parent.parent;
        }

        // HomeScreen attaches us to a 3d object, so we need to project to 2d for the particles
        if(this.control3d) {

          let localX = heartX / this.control3d.guiWidth - 0.5;
          let localY =  (1.0 - heartY / this.control3d.guiHeight) - 0.5;
          let local3d = new Vector3(localX, localY, 0);

          this.control3d.computeWorldMatrix();
          let global3d = Vector3.TransformCoordinates(local3d, this.control3d.getWorldMatrix());
          let handPosition = game.project(global3d);

          heartX = handPosition.x;
          heartY = handPosition.y;

          // Also transform top left corner to get an on-screen size estimate
          // NOTE: Ignoring possibility of both GUI scaling and 3d
          localX = pos.x / this.control3d.guiWidth - 0.5;
          localY =  (1.0 - pos.y / this.control3d.guiHeight) - 0.5;
          local3d = new Vector3(localX, localY, 0);

          this.control3d.computeWorldMatrix();
          global3d = Vector3.TransformCoordinates(local3d, this.control3d.getWorldMatrix());
          let topLeftPosition = game.project(global3d);
          heartWidth = (heartX - topLeftPosition.x) * 2.0;
        }

        // Clear any previous particle system
        if(this.heartParticleSystem) {
          this.heartParticleSystem.dispose();
          this.heartParticleSystem = null;
        }

        let heartPos3d = game.unproject(heartX, heartY);
        let heartCorner3d = game.unproject(heartX + heartWidth * 0.5, heartY + heartWidth * 0.5);
        let range = heartCorner3d.x - heartPos3d.x;

        // Add a sort of disolved heart particle effect
        this.heartParticleSystem = game.argoParticleSystem.createOld2dParticleSystem("particles");
        let emitter = this.heartParticleSystem.emitter as AbstractMesh;
        emitter.position = heartPos3d;
        this.heartParticleSystem.minEmitBox = new Vector3(-range, 0, -range * 1.2);
        this.heartParticleSystem.maxEmitBox = new Vector3(range, 0, range * 0.5);
        this.heartParticleSystem.color1 = new Color4(1, 0, 0, 1);
        this.heartParticleSystem.color2 = new Color4(1, 0, 0, 1);
        this.heartParticleSystem.minLifeTime = 1.0;
        this.heartParticleSystem.maxLifeTime = 2.0;
        this.heartParticleSystem.gravity = new Vector3(0, 0, -10);
        this.heartParticleSystem.startDelay = k4 / 30 * 1000;
        this.heartParticleSystem.manualEmitCount = 100;
        this.heartParticleSystem.disposeOnStop = true;
        this.heartParticleSystem.start();

        //this.LaunchFlyingHeart(heartEmptyUrl, heartX, heartY, heartWidth);
      }
    }

    // If for any reason we didn't add any animations to the group, start won't do anything, onAnimationGroupEnd won't fire, and the overall animation will never advance
    if(animationGroup.targetedAnimations.length === 0)
      return;

    this.animateLivesAnimation = animationGroup.start(false, 1.0, 0, delay);

    return this.animateLivesAnimation;
  }

  animateLivesUnlocked(from: number, to: number): Animatable {
    // XXX - We could do a fade in/out here
    this.unlockedLives = to;
    this.updateHeartAdButtonVisiblity();
    return null;
  }

  defaultAnimateScore(from: number, to: number) {
    return this.scoreText.animate(from, to, 30, game.scene);
  }

  animateScore(from: number, to: number) {
    this.stopScoreAnimation();

    if(this.customAnimateScore) {
      this.animateScoreAnimation = this.customAnimateScore(from, to);
    } else {
      this.animateScoreAnimation = this.defaultAnimateScore(from, to);
    }

    return this.animateScoreAnimation;
  }

  LaunchFlyingHeart(url: string, targetX: number, targetY: number, width: number) {
    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height;

    let startX = guiWidth * 0.5;
    let startY = guiHeight * 0.5;

    // If we're on the Home Screen, start in the upper left corner
    if(getRootState().status === ROOT_STATE_HOME_SCREEN) {
      startX = 0;
      startY = 0;
    }

    let missile = LaunchMissile("MetaGamePanelFlyingHeart", {
      url,
      startX, startY,
      targetX, targetY,
      width,
      height: width,

      startScale: 1.0,
      targetScale: 1.0,

      startAlpha: 0.0,
      targetAlpha: 1.0,
    });

    this.flyingHeartAnimation = missile.animatable;
  }

  onAnimatedIn() {
    if(!this.heartBox) {
      // this.heartBox will be null if we've been disposed
      return;
    }

    this.stopAnimations();
    this.onAnimatedInObservable.notifyObservers(null);
  }

  updateHeartAdButtonVisiblity() {
    // Update the heartAdButton visibility
    let haveUnlockedLives = (this.unlockedLives > 0);
    let haveRewardAd = game.adSystem.isPreloaded("heartReward");

    let heartVisible = (haveUnlockedLives || haveRewardAd);
    let buttonVisible = (!haveUnlockedLives && haveRewardAd);

    this.hearts[3].empty.isVisible = heartVisible;
    this.heartAdButton.isVisible = buttonVisible;

    // Layout to re-center hearts if we changed the number visible
    this.layout();
  }

  stopLivesAnimation() {
    if(this.animateLivesAnimation) {
      // Disable sound triggers before we use goToFrame to advance to the end
      for(let animatable of this.animateLivesAnimation.animatables) {
        if(animatable.target instanceof SoundAnimationTrigger)
          animatable.target.configSound = null;
      }

      this.animateLivesAnimation.goToFrame(this.animateLivesAnimation.to);
      this.animateLivesAnimation.stop();
      this.animateLivesAnimation = null;
    }

    if(this.flyingHeartAnimation) {
      this.flyingHeartAnimation.goToFrame(this.flyingHeartAnimation.toFrame);
      this.flyingHeartAnimation.stop();
      this.flyingHeartAnimation = null;
    }

    // We'll also get rid of the particle system here
    if(this.heartParticleSystem) {
      this.heartParticleSystem.dispose();
      this.heartParticleSystem = null;
    }
  }

  stopScoreAnimation() {
    if(this.animateScoreAnimation) {
      this.animateScoreAnimation.goToFrame(this.animateScoreAnimation.toFrame);
      this.animateScoreAnimation.stop();
      this.animateScoreAnimation = null;
    }
  }

  stopAnimations() {
    if(this.animateInAnimation) {
      this.animateInAnimation.goToFrame(this.animateInAnimation.toFrame);
      this.animateInAnimation.stop();
      this.animateInAnimation = null;
    }

    this.stopLivesAnimation();
    this.stopScoreAnimation();
  }

  clearObservables() {
    this.onAnimatedInObservable.clear();
    this.onHeartAdClickedObservable.clear();
    this.onDisposeObservable.clear();
  }

  dispose() {
    this.stopAnimations();

    this.heartBox = null;
    this.scoreText = null;
    this.hearts.length = 0;

    this.control3d = null;

    if(this.preloadStatusChangedObserver) {
      game.adSystem.preloadStatusChangedObservable.remove(this.preloadStatusChangedObserver);
      this.preloadStatusChangedObserver = null;
    }

    super.dispose();

    this.onDisposeObservable.notifyObservers(null);

    this.clearObservables();
  }
}
