/** Interfaces for ArgoAdProvider */

export enum ArgoAdActionName {
  PURCHASE_OFFER = "purchaseOffer",
}

export interface IArgoAdAction {
  action: ArgoAdActionName;
  offerName?: string; // offerName for PURCHASE_OFFER
}

export interface IArgoAd {
  contentUrl: string;
  paymentsRequired?: boolean;
  buttonActions?: {[index: string]: IArgoAdAction}; // The action handler is expected to call the IAdParams onDoneCallback
}

export interface IArgoAdMap {
  [index: string]: IArgoAd;
}
