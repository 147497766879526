import { getSnapshot } from "mobx-state-tree";

import { ArgoSystem } from "components/game/ArgoSystem";
import { Commands } from "components/ui/Commands";
import { GAME_STATE_GAME_OVER, GAME_STATE_PASS, GAME_STATE_PRE_START } from "states/game/GameState";
import { ROOT_STATE_GAME, safeApplySnapshot } from "states/RootState";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";

import { config } from "utils/Config";
import { decrypt, encrypt } from "utils/Crypto";
import { logger } from "utils/logger";

/** OfflineSystem handles playing multiplayer games with 1 player in them offline, and then reporting results back to server. */
export class OfflineSystem extends ArgoSystem {
  resumingGame: boolean = false; // This is only true while we load the snapshot from storage.

  // we're adding our routes in createGUI() instead of init() so that applySnapshotStage will be called after Game.applySnapshotStage
  createGUI() {
    this.rootState.router.addRoute("^\/game\/applySnapshotStage$", (patch: any, reversePatch: any, params: any) => this.onApplySnapshotStage(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/offline$", (patch: any, reversePatch: any, params: any) => this.onGameOfflineChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/players\/(\\d*)$", (patch: any, reversePatch: any, params: any) => this.onPlayersChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/seatsTurn$", (patch: any, reversePatch: any, params: any) => this.onSeatsTurnChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.onGameStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/round$", (patch: any, reversePatch: any, params: any) => this.onRoundChanged(patch, reversePatch, params));
  }

  onApplySnapshotStage(patch: any, reversePatch: any, params: any) {
    if(!this.game.gameState.offline)
      return;

    if(patch.value === APPLY_SNAPSHOT_STAGE_DONE) {
      // if we're the creator of an offline game, then likely we just loaded the state from the server. So now resume from our offline saved state
      // This effectively loads the same game a second time, the first from the server with no pieces, the second from storage with pieces
      if(this.game.gameState.isPlayerCreator(this.rootState.user.id) && !this.resumingGame) {
        this.resumeOfflineGame(this.game.gameState.id).catch((err) => {
          // if game fails to load for any reason start it now
          if(this.game.gameState.status === GAME_STATE_PRE_START) {
            try {
              logger.info("Failed to resume offline game. Starting Now", { err, status: this.game.gameState.status });
              this.game.gameState.startGame();
            }
            catch(startErr) {
              // if we failed to start a game, return to home screen
              logger.info("Failed to start offline game. Returning to home screen.", { err, startErr, status: this.game.gameState.status });
              Commands.onHomeScreen();
            }
          }
          else {
            // failed to load offline game, and it wasn't in GAME_STATE_PRE_START, so return to home screen
            logger.info("Failed to resume offline game. Returning to home screen.", { err, status: this.game.gameState.status });
            Commands.onHomeScreen();
          }

        });
      }
    }
  }

  onGameStatusChanged(patch: any, reversePatch: any, params: any) {
    if(this.game.gameState.offline && this.game.gameState.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE) {
      // report offline games to server when game is over
      if(patch.value === GAME_STATE_GAME_OVER)
        this.reportOfflineGame();
      else if(patch.value === GAME_STATE_PASS)
        this.saveOfflineGame();
    }
  }

  onPlayersChanged(patch: any, reversePatch: any, params: any) {
    // if there are now other players in our offline game, report the offline game to the server, and go back online
    if(this.game.gameState.offline && this.game.gameState.getHumanPlayerCnt() > 1 && this.game.gameState.isPlayerCreator(this.rootState.user.id)) {
      this.reportOfflineGame();
    }
  }

  onRoundChanged(patch: any, reversePatch: any, params: any) {
    // In doTrickWon the round over sequence is setStatus, scoreRound, ..., round += 1, so we save when the round is advanced in order to save the scores for the round
    if(this.game.gameState.offline && this.game.gameState.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE) {
      this.saveOfflineGame();
    }
  }

  onGameOfflineChanged(patch: any, reversePatch: any, params: any) {
    if(!this.game.gameState.id)
      return;

    // server is signalling us to go offline, ie a multiplayer game with 1 player in it.
    if(patch.value) {
      let localSeat = this.game.gameState.getPlayerSeat(this.rootState.user.id);
      if(localSeat) {
        this.game.gameState.initSeed(); // server never sends seed, so generate our own
        if(this.game.botFactory)
          this.game.botFactory.setEnabled(true); // creates ais for bots
        if(this.game.gameStateSyncMiddleware)
          this.game.gameStateSyncMiddleware.setEnabled(false); // turn off state sync middleware to go offline

        // go ahead and start game now. offline should only be set true in respone to requestStartGame, so it should always be
        // safe to startGame now, as long as it's not during applying a snapshot
        if(this.game.gameState.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE) {
          this.game.gameState.startGame();
        }
      }
    }
    else { // offline is false
      // If a game that was offline is now going back online, then rejoin the game.
      // The original player rejoins to be sure they're gameState is in sync with the server
      // The player joining that trigged the game to go online rejoins to get a seat
      if(this.game.gameState.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE)
        this.game.joinGame(this.game.gameState.id);
    }
  }

  onSeatsTurnChanged(patch: any, reversePatch: any, params: any) {
    if(patch.value === this.game.localSeat && this.game.gameState.offline && this.game.gameState.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE) {
      this.saveOfflineGame();
    }
  }

  /** generate an encryption key for a gameId   */
  genKeyData(gameId: string) {
    // Generate Key Data to be hashed into an encryption key for saving and loading offline games
    // Using the gameId combined with a salt value
    const SALT = "CFWbU7bnbwfJ2s4R7dsXxQ==";
    return gameId + SALT;
  }

  async saveOfflineGame(): Promise<boolean> {
    // save game to local storage when it is our turn
    let snapshot = getSnapshot(this.game.gameState);
    // remove applySnapshotStage from snapshot, by copying everything from snapshot to preppedSnapshot, except applySnapshotStage
    const {
      applySnapshotStage,
      // tslint:disable-next-line:trailing-comma
      ...preppedSnapshot
    } = snapshot;

    // Encrypt the game for obfuscation
    let keyData = this.genKeyData(this.game.gameState.id);
    let jsonSnapshot = JSON.stringify(preppedSnapshot);
    let encryptedGame = await encrypt(jsonSnapshot, keyData);

    // Save the encrypted game to local storage
    return config.storage.setItem("curGame", encryptedGame);
  }

  /** Called to resume an offline game. Checks if there is a snapshot of the game with the same id in storage.
   * Some storage classes may take some time, so return a Promise.
   */
  async resumeOfflineGame(id: string): Promise<void> {
    let keyData = this.genKeyData(id);

    let encryptedGame = await config.storage.getItem("curGame");
    if(!encryptedGame)
      throw new Error("Failed to load offline game - not found.");

    if(typeof encryptedGame !== "string")
      throw new Error("Failed to load offline game - old format.");

    let decryptedGame = await decrypt(encryptedGame, keyData);
    let curGameSnapshot = JSON.parse(decryptedGame);

    // We found a game, so load it up.
    this.rootState.setStatus(ROOT_STATE_GAME);

    if(!curGameSnapshot.id || curGameSnapshot.id !== id)
      throw new Error("Failed to load offline game - wrong id.");

    this.resumingGame = true;
    safeApplySnapshot(this.game.gameState, curGameSnapshot);
    this.resumingGame = false;
    logger.info("OfflineSystem.resumeOfflineGame Loaded Snapshot", { id: this.game.gameState.id, status: this.game.gameState.status });

    // if an offline game hasn't started yet, start it now.
    if(this.game.gameState.status === GAME_STATE_PRE_START) {
      this.game.gameState.startGame();
    }
    // if state is in GAME_STATE_ROUND_OVER then TrickGameState.setApplySnapshotStage checks for that and goes to game over or next round
  }

  /** Go back online and report game results to server */
  reportOfflineGame() {
    if(!this.game.gameState.offline)
      return;

    logger.info("Reporting offline game", { id: this.game.gameState.id, status: this.game.gameState.status });
    // be sure debug auto play is off, otherwise when we applyQueuedActions ignoring pause, it will zoom through the rest of the game.
    let debugAutoPlayLocalSeat = this.game.debugAutoPlayLocalSeat;
    if(debugAutoPlayLocalSeat)
      this.game.setAutoPlayLocalSeat(false);

    // force apply any outstanding actions before sending to server. If we don't then the game might be in a stuck state
    this.game.gameState.applyQueuedActions(true);

    // turn bots back off.
    if(this.game.botFactory)
      this.game.botFactory.setEnabled(false);

    // enable state sync middle ware to go back online
    if(this.game.gameStateSyncMiddleware)
      this.game.gameStateSyncMiddleware.setEnabled(true);

    // get snapshot that will contain result of game and send to server
    let snapshot = this.game.gameState.getSyncToServerSnapshot();
    this.game.gameState.applySyncToServerSnapshot(this.rootState.user.id, snapshot);

    // remove game from local storage so that it isn't accidentally reported multiple times
    // ideally we should wait to remove until we verify it was reported successfully
    config.storage.removeItem("curGame");

    // if auto play was on, turn it back on
    if(debugAutoPlayLocalSeat)
      this.game.setAutoPlayLocalSeat(true);
  }
}
