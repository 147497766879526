import { types } from "mobx-state-tree";

import { config } from "utils/Config";

import { PatchQueueState } from "states/PatchQueueState";
import { STATE_RESET_ERROR } from "states/state-sync/errors";
import { HelpState } from "states/user/HelpState";
import { InventoryState } from "states/user/InventoryState";
import { MetaGameState } from "states/user/MetaGameState";
import { XPState } from "states/user/XPState";

import { logger } from "utils/logger";
import { PaymentsState } from "./PaymentsState";

export const BID_DISPLAY_MODE_METER = "meter";
export const BID_DISPLAY_MODE_TEXT = "text";
export type BidDisplayMode = typeof BID_DISPLAY_MODE_METER  | typeof BID_DISPLAY_MODE_TEXT;

// tslint:disable-next-line:variable-name
const UserState = PatchQueueState
  .props({
    id: types.optional(types.string, "0"),
    stateName: types.optional(types.string, "UserState"),
    name: types.optional(types.string, "Me"),
    imageUrl: types.maybeNull(types.string),

    gameId: types.maybeNull(types.string), // id of the last/current game they're playing, used to resume it

    // Preferences
    bidDisplayMode: types.optional(types.enumeration("BidDisplayMode", [BID_DISPLAY_MODE_METER, BID_DISPLAY_MODE_TEXT]), BID_DISPLAY_MODE_METER),
    sound : types.optional(types.number, 1),  // currently sound is either 0 off or 1 on, but I made it a number so it could be a volume between 0 and 1 in the future
    music: types.optional(types.number, 1),

    metaGame: types.optional(MetaGameState, {}),
    xp: types.optional(XPState, {}),
    help: types.optional(HelpState, {}),  // keeps track of which help items the user has seen
    inventory: types.optional(InventoryState, {}),
    payments: types.optional(PaymentsState, {}),
  })
  .actions((self) => {
    function setId(id: string) {
      // setId can get called multiple times on facebook, so if we've already setId, don't call findOrCreateState again with same id
      if(self.id === id)
        return;

      self.id = id;

      // as soon as the id is set then load the rest of the User from server, or create if it doesn't exist yet
      if(config.autoGetUserState) {
        self.findOrCreateState({id: self.id, name: self.name, imageUrl: self.imageUrl, clientName: config.clientName }).catch((err) => {
          if(err.name !== STATE_RESET_ERROR)
            logger.info("error calling UserState.findOrCreateState", { id: self.id, name: self.name, err });
        });
      }
    }

    // Actions forwarded to server. The first param must be userId of the currently signed in user
    // Our server verifies that userId matches the currently signed in user to be sure a user is only
    // requesting to modify their own UserState
    function setImageUrl(userId: string, url: string) {
      if(userId === self.id)
        self.imageUrl = url;
    }

    function setGameId(userId: string, id: string) {
      if(userId === self.id)
        self.gameId = id;
    }

    function setName(userId: string, name: string) {
      if(userId === self.id)
        self.name = name;
    }

    /** Sets both name and image in one action as an optimization when signing in from facebook instant game for the first time. */
    function setProfile(userId: string, name: string, imageUrl: string) {
      if(userId === self.id) {
        self.name = name;
        self.imageUrl = imageUrl;
      }
    }

    // Spades Preferences
    function setBidDisplayMode(userId: string, mode: BidDisplayMode) {
      if(userId === self.id)
        self.bidDisplayMode = mode;
    }

    function toggleBidDisplayMode(userId: string) {
      if(self.bidDisplayMode === BID_DISPLAY_MODE_METER)
        setBidDisplayMode(userId, BID_DISPLAY_MODE_TEXT);
      else if(self.bidDisplayMode === BID_DISPLAY_MODE_TEXT)
        setBidDisplayMode(userId, BID_DISPLAY_MODE_METER);
    }

    /** volume is currently 0 to disable, 1 to enable. */
    function setSound(userId: string, volume: number) {
      if(userId === self.id)
        self.sound = volume;
    }

    /** volume is currently 0 to disable, 1 to enable. */
    function setMusic(userId: string, volume: number) {
      if(userId === self.id)
        self.music = volume;
    }

    /** leaveGame is basically an optimization to combine all the actions done to the UserState when leaving/finishing a game into one.
     * This is called both when a user leaves a game early, and when the game is over
     */
    function leaveGame(userId: string, metaLoseLife: boolean, metaAddToScore: number, xpEarned: number) {
      setGameId(userId, null);

      if(metaAddToScore)
        self.metaGame.addToScore(userId, metaAddToScore);

      if(xpEarned)
        self.xp.earnXP(xpEarned);

      // Lose Life Last because it may cause the meta game to end
      if(metaLoseLife)
        self.metaGame.loseLife(userId);
    }

    return { leaveGame, setGameId, setImageUrl, setId, setName, setProfile, setBidDisplayMode, setSound, setMusic, toggleBidDisplayMode };
  })
  .views((self) => {
    return { };
  });

// UserState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IUserState = typeof UserState.Type;

export { UserState, IUserState };
