import { Container } from "@babylonjs/gui/2D/controls/container";
import { game } from "components/game/Game";

/**
 * Base class for full screen GUI pop ups
 * Adds and removes the screen from ScreenSystem,
 * which ensures the game and other screens are hidden and shown appropriately
 */
export class Screen extends Container {
  disposed = false;

  constructor(name: string) {
    super(name);
    game.screenSystem.pushScreen(this);
  }

  /** ScreenSystem will call layout when the view is resized */
  layout() {
  }

  dispose() {
    this.disposed = true;

    super.dispose();
    game.screenSystem.removeScreen(this);
  }
}
