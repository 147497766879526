import { getRootState } from "states/RootState";

/** BotFactory auto creates and manages list of ais for seats with bots in them. */
export class BotFactory {
  aiClassType: any; // AI class type to create, ie SpadesGameAI
  gameState: any; // the gameState to pass to ai, it has to be an any because spades needs at least a ITrickGameState
  ais: any[] = []; // list of ai class instances
  enabled: boolean = true; // on client we get disabled for online games, and enabled for offline and standAlone.

  constructor(aiClassType: any, gameState: any) {
    this.aiClassType = aiClassType;
    this.gameState = gameState;

    // listen for any possible changes to bots in seats
    getRootState().router.addRoute("^\/game\/seats\/(\\d*)$", (patch: any, reversePatch: any, params: any) => this.updateAis());
    getRootState().router.addRoute("^\/game\/seats\/(\\d*)\/player$", (patch: any, reversePatch: any, params: any) => this.updateAis());
    getRootState().router.addRoute("^\/game\/players\/(\\d*)$", (patch: any, reversePatch: any, params: any) => this.updateAis());

    this.updateAis();
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled;
    this.updateAis();
  }

  /** Checks to make sure there is an ai for every bot. */
  updateAis() {
    // Go through all seats and make sure bots have ais, and other seats do not.
    this.gameState.seats.forEach((seat: any) => {
      let aiIndex = this.ais.findIndex((ai) => ai.seat === seat);
      let bot = seat.player && seat.player.bot === true && this.enabled;
      if(bot && aiIndex === -1) {
        // Create an ai
        this.ais.push(new this.aiClassType(seat.id, this.gameState));
      }
      else if(!bot && aiIndex !== -1) {
        // no longer need an ai for this seat
        this.ais[aiIndex].stop(); // stop the ai from listening for it's turn to play
        delete this.ais[aiIndex]; // delete ai immediatly, I'm not sure if this is needed
        this.ais.splice(aiIndex, 1); // remove from array
      }
    });
  }
}
