import { GameAnalytics } from "gameanalytics";

import { IAnalytics } from "components/utils/IAnalytics";
import { config } from "utils/Config";

/** Report game events to GameAnalytics.com */
export class GameAnalyticsCom implements IAnalytics {
  constructor(gameKey: string, secretKey: string) {
    // Turn on for testing GameAnalytics
    //GameAnalytics.setEnabledInfoLog(true);
    //GameAnalytics.setEnabledVerboseLog(true);

    GameAnalytics.configureBuild(process.env.SENTRY_RELEASE);
    GameAnalytics.initialize(gameKey, secretKey);
  }

  setUserId(userId: string) {
    GameAnalytics.configureUserId(userId);
  }

  logEvent(eventName: string, value: number = 0, parameters: any = null): Error {
    /*
      GameAnalytics.com supports 5 different types of events:
        Business 	In-App Purchases supporting receipt validation on GA servers.
        Resource 	Managing the flow of virtual currencies – like gems or lives.
        Progression 	Level attempts with Start, Fail & Complete event.
        Error 	Submit exception stack traces or custom error messages.
        Design 	Submit custom event id’s. Useful for tracking metrics specifically needed for your game.
    */
    GameAnalytics.addDesignEvent(eventName, value);
    return null;
  }
}
