import { config } from "utils/Config";

/** get the level tier color for the given level */
export function getXPLevelTierColor(level: number) {
  let color = "white";

  if(!level || !config.levelTierColors)
    return color;

  for(let tierColor of config.levelTierColors) {
    if(tierColor.level > level)
      return color;
    color = tierColor.color;
  }

  return color;
}
