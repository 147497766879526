import { Animatable } from "@babylonjs/core/Animations/animatable";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Observable } from "@babylonjs/core/Misc/observable";

import { ArgoSystem } from "components/game/ArgoSystem";
import { GAME_STATE_AUTO_FINISH, GAME_STATE_DEAL, GAME_STATE_RESET } from "states/game/GameState";

import { logger } from "utils/logger";

export const ANIMATION_MOVE_PIECE = "movePiece";
export const ANIMATION_DEAL = "deal";
export const ANIMATION_GAME_OVER = "gameOver";

// Interface for animation builders, which can be registered with AnimationSystem.registerAnimation
// The builder:
//    1) Accepts a mesh
//    2) Generates an animation for the mesh
//    3) Calls AnimationSystem.begin to start the animation
//    4) Maybe does something itself with the Animatable
//    5) Returns the Animatable returned from begin
type IBuildAnimation = (mesh: AbstractMesh) => Animatable;

export class AnimationSystem extends ArgoSystem {
  animations: {[index: string]: IBuildAnimation} = {
  };

  animationsBlockingPlay: number = 0;
  animationsBlockingRoundEnd: number = 0;

  // Notifications are sent when there is a change to either blocking play or blocking round end
  // If the blocking changed due to an Animatable finishing, the Animatable is passed as the parameter, else it will be null
  onAnimationBlockingObservable = new Observable<Animatable>();

  // Meshes waiting for previous animations to complete
  queue: AbstractMesh[] = [];
  wait = false;

  debugFastMode = false;

  // Animation Registry
  registerAnimation(name: string, build: IBuildAnimation) {
    this.animations[name] = build;
  }

  buildAnimation(name: string, mesh: AbstractMesh): Animatable {
    //console.log("buildAnimation " + name + " " + mesh.metadata.layoutGameStatus + " " + mesh.name + " from " + mesh.metadata.fromParentName + " " + mesh.absolutePosition + " to " + mesh.parent.name + " " + mesh.metadata.layoutPosition);
    //console.log("buildAnimation " + name + " " + mesh.name + " from " + mesh.metadata.fromParentName + " to " + mesh.parent.name);

    // Stop any existing animations on this mesh
    // XXX - Sometimes we want to preserve the position when doing this
    mesh.getScene().stopAnimation(mesh);
    mesh.animations = [];

    // Most animations don't change visibility, so reset visibility to the layoutVisibility
    mesh.visibility = mesh.metadata.layoutVisibility;

    // Get builder for this animation
    let buildFunction: IBuildAnimation = this.animations[name];
    if(buildFunction === undefined) {
      logger.warn("Animation not found!", { name });
      this.game.cardSystem.applyLayout(mesh);
      return;
    }

    // Build and start the animation, returning the controlling Animatable
    return buildFunction(mesh);
  }

  /** Add a piece mesh or array of piece meshes to be animated to their current layout from the metadata */
  queueMeshes(meshOrMeshArray: AbstractMesh | AbstractMesh[]) {
    if(meshOrMeshArray instanceof AbstractMesh) {
      this.queueMesh(meshOrMeshArray);
      return;
    }

    for(let mesh of meshOrMeshArray) {
      /*
      let moved = !mesh.position.equalsWithEpsilon(mesh.metadata.layoutPosition);
      let rotated = !mesh.rotationQuaternion.equals(mesh.metadata.layoutRotationQuaternion);
      if(moved || rotated)*/
      this.queueMesh(mesh);
    }
  }

  queueMesh(mesh: AbstractMesh) {
    // Here we add to a list of meshes to be animated
    // We'll delay processing until the end of the frame to be
    // sure all changes have been made
    let moved = !mesh.position.equalsWithEpsilon(mesh.metadata.layoutPosition);
    let rotated = !mesh.rotationQuaternion.equals(mesh.metadata.layoutRotationQuaternion);
    if(!moved && !rotated)
      return;

    if(this.queue.length) {
      // When there'a move and a flip together, we get the mesh twice in a row
      // so check the end of the queue first
      let lastMesh = this.queue[this.queue.length - 1];
      if(lastMesh.name === mesh.name)
          return;

      for(let queuedMesh of this.queue) {
        if(queuedMesh.name === mesh.name) {
          // The mesh is already in the list, but has apparently been layed out again
          // we'll delete the previous entry then re-add it at the end

          // XXX - it appears that if this does happen (apart from the already-at-end case above)
          //       we'll want to both applyLayout and computeWorldMatrix BEFORE animate is called
          //       it would be too late at time point because the card will have been reparented

          // XXX - Now that Pile.layout calls animate on all of it's cards that need layout
          //       we need to preserve the original order, so if a piece has previously been
          //       added, leave it as is and return to prevent a duplicate

          //this.queue.splice(i, 1);
          return;
        }
      }
    }

    this.queue.push(mesh);
  }

  /** Stop all animations on the given target
   * This is a replacement for scene.stopAnimation, which calls goToFrame before stop
   */
  stopAnimationsForTarget(target: any) {
    for(let animatable of this.game.scene.getAllAnimatablesByTarget(target)) {
      animatable.goToFrame(animatable.toFrame);
      animatable.stop();
    }
  }

  /** Stop all piece animations */
  stopAll()
  {
    let scene = this.game.scene;

    // Clear queues
    this.queue.length = 0;
    this.wait = false;

    let meshes = scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Card");
    for (let mesh of meshes) {
      // Stop all animations for this piece
      this.stopAnimationsForTarget(mesh);

      // And also be sure the layout is applied
      this.game.cardSystem.applyLayout(mesh);
    }

    // Always send an animations done notfication
    this.onAnimationBlockingObservable.notifyObservers(null);
  }

  begin(mesh: AbstractMesh, from: number, to: number, loop: boolean, speedRatio: number): Animatable {
    if(this.debugFastMode)
      speedRatio = 4;

    mesh.metadata.fromParentName = ""; // XXX - I forget why this is here? Probably something to do with flipping cards in klondike
    let animatable = this.game.scene.beginAnimation(mesh, from, to, loop, speedRatio, () => mesh.animations.length = 0);
    this.blockPlayUntilAnimationCompletes(animatable);

    return animatable;
  }

  blockPlayUntilAnimationCompletes(animatable: Animatable) {
    this.animationsBlockingPlay += 1;
    animatable.onAnimationEndObservable.add(() => {
      this.animationsBlockingPlay -= 1;
      // Queued animations may also be blocking play
      if(!this.isBlockingPlay())
        this.notifyBlockingChanged(animatable);
    });
  }

  blockRoundEndUntilAnimationCompletes(animatable: Animatable) {
    this.animationsBlockingRoundEnd += 1;
    animatable.onAnimationEndObservable.add(() => {
      this.animationsBlockingRoundEnd -= 1;
      if(!this.isBlockingRoundEnd())
        this.notifyBlockingChanged(animatable);
    });
  }

  animateDeal() {
    this.game.soundSystem.play("animateDeal");

    // After network optimizations, only cards dealt from the stock show up in the queue during the deal state
    // To get every card to animate, we'll ignore the queue and animate every card

    // Ye old scheme - animate the entire queue
    /*
    while(this.queue.length) {
      let mesh = this.queue.shift();
      this.buildAnimation(ANIMATION_DEAL, mesh);
    }
    */

    // Clear queues
    this.queue.length = 0;
    this.wait = false;

    // Animate every card
    let cards = this.game.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Card");
    for(let card of cards)
      this.buildAnimation(ANIMATION_DEAL, card);
  }

  animateSolitaireGameOver(meshes: AbstractMesh[]) {
    let index = 0;
    while(meshes.length) {
      let mesh = meshes.shift();
      this.buildAnimation(ANIMATION_GAME_OVER, mesh);
    }
  }

  /**
   * Returns true if AnimationSystem is currently blocking play from continuing
   */
  isBlockingPlay(): boolean {
    return this.queue.length > 0 || this.animationsBlockingPlay > 0;
  }

  /**
   * Returns true if AnimationSystem is currently blocking the current game round from ending
   */
  isBlockingRoundEnd(): boolean {
    // If play is blocked, Round End is also blocked
    if(this.isBlockingPlay())
      return true;

    return this.animationsBlockingRoundEnd > 0;
  }

  notifyBlockingChanged(animatable: Animatable) {
    this.onAnimationBlockingObservable.notifyObservers(animatable);
  }

  processQueue() {
    // If we transition for isAnimating to not isAnimating, we'll fire off an animationsDone event, otherwise it will be missed because no animations will be finishing
    let wasAnimating = this.isBlockingPlay();

    // Workaround a glitch if you autoplay a card with a face down card under it, then pick up a card right away
    // What happens is the pick up doesn't animate until both the autplay and autoflip complete
    // So what we want to do is process any animation to the drag pile right away
    if(this.wait) {
      for(let i = 0; i < this.queue.length; i++) {
        if(this.queue[i].parent.name === "drag") {
          let mesh = this.queue.splice(i, 1)[0];
          this.buildAnimation(ANIMATION_MOVE_PIECE, mesh);
        }
      }
    }

    while(this.queue.length) {
      let mesh = this.queue[0];
      let status = mesh.metadata.layoutGameStatus;

      // Don't animate reset
      if(status === GAME_STATE_RESET) {
        this.queue.shift();
        this.game.cardSystem.applyLayout(mesh);
        continue;
      }

      // Custom Deal Animation
      if(status === GAME_STATE_DEAL) {
        this.animateDeal();
        break;
      }

      // XXX - This was always hacky and is getting worse
      let flipInPlace = !mesh.metadata.fromParentName && !mesh.rotationQuaternion.equals(mesh.metadata.layoutRotationQuaternion) && mesh.parent.metadata.layout === "down";

      // If this is a flip in place, we need to wait to perform the flip
      if(flipInPlace)
        this.wait = true;

      // When auto finishing, animate one play at a time
      if(status === GAME_STATE_AUTO_FINISH)
        this.wait = true;

      if(this.wait) {
        if(this.animationsBlockingPlay)
            break;
        else
          this.wait = false;
      }

      // If this is a flip in place, we need to wait for the flip to complete
      if(flipInPlace)
        this.wait = true;

      this.queue.shift();

      this.buildAnimation(ANIMATION_MOVE_PIECE, mesh);
    }

    if(wasAnimating && !this.isBlockingPlay()) {
      // We transitioned from isAnimating to !isAnimating, so fire off a done event
      this.onAnimationBlockingObservable.notifyObservers(null);
    }
  }

  newFrame(deltaTime: number) {
    this.processQueue();
  }
}
