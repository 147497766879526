import { types } from "mobx-state-tree";

import { OptionsState } from "states/game/OptionsState";

// tslint:disable-next-line:variable-name
const TrickGameOptionsState = OptionsState
   .props({
    //partners: types.optional(types.boolean, false),
  });

// TrickGameOptionsState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type ITrickGameOptionsState = typeof TrickGameOptionsState.Type;

export { TrickGameOptionsState, ITrickGameOptionsState };
