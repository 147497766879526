import { PointerInfoBase } from "@babylonjs/core/Events/pointerEvents";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";

/**
 * Extends BABYLON GUI Rectangle to capture the mouse using internal capture support copied from BABYLON GUI Slider
 */
export class RectangleWithPointerCapture extends Rectangle {
  private _pointerIsDown = false;

  constructor(public name?: string) {
    super(name);

    this.isPointerBlocker = true;
  }

  public _onPointerDown(target: Control, coordinates: Vector2, pointerId: number, buttonIndex: number, pi: PointerInfoBase): boolean {
    if (!super._onPointerDown(target, coordinates, pointerId, buttonIndex, pi)) {
        return false;
    }

    this._pointerIsDown = true;

    this._host._capturingControl[pointerId] = this;

    return true;
  }

  public _onPointerUp(target: Control, coordinates: Vector2, pointerId: number, buttonIndex: number, notifyClick: boolean): void {
    this._pointerIsDown = false;

    delete this._host._capturingControl[pointerId];

    super._onPointerUp(target, coordinates, pointerId, buttonIndex, notifyClick);
  }
}
