import { TutorialSystem } from "components/game/TutorialSystem";
import { GAME_STATE_BID, GAME_STATE_PASS, GAME_STATE_PLAY } from "states/game/GameState";
import { HEARTS, QUEEN, SPADES } from "states/game/PieceState";
import { ISeatState } from "states/game/SeatState";
import { ITrickGameState } from "states/game/TrickGameState";

/** Show tutorial help messages for trick games. */
export class TrickTutorialSystem extends TutorialSystem {
  tutorialRound = 1; // round number we're on. we don't use gameState.rounds because we'll want the tutorial to span multiple quick play games.
  gameState: ITrickGameState;

  init() {
    super.init();
    this.gameState = this.rootState.game as ITrickGameState;

    this.rootState.router.addRoute("^\/game\/round$", (patch: any, reversePatch: any, params: any) => this.onRoundChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/tricks\/(\\d*)\/seatWinner$", (patch: any, reversePatch: any, params: any) => this.onSeatWonTrick(patch, reversePatch, params));
  }

  resetTutorial() {
    super.resetTutorial();
    this.tutorialRound = 1;
  }

  onRoundChanged(patch: any, reversePatch: any, params: any) {
    if(patch.value > 0 && patch.value > reversePatch.value)
      this.tutorialRound += 1;
  }

  /** Most help messages are shown when it's the local seats turn. */
  onSeatsTurnChanged(patch: any, reversePatch: any, params: any) {
    super.onSeatsTurnChanged(patch, reversePatch, params);

    this.showTurnHelp();
  }

  /** Shows help needed on your turn */
  showTurnHelp() {
    super.showTurnHelp();

    if(!this.enabled || (!this.gameState.seatsTurn && this.gameState.status !== GAME_STATE_PASS))
      return;

    let helpKey = null;
    let extra: any = {}; // extra variables that get merged with the template message, ie {{extra.leadSuit}}
    let seatsTurn = null;
    if(this.gameState.seatsTurn)
      seatsTurn = this.gameState.seatsTurn.id;
    let localSeatTurn = (this.game.localSeat === seatsTurn);

    if(this.gameState.status === GAME_STATE_PASS) {
      if(this.tutorialRound === 1) {
        helpKey = "tutorial_firstPass";
      }
      else if(this.tutorialRound === 2)
        helpKey = "tutorial_secondPass";
    }
    else if(localSeatTurn) {
      // It's the local seats turn, display a different help message depending on the situation in the game.
      let localHand = this.gameState.getPile("hand" + this.game.localSeat);
      if(this.gameState.status === GAME_STATE_BID) {
        if(this.tutorialRound === 1) {
          helpKey = "tutorial_firstBid";
        }
        else if(this.tutorialRound === 2)
          helpKey = "tutorial_secondBid";
      }
      else if(this.gameState.status === GAME_STATE_PLAY) {
        // check if this is the first trick of a new hand
        if(this.gameState.tricks.length === 1) {
          if(this.tutorialRound === 1)
            helpKey = "tutorial_firstHandPlay";
          else if(this.tutorialRound === 2)
            helpKey = "tutorial_secondHandPlay";
        }
        else {

          // check if we should show help about point cards, ie Hearts or Queen of Spades in Hearts
          if(this.willShowHelp("tutorial_firstQueenOfSpades") || this.willShowHelp("tutorial_firstHearts")) {
            let trick = this.gameState.getPile("trick");
            trick.pieces.forEach((piece) => {
              if(piece.suit() === HEARTS || (piece.suit() === SPADES && piece.rank() === QUEEN)) {
                if(piece.suit() === HEARTS)
                  helpKey = "tutorial_firstHearts";
                else
                  helpKey = "tutorial_firstQueenOfSpades";

                extra.playerName = piece.seat.player.name;
                extra.pieceName = piece.valueLongName();
                extra.piecePoints = this.gameState.getPiecePoints(piece);
              }
            });
          }

          // If we aren't showing help about point cards, check for leading or following help
          if(!helpKey || !this.willShowHelp(helpKey)) {
            let leadPiece = this.gameState.getLeadPiece();
            if(leadPiece) { // there's already a lead piece, so we're following suit
              extra.leadSuit = leadPiece.suitName();
              if(localHand.hasSuit(leadPiece.suit()))
                helpKey = "tutorial_followingSuit";
              else
                helpKey = "tutorial_notFollowingSuit";
            }
            else { // no lead piece, so we're leading
              if(this.gameState.protectedSuitBroken)
                helpKey = "tutorial_protectedSuitBroken";
              else
                helpKey = "tutorial_leadProtectedSuitNotBroken";
            }
          }
        }
      }
    }

    this.maybeShowHelp(helpKey, extra);
  }

  /** Show a bit of help the first time you, your partner, or opponents win a trick */
  onSeatWonTrick(patch: any, reversePatch: any, params: any) {
    if(!this.enabled || !this.game.localSeat)
      return;

    const seat = this.gameState.getSeat(this.game.localSeat);
    const seatWonTrick = this.gameState.getSeat(patch.value);
    let team = this.gameState.getTeamForSeat(this.game.localSeat);
    let partnerSeat: ISeatState;
    if(team)
      partnerSeat = team.getPartnerSeat(this.game.localSeat);

    let leadPiece = this.gameState.getLeadPiece();
    let winningPiece = this.gameState.getTrickWinningPiece();

    let helpKey: string;
    let extra: any = {
      winPiece: winningPiece.valueLongName(true),
      playerWonTrick: seatWonTrick.player.name,
    };

    if(partnerSeat)
      extra.partnerName = partnerSeat.player.name;

    // if the local seat is the winner, change name to You
    if(this.game.localSeat === seatWonTrick.id)
      extra.playerWonTrick = "You";

    let trick = this.gameState.getPile("trick");
    trick.pieces.forEach((piece) => {
      extra.piecePoints = this.gameState.getPiecePoints(piece);
    });

    // Help keys about winning a trick
    if(this.game.localSeat === seatWonTrick.id && seat.bid === 0 && seat.tricksWon) // player failed nil
      helpKey = "tutorial_playerFailedNil";
    else if(partnerSeat && partnerSeat.id === seatWonTrick.id && partnerSeat.bid === 0 && partnerSeat.tricksWon) // partner failed nil
      helpKey = "tutorial_partnerFailedNil";
    else if(leadPiece.suit() !== winningPiece.suit()) // if the winning piece suit doesn't match the lead, then it was trumped with a spade
      helpKey = "tutorial_wonTrickTrump";
    else if(this.game.localSeat === seatWonTrick.id)   // local player won trick
      helpKey = "tutorial_playerWonTrick";
    else if(partnerSeat && partnerSeat === seatWonTrick)  // partner won trick
      helpKey = "tutorial_partnerWonTrick";
    else // otherwise an opponent won
      helpKey = "tutorial_opponentWonTrick";

    const helpShown = this.maybeShowHelp(helpKey, extra);

    // if we're not showing help about winning a trick, go on to show help about making your bid and scoring
    if(!helpShown) {
      helpKey = null;
      const wonTricks = this.gameState.getWonTricksForSeat(this.game.localSeat);
      if(wonTricks) {
        extra.bid = seat.bid;
        extra.tricksWon = seat.tricksWon;
        extra.teamTricksWon = seat.tricksWon;

        let teamBid = seat.bid;
        if(partnerSeat) {
          extra.partnerBid = partnerSeat.bid;
          extra.partnerTricksWon = partnerSeat.tricksWon;
          extra.teamTricksWon += partnerSeat.tricksWon;
          teamBid += partnerSeat.bid;
        }

        extra.teamPoints = teamBid * 10; // not using wonTricks.points, because I want to show only the points for making bid, not including bags, bag penalty or nil
        extra.teamBid = teamBid;

        if(extra.teamTricksWon > extra.teamBid && this.tutorialRound === 2)
          helpKey = "tutorial_secondHandTookBag";
        else if(wonTricks.made && !wonTricks.nilFailed) // means you and partner made bid
          helpKey = "tutorial_teamMadeBid";
        else if(seat.bid > 0 && seat.tricksWon >= seat.bid && !wonTricks.nilFailed)
          helpKey = "tutorial_playerMadeBid";
        else if(partnerSeat && partnerSeat.bid > 0 && partnerSeat.tricksWon >= partnerSeat.bid && !wonTricks.nilFailed)
          helpKey = "tutorial_partnerMadeBid";

        this.maybeShowHelp(helpKey, extra);
      }
    }
  }
}
