import { IStorage } from "components/utils/IStorage";
import { logger } from "utils/logger";

/** This is for storing data in a users browser local storage */
export class BrowserStorage implements IStorage {
  setItem(key: string, value: any): Promise<boolean> {
    const valueJSON = JSON.stringify(value);
    // JSON.stringify(undefined) will return undefined
    // localStorage.setItem(key, undefined), will write the string "undefined" to localStorage
    if(valueJSON === undefined)
      return Promise.resolve(false);
    return new Promise<any> ((resolve, reject) => {
      localStorage.setItem(key, valueJSON);
      resolve(true);
    });
  }

  getItem(key: string): Promise<any> {
    return new Promise<any> ((resolve, reject) => {
      // Bizarrely, JSON.parse will convert an undefined input to the string "undefined" and try to parse that
      // Also localStorage.setItem(key, undefined), will write the string "undefined" to localStorage
      const valueJSON = localStorage.getItem(key);
      if(valueJSON === null || valueJSON === undefined || valueJSON === "undefined")
        return resolve(null);
      const value = JSON.parse(valueJSON);
      resolve(value);
    });
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
