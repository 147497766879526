import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { ElasticEase } from "@babylonjs/core/Animations/easing";
import { Color3 } from "@babylonjs/core/Maths/math";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Ellipse } from "@babylonjs/gui/2D/controls/ellipse";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { RadioButton } from "@babylonjs/gui/2D/controls/radioButton";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";
import { ArgoSystem } from "components/game/ArgoSystem";
import { PlayerSystem } from "components/game/PlayerSystem";
import { TrickGame } from "components/game/TrickGame";
import { Commands } from "components/ui/Commands";
import { Capsule } from "components/ui/Controls";
import { RectangleWithPointerCapture } from "components/ui/controls/RectangleWithPointerCapture";
import { findGuiControl, getControlGlobalPosition, toast, zIndex } from "components/utils/GUI";
import { GUIAnimationProxy } from "components/utils/GUIAnimationProxy";
import { GAME_STATE_BID } from "states/game/GameState";
import { ITrickGameState } from "states/game/TrickGameState";
import { config } from "utils/Config";

import bid_panel_bg_url from "components/game/bid-system/bid-panel-bg.png";
import bid_panel_transparent_edge_url from "components/game/bid-system/bid-panel-transparent-edge.png";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";

export class BidSystem extends ArgoSystem {
  grabbed = false;
  grabX = 0;
  grabY = 0;
  sliderX = 0;
  pageWidth = 0;
  selectedBid: number = null;
  bidPanelBgImage: HTMLImageElement = null;
  bidPanelTransparentEdgeImage: HTMLImageElement = null;
  animation: Animatable;

  init() {
    this.rootState.router.addRoute("^\/game\/seatsTurn$", (patch: any, reversePatch: any, params: any) => this.onSeatsTurnChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.onGameStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/applySnapshotStage$", (patch: any, reversePatch: any, params: any) => this.onApplySnapshotStage(patch, reversePatch, params));

    this.game.onShowGameObservable.add((show) => this.onShowGame(show));
  }

  queueAssets(): void {
    let bgImageTask = this.game.assetsManager.addImageTask("bid_panel_bg_task", bid_panel_bg_url);
    bgImageTask.onSuccess = (task) => {
      this.bidPanelBgImage = task.image;
    };

    let transparentEdgeImageTask = this.game.assetsManager.addImageTask("bid_panel_transparent_edge_task", bid_panel_transparent_edge_url);
    transparentEdgeImageTask.onSuccess = (task) => {
      this.bidPanelTransparentEdgeImage = task.image;
    };
  }

  onApplySnapshotStage(patch: any, reversePatch: any, params: any) {
    // after a game snapshot is done being applied, check to see if it is the local seats turn to bid
    if(patch.value === APPLY_SNAPSHOT_STAGE_DONE && this.rootState.game.status === GAME_STATE_BID && this.game.localSeat === this.rootState.game.seatsTurn.id)
      this.create();
  }

  /**
   * Display the BidBox if the local player needs to place their bid
   */
  onSeatsTurnChanged(patch: any, reversePatch: any, params: any) {
    // if seat is changed to local seat and we're in the bidding state, create bid panel
    let seatsTurn = patch.value; // patch value is the id of the seat, not an ISeatState
    if(this.rootState.game.status === GAME_STATE_BID) {
      if(this.game.localSeat === seatsTurn)
        this.create();
      else
        this.dispose(); // This can happen if your turn times out and the server auto-bids for you
    }
  }

  /**
   * Be sure to close the BidBox if the game leaves the bid state (new game, for example)
   */
  onGameStatusChanged(patch: any, reversePatch: any, params: any) {
    if (patch.value !== GAME_STATE_BID)
      this.dispose();
  }

  create() {
    // Make sure we're starting fresh
    this.dispose();
    this.grabbed = false;
    this.selectedBid = null;

    // BidBox is the top level container
    let box = new Rectangle("BidBox");
    box.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //box.background = "magenta";
    box.thickness = 0;
    box.zIndex = zIndex.GAME_UI;
    box.isVisible = this.game.showingGame;
    this.game.guiTexture.addControl(box);

    // The box background image
    let bg = new Image("BidSystemBG");
    bg.domImage = this.bidPanelBgImage;
    bg.isHitTestVisible = false;
    bg.isPointerBlocker = false;
    box.addControl(bg);

    // Container for the pages
    let pageSet = new RectangleWithPointerCapture("BidPageSet");
    pageSet.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    pageSet.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //pageSet.background = "#faebd7";
    pageSet.thickness = 0;
    box.addControl(pageSet);

    pageSet.onPointerDownObservable.add((e) => this.onPointerDown(e));
    pageSet.onPointerUpObservable.add((e) => this.onPointerUp(e));
    pageSet.onPointerMoveObservable.add((e) => this.onPointerMove(e));

    // The slider holds the pages in the BidPageSet, this is what moves when changing the page
    let slider = new Rectangle("BidSlider");
    slider.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    slider.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //slider.background = "yellow";
    slider.thickness = 0;
    pageSet.addControl(slider);

    // The BidBar sits at the bottom and holds the page indicator buttons
    let bar = new Rectangle("BidBar");
    bar.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    bar.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //bar.background = "magenta";
    bar.thickness = 0;
    box.addControl(bar);

    // The selector sits behind the bid pages to highlight the current selection
    let selector = new Capsule("BidSystemSelector");
    selector.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    selector.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    selector.left = 0;
    selector.top = -10000;
    selector.color = "white";
    selector.background = "#4bb1f9";
    selector.thickness = 4;
    slider.addControl(selector);

    // Page 0 (Bids 1-9 and NIL)
    let page0 = new Rectangle("BidPage0");
    page0.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    page0.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //page0.background = "red";
    page0.thickness = 0;
    slider.addControl(page0);

    // Page 1 (Bids 10-13)
    let page1 = new Rectangle("BidPage1");
    page1.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    page1.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //page1.background = "green";
    page1.thickness = 0;
    slider.addControl(page1);

    // Foreground overlay to soften edge of pages while moving
    let fg = new Image("BidSystemFG");
    fg.domImage = this.bidPanelTransparentEdgeImage;
    fg.isHitTestVisible = false;
    fg.isPointerBlocker = false;
    box.addControl(fg);

    // Page0 Bid Buttons (1-9)
    for (let bid = 1; bid <= 9; bid++) {
      this.createBidButton(page0, bid);
    }

    // Page0 NIL Button
    this.createBidButton(page0, 0);

    // Page1 Bid Buttons (10-13)
    for (let bid = 10; bid <= 13; bid++) {
      this.createBidButton(page1, bid);
    }

    // Bid submit button
    let bidButton = Button.CreateSimpleButton("BidSystemBidButton", "BID");
    bidButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    bidButton.background = "#1e435d";
    bidButton.color = "white";
    bidButton.thickness = 0;
    bidButton.onPointerClickObservable.add(() => this.placeBid());
    // changing Button.fontSize doesn't carry over to the child textBlock, but setting it directly means we also have to set fontFamil and fontWeight directly
    let bidButton_text = findGuiControl("BidSystemBidButton_button", bidButton) as Button;
    bidButton_text.fontFamily = config.fontFamily;
    bidButton_text.fontWeight = config.fontWeight;
    box.addControl(bidButton);

    // Page0 indicator button
    let page0Button = new RadioButton("BidSystemPage0Button");
    page0Button.group = "BidSystemPage";
    page0Button.thickness = 0.0001; // 0 is ignored
    page0Button.checkSizeRatio = 1.0;
    page0Button.color = "white";
    page0Button.background = "#90bcca";
    page0Button.onPointerClickObservable.add(() => this.gotoPage(0));
    bar.addControl(page0Button);

    // Page1 indicator button
    let page1Button = new RadioButton("BidSystemPage1Button");
    page1Button.group = "BidSystemPage";
    page1Button.thickness = 0.0001; // 0 is ignored
    page1Button.checkSizeRatio = 1.0;
    page1Button.color = "white";
    page1Button.background = "#90bcca";
    page1Button.onPointerClickObservable.add(() => this.gotoPage(1));
    bar.addControl(page1Button);

    // info/help button that is an i in a circle
    let infoButton = new Button("BidInfoButton");
    infoButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    infoButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    infoButton.thickness = 0;
    box.addControl(infoButton);

    let infoButtonEllipse = new Ellipse("BidInfoButtonEllipse");
    infoButton.addControl(infoButtonEllipse);

    let infoButtonText = new TextBlock("BidInfoButtonText");
    infoButtonText.fontFamily = config.fontFamily;
    infoButtonText.fontWeight = config.fontWeight;
    infoButtonText.text = "?";
    infoButtonText.color = "white";
    infoButton.addControl(infoButtonText);
    infoButton.onPointerClickObservable.add(() => Commands.onHelp({page: "bid"}));

    // Initial Layout
    this.layout();

    // Select the initial bid
    if(this.selectedBid !== null)
      this.selectBid(this.selectedBid);

    // Goto Page0
    this.gotoPage(0);

    let playerSystem = this.game.systems.get("PlayerSystem") as PlayerSystem;
    if(playerSystem)
      playerSystem.onBidBox(true);

    this.animateIn();
  }

  layout() {
    let box = findGuiControl("BidBox");
    if(!box)
      return;

    // Get an approximate position of the top of the south hand
    let southHandTop = (this.game as TrickGame).getSouthHandProjectedTopPosition();

    let guiHeight = this.game.guiTexture.getSize().height;

    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;
    let bidSystemHeight = guiHeight - menuBarHeight;

    // Measurements from the images

    // Overall image dimensions
    let boxImageWidth = 442;
    let boxImageHeight = 655;

    // Interior area, excluding the corners, where the box contents will be placed
    let boxInteriorX0 = 65;
    let boxInteriorX1 = 376;
    let boxInteriorY0 = 22;
    let boxInteriorY1 = 546;

    // Page size and position relative to boxInterior
    let pageInteriorX0 = 14;
    let pageInteriorY0 = 15;
    let pageInteriorX1 = 297;
    let pageInteriorY1 = 380;

    // Secondary positions derived from the measurements

    // There's basically 5 full rows and 1 half row
    // So the boxHeight is divided into 11 slices, with full rows taking 2
    // The pageHeight is 4 rows so 8/11 of the boxHeight

    // The total height we want is the lesser of the scaled image height, 3/4 of the GUI and the distance from the top of the screen to the top of the south hand
    let totalHeight = Math.min(boxImageHeight * this.game.getScale(), guiHeight * 0.75, southHandTop.y);
    let scale = (totalHeight / boxImageHeight);

    let boxWidth = boxImageWidth * scale;
    let boxHeight = boxImageHeight * scale;

    let boxInteriorLeft = boxInteriorX0 * scale;
    let boxInteriorTop = boxInteriorY0 * scale;
    let boxInteriorWidth = (boxInteriorX1 - boxInteriorX0) * scale;
    let boxInteriorHeight = (boxInteriorY1 - boxInteriorY0) * scale;

    let pageWidth = boxInteriorWidth;
    let pageHeight = boxInteriorHeight * 8 / 11;

    let barHeight = boxInteriorHeight / 11;

    let pageInteriorLeft = pageInteriorX0 * scale;
    let pageInteriorTop = pageInteriorY0 * scale;
    let pageInteriorWidth = (pageInteriorX1 - pageInteriorX0) * scale;
    let pageInteriorHeight = (pageInteriorY1 - pageInteriorY0) * scale;

    let height = pageInteriorHeight / 4;

    // Save the pageWidth for sliding between pages
    this.pageWidth = pageWidth;

    // BidBox is the top level container
    box.top = (bidSystemHeight - boxInteriorHeight) * 0.125 - boxInteriorTop;
    box.width = boxWidth + "px";
    box.height = boxHeight + "px";

    // The box background image
    let bg = findGuiControl("BidSystemBG", box);
    bg.width = boxWidth + "px";
    bg.height = boxHeight + "px";

    // Container for the pages
    let pageSet = findGuiControl("BidPageSet", box);
    pageSet.left = boxInteriorLeft;
    pageSet.top = boxInteriorTop;
    pageSet.width = pageWidth + "px";
    pageSet.height = pageHeight + "px";

    // The slider holds the pages in the BidPageSet, this is what moves when changing the page
    let slider = findGuiControl("BidSlider", box);
    slider.width = (pageWidth * 2) + "px";
    slider.height = pageHeight + "px";

    // The BidBar sits at the bottom and holds the page indicator buttons
    let bar = findGuiControl("BidBar", box);
    bar.left = boxInteriorLeft;
    bar.top = boxInteriorTop + pageHeight + height;
    bar.width = pageWidth + "px";
    bar.height = barHeight + "px";

    // The selector sits behind the bid pages to highlight the current selection
    let selector = findGuiControl("BidSystemSelector", box);
    selector.left = 0;
    selector.top = -10000;
    selector.width = height + "px";
    selector.height = height + "px";

    // Page 0 (Bids 1-9 and NIL)
    let page0 = findGuiControl("BidPage0", box) as Rectangle;
    page0.left = pageInteriorLeft;
    page0.top = pageInteriorTop;
    page0.width = pageInteriorWidth + "px";
    page0.height = pageInteriorHeight + "px";

    // Page 1 (Bids 10-13)
    let page1 = findGuiControl("BidPage1", box) as Rectangle;
    page1.left = pageInteriorLeft + pageWidth;
    page1.top = pageInteriorTop;
    page1.width = pageInteriorWidth + "px";
    page1.height = pageInteriorHeight + "px";

    // Foreground overlay to soften edge of pages while moving
    let fg = findGuiControl("BidSystemFG");
    fg.width = boxWidth + "px";
    fg.height = boxHeight + "px";

    let offsetX = (pageInteriorWidth - height * 3) * 0.5;

    // Page0 Bid Buttons (1-9)
    for (let bid = 1; bid <= 9; bid++) {
      let x = ((bid - 1) % 3) * height + offsetX;
      let y = Math.floor((bid - 1) / 3) * height;
      this.layoutBidButton(page0, bid, x, y, height);
    }

    // Page0 NIL Button
    this.layoutBidButton(page0, 0, offsetX, 3 * height, height);

    // Page1 Bid Buttons (10-13)
    for (let bid = 10; bid <= 13; bid++) {
      let x = ((bid - 10) % 3) * height + offsetX;
      let y = Math.floor((bid - 10) / 3) * height;
      this.layoutBidButton(page1, bid, x, y, height);
    }

    // Bid submit button
    let bidButtonHeight = height * 0.5;
    let bidButton = findGuiControl("BidSystemBidButton", box) as Button;
    bidButton.top = boxInteriorTop + pageHeight + height * 0.25;
    bidButton.width = (height * 2.5) + "px";
    bidButton.height = bidButtonHeight + "px";
    bidButton.cornerRadius = bidButtonHeight;
    let bidButton_text = findGuiControl("BidSystemBidButton_button", bidButton) as Button; // changing Button.fontSize doesn't carry over to the child textBlock
    bidButton_text.fontSize = (bidButtonHeight * 4 / 5) + "px";

    // Paging buttons
    let pageButtonHeight = barHeight * 0.5;

    // Page0 indicator button
    let page0Button = findGuiControl("BidSystemPage0Button", box);
    page0Button.left = pageButtonHeight * -0.6;
    page0Button.width = pageButtonHeight + "px";
    page0Button.height = pageButtonHeight + "px";

    // Page1 indicator button
    let page1Button = findGuiControl("BidSystemPage1Button", box);
    page1Button.left = pageButtonHeight * 0.6;
    page1Button.width = pageButtonHeight + "px";
    page1Button.height = pageButtonHeight + "px";

    // info/help button
    let infoButtonHeight = bidButtonHeight * 0.75;
    let infoButton = findGuiControl("BidInfoButton", box);
    infoButton.width = infoButtonHeight + "px";
    infoButton.height = infoButtonHeight + "px";
    infoButton.left = (boxInteriorLeft + boxInteriorWidth) - infoButtonHeight;
    infoButton.top = boxInteriorTop;

    let infoButtonText = findGuiControl("BidInfoButtonText", box);
    infoButtonText.fontSize = (infoButtonHeight * 4 / 5) + "px";
  }

  /**
   * Dispose the Bid Screen
   */
  dispose() {
    let bidBox = findGuiControl("BidBox");
    if(bidBox) {
      // Notify playerSystem before disposing the BidBox
      let playerSystem = this.game.systems.get("PlayerSystem") as PlayerSystem;
      if(playerSystem)
        playerSystem.onBidBox(false);

      bidBox.dispose();
    }
  }

  /**
   * Helper for creating the bid buttons
   */
  createBidButton(parent: Container, bid: number): Button {
    let label = bid ? "" + bid : "NIL";
    let button = Button.CreateSimpleButton("bid" + bid, label);
    button.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    button.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    button.color = "white";
    button.thickness = 0;

    // Somehow, changing button.fontSize in layout doesn't work, so we have to change it directly on the contained textBlock
    // Doing that, however, means the textBlock doesn't inherit the fontFamily and fontWeight anymore, so we need to set those directly here as well
    let button_textBlock = findGuiControl("bid" + bid + "_button", button) as TextBlock;
    button_textBlock.fontFamily = config.fontFamily;
    button_textBlock.fontWeight = config.fontWeight;

    let proxy = new GUIAnimationProxy(button);

    button.pointerEnterAnimation = () => {
      proxy.animateColor("color", proxy.color, Color3.FromHexString("#1e435d"), 0.2, this.game.scene);
    };

    button.pointerOutAnimation = () => {
      proxy.animateColor("color", proxy.color, new Color3(1, 1, 1), 0.2, this.game.scene);
    };

    // Somehow onPointerDown triggers a PointerOut event, which prevents the PointerClick event
    // So instead we'll select the bid onPointerDown

    button.onPointerDownObservable.add(() => {
      // on iOS, and in the Firefox touch simulation, BABYLON fails to recognize pointerOut after a pointerDown
      // As a workaround, we'll peform the pointerOut animation onPointerDown
      proxy.animateColor("color", proxy.color, new Color3(1, 1, 1), 0.2, this.game.scene);
      this.selectBid(bid);
    });
    parent.addControl(button);
    return button;
  }

  layoutBidButton(parent: Container, bid: number, x: number, y: number, height: number) {
    let width = bid ? height : height * 3;
    let button = findGuiControl("bid" + bid, parent) as Button;
    let button_textBlock = findGuiControl("bid" + bid + "_button", button) as TextBlock;
    button.left = x;
    button.top = y;
    button.width = width + "px";
    button.height = height + "px";
    button_textBlock.fontSize = (height * 4 / 5) + "px"; // setting button.fontSize here doesn't change the contained textBlock
  }

  /**
   * Highlight the current page using the circle buttons
   */
  markPage(page: number) {
    let pageButton = findGuiControl("BidSystemPage" + page + "Button") as RadioButton;
    if(pageButton)
      pageButton.isChecked = true;
  }

  /**
   * Stop all BidSystem animations
   */
  stopAnimations() {
    let slider = findGuiControl("BidSlider");
    if(!slider)
      return;

    let animateable = this.game.scene.getAnimatableByTarget(slider);
    if(!animateable)
      return;

    // Animatable.stop: "stop and delete the current animation"
    animateable.stop();
  }

  /**
   * Animate to the given page number
   */
  gotoPage(page: number) {
    // Stop any animations
    this.stopAnimations();

    // Update the page maker
    this.markPage(page);

    // Get the slider
    let slider = findGuiControl("BidSlider");
    if(!slider)
      return;

    // See where we ended up
    let x = 0;

    // Snap to a page
    if(page === 1)
      x = -this.pageWidth;

    // Animate to the page
    let a = new Animation("BidSystemSlider", "left", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k = 3;
    keyFrames.push({frame: 0, value: slider.leftInPixels});
    keyFrames.push({frame: k, value: x});
    a.setKeys(keyFrames);
    return this.game.scene.beginDirectAnimation(slider, [a], 0, k, false);
  }

  /**
   * Highlight the selected bid button and store the bid value
   */
  selectBid(bid: number) {
    this.selectedBid = bid;

    let selector = findGuiControl("BidSystemSelector");
    let bidButton = findGuiControl("bid" + bid);
    if(!selector || !bidButton)
      return;

    let w = bidButton.widthInPixels;
    let h = bidButton.heightInPixels;
    let x = bidButton.parent.leftInPixels + bidButton.leftInPixels;
    let y = bidButton.parent.topInPixels + bidButton.topInPixels;

    selector.left = x;
    selector.top = y;
    selector.width = w + "px";
    selector.height = h + "px";

    // Make sure we're on the right page if the bid was set from outside
    if(bid < 10)
      this.gotoPage(0);
    else
      this.gotoPage(1);
  }

  /**
   * Submit the currently selected bid and close the box
   */
  placeBid() {
    if(this.selectedBid === null) {
      toast("SelectBidToast", "Select your bid.");
      return;
    }

    let trickGameState = this.rootState.game as ITrickGameState;
    // currently bidding for whoever's turn it currently is
    trickGameState.setBid(this.rootState.user.id, trickGameState.seatsTurn.id, this.selectedBid);

    let animatable = this.animateOut();
    if(animatable) {
      animatable.onAnimationEndObservable.add(() => {
        this.dispose();
        this.game.finishedTurn = true;
      });
    } else {
      this.dispose();
      this.game.finishedTurn = true;
    }
  }

  highlightBidHint(bid: number) {
    // Select the bid
    this.selectBid(bid);

    // Find the button
    let bidButton = findGuiControl("bid" + bid);
    if(!bidButton)
      return;

    let pos = getControlGlobalPosition(bidButton);
    let centerX = pos.x + bidButton.widthInPixels * 0.5;
    let centerY = pos.y + bidButton.heightInPixels * 0.5;
    let pos3d = this.game.unproject(centerX, centerY);

    let particleSystem = this.game.argoParticleSystem.createOld2dParticleSystem("DebugParticlesFuseParticleSystem");
    (particleSystem.emitter as AbstractMesh).position = pos3d;
    particleSystem.minEmitPower = 1;
    particleSystem.maxEmitPower = 2;
    particleSystem.minLifeTime = 0.25;
    particleSystem.maxLifeTime = 0.75;
    particleSystem.disposeOnStop = true;
    particleSystem.manualEmitCount = 40;
    particleSystem.start();
  }

  /** Highlight a bid hinted by the AI */
  setBidHint(bid: number) {
    // First make sure we're on the right page
    this.gotoPage(bid < 10 ? 0 : 1).onAnimationEndObservable.add(() => {

      // If we get the button position now, it's wrong, so wait 1 frame
      this.game.scene.onAfterRenderObservable.addOnce((scene) => this.highlightBidHint(bid));
    });
  }

  /**
   * Handle pointer down for BidPageSet
   */
  onPointerDown(eventData: Vector2WithInfo) {
    // Left button only
    if(eventData.buttonIndex !== 0)
      return;

    // Find the slider
    let slider = findGuiControl("BidSlider");
    if(!slider)
      return;

    // If the slider is animating, stop it
    this.stopAnimations();

    // Start an emulated mouse capture
    this.grabbed = true;
    this.grabX = eventData.x;
    this.grabY = eventData.y;
    this.sliderX = slider.leftInPixels;
  }

  /**
   * Handle pointer up for BidPageSet
   */
  onPointerUp(eventData: Vector2WithInfo) {
    if(eventData.buttonIndex !== 0 || !this.grabbed)
      return;

    // Let go
    this.grabbed = false;

    let slider = findGuiControl("BidSlider");
    if(!slider)
      return;

    // See where we ended up
    let x = slider.leftInPixels;

    // Snap to a page
    let page = 0;
    let threshold = this.pageWidth * -0.5;
    if(x < threshold)
      page = 1;

    this.gotoPage(page);
  }

  /**
   * Handle pointer move for BidPageSet
   */
  onPointerMove(eventData: Vector2) {
    // Ignore if we haven't grabbed the mouse
    if(!this.grabbed)
      return;

    let slider = findGuiControl("BidSlider");
    if(!slider)
      return;

    // Calculate the new position of the slider
    let x = this.sliderX + eventData.x - this.grabX;

    // Keep it in bounds
    x = Math.max(x, -this.pageWidth);
    x = Math.min(x, 0);

    // Move the slider
    slider.left = x;

    // Mark the page
    let page = 0;
    let threshold = this.pageWidth * -0.5;
    if(x < threshold)
      page = 1;
    this.markPage(page);
  }

  onShowGame(show: boolean) {
    let bidBox = findGuiControl("BidBox");
    if(bidBox)
      bidBox.isVisible = show;
  }

  animateIn() {
    let box = findGuiControl("BidBox");
    if(!box)
      return null;

    let from = -box.heightInPixels;
    let to = box.topInPixels;

    box.top = from;

    let a = new Animation("BidBoxAnimateIn", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 7;
    let k1 = k0 + 45;
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    a.setKeys(keyFrames);

    // Elasic easing overshoots, then springs back
    let easingMode = EasingFunction.EASINGMODE_EASEOUT;
    let easingFunction = new ElasticEase(2, 8);
    easingFunction.setEasingMode(easingMode);
    a.setEasingFunction(easingFunction);

    this.animation = this.game.scene.beginDirectAnimation(box, [a], 0, k1, false);

    return this.animation;
  }

  animateOut() {
    let box = findGuiControl("BidBox");
    if(!box)
      return null;

    let from = box.topInPixels;
    let to = -box.heightInPixels;

    box.top = from;

    let a = new Animation("BidBoxAnimateIn", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 7;
    let k1 = k0 + 45;
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    a.setKeys(keyFrames);

    // Elasic easing overshoots, then springs back
    let easingMode = EasingFunction.EASINGMODE_EASEOUT;
    let easingFunction = new ElasticEase(2, 8);
    easingFunction.setEasingMode(easingMode);
    a.setEasingFunction(easingFunction);

    this.animation = this.game.scene.beginDirectAnimation(box, [a], 0, k1, false);

    return this.animation;
  }

  stopAnimation() {
    if(this.animation) {
      this.animation.stop();
      this.animation = null;
    }
  }

  /**
   * Handle screen resize if the bid screen is currently up
   */
  resized(): void {
    this.stopAnimation();
    this.layout();
  }
}
