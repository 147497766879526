import { Vector2 } from "@babylonjs/core/Maths/math";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";
import { RectangleWithPointerCapture } from "components/ui/controls/RectangleWithPointerCapture";

/**
 * Minimal ScrollBox control
 * BABYLON GUI adaptWidth/HeightToChildren literally only looks at width & height and sizes to match the largest child regardless of positions
 * Because of that it's best to add one container object to the scroll box and manage smaller objects inside of that
 */
export class ScrollBox extends Rectangle {
  slider: RectangleWithPointerCapture;

  grabbed: boolean;
  grabPointerX: number;
  grabPointerY: number;
  grabContentsX: number;
  grabContentsY: number;

  constructor(name: string) {
    super(name);

    //this.background = "magenta";
    this.thickness = 0;
    this.isPointerBlocker = true;

    this.slider = new RectangleWithPointerCapture(name + "Slider");
    this.slider.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.slider.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.slider.adaptWidthToChildren = true;
    this.slider.adaptHeightToChildren = true;
    //this.slider.background = "yellow";
    this.slider.thickness = 0;
    this.slider.isPointerBlocker = true;
    super.addControl(this.slider);

    this.slider.onPointerDownObservable.add((e) => this.onPointerDown(e));
    this.slider.onPointerUpObservable.add((e) => this.onPointerUp(e));
    this.slider.onPointerMoveObservable.add((e) => this.onPointerMove(e));
  }

  get contentWidthInPixels() {
    if(this.slider.children.length) {
      let content = this.slider.children[0];
      return content.widthInPixels;
    }

    return 0;
  }

  get contentHeightInPixels() {
    if(this.slider.children.length) {
      let content = this.slider.children[0];
      return content.heightInPixels;
    }

    return 0;
  }

  setScrollPosition(x: number, y: number) {
    let minX = Math.min(this.widthInPixels - this.contentWidthInPixels, 0);
    let minY = Math.min(this.heightInPixels - this.contentHeightInPixels, 0);

    x = Math.max(Math.min(x, 0), minX);
    y = Math.max(Math.min(y, 0), minY);

    this.slider.left = x;
    this.slider.top = y;
  }

  centerOnPoint(pointX: number, pointY: number) {
    let x = this.widthInPixels * 0.5 - pointX;
    let y = this.heightInPixels * 0.5 - pointY;
    this.setScrollPosition(x, y);
  }

  /**
   * Override addControl to add to contents instead
   */
  addControl(control: Control): Container {
    this.slider.addControl(control);
    return this;
  }

  onPointerDown(eventData: Vector2WithInfo) {
    // Left button only
    if(eventData.buttonIndex !== 0)
      return;

    // Start an emulated mouse capture
    this.grabbed = true;
    this.grabPointerX = eventData.x;
    this.grabPointerY = eventData.y;
    this.grabContentsX = this.slider.leftInPixels;
    this.grabContentsY = this.slider.topInPixels;
  }

  onPointerUp(eventData: Vector2WithInfo) {
    if(eventData.buttonIndex !== 0 || !this.grabbed)
      return;

    this.grabbed = false;
  }

  onPointerMove(eventData: Vector2) {
    if(!this.grabbed)
      return;

    let x = this.grabContentsX + eventData.x - this.grabPointerX;
    let y = this.grabContentsY + eventData.y - this.grabPointerY;
    this.setScrollPosition(x, y);
  }

  dispose() {
    super.dispose();
    this.slider = null;
  }
}
