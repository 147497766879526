import { Button } from "@babylonjs/gui/2D/controls/button";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";

import { game } from "components/game/Game";

import { PopUp } from "components/ui/controls/PopUp";
import { findGuiControl, zIndex } from "components/utils/GUI";

import { config } from "utils/Config";

import passButtonImageUrl from "components/ui/ad-system/ads/offer-life-pop-up/offer-life-pass-button.png";
import watchButtonImageUrl from "components/ui/ad-system/ads/offer-life-pop-up/offer-life-watch-ad-button.png";
import closeIconUrl from "components/ui/modal-dialogs/modal-close.png";

/** PopUp box for when the meta game goes to zero lives
 * Gives the player two options for continuing: watch a reward ad or purchase an InstaPass
 * The box currently just uses PopUp.disposeReason to return "close", "watchAd" or "purchasePass", UserStatusSystem takes care of the rest
 */
export class OfferLifePopUp extends PopUp {
  contents: Container;
  backgroundImage: Image;
  watchButtonImage: Image;
  passButtonImage: Image;
  closeButton: Button;

  constructor(name: string) {
    super(name);

    this.thickness = 0;
    this.zIndex = zIndex.ABOVE_ALL;

    let darkness = new Rectangle(this.name + "Darkness");
    darkness.background = "black";
    darkness.alpha = 0.5;
    darkness.thickness = 0;
    this.addControl(darkness);

    this.contents = new Container(this.name + "Contents");
    this.addControl(this.contents);

    this.backgroundImage = new Image(this.name + "BackgroundImage", config.offerLifePopUpBackgroundUrl);
    this.contents.addControl(this.backgroundImage);
    if(!this.backgroundImage.isLoaded)
      this.backgroundImage.onImageLoadedObservable.addOnce(() => this.layout());

    this.watchButtonImage = new Image(this.name + "WatchButton", watchButtonImageUrl);
    this.watchButtonImage.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.watchButtonImage.onPointerClickObservable.add(() => this.disposeWithReason("watchAd"));
    this.watchButtonImage.isVisible = game.adSystem.isPreloaded("heartReward");
    this.contents.addControl(this.watchButtonImage);
    if(!this.watchButtonImage.isLoaded)
      this.watchButtonImage.onImageLoadedObservable.addOnce(() => this.layout());

    this.passButtonImage = new Image(this.name + "PassButton", passButtonImageUrl);
    this.passButtonImage.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.passButtonImage.onPointerClickObservable.add(() => this.disposeWithReason("purchasePass"));
    this.passButtonImage.isVisible = (game.rootState.paymentsSupported); // Hide the InstaPass button if payments are not supported
    this.contents.addControl(this.passButtonImage);
    if(!this.passButtonImage.isLoaded)
      this.passButtonImage.onImageLoadedObservable.addOnce(() => this.layout());

    this.closeButton = Button.CreateImageOnlyButton(name + "CloseButton", closeIconUrl);
    this.closeButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.closeButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.closeButton.thickness = 0;
    this.closeButton.onPointerClickObservable.add(() => this.disposeWithReason("close"));
    this.contents.addControl(this.closeButton);

    // Testing!
    //this.watchButtonImage.isVisible = true // No semicolon
    //this.passButtonImage.isVisible = true // No semicolon

    this.layout();
  }

  layout() {
    super.layout();

    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiHeight = game.guiTexture.getSize().height - menuBarHeight;

    let height = Math.min(1080, Math.floor(guiHeight * 0.9));
    let scale = height / 1080;

    this.contents.width = height + "px";
    this.contents.height = height + "px";

    this.backgroundImage.width = height + "px";
    this.backgroundImage.height = height + "px";

    let buttonWidth = 687 * scale;
    let buttonHeight = 189 * scale;
    let buttonYWatch = 596 * scale;
    let buttonYPass = 816 * scale;

    this.watchButtonImage.top = buttonYWatch;
    this.watchButtonImage.width = buttonWidth + "px";
    this.watchButtonImage.height = buttonHeight + "px";

    this.passButtonImage.top = buttonYPass;
    this.passButtonImage.width = buttonWidth + "px";
    this.passButtonImage.height = buttonHeight + "px";

    this.closeButton.width = buttonHeight + "px";
    this.closeButton.height = buttonHeight + "px";
  }
  onPointerMiss() {
    // Don't close on miss
  }

  /** Helper to show the PopUp */
  static show() {
    let popUp = new OfferLifePopUp("OfferLifePopUp");

    // If both buttons are hidden, self destruct without showing, but we have to return a valid popUp first
    if(!popUp.watchButtonImage.isVisible && !popUp.passButtonImage.isVisible) {
      setTimeout(() => popUp.dispose(), 0);
      return popUp;
    }

    game.guiTexture.addControl(popUp);
    return popUp;
  }
}
