// tslint:disable:no-console
import { Observable } from "@babylonjs/core/Misc/observable";

import * as Sentry from "@sentry/minimal";
import { Event, Severity } from "@sentry/types";

import { config } from "utils/Config";

/**
 * Usage:
 * import { logger } from "utils/logger";
 * logger.error("An Error message", { id:1234 });
 * Note the error message generally shouldn't have any data formatted into it, all data should be passed in the extra object.
 * This is because sentry groups errors based on the message, so if every message is unique it breaks grouping.
 * Also in general don't pass objects directly as extra, ie don't: error("error", snapshot)  do: error("error", { snapshot }).
 * This is so that the snapshot object will be labled in sentry, and it's easier to add additional details later if needed.
 */

// FATAL, ERROR, and WARN get sent to sentry as events
// INFO is added to an event as breadcrumbs
export const FATAL = Severity.Fatal;    // The service/app is going to stop or become unusable now.
export const ERROR = Severity.Error;    // Fatal for a particular request, but the service/app continues servicing other requests.
export const WARN = Severity.Warning;   // A note on something that should probably be looked at by an operator eventually.
export const INFO = Severity.Info;      // Detail on regular operation.
export const DEBUG = Severity.Debug;    // Verbose details for debugging only
const logLevels = [FATAL, ERROR, WARN, INFO, DEBUG]; // list of log levels in order of most severe to least.

const GAME_LOG_MAX_ENTRIES = 16;

export let logger: Logger = null;

export class Logger {

  public beforeSendObservable = new Observable(); // triggered before event gets sent to sentry

  _gameLog: any[];
  _warnedOnce: {[key: string]: boolean};
  useSentry = false;

  constructor(sentryDsn: string = null) {
    logger = this;
    this._gameLog = [];
    this._warnedOnce = {};
    // overridden by subclass LoggerBrowser or LoggerNode

    if(sentryDsn)
      this.useSentry = true;
  }

  /** this is mainly a convenience function for lambdas to clear scope and optionaly set some scope vars */
  resetScope(userId: string = null, tags: any = null) {
    Sentry.configureScope((scope) => {
      scope.clear(); // clear scope to reset logger.info messages from previous request that got saved as breadcrumbs
      scope.setTag("NODE_ENV", process.env.NODE_ENV);
      scope.setTag("STAGE", process.env.STAGE);

      if(userId)
        scope.setUser({ id: userId });

      if(tags) {
        Object.keys(tags).forEach((key, value) => {
          scope.setTag(key, tags[key]);
        });
      }
    });
  }

  /** in general don't call log, use fatal, error, warn, info, debug instead */
  log(level: Severity, message: string, extra: any = null) {
    if(this.useSentry) {
      if((level === FATAL || level === ERROR || level === WARN)) {
        Sentry.withScope((scope) => {
          scope.setLevel(level);
          if(extra)
            scope.setExtra("extra", extra);

          Sentry.captureMessage(message);
        });
      }
      else if(level === INFO) {

        // we need to stringfy each object in extra, otherwise sentry.io will display: [Object Object]
        let data: any = {};
        if(extra) {
          Object.keys(extra).forEach((key, value) => {
            data[key] = JSON.stringify(extra[key]);
          });
        }
        Sentry.addBreadcrumb({
          //category: 'auth',
          data: data,
          level: level,
          message: message,
        });
      }
    }

    // log to console if level less then or equal to loggerConsoleLevel
    if(config.loggerConsoleLevel && getLogLevelRank(level) <= getLogLevelRank(config.loggerConsoleLevel)) {
      this.logToConsole(level, message, extra);
    }
  }

  logToConsole(level: Severity, message: string, extra: any = null) {
    // this is overriden by logger-node

    if(level === FATAL || level === ERROR)
      console.error(message, extra);
    else if(level === WARN)
      console.warn(message, extra);
    else if(level === INFO)
      console.info(message, extra);
    else if(level === DEBUG)
      console.debug(message, extra);
  }

  fatal(message: string, extra: any = null) {
    this.log(FATAL, message, extra);
  }

  error(message: string, extra: any = null) {
    this.log(ERROR, message, extra);
  }

  warn(message: string, extra: any = null) {
    this.log(WARN, message, extra);
  }

  warnOnce(key: string, message: string, extra: any = null) {
    // Don't warn if we've already sent a warning for this key
    if(this._warnedOnce[key] === true)
      return;

    // Flag that we're sending a warning
    this._warnedOnce[key] = true;

    // Send the warning
    this.warn(message, extra);
  }

  info(message: string, extra: any = null) {
    this.log(INFO, message, extra);
  }

  debug(message: string, extra: any = null) {
    this.log(DEBUG, message, extra);
  }

  /** GameLog works differently, the information is added to an array, and will be included in extra when an error is sent */
  appendToGameLog(message: string) {
    // Pop out the 1st entry if we're at capacity
    if(this._gameLog.length >= GAME_LOG_MAX_ENTRIES)
      this._gameLog.shift();
    this._gameLog.push(message);
  }

  getGameLog() {
    return this._gameLog;
  }

  resetGameLog() {
    this._gameLog.length = 0;
  }

  /** safely close sentry and reload/refresh browser */
  safeReloadBrowser() {
    // implemented by logger-browser
  }

  /** this strips out everything except for the file name from the path, ie /instant-bundle/2262807303790585/1821755774595775/app.7ec5e1a9378f34ef6681.js  becomes /app.7ec5e1a9378f34ef6681.js */
  normalizePath(path: string) {
    let filename = path.substring(path.lastIndexOf("/"));
    return filename;
  }

  /** This iterates through the stacktrace in event and simplifies the paths, ie from /instant-bundle/2262807303790585/1821755774595775/app.7ec5e1a9378f34ef6681.js  to  /app.7ec5e1a9378f34ef6681.js
   * Sentry needs the path in the stacktrace to match the sourcemap uploaded to sentry.io.  Facebook keeps changing those numbers in the path, so the easiest way to make them match is to remove those
   * This came from: https://github.com/getsentry/sentry/issues/4719#issuecomment-334111015
   */
  normalizeStackPaths(event: Event) {
    let stacktrace = event.stacktrace || (event.exception && event.exception.values[0].stacktrace);
    if(stacktrace) {
      stacktrace.frames.forEach((frame: any) => {
        frame.filename = this.normalizePath(frame.filename);
      });
    }
  }
}

/** return the numerical rank of the log level according to logLevels list */
function getLogLevelRank(level: Severity) {
  return logLevels.indexOf(level);
}
