import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { TextWrapping } from "@babylonjs/gui/2D/controls/textBlock";
import { ImageWithFallback } from "components/ui/Controls";
import { CardButton3d } from "components/ui/meshes/CardButton3d";
import { config } from "utils/Config";

import pawn0AvatarUrl from "components/game/avatars/pawn0.png";
import backgroundUrl from "components/ui/meshes/score-card/score-card-background-red.png";

let BACKGROUND_BORDER = 20;

/**
 * 3d card displaying a player's image, name and score
 */
export class ScoreCard3d extends CardButton3d {
  background: ImageWithFallback;
  imageBackground: Rectangle;
  image: ImageWithFallback;
  nameText: TextBlock;
  scoreText: TextBlock;
  messageText: TextBlock;

  centerWidth: number;
  nameTextFontSize: number;

  constructor(name: string, onClick: () => void) {
    super(name, null, onClick);
    this.addGUI();

    // Compute sizes
    // Since the gui is a fixed size, there's no need for a separate layout function

    // centerHeight works out to 472
    // The sum of all the heights works out to 475

    let centerWidth = this.guiWidth - BACKGROUND_BORDER * 2;
    let centerHeight = this.guiHeight - BACKGROUND_BORDER * 2;

    let k = this.guiWidth / 2;

    let imageWidth = k;
    let imageHeight = k;

    let spacerHeight = k / 8;

    let nameTextHeight = k * 3 / 8;
    let nameTextFontSize = nameTextHeight * 0.8;

    let scoreTextHeight = k * 7 / 8;
    let scoreTextFontSize = scoreTextHeight * 0.8;

    let messageTextHeight = k / 2;
    let messageTextFontSize = messageTextHeight * 0.8;
    let messageTextTop = imageHeight - messageTextHeight;

    let textColor = "white";

    // Save values for setName
    this.centerWidth = centerWidth;
    this.nameTextFontSize = nameTextFontSize;

    // Create components
    this.background = new ImageWithFallback(this.name + "Background", backgroundUrl);
    this.gui.addControl(this.background);

    let center = new Container(this.name + "Center");
    center.width = centerWidth + "px";
    center.height = centerHeight + "px";
    this.gui.addControl(center);

    let stack = new StackPanel(this.name + "StackPanel");
    stack.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    center.addControl(stack);

    this.imageBackground = new Rectangle(this.name + "ImageBackground");
    this.imageBackground.width = imageWidth + "px";
    this.imageBackground.height = imageHeight + "px";
    this.imageBackground.thickness = 0;
    stack.addControl(this.imageBackground);

    this.image = new ImageWithFallback(this.name + "Image", pawn0AvatarUrl);
    this.imageBackground.addControl(this.image);

    let spacer = new Control(this.name + "Spacer");
    spacer.height = spacerHeight + "px";
    stack.addControl(spacer);

    this.nameText = new TextBlock(this.name + "NameTextBlock", "Top");
    this.nameText.height = nameTextHeight + "px";
    this.nameText.color = textColor;
    this.nameText.fontFamily = config.fontFamily;
    this.nameText.fontWeight = config.fontWeight;
    this.nameText.fontSize = nameTextFontSize + "px";
    //this.nameText.textWrapping = TextWrapping.Ellipsis;
    //this.nameText.text = "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW"
    stack.addControl(this.nameText);

    this.scoreText = new TextBlock(this.name + "ScoreTextBlock", "Top");
    this.scoreText.height = scoreTextHeight + "px";
    this.scoreText.color = textColor;
    this.scoreText.fontFamily = config.fontFamily;
    this.scoreText.fontWeight = config.fontWeight;
    this.scoreText.fontSize = scoreTextFontSize + "px";
    //this.scoreText.text = "999"
    stack.addControl(this.scoreText);

    this.messageText = new TextBlock(this.name + "MessageTextBlock", "Top");
    this.messageText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.messageText.top = messageTextTop;
    this.messageText.height = messageTextHeight + "px";
    this.messageText.color = config.scoreHighlightColor;
    this.messageText.shadowBlur = config.scoreHighlightShadowBlur;
    this.messageText.shadowColor = config.scoreHighlightShadowColor || config.scoreHighlightColor;
    this.messageText.fontFamily = config.fontFamily;
    this.messageText.fontWeight = config.fontWeight;
    this.messageText.fontSize = messageTextFontSize + "px";

    //this.messageText.text = "Winner!"
    center.addControl(this.messageText);
  }

  setBackground(imageUrl: string) {
    this.background.setSource(imageUrl);
  }

  setName(name: string) {
    //name = "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW"

    this.nameText.text = name;

    // Babylon won't tell us the width of the text, but we can get it from the canvas context
    let ctx = this.gui.getContext();
    ctx.font = config.fontWeight + " " + this.nameTextFontSize + "px " + config.fontFamily;
    let metrics = ctx.measureText(name);
    let textWidth = metrics.width;

    if(textWidth <= this.centerWidth) {
      // The text fits, use the normal font size and no ellipsis
      this.nameText.fontSize = this.nameTextFontSize + "px";
      this.nameText.textWrapping = TextWrapping.Clip;
    } else {
      // The text doesn't fit, reduce the font size, and use an ellipsis if it overflows
      this.nameText.fontSize = (this.nameTextFontSize * 0.667) + "px";
      this.nameText.textWrapping = TextWrapping.Ellipsis;
    }
  }

  setImage(imageUrl: string) {
    this.image.setSource(imageUrl, pawn0AvatarUrl);
  }

  setScore(score: number) {
    //score = 999
    this.scoreText.text = `${score}`;
  }

  setMessage(message: string) {
    this.messageText.text = message;
  }
}
