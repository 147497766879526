import $ from "jquery";

import { isAlive } from "mobx-state-tree";

import { config } from "utils/Config";

import { IRootState, RootState, setRootState } from "states/RootState";

import { Game } from "components/game/Game";

import { stateSyncInit } from "states/state-sync/StateSyncClient";

let game: Game = null;

// save initTime for calculating loadTime for analytics
let now = new Date();
export let initTime = now.getTime();

/** Call init from the entry point to kick off the init/loading process for the current platform */
export function init() {
  (window as any).$ = $; // make jquery available in html files, initially for help.html

  // check for any cgi url vars, such as ?loadTestClientMode=1  to turn on load testing mode, if we're allowed to.
  if(config.allowLoadTestClientModeCGI) {
    let url = new URL(window.location.href);
    if(url.searchParams.get("loadTestClientMode"))
      config.loadTestClientMode = true;
  }

  if(process.env.PLATFORM === "facebook_ig") {
    window.onload = () => {
      FBInstant.initializeAsync().then(() => {
        onLoaded();
      });
    };
  }
  else { // web
    (window as any).FBInstant = null;
    window.addEventListener("DOMContentLoaded", () => {
      onLoaded();
    });
  }
}

/** This gets called when it is time to actually create the rootstate and game. */
function onLoaded() {
  // Create RootState adding in other root level states from config, ie SpadesGameState
  // tslint:disable-next-line:variable-name  disable variable-name so that States can be capitalized below
  const GameRootState = RootState.props(config.rootStates);
  let rootState = GameRootState.create({});
  setRootState(rootState as IRootState);

  if(config.haveStateSync)
    stateSyncInit();

  // calling isAlive on states makes sure that they are actually created at this time, otherwise
  // they don't seem to be created until something tries to access them
  isAlive(rootState.game);

  // Create the game using the 'renderCanvas'.
  if(game === null) {
    let gameClass = config.gameClass;
    game = new gameClass("renderCanvas");
  }
}
