// tslint:disable-next-line:no-var-requires
const mixin = require("mixin-deep"); // mixin-deep doesn't have typescript definition so we have to use require

let config: any = {
};

/** Merges newConfig into main config. */
function mergeConfig(newConfig: object) {
  config = mixin(config, newConfig);
}

export { config, mergeConfig };
