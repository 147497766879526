import { Tools } from "@babylonjs/core/Misc/tools";
import { toDataURL } from "components/utils/Image";
import * as Mustache from "mustache";

/**
 * Render an SVG XML String to a PNG and return a dataURI for the PNG
 * The <svg> tag must have width and height attributes
 * <image> tags, or anything else with a URL, has to use a data URI.  The svg can't reference anything external. (see imageUrlToPNGDataURI below)
 * Facebook wants just the base64 data, which is dataURI.split(",")[1]
 */
export function svgToPngDataURI(svg: string) {
  return new Promise<string>((resolve, reject) => {
    let svgUri = `data:image/svg+xml;base64,${window.btoa(svg)}`;

    let image = new Image();

    image.onload = () => {
      let canvas = document.createElement("canvas") as HTMLCanvasElement;
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0);
      let uri = canvas.toDataURL("image/png");
      resolve(uri);
    };

    image.onerror = () => {
      resolve(null);
    };

    image.src = svgUri;
  });
}

export function svgLoadTemplateIntoImage(svgTemplateUrl: string, view: any = {}): Promise<HTMLImageElement> {
  let loadPromises: any[] = [];

  // Load the SVG template
  let svgPromise = new Promise( (resolve, reject) => {
    Tools.LoadFile(svgTemplateUrl, resolve, undefined, undefined, undefined, reject);
  });

  loadPromises.push(svgPromise);

  // Convert any image urls to data uris
  for(let key of Object.keys(view)) {
    let value = view[key];
    if(typeof value === "string") {
      let lowerCaseValue = value.toLowerCase();
      if(lowerCaseValue.endsWith(".png") || lowerCaseValue.endsWith(".jpg")) {
        let imageLoadPromise = imageUrlToPngDataURI(value).then((uri) => view[key] = uri);
        loadPromises.push(imageLoadPromise);
      }
    }
  }

  // Wait for everything to load
  return new Promise<HTMLImageElement>( (resolve, reject) => {
    Promise.all(loadPromises).then( (results: any[]) => {
      let svg = Mustache.render(results[0], view);
      let svgUri = `data:image/svg+xml;base64,${window.btoa(svg)}`;
      let image = new Image();
      image.onload = () => {
        resolve(image);
      };

      image.onerror = () => {
        resolve(null);
      };

      image.src = svgUri;
    });
  });

}

export function svgTemplateToPngDataURI(svgTemplateUrl: string, view: any = {}): Promise<string> {
  let loadPromises: any[] = [];

  // Load the SVG template
  let svgPromise = new Promise( (resolve, reject) => {
    Tools.LoadFile(svgTemplateUrl, resolve, undefined, undefined, undefined, reject);
  });

  loadPromises.push(svgPromise);

  // Convert any image urls to data uris
  for(let key of Object.keys(view)) {
    let value = view[key];
    if(typeof value === "string") {
      let lowerCaseValue = value.toLowerCase();
      if(lowerCaseValue.endsWith(".png") || lowerCaseValue.endsWith(".jpg")) {
        let imageLoadPromise = imageUrlToPngDataURI(value).then((uri) => view[key] = uri);
        loadPromises.push(imageLoadPromise);
      }
    }
  }

  // Wait for everything to load
  return new Promise( (resolve, reject) => {
    svgLoadTemplateIntoImage(svgTemplateUrl, view).then((image) => {
      let canvas = document.createElement("canvas") as HTMLCanvasElement;
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0);
      let uri = canvas.toDataURL("image/png");
      resolve(uri);
    });
  });
}

/**
 * Load an image url return a PNG DataURL
 * I missed we already have something similar in components/utils/Image.ts: toDataURL
 * Currently imageUrlToPngDataURI is simply calling toDataURL, but catching any errors
 */
export async function imageUrlToPngDataURI(url: string): Promise<string> {
  try {
   return await toDataURL(url);
  } catch(e) {
    // We just want to return null on errors, so catch an errors from toDataURL
    return null;
  }

  // It seems this has problems on at least Mobile Safari, maybe desktop Safri, too
  // The issue seems to be that Safari fires onload when the image is loaded, but not yet decoded
  // Why it doesn't decode it when you try to draw I don't know
  // There's a newer function image.decode() that returns a promise for the decoded image, but it isn't supported on Firefox
  /*
  return new Promise<string>((resolve, reject) => {
    let image = new Image();

    // Without this line, Facebook avatars get a SecurityError in the browser
    Tools.SetCorsBehavior(url, image);

    image.onload = () => {
      let canvas = document.createElement("canvas") as HTMLCanvasElement;
      canvas.width = image.width;
      canvas.height = image.height;
      let context = canvas.getContext("2d");
      context.drawImage(image, 0, 0);
      let uri = canvas.toDataURL("image/png");
      resolve(uri);
    };

    image.onerror = () => {
      resolve(null);
    };

    image.src = url;
  });
  */
}

/**
 * Load an image url return a PNG DataURI
 * If the url fails to load, try again with the fallback url
 */
export async function imageUrlToPngDataURIWithFallback(url: string, fallbackUrl: string): Promise<string> {
  let dataURI = await imageUrlToPngDataURI(url);
  if(dataURI === null)
    dataURI = await imageUrlToPngDataURI(fallbackUrl);
  return dataURI;
}
