import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { ElasticEase } from "@babylonjs/core/Animations/easing";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

import { ANIMATION_DEAL } from "components/game/AnimationSystem";
import { ArgoSystem } from "components/game/ArgoSystem";

export class DealHand extends ArgoSystem {

    init() {
      this.game.animationSystem.registerAnimation(ANIMATION_DEAL, (mesh) => this.buildDealHandAnimation(mesh));
    }

  /**
   * Deals a hand of cards for the trick games by sliding them into the hand from off screen using elastic easing
   */
  buildDealHandAnimation(mesh: AbstractMesh): Animatable {
    //console.log("buildDealHandAnimation " + mesh.metadata.layoutGameStatus + " " + mesh.name + " from " + mesh.metadata.fromParentName + " " + mesh.absolutePosition + " to " + mesh.parent.name + " " + mesh.metadata.layoutPosition);

    let radius = 2.0;

    // Get an offscreen position along the card's rotation vector
    let rotations = mesh.metadata.layoutRotationQuaternion.toEulerAngles();
    let angle = rotations.z * 180.0 / Math.PI;
    let a = (90.0 + angle) * Math.PI / 180;
    let x = Math.cos(a) * radius;
    let y = Math.sin(a) * radius;

    let position = mesh.metadata.layoutPosition;
    let rotationQuaternion = mesh.metadata.layoutRotationQuaternion;

    // Apply rotation since it isn't animating well without clipping
    mesh.rotationQuaternion = rotationQuaternion;

    let v = new Vector3(x, y, 0);

    let position0 = position.subtract(v.scale(radius));
    let position1 = position.clone();

    // Keys
    let k0 = mesh.metadata.layoutIndex;
    let k1 = k0 + 25;

    let positionAnimation = new Animation("PositionMesh", "position", 30.0, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let positionKeys = [];
    positionKeys.push({ frame: 0, value: position0 });
    positionKeys.push({ frame: k0, value: position0 });
    positionKeys.push({ frame: k1, value: position1 });
    positionAnimation.setKeys(positionKeys);
    mesh.animations.push(positionAnimation);

    // Elasic easing overshoots, then springs back
    let easingFunction = new ElasticEase(1, 5);
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
    positionAnimation.setEasingFunction(easingFunction);

    // Fade in cards so the opponent cards aren't visible waiting to animate
    let alphaAnimation = new Animation("FadeMesh", "visibility", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let alphaKeys = [];
    alphaKeys.push({ frame: 0, value: 0.0 });
    alphaKeys.push({ frame: k0, value: 0.0 });
    alphaKeys.push({ frame: (k0 + k1) / 3, value: 1.0 });
    alphaAnimation.setKeys(alphaKeys);
    mesh.animations.push(alphaAnimation);

    let speed = 1.0;

    return this.game.animationSystem.begin(mesh, 0, k1, false, speed);
  }
}
