import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { ElasticEase } from "@babylonjs/core/Animations/easing";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Observer } from "@babylonjs/core/Misc/observable";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Grid } from "@babylonjs/gui/2D/controls/grid";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";
import { Measure } from "@babylonjs/gui/2D/measure";

import { game } from "components/game/Game";

import { ArgoButton } from "components/ui/controls/ArgoButton";
import { NinePatchImage } from "components/ui/controls/NinePatchImage";
import { PopUp } from "components/ui/controls/PopUp";
import { zIndex } from "components/utils/GUI";
import { GUIAnimationProxy } from "components/utils/GUIAnimationProxy";

import backgroundNinePatchMultiPanelUrl from "components/ui/tutorial/interactive-help-multi-panel-background-9p.png";

import backIconUrl from "components/ui/tutorial/back-icon.png";
import forwardIconUrl from "components/ui/tutorial/forward-icon.png";
import showTutorialIconUrl from "components/ui/tutorial/show-tutorial-icon.png";
import skipIconUrl from "components/ui/tutorial/skip-icon.png";

const BUTTON_COLOR = "#f08f04";  // orange

/** Tutorial popup box with back, continue, and skip buttons. */
export class TutorialPopUp extends PopUp {
  private animateInOutAnimation: Animatable = null;
  backgroundBox: Container;
  backgroundImage: NinePatchImage;
  disposeOnMiss = false;
  textBlock: TextBlock;
  continueButton: ArgoButton;
  skipButton: ArgoButton;
  navigationGrid: Grid;
  backButton: ArgoButton;
  forwardButton: ArgoButton;
  showTutorialButton: ArgoButton; // The button on the right center side of screen to re-show tutorial box when it is hidden
  layoutX = 0;
  layoutY = 0;
  layoutShowTutorialButtonX = 0;
  layoutShowTutorialButtonOffScreenX = 0;
  onPointerClickObserver: Observer<Vector2WithInfo>;
  continueButtonOnPointerClickObserver: Observer<Vector2WithInfo>;
  proxy: GUIAnimationProxy;

  // called when user clicks Continue button, or clicks outside of tutorial box.
  // skipRestOfTutorial means the user clicked skip, so end tutorial and don't show any more message
  // I added the flag to continueCB instead of a seperate skipCB, because it needs to continue first to unpause
  continueCB: (skipRestOfTutorial: boolean) => any;

  static readonly BOTTOM = "bottom";
  static readonly RIGHT = "right";

  constructor(name: string, backCB?: () => any, forwardCB?: () => any, continueCB?: (skipRestOfTutorial: boolean) => any, showTutorialCB?: () => any) {
    super(name);

    this.continueCB = continueCB;

    // Create an animation proxy
    this.proxy = new GUIAnimationProxy(this);

    this.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //this.background = "magenta";
    this.thickness = 0;
    this.isPointerBlocker = true;

    // Skip Button disables tutorial for rest of the game.
    this.skipButton = new ArgoButton(name + "SkipButton", "", skipIconUrl);
    this.skipButton.iconPosition = ArgoButton.ICON_RIGHT;
    this.skipButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.skipButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.skipButton.background = "#00000080";
    this.skipButton.color = BUTTON_COLOR;
    this.skipButton.thickness = 0;
    this.skipButton.isPointerBlocker = true;
    this.skipButton.onPointerClickObservable.add(() => {
      this.onContinueClick(true); // we need to call continueCB to resume the game, but pass true to skipRestOfTutorial so it doesn't go on to next help
    });
    this.addControl(this.skipButton);

    // Add a container to hold the background image
    this.backgroundBox = new Container(name + "BackgroundBox");
    this.backgroundBox.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.addControl(this.backgroundBox);

    // Add Background Image below the Skip Button
    this.backgroundImage = new NinePatchImage(name + "BackgroundImage", backgroundNinePatchMultiPanelUrl);
    this.backgroundImage.populateNinePatchSlicesFromImage = true;
    this.backgroundImage.stretch = Image.STRETCH_NINE_PATCH;
    this.backgroundBox.addControl(this.backgroundImage);

    // Layout needs the backgroundImage loaded, so if it isn't loaded right away, re-layout when it does load
    if(!this.backgroundImage.isLoaded)
      this.backgroundImage.onImageLoadedObservable.addOnce(() => this.layout());

    // Add Text
    this.textBlock = new TextBlock(name + "TextBlock");
    this.textBlock.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.textBlock.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.textBlock.color = "white";
    this.textBlock.textWrapping = true;
    this.addControl(this.textBlock);

    // Continue button
    this.continueButton = new ArgoButton(name + "Button", "Continue");
    this.continueButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.continueButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.continueButton.color = "white";
    this.continueButton.background = "black";
    this.continueButton.thickness = 0;
    this.continueButton.textBlock.shadowBlur = 4;
    this.continueButton.textBlock.shadowColor = "white";
    this.continueButtonOnPointerClickObserver = this.continueButton.onPointerClickObservable.add(() => {
      this.onContinueClick();
    });
    this.addControl(this.continueButton);

    // Navigation Button Container
    this.navigationGrid = new Grid(name + "NavigationStack");
    this.navigationGrid.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.navigationGrid.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.navigationGrid.addColumnDefinition(0.45); // Column for Back
    this.navigationGrid.addColumnDefinition(0.1);  // empty space between back and forward
    this.navigationGrid.addColumnDefinition(0.45); // Column for Forward
    this.addControl(this.navigationGrid);

    // Back Button
    this.backButton = new ArgoButton(name + "BackButton", "", backIconUrl);
    this.backButton.color = BUTTON_COLOR;
    this.backButton.background = "black";
    this.backButton.isPointerBlocker = true;
    this.backButton.onPointerClickObservable.add(() => {
      if(backCB)
        backCB();
    });
    this.navigationGrid.addControl(this.backButton, 0, 0);

    // Forward Button
    this.forwardButton = new ArgoButton(name + "ForwardButton", "", forwardIconUrl);
    this.forwardButton.color = BUTTON_COLOR;
    this.forwardButton.background = "black";
    this.forwardButton.isPointerBlocker = true;
    this.forwardButton.onPointerClickObservable.add(() => {
      if(forwardCB)
        forwardCB();
    });
    this.navigationGrid.addControl(this.forwardButton, 0, 2);

    // Show Tutorial button on right center of screen, it is a child of the guiTexture not the PopUpBox!
    this.showTutorialButton = new ArgoButton("ShowTutorialButton", "", showTutorialIconUrl);
    this.showTutorialButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.showTutorialButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    this.showTutorialButton.thickness = 0;
    this.showTutorialButton.roundedCorners = false;
    this.showTutorialButton.zIndex = zIndex.ABOVE_GAME;
    this.showTutorialButton.image.shadowColor = BUTTON_COLOR;
    this.showTutorialButton.onPointerClickObservable.add(() => {
      if(showTutorialCB)
        showTutorialCB();
    });
    game.guiTexture.addControl(this.showTutorialButton);
  }

  init() {
    // Layout needs to happen after the PopUp is added to it's parent

    // Initial Layout
    this.layout();

    // Initial Appearance
    this.animateIn();
  }

  /** set a new message
   * @param showPopUp means if the popup is currently invisible, animate it in and show it, if false then update message, but don't show it.
   */
  setMessage(message: string, showPopUp: boolean, showBack: boolean, showForward: boolean) {
    this.textBlock.text = message;
    this.layout();
    if(!this.isVisible) {
      if(showPopUp)
        this.animateIn();
      else
        this.drawAttentionToShowTutorialButton();
    }

    this.backButton.isVisible = showBack;
    this.forwardButton.isVisible = showForward;
  }

  layout() {
    super.layout();

    // Get the GUI size in pixels
    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height;

    // Compute a base height for text and buttons.  We'll base everything else on this height
    let baseHeightScale = Math.floor(30 * game.getScale()); // Generally, we'll try to make the text always the same size
    let baseHeightDivisions = Math.floor(guiHeight / 16); // But we do need to watch out for screens without a lot of vertical height
    let baseHeight = Math.min(baseHeightScale, baseHeightDivisions);

    // Compute a border width to keep text away from the frame edges
    let border = Math.floor(baseHeight * 0.25);

    // Compute a border for the buttons
    let buttonBorder = Math.floor(baseHeight * 0.25);

    // Compute a font size for the main text
    let fontSize = baseHeight * 0.8;

    // Compute a width for the overall PopUp Box
    let boxWidth = baseHeight * 12;

    // Match the button text to the main text
    let buttonHeight = baseHeight + buttonBorder * 2;

    // Skip button is a little smaller
    let skipButtonHeight = Math.floor(buttonHeight * 0.75);
    let skipButtonBorder = Math.floor(skipButtonHeight * 0.25);

    // Compute the size of the main text
    let textWidth = boxWidth - border * 2;
    this.textBlock.width = textWidth + "px";
    this.textBlock.fontSize = fontSize + "px";
    let measure = new Measure(0, 0, guiWidth, guiHeight);
    let context = game.guiTexture.getContext();
    (this.textBlock as any)._layout(measure, context); // Workaround for Babylon GUI not updating things until rendered
    let textHeight = this.textBlock.computeExpectedHeight();

    // Compute the content area (text size)
    let contentX = border;
    let contentY = skipButtonHeight + border;
    let contentHeight = textHeight;

    // Get some values from the background image, if it's available
    // The background cannot be smaller than the backgroundImage, or the element will overlap
    // Also, we need to know the height of the bottom slice in order to fit buttons into that area
    let backgroundHeight = contentHeight;
    let bottomSliceHeight = baseHeight;
    if(this.backgroundImage && this.backgroundImage.isLoaded && this.backgroundImage.domImage) {
      backgroundHeight = Math.max(backgroundHeight, this.backgroundImage.domImage.height - 2);
      bottomSliceHeight = this.backgroundImage.domImage.height - this.backgroundImage.sliceBottom - 1;
      // Because Babylon's nine-patch is not DPI Aware, we scale it below, and we need to scale the bottm slice height as well (the overall height should work out the same)
      bottomSliceHeight *= game.getScale();
    }

    // Make sure the contentHeight fits above the bottomSliceHeight
    backgroundHeight = Math.max(backgroundHeight, contentHeight + bottomSliceHeight);

    // Compute overall box height
    let boxHeight = skipButtonHeight + backgroundHeight;

    // Position the main text in the content area
    this.textBlock.left = contentX;
    this.textBlock.top = contentY;
    this.textBlock.height = textHeight + "px";

    // Compute the PopUp location above right center of the screen
    let x = guiWidth - boxWidth;
    let y = guiHeight / 3 - boxHeight / 2;
    if(y < 0)
      y = 0;

    // Save the layed out position for animation
    this.layoutX = x;
    this.layoutY = y;

    // Position & Size the PopUp box
    this.left = x;
    this.top = y;
    this.width = boxWidth + "px";
    this.height = boxHeight + "px";

    // Layout the SkipButton
    this.skipButton.left = -border;
    this.skipButton.baseHeight = skipButtonHeight;
    this.skipButton.borderWidth = skipButtonBorder;

    // The background fills everything under the Skip Button
    this.backgroundBox.width = boxWidth + "px";
    this.backgroundBox.height = backgroundHeight + "px";

    // Because Babylon's nine-patch is not DPI aware, we'll use scale to compensate
    let scale = game.getScale();
    this.backgroundImage.scaleX = scale;
    this.backgroundImage.scaleY = scale;
    this.backgroundImage.width = (boxWidth / scale) + "px";
    this.backgroundImage.height = (backgroundHeight / scale) + "px";

    // Continue Button
    this.continueButton.left = -border;
    this.continueButton.top = -border;
    this.continueButton.baseHeight = buttonHeight;
    this.continueButton.borderWidth = buttonBorder;

    // Navigation Grid
    this.navigationGrid.left = border;
    this.navigationGrid.top = -border;
    this.navigationGrid.width = (buttonHeight * 2) + "px";
    this.navigationGrid.height = buttonHeight + "px";

    // Back Button
    this.backButton.baseHeight = buttonHeight;
    this.backButton.borderWidth = buttonBorder;

    // Forward Button
    this.forwardButton.baseHeight = buttonHeight;
    this.forwardButton.borderWidth = buttonBorder;

    // Show Tutorial Button
    this.layoutShowTutorialButtonX = buttonHeight * 4.0 / 64.0; // Push right border off screen;
    this.layoutShowTutorialButtonOffScreenX = buttonHeight;

    this.showTutorialButton.left = this.isVisible ? this.layoutShowTutorialButtonOffScreenX : this.layoutShowTutorialButtonX;
  }

  animateIn() {
    // Clear highlight on the showTutorialButton
    this.showTutorialButton.image.shadowBlur = 0;

    this.isVisible = true; // be sure it is visible incase a previous animateOut hid

    let guiWidth = game.guiTexture.getSize().width;
    let moveTime = 0.1;

    let from = new Vector2(guiWidth, this.layoutY);
    let to = new Vector2(this.layoutX, this.layoutY);

    this.proxy.position = from;

    let a = new Animation(name + "AnimateIn", "position", 30, Animation.ANIMATIONTYPE_VECTOR2, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = k0 + 30 * moveTime;
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    a.setKeys(keyFrames);

    this.animateInOutAnimation = game.scene.beginDirectAnimation(this.proxy, [a], 0, k1);

    // Animate the Show Tutorial Button out at the same time
    let at = new Animation(name + "AnimateShowTutorialOut", "left", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    keyFrames = [];
    keyFrames.push({frame: k0, value: this.layoutShowTutorialButtonX });
    keyFrames.push({frame: k1, value: this.layoutShowTutorialButtonOffScreenX });
    at.setKeys(keyFrames);

    this.animateInOutAnimation.appendAnimations(this.showTutorialButton, [at]);
  }

  /** animateOut
   * @param dispose - dispose after animating, otherise just set isVisible true
   */
  animateOut(dispose = false) {
    let oldLeft = this.leftInPixels;
    let oldTop = this.topInPixels;

    this.stopAnimations();

    let guiWidth = game.guiTexture.getSize().width;
    let moveTime = 0.1;

    this.top = oldTop + "px";

    let from = new Vector2(oldLeft, oldTop);
    let to = new Vector2(guiWidth, this.layoutY);

    this.proxy.position = from;

    let a = new Animation(name + "AnimateIn", "position", 30, Animation.ANIMATIONTYPE_VECTOR2, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = k0 + 30 * moveTime;
    let k2 = k1 + 30 * moveTime;
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    a.setKeys(keyFrames);

    this.animateInOutAnimation = game.scene.beginDirectAnimation(this.proxy, [a], 0, k2);
    this.animateInOutAnimation.onAnimationEndObservable.add( () => {
      if(dispose)
        this.dispose();
      else
        this.isVisible = false;
    });

    // Animate the Show Tutorial Button in after the tutorial box animates out
    let at = new Animation(name + "AnimateShowTutorialOut", "left", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    keyFrames = [];
    keyFrames.push({frame: k0, value: this.layoutShowTutorialButtonOffScreenX });
    keyFrames.push({frame: k1, value: this.layoutShowTutorialButtonOffScreenX });
    keyFrames.push({frame: k2, value: this.layoutShowTutorialButtonX });
    at.setKeys(keyFrames);

    this.animateInOutAnimation.appendAnimations(this.showTutorialButton, [at]);
  }

  drawAttentionToShowTutorialButton() {
    // Clear the highlight before animation due to on screen artifacts
    this.showTutorialButton.image.shadowBlur = 0;

    let proxy = new GUIAnimationProxy(this.showTutorialButton);

    let speed = 4.0;

    let kStart = 0;
    let kZoomOut = kStart + 15;
    let kHalfTurnLeft = kZoomOut + 7;
    let kTurnRight = kHalfTurnLeft + 15;
    let kTurnLeft = kTurnRight + 15;
    let kCenter = kTurnLeft + 7;
    let kZoomIn = kCenter + 15;
    let kEnd = kZoomIn;

    let angle = 15;
    let rotateLeft = -angle * Math.PI / 180;
    let rotateRight = angle * Math.PI / 180;

    let animateScale = new Animation("drawAttentionToShowTutorialButtonScale", "scale", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    let keyFrames = [];
    keyFrames.push({frame: kStart, value: 1.0 });
    keyFrames.push({frame: kZoomOut, value: 1.5 });
    keyFrames.push({frame: kCenter, value: 1.5 });
    keyFrames.push({frame: kZoomIn, value: 1.0 });
    animateScale.setKeys(keyFrames);

    let animateRotation = new Animation("drawAttentionToShowTutorialButtonRotation", "rotation", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    keyFrames = [];
    keyFrames.push({frame: kStart, value: 0.0 });
    keyFrames.push({frame: kZoomOut, value: 0.0 });
    keyFrames.push({frame: kHalfTurnLeft, value: rotateLeft });
    keyFrames.push({frame: kTurnRight, value: rotateRight });
    keyFrames.push({frame: kTurnLeft, value: rotateLeft });
    keyFrames.push({frame: kCenter, value: 0.0 });
    animateRotation.setKeys(keyFrames);

    game.scene.beginDirectAnimation(proxy, [animateScale, animateRotation], kStart, kEnd, false, speed, () => {
      // Highlight the showTutorialButton to indicate pending messages - it will be cleared in animateIn
      this.showTutorialButton.image.shadowBlur = 4;
    });
  }

  onPointerMiss() {
    if(!this.isVisible)
      return;

    super.onPointerMiss();

    // auto continue tutorial when user clicks out side of the box, ie they clicked a card
    this.onContinueClick();
  }

  /** User either clicked continue, skip, or clicked outside of popup
   * @param skipRestOfTutorial means user clicked skip and to continue then end tutorial
   */
  onContinueClick(skipRestOfTutorial = false) {
    if(this.continueCB) {
      this.continueCB(skipRestOfTutorial);
    }
    else {
      this.animateOut();
    }
  }

  stopAnimations() {
    super.stopAnimations();

    if(this.animateInOutAnimation) {
      this.animateInOutAnimation.goToFrame(this.animateInOutAnimation.toFrame);
      this.animateInOutAnimation.stop();
      this.animateInOutAnimation = null;
    }
  }

  dispose() {
    super.dispose();
    this.showTutorialButton.dispose();

    // Stop Listening to clicks
    if(this.continueButtonOnPointerClickObserver) {
      this.continueButton.onPointerClickObservable.remove(this.continueButtonOnPointerClickObserver);
      this.continueButtonOnPointerClickObserver = null;
    }
  }
}
