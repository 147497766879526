import { types } from "mobx-state-tree";

import { config } from "utils/Config";

import { InventoryItemState } from "states/user/InventoryItemState";

import { logger } from "@sentry/utils";

interface IPayment {
  type: string; // "facebook". should make enum
  paymentData: any; // json payment data from provider
  /*
    Facebook paymentData:
      developerPayload string? A developer-specified string, provided during the purchase of the product
      paymentID string The identifier for the purchase transaction
      productID string The product's game-specified identifier
      purchaseTime string Unix timestamp of when the purchase occurred
      purchaseToken string A token representing the purchase that may be used to consume the purchase
      signedRequest SignedPurchaseRequest Server-signed encoding of the purchase request
  */
}

// tslint:disable-next-line:variable-name
const InventoryState = types
  .model("InventoryState", {
    items: types.array(InventoryItemState),
  })
  .views((self) => {

    /** Searches inventory for an item by name. ie pass, returns item even if it is expired or quantity 0
     * @param includeExpired  set true to return expired items, defaults to false
     * @param includeEmpty set true to return items with 0 quantity, defaults to false
     */
    function getItem(name: string, includeExpired = false, includeEmpty = false) {
      return self.items.find((item) => item.name === name && (includeExpired || !item.isExpired()) && (includeEmpty || item.quantity > 0));
    }

    /** returns true if user has a quantity of an item, and it's not expired */
    function hasItem(name: string, includeExpired = false, includeEmpty = false) {
      let item = getItem(name, includeExpired, includeEmpty);
      if(item)
        return true;

      return false;
    }

    return { getItem, hasItem };
  })
  .actions((self) => {

    /** Inserts/updates item for offerName in inventory */
    function insertOffer(offerName: string) {

      const offer = config.catalogOffers[offerName];
      if(!offer) {
        logger.error("InventoryState.insertOffer Offer not found", { offerName });
        return;
      }

      // Check to see if they already have item, if not create an empty item.
      let item = self.getItem(offer.item, true, true);
      if(!item) {
        item = InventoryItemState.create({ name: offer.item });
        self.items.push(item);
      }

      // Then applyOffer to the item to set quantity and/or extend term
      item.applyOffer(offer);
    }

    return { insertOffer };
  });

// InventoryState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IInventoryState = typeof InventoryState.Type;

export { InventoryState, IInventoryState };
