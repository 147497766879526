import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { ElasticEase } from "@babylonjs/core/Animations/easing";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import { ArgoSystem } from "components/game/ArgoSystem";
import { Commands } from "components/ui/Commands";
import { disposeGuiControl, findGuiControl, zIndex } from "components/utils/GUI";
import { GAME_STATE_WAITING_FOR_PLAYERS } from "states/game/GameState";
import { config } from "utils/Config";

export class StartButtonSystem extends ArgoSystem {
  boxTop = 0;

  init() {
    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.check());

    this.game.onShowGameObservable.add((show) => this.onShowGame(show));
  }

  check() {
    if(this.game.gameState.status === GAME_STATE_WAITING_FOR_PLAYERS)
      this.create();
    else
      this.dispose();
  }

  create() {
    // Make sure we're starting fresh
    this.dispose();

    let gui = new Container("StartButtonGUI");
    gui.zIndex = zIndex.GAME_DIALOG; // Keep above players
    gui.isHitTestVisible = false;
    this.game.guiTexture.addControl(gui);

    this.createStartButton(gui);

    // Auto start game if we're auto playing
    if(this.game.debugAutoPlayLocalSeat)
      setTimeout(() => this.onStartClick(), 1000);
  }

  createStartButton(gui: Container) {
    // Start Game button
    let startBox = new Rectangle("StartButtonBox");
    startBox.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    //startBox.background = "magenta";
    startBox.thickness = 0;
    startBox.isVisible = this.game.showingGame;

    gui.addControl(startBox);

    let startButton = Button.CreateSimpleButton("StartButtonButton", "Start Game");
    startButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    startButton.background = config.popUpButtonBackgroundColor;
    startButton.color = config.popUpButtonTextColor;
    startButton.thickness = 4;
    startButton.fontFamily = config.fontFamily;
    startButton.fontWeight = config.fontWeight;
    startButton.onPointerClickObservable.add(() => this.onStartClick());
    startBox.addControl(startButton);

    let startLabel = new TextBlock("StartButtonLabel");
    startLabel.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    startLabel.fontFamily = config.fontFamily;
    startLabel.fontWeight = config.fontWeight;
    startLabel.color = config.popUpButtonUnderneathTextColor;
    startLabel.text = "Press start to play now.";
    startBox.addControl(startLabel);

    this.layout();
    this.animateIn();
  }

  layout() {
    let gui = findGuiControl("StartButtonGUI");
    if(!gui)
      return;

    this.stopAnimations();

    let guiHeight = this.game.guiTexture.getSize().height;

    let boxHeight = 100 * this.game.getScale();
    let buttonHeight = boxHeight * 0.8;
    let labelHeight = boxHeight * 0.2;

    this.boxTop = (guiHeight / -4) + boxHeight * 0.5;

    let startWidth = buttonHeight * 5;

    let startBox = findGuiControl("StartButtonBox", gui);
    startBox.top = this.boxTop;
    startBox.width = startWidth + "px";
    startBox.height = boxHeight + "px";

    let startButton = findGuiControl("StartButtonButton", gui) as Button;
    startButton.width = startWidth + "px";
    startButton.height = buttonHeight + "px";
    startButton.cornerRadius = buttonHeight * 0.125;
    startButton.fontSize = (buttonHeight * 0.8) + "px";

    let startLabel = findGuiControl("StartButtonLabel", gui) as TextBlock;
    startLabel.width = startWidth + "px";
    startLabel.height = labelHeight + "px";
    startLabel.fontSize = (labelHeight * 0.8) + "px";
  }

  animateIn() {
    let box = findGuiControl("StartButtonBox");
    if(!box)
      return;

    this.stopAnimations();

    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let from = box.heightInPixels;
    let to = this.boxTop;

    let duration = 45;
    let delay = 7;

    let k0 = delay;
    let k1 = k0 + duration;

    let ka0 = k0 + Math.floor(duration * 0.05);
    let ka1 = k0 + Math.floor(duration * 0.5);

    let keyFrames = [];

    let positionAnimation = new Animation("StartButtonAnimateIn", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    keyFrames = [];
    keyFrames.push({frame: 0, value: from});
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    positionAnimation.setKeys(keyFrames);

    let alphaAnimation = new Animation("StartButtonAnimateIn", "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    keyFrames = [];
    keyFrames.push({frame: 0, value: 0});
    keyFrames.push({frame: ka0, value: 0});
    keyFrames.push({frame: ka1, value: 1});
    alphaAnimation.setKeys(keyFrames);

    // Elasic easing overshoots, then springs back
    let easingMode = EasingFunction.EASINGMODE_EASEOUT;
    let easingFunction = new ElasticEase(2, 8);
    easingFunction.setEasingMode(easingMode);
    positionAnimation.setEasingFunction(easingFunction);

    this.game.scene.beginDirectAnimation(box, [positionAnimation, alphaAnimation], 0, k1, false);

    // Init position & alpha to starting values
    box.top = from;
    box.alpha = 0;
  }

  stopAnimations() {
    let gui = findGuiControl("StartButtonGUI");
    if(!gui)
      return;

    let box = findGuiControl("StartButtonBox", gui);
    if(!box)
      return;

    let animatable = this.game.scene.getAnimatableByTarget(box);
    if(animatable !== null) {
      animatable.goToFrame(animatable.toFrame);
      animatable.stop();
    }
  }

  dispose() {
    disposeGuiControl("StartButtonGUI");
  }

  onShowGame(show: boolean) {
    let startButton = findGuiControl("StartButtonGUI");
    if(!startButton)
      return;

    startButton.isVisible = show;

    if(show === true) {
      this.layout();
      this.animateIn();
    }
  }

  onStartClick() {
    this.dispose();
    Commands.onRequestStartGame();
  }

  resized(): void {
    this.layout();
  }
}
