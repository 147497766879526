import { Observable } from "@babylonjs/core/Misc/observable";
import { ArgoSystem, IArgoSystemConfig } from "components/game/ArgoSystem";
import { Game } from "components/game/Game";
import { GAME_STATE_GAME_OVER, GAME_STATE_RESET, GAME_STATE_ROUND_OVER } from "states/game/GameState";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";

export enum RoundSystemEvent {
  // Rounds
  ROUND_START = "round_start",
  ROUND_OVER = "round_over",

  // Game
  GAME_RESET = "game_reset",
  GAME_STARTED_BY_OTHERS = "game_started_by_others",
  GAME_OVER = "game_over",
}

/**
 * Monitors Round Over and Game Over events
 * including changes to score, xp, level, rank, etc.
 */
export class RoundSystem extends ArgoSystem {
  eventObservable: Observable<RoundSystemEvent>;
  waitForAnimationsBeforeRoundOver: boolean = false;
  waitForAnimationsBeforeGameOver: boolean = false;

  constructor(game: Game, systemConfig?: IArgoSystemConfig) {
    super(game, systemConfig);

    // The eventObservable needs to be created before init is called
    // We'll just init all of the member properties here
    this.eventObservable = new Observable<RoundSystemEvent>();
  }

  init() {
    this.rootState.router.addRoute("^\/game\/round$", (patch: any, reversePatch: any, params: any) => this.onRoundChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.onGameStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/nextGameId$", (patch: any, reversePatch: any, params: any) => this.onNextGameIdChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/applySnapshotStage$", (patch: any, reversePatch: any, params: any) => this.onApplySnapshotStage(patch, reversePatch, params));

    this.game.animationSystem.onAnimationBlockingObservable.add(() => this.onAnimationBlockingChanged()); // Watch for card animations to be done
  }

  notify(event: RoundSystemEvent) {
    //console.log(`XXX - RoundSystem notify: ${event}`)
    this.eventObservable.notifyObservers(event);
  }

  onRoundChanged(patch: any, reversePatch: any, params: any) {
    // Watch out for reset
    if(patch.value === null)
      return;

    this.onRoundStart();
  }

  onGameStatusChanged(patch: any, reversePatch: any, params: any) {
    if(patch.value === GAME_STATE_ROUND_OVER)
      this.onRoundOver();

    if (patch.value === GAME_STATE_GAME_OVER) {
      this.checkGameOver();
    }

    if (patch.value === GAME_STATE_RESET) {
      this.onGameReset();
    }
  }

  onAnimationBlockingChanged() {
    if(this.waitForAnimationsBeforeRoundOver)
    {
      this.waitForAnimationsBeforeRoundOver = false;
      this.onRoundOver();
    }

    if(this.waitForAnimationsBeforeGameOver)
    {
      this.waitForAnimationsBeforeGameOver = false;
      this.onGameOver();
    }
  }

  checkGameOver() {
    if(this.game.gameState.status !== GAME_STATE_GAME_OVER)
      return;

    if(this.game.animationSystem.isBlockingRoundEnd()) {
      this.waitForAnimationsBeforeGameOver = true;
      return;
    }

    this.onGameOver();
  }

  onNextGameIdChanged(patch: any, reversePatch: any, params: any) {
    if(this.game.gameState.nextGameId && patch.op === "replace") {
      // Let the player know others are starting a new game
      if(!this.game.requestedNextGame) {
        this.onGameStartedByOthers();
      }
    }
  }

  onApplySnapshotStage(patch: any, reversePatch: any, params: any) {
    if(patch.value === APPLY_SNAPSHOT_STAGE_DONE) {
      // Check for a game loaded in the round over state
      if(this.game.gameState.status === GAME_STATE_ROUND_OVER) {
        this.onRoundOver();
      }
    }
  }

  onRoundStart() {
    this.notify(RoundSystemEvent.ROUND_START);
  }

  onRoundOver() {
    if(this.game.animationSystem.isBlockingRoundEnd())
    {
      this.waitForAnimationsBeforeRoundOver = true;
      return;
    }

    this.notify(RoundSystemEvent.ROUND_OVER);
  }

  onGameReset() {
    this.waitForAnimationsBeforeRoundOver = false;
    this.waitForAnimationsBeforeGameOver = false;

    this.notify(RoundSystemEvent.GAME_RESET);
  }

  onGameStartedByOthers() {
    this.notify(RoundSystemEvent.GAME_STARTED_BY_OTHERS);
  }

  onGameOver() {
    this.notify(RoundSystemEvent.GAME_OVER);
  }
}
