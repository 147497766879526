import { Button } from "@babylonjs/gui/2D/controls/button";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";

import { game } from "components/game/Game";
import { PopUp } from "components/ui/controls/PopUp";
import { findGuiControl, zIndex } from "components/utils/GUI";
import { config } from "utils/Config";

import backgroundImageUrl from "components/ui/ad-system/ads/insta-pass-pop-up/insta-pass-background.png";
import backgroundImageSaleUrl from "components/ui/ad-system/ads/insta-pass-pop-up/insta-pass-background_sale.png";
import instaPassButtonImageUrl from "components/ui/ad-system/ads/insta-pass-pop-up/insta-pass-button.png";
import closeIconUrl from "components/ui/modal-dialogs/modal-close.png";

/** PopUp box advertisement to purchase an InstaPass
 */
export class InstaPassPopUp extends PopUp {
  contents: Container;
  backgroundImage: Image;
  buyButton: Button;
  closeButton: Button;

  constructor(name: string) {
    super(name);

    let price = "$2.99";
    let offer = config.catalogOffers.insta_pass_1_month;
    if(offer)
      price = offer.price;

    this.thickness = 0;
    this.zIndex = zIndex.ABOVE_ALL;

    let darkness = new Rectangle(this.name + "Darkness");
    darkness.background = "black";
    darkness.alpha = 0.5;
    darkness.thickness = 0;
    this.addControl(darkness);

    this.contents = new Container(this.name + "Contents");
    this.addControl(this.contents);

    // background image can optionally have a sale version
    let imageUrl = backgroundImageUrl;
    if(config.catalogOffers.insta_pass_1_month.sale)
      imageUrl = backgroundImageSaleUrl;
    this.backgroundImage = new Image(this.name + "BackgroundImage", imageUrl);
    this.contents.addControl(this.backgroundImage);
    if(!this.backgroundImage.isLoaded)
      this.backgroundImage.onImageLoadedObservable.addOnce(() => this.layout());

    this.buyButton = Button.CreateImageButton(this.name + "PassButton", price, instaPassButtonImageUrl);
    this.buyButton.image.width = "1";
    this.buyButton.image.height = "1";
    this.buyButton.textBlock.fontFamily = config.fontFamily;
    this.buyButton.textBlock.fontWeight = config.fontWeight;
    this.buyButton.textBlock.color = "white";
    this.buyButton.textBlock.outlineColor = "#871f00";
    this.buyButton.textBlock.width = "1";
    this.buyButton.textBlock.height = "1";
    this.buyButton.textBlock.paddingLeft = 0;
    this.buyButton.textBlock.zIndex = 100;
    this.buyButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.buyButton.thickness = 0;
    this.buyButton.isVisible = (game.rootState.paymentsSupported); // Hide the InstaPass button if payments are not supported
    this.buyButton.onPointerClickObservable.add(() => this.disposeWithReason("purchaseInstaPass"));
    this.contents.addControl(this.buyButton);

    this.closeButton = Button.CreateImageOnlyButton(name + "CloseButton", closeIconUrl);
    this.closeButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.closeButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.closeButton.thickness = 0;
    this.closeButton.onPointerClickObservable.add(() => this.disposeWithReason("close"));
    this.contents.addControl(this.closeButton);

    this.layout();
  }

  layout() {
    super.layout();

    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiHeight = game.guiTexture.getSize().height - menuBarHeight;

    let height = Math.min(1080, Math.floor(guiHeight * 0.9));
    let scale = height / 1080;

    this.contents.width = height + "px";
    this.contents.height = height + "px";

    this.backgroundImage.width = height + "px";
    this.backgroundImage.height = height + "px";

    let buttonWidth = 687 * scale;
    let buttonHeight = 169 * scale;
    let buttonTextHeight = buttonHeight * 0.6;
    let buttonYPass = 796 * scale;

    this.buyButton.top = buttonYPass;
    this.buyButton.width = buttonWidth + "px";
    this.buyButton.height = buttonHeight + "px";
    this.buyButton.textBlock.fontSize = buttonTextHeight + "px";
    this.buyButton.textBlock.outlineWidth = buttonTextHeight / 16;

    this.closeButton.width = buttonHeight + "px";
    this.closeButton.height = buttonHeight + "px";
  }
  onPointerMiss() {
    // Don't close on miss
  }

  /** Helper to show the PopUp */
  static show() {
    let popUp = new InstaPassPopUp("InstaPassPopUp");
    game.guiTexture.addControl(popUp);
    return popUp;
  }
}
