import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { PowerEase } from "@babylonjs/core/Animations/easing";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { Scalar } from "@babylonjs/core/Maths/math.scalar";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { ArgoSystem } from "components/game/ArgoSystem";

import turnIndicatorArrowUrl from "components/game/turn-indicator/turn-indicator-arrow.png";
import turnIndicatorRingUrl from "components/game/turn-indicator/turn-indicator-ring.png";

const spinOnChange = false; // Spin to new seat
const fadeOnChange = true; // Fade out, change to new seat, then fade back in
const fadeOnTimeOut = true; // Fade ring to 0 as turn times out, leaving the arrow only

export class TurnIndicatorSystem extends ArgoSystem {
  ringTexture: Texture;
  arrowTexture: Texture;

  ringMaterial: StandardMaterial;
  arrowMaterial: StandardMaterial;

  ring: AbstractMesh;
  arrow: AbstractMesh;

  boomRing: AbstractMesh;

  init() {
    // Listen for seatsTurn
    this.rootState.router.addRoute("^\/game\/seatsTurn$", (patch: any, reversePatch: any, params: any) => this.onSeatsTurnChanged());
  }

  queueAssets(): void {
    this.game.assetsManager.addTextureTask("TurnIndicatorRingTask", turnIndicatorRingUrl).onSuccess = (task) => {
      this.ringTexture = task.texture;
    };

    this.game.assetsManager.addTextureTask("TurnIndicatorArrowTask", turnIndicatorArrowUrl).onSuccess = (task) => {
      this.arrowTexture = task.texture;
    };
  }

  createMaterials() {
    this.ringMaterial = new StandardMaterial("turnIndicatorRing", this.game.scene);
    this.ringMaterial.diffuseTexture = this.ringTexture;
    this.ringMaterial.opacityTexture = this.ringTexture;

    this.arrowMaterial = new StandardMaterial("turnIndicatorArrow", this.game.scene);
    this.arrowMaterial.diffuseTexture = this.arrowTexture;
    this.arrowMaterial.opacityTexture = this.arrowTexture;
  }

  createSceneElements(): void {
    let ringSize = 3.3;

    this.ring = MeshBuilder.CreatePlane("TurnIndicatorRing", {size: ringSize}, this.game.scene);
    this.ring.parent = this.game.gameRootTransformNode;
    this.ring.material = this.ringMaterial;
    this.ring.rotation.x = 90 * Math.PI / 180;
    this.ring.position.y = -0.02;
    this.ring.alphaIndex = 0; // Ensure the TurnIndicator is rendered before transparent cards
    this.ring.visibility = 0;

    this.arrow = MeshBuilder.CreatePlane("TurnIndicatorArrow", {size: ringSize}, this.game.scene);
    this.arrow.parent = this.game.gameRootTransformNode;
    this.arrow.material = this.arrowMaterial;
    this.arrow.rotation.x = 90 * Math.PI / 180;
    this.arrow.position.y = -0.01;
    this.arrow.alphaIndex = 0;
    this.arrow.visibility = 0;

    this.boomRing = MeshBuilder.CreatePlane("TurnIndicatorBoomRing", {size: ringSize}, this.game.scene);
    this.boomRing.parent = this.game.gameRootTransformNode;
    this.boomRing.material = this.ringMaterial;
    this.boomRing.rotation.x = 90 * Math.PI / 180;
    this.boomRing.position.y = -0.03;
    this.boomRing.alphaIndex = 0; // Ensure the TurnIndicator is rendered before transparent cards
    this.boomRing.visibility = 0;

    this.update();
  }

  update(advance = false, advanceIsLastCard = false) {
    if(this.game.gameState.seatsTurn === null || advanceIsLastCard) {
      // Nobody's turn
      let fadeAni = new Animation("SeatsTurnChangedFade", "visibility", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let fadeAniKeyFrames = [];
      let k0 = 0;
      let k1 = 10;
      fadeAniKeyFrames.push({frame: k0, value: this.ring.visibility });
      fadeAniKeyFrames.push({frame: k1, value: 0 });
      fadeAni.setKeys(fadeAniKeyFrames);
      this.game.scene.beginDirectAnimation(this.ring, [fadeAni], k0, k1, false);
      this.game.scene.beginDirectAnimation(this.arrow, [fadeAni], k0, k1, false);
      return;
    }

    let index = parseInt(this.game.gameState.seatsTurn.id);
    if(advance)
      index = (index + 1) % 4;

    let offset = parseInt(this.game.localSeat);
    let position = (index + 4 - offset) % 4;

    let oldAngle = this.ring.rotation.z;
    let newAngle = (-90 * position) * Math.PI / 180;

    if(this.ring.visibility === 1 && Scalar.WithinEpsilon(oldAngle, newAngle))
      return;

    // Always rotate right
    if(oldAngle < newAngle)
      oldAngle += Math.PI * 2;

    this.game.scene.stopAnimation(this.ring);
    this.game.scene.stopAnimation(this.arrow);

    let keyFrames = [];
    if(spinOnChange) {
      let a = new Animation("SeatsTurnChangedSpin", "rotation.z", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      keyFrames = [];
      let k = 10;
      keyFrames.push({frame: 0, value: oldAngle });
      keyFrames.push({frame: k, value: newAngle });
      a.setKeys(keyFrames);
      this.game.scene.beginDirectAnimation(this.ring, [a], 0, k, false);
      this.game.scene.beginDirectAnimation(this.arrow, [a], 0, k, false);
    } else {
      this.ring.rotation.z = newAngle;
      this.arrow.rotation.z = newAngle;
    }

    if(fadeOnChange) {
      let fadeAni = new Animation("SeatsTurnChangedFade", "visibility", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      keyFrames = [];
      let k0 = 0;
      let k1 = 5;
      let k2 = 10;
      keyFrames.push({frame: k0, value: this.ring.visibility });
      keyFrames.push({frame: k1, value: 0 });
      keyFrames.push({frame: k2, value: 1 });
      fadeAni.setKeys(keyFrames);
      this.game.scene.beginDirectAnimation(this.ring, [fadeAni], k0, k2, false);
      this.game.scene.beginDirectAnimation(this.arrow, [fadeAni], k0, k2, false);

      if(!spinOnChange) {
        let spinAni = new Animation("SeatsTurnChangedSpin", "rotation.z", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        keyFrames = [];
        keyFrames.push({frame: k0, value: oldAngle });
        keyFrames.push({frame: k1 - 1, value: oldAngle });
        keyFrames.push({frame: k1, value: newAngle });
        spinAni.setKeys(keyFrames);
        this.game.scene.beginDirectAnimation(this.ring, [spinAni], 0, k1, false);
        this.game.scene.beginDirectAnimation(this.arrow, [spinAni], 0, k1, false);
      }
    }

    if(fadeOnTimeOut && this.game.gameState.seatsTurn /*&& this.game.gameState.seatsTurn.id === this.game.localSeat*/) {
      let turnTimeout = this.game.gameState.getSeatTurnTimeout(this.game.gameState.seatsTurn);
      if(!turnTimeout)
        return;

      let now = new Date();
      let turnStart = this.game.gameState.turnStart;
      let secondsUntilExpired = turnTimeout - (now.getTime() - turnStart.getTime()) * 0.001;

      let k0 = 0;
      let k1 = 10;
      let k2 = secondsUntilExpired * 30;
      if(k2 <= k1)
        k2 = k1 + 1;

      let fadeOutAni = new Animation("SeatsTurnTimeoutFade", "visibility", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      keyFrames = [];
      keyFrames.push({frame: k0, value: 1 });
      keyFrames.push({frame: k1, value: 1 });
      keyFrames.push({frame: k2, value: 0 });
      fadeOutAni.setKeys(keyFrames);
      this.game.scene.beginDirectAnimation(this.ring, [fadeOutAni], k0, k2, false);
    }
  }

  onSeatsTurnChanged() {
    this.update();
  }

  /** Visually advance the turn indicator after a piece is pre-played */
  preAdvanceTurn(isLastCard: boolean) {
    this.update(true, isLastCard);
  }

  boom() {
    this.game.scene.stopAnimation(this.boomRing);

    let scale0 = new Vector3(0.1, 0.1, 0.1);
    let scale1 = new Vector3(4, 4, 4);

    this.boomRing.visibility = 1;
    this.boomRing.scaling = scale0;

    let k0 = 0;
    let k1 = 30;

    let ani_scale = new Animation("BoomRing", "scaling", 30, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let kf_scale = [];
    kf_scale.push({frame: k0, value: scale0 });
    kf_scale.push({frame: k1, value: scale1 });
    ani_scale.setKeys(kf_scale);

    let easingFunction = new PowerEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
    ani_scale.setEasingFunction(easingFunction);

    let ani_fade = new Animation("BoomRing", "visibility", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let kf_fade = [];
    kf_fade.push({frame: k0, value: 1 });
    kf_fade.push({frame: k1, value: 0 });
    ani_fade.setKeys(kf_fade);

    this.game.scene.beginDirectAnimation(this.boomRing, [ani_scale, ani_fade], k0, k1, false);
  }
}
