import { types } from "mobx-state-tree";

// tslint:disable-next-line:variable-name
const EntryState =  types
  .model("EntryState", {
    rank: types.number,
    score: types.number,
    name: types.string,
    imageUrl: types.maybeNull(types.string),
    userId: types.maybeNull(types.string), // Argo userId
    serviceUserId: types.maybeNull(types.string), // Leaderboard service's id for the user (facebook playerId)
  })
  .actions((self) => {
    return { };
  })
  .views((self) => {
    return { };
  });

// EntryState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IEntryState = typeof EntryState.Type;

export { EntryState, IEntryState };
