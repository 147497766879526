import { types } from "mobx-state-tree";

/** This is a mini version of IOptionsState for use with IGameStart. It really shouldn't be needed,
 * but IOptionsState seems to require all properties to be set even though they're optional.
 */
export interface IMinOptions {
  name: string;
  maxRounds?: number;
  endScore?: number;
  scoring?: string;

  // Multiplayer Options
  multiplayer?: boolean;
  standAlone?: boolean;
  allowFriends?: boolean;
  allowAnyone?: boolean;
  turnTimeoutPlay?: number;
  turnTimeoutNewRound?: number;
}

// tslint:disable-next-line:variable-name
const OptionsState = types
  .model("OptionsState", {
    name: types.optional(types.string, "default"),  // name of predefined set of Options, or custom
    // Rules
    maxRounds: types.optional(types.number, 0),  // max number of rounds to be played
    endScore: types.optional(types.number, 0),  // The score needed to end/win the game
    scoring: types.optional(types.string, "regular"), // used in hearts to change scoring to spot

    // Multiplayer Options
    multiplayer: types.optional(types.boolean, false),  // The game allows more then 1 player
    standAlone: types.optional(types.boolean, false),   // the game is completely offline, never touching the server. which is different then game.offline, which means the game was created online, but there was only one player so played offline
    allowFriends: types.optional(types.boolean, false),
    allowAnyone: types.optional(types.boolean, false),
    turnTimeoutPlay: types.maybeNull(types.number), // How many seconds after their turn starts before autoplaying for them
    turnTimeoutNewRound: types.maybeNull(types.number), // How many seconds after the new round starts before auto-advancing (includes bidding & summary screen)
  });

// OptionsState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IOptionsState = typeof OptionsState.Type;

export { OptionsState, IOptionsState };
