import { types } from "mobx-state-tree";
import { IPieceState, PieceState } from "states/game/PieceState";
import { ISeatState, SeatState } from "states/game/SeatState";

// tslint:disable-next-line:variable-name
const TrickState = types
  .model("TrickState", {
    seatLead: types.maybeNull(types.reference(SeatState)),
    seatWinner: types.maybeNull(types.reference(SeatState)),
    pieces: types.array(types.maybeNull(types.reference(PieceState))),
  })
  .views((self) => {

    function getPieceForSeat(seatOrId: ISeatState | string): IPieceState {
      let seatId = "";
      if(typeof seatOrId === "string")
        seatId = seatOrId;
      else
        seatId = seatOrId.id;

      let index = parseInt(seatId);

      if(index < self.pieces.length)
        return self.pieces[index];
      else
        return null;
    }

    return { getPieceForSeat };
  })
  .actions((self) => {

    function setSeatLead(seat: ISeatState) {
      self.seatLead = seat;
    }

    function setSeatWinner(seat: ISeatState) {
      self.seatWinner = seat;
    }

    function setPiece(seat: ISeatState, piece: IPieceState) {
      let index = parseInt(seat.id);
      // mobx-state-tree doesn't allow gaps like javascript, so fill in nulls if needed
      while(self.pieces.length < index)
        self.pieces.push(null);
      self.pieces[index] = piece;
    }

    return { setSeatLead, setSeatWinner, setPiece };
  });

type ITrickState = typeof TrickState.Type;

export { TrickState, ITrickState };
