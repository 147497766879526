import { mergeConfig } from "utils/Config";

import ahUrl from "./ah.mp3";
import excellentUrl from "./excellent.mp3";
import goodJobUrl from "./good-job.mp3";
import highScoreUrl from "./high-score.mp3";
import wayToGoUrl from "./way-to-go.mp3";
import yesUrl from "./yes.mp3";

mergeConfig({
  sounds: {
    goodReaction: {url: [goodJobUrl, excellentUrl, wayToGoUrl, yesUrl], volume: 1.0},
    badReaction: {url: ahUrl, volume: 1.0},
    highScore: {url: highScoreUrl, volume: 1.0},
  },
});
