/** This state keeps track of which first time help messages the user has seen */
import { types } from "mobx-state-tree";

import { config } from "utils/Config";

// tslint:disable-next-line:variable-name
const HelpState = types
  .model("HelpState", {
    seenCnt: types.map(types.number),  // map of helpKey strings to count of times help seen, ie { myTurn_bid: 3 }
  })
  .views((self) => {

    /** returns how many times the user has seen the help message */
    function getSeenCnt(helpKey: string): number {
      let cnt = 0;
      if(self.seenCnt.has(helpKey))
        cnt = self.seenCnt.get(helpKey);
      return cnt;
    }

    /** return if a help message should be shown to the user */
    function shouldShow(helpKey: string): boolean {
      // first see if we have a config for this help item, then check if the user has already seen it maxViews times
      let helpConfig = config.help[helpKey];
      if(helpConfig && (!helpConfig.maxViews || (helpConfig.maxViews && getSeenCnt(helpKey) < helpConfig.maxViews)))
        return true;

      return false;
    }

    return { getSeenCnt, shouldShow };
  })
  .actions((self) => {

    /** increment the number of times they've seen a help item */
    function incSeenCnt(userId: string, helpKey: string) {
      self.seenCnt.set(helpKey, self.getSeenCnt(helpKey) + 1);
    }

    /** resets all help seen counters. So that the user will see all the help again. */
    function resetSeenCnt(userId: string) {
      self.seenCnt.clear();
    }

    return { incSeenCnt, resetSeenCnt };
  });

// IHelpState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IHelpState = typeof HelpState.Type;

export { HelpState, IHelpState };
