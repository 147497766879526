import { PointerInfoPre } from "@babylonjs/core/Events/pointerEvents";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import { game } from "components/game/Game";
import { ImageWithFallback } from "components/ui/Controls";
import { PopUp } from "components/ui/controls/PopUp";
import { findGuiControl, zIndex } from "components/utils/GUI";
import { getXPLevelTierColor } from "components/utils/XPTierColors";
import { config } from "utils/Config";

import pawn0AvatarUrl from "components/game/avatars/pawn0.png";
import heartEmptyUrl from "components/ui/user-status/heart-empty.png";
import heartUrl from "components/ui/user-status/heart.png";
import { IEntryState } from "states/leaderboard/EntryState";

export class PlayerInfoPopUp extends PopUp {
  userId: string;
  userRouteId: number;
  statusRouteId: number;
  applyingSnapshot: boolean;

  imageContainer: Rectangle;
  playerImage: ImageWithFallback;
  nameText: TextBlock;
  levelText: TextBlock;
  scoreText: TextBlock;
  leaderboardRankText: TextBlock;
  leaderboardScoreText: TextBlock;

  constructor(userId: string, bot = false) {
    super("PlayerInfoPopUp");

    this.userId = userId;

    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height;

    let k = 3;
    let height = Math.min(guiWidth / 5, guiHeight / k);
    let width = height * 3;

    let left = game.scene.pointerX;
    if(left + width > guiWidth)
      left = guiWidth - width;
    let top = game.scene.pointerY;
    if(top + height > guiHeight)
      top = guiHeight - height;

    let imageWidth = height;

    let bgWidth = width;
    let bgHeight = height * 0.9;

    let contentLeft = Math.floor(bgHeight / 10);
    let contentWidth = width - imageWidth - contentLeft * 2 - 2;
    let contentHeight = bgHeight;

    let topBarHeight = contentHeight / 5;
    let topBarFontHeight = topBarHeight * 0.8;

    let lineHeight = (contentHeight - topBarHeight) / 3;
    let lineFontHeight = lineHeight * 0.8;

    // rank, score, name
    this.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.left = left;
    this.top = top;
    this.width = width + "px";
    this.height = height + "px";
    //this.background = "magenta";
    this.thickness = 0;
    this.isHitTestVisible = false;
    this.zIndex = zIndex.MENU;
    game.guiTexture.addControl(this);

    let bg = new Rectangle("PlayerInfoPopUpBackground");
    bg.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    bg.width = bgWidth + "px";
    bg.height = bgHeight + "px";
    bg.background = "#0c0338";
    bg.color = "black";
    bg.thickness = 2;
    bg.cornerRadius = bgHeight / 16;
    this.addControl(bg);

    let topBarBG = new Rectangle("PlayerInfoPopUpTopBar");
    topBarBG.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    topBarBG.width = bgWidth + "px";
    topBarBG.height = topBarHeight + "px";
    topBarBG.background = "#3609cd";
    topBarBG.thickness = 0;
    bg.addControl(topBarBG);

    this.imageContainer = new Rectangle("PlayerInfoPopUpPlayerImageBackground");
    this.imageContainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.imageContainer.width = imageWidth + "px";
    this.imageContainer.height = imageWidth + "px";
    this.imageContainer.background = "black";
    this.imageContainer.color = "black";
    this.imageContainer.thickness = 2;
    this.imageContainer.cornerRadius = imageWidth / 8;
    this.addControl(this.imageContainer);

    this.playerImage = new ImageWithFallback("PlayerInfoPopUpPlayerImage", pawn0AvatarUrl);
    this.imageContainer.addControl(this.playerImage);

    let content = new StackPanel("PlayerInfoPopUpContent");
    content.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    content.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    content.left = -contentLeft;
    content.width = contentWidth + "px";
    content.height = contentHeight + "px";
    content.isVertical = true;
    bg.addControl(content);

    let topBar = new Rectangle("PlayerInfoPopUpTopBar");
    topBar.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    topBar.height = topBarHeight + "px";
    topBar.thickness = 0;
    content.addControl(topBar);

    this.nameText = new TextBlock("PlayerInfoPopUpName");
    this.nameText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.nameText.color = "white";
    this.nameText.fontSize = topBarFontHeight + "px";
    this.nameText.fontFamily = config.fontFamily;
    this.nameText.fontWeight = config.fontWeight;
    topBar.addControl(this.nameText);

    this.levelText = new TextBlock("PlayerInfoPopUpLevel");
    this.levelText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.levelText.color = "white";
    this.levelText.fontSize = topBarFontHeight + "px";
    this.levelText.fontFamily = config.fontFamily;
    this.levelText.fontWeight = config.fontWeight;
    topBar.addControl(this.levelText);

    let leaderboardBar = new StackPanel("PlayerInfoPopUpLeaderboardBar");
    leaderboardBar.isVertical = false;
    leaderboardBar.height = lineHeight + "px";
    content.addControl(leaderboardBar);

    this.leaderboardRankText = new TextBlock("PlayerInfoPopUpLevel");
    this.leaderboardRankText.width = (contentWidth * 0.5) + "px";
    this.leaderboardRankText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.leaderboardRankText.color = "white";
    this.leaderboardRankText.fontSize = topBarFontHeight + "px";
    this.leaderboardRankText.fontFamily = config.fontFamily;
    this.leaderboardRankText.fontWeight = config.fontWeight;
    leaderboardBar.addControl(this.leaderboardRankText);

    this.leaderboardScoreText = new TextBlock("PlayerInfoPopUpLevel");
    this.leaderboardScoreText.width = (contentWidth * 0.5) + "px";
    this.leaderboardScoreText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.leaderboardScoreText.color = "white";
    this.leaderboardScoreText.fontSize = topBarFontHeight + "px";
    this.leaderboardScoreText.fontFamily = config.fontFamily;
    this.leaderboardScoreText.fontWeight = config.fontWeight;
    leaderboardBar.addControl(this.leaderboardScoreText);

    let heartBar = new StackPanel("PlayerInfoPopUpHeartBar");
    heartBar.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    heartBar.width = (lineHeight * 5) + "px";
    heartBar.height = lineHeight + "px";
    heartBar.isVertical = false;
    content.addControl(heartBar);

    let heartBox = new Rectangle("PlayerInfoPopUpHeartBox");
    heartBox.width = (lineHeight * 3) + "px";
    heartBox.height = lineHeight + "px";
    heartBox.thickness = 0;
    heartBar.addControl(heartBox);

    for(let heartIndex = 0; heartIndex < 3; heartIndex++) {
      let emptyHeart = new Image("UserStatusGUIEmptyHeart" + heartIndex, heartEmptyUrl);
      emptyHeart.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      emptyHeart.left = heartIndex * lineHeight;
      emptyHeart.width = lineHeight + "px";
      emptyHeart.height = lineHeight + "px";
      heartBox.addControl(emptyHeart);

      let heart = new Image("PlayerInfoPopUpHeart" + heartIndex, heartUrl);
      heart.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      heart.left = heartIndex * lineHeight;
      heart.width = lineHeight + "px";
      heart.height = lineHeight + "px";
      heartBox.addControl(heart);
    }

    this.scoreText = new TextBlock("PlayerInfoPopUpScore");
    this.scoreText.width = (lineHeight * 2) + "px";
    this.scoreText.color = "white";
    this.scoreText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.scoreText.fontSize = lineFontHeight + "px";
    this.scoreText.fontFamily = config.fontFamily;
    this.scoreText.fontWeight = config.fontWeight;
    this.scoreText.text = "0";
    heartBar.addControl(this.scoreText);

    // Close if the game status changes
    this.statusRouteId = game.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.dispose());

    // Close if clicked
    this.onPointerDownObservable.add(() => this.dispose());

    if(bot) {
      heartBar.isVisible = false;
      this.updateBot();
      return;
    }

    // Init from the user data, if it's already loaded
    this.update();

    // Listen for updates
    this.userRouteId = game.rootState.router.addRoute(`^\/users\/users\/${userId}\/`, (patch: any, reversePatch: any, params: any) => this.onUserPatch(patch, reversePatch, params));
  }

  public set left(value: number) {
    let guiWidth = game.guiTexture.getSize().width;
    if(value < 0)
      value = 0;
    if(value + this.widthInPixels > guiWidth)
      value = guiWidth - this.widthInPixels;

    super.left = value;
  }

  public set top(value: number) {
    let guiHeight = game.guiTexture.getSize().height;
    if(value < 0)
      value = 0;
    if(value + this.heightInPixels > guiHeight)
      value = guiHeight - this.heightInPixels;

    super.top = value;
  }

  setImage(url: string) {
    if(!url)
      url = pawn0AvatarUrl;

    this.playerImage.setSource(url, pawn0AvatarUrl);
  }

  update() {
    if(!this.userId)
      return;

    // Get the user data
    let user = game.rootState.users.getUser(this.userId);
    if(!user)
      return;

    // Look for a leaderboard entry for our userId
    let board = game.rootState.leaderboards.getLeaderboard(config.defaultLeaderboard);
    let entry = board.entries.find((e: IEntryState) => e.userId === this.userId);

    // Update image URL
    this.setImage(user.imageUrl);

    let color = getXPLevelTierColor(user.xp.level);

    this.nameText.color = color;
    this.nameText.text = user.name;

    this.levelText.color = color;
    this.levelText.text = `Level ${user.xp.level}`;

    if(entry) {
      this.leaderboardRankText.text = `Rank: ${entry.rank}`;
      this.leaderboardScoreText.text = "" + entry.score;
    }

    if(user.metaGame) {
      let lives = user.metaGame.lives;
      for(let heartIndex = 0; heartIndex < 3; heartIndex++) {
        let heart = findGuiControl("PlayerInfoPopUpHeart" + heartIndex, this);
        heart.isVisible = heartIndex < lives;
      }

      this.scoreText.text = "" + user.metaGame.score;
    }
  }

  updateBot() {
    // Find a player entry instead of a user entry
    let player = game.gameState.getPlayer(this.userId);
    if(!player)
      return;

    if(player.imageUrl)
      this.setImage(player.imageUrl);

    this.nameText.text = player.name;
    this.levelText.text = "BOT";
  }

  onUserPatch(patch: any, reversePatch: any, params: any) {
    // Ignore patches while applying snapshot
    if(patch.op === "replace" && patch.path.endsWith("/applySnapshotStage")) {
      if(patch.value === "start")
        this.applyingSnapshot = true;
      else if(patch.value === "done")
        this.applyingSnapshot = false;
    }

    if(this.applyingSnapshot)
      return;

    this.update();
  }

  onScenePrePointerDown(info: PointerInfoPre) {
    this.dispose();
  }

  onResized() {
    this.dispose();
  }

  dispose() {
    super.dispose();

    if(this.userRouteId) {
      game.rootState.router.removeRoute(this.userRouteId);
      this.userRouteId = null;
    }

    if(this.statusRouteId) {
      game.rootState.router.removeRoute(this.statusRouteId);
      this.statusRouteId = null;
    }

    this.imageContainer = null;
    this.playerImage = null;
    this.nameText = null;
    this.levelText = null;
    this.scoreText = null;
    this.leaderboardRankText = null;
    this.leaderboardScoreText = null;
  }
}
