
/** Base Analytics interface, implemented by FacebookIGAnalytics and GameAnalytics */
export interface IAnalytics {
  logEvent(eventName: string, value: number, parameters: any): void;
  setUserId(userId: string): void;
}

/** analytics class that does nothing, used during development to avoid pulluting live analytics */
export class DummyAnalytics implements IAnalytics {
  logEvent(eventName: string, value: number, parameters: any) {}
  setUserId(userId: string) {}
}
