import { mergeConfig } from "utils/Config";

import avatarUrl_Aayla from "components/game/avatars/images/Aayla.jpg";
import avatarUrl_Azhar from "components/game/avatars/images/Azhar.jpg";
import avatarUrl_Charlotte from "components/game/avatars/images/Charlotte.jpg";
import avatarUrl_Effie from "components/game/avatars/images/Effie.jpg";
import avatarUrl_Ellen from "components/game/avatars/images/Ellen.jpg";
import avatarUrl_Enzo from "components/game/avatars/images/Enzo.jpg";
import avatarUrl_Estelle from "components/game/avatars/images/Estelle.jpg";
import avatarUrl_Imene from "components/game/avatars/images/Imene.jpg";
import avatarUrl_Jiemba from "components/game/avatars/images/Jiemba.jpg";
import avatarUrl_Joe from "components/game/avatars/images/Joe.jpg";
import avatarUrl_Ju_long from "components/game/avatars/images/Ju-long.jpg";
import avatarUrl_Kalama from "components/game/avatars/images/Kalama.jpg";
import avatarUrl_Lucas from "components/game/avatars/images/Lucas.jpg";
import avatarUrl_Lucy from "components/game/avatars/images/Lucy.jpg";
import avatarUrl_Noah from "components/game/avatars/images/Noah.jpg";
import avatarUrl_Odawa from "components/game/avatars/images/Odawa.jpg";
import avatarUrl_Sarah from "components/game/avatars/images/Sarah.jpg";
import avatarUrl_Sid from "components/game/avatars/images/Sid.jpg";
import avatarUrl_Tommen from "components/game/avatars/images/Tommen.jpg";
import avatarUrl_Xiulan from "components/game/avatars/images/Xiulan.jpg";

// XXX - The name list is duplicated in ~/Projects/Argo/src/states/game/GameState.ts for the server

const botAvatarImageUrls: {[key: string]: string; } = {
  "Aayla": avatarUrl_Aayla,
  "Azhar": avatarUrl_Azhar,
  "Charlotte": avatarUrl_Charlotte,
  "Effie": avatarUrl_Effie,
  "Ellen": avatarUrl_Ellen,
  "Enzo": avatarUrl_Enzo,
  "Estelle": avatarUrl_Estelle,
  "Imene": avatarUrl_Imene,
  "Jiemba": avatarUrl_Jiemba,
  "Joe": avatarUrl_Joe,
  "Ju-long": avatarUrl_Ju_long,
  "Kalama": avatarUrl_Kalama,
  "Lucas": avatarUrl_Lucas,
  "Lucy": avatarUrl_Lucy,
  "Noah": avatarUrl_Noah,
  "Odawa": avatarUrl_Odawa,
  "Sarah": avatarUrl_Sarah,
  "Sid": avatarUrl_Sid,
  "Tommen": avatarUrl_Tommen,
  "Xiulan": avatarUrl_Xiulan,
};

const botAvatarNames: string[] = Object.keys(botAvatarImageUrls);

mergeConfig({
    botAvatarImageUrls,
    botAvatarNames,
});
