import { types } from "mobx-state-tree";

import { ISeatState, SeatState } from "states/game/SeatState";

export const FACING_UP = "up";
export const FACING_DOWN = "down";
export type Facing = typeof FACING_UP  | typeof FACING_DOWN;

/* value for a card ranges from 1 to 52:
1-13 is A-K Clubs
14-26 is A-K Diamonds
27-39 is A-K Spades
39-52 is A-K Hearts
*/

/*
AC 1     AD 14    AS 27    AH 40
2C 2     2D 15    2S 28    2H 41
3C 3     3D 16    3S 29    3H 42
4C 4     4D 17    4S 30    4H 43
5C 5     5D 18    5S 31    5H 44
6C 6     6D 19    6S 32    6H 45
7C 7     7D 20    7S 33    7H 46
8C 8     8D 21    8S 34    8H 47
9C 9     9D 22    9S 35    9H 48
0C 10    0D 23    0S 36    0H 49
JC 11    JD 24    JS 37    JH 50
QC 12    QD 25    QS 38    QH 51
KC 13    KD 26    KS 39    KH 52
*/

export const CLUBS =    0;
export const DIAMONDS = 1;
export const SPADES =   2;
export const HEARTS =   3;
const suitShortName = ["C", "D", "S", "H"];
const suitLongName = ["Club", "Diamond", "Spade", "Heart"];

export const RED =      0;
export const BLACK =    1;

export const ACE =      1;
export const JACK =     11;
export const QUEEN =    12;
export const KING =     13;
export const ACE_HIGH = 14;
export const LBOWER =   15;
export const RBOWER =   16;
export const JOKER =     17;
const rankShortName = ["", "A", "2", "3", "4", "5", "6", "7", "8", "9", "0", "J", "Q", "K", "A"];
const rankLongName = ["", "Ace", "2", "3", "4", "5", "6", "7", "8", "9", "10", "Jack", "Queen", "King", "Ace"];

/** returns the internal value of a card with suit and rank. ie 4 of Diamonds is 17 */
export function cardValue(suit: number, rank: number) {
  return 13 * suit + rank;
}

// tslint:disable-next-line:variable-name
const PieceState = types
  .model("PileState", {
    name: types.identifier,
    value: types.number,
    //facing: types.string,  // FACING_UP, FACING_DOWN. I tried types.enumeration here, but then couldn't set it to a string in setFacing
    facing: types.optional(types.enumeration("Facing", [FACING_UP, FACING_DOWN]), FACING_DOWN),
    seat: types.maybeNull(types.reference(SeatState)), // seat that owns/last played this piece
    selected: types.optional(types.boolean, false), // flag to indicate piece is selected, used for passing cards in trick games
  })
  .actions((self) => {

    function setFacing(facing: Facing) {
      self.facing = facing;
    }

    function setSeat(seat: ISeatState) {
      self.seat = seat;
    }

    function setSelected(userId: string, selected: boolean) {
      self.selected = selected;
    }

    function setValue(value: number) {
      self.value = value;
    }

    return { setFacing, setSeat, setSelected, setValue };
  })
  .views((self) => {

    /** calculate suit from value. returns CLUBS, DIAMONDS, HEARTS, or SPADES  */
    function suit() {
      if(self.value < 0)
        return -1;
      let suitN = Math.floor((self.value - 1) / 13);
      if(suitN < 0 || suitN > HEARTS)
        return -1;
      return suitN;
    }

    return { suit };
  })

  // we start a new views section so that color() can call suit(), if they were in the same views, then color() would not find suit()
 .views((self) => {
    function color() {
      if([SPADES, CLUBS].indexOf(self.suit()) > -1)
        return BLACK;
      return RED;
    }

    /** get the rank of a card ignoring suit, ie returns 1 to 13 */
    function rank() {
      if(self.value < 0)
        return -1;
      return (self.value - 1) % 13 + 1;
    }

    function rankAceHigh() {
      let pieceRank =  rank();
      if(pieceRank === ACE)
        return ACE_HIGH;
      return pieceRank;
    }

    /** returns a short name/abbreviation of value, ie AH for ace of hearts  */
    function valueShortName() {
      return rankShortName[rank()] + suitShortName[self.suit()];
    }

    /** returns a long name of value, ie Ace of Hearts
     * prefixAorAn - means to prefix with either a or an, ie an Ace of Hearts, or a King of Hearts
     */
    function valueLongName(prefixAorAn = false) {
      const rankName = rankLongName[rank()];
      let prefix = "";
      if(prefixAorAn) {
        if(rankName === "Ace")
          prefix = "an ";
        else
          prefix = "a ";
      }

      return prefix + rankName + " of " + suitLongName[self.suit()] + "s";
    }

    // returns name of suit, ie Hearts, Spades, Euchre, Club
    function suitName(): string {
      return suitLongName[self.suit()];
    }

    return {  color, rank, rankAceHigh, suitName, valueLongName, valueShortName };
  });

// PieceState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IPieceState = typeof PieceState.Type;

export { PieceState, IPieceState };
