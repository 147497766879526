import { mergeConfig } from "utils/Config";

const SECONDS_IN_A_DAY = 86400;

export interface ICatalogItem {
  maxQuantity: number; // currently not implemented
}

export interface ICatalogOffer {
  item: string; // name of item, ie insta_pass
  term: number; // in seconds
  quantity: number;
  sale: boolean; // indicate if item is on sale, which may show sale versions of ads and buttons

  // merged from facebook catalog
  price: string; // The price of the product
  priceCurrencyCode: string; // The currency code for the product
  //title: string; // The title of the product
  //productID: string; // The product's game-specified identifier
  //description?: string; // The product description
  //imageURI?: string; // A link to the product's associated image
}

interface ICatalogItemMap {[index: string]: ICatalogItem; }
interface ICatalogOfferMap {[index: string]: ICatalogOffer; }

mergeConfig({
  catalogItems: { // in-app items
    insta_pass: {
      maxQuantity: 1,
    },
  } as ICatalogItemMap,

  catalogOffers: {
    insta_pass_1_month: {
      item: "insta_pass",
      term: 31 * SECONDS_IN_A_DAY,
      quantity: 1,
      price: "2.99",
      priceCurrencyCode: "USD",
      sale: true,
    },
  } as ICatalogOfferMap,
});
