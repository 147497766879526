import { ArgoSystem } from "components/game/ArgoSystem";
import SocketCluster from "socketcluster-client";
import { GAME_STATE_CREATE, GAME_STATE_GAME_OVER } from "states/game/GameState";
import { waitForAuth } from "states/state-sync/StateSyncClient";
import { logger } from "utils/logger";

// Note this file contains both LoadTestSystem (this is used by the load test client), and LoadTestMonitorSystem (used by debug client by admin to monitor and control load test)

/** System to report game status to monitor and listen for commands */
export class LoadTestSystem extends ArgoSystem {
  channel: any;

  init() {
    (window as any).loadTestCommand = "load_test_working";

    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.onGameStatusChanged(patch, reversePatch, params));

    // subscribe to __LOAD_TEST__ channel to report to monitor, and watch for commands
    waitForAuth().then((client: SocketCluster.SCClientSocket) => {
      this.channel = client.subscribe("__LOAD_TEST__");

      this.channel.on("subscribe", () => {
        this.channel.publish({ userId: this.rootState.user.id, status: "subscribed" });
      });

      this.channel.watch((data: any) => {
        logger.info("data", data.command);
        // signal to stop test, for now just stop playing game
        if(data.command === "load_test_stop") {
          this.game.leaveGame();
          (window as any).loadTestCommand = data.command; // the test runner (index.js) will watch for loadTestCommand value to change
        }
      });
    });
  }

  onGameStatusChanged(patch: any, reversePatch: any, params: any) {
    // report games starting and ending to monitor
    if(patch.value === GAME_STATE_CREATE || patch.value === GAME_STATE_GAME_OVER)
      this.channel.publish({ userId: this.rootState.user.id, gameStatus: patch.value });
  }
}

/** Monitor to listen for status of load test clients and send commands */
export class LoadTestMonitorSystem extends ArgoSystem {
  channel: any;

  init() {
    // Subscribe to __LOAD_TEST__ channel to listen for updates from test clients
    waitForAuth().then((client: SocketCluster.SCClientSocket) => {
      this.channel = client.subscribe("__LOAD_TEST__");

      this.channel.on("subscribe", () => {
        logger.info("Subscribed to __LOAD_TEST__ channel.");
      });

      this.channel.watch((data: any) => {
        logger.info("Load Test Event:", data);
      });
    });
  }

  /** publishes stop signal to stop all load test clients */
  stopClients() {
    if(!this.channel) {
      logger.info("Not subscribed to channel yet");
      return;
    }

    this.channel.publish({ command: "load_test_stop"});
  }
}
