/**
 * StateSyncGameMiddleware extends StateSyncMiddleware to configure it specifically for GameState
 */

import { IPatchQueueState } from "states/PatchQueueState";
import { StateSyncMiddleware } from "states/state-sync/StateSyncMiddleware";

export class StateSyncGameMiddleware extends StateSyncMiddleware {
  warnOnSendingTooFast = true; // warn if we're sending game actions too fast, which can cause the server to process them out of order due to db load time variance.

  constructor(state: IPatchQueueState) {
    super(state);
    // list of actions to intercept and forward to server (_forwardActions still gets ran locally, _forwardOnlyActions does not)
    // it is critical that the first argument to the function is the playerId of the player doing the action
    // the server checks that the first argument matches the authenticated user id before running action
    this._forwardActions = [
      // BaseStateSync
      "setClientName",
    ];

    this._forwardOnlyActions = [
      // GameState
      "addPlayer",
      "applySyncToServerSnapshot",
      "clickPiece",
      "leaveGame",
      "requestNextGameId",
      "requestSeat",
      "requestStartGame",
      "setBid",
      "setSeatReady",
      "updatePlayerInfo",

      //PilesGameState
      "selectPieces",

      // PieceState
      "setSelected",
    ];
  }
}
