/**
 * Parameters for displaying ads
 */

/** Status response to requesting an ad to be shown */
export enum AdStatus {
  // Error conditions
  AD_STATUS_DISABLED = "disabled", // The ad is disabled in the config
  AD_STATUS_NO_PROVIDER = "no_provider", // There is no ad provider registered for the configured type
  AD_STATUS_UNSUPPORTED = "unsupported", // The ad type is not supported
  AD_STATUS_MISCONFIGURED = "misconfigured", // The ad key isn't configured properly
  AD_STATUS_NOT_PRELOADED = "not_preloaded", // The ad was not preloaded
  AD_STATUS_UNAVAILABLE = "unavailable", // No ad was available to show
  AD_STATUS_PRELOADING = "preloading", // The ad is still preloading
  AD_STATUS_WAITING_FOR_FILL = "waiting_for_fill", // The ad is waiting after a "no fill" error before trying to preload again
  AD_STATUS_READY = "ready", // The ad is preloaded and ready to show
  AD_STATUS_ERROR = "error", // There was an error when showing the ad
  AD_STATUS_UNKNOWN = "unknown", // There was an unknown error when showing the ad
  AD_STATUS_TIMEOUT = "timeout", // Preloading appears to have timed out (This is a guess based on a message from an UNKNOWN error)
  AD_STATUS_NOT_SHOWING = "not_showing", // The caller passed false to showAd
  AD_STATUS_SHOW_IN_PROGRESS = "show_in_progress", // There's already a showAsync call in progress
  AD_STATUS_TOO_MANY_INSTANCES = "too_many_instances", // We had too many ad instances active at once
  AD_STATUS_FREQUENT_LOAD = "frequent_load", // We're requsting ads too frequently
  AD_STATUS_NETWORK_ERROR = "network_error", // There was a network error loading the ad
  AD_STATUS_NO_FILL = "no_fill", // There wasn't an ad available to load
  AD_STATUS_PAYMENTS_REQUIRED = "payments_required", // The ad requires payments, but payments are not supported

  // Reward ad not completed
  // The actual error is USER_INPUT. We've received it with the error message "reward not completed".
  // It's undocumented, and unknown if Facebook can return this for interstitial ads
  // It also seems to be untestable on Desktop or iOS Messenger, because there doesn't seem to be any way to cancel the ad without closing the game
  AD_STATUS_REWARD_NOT_COMPLETED = "reward_not_completed",

  // Success conditions
  AD_STATUS_FINISHED = "finished", // The ad was displayed successfully and has finished

  // Actions
  // The ad was shown successfully, and attempted to perform an action in response to a user button click (ArgoAdProvider only)
  AD_STATUS_ACTION_CANCELED = "action_canceled", // The user cancelled the action
  AD_STATUS_ACTION_ERROR = "action_error", // The action resulted in an error
  AD_STATUS_ACTION_SUCCEEDED = "action_succeeded", // The action was successfully performed
}

export interface IAdParams {
  key: string;
  onDoneCallback: (status: AdStatus) => void;
}

export interface IAdPlacement {
  id: string; // The provider specific id of the ad
  type: string; // The provider type, currently "interstitial" and "reward" are two types of Facebook ads, and popUp is our own html pop up ads
}

export interface IAdPlacementMap {
  [index: string]: IAdPlacement;
}

export interface IAdProvider {
  type: string;
  preload: (key: string, adConfig: IAdPlacement) => void;
  isPreloaded: (key: string, adConfig: IAdPlacement) => boolean;
  show: (params: IAdParams, adConfig: IAdPlacement) => void;
  showSpecificAd?: (params: IAdParams, specificAdName: string) => void;
}

export interface IAdPreloadStatus {
  key: string;
  status: AdStatus;
}
