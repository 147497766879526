import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import { config } from "utils/Config";

import { ArgoSystem } from "components/game/ArgoSystem";
import { LeaderboardPlayer, LeaderboardPlayerCompact } from "components/ui/LeaderboardPlayer";
import { LeaderboardScreen } from "components/ui/LeaderboardScreen";
import { PlayerInfoPopUp } from "components/ui/PlayerInfoPopUp";
import { disposeGuiControl, findGuiControl, getControlGlobalPosition, zIndex } from "components/utils/GUI";
import { ROOT_STATE_HOME_SCREEN } from "states/RootState";

import pawn0AvatarUrl from "components/game/avatars/pawn0.png";
import largeStarUrl from "components/ui/leaderboard-sidebar/large-star.png";
import panelUrl from "components/ui/leaderboard-sidebar/panel.png";
import smallStarUrl from "components/ui/leaderboard-sidebar/small-star.png";

export class LeaderboardSidebarSystem extends ArgoSystem {
  inOutAnimation: Animatable;
  leaderboardName = config.defaultLeaderboard; // name of leaderboard that we are currently displaying

  init() {
    // Watch for displaying the status gui
    this.rootState.router.addRoute("^\/status$", (patch: any, reversePatch: any, params: any) => this.check());
    this.rootState.router.addRoute("^\/leaderboards\/boards\/(\\d*)\/entries\/(\\d*)", (patch: any, reversePatch: any, params: any) => this.onLeaderboardEntryChanged(patch, reversePatch, params));
  }

  check() {
    if(this.rootState.status === ROOT_STATE_HOME_SCREEN)
      this.create();
    else
      this.animateOut();
  }

  create(animate = true) {
    if(!config.haveLeaderboards)
      return;

    // Make sure we're starting fresh
    this.dispose();

    let box = new Rectangle("LeaderboardSidebarGUI");
    box.zIndex = zIndex.ABOVE_GAME; // Keep above bidbox
    box.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    box.color = "yellow";
    box.thickness = 0;
    this.game.guiTexture.addControl(box);

    let panel = new Rectangle("LeaderboardSidebarGUIPanel");
    panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    panel.thickness = 0;
    box.addControl(panel);

    let panelBackground = new Image("LeaderboardSidebarGUIBackground", panelUrl);
    panel.addControl(panelBackground);

    let largeStar = new Image("LeaderboardSidebarGUILargeStar", largeStarUrl);
    largeStar.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    largeStar.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    box.addControl(largeStar);

    let smallStar = new Image("LeaderboardSidebarGUISmallStar", smallStarUrl);
    smallStar.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    smallStar.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    box.addControl(smallStar);

    let stack = new StackPanel("LeaderboardSidebarGUIStack");
    stack.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    panel.addControl(stack);

    let title = new TextBlock("LeaderboardSidebarGUITitle");
    title.fontFamily = config.fontFamily;
    title.fontWeight = config.fontWeight;
    title.color = "#ffce5a";
    title.text = "Best Scores";
    stack.addControl(title);

    let playerBox = new Rectangle("LeaderboardSidebarGUIPlayerBox");
    //playerBox.background = "magenta";
    playerBox.thickness = 0;
    stack.addControl(playerBox);

    for(let playerIndex = 0; playerIndex < 3; playerIndex++) {
      let player = new LeaderboardPlayerCompact("LeaderboardSidebarGUIPlayer_" + playerIndex);
      player.imageContainer.onPointerEnterObservable.add(() => this.onPointerEnter(player));
      player.imageContainer.onPointerOutObservable.add(() => this.onPointerOut());
      player.imageContainer.onPointerClickObservable.add(() => this.onPointerEnter(player));
      playerBox.addControl(player);
    }

    let buttonSpacer = new Control("LeaderboardSidebarGUIButtonSpader");
    stack.addControl(buttonSpacer);

    let leaderboardButton = Button.CreateSimpleButton("LeaderboardSidebarGUILeaderboardButton", "More");
    leaderboardButton.background = "#01003a";
    leaderboardButton.thickness = 0;
    leaderboardButton.color = "white";
    leaderboardButton.onPointerClickObservable.add(() => LeaderboardScreen.showLeaderboardScreen(false, "Back"));
    stack.addControl(leaderboardButton);

    // Changes to button fontHeight aren't taking effect
    // So we need to set the textBlock fontHeight directly
    // Which means we need to set the fontFamily and fontWeight directly as well
    let leaderboardButtonText = findGuiControl(leaderboardButton.name + "_button", leaderboardButton);
    leaderboardButtonText.fontFamily = config.fontFamily;
    leaderboardButtonText.fontWeight = config.fontWeight;

    this.layout();
    this.update();

    if(animate) {
      this.cancelInOutAnimation();

      let a = new Animation("LeaderboardSidebarIntro", "left", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let keyFrames = [];
      let k0 = 0;

      if(this.rootState.status === ROOT_STATE_HOME_SCREEN)
        k0 = 30; // Waiting for Home Screen cards to slide in - (assumes the sidebar is always displayed with the home screen!)

      let k1 = k0 + 5;
      keyFrames.push({frame: k0, value: -box.widthInPixels});
      keyFrames.push({frame: k1, value: 0});
      a.setKeys(keyFrames);

      // No elastic easing, because it would pull away from the edge of the screen, we'd need the box to be oversized to do a bounce

      this.game.scene.beginDirectAnimation(box, [a], 0, k1, false, 1.0);
    }
  }

  layout() {
    let guiHeight = this.game.guiTexture.getSize().height;

    let panelHeight = Math.floor(guiHeight * 3 / 5);
    let panelWidth = Math.floor(panelHeight * 376 / 1267);
    let panelBorder = Math.floor(panelWidth / 9);

    let boxWidth = panelWidth + panelBorder;
    let boxHeight = panelHeight + panelBorder;

    let contentWidth = panelWidth - panelBorder * 2;

    let starWidth = Math.floor(contentWidth / 3);
    let smallStarWidth = Math.floor(starWidth / 2);

    let titleHeight = Math.floor(contentWidth / 5);
    let buttonHeight = Math.floor(contentWidth / 3);

    let playerWidth = Math.floor(contentWidth);

    let box = findGuiControl("LeaderboardSidebarGUI") as Rectangle;
    box.width = boxWidth + "px";
    box.height = boxHeight + "px";

    let panel = findGuiControl("LeaderboardSidebarGUIPanel") as Rectangle;
    panel.width = panelWidth + "px";
    panel.height = panelHeight + "px";

    let largeStar = findGuiControl("LeaderboardSidebarGUILargeStar") as Image;
    largeStar.width = starWidth + "px";
    largeStar.height = starWidth + "px";

    let smallStar = findGuiControl("LeaderboardSidebarGUISmallStar") as Image;
    smallStar.left = - starWidth * 0.9;
    smallStar.top = starWidth * 0.5;
    smallStar.width = smallStarWidth + "px";
    smallStar.height = smallStarWidth + "px";

    let stack = findGuiControl("LeaderboardSidebarGUIStack") as StackPanel;
    stack.top = starWidth;

    let title = findGuiControl("LeaderboardSidebarGUITitle") as TextBlock;
    title.width = contentWidth + "px";
    title.height = titleHeight + "px";
    title.fontSize = (titleHeight * 0.8) + "px";

    let playerBox = findGuiControl("LeaderboardSidebarGUIPlayerBox") as Rectangle;
    playerBox.width = playerWidth + "px";
    playerBox.height = (playerWidth * 3) + "px";

    for(let player of playerBox.children as LeaderboardPlayer[]) {
      player.setLayoutHeight(playerWidth);
    }

    let buttonSpacer = findGuiControl("LeaderboardSidebarGUIButtonSpader");
    buttonSpacer.height = (buttonHeight * 0.5) + "px";

    let leaderboardButton = findGuiControl("LeaderboardSidebarGUILeaderboardButton") as Button;
    leaderboardButton.width = contentWidth + "px";
    leaderboardButton.height = buttonHeight + "px";
    leaderboardButton.cornerRadius = buttonHeight / 8;

    let leaderboardButtonText = findGuiControl(leaderboardButton.name + "_button", leaderboardButton);
    leaderboardButtonText.fontSize = (buttonHeight * 0.8) + "px";
  }

  onLeaderboardEntryChanged(patch: any, reversePatch: any, params: any) {
    /*
    if(patch.op === "replace") {
      // Get the variable changed
      let variable = patch.path.substring(patch.path.lastIndexOf("/") + 1);
      console.log(`replace ${variable}`);

      // We don't have enough information to update the player in the patch, so find the entry
      let board = this.rootState.leaderboards.getLeaderboard("quickplay_leaderboard_global");
      let index = parseInt(params[2]);
      let entry = board.entries[index];
      if(entry) {
        let player = this.getPlayerByUserId(entry.userId);
        if(player) {
          if(variable === "score") {
            console.log(`Player ${player.name} score ${player.score} to ${patch.value}`);
            player.setScore(patch.value);
            console.log("---");
            return;
          }
        }
      }
    }
    */

    // For now we're updating all leaderboard entries on any change, ideally
    // we would only change the entry that is changing
    this.update();
  }

  cancelInOutAnimation() {
    if(!this.inOutAnimation)
      return;

    this.inOutAnimation.stop();
    this.inOutAnimation = null;
  }

  animateOut() {
    this.cancelInOutAnimation();

    let box = findGuiControl("LeaderboardSidebarGUI") as Rectangle;
    if(!box)
      return;

    let a = new Animation("LeaderboardSidebarIntro", "left", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;

    if(this.rootState.status === ROOT_STATE_HOME_SCREEN)
      k0 = 30; // Waiting for Home Screen cards to slide in - (assumes the sidebar is always displayed with the home screen!)

    let k1 = k0 + 5;
    keyFrames.push({frame: k0, value: 0});
    keyFrames.push({frame: k1, value: -box.widthInPixels});
    a.setKeys(keyFrames);

    // No elastic easing, because it would pull away from the edge of the screen, we'd need the box to be oversized to do a bounce

    this.game.scene.beginDirectAnimation(box, [a], 0, k1, false, 1.0, () => this.dispose());
  }

  getTop3() {
    let board = this.rootState.leaderboards.getLeaderboard(config.defaultLeaderboard);
    if(!board)
      return [null, null, null];

    return board.getTopEntries(3, this.rootState.user.id);
  }

  getPlayerByUserId(userId: string) {
    let playerBox = findGuiControl("LeaderboardSidebarGUIPlayerBox") as Rectangle;
    if(!playerBox) {
      //console.log("getPlayerByID: playerBox is NULL!");
      return null;
    }
    for(let playerIndex = 0; playerIndex < 3; playerIndex++) {
      let player = playerBox.children[playerIndex] as LeaderboardPlayer;
      if(player.userId === userId)
        return player;
  }
    return null;
  }

  update() {
    let gui = findGuiControl("LeaderboardSidebarGUI");
    if(!gui)
      return;

    let playerBox = findGuiControl("LeaderboardSidebarGUIPlayerBox") as Rectangle;

    let top3 = this.getTop3();

    for(let playerIndex = 0; playerIndex < 3; playerIndex++) {
      let player = playerBox.children[playerIndex] as LeaderboardPlayer;

      let entry = top3[playerIndex];
      if(entry === null) {
        player.isVisible = false;
        continue;
      }

      let imageUrl = entry.imageUrl || pawn0AvatarUrl;

      player.isVisible = true;

      player.setUserId(entry.userId);
      player.setPlayerName(entry.name);
      player.setImage(imageUrl);
      player.setRank(entry.rank);
      player.setScore(entry.score);
      player.setIndex(playerIndex);
    }
  }

  onPointerEnter(player: LeaderboardPlayer) {
    if(!player.userId)
      return;

    let popUp = new PlayerInfoPopUp(player.userId);

    let globalPos = getControlGlobalPosition(player);
    popUp.left = globalPos.x + player.widthInPixels * 1.25;
    popUp.top = globalPos.y + player.heightInPixels * 0.5 - popUp.heightInPixels * 0.5;
  }

  onPointerOut() {
    disposeGuiControl("PlayerInfoPopUp");
  }

  dispose() {
    disposeGuiControl("LeaderboardSidebarGUI");
  }

  resized(): void {
    if(findGuiControl("LeaderboardSidebarGUI")) {
      this.layout();
    }
  }
}
