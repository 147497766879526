import { ISpadesGameState } from "./SpadesGameState";

import { BotFactory } from "ai/BotFactory";
import { SpadesGameAI } from "games/spades/src/ai/SpadesGameAI";

import { BidMeterSystem } from "components/game/BidMeterSystem";
import { BidSystem } from "components/game/BidSystem";
import { SpadesRoundSummary } from "components/game/SpadesRoundSummary";
import { TrickGame } from "components/game/TrickGame";
import { TrickTutorialSystem } from "components/game/TrickTutorialSystem";

import { HelpSystem } from "components/ui/HelpSystem";
import { HomeScreenSystem } from "components/ui/HomeScreenSystem";
import { LeaderboardSidebarSystem } from "components/ui/LeaderboardSidebarSystem";
import { SpadesHintSystem } from "components/ui/SpadesHintSystem";
import { SpadesShareSystem } from "components/ui/SpadesShareSystem";
import { StartButtonSystem } from "components/ui/StartButtonSystem";
import { UserStatusSystem } from "components/ui/UserStatusSystem";
import { spadesPieceValueCompare } from "components/utils/PieceSortFunctions";

export class Spades extends TrickGame {
  paddingTop = 0.1;
  paddingBottom = 0.1;
  paddingLeft = 0.1;
  paddingRight = 0.1;
  gameState: ISpadesGameState; // overrides Game.gameState to set game specific type
  pieceValueSortFunction = spadesPieceValueCompare;

  initSystems() {
    this.systems.set("HomeScreenSystem", new HomeScreenSystem(this));
    this.systems.set("UserStatusSystem", new UserStatusSystem(this));
    this.systems.set("LeaderboardSidebarSystem", new LeaderboardSidebarSystem(this));
    this.systems.set("StartButtonSystem", new StartButtonSystem(this));
    this.systems.set("BidSystem", new BidSystem(this));
    this.systems.set("BidMeterSystem", new BidMeterSystem(this));
    this.systems.set("SpadesRoundSummary", new SpadesRoundSummary(this));
    this.systems.set("HelpSystem", new HelpSystem(this));
    this.systems.set("TutorialSystem", new TrickTutorialSystem(this));
    this.systems.set("SpadesHintSystem", new SpadesHintSystem(this));

    this.shareSystem = new SpadesShareSystem(this);
    this.systems.set("ShareSystem", this.shareSystem);

    super.initSystems();
  }

  createPiles(): void {
    super.createPiles();

    // Create a BotFactory to auto create spades ai for bot players
    this.botFactory = new BotFactory(SpadesGameAI, this.gameState);

    this.layout();
  }
}
