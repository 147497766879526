import { Observable } from "@babylonjs/core/Misc/observable";

/**
 * StateSuncMiddleware uses StateSyncClient to get snapshots from socket cluster server, subscribe to changes(patches)
 * to that snapshot, and forward actions to server.
 *
 * It basically works by adding a middleware function to the state and implements the createState, findOrCreateState, syncState
 * actions from BaseStateSync. Then it checks other actions to see if they should be forwarded to the server
 */

import { addMiddleware, applyPatch, applySnapshot, getPath, ISerializedActionCall } from "mobx-state-tree";
import SocketCluster from "socketcluster-client";

import { getStateSyncClient, getStateSyncClientUserId, waitForAuth } from "states/state-sync/StateSyncClient";

import { IPatchQueueState } from "states/PatchQueueState";
import { getRootState, safeApplySnapshot } from "states/RootState";
import { APPLY_SNAPSHOT_STAGE_DONE, APPLY_SNAPSHOT_STAGE_START, IPatchSet, IStateActionCall } from "states/state-sync/BaseStateSync";
import { CLIENT_VERSION_ERROR, STATE_NOT_FOUND_ERROR, STATE_RESET_ERROR, StateNotFoundError, StateResetError } from "states/state-sync/errors";

import { ERROR, INFO, logger, WARN } from "utils/logger";

const MISSING_PATCH_TIMEOUT = 4000; // time in ms to wait for missing patches to come in before reloading whole state from service

export class StateSyncMiddleware {
  state: IPatchQueueState; // the state to load/sync with server
  enabled: boolean = true; // setting to false will stop actionMiddleware from intercepting any actions. This is to allow for local single player games

  // list of actions to intercept and forward to server instead of running on local state
  // it is critical that the first argument to the function is the playerId of the player doing the action
  // the server checks that the first argument matches the authenticated user id before running action
  _forwardActions: string[] = [];   // list of actions to apply locally and forward to server
  _forwardOnlyActions: string[] = [];   // list of actions to only forward to server

  subscribeStateId: string; // id of the state we're currently subscribed to
  patchSetQueue: IPatchSet[] = []; // we need to queue and sort incoming patchSets because they might be out of order
  privatePatchSetQueue: IPatchSet[] = []; // PatchSets that came in on private channel
  reSyncTimer: any; // handle of timer to resync if we don't get any more patches after missing a patch
  pingSentTime: number;
  lastSentTime: number; // timestamp of time last action was sent
  lastActionSent: string; // name of last action sent
  warnOnSendingTooFast = false; // set true to warn if actions are being sent to server to fast
  resetCnt: number = 0; // Keep track of how many times the state has been reset, if this changes during a syncState, createState or findOrCreateState, then those will ignore the response from server and return a StateResetError

  errorObservable = new Observable();

  constructor(state: IPatchQueueState) {
    this.state = state;
    addMiddleware(this.state, (call, next, abort) => { this.actionMiddleware(call, next, abort); }, false);
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled;
  }

  /** actionMiddleware gets called for all actions called on self.state */
  actionMiddleware(call: any, next: any, abort: any) {
    if(!this.enabled) {
      next(call);
      return;
    }

    // We basically override createState, findOrCreateState, syncState from BaseStateSync to implement the interface to loading a state from server
    // Then we check if the action is in the lists to be forwarded to server.
    if(call.name === "createState") {
      let promise = this.createState.apply(this, call.args);
      abort(promise);
    }
    else if(call.name === "findOrCreateState") {
      let promise = this.findOrCreateState.apply(this, call.args);
      abort(promise);
    }
    else if(call.name === "syncState") {
      let promise = this.syncState.apply(this, call.args);
      abort(promise);
    }
    else if(call.name === "resetState") {
      this.resetState.apply(this, call.args);
      next(call);
    }
    else if(call.name === "ping") {
      let now = new Date();
      this.pingSentTime = now.getTime();
      this.forwardAction(call);
      abort();
    }
    else if(this._forwardActions.indexOf(call.name) !== -1) {
      this.forwardAction(call);
      next(call); // still run action locally
    }
    else if(this._forwardOnlyActions.indexOf(call.name) !== -1) {
      this.forwardAction(call);
      abort(); // do not run action locally
    }
    else {
      //console.log("actionMiddleware next", call)
      next(call);
    }
  }

  /** State is being reset to an unloaded state, so unsubscribe and reset timers and queues */
  resetState() {
    logger.info("State reset", { stateName: this.state.stateName, id: this.state.id });

    // be sure to unsubscribe from previous state
    const userId = getStateSyncClientUserId();
    getStateSyncClient().destroyChannel(`${this.state.stateName}.${this.subscribeStateId}`);
    getStateSyncClient().destroyChannel(`${this.state.stateName}.${this.subscribeStateId}#${userId}`);

    this.subscribeStateId = null;
    this.patchSetQueue = [];
    this.privatePatchSetQueue = [];

    if(this.reSyncTimer) {
      clearTimeout(this.reSyncTimer);
      this.reSyncTimer = null;
    }

    this.resetCnt++; // if any requests to syncState, createState, or findOrCreateState are in progress, this will ignore results from it
  }

  /** check if resetState has been called since resetCnt */
  hasReset(resetCnt: number) {
    return this.resetCnt !== resetCnt;
  }

  /** gets snapshot from server and subscribe to patches */
  syncState(id: string): Promise<boolean> {

    return new Promise<boolean> ((resolve, reject) => {
      this.resetCnt++;
      let curResetCnt = this.resetCnt;
      waitForAuth().then((client: SocketCluster.SCClientSocket) => {
        if(this.hasReset(curResetCnt)) {
          reject(new StateResetError());
        }
        else {
          client.emit("get" + this.state.stateName, {id: id}, (err: any, responseData: any) => {
            if(this.hasReset(curResetCnt)) {
              reject(new StateResetError());
            }
            else if(err) {
              this.logClientError("syncState", err, { id });
              reject(err);
            }
            else if(responseData) {
              this.loadSnapshot(responseData);
              resolve(responseData);
            }
            else
              reject(new StateNotFoundError());
          });
        }
      });
    });
  }

  /** creates a new state on the server and subscribes to it */
  createState(snapshot: any) {
    return new Promise<boolean> ((resolve, reject) => {
      this.resetCnt++;
      let curResetCnt = this.resetCnt;
      waitForAuth().then((client: SocketCluster.SCClientSocket) => {
        if(this.hasReset(curResetCnt)) {
          reject(new StateResetError());
        }
        else {
          client.emit("create" + this.state.stateName, snapshot, (err: any, responseData: any) => {
            if(this.hasReset(curResetCnt)) {
              reject(new StateResetError());
            }
            else if(err) {
              this.logClientError("createState", err, { snapshot });
              reject(err);
            }
            else if(responseData) {
              this.loadSnapshot(responseData);
              resolve(responseData);
            }
            else
              reject(new StateNotFoundError());
          });
        }
      });
    });
  }

  /** finds an existing stating matching requirements in snapshot, or creates a new state on the server and subscribes to it */
  findOrCreateState(snapshot: any): Promise<boolean> {
    return new Promise<boolean> ((resolve, reject) => {
      this.resetCnt++;
      let curResetCnt = this.resetCnt;
      waitForAuth().then((client: SocketCluster.SCClientSocket) => {
        if(this.hasReset(curResetCnt)) {
          reject(new StateResetError());
        }
        else {
          client.emit("findOrCreate" + this.state.stateName, snapshot, (err: any, responseData: any) => {
            if(this.hasReset(curResetCnt)) {
              reject(new StateResetError());
            }
            else if(err) {
              this.logClientError("findOrCreateState", err,  { snapshot });
              reject(err);
            }
            else if(responseData) {
              this.loadSnapshot(responseData);
              resolve(responseData);
            }
            else
              reject(new StateNotFoundError());
          });
        }
      });
    });
  }

  /** forwards action to server */
  forwardAction(call: any) {

    // Warn if it appears we're sending multiple actions in a row. The problem is even though the server will receive them in order, it may
    // load from dynamodb out of order. For example passing used to send 3 setSelected, and then a setSeatReady action. Some how the
    // setSeatReady action would load from dynamodb first, and be processed before the setSelecteds. The solution is to wrap up mulitple actions
    // into one, ie passCards.
    if(this.warnOnSendingTooFast) {
      let now = new Date();
      if(now.getTime() - this.lastSentTime < 10) // 10ms
        logger.warn("WARNING! Sending multiple actions too quickly may result in actions being processed out of order on server!", {action: call.name, lastAction: this.lastActionSent});
      this.lastSentTime = now.getTime();
      this.lastActionSent = call.name;
    }

    // gets path of node(call.context) that action was called on relative to this.state
    // ie change /game/players/12  to just /players/12
    let sStatePath = getPath(this.state);
    let sContextPath = getPath(call.context).replace(sStatePath, "");
    let action: ISerializedActionCall = {
      name: call.name,
      path: sContextPath,
      args: call.args,
    };

    let stateAction: IStateActionCall = {
      id: this.state.id,
      action: action,
      stateName: this.state.stateName,  // UserState, GameState
    };

    if(this.state.id && this.state.id !== "0") {
      waitForAuth().then((client: SocketCluster.SCClientSocket) => {
        client.emit("applyAction" + this.state.stateName, stateAction, (err: any, responseData: any) => {
          if(err) {
            this.logClientError("forwardAction", err, { id: this.state.id, action });
          }
        });
      });
    }
    else
      logger.warn("Attempted to forward action to service on state with id === 0.", { stateName: this.state.stateName, action});
  }

  /** Internal helper function to apply snapshot and subscribe to patches. */
  loadSnapshot(snapshot: any) {
    // reset patchSetQueue, reSyncTimer, subscription
    this.resetState();
    safeApplySnapshot(this.state, snapshot);
    logger.info("StateSyncMiddleware Loaded Snapshot", { stateName: this.state.stateName, id: this.state.id });
    this.subscribeToPatches(snapshot.id);
  }

  /** Internal helper function to apply queued patches in the correct order based on patchSetId */
  applyQueuedPatchSets() {
    this.resetCnt++;
    let curResetCnt = this.resetCnt;
    // always reset the resync timer
    if(this.reSyncTimer) {
      clearTimeout(this.reSyncTimer);
      this.reSyncTimer = null;
    }

    // sort the queue in case we received a patch out of order
    this.patchSetQueue.sort((a, b) => a.patchSetId - b.patchSetId);

    while(this.patchSetQueue.length) {
      // stop applying patches if the state got reset.
      if(this.hasReset(curResetCnt))
        break;
      let patchSet = this.patchSetQueue[0];
      if(patchSet.patchSetId === this.state.patchSetId + 1) {
        this.patchSetQueue.shift(); // remove PatchSet from queue
        patchSet.patches.forEach((patchData: string) => {
          // stop applying patches if the state got reset.
          if(this.hasReset(curResetCnt))
            return;

          let patch = JSON.parse(patchData);
          // we need to apply the change to patchSetId immediately just in case we get more patches while others are queued
          if(patch.path === "/patchSetId")
            applyPatch(this.state, patch);
          else if(patch.path === "/pingId") {
            // watch for pingId being set, which is basically a pong, and log time since we forwarded the ping action
            let now = new Date();
            logger.info("Ping time", { time: now.getTime() - this.pingSentTime });
          }
          else {
            this.state.queuePatch(patch); // PatchQueueState.queuePatch instead of applyPatch, so that applying patches can be paused for animations
            this.applyPrivateQueuedPatchSets(); // sends any private patches that are at the same or less patchSetId
          }
        });
      }
      else if(patchSet.patchSetId <= this.state.patchSetId) {
        this.patchSetQueue.shift(); // remove PatchSet from queue
        logger.info("Detected duplicate patchSetId, ignoring.", { stateName: this.state.stateName, curPatchSetId: this.state.patchSetId, incomingPatchSetId: patchSet.patchSetId});
      }
      else {
        logger.debug("Detected missing patchSetId, queued.", { stateName: this.state.stateName, curPatchSetId: this.state.patchSetId, incomingPatchSetId: patchSet.patchSetId, patchSet});
        // wait 2 seconds for the missing patch to come in, if it doesn't then we need to call syncState again to reload the whole state
        this.reSyncTimer = setTimeout(() => {
          logger.info("TIMEOUT waiting for missing patches, resyncing state", { stateName: this.state.stateName });
          this.state.syncState(this.state.id).catch((err) => {
            if(err.name !== STATE_RESET_ERROR)
              logger.info("Error resyncing after missing patches", { stateName: this.state.stateName, err });
          });
        }, MISSING_PATCH_TIMEOUT);
        break;
      }
    }
  }

  /** Sends any patchSets received on the private channel that are at or below the current this.state.patchSetId level */
  applyPrivateQueuedPatchSets() {
    this.privatePatchSetQueue.sort((a, b) => a.patchSetId - b.patchSetId);

    while(this.privatePatchSetQueue.length) {
      let patchSet = this.privatePatchSetQueue[0];
      if(patchSet.patchSetId <= this.state.patchSetId) {
        this.privatePatchSetQueue.shift(); // remove PatchSet from queue
        patchSet.patches.forEach((patchData: string) => {
          let patch = JSON.parse(patchData);
          this.state.queuePatch(patch);
        });
      }
      else
        break;
    }
  }

  /** Called when ever we receive data from socket cluster channel */
  receiveData(data: any, privateChannel: boolean) {
    let patchSet = data;  // data is an IPatchSet

    // Double check that the patch is for the state we're currently subscribed to
    if(patchSet.id !== this.subscribeStateId) {
      logger.debug("Received patchSet for wrong state id", { stateName: this.state.stateName, incomingPatchSetId: patchSet.id,  subscribeStateId: this.subscribeStateId, privateChannel });
      return;
    }

    // queue PatchSet and attempt to apply them, it might not apply immediatly if it the patchSet is out of order
    if(privateChannel) {
      this.privatePatchSetQueue.push(patchSet);
      this.applyPrivateQueuedPatchSets();
    }
    else {
      this.patchSetQueue.push(patchSet);
      this.applyQueuedPatchSets();
    }
  }

  /** Internal helper function to subscribe to patches via websocket */
  subscribeToPatches(id: string) {
    // don't do anything if we're already subscribed to this state
    if(this.subscribeStateId === id)
      return;

    const userId = getStateSyncClientUserId();
    let client = getStateSyncClient();

    // be sure we're unsubscribed from previous state
    if(this.subscribeStateId) {
      client.destroyChannel(`${this.state.stateName}.${this.subscribeStateId}`);
      client.destroyChannel(`${this.state.stateName}.${this.subscribeStateId}#${userId}`);
    }

    this.subscribeStateId = id;

    // Subscribe to public channel, ie GameState.1234
    let channel = client.subscribe(`${this.state.stateName}.${this.subscribeStateId}`);
    channel.on("subscribeFail", (err: any) => {
      logger.error("Failed to subscribe to state channel due to error: ", { err });
      /* I'm not sure what errors would be appropriate to resync at this point. So I'll wait to see what gets reported to sentry
      // watch for errors and attempt to resync/subscribe
      logger.info("subscribeToPatches error, we're resyncing", { stateName: this.state.stateName, id, error });
      this.state.syncState(id);
      */
    });

    channel.watch( (data: any) => {
      this.receiveData(data, false);
    });

    // subscribe to private channel, ie GameState.1234.1234
    let privateChannel = client.subscribe(`${this.state.stateName}.${this.subscribeStateId}#${userId}`);
    privateChannel.on("subscribeFail", (err: any) => {
      logger.error("Failed to subscribe to private state channel due to error: ", { err });
    });

    privateChannel.watch( (data: any) => {
      this.receiveData(data, true);
    });
  }

  /** report errors to logger, but treat some common errors such as getting disconnected as warnings instead of errors */
  logClientError(functionName: string, err: any, extra: any) {
    let logLevel = ERROR;
    if(err.name === "BadConnectionError" || err.name === STATE_NOT_FOUND_ERROR)
      logLevel = INFO;
    else if(err.name === "SocketProtocolError" || err.name === CLIENT_VERSION_ERROR)
      logLevel = WARN;

    extra.stateName = this.state.stateName;
    extra.function = functionName;
    if(!extra.id)
      extra.id = this.state.id;

    if(logLevel) {
      const message = `${functionName} Error ${this.state.stateName} ${err.name}`;
      logger.log(logLevel, message, { err, ...extra});
    }

    // notify observers of error. for example Game.ts will prompt the user to reload on certain errors like CLIENT_VERSION_ERROR.
    this.errorObservable.notifyObservers({ err, extra });
  }
}
