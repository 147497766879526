import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { ElasticEase } from "@babylonjs/core/Animations/easing";
import { Color3 } from "@babylonjs/core/Maths/math";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Scene } from "@babylonjs/core/scene";

/**
 * Wrapper around various subclasses of GUI.Control to animate the control's background and color properties
 *
 * background, color: Animation.ANIMATIONTYPE_COLOR3
 * position: Animation.ANIMATIONTYPE_VECTOR2
 * width, height, scale: Animation.ANIMATIONTYPE_FLOAT
 */
export class GUIAnimationProxy {
  control: any; // The GUI.Control base class dosn't have every property, for example background is missing, so control needs to be type any
  positionOffset: Vector2 = Vector2.Zero();
  animation: Animatable;

  constructor(control: any) {
    this.control = control;
  }

  get background() {
    return Color3.FromHexString(this.control.background);
  }

  set background(color: Color3) {
    this.control.background = color.toHexString();
  }

  get color() {
    return Color3.FromHexString(this.control.color);
  }

  set color(color: Color3) {
    this.control.color = color.toHexString();
  }

  set width(widthInPixels: number) {
    this.control.width = widthInPixels + "px";
  }

  set height(heightInPixels: number) {
    this.control.height = heightInPixels + "px";
  }

  set position(pos: Vector2) {
    let offsetPos = pos.add(this.positionOffset);
    this.control.left = offsetPos.x;
    this.control.top = offsetPos.y;
  }

  set rotation(angle: number) {
    this.control.rotation = angle;
  }

  set scale(scale: number) {
    this.control.scaleX = scale;
    this.control.scaleY = scale;
  }

  set cellId(cellId: number) {
    this.control.cellId = Math.floor(cellId);
  }

  set alpha(alpha: number) {
    this.control.alpha = alpha;
  }

  stopAnimation() {
    if(this.animation) {
      this.animation.stop();
      this.animation = null;
    }
  }

  /**
   * property should be "background" or "color"
   */
  animateColor(property: string, fromColor: Color3, toColor: Color3, durationInSeconds: number, scene: Scene) {
    this.stopAnimation();

    let a = new Animation(this.control.name + "Coloranimation", property, 30, Animation.ANIMATIONTYPE_COLOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = Math.floor(durationInSeconds * 30.0);
    keyFrames.push({frame: k0, value: fromColor});
    keyFrames.push({frame: k1, value: toColor});
    a.setKeys(keyFrames);

    this.animation = scene.beginDirectAnimation(this, [a], 0, k1, false);

    return this.animation;
  }

  animateFloat(property: string, startValue: number, endValue: number, durationInSeconds: number, loop: boolean, bounce: boolean, scene: Scene) {
    this.stopAnimation();

    let a = new Animation("GHIAnimationProxyFloatAnimation", property, 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CYCLE);
    let keyFrames = [];
    let k0 = 0;
    let k1 = Math.floor(durationInSeconds * 30.0);
    keyFrames.push({frame: k0, value: startValue });
    keyFrames.push({frame: k1, value: endValue });
    a.setKeys(keyFrames);

    if(bounce) {
      // Elasic easing overshoots, then springs back
      let easingMode = (startValue < endValue) ? EasingFunction.EASINGMODE_EASEOUT : EasingFunction.EASINGMODE_EASEIN;
      let easingFunction = new ElasticEase(2, 5);
      easingFunction.setEasingMode(easingMode);
      a.setEasingFunction(easingFunction);
    }

    this.animation = scene.beginDirectAnimation(this, [a], 0, k1, loop);

    return this.animation;
  }
}
