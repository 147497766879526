import { Animation } from "@babylonjs/core/Animations/animation";
import { Color3 } from "@babylonjs/core/Maths/math";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Ellipse } from "@babylonjs/gui/2D/controls/ellipse";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { ArgoSystem } from "components/game/ArgoSystem";
import { AnimatableRectangle } from "components/ui/controls/AnimatableRectangle";
import { findGuiControl, zIndex } from "components/utils/GUI";

const BG_COLOR = "#053753";
const COLOR0 = "#FF0000";
const COLOR1 = "#00AEEF";

export class BidMeterSystem extends ArgoSystem {
  wonControls: AnimatableRectangle[] = [];
  topBidControls: Ellipse[] = [];
  bottomBidControls: Ellipse[] = [];
  color0 = COLOR0;
  color1 = COLOR1;
  dotLeft = 0;
  spacing = 0;

  init() {
    this.game.onShowGameObservable.add((show) => this.onShowGame(show));
  }

  createGUI() {
    this.create();
    // update when anything about seats change, notice no trailing $
    this.rootState.router.addRoute("^\/game\/seats\/", (patch: any, reversePatch: any, params: any) => this.update());
  }

  create() {
    let guiWidth = this.game.guiTexture.getSize().width;
    let guiHeight = this.game.guiTexture.getSize().height;

    let height = Math.min(guiWidth / 32, guiHeight / 12); // on mobile, limiting to guiHeight / 12 keeps the meter from taking over the screen

    let spacing = height / 2;
    let gap = spacing * 0.3;
    let left = gap;
    let meterWidth = spacing * 13 + gap;
    let itemWidth = spacing * 0.75;
    let dotWidth = spacing * 0.8;
    let dotLeft = left + (itemWidth - dotWidth) * 0.5;
    let meterHeight = height + itemWidth * 2 + gap * 2;
    let padding = spacing * 0.5;

    this.dotLeft = dotLeft;
    this.spacing = spacing;

    let bidMeterGUI = new Rectangle("BidMeterGUI");
    bidMeterGUI.zIndex = zIndex.GAME_UI; // Keep above players
    bidMeterGUI.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    bidMeterGUI.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    bidMeterGUI.top = padding + "px";
    bidMeterGUI.left = (-padding) + "px";
    bidMeterGUI.width = meterWidth + "px";
    bidMeterGUI.height = meterHeight + "px";
    bidMeterGUI.thickness = 0;
    this.game.guiTexture.addControl(bidMeterGUI);

    for(let i = 0; i < 13; i++) {
      let r = new AnimatableRectangle("Won" + i);
      r.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      r.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      r.background = BG_COLOR;
      r.left = (left + i * spacing) + "px";
      r.top = (itemWidth + gap) + "px";
      r.width = itemWidth + "px";
      r.height = height + "px";
      r.cornerRadius = itemWidth * 0.25;
      r.thickness = 0;
      bidMeterGUI.addControl(r);
      this.wonControls.push(r);

      let e = new Ellipse("TopBid" + "i");
      e.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      e.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      e.background = this.color0;
      e.left = (dotLeft + i * spacing) + "px";
      e.width = dotWidth + "px";
      e.height = dotWidth + "px";
      e.thickness = 0;
      bidMeterGUI.addControl(e);
      this.topBidControls.push(e);

      e = new Ellipse("BottomBid" + "i");
      e.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      e.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      e.background = this.color1;
      e.left = (dotLeft + i * spacing) + "px";
      e.top = (meterHeight - dotWidth) + "px";
      e.width = dotWidth + "px";
      e.height = dotWidth + "px";
      e.thickness = 0;
      bidMeterGUI.addControl(e);
      this.bottomBidControls.push(e);
    }

    this.setBid(0, 0, false);
    this.setWon(0, 0, false);
  }

  setBid(bid0: number, bid1: number, animate= true) {
    for(let i = 0; i < 13; i++) {
      let c = this.topBidControls[i];
      c.background = this.color0;
      let wasVisible = c.isVisible;
      c.isVisible = (i < bid0);

      if(c.isVisible && !wasVisible && animate) {
        let a = new Animation("BidMeterBid", "left", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        let keyFrames = [];
        let k0 = i * 5;
        let k1 = k0 + 15;
        if(k0)
          keyFrames.push({frame: 0, value: this.dotLeft + 14 * this.spacing});
        keyFrames.push({frame: k0, value: this.dotLeft + 14 * this.spacing});
        keyFrames.push({frame: k1, value: this.dotLeft + i * this.spacing});
        a.setKeys(keyFrames);
        this.game.scene.beginDirectAnimation(c, [a], 0, k1, false);
      }
    }

    for(let i = 0; i < 13; i++) {
      let c = this.bottomBidControls[12 - i];
      c.background = this.color1;
      let wasVisible = c.isVisible;
      c.isVisible = (i < bid1);

      if(c.isVisible && !wasVisible && animate) {
        let a = new Animation("BidMeterBid", "left", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        let keyFrames = [];
        let k0 = i * 5;
        let k1 = k0 + 15;
        if(k0)
          keyFrames.push({frame: 0, value: this.dotLeft + -2 * this.spacing});
        keyFrames.push({frame: k0, value: this.dotLeft + -2 * this.spacing});
        keyFrames.push({frame: k1, value: this.dotLeft + (12 - i) * this.spacing});
        a.setKeys(keyFrames);
        this.game.scene.beginDirectAnimation(c, [a], 0, k1, false);
      }
    }
  }

  setWon(won0: number, won1: number, animate= true) {
    for(let i = 0; i < 13; i++) {
      let k = 12 - i;
      let c = this.wonControls[i];
      let color: Color3 = null;
      if(i < won0)
        color = Color3.FromHexString(this.color0);
      else if(k < won1)
        color = Color3.FromHexString(this.color1);
      else
        color = Color3.FromHexString(BG_COLOR);

      if(animate)
        c.animateBackgroundColor("BidMeterWonColor" + i, color, 0.5, this.game.scene);
      else
        c.backgroundColor3 = color;
    }
  }

  update(animate= true) {
    if(!this.wonControls.length)
      return;

    let teamBid = [];
    let teamWon = [];
    for(let team of this.game.gameState.teams) {
      let bid = 0;
      let won = 0;
      for(let seatId of team.seats) {
        let seat = this.game.gameState.getSeat(seatId);
        let seatBid = seat.bid || 0;
        let seatWon = seat.tricksWon;

        bid += seatBid;
        won += seatWon;
      }

      teamBid.push(bid);
      teamWon.push(won);
    }

    // Keep the local seat's team in the top-left with the correct color
    if(this.game.localSeat !== null) {
      let localTeam = this.game.gameState.getTeamForSeat(this.game.localSeat);
      if(localTeam.id === "0") {
        this.color0 = COLOR0;
        this.color1 = COLOR1;
        this.setBid(teamBid[0], teamBid[1], animate);
        this.setWon(teamWon[0], teamWon[1], animate);
      } else {
        this.color1 = COLOR0;
        this.color0 = COLOR1;
        this.setBid(teamBid[1], teamBid[0], animate);
        this.setWon(teamWon[1], teamWon[0], animate);
      }
    }
  }

  onShowGame(show: boolean) {
    let bidMeterGUI = findGuiControl("BidMeterGUI");
    if(bidMeterGUI)
      bidMeterGUI.isVisible = show;
  }

  resized(): void {
    let bidMeterGUI = findGuiControl("BidMeterGUI");
    if(bidMeterGUI) {
      // XXX - We can't re-layout right now, so we'll re-create
      bidMeterGUI.dispose();
      this.wonControls.length = 0;
      this.topBidControls.length = 0;
      this.bottomBidControls.length = 0;
      this.create();
      this.update(false);
    }
  }
}
