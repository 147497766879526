import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Observable } from "@babylonjs/core/Misc/observable";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { RadioButton } from "@babylonjs/gui/2D/controls/radioButton";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";
import { game } from "components/game/Game";
import { RectangleWithPointerCapture } from "components/ui/controls/RectangleWithPointerCapture";

const BG_COLOR = "#01003a";

/** Carousel Page */
export class CarouselPage extends Rectangle {
  pageNumber: number;

  // NOTE: metadata is a standared control property in Babylon 4
  metadata: any;
}

/**
 * Container for pages, with controls, supporting dragging to the next page
 */
export class Carousel extends Rectangle {
  pageWidth: number = 600;
  pageHeight: number = 315;

  borderWidth: number;

  grabbed = false;
  grabX = 0;
  grabY = 0;
  sliderX = 0;
  currentPage = 0;

  pageWindow: Rectangle;
  pagePanel: StackPanel;
  pageButtonPanel: StackPanel;  // the little white circles that indicate which page is visible

  pageAnimation: Animatable;

  public onPageChangedObservable = new Observable<number>(); // triggered when the page that is mostly visible changes
  public onCarouselClickedObservable = new Observable<Vector2WithInfo>(); // Pointer down, then up without moving

  constructor(public name?: string) {
    super(name);

    this.background = BG_COLOR;
    this.thickness = 0;
    this.isPointerBlocker = true;

    this.pageWindow = new RectangleWithPointerCapture(name + "PageWindow");
    this.pageWindow.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.pageWindow.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.pageWindow.background = "black";
    this.pageWindow.thickness = 0;
    this.addControl(this.pageWindow);

    this.pageWindow.onPointerDownObservable.add((e) => this.onPointerDown(e));
    this.pageWindow.onPointerUpObservable.add((e) => this.onPointerUp(e));
    this.pageWindow.onPointerMoveObservable.add((e) => this.onPointerMove(e));

    this.pagePanel = new StackPanel(name + "Panel");
    this.pagePanel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.pagePanel.isVertical = false;
    this.pagePanel.adaptHeightToChildren = true;
    this.pageWindow.addControl(this.pagePanel);

    this.pageButtonPanel = new StackPanel(name + "DotPanel");
    this.pageButtonPanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.pageButtonPanel.isVertical = false;
    this.pageButtonPanel.adaptHeightToChildren = true;
    this.addControl(this.pageButtonPanel);

    this.setCurrentPage(0);
    this.layout();
  }

  setPageSize(pageWidth: number, pageHeight: number) {
    this.pageWidth = pageWidth;
    this.pageHeight = pageHeight;
    this.layout();
  }

  addPage(name?: string): CarouselPage {
    let pageNumber = this.pagePanel.children.length;
    if(!name)
      name = `${this.name}Page${pageNumber}`;

    let page = new CarouselPage(name);
    page.pageNumber = pageNumber;
    page.thickness = 0;
    this.pagePanel.addControl(page);

    this.addPageButton();

    this.layout();

    return page;
  }

  addPageButton() {
    let pageIndex = this.pageButtonPanel.children.length;

    let button = new RadioButton("BidSystemPage0Button");
    button.group = "BidSystemPage";
    button.thickness = 0.0001; // 0 is ignored
    button.checkSizeRatio = 1.0;
    button.color = "white";
    button.background = "#90bcca";
    button.onPointerClickObservable.add(() => this.gotoPage(pageIndex));
    this.pageButtonPanel.addControl(button);

    // If this was the 1st button, mark it
    if(pageIndex === 0)
      this.setCurrentPage(0);
  }

  getPage(page?: number): CarouselPage {
    if(page === undefined)
      page = this.currentPage;

    if(page < 0 || page >= this.pagePanel.children.length)
      return null;

    return this.pagePanel.children[page] as CarouselPage;
  }

  layout() {
    let pageWidth = this.pageWidth;
    let pageHeight = this.pageHeight;

    let borderWidth = Math.floor(pageHeight / 16);
    this.borderWidth = borderWidth;

    let width = pageWidth + borderWidth * 2;
    let height = pageHeight + borderWidth * 2;

    this.width = width + "px";
    this.height = height + "px";
    this.cornerRadius = borderWidth * 0.5;

    this.pageWindow.left = borderWidth;
    this.pageWindow.top = borderWidth;
    this.pageWindow.width = pageWidth + "px";
    this.pageWindow.height = pageHeight + "px";
    this.pageWindow.cornerRadius = borderWidth * 0.5;

    for(let page of this.pagePanel.children) {
      page.width = this.pageWidth + "px";
      page.height = this.pageHeight + "px";
    }

    let pageButtonWidth = borderWidth * 0.8;
    let pageButtonBorder = borderWidth * 0.1;

    // It seems to me that the page buttons should be centered without needing pageButtonBorder at all, but for some reason pageButtonBorder * 2 centers them
    this.pageButtonPanel.top = borderWidth + pageHeight + pageButtonBorder * 2;

    for(let pageButton of this.pageButtonPanel.children) {
      pageButton.width = pageButtonWidth + "px";
      pageButton.height = pageButtonWidth + "px";

      pageButton.paddingLeft = pageButtonBorder;
      pageButton.paddingRight = pageButtonBorder;
      pageButton.paddingTop = pageButtonBorder;
      pageButton.paddingBottom = pageButtonBorder;
    }

    this.gotoPage(this.currentPage, false); // If the size changed this should recenter the current page without animating
  }

  onPointerDown(eventData: Vector2WithInfo) {
    // Left button only
    if(eventData.buttonIndex !== 0)
      return;

    // If the slider is animating, stop it
    this.stopAnimations();

    // Start an emulated mouse capture
    this.grabbed = true;
    this.grabX = eventData.x;
    this.grabY = eventData.y;
    this.sliderX = this.pagePanel.leftInPixels;
  }

  onPointerUp(eventData: Vector2WithInfo) {
    if(eventData.buttonIndex !== 0 || !this.grabbed)
      return;

    // Let go
    this.grabbed = false;

    // If the pointer hasn't moved, consider it a click
    let deltaX = eventData.x - this.grabX;
    if(!deltaX) {
      if(this.onCarouselClickedObservable.hasObservers())
        this.onCarouselClickedObservable.notifyObservers(eventData);
      return;
    }

    // Snap to nearest page
    let page = this.getNearestPage(deltaX);
    this.gotoPage(page);
  }

  onPointerMove(eventData: Vector2) {
    // Ignore if we haven't grabbed the mouse
    if(!this.grabbed)
      return;

    // Don't move without pages
    if(this.pagePanel.children.length < 2)
      return;

    // Calculate the new position of the slider
    let x = this.sliderX + eventData.x - this.grabX;

    // Keep it in bounds
    x = Math.max(x, (this.pagePanel.children.length - 1) * -this.pageWidth);
    x = Math.min(x, 0);

    // Move the slider
    this.pagePanel.left = x;

    // Mark the page
    let page = this.getNearestPage(eventData.x - this.grabX);
    this.setCurrentPage(page);
  }

  getNearestPage(direction = 0) {
    if(!this.pagePanel.children.length)
      return;

    // We want to count as the next page if the pagePanel is exposing at least 25% of the page in the direction of dragging
    let pageFraction = this.pagePanel.leftInPixels / -this.pageWidth;
    let page = Math.floor(pageFraction);
    pageFraction -= page;

    let wasPage = page;

    if(direction < 0) {
      // Dragging left
      if(pageFraction >= 0.25)
        page += 1;
    } else if(direction > 0) {
      // Dragging right
      if(pageFraction > 0.75)
        page += 1;
    } else {
      // Nearest page
      page = Math.round(this.pagePanel.leftInPixels / -this.pageWidth);
    }

    // Check bounds
    page = Math.max(page, 0);
    page = Math.min(page, this.pagePanel.children.length - 1);
    return page;
  }

  /** setCurrentPage is called when a new page becomes mostly visible. It lights up the right indicator button, and notifies observers
   * It doesn't change which page is visible, for that use gotoPage
   */
  setCurrentPage(page: number) {
    if(page < 0 || page >= this.pageButtonPanel.children.length)
      return;

    this.currentPage = page;

    let pageButton = this.pageButtonPanel.children[page] as RadioButton;
    if(pageButton)
      pageButton.isChecked = true;

    if(this.onPageChangedObservable.hasObservers())
      this.onPageChangedObservable.notifyObservers(page);
  }

  /** change the currently visible page
   * @param page The page to goto
   * @param animate if true animates from current position to page being fully visible, set false when relaying out to avoid glitchy looking animation
   */
  gotoPage(page: number, animate: boolean = true) {
    if(page < 0 || page >= this.pageButtonPanel.children.length)
      return;

    // Stop any animations
    this.stopAnimations();

    // Update the page marker
    this.setCurrentPage(page);

    let x = page * -this.pageWidth;

    // Animate to the page
    if(animate) {
      let a = new Animation("CarouselPageAnimation", "left", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let keyFrames = [];
      let k = 3;
      keyFrames.push({frame: 0, value: this.pagePanel.leftInPixels});
      keyFrames.push({frame: k, value: x});
      a.setKeys(keyFrames);
      this.pageAnimation = game.scene.beginDirectAnimation(this.pagePanel, [a], 0, k, false);
    }
    else
      this.pagePanel.left = x;
  }

  stopAnimations() {
    if(this.pageAnimation) {
      this.pageAnimation.stop();
      this.pageAnimation = null;
    }
  }
}
