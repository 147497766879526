import { castToReferenceSnapshot, detach, getSnapshot, types } from "mobx-state-tree";

import { GameState, IGameStateErrorCallback } from "states/game/GameState";
import { Facing, IPieceState, PieceState } from "states/game/PieceState";
import { IPileState, PileState } from "states/game/PileState";
import { ISeatState } from "states/game/SeatState";

import { logger } from "utils/logger";

// tslint:disable-next-line:variable-name
const PilesGameState = GameState
  .props({
    piles: types.map(PileState),
  })
  .views((self) => {

    /** look in all piles for pieceName */
    function findPiece(pieceOrName: IPieceState | string): IPieceState {

      let foundPiece: IPieceState = null;
      self.piles.forEach((pile) => {
        if(foundPiece) // we can't break forEach, so just stop doing anything once we find a piece
          return;
        if(typeof pieceOrName === "string")
          foundPiece = pile.pieces.find((piece) => piece.name === pieceOrName);
        else
          foundPiece = pile.pieces.find((piece) => piece === pieceOrName);
      });

      return foundPiece;
    }

    /** look in all piles for a piece with value */
    function findPieceByValue(value: number): IPieceState {
      let foundPiece: IPieceState = null;
      self.piles.forEach((pile) => {
        if(foundPiece) // we can't break forEach, so just stop doing anything once we find a piece
          return;
        foundPiece = pile.pieces.find((piece) => piece.value === value);
      });
      return foundPiece;
    }

    function findAllPiecesOwnedBySeat(seatOrId: ISeatState | string): IPieceState[] {
      let ownedPieces: IPieceState[] = [];
      let seat = self.getSeat(seatOrId);
      if(seat === null)
        return ownedPieces;

      self.piles.forEach((pile) => {
        pile.pieces.forEach((piece) => {
          if(piece.seat && piece.seat.id === seat.id)
            ownedPieces.push(piece);
        });
      });

      return ownedPieces;
    }

    /** returns how many points a piece is worth, ie in Hearts a Heart is 1 point, Queen of Spades is 13 */
    function getPiecePoints(pieceOrName: IPieceState | string): number {
      // overridden by subclasses
      return 0;
    }

    function canClickPiece(playerId: string, seatOrId: ISeatState | string, pileOrName: IPileState | string, pieceOrName: IPieceState | string, errorCallback?: IGameStateErrorCallback): boolean {
      // overridden by subclass
      return false;
    }

    function canClickPile(playerId: string, seatOrId: ISeatState | string, pileOrName: IPileState | string): boolean {
      // overridden by subclass
      return false;
    }

    /** Checks if a piece can be removed from pile, if it can return list of pieces to be removed with it(including it) */
    function canRemovePiece(playerId: string, seatOrId: ISeatState | string, pileOrName: IPileState | string, pieceOrName: IPieceState | string): IPieceState[] {
      // overridden by subclass
      return null;
    }

    function canAddPieces(playerId: string, seatOrId: ISeatState | string, pileOrName: IPileState | string, piecesOrNames: Array<IPieceState | string>): boolean {
      // overridden by subclass
      return false;
    }

    function canSelectPieces(playerId: string, seatOrId: ISeatState | string, pileOrName: IPileState | string, piecesOrNames: Array<IPieceState | string>, setReady: boolean, errorCallback?: IGameStateErrorCallback): boolean {
      // overridden by subclass
      return false;
    }

    /** getAutoMoveDest is for double tapping a solitaire card to move it to foundation. It returns the name of the pile the piece can be auto moved to, or null if none. */
    function getAutoMoveDest(playerId: string, seatOrId: ISeatState | string, pileOrName: IPileState | string, pieceOrName: IPieceState | string): string {
      // overridden by subclass
      return null;
    }

    /** canAutoFinish checks if the game can auto finish, ie klondike remaining cards are in tableau and face up. */
    function canAutoFinish(playerId: string, seatOrId: ISeatState | string): boolean {
      // overridden by subclass
      return false;
    }

    /** finds a pile by name, or if we're passed a IPileState just return it. Useful for resolving params like fromPileOrName
     * @param pileOrName  can be an IPileState or a string name of pile
     */
    function getPile(pileOrName: IPileState | string): IPileState {
      if(typeof pileOrName === "string")
        return self.piles.get(pileOrName);
      else
        return pileOrName;
    }

    /** get the pile name from a min pile name, ie return hand0 for h0 */
    function pileNameFromMin(minPileName: string) {
      return minPileName.replace("h", "hand");
    }

    return { findPiece, findPieceByValue, findAllPiecesOwnedBySeat, getPiecePoints, canClickPiece, canRemovePiece, canAddPieces, canSelectPieces, canClickPile, getAutoMoveDest, canAutoFinish, getPile, pileNameFromMin };
  })
  .actions((self) => {
      /** auto finishes a game, for example moving cards from tableau to foundations, canAutoFinish must return true  */
    function autoFinish(playerId: string, seatOrId: ISeatState | string) {
      // overridden by subclass
    }

    function scorePiece(piece: IPieceState, fromPile: IPileState, toPile: IPileState) {
      // overridden by subclass
    }

    return { autoFinish, scorePiece };
  })
  .actions((self) => {
    /** movePieces should only be used internally by other GameState and subclasses actions that are sure the move is valid, ie dealing, flipping cards in stock.
     * use requestMovePieces instead.
     * toFacing - facing to set when we add the card to pile
     * toSeat - seat to assign to piece when adding to the pile
     * toValue - value to assign to piece
     */
    function movePieces(fromPileOrName: IPileState | string, toPileOrName: IPileState | string, piecesOrNames: Array<IPieceState | string>,  toFacing: Facing = null, toSeat: ISeatState = null, toValue: number = null) {
      let fromPile = self.getPile(fromPileOrName);
      let toPile = self.getPile(toPileOrName);

      piecesOrNames.forEach((pieceOrName) => {
        let piece = fromPile.getPiece(pieceOrName);
        if (piece) {
          detach(piece); // finds piece and removes it from whereever it is, it's assumed that it is in the fromPile
          // as an optimization to reduce patches we set facing and seat while the piece is detached from the tree
          // But we can't set the seat of a piece while it is detached because seat is a reference, and the reference needs to be in the same tree
          // So instead we make a copy of the piece, modify seat and facing, and then push a newly created piece to the toPile
          const pieceSnapshot = Object.assign({}, getSnapshot(piece));
          if(toSeat)
            pieceSnapshot.seat = toSeat.id;

          if(toFacing)
            pieceSnapshot.facing = toFacing;

          if(toValue !== null)
            pieceSnapshot.value = toValue;

          // be sure piece is unselected (for passing in trick games)
          if(pieceSnapshot.selected)
            pieceSnapshot.selected = false;

          // Create new piece add to pile
          let newPiece = PieceState.create(pieceSnapshot);
          toPile.pieces.push(newPiece);

          self.scorePiece(piece, fromPile, toPile);
        }
        else
          logger.warn("movePieces piece not found", { fromPile: fromPile.name, toPile: toPile.name, piece: pieceOrName});
      });
    }

    /** create a PileState object and add it to piles
     * @param name name of pile ie tableau0
     * @param type type of pile ie tableau
     * @param seat(optional) the seat that owns this pile, ie a hand in spades
     */
    function createPile(name: string, type: string, seat?: ISeatState) {
      let pile = PileState.create({
        name,
        type,
        seat: castToReferenceSnapshot(seat),
      });

      self.piles.put(pile);
    }

    /** set a list of pieces selected, then optionally set seat ready. This is used for passing cards in hearts */
    function selectPieces(playerId: string, seatOrId: ISeatState | string, pileOrName: IPileState | string, piecesOrNames: Array<IPieceState | string>, setReady: boolean) {
      // first be sure it is valid
      if(!self.canSelectPieces(playerId, seatOrId, pileOrName, piecesOrNames, setReady))
        return;

      piecesOrNames.forEach((pieceOrName) => {
        let piece = self.findPiece(pieceOrName);
        if (piece) {
          piece.setSelected(playerId, true);
        }
      });

      if(setReady)
        self.setSeatReady(playerId, seatOrId, true);
    }

    return { movePieces, createPile, selectPieces };
  })
  .actions((self) => {
    function createPileGroup(name: string, type: string, count: number) {
      for (let i = 0; i < count; i++) {
        self.createPile(name + i, type);
       }
    }

    /** The UI should use requestMovePieces to move pieces, it makes sure it is a valid move first    */
    function requestMovePieces(playerId: string, seatOrId: ISeatState | string, fromPileOrName: IPileState | string, toPileOrName: IPileState | string, piecesOrNames: Array<IPieceState | string>) {
      // Make sure it is a valid move first, unless we're dealing, then always allow
      if(!self.canRemovePiece(playerId, seatOrId, fromPileOrName, piecesOrNames[0]) || !self.canAddPieces(playerId, seatOrId, toPileOrName, piecesOrNames))
        return;
      self.movePieces(fromPileOrName, toPileOrName, piecesOrNames);
    }

    function requestAutoFinish(playerId: string, seatOrId: ISeatState | string) {
      if(self.canAutoFinish(playerId, seatOrId))
        self.autoFinish(playerId, seatOrId);
    }

    function deal() {
      // overridden by subclass
    }

    function clickPiece(playerId: string, seatId: string, pileName: string, pieceName: string) {
      // overridden by subclass
    }

    function clickPile(playerId: string, seatOrId: ISeatState | string, pileOrName: IPileState | string) {
      // overridden by subclass
    }

    return { createPileGroup, requestMovePieces, requestAutoFinish, deal, clickPiece, clickPile };
  });

// PilesGameState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IPilesGameState = typeof PilesGameState.Type;

export { PilesGameState, IPilesGameState };
