import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { ElasticEase } from "@babylonjs/core/Animations/easing";
import { Color3 } from "@babylonjs/core/Maths/math";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Observable } from "@babylonjs/core/Misc/observable";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { TextWrapping } from "@babylonjs/gui/2D/controls/textBlock";
import { game } from "components/game/Game";
import { PlayerSystem } from "components/game/PlayerSystem";
import { ImageWithFallback } from "components/ui/Controls";
import { AnimatableRectangle } from "components/ui/controls/AnimatableRectangle";
import { InviteButton } from "components/ui/InviteButton";
import { findGuiControl } from "components/utils/GUI";
import { GUIAnimationProxy } from "components/utils/GUIAnimationProxy";
import { ISeatState } from "states/game/SeatState";
import { WonTricks } from "states/game/WonTricks";
import { BID_DISPLAY_MODE_METER, BID_DISPLAY_MODE_TEXT, BidDisplayMode } from "states/user/UserState";
import { config } from "utils/Config";

import inviteAvatarUrl from "components/game/avatars/invite_128.png";
import switchSeatUrl from "components/game/avatars/switch_seat.png";

import pawn0AvatarUrl from "components/game/avatars/pawn0.png";
import pawn1AvatarUrl from "components/game/avatars/pawn1.png";

//import nameBGBlackUrl from "components/game/avatars/name-bg-black.png";
import nameBGBlueUrl from "components/game/avatars/name-bg-blue.png";
import nameBGRedUrl from "components/game/avatars/name-bg-red.png";

// SeatColor is an index into the COLORS, nameBGUrl and pawnAvatarUrls lists
export enum SeatColor {
  Red = 0,
  Blue = 1,
}
const COLORS = [
  "#FF0000",  // Red
  "#00AEEF",  // Blue
];

const nameBGUrl = [
  nameBGRedUrl,   // Red
  nameBGBlueUrl,  // Blue
];

const pawnAvatarUrls = [
  pawn0AvatarUrl,  // Red
  pawn1AvatarUrl,  // Blue
];

const NIL_BID_COLOR = Color3.Black();
const NIL_BID_FAILED_COLOR = Color3.Red();
const NIL_BID_FAILED_ALPHA = 0.25;

// If the bid is higher than this, we'll switch to text mode
const MAX_METER_BID = 9;

/**
 * The PlayerGUIObject displays an image and information about a player
 * It is used here for in-game avatars and also used by SpadesRoundSummary
 */
export class PlayerGUIObject extends Rectangle {
  playerSystem: PlayerSystem;
  bidDisplayMode: BidDisplayMode;
  meterSide = "right";

  playerName: string; // first line of text under picture
  scoreText: string;  // optional second line of text

  bid: number = null;
  wonTricksCount: number = 0;
  wonTricksLength: number = 0;
  made: boolean;
  seatColor: SeatColor; // color of name plaque and background

  bidMeter: Rectangle = null;
  meters: Image[] = [];
  nil: AnimatableRectangle = null;
  nilText: TextBlock = null;
  text: TextBlock = null;
  textBox: Rectangle;
  textbg: Image = null;
  bidTextBackground: Rectangle = null;
  bidText: TextBlock = null;
  image: ImageWithFallback = null;
  teamImageBox: Button = null;
  inviteImage: Image = null;
  inviteButton: InviteButton = null;
  switchSeatImageButton: Button = null;
  switchSeatImageAnimation: Animatable = null;
  moveAnimation: Animatable;

  baseHeight = 0;
  textWidth = 0;
  textHeight = 0;
  imageTop = 0;
  imageWidth = 0;
  imageOffset = 0;
  textOffset = 0;
  teamThickness = 1;
  meterLeftPos = 0;
  meterRightPos = 0;
  meterTop = 0;
  meterStep = 0;

  centerOffsetX = 0;
  centerOffsetY = 0;

  bidDisplayClickObservable = new Observable();
  switchSeatClickObservable = new Observable();

  constructor(playerSystem: PlayerSystem, name: string, index: number, seatColor: SeatColor, baseHeight?: number) {
    super(name);

    this.playerSystem = playerSystem;
    this.baseHeight = baseHeight;
    this.seatColor = seatColor;

    // Root player object
    this.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //this.background = "black"; //"#FAEBD7";
    //this.color = "yellow";
    this.isPointerBlocker = true;
    this.thickness = 0;

    // Text
    let textBox = new Rectangle(name + "TextBlockBox");
    textBox.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    //textBox.background = "magenta";
    textBox.thickness = 0;
    this.addControl(textBox);
    this.textBox = textBox;

    let textbg = new Image(name + "TextBlockBG", nameBGUrl[this.seatColor]);
    textbg.alpha = 0.3;
    textBox.addControl(textbg);
    this.textbg = textbg;

    let text = new TextBlock(name + "TextBlock");
    text.color = "white";
    text.outlineColor = "black";
    text.outlineWidth = 2; //Math.max(1, Math.floor(textHeight / 16)); // Beyond 2 there are weird spikey artifacts on "M" for example
    text.fontFamily = config.fontFamily;
    text.fontWeight = config.fontWeight;
    text.textWrapping = TextWrapping.Ellipsis;
    textBox.addControl(text);
    this.text = text;

    // Image Background - clips the image
    let team = new Button(name + "ImageBox");
    team.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    team.background = "black";
    team.color = COLORS[this.seatColor];
    this.addControl(team);
    this.teamImageBox = team;

    // Image
    this.image = new ImageWithFallback(name + "Image");
    team.addControl(this.image);

    this.inviteImage = new Image(name + "InviteImage", inviteAvatarUrl);
    this.inviteImage.isVisible = false;
    team.addControl(this.inviteImage);

    // Bid Meter
    let bidMeter = new Rectangle(name + "bidMeter");
    //bidMeter.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    bidMeter.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    //bidMeter.background = "magenta";
    bidMeter.thickness = 0;
    bidMeter.isVisible = true;
    bidMeter.onPointerClickObservable.add((eventData, eventState) => { if(this.bid !== null) {this.bidDisplayClickObservable.notifyObservers(undefined); eventState.skipNextObservers = true; }});
    this.addControl(bidMeter);
    this.bidMeter = bidMeter;

    // NIL
    let nilbg = new AnimatableRectangle(name + "nilBackground");
    nilbg.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    nilbg.backgroundColor3 = NIL_BID_COLOR;
    nilbg.thickness = 0;
    nilbg.isVisible = false;
    bidMeter.addControl(nilbg);
    this.nil = nilbg;

    let niltext = new TextBlock(name + "nilText");
    niltext.color = "white";
    niltext.text = "N\nI\nL";
    nilbg.addControl(niltext);
    this.nilText = niltext;

    // Create bottom-up to improve glow overlap
    // One extra, for animating, but it has to be last, so make it -1
    for(let i = 12; i >= -1; i--) {
      let r = new Image(name + "bid" + i);
      r.domImage = this.playerSystem.bidImage;
      r.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
      r.isVisible = false;
      bidMeter.addControl(r);
      // Note meters[-1] actually works but it works like meters["-1"] which sets an object property rather than actually accessing the array
      this.meters[i] = r;
    }

    // Bid Text
    let bidtextbg = new Rectangle(name + "bidTextBackground");
    bidtextbg.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    bidtextbg.left = (this.meterSide === "left") ? -this.imageWidth * 0.5 : this.imageWidth * 0.5; // also setMeterSide
    bidtextbg.background = "black";
    bidtextbg.thickness = 0;
    bidtextbg.isVisible = true;
    bidtextbg.onPointerClickObservable.add((eventData, eventState) => { this.bidDisplayClickObservable.notifyObservers(undefined); eventState.skipNextObservers = true; });
    this.addControl(bidtextbg);
    this.bidTextBackground = bidtextbg;

    let bidtext = new TextBlock(name + "bidText");
    bidtext.fontFamily = config.fontFamily;
    bidtext.fontWeight = config.fontWeight;
    bidtext.color = "white";
    bidtextbg.addControl(bidtext);
    this.bidText = bidtext;

    // The switchSeat button should be to the lower right corner of avatar. I just used meterWidth to roughly size and position it for now
    this.switchSeatImageButton = Button.CreateImageOnlyButton(name + "SwitchSeatImage", switchSeatUrl);
    this.switchSeatImageButton.thickness = 0;
    this.switchSeatImageButton.isVisible = false;
    this.switchSeatImageButton.onPointerClickObservable.add((eventData, eventState) => { this.switchSeatClickObservable.notifyObservers(undefined); eventState.skipNextObservers = true; });
    this.addControl(this.switchSeatImageButton);

    this.updateBidDisplayMode();

    this.layout();
  }

  setBaseHeight(baseHeight: number) {
    this.baseHeight = baseHeight;
    this.layout();
  }

  layout() {
    if(this.baseHeight === undefined || this.baseHeight === null || this.baseHeight <= 0)
      return;

    this.stopAnimations();

    let height = this.baseHeight;

    let fontHeight = 30 * game.getScale() * 0.8; // Base textHeight on game scale (dpi scale) for readability
    let lineSpacing = fontHeight * -0.2;

    const textLines = (this.text.text.match(/\n/g) || "").length + 1; // count how many lines of text ie is it just Name, or Name\nScore
    let textHeight = fontHeight * textLines + fontHeight * 1.5;
    let textWidth = fontHeight * 7;

    let boxWidth = Math.max(textWidth, height * 2.0);
    let boxHeight = textHeight + height * 1.25;
    let imageWidth = height;
    let imageCornerRadius = imageWidth / 16;

    let imageTop = -textHeight * 0.8;

    let teamThickness = imageWidth / 32;

    let meterBaseWidth = imageWidth * 0.6;

    let meterWidth = meterBaseWidth * 71 / 129 * 1.3;
    let meterHeight = meterWidth * 91 / 129;
    let meterPad = meterHeight / 3;
    let meterRightPos = imageWidth * 0.5 + meterBaseWidth * 0.3;
    let meterLeftPos = -meterRightPos;
    let meterTop = meterPad * 0.5;
    let meterStep = meterHeight - meterPad * 1.55;

    let nilWidth = meterBaseWidth * 0.5;
    let nilHeight = imageWidth * 0.8;

    let bidTextFontHeight = fontHeight * 0.75;
    let bidTextHeight = bidTextFontHeight * 1.25;
    let bidTextWidth = bidTextHeight * 2.5;

    // Save a couple of values for handling who's turn it is
    this.textWidth = textWidth;
    this.textHeight = textHeight;
    this.imageTop = imageTop;
    this.imageWidth = imageWidth;
    this.imageOffset = boxHeight - textHeight - imageWidth * 0.5;
    this.textOffset = this.imageOffset + textHeight * 0.9; // Trying to keep south player just above south hand cards
    this.teamThickness = teamThickness;
    this.meterTop = meterTop;
    this.meterStep = meterStep;
    this.meterLeftPos = meterLeftPos;
    this.meterRightPos = meterRightPos;

    this.centerOffsetX = boxWidth * 0.5;
    this.centerOffsetY = boxHeight + imageTop - teamThickness - imageWidth * 0.5;

    // Root player object
    this.width = boxWidth + "px";
    this.height = boxHeight + "px";

    // Text
    this.textBox.width = textWidth + "px";
    this.textBox.height = textHeight + "px";
    this.textbg.height = textHeight + "px";

    this.text.fontSize = fontHeight + "px";
    this.text.lineSpacing = lineSpacing + "px"; // remove space between name and score lines of text

    // Image Background - clips the image
    this.teamImageBox.top = imageTop - teamThickness;
    this.teamImageBox.width = (imageWidth - teamThickness * 2) + "px";
    this.teamImageBox.height = (imageWidth - teamThickness * 2) + "px";
    this.teamImageBox.thickness = teamThickness; // Due to some sort of imprecision in babylon's rounded corners, we need a thickness here so that the outline matches the turn overlay
    this.teamImageBox.cornerRadius = imageCornerRadius;

    // The switchSeat button should be to the lower right corner of avatar. I just used meterWidth to roughly size and position it for now
    this.switchSeatImageButton.width = meterWidth + "px";
    this.switchSeatImageButton.height = meterWidth + "px";
    this.switchSeatImageButton.top = meterWidth / 2; // this doesn't position very well, it's not anchored to the bottom right of avatar
    this.switchSeatImageButton.left = ((this.meterSide === "left") ? meterLeftPos : meterRightPos );

    // Bid Meter
    this.bidMeter.width = meterWidth + "px";
    this.bidMeter.height = (boxHeight + imageTop + meterPad * 0.5 - 1) + "px";
    this.bidMeter.top = imageTop + meterPad * 0.5;
    this.bidMeter.left = (this.meterSide === "left") ? meterLeftPos : meterRightPos;

    // NIL
    this.nil.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.nil.width = nilWidth + "px";
    this.nil.height = nilHeight + "px";
    this.nil.top = (nilHeight - imageWidth) * 0.5;

    this.nilText.fontSize = (nilHeight / 3 * 0.8) + "px";
    this.nilText.lineSpacing = (nilHeight / 3 * 0.8 * -0.1) + "px";

    for(let i = 12; i >= -1; i--) {
      this.meters[i].top = meterTop - i * meterStep;
      this.meters[i].width = meterWidth + "px";
      this.meters[i].height = meterHeight + "px";
    }

    // Bid Text
    this.bidTextBackground.left = (this.meterSide === "left") ? -this.imageWidth * 0.5 : this.imageWidth * 0.5; // also setMeterSide
    this.bidTextBackground.top = imageTop;
    this.bidTextBackground.width = bidTextWidth + "px";
    this.bidTextBackground.height = bidTextHeight + "px";
    this.bidTextBackground.cornerRadius = bidTextHeight / 4;

    this.bidText.fontSize = bidTextFontHeight + "px";

    // Invite Button
    if(this.inviteButton)
      this.inviteButton.layout();
  }

  getGlobalImageCenter(): Vector2 {
    // The team is horizontally centered, but positioned vertically from the bottom
    let x = this.leftInPixels + this.widthInPixels * 0.5;
    let y = this.topInPixels + this.heightInPixels + this.teamImageBox.topInPixels - this.teamImageBox.heightInPixels * 0.5;

    return new Vector2(x, y);
  }

  getBidImage(made: boolean, type: string) {
    if(type === "won")
      return made ? this.playerSystem.bidMetImage : this.playerSystem.bidWonImage;
    else if(type === "partner")
      return this.playerSystem.bidWonPartnerImage;
    else if(type === "bag")
      return this.playerSystem.bidWonImage;
    else if(type === "fail" /*|| type === "miss"*/)
      return this.playerSystem.bidWonImage; //this.playerSystem.bidNilFailedImage;
    else
      return this.playerSystem.bidImage;
  }

  setBid(bid: number, animate: boolean) {
    if(bid === this.bid)
      return;

    this.bid = bid;

    this.setBidText();
    this.updateBidDisplayMode();

    // Stop any existing animation
    this.stopAnimations();

    // Hide everything to start to handle the case where the bid changes for any reason
    this.nil.isVisible = false;
    for(let m of this.meters) {
      m.isVisible = false;
    }

    // If the bid hasn't been set yet, leave everything hidden
    if(bid === null || bid === undefined)
      return;

    // NIL bid
    if(bid === 0)
    {
      this.nil.backgroundColor3 = NIL_BID_COLOR;
      this.nil.isVisible = true;
      this.nil.alpha = 1.0;
      return;
    }

    // Bid is set, animate in the meter pips
    for(let i = 0; i < bid; i++) {
      let m = this.meters[i];
      m.domImage = this.playerSystem.bidImage;
      m.isVisible = true;

      // Stop any existing animation
      let animatable = game.scene.getAnimatableByTarget(m);
      if(animatable !== null) {
        animatable.goToFrame(animatable.toFrame);
        animatable.stop();
      }

      if(!animate)
        continue;

      let a = new Animation("BidMeterBid", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let keyFrames = [];
      let k0 = i * 5;
      let k1 = k0 + 10;
      if(k0)
        keyFrames.push({frame: 0, value: this.meterTop - 13 * this.meterStep });
      keyFrames.push({frame: k0, value: this.meterTop - 13 * this.meterStep });
      keyFrames.push({frame: k1, value: this.meterTop - i * this.meterStep });
      a.setKeys(keyFrames);

      let alphaAnimation = new Animation("BidMeterBidFade", "alpha", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      let alphaKeys = [];
      if(k0)
        alphaKeys.push({ frame: 0, value: 0.0 });
      alphaKeys.push({ frame: k0, value: 0.0 });
      alphaKeys.push({ frame: k1, value: 1.0 });
      alphaAnimation.setKeys(alphaKeys);

      game.scene.beginDirectAnimation(m, [a, alphaAnimation], 0, k1, false);
    }

  }

  setWon(wonTricks: WonTricks, wonTricksLength: number, animate: boolean, summaryTiming = 0) {
    if(!wonTricks)
      return;
    if(wonTricksLength < 0)
      wonTricksLength = wonTricks.length;

    // As a workaround for glitching a successful NIL to look like it failed,
    // watch out for wonTricks with only a "nil" entry, when animate is false
    // (I tried looking at wonTricksCount instead of wonTricksLength for NIL failed,
    // which ought to have worked, but created problems where it failed to recognize a true failed NIL)
    if(!animate && wonTricks.length === 1 && wonTricks.getType(0) === "nil")
      wonTricksLength = 0;

    if(wonTricks.made === this.made && wonTricksLength === this.wonTricksLength)
      return;

    // Don't animate the top pip if only the made flag has changed
    if(wonTricks.made !== this.made && wonTricksLength === this.wonTricksLength)
      animate = false;

    this.made = wonTricks.made;
    this.wonTricksCount = wonTricks.wonTricksCount;
    this.wonTricksLength = wonTricksLength;

    this.setBidText();
    this.updateBidDisplayMode();

    // Grab the extra pip for animation
    // Note that -1 is like "-1" and is an object property, not the last element
    let proxy = this.meters[-1];

    // Stop any existing animation
    this.stopAnimations();

    if(this.bid === 0 && this.wonTricksLength > 0)
    {
      this.nil.backgroundColor3 = NIL_BID_FAILED_COLOR;
      this.nil.isVisible = true;
      this.nil.alpha = NIL_BID_FAILED_ALPHA;
    }

    // We need at least 1 won trick to proceed
    if(!wonTricksLength)
      return;

    for(let i = 0; i < wonTricksLength ; i++) {
      let image = this.getBidImage(wonTricks.made, wonTricks.getType(i));

      let m = this.meters[i];

      // Only animate the last pip
      if(i < wonTricksLength - 1 || !animate) {
        m.domImage = image;
        m.isVisible = true;
        continue;
      }

      let keyFrames = [];
      let k0 = summaryTiming ? 0 : 30; // We're trying to match this up to the delay in TrickGameAnimation
      let k1 = k0 + (summaryTiming ? summaryTiming : 10);

      // If this broke the nil, animate the color change
      if(this.bid === 0 && i === 0) {
        let fadeAni = new Animation("NilBidFade", "backgroundColor3", 30.0, Animation.ANIMATIONTYPE_COLOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
        keyFrames = [];
        if(k0)
          keyFrames.push({ frame: 0, value: NIL_BID_COLOR });
        keyFrames.push({ frame: k0, value: NIL_BID_COLOR });
        keyFrames.push({ frame: k1, value: NIL_BID_FAILED_COLOR });
        fadeAni.setKeys(keyFrames);

        let alphaNilAni = new Animation("BidMeterNilFade", "alpha", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        keyFrames = [];
        if(k0)
          keyFrames.push({ frame: 0, value: 1.0 });
        keyFrames.push({ frame: k0, value: 1.0 });
        keyFrames.push({ frame: k1, value: NIL_BID_FAILED_ALPHA });
        alphaNilAni.setKeys(keyFrames);

        game.scene.beginDirectAnimation(this.nil, [fadeAni, alphaNilAni], 0, k1, false, 1.0);
      }

      // Animate in the top pip
      proxy.isVisible = true;
      proxy.domImage = image;

      let a = new Animation("BidMeterBid", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      keyFrames = [];
      if(k0)
        keyFrames.push({frame: 0, value: this.meterTop - 13 * this.meterStep });
      keyFrames.push({frame: k0, value: this.meterTop - 13 * this.meterStep });
      keyFrames.push({frame: k1, value: this.meterTop - this.wonTricksLength * this.meterStep });
      a.setKeys(keyFrames);

      let alphaAni = new Animation("BidMeterBidFade", "alpha", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
      keyFrames = [];
      if(k0)
        keyFrames.push({ frame: 0, value: 0.0 });
      keyFrames.push({ frame: k0, value: 0.0 });
      keyFrames.push({ frame: k1, value: 1.0 });
      alphaAni.setKeys(keyFrames);

      game.scene.beginDirectAnimation(proxy, [a, alphaAni], 0, k1, false, 1.0, () => {
        proxy.isVisible = false;
        this.meters[i].domImage = image;
        this.meters[i].isVisible = true;

        if(summaryTiming)
          game.soundSystem.play("highlightHigh");
      });
    }
  }

  /** This player has won a trick (even if the trick counts towards the partners bid) */
  onTrickWon() {
    // Animate the player's image scale slightly

    if(!this.teamImageBox)
      return;

    // The proxy saves needing to animate scaleX and scaleY with separate animations
    let proxy = new GUIAnimationProxy(this.teamImageBox);

    // Constant to keep the timing copied from setWon, if we want this from the summary screen it can be a parameter
    let summaryTiming = false;

    let k0 = summaryTiming ? 0 : 30; // We're trying to match this up to the delay in TrickGameAnimation
    let k1 = k0 + (summaryTiming ? summaryTiming : 10);

    let scaleAni = new Animation("onTrickWonScale", "scale", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let k05 = Math.floor((k0 + k1) * 0.5);
    let keyFrames = [];
    keyFrames.push({ frame: 0, value: 1.0 });
    keyFrames.push({ frame: k0, value: 1.0 });
    keyFrames.push({ frame: k05, value: 1.1 });
    keyFrames.push({ frame: k1, value: 1.0 });
    scaleAni.setKeys(keyFrames);
    game.scene.beginDirectAnimation(proxy, [scaleAni], 0, k1);
  }

  setText(text: string) {
    // As of March 2018, Facebook appears to have a 50 character limit on usernames
    // As of September 2011, Facebook had a limit of 75 characters each for first and last name, I couldn't find any more recent information
    //text = "WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW";
    //text = "Tap to Invite"; // This has to fit without "..."

    // Make sure this is a change
    if(this.text.text === text)
      return;

    this.text.text = text;

    // Babylon won't tell us the width of the text, but we can get it from the canvas context
    let ctx = game.guiTexture.getContext();
    ctx.font = config.fontWeight + " " + this.text.fontSizeInPixels + "px " + config.fontFamily;
    let metrics = ctx.measureText(this.text.text);
    let width = metrics.width;

    // Set the text background to be a wee bit wider than the text.
    this.textbg.width = Math.min(width + this.textHeight, this.textWidth) + "px";

    this.layout();
  }

  /** combine playerName and optionally score text and set to text gui object */
  updateText() {
    let text = this.playerName;
    if(this.scoreText) {
      let offset = parseInt(game.getSouthSeat());
      let index = parseInt(this.name.slice(-1));
      let position = (index + 4 - offset) % 4;

      if(position === 0) {
        // This is the local seat, display only the score to save space
        text = this.scoreText;
      } else {
        // For other seats, add the score text as a 2nd line
        text += "\n" + this.scoreText;
      }
    }
    this.setText(text);
  }

  setScoreText(text: string) {
    this.scoreText = text;
    this.updateText();
  }

  setImageUrl(url: string, fallback?: string) {
    this.image.setSource(url, fallback);
  }

  showInviteImage(show: boolean) {
    let offset = parseInt(game.getSouthSeat());
    let index = parseInt(this.name.slice(-1));
    let position = (index + 4 - offset) % 4;

    // Make sure the inviteButton goes away if our position changes or it's being hidden
    if(this.inviteButton && (!show || position !== 2)) {
      this.inviteButton.dispose();
      this.inviteButton = null;
      this.isVisible = true;
    }

    // Use inviteButton for the top player
    if(position === 2) {
      if(show && !this.inviteButton) {
        this.inviteButton = new InviteButton("InviteButton");
        this.parent.addControl(this.inviteButton);
        //this.inviteButton.show(); // We're animating the inviteButton ourselves, so don't call show()
        this.inviteButton.isVisible = true;
        this.isVisible = false;
      }

      // To be sure inviteImage is set correctly in case of a seat switch
      // We'll set show to false here and continue to the inviteImage code below
      show = false;
    }

    // Use inviteImage for the other players
    if(this.inviteImage.isVisible === show)
      return;

    this.inviteImage.isVisible = show;
    this.switchSeatImageButton.isVisible = show;
  }

  setNameAndImageFromSeat(seat: ISeatState) {
    let name = null;
    let imageUrl = null;

    // Try to get information from seat player
    if(seat.player) {
      if(seat.player.name)
        name = seat.player.name;
      if(seat.player.imageUrl)
        imageUrl = seat.player.imageUrl;
      else if(seat.player.bot)
        imageUrl = config.botAvatarImageUrls[name];

      if(seat.player.id) {
        if(name && imageUrl) {
          // Cache the values
          this.playerSystem.nameAndImageCache[seat.player.id] = {name, imageUrl};
        } else {
          // Try to get values from the cache
          let cacheEntry = this.playerSystem.nameAndImageCache[seat.player.id];
          if(cacheEntry) {
            name = cacheEntry.name;
            imageUrl = cacheEntry.imageUrl;
          }
        }
      }
    }

    let fallbackUrl = pawnAvatarUrls[this.seatColor];

    // Invite Mode
    if(!name) {
      this.playerName = "Invite Friend";
      this.updateText();
      this.showInviteImage(true);
      return;
    }

    this.showInviteImage(false);

    if(!imageUrl)
      imageUrl = fallbackUrl;

    // Update the player
    this.playerName = name;
    this.updateText();
    this.setImageUrl(imageUrl, fallbackUrl);
  }

  setTurn(turn: boolean) {
    this.textbg.alpha = turn ? 1.0 : 0.3;
  }

  setBidText() {
    if(this.bid === null || this.bid === undefined || this.wonTricksCount === null || this.wonTricksCount === undefined) {
      this.bidText.text = "";
      this.bidTextBackground.isVisible = false;
    }
    else {
      this.bidTextBackground.isVisible = (this.getBidDisplayMode() === BID_DISPLAY_MODE_TEXT);
      if(this.bid === 0 && this.wonTricksCount === 0)
        this.bidText.text = "NIL";
      else
        this.bidText.text = this.wonTricksCount + "/" + this.bid;
    }
  }

  getBidDisplayMode() {
    if(this.bid > MAX_METER_BID || this.wonTricksCount > MAX_METER_BID)
      return BID_DISPLAY_MODE_TEXT;
    return this.bidDisplayMode;
  }

  updateBidDisplayMode() {
    this.bidMeter.isVisible = (this.getBidDisplayMode() === BID_DISPLAY_MODE_METER);
    this.bidTextBackground.isVisible = (this.getBidDisplayMode() === BID_DISPLAY_MODE_TEXT) && this.bidText.text !== "";
  }

  setBidDisplayMode(mode: BidDisplayMode | string) {
    if(this.bidDisplayMode === mode)
      return;

    this.bidDisplayMode = mode as BidDisplayMode;

    this.updateBidDisplayMode();
  }

  setMeterSide(side: string) {
    if(side !== "left" && side !== "right")
      return; // Error?

    if(side === this.meterSide)
      return;

    this.meterSide = side;

    this.bidMeter.left = (this.meterSide === "left") ? this.meterLeftPos : this.meterRightPos;
    this.bidTextBackground.left = (this.meterSide === "left") ? -this.imageWidth * 0.5 : this.imageWidth * 0.5;
  }

  aniMove(x0: number, y0: number, x1: number, y1: number, duration: number) {
    this.stopMoveAnimation();

    let proxy = new GUIAnimationProxy(this);

    let from = new Vector2(x0, y0);
    let to = new Vector2(x1, y1);

    this.left = from.x;
    this.top = from.y;

    let a = new Animation(this.name + "AniMove", "position", 30, Animation.ANIMATIONTYPE_VECTOR2, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = k0 + Math.floor(duration * 30);
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    a.setKeys(keyFrames);

    // Elasic easing overshoots, then springs back
    let easingMode = EasingFunction.EASINGMODE_EASEOUT;
    let easingFunction = new ElasticEase(2, 8);
    easingFunction.setEasingMode(easingMode);
    a.setEasingFunction(easingFunction);

    this.moveAnimation = game.scene.beginDirectAnimation(proxy, [a], 0, k1, false);
  }

  animateIn() {
    this.stopMoveAnimation();

    let offset = parseInt(game.getSouthSeat());
    let index = parseInt(this.name.slice(-1));
    let position = (index + 4 - offset) % 4;

    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height - menuBarHeight;

    let control = this.inviteButton || this;
    let proxy = new GUIAnimationProxy(control);

    let from = new Vector2(control.leftInPixels, control.topInPixels);
    let to = new Vector2(control.leftInPixels, control.topInPixels);

    switch(position) {
      case 0:
        // Since the south player is actually on the left, we'll drop through and animate in from the left like the west player
        //from.y = guiHeight + control.heightInPixels;
        //break;
      case 1:
        from.x = -control.widthInPixels;
        break;
      case 2:
        from.y = -control.heightInPixels;
        break;
      case 3:
        from.x = guiWidth + control.widthInPixels;
        break;
    }

    control.left = from.x;
    control.top = from.y;

    let duration = 45;
    let delay = 7; // + 10 * index;

    let k0 = delay;
    let k1 = k0 + duration;

    let ka0 = k0 + Math.floor(duration * 0.05);
    let ka1 = k0 + Math.floor(duration * 0.5);

    let keyFrames = [];

    let positionAnimation = new Animation(this.name + "AnimateIn", "position", 30, Animation.ANIMATIONTYPE_VECTOR2, Animation.ANIMATIONLOOPMODE_CONSTANT);
    keyFrames = [];
    keyFrames.push({frame: 0, value: from});
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    positionAnimation.setKeys(keyFrames);

    let alphaAnimation = new Animation(this.name + "AnimateIn", "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    keyFrames = [];
    keyFrames.push({frame: 0, value: 0});
    keyFrames.push({frame: ka0, value: 0});
    keyFrames.push({frame: ka1, value: 1});
    alphaAnimation.setKeys(keyFrames);

    // Elasic easing overshoots, then springs back
    let easingMode = EasingFunction.EASINGMODE_EASEOUT;
    let easingFunction = new ElasticEase(2, 8);
    easingFunction.setEasingMode(easingMode);
    positionAnimation.setEasingFunction(easingFunction);

    this.moveAnimation = game.scene.beginDirectAnimation(proxy, [positionAnimation, alphaAnimation], 0, k1, false);

    // Because Babylon only inherits alpha from the immediate parent
    // And we use alpha on textbg to indicate the turn
    // We must additionally animate alpha on textbg's parent, textbox
    this.moveAnimation.appendAnimations(this.textBox, [alphaAnimation]);
  }

  stopMoveAnimation() {
    if(this.moveAnimation) {
      this.moveAnimation.goToFrame(this.moveAnimation.toFrame); // Advance to final position
      this.moveAnimation.stop();
      this.moveAnimation = null;
    }
  }

  stopAnimations() {
    this.stopMoveAnimation();

    let animatable: Animatable = null;

    for(let i = 12; i >= -1; i--) {
      animatable = game.scene.getAnimatableByTarget(this.meters[i]);
      if(animatable !== null) {
        animatable.goToFrame(animatable.toFrame);
        animatable.stop();
      }
    }

    animatable = game.scene.getAnimatableByTarget(this.nil);
    if(animatable !== null) {
      animatable.goToFrame(animatable.toFrame);
      animatable.stop();
    }
  }

  isAnimating() {
    // XXX - not checking bids

    let animatable = game.scene.getAnimatableByTarget(this.meters[-1]);
    if(animatable !== null)
      return true;

    animatable = game.scene.getAnimatableByTarget(this.nil);
    if(animatable !== null)
      return true;

    return false;
  }

  getBidMarkerGlobalPosition(index: number) {
    if(this.getBidDisplayMode() === BID_DISPLAY_MODE_TEXT) {
      let localCoordinatesForZero = this.bidTextBackground.getLocalCoordinates(new Vector2(0, 0));

      let x = -localCoordinatesForZero.x +  this.bidTextBackground.widthInPixels * 0.5;
      let y = -localCoordinatesForZero.y + this.bidTextBackground.heightInPixels * 0.5;

      return new Vector2(x, y);
    } else {
      // Babylon JS gives us global -> local, but not local -> global
      // So we'll get global (0, 0) -> local and use that as an offset to get global coordinates
      // This returns the negative offsets to move the top left corner of the bidMeter to global (0, 0)
      let localCoordinatesForZero = this.bidMeter.getLocalCoordinates(new Vector2(0, 0));

      let x = -localCoordinatesForZero.x + this.bidMeter.widthInPixels * 0.5;
      let y = -localCoordinatesForZero.y + this.bidMeter.heightInPixels - this.meterTop - index * this.meterStep - this.meterStep * 0.5;

      return new Vector2(x, y);
    }
  }

  dispose() {
    super.dispose();
    if(this.inviteButton) {
      this.inviteButton.dispose();
      this.inviteButton = null;
    }
  }
}
