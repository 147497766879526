/** Initializes global singleton StateSync client */

import SocketCluster from "socketcluster-client";
import { CONNECTION_STATUS_CONNECTED, CONNECTION_STATUS_CONNECTING, CONNECTION_STATUS_DISCONNECTED, getRootState } from "states/RootState";

import { setServerTime } from "components/utils/ServerTime";

import { config } from "utils/Config";
import { logger } from "utils/logger";

export interface ICredentials {
  authType: string;
  serviceUserId?: string; // the user id from the auth service ie facebook, not our user id.
  signature?: string;
  clientVersion?: string;
}

// global singleton instance of app sync client
let _client: SocketCluster.SCClientSocket;

export function stateSyncInit() {
  let options = {
    hostname: config.scHost,
    secure: config.scSecure,
    port: config.scPort,
    autoConnect: false, // don't autoConnect so that we can optionally deauth before connecting
    rejectUnauthorized: false, // Only necessary during debug if using a self-signed certificate
    ackTimeout: 60 * 60 * 1000, //  timeout in ms for getting a response to a SCSocket emit event. Set it really to give plenty of time to let client auto reconnect
    autoReconnectOptions: {
      initialDelay: 500, // default 10,000 ms
      randomness: 1000, // default 10,000 ms, timeout is initialDelay + randomness * Math.random()
      multiplier: 1.5, // decimal, used to set back off rate
      maxDelay: 10000, // default 60,0000 ms
    },
  };

  _client = SocketCluster.create(options);
  getRootState().setConnectionStatus(CONNECTION_STATUS_CONNECTING);

  // on facebook we need to deauth before connecting, because if the user switches facebook accounts then it could connect and auth
  // with the previous users authToken.
  if(!config.reuseAuth)
    _client.deauthenticate();

  _client.connect();

  _client.on("error", (err: any) => {
    logger.info("SocketCluster error", { err });
  });

  _client.on("disconnect", () => {
    getRootState().setConnectionStatus(CONNECTION_STATUS_DISCONNECTED);
  });

  _client.on("connecting", () => {
    getRootState().setConnectionStatus(CONNECTION_STATUS_CONNECTING);
  });

  // set user.id as soon as we're authenticated. Hopefully this happens before any other "authenticate" handlers get called that need user.id set
  // We need to set user.id in response to on("authenticate" instead of in response to emit("login") because the on handlers get called before the emit("login") callback
  _client.on("authenticate", () => {
    // Caution! this can get called multiple times during the initial connection.
    let authToken = _client.getAuthToken() as any;
    getRootState().user.setId(authToken.userId);
  });

  _client.on("connect", (status: SocketCluster.SCClientSocket.ConnectStatus) => {
    logger.info("Socket is connected.", { status });
    getRootState().setConnectionStatus(CONNECTION_STATUS_CONNECTED);

    // if we're not on facebook, then login now, otherwise we need to wait for facebook getSignedPlayerInfoAsync
    if(config.autoAuth) {
      stateSyncLoginJWT();
    }
  });
}

/** Login and auth with jwt (JSON Web Token). If we're already auth'd it will reuse auth token, but it's still import to login to send current clientVersion */
export function stateSyncLoginJWT() {
  stateSyncLogin({ authType: "jwt" });
}

export function stateSyncLogin(credentials: ICredentials) {
  credentials.clientVersion = process.env.VERSION;
  waitForConnect().then((client: SocketCluster.SCClientSocket) => {
    _client.emit("login", credentials, (err: any, responseData: any) => {
      if(err)
        logger.info("Login error", { err });
      else {
        // user.id is set by on("authenticate"... handler above
        let authToken = _client.getAuthToken() as any;
        if(!authToken || !authToken.userId)
          logger.info("Failed log in, bad authToken.", { authToken });

        // set server time, used to check for expired inventory items.
        if(responseData && responseData.serverTime)
          setServerTime(responseData.serverTime);
      }
    });
  });
}

/** Returns a promise that resolves when the global socket client is connected. Resolves immediatly if it is already connected. */
export function waitForConnect(): Promise<SocketCluster.SCClientSocket> {
  return new Promise<SocketCluster.SCClientSocket> ((resolve, reject) => {
    if(_client.getState() === _client.OPEN)
      resolve(_client);
    else {
      _client.on("connect", (status: SocketCluster.SCClientSocket.ConnectStatus) => {
        resolve(_client);
      });
    }
  });
}

/** Returns a promise that resolves when the global socket client is connected and authenticated. Resolves immediatly if it is already authenticated. */
export function waitForAuth(): Promise<SocketCluster.SCClientSocket> {
  return new Promise<SocketCluster.SCClientSocket> ((resolve, reject) => {
    waitForConnect().then((client: SocketCluster.SCClientSocket) => {
      if(_client.authState === _client.AUTHENTICATED)
        resolve(_client);
      else {
        _client.on("authenticate", (authToken: string) => {
          resolve(_client);
        });
      }
    });
  });
}

export function getStateSyncClient(): SocketCluster.SCClientSocket {
  return _client;
}

/** Returns the userId of the currently connected client */
export function getStateSyncClientUserId(): string {
  let authToken = _client.getAuthToken() as any;
  if(authToken && authToken.userId)
    return authToken.userId;
  else
    return null;
}
