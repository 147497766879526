import { IAnimationKey } from "@babylonjs/core/Animations/animationKey";
import { Color4, Curve3, Vector2, Vector3 } from "@babylonjs/core/Maths/math";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";

import { game } from "components/game/Game";
import { debugGUILine, debugGUILineEmpty } from "components/utils/GUI";

/** Interface for passing options to make an animation path */
export interface IPath2dOptions {
  startX: number;
  startY: number;

  targetX: number;
  targetY: number;

  duration?: number; // default 30 frames (1 second)

  sideScale?: number; // Scaling for side vector, default of 1.0 is left

  debugLines?: boolean; // 2d GUI lines
  debugLines3d?: boolean; // 3d guiRootTransformNode lines
}

// For debug drawing the side vector
let lastMid: Vector3 = null;
let lastSide: Vector3 = null;

/** Calls MakeAnimationPath3d and converts the keys to Vector2 */
export function makeAnimationPath2d(options: IPath2dOptions): IAnimationKey[] {
  // Get the 3d animation keyframes
  let path3d = makeAnimationPath3d(options);

  // Convert to Vector2
  let keys: IAnimationKey[] = [];

  for(let key of path3d) {
    keys.push({
      frame: key.frame,
      value: new Vector2(key.value.x, key.value.y),
    });
  }

  // Draw 2d GUI debug lines, if requested
  if(options.debugLines === true)
    debugDrawAnimationPath2d(keys);

  return keys;
}

/** Uses Curve3.CreateCatmullRomSpline to create a path that curves from the two end points through a computed mid point above the line */
export function makeAnimationPath3d(options: IPath2dOptions): IAnimationKey[] {
  let start = new Vector3(options.startX, options.startY, 0);
  let target = new Vector3(options.targetX, options.targetY, 0);
  let mid = Vector3.Center(start, target);
  let frames = options.duration || 30;

  let dir = target.subtract(start);
  let distance = dir.length();
  dir.normalize();

  let sideScale = distance * 0.125;
  if(options.sideScale !== undefined)
    sideScale *= options.sideScale;

  // Compute an orthogonal vector 90 that makes a 90 degree angle with the start to target line, on the left
  let ortho = Vector3.Cross(dir, new Vector3(0, 0, 1));

  // Compute a side point along the ortho vector
  let side = mid.add(ortho.scale(sideScale));

  // Save the last mid and side vectors in case we need them for debug drawing
  lastMid = mid;
  lastSide = side;

  // Create the spline points
  let controlPoints = [
    start,
    side,
    target,
  ];

  let curve = Curve3.CreateCatmullRomSpline(controlPoints, 20);
  let points = curve.getPoints();
  let length = curve.length();

  // Create the animation keyframes
  let keys: IAnimationKey[] = [];

  let lastPoint = points[0];
  let lastDistance = 0;

  for(let point of points) {
    lastDistance += point.subtract(lastPoint).length();
    lastPoint = point;

    keys.push({
      frame: lastDistance / length * frames,
      value: point,
    });
  }

  // Draw 3d debug lines, if requested
  if(options.debugLines3d === true)
    debugDrawAnimationPath3d(keys);

  return keys;
}

/** Draw 2d lines for an animation path
 * Expects Vector2 values
 * supports tangent points
 */
export function debugDrawAnimationPath2d(keys: IAnimationKey[]) {
  let name = "debugDrawAnimationPath2d";

  debugGUILine(name + "DebugDirLine", keys[0].value.x, keys[0].value.y, keys[keys.length - 1].value.x, keys[keys.length - 1].value.y, "red", 20);
  debugGUILine(name + "DebugOrthoLine", lastMid.x, lastMid.y, lastSide.x, lastSide.y, "yellow", 20);

  let pathLines = debugGUILineEmpty(name + "DebugPath", "green");

  for(let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
    let key = keys[keyIndex];
    let pos = key.value;
    let tangent = key.inTangent || key.outTangent;

    if(tangent)
      debugGUILine(name + "DebugT" + keyIndex, pos.x, pos.y, pos.x + tangent.x, pos.y + tangent.y, "blue", 10);

    if(keyIndex === keys.length - 1)
      continue;

    let nextKey = keys[keyIndex + 1];
    let nextPos = nextKey.value;
    let frames = nextKey.frame - key.frame;

    for(let t = 0; t <= 100; t++) {
      let p: Vector2 = null;
      if(key.outTangent && nextKey.inTangent)
        p = Vector2.Hermite(pos, key.outTangent.scale(frames), nextPos, nextKey.inTangent.scale(frames), t / 100);
      else
        p = Vector2.Lerp(pos, nextPos, t / 100);

      pathLines.add({x: p.x, y: p.y});
    }
  }
}

/**
 * Draw 3d lines for an animation path
 * Expects Vector3 values
 * Does not support tangents
 * Lines are created in the guiRootTransformNode and associated with the aboveGUICamera
 */
export function debugDrawAnimationPath3d(keys: IAnimationKey[]) {
  // Cleanup any previous debug lines
  let oldMesh = game.scene.getMeshByName("debugDrawAnimationPath2dBaseLine");
  if(oldMesh)
    oldMesh.dispose();

  oldMesh = game.scene.getMeshByName("debugDrawAnimationPath2dOrthoLine");
  if(oldMesh)
    oldMesh.dispose();

  oldMesh = game.scene.getMeshByName("debugDrawAnimationPath3d");
  if(oldMesh) {
    oldMesh.dispose();
  }

  // Debug Base Line
  let debugPoints = [
    keys[0].value,
    keys[keys.length - 1].value,
  ];

  let colors = Array(debugPoints.length).fill(new Color4(1, 0, 0, 1));

  let lines = MeshBuilder.CreateLines("debugDrawAnimationPath2dBaseLine", {points: debugPoints, colors}, game.scene);
  lines.parent = game.guiRootTransformNode;
  lines.layerMask = game.aboveGUICamera.layerMask;

  // Debug side line
  debugPoints = [
    lastMid,
    lastSide,
  ];

  colors = Array(debugPoints.length).fill(new Color4(1, 1, 0, 1));

  lines = MeshBuilder.CreateLines("debugDrawAnimationPath2dOrthoLine", {points: debugPoints, colors}, game.scene);
  lines.parent = game.guiRootTransformNode;
  lines.layerMask = game.aboveGUICamera.layerMask;

  // Debug curve lines
  let points: Vector3[] = [];

  for(let key of keys) {
    points.push(key.value);
  }

  colors = Array(points.length).fill(new Color4(0, 1, 0, 1));

  lines = MeshBuilder.CreateLines("debugDrawAnimationPath3d", {points, colors}, game.scene);
  lines.parent = game.guiRootTransformNode;
  lines.layerMask = game.aboveGUICamera.layerMask;
}
