import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import { ImageWithFallback, IntegerTextBlock } from "components/ui/Controls";
import { getXPLevelTierColor } from "components/utils/XPTierColors";
import { config } from "utils/Config";

import pawn0AvatarUrl from "components/game/avatars/pawn0.png";
import largeStarUrl from "components/ui/leaderboard-sidebar/large-star.png";

function ordinal(n: number) {
  // https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
  // This handles "1st", "11th" and "21st" by magic
  let s = ["th", "st", "nd", "rd"];
  let v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export class LeaderboardPlayer extends Rectangle {
  userId: string;
  playerName: string;
  score: number;
  rank: number;
  level: number;

  index: number = -1;
  flagToRemove = false;
  ordinalRank = false;

  imageContainer: Rectangle;
  rankText: TextBlock;
  scoreText: IntegerTextBlock;
  playerImage: ImageWithFallback;
  nameText: TextBlock;
  levelText: TextBlock;

  layoutHeight: number = 0;

  rankAnimatable: Animatable;

  constructor(name: string) {
    super(name);
  }

  setImage(url: string) {
    this.playerImage.setSource(url, pawn0AvatarUrl);
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  setPlayerName(playerName: string) {
    this.playerName = playerName;
    if(this.nameText)
      this.nameText.text = playerName;
  }

  setRank(rank: number) {
    this.rank = rank;

    if(this.ordinalRank)
      this.rankText.text = ordinal(rank);
    else
      this.rankText.text = "" + rank;
  }

  setScore(score: number) {
    this.score = score;

    // XXX - Currently this usually animates the wrong player
    //this.scoreText.animate(this.scoreText.value, score, 30, game.scene);

    this.scoreText.value = score;
  }

  setScoreColor(color: string, shadowBlur: number = 0, shadowColor?: string) {
    this.scoreText.color = color;
    this.scoreText.shadowBlur = shadowBlur;
    this.scoreText.shadowColor = shadowColor || color;
  }

  setLevel(level: number) {
    this.level = level;

    let color = getXPLevelTierColor(level);

    if(this.levelText) {
      this.levelText.color = color;
      this.levelText.text = `Level ${level}`;
      this.levelText.isVisible = (level > 0);
    }

    if(this.nameText)
      this.nameText.color = color;
  }

  setIndex(index: number) {
    if(this.index === index)
      return;

    //let oldIndex = this.index;
    this.index = index;

    this.top = this.index * this.layoutHeight;

    /*
    let oldTop = oldIndex * this.playerWidth;
    let newTop = index * this.playerWidth;

    this.stopAnimations();

    let a = new Animation("RankPositioning", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = k0 + 30;
    keyFrames.push({frame: k0, value: oldTop});
    keyFrames.push({frame: k1, value: newTop});
    a.setKeys(keyFrames);

    this.rankAnimatable = game.scene.beginDirectAnimation(this, [a], 0, k1, false, 1.0);
    */
  }

  remove() {
    if(this.index === 4)
      return;
    this.setIndex(4);
    this.name += "REMOVED";
    this.userId = null;
    this.rankAnimatable.onAnimationEndObservable.add(() => this.dispose());
  }

  setLayoutHeight(layoutHeight: number) {
    this.layoutHeight = layoutHeight;
    this.layout();
  }

  layout() {
  }

  stopAnimations() {
    if(this.rankAnimatable) {
      this.rankAnimatable.stop();
      this.rankAnimatable = null;
    }
  }

  dispose() {
    super.dispose();

    this.stopAnimations();

    this.imageContainer = null;
    this.rankText = null;
    this.scoreText = null;
    this.playerImage = null;
    this.nameText = null;
    this.levelText = null;
  }
}

export class LeaderboardPlayerCompact extends LeaderboardPlayer {
  rankStarImage: Image;

  constructor(name: string) {
    super(name);

    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //this.background = "magenta";
    this.thickness = 0;

    this.imageContainer = new Rectangle(name + "ImageContainer");
    this.imageContainer.background = "black";
    this.imageContainer.thickness = 0;
    this.addControl(this.imageContainer);

    this.playerImage = new ImageWithFallback(name + "Image", pawn0AvatarUrl);
    this.imageContainer.addControl(this.playerImage);

    this.rankStarImage = new Image(name + "PlayerStarImage", largeStarUrl);
    this.rankStarImage.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.rankStarImage.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.rankStarImage.isHitTestVisible = false;
    this.addControl(this.rankStarImage);

    this.rankText = new TextBlock(name + "RankText");
    this.rankText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.rankText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.rankText.fontFamily = config.fontFamily;
    this.rankText.fontWeight = config.fontWeight;
    this.rankText.color = "black";
    this.rankText.text = "";
    this.rankText.isHitTestVisible = false;
    this.addControl(this.rankText);

    this.scoreText = new IntegerTextBlock(name + "ScoreText");
    this.scoreText.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.scoreText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.scoreText.fontFamily = config.fontFamily;
    this.scoreText.fontWeight = config.fontWeight;
    this.scoreText.color = "white";
    this.scoreText.outlineColor = "black";
    this.scoreText.outlineWidth = 2;
    this.scoreText.isHitTestVisible = false;
    this.addControl(this.scoreText);
  }

  layout() {
    let imageWidth = Math.floor(this.layoutHeight * 0.75);
    let titleHeight = Math.floor(this.layoutHeight * 0.2);
    let playerStarWidth = this.layoutHeight * 0.333;

    this.top = (this.rank - 1) * this.layoutHeight;

    this.width = this.layoutHeight + "px";
    this.height = this.layoutHeight + "px";

    this.imageContainer.width = imageWidth + "px";
    this.imageContainer.height = imageWidth + "px";
    this.imageContainer.cornerRadius = imageWidth / 8;

    this.rankStarImage.width = playerStarWidth + "px";
    this.rankStarImage.height = playerStarWidth + "px";

    this.rankText.top = playerStarWidth * 0.125;
    this.rankText.width = playerStarWidth + "px";
    this.rankText.height = playerStarWidth + "px";
    this.rankText.fontSize = (titleHeight * 0.8) + "px";

    this.scoreText.width = this.layoutHeight + "px";
    this.scoreText.height = playerStarWidth + "px";
    this.scoreText.fontSize = (playerStarWidth * 0.8) + "px";
  }

  stopAnimations() {
    if(this.rankAnimatable) {
      this.rankAnimatable.stop();
      this.rankAnimatable = null;
    }
  }

  dispose() {
    super.dispose();

    this.rankStarImage = null;
  }
}

export class LeaderboardPlayerWide extends LeaderboardPlayer {
  stack: StackPanel;
  spacer: Control;

  ordinalRank = true;

  constructor(name: string) {
    super(name);

    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    //this.background = "blue";
    //this.color = "yellow";
    this.thickness = 0;

    this.stack = new StackPanel(name + "StackPanel");
    this.stack.isVertical = false;
    //this.stack.background = "green";
    this.addControl(this.stack);

    this.rankText = new TextBlock(name + "RankText");
    this.rankText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.rankText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.rankText.fontFamily = config.fontFamily;
    this.rankText.fontWeight = config.fontWeight;
    this.rankText.color = "white";
    this.rankText.text = "";
    this.stack.addControl(this.rankText);

    this.imageContainer = new Rectangle(name + "ImageContainer");
    this.imageContainer.background = "black";
    this.imageContainer.thickness = 0;
    this.stack.addControl(this.imageContainer);

    this.playerImage = new ImageWithFallback(name + "Image", pawn0AvatarUrl);
    this.imageContainer.addControl(this.playerImage);

    this.spacer = new Control(name + "spacer");
    this.stack.addControl(this.spacer);

    let nameLevelStack = new StackPanel(name + "NameLevelStack");
    nameLevelStack.isVertical = true;
    nameLevelStack.adaptWidthToChildren = true;
    this.stack.addControl(nameLevelStack);

    this.nameText = new TextBlock(name + "RankText");
    this.nameText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.nameText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.nameText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.nameText.fontFamily = config.fontFamily;
    this.nameText.fontWeight = config.fontWeight;
    this.nameText.color = "white";
    this.nameText.text = "Name";
    nameLevelStack.addControl(this.nameText);

    this.levelText = new TextBlock(name + "RankText");
    this.levelText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.levelText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.levelText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.levelText.fontFamily = config.fontFamily;
    this.levelText.fontWeight = config.fontWeight;
    this.levelText.color = "white";
    this.levelText.text = "Level 9999";
    nameLevelStack.addControl(this.levelText);

    this.scoreText = new IntegerTextBlock(name + "ScoreText");
    this.scoreText.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.scoreText.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.scoreText.fontFamily = config.fontFamily;
    this.scoreText.fontWeight = config.fontWeight;
    this.scoreText.color = "white";
    this.scoreText.isHitTestVisible = false;
    this.stack.addControl(this.scoreText);
  }

  layout() {
    let imageHeight = this.layoutHeight * 0.8;
    let textWidth = imageHeight * 2;
    let textHeight = imageHeight;
    let fontHeight = textHeight * 0.667;

    let smallTextWidth = textWidth * 2.0;
    let smallTextHeight = textHeight * 0.5;
    let smallFontHeight = fontHeight * 0.5;

    this.width = (this.layoutHeight * 8) + "px";
    this.height = this.layoutHeight + "px";

    this.rankText.width = textWidth + "px";
    this.rankText.height = textHeight + "px";
    this.rankText.fontSize = fontHeight + "px";

    this.imageContainer.width = imageHeight + "px";
    this.imageContainer.height = imageHeight + "px";
    this.imageContainer.cornerRadius = imageHeight / 8;

    this.spacer.width = (imageHeight * 0.25) + "px";

    this.nameText.width = smallTextWidth + "px";
    this.nameText.height = smallTextHeight + "px";
    this.nameText.fontSize = smallFontHeight + "px";

    this.levelText.width = smallTextWidth + "px";
    this.levelText.height = smallTextHeight + "px";
    this.levelText.fontSize = smallFontHeight + "px";

    this.scoreText.width = textWidth + "px";
    this.scoreText.height = textHeight + "px";
    this.scoreText.fontSize = fontHeight + "px";
  }

  stopAnimations() {
    if(this.rankAnimatable) {
      this.rankAnimatable.stop();
      this.rankAnimatable = null;
    }
  }

  dispose() {
    super.dispose();

    this.stack = null;
    this.spacer = null;
    }
}
