import { Sound } from "@babylonjs/core/Audio/sound";
import { Engine } from "@babylonjs/core/Engines/engine";
import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents";

import { ArgoSystem } from "components/game/ArgoSystem";
import { game } from "components/game/Game";
import { APPLY_SNAPSHOT_STAGE_DONE } from "states/state-sync/BaseStateSync";
import { config } from "utils/Config";
import { logger } from "utils/logger";

export class SoundSystem extends ArgoSystem {
  renderIdMap: {[key: string]: number} = {};
  backgroundMusicSound: Sound;
  updatingAudioPlaybackEnabled: boolean = false;

  init() {
    // if we don't have an audioEngine then likely we're in loadTestClientMode, so disable audio
    if(!Engine.audioEngine)
      return;

    this.rootState.router.addRoute("^\/user\/sound$", (patch: any, reversePatch: any, params: any) => this.updateAudioPlaybackEnabled());
    this.rootState.router.addRoute("^\/user\/music$", (patch: any, reversePatch: any, params: any) => this.updateAudioPlaybackEnabled());
    this.rootState.router.addRoute("^\/user\/applySnapshotStage$", (patch: any, reversePatch: any, params: any) => this.updateAudioPlaybackEnabled());
    this.rootState.router.addRoute("^\/paused$", (patch: any, reversePatch: any, params: any) => this.updateAudioPlaybackEnabled());

    // Don't show Babylon's default audio unlock button
    Engine.audioEngine.useCustomUnlockedButton = true;

    // On any pointer down event, try to unlock the audio
    this.game.scene.onPrePointerObservable.add(() => {
      // NOTE: BABYLON's try/catch block around resuming the audioContext is failing to catch the exception if there is no audio context, so we'll check ourselves first
      if(Engine.audioEngine.audioContext && !Engine.audioEngine.unlocked) {
        Engine.audioEngine.unlock();
      }
    }, PointerEventTypes.POINTERDOWN);

    this.updateAudioPlaybackEnabled();
  }

  /** play a configured sound url */
  play(configSound: string) {
    let entry = config.sounds[configSound];
    if(!entry) {
      logger.info("No sound entry", { configSound });
      return;
    }

    // If the entry url is null, assume the sound is disabled
    if(!entry.url)
      return;

    this.playUrl(entry.url, entry.volume);
  }

  /** Play a sound by URL or array of URLs.  If an array, one will be randomly selected
   *  js supports .mp3, .ogg, .wav and "blob:" with it's audioEngine (if supported by the browser)
   *  Supposedly m4a (or aac or mp4) should work in all modern browsers, but Babylon's Sound object isn't checking
   *  Other formats theoretically should play via the Audio tag, however .m4a files did not work
   *  Ogg seems to be supported by everyone except Apple
   *  So .mp3 seems the best choice at the moment
   */
  playUrl(urlOrArray: string | string[], volume: number) {
    let soundEnabled = this.rootState.user.sound && this.isAudioPlaybackEnabled();
    if(!soundEnabled)
      return;

    // If urlOrArray is an array, make a random selection
    let url = "";
    if(typeof urlOrArray === "string")
      url = urlOrArray;
    else
      url = urlOrArray[Math.floor(Math.random() * urlOrArray.length)];

    // Has the sound been loaded?
    let sound = this.game.scene.getSoundByName(url);
    if(!sound) {
      // create a new sound, and re-call playUrl when it's loaded
      sound = new Sound(url, url, this.game.scene, () => this.playUrl(urlOrArray, volume));
      return;
    }

    // If the sound isn't ready, we're still waiting for that readyToPlayCallback
    if(!sound.isReady())
      return;

    // If we already played the sound this frame, don't play it again
    if(this.wasPlayedThisFrame(url))
      return;

    // Set the volume
    sound.setVolume(volume);

    // Start playing
    sound.play();

    // Record the frame it was started in
    this.renderIdMap[url] = this.game.scene.getRenderId();
  }

  /** play a sound url continuously in the background */
  playBackgroundMusic(configSound: string) {
    let entry = config.music[configSound];
    if(!entry) {
      logger.info("No music entry", { configSound });
      return;
    }

    // If the entry url is null, assume the sound is disabled
    if(!entry.url)
      return;

    this.playBackgroundMusicUrl(entry.url, entry.volume);
  }

  playBackgroundMusicUrl(urlOrArray: string | string[], volume: number) {
    if(!this.isAudioPlaybackEnabled())
      return;

    // If urlOrArray is an array, make a random selection
    let url = "";
    if(typeof urlOrArray === "string")
      url = urlOrArray;
    else
      url = urlOrArray[Math.floor(Math.random() * urlOrArray.length)];

    this.stopBackgroundMusic();

    this.backgroundMusicSound = new Sound("BackgroundMusic", url, this.game.scene, null, {
      volume,
      // Streaming in Babylon uses HTMLAudioTags instead of WebAudio
      // Which on iOS causes the mute switch to be ignored
      // Which Facebook does not like at all
      // So we disable streaming now.  The music will need to download before starting
      streaming: false,

      autoplay: true,
      loop: true,
    });
  }

  pauseBackgroundMusic() {
    if(this.backgroundMusicSound) {
      this.backgroundMusicSound.pause();
    }
  }

  stopBackgroundMusic() {
    // XXX - This works with a logged warning and error
    //       Invalid URI. Load of media resource  failed.
    //       AbortError: The operation was aborted.
    // Calling stop first makes no difference, in fact dispose will call stop if the sound is playing
    if(this.backgroundMusicSound) {
      this.backgroundMusicSound.dispose();
      this.backgroundMusicSound = null;
    }
  }

  wasPlayedThisFrame(url: string) {
    if(this.renderIdMap[url] === this.game.scene.getRenderId())
      return true;
    return false;
  }

  /** Returns true if the AudioEngine and AudioContext are able to play audio */
  isAudioPlaybackEnabled() {
    if(!Engine.audioEngine || !Engine.audioEngine.audioContext)
      return false;

    let context = Engine.audioEngine.audioContext;
    if(context.state === "closed")
      return false;

    if(context.state === "running")
      return true;

    return false;
  }

  /** Update the AudioEngine and AudioContext enabled state to match the state we want */
  async updateAudioPlaybackEnabled() {
    // XXX - Chrome works without this, but on Firefox updateAudioPlaybackEnabled gets called before the previous updated is completed
    //       Somehow that confuses things and no sound plays.  Maybe Chrome worked by luck.
    //       Using the flag to prevent re-entry works, but maybe there's another way to go about this

    // Watch for being called while we're working
    if(this.updatingAudioPlaybackEnabled) {
      setTimeout(() => this.updateAudioPlaybackEnabled(), 10);
      return;
    }

    this.updatingAudioPlaybackEnabled = true;

    await this._updateAudioPlaybackEnabled();

    this.updatingAudioPlaybackEnabled = false;
  }

  /** internal function to sync the audio playback state with our desired state */
  async _updateAudioPlaybackEnabled() {
    // Previously we called updateAudioPlaybackEnabled, however, setting scene.audioEnabled false in response to FBInstant.onPause is throwing an exception sometimes on iOS
    // The exception occurs in native code in the browser when Babylon calls stop on the AudioBufferSourceNode representing a Babylon.sound internally
    // Here we're going behind Babylon's back to suspend the AudioContext, as of Babylon 4.0.3, Babylon doesn't appear to be doing anything similar
    // According to the AudioContext documentation on MDN, this should actually be better because it pauses the audio and frees up hardware resources
    // Whereas setting Scene.audioEnabled simply calls stop on all sounds

    // Check if Babylon has an audioEngine, and if the audoEngine has an AudioContext
    if(!Engine.audioEngine || !Engine.audioEngine.audioContext)
      return;

    // The context has 3 states "close", "running" or "suspended"
    // Check if it's closed
    let context = Engine.audioEngine.audioContext;
    if(context.state === "closed")
      return;

    // Enable audio if sound or music volume is not 0, and the game is not paused
    // check for APPLY_SNAPSHOT_STAGE_DONE to not enable music until the user state is loaded, otherwise if user has music off, it may play a few seconds until user state is loaded.
    let soundOrMusicEnabled = (this.rootState.user.sound > 0) || (this.rootState.user.music > 0);
    let audioPlaybackEnabled = config.haveSound && soundOrMusicEnabled && !this.rootState.paused && this.rootState.user.applySnapshotStage === APPLY_SNAPSHOT_STAGE_DONE;

    // See if we need to take action
    if(audioPlaybackEnabled !== this.isAudioPlaybackEnabled()) {
      // Suspend or resume the AudioContext
      let suspend = !audioPlaybackEnabled;

      if(suspend && context.state === "running")
        await Engine.audioEngine.audioContext.suspend();

      else if(!suspend && context.state === "suspended")
        await Engine.audioEngine.audioContext.resume();
    }

    // Also update sound & music
    this.updateSoundEnabled();
    this.updateMusicEnabled();
  }

  /** Update in response to the sound enabled state, we only need to stop any currently playing sounds if disabled */
  updateSoundEnabled() {
    let soundEnabled = this.rootState.user.sound;

    // If sound is enabled, we don't need to do anything here
    if(soundEnabled)
      return;

    // If sound is disabled, we need to stop any currently playing sounds, except our BackgroundMusic
    for(let sound of game.scene.mainSoundTrack.soundCollection) {
      // Leave the background music alone here
      if(sound.name === "BackgroundMusic")
        continue;

      // Stop anything else
      sound.stop();
    }
  }

  /** Update in response to changes to the music enabled state.  We'll need to play or pause the music. */
  updateMusicEnabled() {
    let musicEnabled = this.rootState.user.music;

    // Start the background music playing if both sound and music are enabled and nothing is playing
    if(this.isAudioPlaybackEnabled() && musicEnabled) {
      if(this.backgroundMusicSound) {
        // Resume the background music
        if(this.backgroundMusicSound.isPaused)
          this.backgroundMusicSound.play();
      } else {
        // Play 1st bit of music defined
        let musicKeys = Object.keys(config.music);
        if(musicKeys.length)
          this.playBackgroundMusic(musicKeys[0]);
      }
    }

    // We won't ever stop the background music, only pause it
    // This is to avoid a delay we're seeing before the music begins, even if cached by the browser
    if(!musicEnabled)
      this.pauseBackgroundMusic();
  }
}

/** Simple animatable object to play a sound when a value transitions across whole numbers
 * (I had forgotton about AnimationEvent, which can fire off a script, but these are still useful)
 */
export class SoundAnimationTrigger {
  configSound: string;
  _value = 0;

  constructor(configSound: string) {
    this.configSound = configSound;
  }

  initValue(value: number) {
    this._value = value;
  }

  set value(newValue: number) {
    newValue = Math.floor(newValue);

    if(newValue === this._value)
      return;

    if(newValue > this._value)
      this.play();

    this._value = newValue;
  }

  play() {
    game.soundSystem.play(this.configSound);
  }
}

export class SoundAnimationUrlTrigger extends SoundAnimationTrigger {
  url: string | string[];
  volume: number;

  constructor(url: string | string[], volume: number) {
    super("");
    this.url = url;
    this.volume = volume;
  }

  play() {
    game.soundSystem.playUrl(this.url, this.volume);
  }
}
