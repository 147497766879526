import { getParent, types } from "mobx-state-tree";
import { IUserState } from "./UserState";

// tslint:disable-next-line:variable-name
const XPState = types
  .model("XPState", {
    points: types.optional(types.number, 0),
    level: types.optional(types.number, 0), // The smallest level is 1, but defaulting to zero lets us tell setting the level apart from gaining a level
  })
  .views((self) => {

    /** returns how many points needed to earn level */
    function xpForLevel(level: number) {
      /*
        1 0
        2 300
        3-25 750
        26-50 1250
        51-75 2000
        76-100 3000
        101-125 4500
        126-150 7000
      */
      let points = 0;
      for(let n = 2; n <= level; n++) {
        if(n === 2)
          points += 300;
        else if(n >= 3 && n <= 25)
          points += 750;
        else if(n >= 26 && n <= 50)
          points += 1250;
        else if(n >= 51 && n <= 75)
          points += 2000;
        else if(n >= 76 && n <= 100)
          points += 3000;
        else if(n >= 101 && n <= 125)
          points += 4500;
        else if(n >= 126 && n <= 150)
          points += 7000;
      }
      return points;
    }

    return { xpForLevel };
  })
  .actions((self) => {

    function earnXP(points: number) {

      // give 25% xp bonus for having an insta pass
      let userState = getParent(self) as IUserState;
      if(userState.inventory.hasItem("insta_pass"))
        points += points * 0.25;

      points = Math.ceil(points); // round up
      self.points += points;

      // check if they've earned enough points for the next level
      if(self.points >= self.xpForLevel(self.level + 1))
        self.level++;
    }

    return { earnXP };
  });

// IXPState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IXPState = typeof XPState.Type;

export { XPState, IXPState };
