import { Button } from "@babylonjs/gui/2D/controls/button";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";
import { config } from "utils/Config";

import { game } from "components/game/Game";
import { Commands } from "components/ui/Commands";
import { Carousel, CarouselPage } from "components/ui/controls/Carousel";
import { PopUp } from "components/ui/controls/PopUp";
import { IShareObject } from "components/ui/ShareSystem";
import { shuffleArray } from "components/utils/Array";
import { findGuiControl, toast, zIndex } from "components/utils/GUI";

import shareIconUrl from "components/ui/icons/share.png";
import closeIconUrl from "components/ui/modal-dialogs/modal-close.png";

const BG_COLOR = "#01003a";

/** Popup to display share images and allow user to select one to share. */
export class SharePopUp extends PopUp {
  box: Rectangle;
  carousel: Carousel;
  closeButton: Button;
  shareButton: Button;
  customPageHeight: number;
  centerOnClick = false;

  constructor(name: string = "SharePopUp", shareNames?: string[], shuffle = true, appendDefault = true) {
    super(name);

    //this.background = "yellow";
    this.thickness = 0;

    this.box = new Rectangle(name + "Box");
    this.box.background = BG_COLOR;
    this.box.thickness = 0;
    this.addControl(this.box);

    this.carousel = new Carousel(name + "Carousel");
    this.carousel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.carousel.onCarouselClickedObservable.add((eventData) => this.onCarouselClicked(eventData));
    this.box.addControl(this.carousel);

    this.closeButton = Button.CreateImageOnlyButton(name + "CloseButton", closeIconUrl);
    this.closeButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.closeButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.closeButton.thickness = 0;
    this.closeButton.onPointerClickObservable.add(() => this.disposeWithReason("close"));
    this.addControl(this.closeButton);

    this.shareButton = Button.CreateImageButton(name + "ShareButton", "Share", shareIconUrl);
    this.shareButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.shareButton.color = "white";
    this.shareButton.background = "#01003a";
    this.shareButton.thickness = 0;
    this.shareButton.onPointerClickObservable.add(() => this.onShare());
    this.box.addControl(this.shareButton);

    // Changes to button fontHeight aren't taking effect
    // So we need to set the textBlock fontHeight directly
    // Which means we need to set the fontFamily and fontWeight directly as well
    let shareButtonText = findGuiControl(this.shareButton.name + "_button", this.shareButton);
    shareButtonText.fontFamily = config.fontFamily;
    shareButtonText.fontWeight = config.fontWeight;

    // Switch icon to right side and make larger
    let shareButtonImage = findGuiControl(this.shareButton.name + "_icon", this.shareButton);
    shareButtonImage.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    shareButtonImage.width = "30%";
    shareButtonText.paddingLeft = "0px";
    shareButtonText.paddingRight = "30%";

    this.createSharePages(shareNames, shuffle, appendDefault);

    this.layout();
    this.checkButton();

    this.carousel.onPageChangedObservable.add((page) => this.checkButton());
  }

  setCustomPageHeight(pageHeight: number) {
    this.customPageHeight = pageHeight;
    this.layout();
  }

  createSharePages(shareNames: string[], shuffle = true, appendDefault = true) {
    // Make sure we have an array
    if(shareNames === undefined || shareNames === null)
      shareNames = [];
    else
      shareNames = shareNames.slice(); // Duplicate the array or else we'll modify the original

    // shuffle, if requested
    if(shuffle)
      shuffleArray(shareNames);

    // appened the default share name, if requested
    if(appendDefault)
      shareNames.push("default");

    // Create a page for each share name and start creating a share object for the page
    for(let shareName of shareNames) {
      let page = this.addPage({});
      game.shareSystem.makeShareObject(shareName).then((shareObject) => {
        this.updatePage(page, shareObject);
      });
    }
  }

  addPage(shareObject: IShareObject) {
    let page = this.carousel.addPage();
    page.metadata = shareObject;
    let image = new Image(page.name + "Image", shareObject.image);
    image.stretch = Image.STRETCH_UNIFORM;
    page.addControl(image);
    this.checkButton();
    return page;
  }

  updatePage(page: CarouselPage, shareObject: IShareObject) {
    let image = findGuiControl(page.name + "Image", page) as Image;
    if(!image)
      return; // Probably the shareObject finished after the PopUp was closed

    if(shareObject) {
      Object.assign(page.metadata, shareObject);
      image.source = shareObject.image;
    } else {
      // XXX - Display an error on the page?
    }
    this.checkButton();
  }

  layout() {
    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiHeight = game.guiTexture.getSize().height - menuBarHeight;

    let pageHeight = this.customPageHeight || Math.floor(guiHeight * 0.5);
    let pageWidth = Math.floor(pageHeight * game.shareSystem.shareWidth / game.shareSystem.shareHeight);

    let buttonHeight = Math.floor(pageHeight / 5);
    let buttonWidth = buttonHeight * 3.5;
    let buttonFontSize = buttonHeight * 0.8;

    this.carousel.setPageSize(pageWidth, pageHeight);

    let closeButtonWidth = this.carousel.borderWidth * 3;

    let boxWidth = this.carousel.widthInPixels;
    let boxHeight = this.carousel.heightInPixels + buttonHeight;

    let width = boxWidth + this.carousel.borderWidth * 2;
    let height = boxHeight + this.carousel.borderWidth * 2;

    this.width = width + "px";
    this.height = height + "px";

    this.box.width = boxWidth + "px";
    this.box.height = boxHeight + "px";
    this.box.cornerRadius = this.carousel.cornerRadius;

    this.closeButton.width = closeButtonWidth + "px";
    this.closeButton.height = closeButtonWidth + "px";

    this.shareButton.width = buttonWidth + "px";
    this.shareButton.height = buttonHeight + "px";

    let shareButtonText = findGuiControl(this.shareButton.name + "_button", this.shareButton);
    shareButtonText.fontSize = buttonFontSize + "px";
  }

  onCarouselClicked(eventData: Vector2WithInfo) {
    // If requested, clear any custom height and center the PopUp if the carousel is clicked
    if(!this.centerOnClick || !this.customPageHeight)
      return;

    // Re-center the SharePopUp
    this.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    this.left = 0;
    this.top = 0;

    // Remove the custom page height
    this.setCustomPageHeight(0);

    // Re-enable disposeOnMiss, though that may not always be wanted
    this.disposeOnMiss = true;
  }

  onShare() {
    let page = this.carousel.getPage();
    if(!page) {
      Commands.onShare();
      this.dispose();
      return;
    }

    if(!page.metadata.image) {
      toast("SharePopUpToast", "This share image is not ready yet.");
      return;
    }

    Commands.onShare(page.metadata);
    this.dispose();
  }

  checkButton() {
    // Enable or Disable the ShareButton based on whether the current page has generated its imageDataURI
    let page = this.carousel.getPage();
    if(!page)
      return;

    if(page.metadata.image)
      this.shareButton.alpha = 1.0;
    else
      this.shareButton.alpha = 0.5;
  }
}
