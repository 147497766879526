/** PatchQueueState is intended to be composed with other states to add the ability to apply patches and actions in a queue,
 *  then to be able to pause and unpause applying those patches and actions from the queue. The reason to pause is to prevent
 *  the local client state from getting ahead of animations. Basically the gui animates something, pauses the queue, waits for
 *  animations to finish, then unpauses.
 *  Patches are streamed from the server when playing online, and no actions should be queued when online.
 *  When playing offline the inverse is true, no patches should be queued, but actions will.
 */

import { applyAction, applyPatch, IJsonPatch, ISerializedActionCall } from "mobx-state-tree";

import { BaseStateSync } from "states/state-sync/BaseStateSync";
import { logger } from "utils/logger";

// tslint:disable-next-line:variable-name
const PatchQueueState = BaseStateSync
  .props({
  })
  .volatile((self) => ({
  }))
  .views((self) => {
    // Defining the queue here makes it volatile and only visible to functions in this block.
    // Alternatively it could be defined in .volatile above
    // We're also defining them and supporting functions in views instead of actions. Because when they're actions  mobx-state-tree does
    // more work, and they would get reported to onAction. Since they get called every frame I was concerned it could cost performance.
    let patchQueue: IJsonPatch[] = [];
    let actionQueue: ISerializedActionCall[] = [];
    let patchQueuePauseCount = 0;

    function resetQueues() {
      patchQueue = [];
      actionQueue = [];
      patchQueuePauseCount = 0;
    }

    function queueAction(action: ISerializedActionCall) {
      actionQueue.push(action);
    }

    /** process actions in queue. Note it should be called externally, ie Game.ts.startRenderLoop and StateService.applyAction
     * This is to unwind the callstack, and avoid deep recursion. This improves CPU Profiling reports.
     * @param ignorePause - apply actions even if paused
     */
    function applyQueuedActions(ignorePause = false) {
      while(actionQueue.length && (!patchQueuePauseCount || ignorePause)) {
        let action = actionQueue.shift();
        applyAction(self, action);
      }
    }

    function applyQueuedPatches() {
      while(patchQueue.length && !patchQueuePauseCount) {
        let patch = patchQueue.shift();
        applyPatch(self, patch);
      }
    }

    /** This needs to be called regularly in order for patches to be applied */
    function applyAll() {
      applyQueuedActions();
      applyQueuedPatches();
    }

    function queuePatch(patch: IJsonPatch) {
      patchQueue.push(patch);
    }

    /**
     * Callers to pausePatchQueue must keep track of the fact that the paused the queue and be sure to unpause it
     * When the state is reset, the pause count is also reset, meaning callers need to either re-call pause, or clear their flag for calling unpause
     * Currently, the best way to do this is watch for gameState.status to change to GAME_STATE_RESET
     */
    function pausePatchQueue() {
      patchQueuePauseCount += 1;
    }

    function unpausePatchQueue() {
      if(patchQueuePauseCount) {
        patchQueuePauseCount -= 1;
      } else {
        let err = new Error("PatchQueuState.unpausePatchQueue called without being paused");
        if(process.env.NODE_ENV === "development")
          throw err;
        else
          // In production log a warning instead of throwing an exception
          logger.warn("PatchQueuState.unpausePatchQueue called without being paused", { err });
      }
    }

    return { applyQueuedActions, applyQueuedPatches, applyAll, queuePatch, queueAction, pausePatchQueue, unpausePatchQueue, resetQueues };
  })
  .actions((self) => {
    const superResetState = self.resetState;

    /** state is being reset, reset queue */
    function resetState() {
      superResetState();
      self.resetQueues();
    }
    return { resetState };
  });

// PatchQueueState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IPatchQueueState = typeof PatchQueueState.Type;

export { PatchQueueState, IPatchQueueState };
