// Wrapper around the Web Crypto API to encrypt and decrypt string data using the given key data
// Node.js does not support the Web Crypto API (it does have it's own similar api)
import * as base64 from "base64-arraybuffer";

/** Unexported helper to generate a CryptoKey object from keyData by hashing with SHA-256 */
async function deriveKey(keyData: string): Promise<CryptoKey> {
  let enc = new TextEncoder();
  let keyDataBytes = enc.encode(keyData);
  let hashBytes = await window.crypto.subtle.digest("SHA-256", keyDataBytes);
  let algorithm = "AES-GCM";
  let extractable = false;

  return window.crypto.subtle.importKey("raw", hashBytes, algorithm, extractable, [ "encrypt", "decrypt" ]);
}

/** Encrypt the given clearText using AES-GCM
 * AES-GCM is an authenticated encryption algorithm which will fail to decrypt if the encrypted data has been tampered with
 * A limitation of AES-GCM is that a (key, iv) pair shouldn't be reused
 * returns a promsing returning a string "iv:cipherText", where both are base64
 */
export async function encrypt(clearText: string, keyData: string): Promise<string> {
  let algorithm: AesGcmParams = {
    name: "AES-GCM",
    iv: window.crypto.getRandomValues(new Uint8Array(12)),
  };

  let key = await deriveKey(keyData);

  let enc = new TextEncoder();
  let encodedClearText = enc.encode(clearText);

  let cipherTextBytes = await window.crypto.subtle.encrypt(
    algorithm,
    key,
    encodedClearText,
  );

  let ivBase64 = base64.encode(algorithm.iv as ArrayBuffer);
  let cipherTextBase64 = base64.encode(cipherTextBytes);

  return `${ivBase64}:${cipherTextBase64}`;
}

/** Decrypts the given string in the format "iv:cipherText" using AES-GCM
 * AES-GCM is an authenticated encryption algorithm which will fail to decrypt if the encrypted data has been tampered with
 * returns a promise returning the decrypted and decoded clearText string
 */
export async function decrypt(encryptedString: string, keyData: string) {
  let [ivBase64, cipherTextBase64] = encryptedString.split(":");

  let algorithm: AesGcmParams = {
    name: "AES-GCM",
    iv: base64.decode(ivBase64),
  };

  let cipherTextBytes = base64.decode(cipherTextBase64);

  let key = await deriveKey(keyData);

  let clearTextBytes = await window.crypto.subtle.decrypt(
    algorithm,
    key,
    cipherTextBytes,
  );

  let dec = new TextDecoder();
  return dec.decode(clearTextBytes);
}
