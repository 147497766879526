interface IRoute {
  id: number;
  regExp: RegExp;
  func: (patch: any, reversePatch: any, params: any) => void;
}

interface IPatchSet {
  patch: any;
  reversePatch: any;
}

/**
 * JSONPatchRouter is used with a mobx state tree. Feed it patches with:
 * onPatch(self, (patch, reversePatch) => self.router.queuePatch(patch, reversePatch));
 *
 * It routes patch notifications to listeners that match a regular expression
 *
 * It is important that we do not process patches while a snapshot is being applied, because
 * onPatch is called for every item in the state tree as it is being set. Most things will
 * expect the entire state tree to be up to date before being notified. For example many things
 * are triggeed by gameState.status being set, if something responding to that needs to check GameState.options
 * but options haven't been set yet, then it appears broken. So we need to wait for the entire snapshot
 * to be applied before unpausing and sending out patch notifications
 *
 * StateSyncMiddleware currently handles calling pause and unpause
 */
export class JSONPatchRouter {
  routes: IRoute[] = [];
  queue: IPatchSet[] = [];
  paused = false;
  curRouteId = 1;

  addRoute(regExpStr: string, func: any): number {
    let route = {
      id: this.curRouteId++,
      regExp: new RegExp(regExpStr),
      func: func,
    };
    this.routes.push(route);
    return route.id;
  }

  removeRoute(id: number) {
    let index = this.routes.findIndex((route: IRoute) => route.id === id);
    if(index !== -1)
      this.routes.splice(index, 1); // remove route
  }

  pause() {
    this.paused = true;
  }

  unpause() {
    this.paused = false;
    this.applyQueuedPatches();
  }

  applyQueuedPatches() {
    while(this.queue.length && !this.paused) {
      let patchSet = this.queue.shift();
      this.processPatch(patchSet.patch, patchSet.reversePatch);
    }
  }

  queuePatch(patch: any, reversePatch: any) {
    if(this.paused)
      this.queue.push({patch, reversePatch});
    else
      this.processPatch(patch, reversePatch);
  }

  processPatch(patch: any, reversePatch: any) { // what should type of patch be? typeof says it's an Object, but then patch.path says path is not a member.. try IJsonPatch
    //console.dir(JSON.stringify(patch));
    this.routes.forEach((route) => {
      let params = route.regExp.exec(patch.path);
      if(params)
        route.func(patch, reversePatch, params);
    });
  }
}
