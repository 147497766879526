import { GAME_STATE_BID } from "states/game/GameState";
import { IPieceState, SPADES } from "states/game/PieceState";
import { ISeatState } from "states/game/SeatState";
import { TrickGameState } from "states/game/TrickGameState";
import { WonTricks } from "states/game/WonTricks";

import { TrickGameAI } from "ai/TrickGameAI";

// tslint:disable-next-line:variable-name
const SpadesGameState = TrickGameState
  // When adding more properties be sure to review if they need to be copied in applySyncToServerSnapshot
  .actions((self) => {
    const superCreateState = self.createState;
    const superResetRound = self.resetRound;
    const superStartRound = self.startRound;

    function afterCreate() {
      self.trumpSuit = SPADES;
    }

    function createState(snapshot: any): Promise<boolean> {
      let promise = superCreateState(snapshot);

      // if minPiles is set then TrickGameState already loaded them and we just need to advance the state to GAME_STATE_BID
      // and set the first players turn. minPiles is used for playing handChallenges stand alone
      if(snapshot.minPiles) {
        self.setStatus(GAME_STATE_BID);
        self.setSeatsTurn(self.getNextSeat(self.dealer));
      }
      return promise;
    }

    function scoreTrick(seatWon: ISeatState) {
      seatWon.incTricksWon();
      //console.log("TrickWon Seat:", seatWon.id, "bid:", seatWon.bid, "won:", seatWon.tricksWon);
    }

    function startRound() {
      superStartRound();

      self.setStatus(GAME_STATE_BID);

      // Player to the left of the dealer bids
      // Setting this triggers the AI to play if it's their turn
      self.setSeatsTurn(self.getNextSeat(self.dealer));
    }

    function scoreRound() {
      self.teams.forEach((team) => {

        // The Rules According to Pagat (bicycle.com has some very strange rules, Wikipedia is close to pagat, but doesn't specify what happens to nil overtricks)
        // ============================
        // If bid is made, 10 points per bid + 1 point for each bag
        // If bid is not made, -10 points per bid
        // 10 bags is a -100 penalty (It's possible to get 2 bag penalties in one hand, 9 bags + 11 tricks, for example)
        // A Nil made is 100 points, failed then -100 points.  Tricks won on a nil count as bags, but do not count toward the partner's bid
        //
        // This logic is duplicated in getWonTricksForSeat, below

        let teamScore = 0;
        let teamBags = 0;
        let teamBid = team.getBid();
        let teamTricksWon = 0;
        let individualBids = false;
        let teamMadeBid = false;

        // check for nils for 100 points
        team.seats.forEach((seatId) => {
          let seat = self.getSeat(seatId);
          if(seat.bid === 0) {
            // Individual Bid
            individualBids = true;
            if(seat.tricksWon === 0)
            {
              teamScore += 100;
            } else {
              // Failed NIL overtricks do not count towards the partner's bid, but do count as bags
              teamScore -= 100;
              teamBags += seat.tricksWon;
            }
          } else {
            // Tricks contribute to the team bid
            teamTricksWon += seat.tricksWon;
          }
        });

        // score bid
        if(teamTricksWon >= teamBid) {
          teamMadeBid = true;
          teamBags += teamTricksWon - teamBid;
          teamScore += teamBid * 10;
        } else {
          teamScore -= teamBid * 10;
        }

        teamScore += teamBags;

        // check for 10 bag penalty
        // It's unlikely, but possible to get 2 bag penalties
        team.setBags(team.bags + teamBags);
        while(team.bags >= 10) {
          teamScore -= 100;
          team.setBags(team.bags - 10);
        }

        team.setScore(team.score + teamScore);
        //console.log("Team", team.id, "bid", teamBid, "tricksWon", teamTricksWon, "scored", teamScore, "total score", team.score);

        // Set seat roundScore
        // We don't count bag penalties here, just compute the score as if it was an individual bid
        for(let seatId of team.seats) {
          let seat = self.getSeat(seatId);
          if(individualBids) {
            if(seat.bid === 0 && seat.tricksWon === 0)
              seat.setRoundScore(100);
            else if(seat.bid === 0)
              seat.setRoundScore(-100 + seat.tricksWon);
            else if(seat.tricksWon > seat.bid)
              seat.setRoundScore(seat.bid * 10 + seat.tricksWon - seat.bid);
            else
              seat.setRoundScore(seat.tricksWon * 10);
          } else if(teamMadeBid) {
            if(seat.tricksWon > seat.bid)
              seat.setRoundScore(seat.bid * 10 + seat.tricksWon - seat.bid);
            else
              seat.setRoundScore(seat.tricksWon * 10);
          } else {
            seat.setRoundScore(seat.bid * -10);
          }
        }
      });
    }

    function resetRound() {
      superResetRound();

      // reset bid, bags, and tricksWon count for each seat
      self.seats.forEach((seat) => {
        seat.bid = null; // set to null to indicate they haven't bid yet
        seat.tricksWon = 0;
      });
    }

    /** Seat turn timed out, so make a play for them */
    function forcePlay(seatId: string) {

      // create an ai for the seat that will play one move
      let ai = new TrickGameAI(seatId, self, TrickGameAI.PLAY_ONCE);
    }

    return { afterCreate, createState, forcePlay, scoreTrick, startRound, scoreRound, resetRound };
  })
  .views((self) => {

    function isGameOver(): boolean {
      let endScore = self.options.endScore;
      // game is over if any team has over endScore points, or less then -endScore
      if(endScore && (self.teams.find((team) => team.score >= endScore || team.score <= -endScore)))
        return true;

      // check if this game is limited to a max number of rounds
      if(self.options.maxRounds && self.round >= self.options.maxRounds)
        return true;

      return false;
    }

    /** Return a detailed list describing each won trick in the round for the SpadesRoundSummary, also used by PlayerSystem */
    function getWonTricksForSeat(seatOrId: ISeatState | string, summaryMode = false): WonTricks {
      let team = self.getTeamForSeat(seatOrId);
      let seat = self.getSeat(seatOrId);
      let partnerSeat = team.getPartnerSeat(seatOrId);

      // Has bidding taken place?
      if(seat.bid === null || partnerSeat.bid === null)
        return new WonTricks();

      let bid = seat.bid;
      let won = seat.tricksWon;
      let partnerBid = partnerSeat.bid;
      let partnerWon = partnerSeat.tricksWon;
      let made = false;

      // bid made?
      if(bid > 0) {
        if(partnerBid > 0)
          made = (won + partnerWon >= bid + partnerBid);
        else
          made = won >= bid;
      }

      // Get starting bags by working backwards from current bags
      let bags = team.bags;
      if(bid === 0) {
        bags -= won;
        if(partnerWon > partnerBid)
          bags -= partnerWon - partnerBid;
      } else if(partnerBid === 0) {
        bags -= partnerWon;
        if(won > bid)
          bags -= won - bid;
      } else {
        let teamBid = bid + partnerBid;
        let teamWon = won + partnerWon;
        if(teamWon > teamBid)
          bags -= teamWon - teamBid;
      }
      while(bags < 0)
        bags += 10;

      // Get list of tricks
      let tricks = self.tricks;

      // Search tricks to find won tricks, partner's won tricks and bags
      let searchWon = 0;
      let searchPartnerWon = 0;
      let wonTricks = new WonTricks(made, bags);

      for(let trick of tricks) {
        // If the trick isn't finished, there won't be a winner yet
        if(trick.seatWinner === null)
          continue;

        if(trick.seatWinner.id === seat.id) {
          // Our trick
          if(bid === 0) {
            // We bid NIL, everything is a bag
            // Except the first is a fail
            if(wonTricks.length === 0)
              wonTricks.nilFailed();
            else
              wonTricks.bag();
          } else if(searchWon >= bid) {
            // Our bid is made, check partner
            if(partnerBid > 0 && searchPartnerWon < partnerBid) {
              // Give to partner
              searchPartnerWon += 1;
            } else {
              // Bag
              searchWon += 1;
              wonTricks.bag();
            }
          } else {
            // Our bid is not yet made, so take it
            searchWon += 1;
            wonTricks.won();
          }
        } else if(trick.seatWinner.id === partnerSeat.id) {
          // Partner's trick
          if(partnerBid === 0) {
            // Partner failed NIL, everything is a bag
            searchPartnerWon += 1;
            wonTricks.partnerBag();
          } else if(searchPartnerWon >= partnerBid) {
            // Partner's bid is made, see if we need it
            if(bid > 0 && searchWon < bid) {
              // Take it
              searchWon += 1;
              wonTricks.partnerWon();
            } else {
              // Bag
              searchPartnerWon += 1;
              wonTricks.partnerBag();
            }
          } else {
            // Partner's bid is not yet made
            searchPartnerWon += 1;
          }
        }
      }

      // Points adjustments for points not associated with a won trick
      if(bid === 0) {
        // Check for a successful nil
        if(won === 0)
          wonTricks.nil(summaryMode);
      } else if(searchWon < bid) {
        // We failed to make our bid
        for(let i = searchWon; i < bid; i++)
          wonTricks.miss(summaryMode);
      }

      return wonTricks;
    }

    /** Checks if piece is a protected card, meaning it can't be played until that suit is broken. */
    function isProtectedPiece(piece: IPieceState) {
      // overrides base class
      return piece.suit() === SPADES;
    }

    return { isGameOver, getWonTricksForSeat, isProtectedPiece };
  });

// SpadesGameState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type ISpadesGameState = typeof SpadesGameState.Type;

export { SpadesGameState, ISpadesGameState };
