import { Game } from "components/game/Game";
import { IRootState } from "states/RootState";

export interface IArgoSystemConfig {
  [index: string]: any;
}

/**
 * ArgoSystem
 * Base class defining interface for a system in Argo
 */
export class ArgoSystem {
    game: Game;
    rootState: IRootState;
    systemConfig: IArgoSystemConfig;

    constructor(game: Game, systemConfig?: IArgoSystemConfig) {
        this.game = game;
        this.rootState = this.game.rootState;
        this.systemConfig = systemConfig;

        // If there's no systemConfig provided, use an empty object so ArgoSystems don't need to check
        if(this.systemConfig === undefined || this.systemConfig === null)
          this.systemConfig = {};
    }

    /**
     * Initize the system
     * Called very early, before Babylon is initialized
     */
    init(): void {
    }

    /**
     * Queue assets using game.assetsManager
     */
    queueAssets(): void {
    }

    /**
     * Create any materials needed
     */
    createMaterials(): void {
    }

    /**
     * Create 3d Scene Elements
     * Called after cratePiles
     */
    createSceneElements(): void {
    }

    /**
     * create GUI controls
     */
    createGUI(): void {
    }

    /**
     * The canvas has been resized
     * Called onEngineBeginFrame
     */
    resized(): void {
    }

    /**
     * The canvas has been resized
     * Called after resized and fitInWindow
     * So the 3d objects should be in their final positions
     */
    afterResized(): void {
    }

    /**
     * A new frame has begin and the previous frame has completed processing
     */
    newFrame(deltaTime: number): void {
    }
}
