export const STATE_NOT_FOUND_ERROR = "StateNotFoundError";
export const STATE_RESET_ERROR = "StateResetError";
export const PERMISSION_DENIED_ERROR = "PermissionDeniedError";
export const CLIENT_VERSION_ERROR = "ClientVersionError";
export const BAD_REQUEST_ERROR = "BadRequestError";

export class StateNotFoundError extends Error {
  constructor(m: string = "State Not Found") {
      super(m);

      // Set the prototype explicitly.
      Object.setPrototypeOf(this, StateNotFoundError.prototype);
      this.name = STATE_NOT_FOUND_ERROR;
  }
}

export class StateResetError extends Error {
  constructor(m: string = "State Reset") {
      super(m);

      // Set the prototype explicitly.
      Object.setPrototypeOf(this, StateResetError.prototype);
      this.name = STATE_RESET_ERROR;
  }
}

export class PermissionDeniedError extends Error {
  constructor(m: string = "Permission Denied") {
      super(m);

      // Set the prototype explicitly.
      Object.setPrototypeOf(this, PermissionDeniedError.prototype);
      this.name = PERMISSION_DENIED_ERROR;
  }
}

export class ClientVersionError extends Error {
  constructor(m: string = "Client is out of date.") {
      super(m);

      // Set the prototype explicitly.
      Object.setPrototypeOf(this, ClientVersionError.prototype);
      this.name = CLIENT_VERSION_ERROR;
  }
}

export class BadRequestError extends Error {
  constructor(m: string = "Bad Request.") {
      super(m);

      // Set the prototype explicitly.
      Object.setPrototypeOf(this, BadRequestError.prototype);
      this.name = BAD_REQUEST_ERROR;
  }
}
