import { MultiMaterial } from "@babylonjs/core/Materials/multiMaterial";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { ColorGradingTexture } from "@babylonjs/core/Materials/Textures/colorGradingTexture";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Color4, Matrix, Quaternion, Vector3, Vector4 } from "@babylonjs/core/Maths/math";
import { Scalar } from "@babylonjs/core/Maths/math.scalar";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { VertexBuffer } from "@babylonjs/core/Meshes/buffer";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { VertexData } from "@babylonjs/core/Meshes/mesh.vertexData";
import { Node } from "@babylonjs/core/node";
import { Particle } from "@babylonjs/core/Particles/particle";
import { ParticleSystem } from "@babylonjs/core/Particles/particleSystem";

import { ArgoSystem } from "components/game/ArgoSystem";
import { BaseCardMesh } from "components/game/BaseCardMesh";
import { FACING_DOWN, FACING_UP, IPieceState } from "states/game/PieceState";

import { config } from "utils/Config";

const useCardModel = true;

const BACK_INDEX = 76;
const BLANK_INDEX = 76; // this is the back, use 79 for the true blank

// XXX - Cheating with pre-calculated edge face center points - this should be extractable from the card mesh geometry
// length: 242
let cardModelFaceCenters = [
  [-0.464, 0.634, -0.014], [-0.494, 0.597, -0.014], [-0.499, 0.497, -0.011], [-0.498, -0.583, -0.013], [-0.471, -0.628, -0.014], [-0.358, -0.643, -0.012], [0.439, -0.642, -0.014], [0.482, -0.619, -0.014], [0.499, -0.497, -0.011], [0.498, 0.584, -0.013], [0.472, 0.629, -0.014], [0.358, 0.643, -0.012], [-0.434, 0.643, -0.014], [-0.036, 0.643, -0.008], [0.036, -0.643, -0.008], [0.251, -0.643, -0.010], [-0.251, 0.643, -0.010], [0.143, 0.643, -0.009], [-0.143, -0.643, -0.009], [-0.251, -0.643, -0.010],
  [0.251, 0.643, -0.010], [-0.143, 0.643, -0.009], [0.143, -0.643, -0.009], [0.358, -0.643, -0.012], [-0.358, 0.643, -0.012], [0.036, 0.643, -0.008], [-0.036, -0.643, -0.008], [-0.499, 0.383, -0.007], [-0.499, 0.268, -0.005], [-0.499, 0.153, -0.004], [-0.499, 0.038, -0.003], [-0.499, -0.153, -0.004], [-0.499, -0.268, -0.005], [-0.499, -0.383, -0.007], [-0.499, -0.497, -0.011], [0.499, -0.383, -0.007], [0.499, -0.268, -0.005], [0.499, -0.153, -0.004], [0.499, -0.038, -0.003], [0.499, 0.153, -0.004],
  [0.499, 0.268, -0.005], [0.499, 0.383, -0.007], [0.499, 0.497, -0.011], [-0.439, 0.643, -0.005], [0.435, 0.644, -0.008], [0.461, 0.637, -0.009], [0.492, 0.606, -0.008], [0.499, 0.536, -0.003], [0.499, -0.579, -0.008], [0.492, -0.605, -0.008], [0.461, -0.636, -0.009], [0.394, -0.643, -0.004], [-0.435, -0.643, -0.008], [-0.460, -0.636, -0.009], [-0.491, -0.605, -0.008], [-0.499, -0.536, -0.003], [-0.499, 0.579, -0.008], [-0.491, 0.606, -0.008], [-0.460, 0.637, -0.009], [0.394, 0.643, -0.004],
  [0.499, -0.536, -0.003], [-0.394, -0.643, -0.004], [-0.499, 0.536, -0.003], [-0.500, -0.536, -0.009], [0.394, -0.644, -0.010], [0.500, 0.536, -0.009], [-0.394, 0.644, -0.010], [0.499, 0.383, 0.002], [0.499, 0.268, 0.004], [0.499, 0.153, 0.006], [0.499, 0.038, 0.006], [0.499, -0.153, 0.006], [0.499, -0.268, 0.004], [0.499, -0.383, 0.002], [-0.499, -0.383, 0.002], [-0.499, -0.268, 0.004], [-0.499, -0.153, 0.006], [-0.499, -0.038, 0.006], [-0.499, 0.153, 0.006], [-0.499, 0.268, 0.004],
  [-0.499, 0.383, 0.002], [-0.143, 0.643, 0.001], [0.036, 0.643, 0.001], [0.251, 0.643, -0.001], [0.143, -0.643, 0.001], [-0.036, -0.643, 0.001], [-0.251, -0.643, -0.001], [-0.251, 0.643, -0.001], [0.251, -0.643, -0.001], [-0.036, 0.643, 0.001], [0.143, 0.643, 0.001], [0.036, -0.643, 0.001], [-0.143, -0.643, 0.001], [0.251, -0.644, -0.004], [0.036, -0.644, -0.002], [-0.179, -0.644, -0.006], [-0.036, -0.644, -0.002], [0.179, -0.644, -0.006], [-0.500, -0.383, -0.002], [-0.500, -0.268, 0.001],
  [-0.500, -0.153, 0.002], [-0.500, -0.038, 0.003], [-0.500, 0.153, 0.002], [-0.500, 0.268, 0.001], [-0.500, 0.383, -0.002], [0.036, 0.644, -0.002], [-0.251, 0.644, -0.004], [-0.143, 0.644, -0.003], [0.287, 0.644, -0.007], [0.179, 0.644, -0.006], [0.500, 0.383, -0.002], [0.500, 0.268, 0.001], [0.500, 0.153, 0.002], [0.500, 0.038, 0.003], [0.500, -0.153, 0.002], [0.500, -0.268, 0.001], [0.500, -0.383, -0.002], [-0.460, 0.636, -0.014], [-0.496, 0.593, -0.013], [-0.499, -0.579, -0.013],
  [-0.475, -0.625, -0.014], [-0.394, -0.643, -0.012], [0.435, -0.643, -0.013], [0.485, -0.616, -0.014], [0.499, -0.536, -0.011], [0.499, 0.579, -0.013], [0.476, 0.626, -0.014], [0.394, 0.643, -0.012], [-0.439, 0.643, -0.013], [-0.072, 0.643, -0.008], [0.072, -0.643, -0.008], [0.287, -0.643, -0.010], [-0.287, 0.643, -0.010], [0.179, 0.643, -0.009], [-0.179, -0.643, -0.009], [-0.287, -0.643, -0.010], [0.287, 0.643, -0.010], [-0.179, 0.643, -0.009], [0.179, -0.643, -0.009], [0.394, -0.643, -0.012],
  [-0.394, 0.643, -0.012], [0.072, 0.643, -0.008], [-0.072, -0.643, -0.008], [-0.499, 0.421, -0.008], [-0.499, 0.306, -0.005], [-0.499, 0.191, -0.004], [-0.499, 0.077, -0.003], [-0.499, -0.077, -0.003], [-0.499, -0.191, -0.004], [-0.499, -0.306, -0.005], [-0.499, -0.421, -0.008], [-0.499, -0.536, -0.011], [0.499, -0.421, -0.008], [0.499, -0.306, -0.005], [0.499, -0.191, -0.004], [0.499, -0.077, -0.003], [0.499, 0.077, -0.003], [0.499, 0.191, -0.004], [0.499, 0.306, -0.005], [0.499, 0.421, -0.008],
  [0.499, 0.536, -0.011], [-0.434, 0.642, -0.005], [0.440, 0.643, -0.011], [0.453, 0.640, -0.011], [0.482, 0.621, -0.011], [0.499, 0.584, -0.010], [0.499, -0.583, -0.010], [0.496, -0.596, -0.010], [0.473, -0.628, -0.011], [0.440, -0.643, -0.011], [-0.439, -0.643, -0.011], [-0.452, -0.639, -0.011], [-0.481, -0.619, -0.011], [-0.499, -0.583, -0.010], [-0.499, 0.584, -0.010], [-0.495, 0.597, -0.010], [-0.472, 0.630, -0.011], [-0.439, 0.643, -0.011], [0.358, 0.643, -0.003], [0.499, -0.497, -0.001],
  [-0.358, -0.643, -0.003], [-0.499, 0.497, -0.001], [-0.500, -0.498, -0.005], [0.358, -0.644, -0.006], [0.500, 0.498, -0.005], [-0.358, 0.644, -0.006], [0.499, 0.421, 0.000], [0.499, 0.306, 0.003], [0.499, 0.191, 0.005], [0.499, 0.077, 0.006], [0.499, -0.077, 0.006], [0.499, -0.191, 0.005], [0.499, -0.306, 0.003], [0.499, -0.421, 0.000], [-0.499, -0.421, 0.000], [-0.499, -0.306, 0.003], [-0.499, -0.191, 0.005], [-0.499, -0.077, 0.006], [-0.499, 0.077, 0.006], [-0.499, 0.191, 0.005],
  [-0.499, 0.306, 0.003], [-0.499, 0.421, 0.000], [-0.179, 0.643, -0.000], [0.072, 0.643, 0.000], [0.287, 0.643, -0.002], [0.179, -0.643, -0.000], [-0.072, -0.643, 0.000], [-0.287, -0.643, -0.002], [-0.287, 0.643, -0.002], [0.287, -0.643, -0.002], [-0.072, 0.643, 0.000], [0.179, 0.643, -0.000], [0.072, -0.643, 0.000], [-0.179, -0.643, -0.000], [0.287, -0.644, -0.007], [0.072, -0.644, -0.005], [-0.143, -0.644, -0.003], [-0.287, -0.644, -0.007], [-0.072, -0.644, -0.005], [0.143, -0.644, -0.003],
  [-0.500, -0.421, -0.005], [-0.500, -0.306, -0.003], [-0.500, -0.191, -0.001], [-0.500, -0.077, 0.000], [-0.500, 0.077, 0.000], [-0.500, 0.191, -0.001], [-0.500, 0.306, -0.003], [-0.500, 0.421, -0.005], [0.072, 0.644, -0.005], [-0.072, 0.644, -0.005], [-0.287, 0.644, -0.007], [-0.179, 0.644, -0.006], [0.251, 0.644, -0.004], [0.143, 0.644, -0.003], [0.500, 0.421, -0.005], [0.500, 0.306, -0.003], [0.500, 0.191, -0.001], [0.500, 0.077, 0.000], [0.500, -0.077, 0.000], [0.500, -0.191, -0.001],
  [0.500, -0.306, -0.003], [0.500, -0.421, -0.005],
  ];

// resources
import inactiveColorGradingTexturePath from "components/game/decks/inactive.3dl";
import { Argo3dParticleSystem } from "./ArgoParticleSystem";

export class Card extends ArgoSystem {
  deckTexture: Texture = null;
  inactiveColorGradingTexture: ColorGradingTexture = null;
  sparkleEdgeParticleSystems: ParticleSystem[] = [null, null, null, null];

  init() {
  }

  queueAssets(): void {
    // add a mesh task to load our 3d card model
    let meshTask = this.game.assetsManager.addMeshTask("CardTask", "", "./meshes/", "card_no_texture.babylon");
    meshTask.onSuccess = (task) => {
      // Get the loaded card mesh
      let card = task.loadedMeshes[0] as Mesh;

      // Save it
      BaseCardMesh.baseCardMesh = card;

      // Hide it
      card.setEnabled(false);
    };

    // Add a texture task to load the deck texture
    let textureTask = this.game.assetsManager.addTextureTask("CardTextureTask", config.defaultDeckUrl);
    textureTask.onSuccess = (task) => {
      this.deckTexture = task.texture;
    };

    // There doesn't appear to be any way to load a ColorGradingTexture with AssetsManager
    // ColorGradingTexture doesn't work with data url's
    if(!config.loadTestClientMode) { // the following will get an error if this.game._engine is a NullEngine, not sure how to test for that
      this.inactiveColorGradingTexture = new ColorGradingTexture(inactiveColorGradingTexturePath, this.game.scene);
      BaseCardMesh.inactiveColorGradingTexture = this.inactiveColorGradingTexture;
    }
  }

  createMaterials() {
    // create card and pile materials
    let cardMaterial = new StandardMaterial("card", this.game.scene);
    cardMaterial.diffuseTexture = this.deckTexture;

    // NOTE: We can't set cameraColorGradingTexture here because Babylon JS will load and parse the .3dl file for each card
    //       To prevent that, we set the shared inactiveColorGradingTexture after cloning the material in createMesh

    // Create the back material
    let backMaterial = cardMaterial.clone("cardBack");

    // Set back uv offsets
    let cardIndex = BACK_INDEX + config.defaultDeckBack;
    let tex = backMaterial.getActiveTextures()[0] as Texture;
    if(tex) {
      tex.uScale = 400.0 / 4096.0;
      tex.vScale = 512.0 / 4096.0;
      tex.uOffset = (cardIndex % 10) * tex.uScale;
      tex.vOffset = (7 - Math.floor(cardIndex / 10)) * tex.vScale;
    }

    // Get the loaded base card mesh
    let baseCardMesh = this.game.scene.getMeshByID("Card");

    // Set the front and back shader materials as the front and back multimaterial entries
    let cardMultiMaterial = baseCardMesh.material as MultiMaterial;
    cardMultiMaterial.subMaterials[0] = cardMaterial;
    cardMultiMaterial.subMaterials[1] = backMaterial;
  }

  createMesh(parent: Node, pieceState: IPieceState): AbstractMesh {
    let mesh: AbstractMesh = null;

    if (useCardModel) {
      mesh = new BaseCardMesh(pieceState.name, parent);
    }
    else {
      mesh = new Mesh(pieceState.name, parent.getScene(), parent);

      // Initialize the rotationQuaternion since it defaults to uninitialized
      mesh.rotationQuaternion = new Quaternion();

      let cardIndex = BACK_INDEX;
      let uScale = 400.0 / 4096.0;
      let vScale = 512.0 / 4096.0;
      let uOffset = (cardIndex % 10) * uScale;
      let vOffset = (7 - Math.floor(cardIndex / 10)) * vScale;

      let front_uvs = new Vector4(uOffset + uScale, vOffset, uOffset, vOffset + vScale);  // back, real front uvs are set in updateValue
      let back_uvs = new Vector4(uOffset + uScale, vOffset, uOffset, vOffset + vScale);  // back

      // create plane
      let options = { width: 1, height: 512.0 / 400.0, sideOrientation: Mesh.DOUBLESIDE, frontUVs: front_uvs, backUVs: back_uvs, updatable: false };
      let vertexData = VertexData.CreatePlane(options);
      vertexData.applyToMesh(mesh as Mesh, options.updatable);

      // find card material in scene
      mesh.material = parent.getScene().materials.filter((material) => material.name === "card")[0]; // materials is an array but doesn't have the find function? used filter instead
    }

    mesh.metadata = {
      type: "Card",
      facing: FACING_UP,
      value: 0,
      seatId: "",
      active: true,
      selected: false,
      highlighted: false,
      fromParentName: "",
      layoutGameStatus: "",
      layoutIndex: 0,
      layoutPosition: new Vector3(0, 0, 0),
      layoutRotationQuaternion: new Quaternion(),
      layoutVisibility: 1,
      randomX: 0,
      randomY: 0,
      randomA: 0,
    };

    this.genRandom(mesh);

    // set cards to both receive shadows and cast shadows
    mesh.receiveShadows = true;
    this.game.shadowGenerator.addShadowCaster(mesh);

    this.updateState(mesh, pieceState);

    return mesh;
  }

  /** Create a clone of the card mesh without setting it up as a game piece */
  createBlankMesh(parent: Node, name: string): AbstractMesh {
    return new BaseCardMesh(name, parent);
  }

  /** updates the card front texture to display the card of the value in the state  */
  updateValue(card: AbstractMesh, pieceValue: number) {
    card.metadata.value = pieceValue;

    let cardIndex = pieceValue - 1;
    if(cardIndex < 0 || cardIndex > 79 || !this.isFaceUp(card))
      cardIndex = BLANK_INDEX;

    if (useCardModel) {
      let meshMaterial = card.material as MultiMaterial;
      let tex = meshMaterial.subMaterials[0].getActiveTextures()[0] as Texture;
      if(tex) {
        tex.uScale = 400.0 / 4096.0;
        tex.vScale = 512.0 / 4096.0;
        tex.uOffset = (cardIndex % 10) * tex.uScale;
        tex.vOffset = (7 - Math.floor(cardIndex / 10)) * tex.vScale;
      }
    }
    else {
      // todo calculate these values from texture
      let textureWidth = 1024;
      let textureHeight = 1024;
      let cardWidth = 100;
      let cardHeight = 128;

      // calculate cards row and col in texture, there are 10 cards per row
      let col = cardIndex % 10;
      let row = Math.floor(cardIndex / 10);

      // calculate uv coordinates from row and col, 0,0 is the bottom left corner, not the top left, so card_y1 and card_y2 compensate for that by subtracting from textureHeight, and are flipped
      let card_x1 = (cardWidth * col) / textureWidth;
      let card_y1 = textureHeight - ((cardHeight * (row + 1)) / textureHeight);
      let card_x2 = (cardWidth * (col + 1)) / textureWidth;
      let card_y2 = textureHeight - ((cardHeight * row) / textureHeight);

      // update uv coordinates to new location in texture
      let uvs = card.getVerticesData(VertexBuffer.UVKind);
      uvs[0] = card_x1;
      uvs[1] = card_y1;
      uvs[2] = card_x2;
      uvs[3] = card_y1;
      uvs[4] = card_x2;
      uvs[5] = card_y2;
      uvs[6] = card_x1;
      uvs[7] = card_y2;
      card.setVerticesData(VertexBuffer.UVKind, uvs);
    }
  }

  setCustomFaceTexture(card: AbstractMesh, texture: Texture) {
    (card as BaseCardMesh).setCustomFaceTexture(texture);
  }

  isActive(card: AbstractMesh) {
    return card.metadata.active;
  }

  setActive(card: AbstractMesh, active: boolean) {
    if (card.metadata.active === active)
      return;

    card.metadata.active = active;

    let meshMaterial = card.material as MultiMaterial;
    let mat = meshMaterial.subMaterials[0] as StandardMaterial;

    mat.cameraColorGradingEnabled = !active;
  }

  isSelected(card: AbstractMesh) {
    return card.metadata.selected;
  }

  setSelected(card: AbstractMesh, selected: boolean) {
    if (card.metadata.selected === selected)
      return;

    card.metadata.selected = selected;
  }

  toggleSelected(card: AbstractMesh) {
    card.metadata.selected = !card.metadata.selected;
  }

  isHighlighted(card: AbstractMesh) {
    return card.metadata.highlighted;
  }

  setHighlighted(card: AbstractMesh, highlighted: boolean, color?: Color4) {
    if(highlighted) {
      card.metadata.highlighted = true;
      card.enableEdgesRendering();
      card.edgesWidth = 4.0;
      card.edgesColor = color ? color : new Color4(1, 0, 1, 1);
    }
    else {
      card.metadata.highlighted = false;
      card.disableEdgesRendering();
    }
  }

  /** updates the mesh with values from pieceState,
   * @param pieceState is an IPieceState which can be an actual instance of PieceState, or a json snapshot of one
   */
  updateState(card: AbstractMesh, pieceState: IPieceState) {
    if(pieceState.seat) {
      if(typeof pieceState.seat === "string")
        card.metadata.seatId = pieceState.seat;
      else if(typeof pieceState.seat)
        card.metadata.seatId = pieceState.seat.id;
    }

    let facing = pieceState.facing;

    if (facing === FACING_UP && !this.isFaceUp(card))
      this.setFaceUp(card);
    else if (facing === FACING_DOWN && this.isFaceUp(card))
      this.setFaceDown(card);

    if(pieceState.selected !== this.isSelected(card))
      this.setSelected(card, pieceState.selected);

    // todo check to see if the value actually changed
    this.updateValue(card, pieceState.value);

    // be sure card is enabled and visible, added for BabylonJS 4.2
    card.setEnabled(true);
  }

  isFaceUp(card: AbstractMesh) {
    return card.metadata.facing === FACING_UP;
  }

  setFaceUp(card: AbstractMesh) {
    card.metadata.facing = FACING_UP;
    this.updateValue(card, card.metadata.value);
  }

  setFaceDown(card: AbstractMesh) {
    card.metadata.facing = FACING_DOWN;
    this.updateValue(card, card.metadata.value);
  }

  genRandom(card: AbstractMesh) {
    // generate new random values from about -1 to +1
    card.metadata.randomX = Math.random() * 2 - 1;
    card.metadata.randomY = Math.random() * 2 - 1;
    card.metadata.randomA = Math.random() * 2 - 1;
  }

  // Change the parent of the card without moving it in world space
  reparent(card: AbstractMesh, newParent: AbstractMesh) {
    // If we pre-played the card to hide a network stall, it will already have been reparented
    // We could go ahead and re-re-parent it, but that will cut off any ongoing animation
    if(card.parent === newParent)
      return;

    // If this card is being animated, finish the animation before reparenting
    if (card.animations.length) {
      card.getScene().stopAnimation(card);
      this.applyLayout(card);
    }

    // Save the previous parent name
    card.metadata.fromParentName = card.parent.name;

    // re-parent the card
    // calling setParent instead of just card.parent = newParent, preserves the position and rotation
    card.setParent(newParent);

    // Generate new random values for jittering
    this.genRandom(card);
  }

  applyLayout(card: AbstractMesh) {
    card.metadata.fromParentName = "";
    card.position = card.metadata.layoutPosition;
    card.rotationQuaternion = card.metadata.layoutRotationQuaternion;
    card.visibility = card.metadata.layoutVisibility;
  }

  reparentAllAndResetPosition(newParent: AbstractMesh) {
    if(!newParent)
      return;

    let cards = this.game.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Card");
    for (let card of cards) {
      this.reparent(card, newParent);
      card.position = new Vector3(0, 0, 0);
      card.rotationQuaternion = new Quaternion(0, 0, 0, 1);
    }
  }

  resetAll() {
    // We can rely on updateState getting called for things like facing
    // Here we need to handle anything not tied to the game state
    this.game.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Card").forEach((mesh) => {
      // Set all cards active
      this.setActive(mesh, true);

      // Deselect all cards
      this.setSelected(mesh, false);

      // Unhighlight all cards
      this.setHighlighted(mesh, false);

      // Set Face Down
      this.setFaceDown(mesh);

      // Generate new random values for jittering
      this.genRandom(mesh);
    });
  }

  refreshAllState() {
    this.game.scene.meshes.filter((mesh) => mesh.metadata && mesh.metadata.type === "Card").forEach((mesh) => {
      let pieceState = this.game.gameState.findPiece(mesh.name);
      if (pieceState)
        this.updateState(mesh, pieceState);
    });
  }

  sparkleEdge(card: AbstractMesh) {
    // Clean up any previous system
    let oldParticleSystem = this.sparkleEdgeParticleSystems.shift();
    if(oldParticleSystem)
      oldParticleSystem.dispose();

    let particleSystem = new Argo3dParticleSystem("particles", 2000, this.game.scene);
    this.sparkleEdgeParticleSystems.push(particleSystem);

    particleSystem.emitter = card;
    particleSystem.layerMask = card.layerMask;

    particleSystem.minSize = 0.2;
    particleSystem.maxSize = 0.2;

    let emitBoxSize = 0.025;
    particleSystem.minEmitBox = new Vector3(-emitBoxSize, 0, -emitBoxSize);
    particleSystem.maxEmitBox = new Vector3(emitBoxSize, 0, emitBoxSize);

    particleSystem.minEmitPower = 1;
    particleSystem.maxEmitPower = 3;

    particleSystem.minLifeTime = 0.1;
    particleSystem.maxLifeTime = 0.4;

    //particleSystem.gravity = new Vector3(0, 2, 0);

    let index = 0;

    // tslint:disable-next-line:only-arrow-functions
    particleSystem.startPositionFunction = function(worldMatrix: Matrix, positionToUpdate: Vector3, particle: Particle) {
      let p = cardModelFaceCenters[index];
      index += 1;
      let randX = p[0] + Scalar.RandomRange(this.minEmitBox.x, this.maxEmitBox.x);
      let randY = p[1] + Scalar.RandomRange(this.minEmitBox.y, this.maxEmitBox.y);
      let randZ = p[2] + Scalar.RandomRange(this.minEmitBox.z, this.maxEmitBox.z);
      Vector3.TransformCoordinatesFromFloatsToRef(randX, randY, randZ, worldMatrix, positionToUpdate);
    };

    let dir = new Vector3();
    let zero = Vector3.Zero();
    // tslint:disable-next-line:only-arrow-functions
    particleSystem.startDirectionFunction = function(worldMatrix: Matrix, directionToUpdate: Vector3, particle: Particle) {
      Vector3.TransformCoordinatesToRef(zero, worldMatrix, dir);
      particle.position.subtractToRef(dir, directionToUpdate);
    };

    particleSystem.manualEmitCount = cardModelFaceCenters.length;
    particleSystem.disposeOnStop = true;
    particleSystem.start();
  }
}
