import { Animation } from "@babylonjs/core/Animations/animation";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { Observable } from "@babylonjs/core/Misc/observable";
import { ParticleSystem } from "@babylonjs/core/Particles/particleSystem";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { game } from "components/game/Game";
import { PlayerSystem } from "components/game/PlayerSystem";
import { IntegerTextBlock } from "components/ui/Controls";
import { AnimatableRectangle } from "components/ui/controls/AnimatableRectangle";
import { ScreenWithFooter } from "components/ui/controls/ScreenWithFooter";
import { SharePopUp } from "components/ui/controls/SharePopUp";
import { LeaderboardSidebarSystem } from "components/ui/LeaderboardSidebarSystem";
import { PlayerGUIObject } from "components/ui/PlayerGUIObject";
import { UserStatusSystem } from "components/ui/UserStatusSystem";
import { disposeGuiControl, findGuiControl, toast } from "components/utils/GUI";
import { PLAYER_STATE_SUB } from "states/game/PlayerState";
import { WonTricks } from "states/game/WonTricks";
import { BidDisplayMode } from "states/user/UserState";
import { config } from "utils/Config";

import shareIconUrl from "components/ui/icons/share.png";
import { Argo3dParticleSystem } from "../ArgoParticleSystem";

const SHARE_NAMES = ["bid", "bid-alt"];

let SCORE_FRAMES_FACTOR = 6;
let WON_TRICK_TIMING = 1;
let WAIT_FOR_FLOATERS = true;
let FLOATER_DELAY = 0.1;
let FLOATER_START = 8;
let FLOATER_FLOAT = 20;

export class SpadesRoundSummaryGUI extends ScreenWithFooter {
  gameOverMode = false;  // false we're a round summary, true we're the game over screen
  playerSystem: PlayerSystem;
  players: PlayerGUIObject[] = [];
  teamText: IntegerTextBlock[] = [];
  teamParticleSystem: ParticleSystem[] = [];
  wonTricks: WonTricks[] = [];
  wonTricksSeat = 0;
  wonTricksIndex = -2;
  wonTricksAnimating = false;
  wonTricksTimer = 0;
  wonTricksWait = false;
  wonTricksCheckGameOver = false;
  animateUserStatusChanges = false;

  onDoneObservable: Observable<void>;
  onContinueObservable: Observable<void>;

  static autoShowAvatarSharePopUp = true;

  constructor(playerSystem: PlayerSystem, test: boolean, gameOverMode: boolean, continueTimeout: number) {
    super("SpadesRoundSummary");

    this.onDoneObservable = new Observable<void>();
    this.onContinueObservable = new Observable<void>();

    this.gameOverMode = gameOverMode;
    this.playerSystem = playerSystem;

    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height - menuBarHeight;

    let ww = guiWidth;
    let wh = guiHeight;

    let cw = guiWidth;
    let ch = guiHeight * 3 / 5;
    let ct = guiHeight / 5;

    let col0 = cw * 2 / 9 + cw / 18;
    let col1 = cw * 6 / 9 + cw / 18;
    let row0 = ct + ch * 1 / 4;
    let row1 = ct + ch * 3 / 4;

    let k = 8; // "k" is basically how many player boxes should fit vertically on the screen, so bigger k for smaller boxes
    let height = Math.min(guiWidth / k / 2, guiHeight / k);

    let fontHeight = height;
    let winnerLabelHeight = height * 0.5;

    let buttonWidth = height * 4.0;
    let buttonHeight = height * 0.75;
    let buttonFontHeight = buttonHeight * 0.8;
    let buttonPadding = (buttonWidth * 0.05);

    let quickPlayMode = game.gameState.options.name === "quickPlay";
    let haveContinueButton = game.gameState.options.name !== "handChallenge"; // no continue/Play Again button in hand challenge mode
    if(this.gameOverMode &&  game.gameState.options.name === "tutorial") // also no Play Again button on tutorial game over
      haveContinueButton = false;

    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.width = ww + "px";
    this.height = wh + "px";
    //this.background = "green";
    game.guiTexture.addControl(this);

    this.onPointerClickObservable.add(() => this.onClick());

    let classicEndLabel = new TextBlock("SpadesRoundSummaryClassicEndLabel");
    classicEndLabel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    classicEndLabel.top = row0 - winnerLabelHeight * 3;
    classicEndLabel.width = guiWidth + "px";
    classicEndLabel.height = winnerLabelHeight + "px";
    classicEndLabel.fontFamily = config.fontFamily;
    classicEndLabel.fontWeight = config.fontWeight;
    classicEndLabel.fontSize = winnerLabelHeight + "px";
    classicEndLabel.color = "white";
    classicEndLabel.text = `Game ends at ${game.gameState.options.endScore} points`;
    classicEndLabel.isVisible = !quickPlayMode && game.gameState.options.endScore > 0;
    this.addControl(classicEndLabel);

    let winnerLabel = new TextBlock("SpadesRoundSummaryWinnerLabel");
    winnerLabel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    winnerLabel.top = row0 - winnerLabelHeight * 2;
    winnerLabel.width = guiWidth + "px";
    winnerLabel.height = winnerLabelHeight + "px";
    winnerLabel.fontFamily = config.fontFamily;
    winnerLabel.fontWeight = config.fontWeight;
    winnerLabel.fontSize = winnerLabelHeight + "px";
    winnerLabel.color = "white";
    winnerLabel.text = "Winners!";
    winnerLabel.isVisible = false;
    this.addControl(winnerLabel);

    this.addFooterButton("SpadesRoundSummaryShare", "Share", shareIconUrl, () => this.showAvatarSharePopUp(false));

    // If the game is over change the Continue button to a Play Again button
    let continueText = "Continue";
    if(this.gameOverMode)
      continueText = "Play Again";

    // if the game is over show a Done button that goes back to Home Screen
    if(this.gameOverMode) {
      this.addFooterButton("SpadesRoundSummaryExit", "Done", null, () => this.onDoneObservable.notifyObservers(null));
    }

    if(haveContinueButton) {
      let continueButton = this.addFooterButton("SpadesRoundSummaryContinue", continueText, null, () => this.onContinueObservable.notifyObservers(null));

      if(continueTimeout) {
        let now = new Date();
        let turnStart = game.gameState.turnStart;
        let secondsUntilExpired = continueTimeout - (now.getTime() - turnStart.getTime()) * 0.001;

        if(secondsUntilExpired < 3)
          secondsUntilExpired = 3;

        // red bar under the continue button
        let timer = new AnimatableRectangle("SpadesRoundSummaryTimer");
        timer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        timer.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
        timer.width = 0;
        timer.height = "2px";
        timer.color = "#800000";
        continueButton.addControl(timer);

        let a = new Animation("SpadesRoundSummaryTimer", "animatableWidth", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
        let keyFrames = [];
        let k0 = 0;
        let k1 = secondsUntilExpired * 30;
        keyFrames.push({frame: k0, value: 0});
        keyFrames.push({frame: k1, value: continueButton.widthInPixels});
        a.setKeys(keyFrames);

        // XXX - The animation done event will fire if the animation is stopped in dispose, so we need to detect that and not try to auto-click

        game.scene.beginDirectAnimation(timer, [a], 0, k1, false, 1.0, () => this.onTimeOut());
      }
    }

    // Create players
    let playerK = 6; // "k" is basically how many player boxes should fit vertically on the screen, so bigger k for smaller boxes
    let playerHeight = Math.min(guiWidth / playerK / 2, guiHeight / playerK);

    let sortedTeams = game.gameState.teams.slice().sort((a, b) => b.score - a.score);

    let row = 0;
    for(let team of sortedTeams) {
      let teamScore = team.score;
      let col = 0;

      // Create the team's score text
      let text = new IntegerTextBlock("Team" + team.id + "Text");
      text.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      text.top = (row === 0 ? row0 : row1) - height * 0.5;
      text.width = guiWidth + "px";
      text.height = height + "px";
      text.fontFamily = config.fontFamily;
      text.fontWeight = config.fontWeight;
      text.fontSize = fontHeight + "px";
      text.color = "white";
      this.addControl(text);
      this.teamText.push(text);

      // Create a particle system
      this.teamParticleSystem.push(this.createParticleSystem(team.id, height, text));

      for(let seatId of team.seats) {
        let seat = game.gameState.getSeat(seatId);

        let player = new PlayerGUIObject(this.playerSystem, "SpadesRoundSummaryPlayer" + seatId, parseInt(team.id),  this.playerSystem.getSeatColor(seatId), playerHeight);
        this.addControl(player);
        player.setBidDisplayMode(this.playerSystem.rootState.user.bidDisplayMode);
        this.players.push(player);
        player.bidDisplayClickObservable.add(() => this.playerSystem.toggleBidDisplayMode());
        player.setMeterSide(col === 1 ? "left" : "right");
        player.left = (col === 0 ? col0 : col1) - player.centerOffsetX;
        player.top = (row === 0 ? row0 : row1) - player.centerOffsetY;
        player.setNameAndImageFromSeat(seat);

        player.setBid(seat.bid, false);

        let wonTricks = game.gameState.getWonTricksForSeat(seatId, true);
        if(wonTricks) {
          this.wonTricks.push(wonTricks);
          teamScore -= wonTricks.points;
        }

        col += 1;
      }

      // Init the team score text with what should be the previous round's score
      text.value = teamScore;

      row += 1;
    }

    if(test === true) {
      this.players[0].setBid(3, false);
      this.players[1].setBid(3, false);

      this.players[2].setBid(0, false);
      this.players[3].setBid(3, false);

      this.wonTricks.length = 0;

      let w = new WonTricks(true, 0);
      w.won();
      w.won();
      w.won();
      w.bag();
      this.wonTricks.push(w);

      w = new WonTricks(true, 0);
      w.won();
      w.won();
      w.won();
      w.bag();
      w.bag();
      this.wonTricks.push(w);

      w = new WonTricks(false, 0);
      w.nilFailed();
      this.wonTricks.push(w);

      w = new WonTricks(true, 0);
      w.won();
      w.won();
      w.partnerWon();
      this.wonTricks.push(w);

      this.teamText[0].value = 0;
      this.teamText[1].value = 0;
    }

    if(this.wonTricks.length) {
      // Start wonTricksAnimation
      this.wonTricksAnimating = true;
    }
  }

  createParticleSystem(teamId: string, height: number, teamText: IntegerTextBlock) {
    // Create a particle system
    let particleSystem = new Argo3dParticleSystem("SpadesRoundSummaryParticleSystem" + teamId, 2000, game.scene);

    // Create an emitter mesh
    particleSystem.emitter = Mesh.CreateBox("SpadesRoundSummaryEmitter" + teamId, 0.0, game.scene);
    particleSystem.emitter.isVisible = false;

    // Initialize the particle system
    particleSystem.minSize = 0.2;
    particleSystem.maxSize = particleSystem.minSize;
    let range = particleSystem.minSize * 0.5;
    particleSystem.minEmitBox = new Vector3(-range, 0, -range);
    particleSystem.maxEmitBox = new Vector3(range, 0, range);
    particleSystem.direction1 = new Vector3(-1, 0, -1);
    particleSystem.direction2 = new Vector3(1, 0, 1);
    particleSystem.minEmitPower = 0.1;
    particleSystem.maxEmitPower = 2.0;
    particleSystem.minLifeTime = 1.0;
    particleSystem.maxLifeTime = 1.0;
    //particleSystem.emitRate = 100; // Using manualEmitCount instead

    // Assign a custom update function to attract particles to the team text
    particleSystem.updateFunction = function(particles) {
      // Get the center point of the team text in global coordinates
      let localCoordinatesForZero = teamText.getLocalCoordinates(new Vector2(0, 0));
      let x = teamText.widthInPixels * 0.5 - localCoordinatesForZero.x;
      let y = teamText.heightInPixels * 0.5 - localCoordinatesForZero.y;

      // We'll attract our particles to that position
      let blackHole = game.unproject(x, y); //new Vector3(x, 0, y);

      // A couple of helpers so we don't need to create new vectors inside the loop
      let difference = new Vector3(0, 0, 0);
      let direction = new Vector3(0, 0, 0);

      // This controls the strength of the attraction
      let g = 7.0;

      for(let index = 0; index < particles.length; index++) {
        // Get the current particle
        let particle = particles[index];

        // Age the particle and see if it died (from default update function)
        particle.age += this._scaledUpdateSpeed;
        if(particle.age >= particle.lifeTime) {
          this.recycleParticle(particle);
          index--;
          continue;
        }

        // Apply the emitted velocity to the particle's position (from default update function)
        particle.direction.scaleToRef(this._scaledUpdateSpeed, this._scaledDirection);
        particle.position.addInPlace(this._scaledDirection);

        // Compute the vector from the particle to the black hole (text position)
        particle.position.subtractToRef(blackHole, difference);

        // Compute the distance
        let distance = difference.length();

        // Shrink the distance
        let t = distance - g * this._scaledUpdateSpeed;

        // If the particle has arrived at, or overshot the black hole, recycle it
        if(t <= 0) {
          this.recycleParticle(particle);
          index--;
          continue;
        }

        // Normalize the direction vector
        difference.normalizeToRef(direction);

        // Scale it by the new distance
        direction.scaleInPlace(t);

        // Position the particle
        blackHole.addToRef(direction, particle.position);

        // Adjust the particle's color based on it's age (from default update function)
        particle.colorStep.scaleToRef(this._scaledUpdateSpeed, this._scaledColorStep);
        particle.color.addInPlace(this._scaledColorStep);
        if(particle.color.a < 0)
            particle.color.a = 0;
     }
    };

    return particleSystem;
  }

  createPointText(text: string, teamText: IntegerTextBlock, points: number, x: number, y: number, width: number, height: number) {
    let textWidth = width;
    let textHeight = height;

    if(points > 50 || points < -50) {
      textWidth *= 1.5;
      textHeight *= 1.5;
    }

    let pointText = Button.CreateSimpleButton("SpadesRoundSummaryPointText", text);
    pointText.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    pointText.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    pointText.left = x;
    pointText.top = y;
    pointText.width = textWidth + "px";
    pointText.height = textHeight + "px";
    pointText.fontFamily = config.fontFamily;
    pointText.fontWeight = config.fontWeight;
    pointText.fontSize = (textHeight * 0.8) + "px";
    pointText.color = "black";
    pointText.background = "white";
    pointText.cornerRadius = textHeight * 0.25;
    pointText.thickness = 0;
    game.guiTexture.addControl(pointText);

    let k0 = 0;
    let k1 = k0 + FLOATER_START; // This is about how long the particles take to get to the center
    let k2 = k1 + FLOATER_FLOAT;

    let aLeft = new Animation("SpadesRoundSummaryPointTextAnimationLeft", "left", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let aLeftKeyFrames = [];
    aLeftKeyFrames.push({frame: k0, value: x});
    aLeftKeyFrames.push({frame: k1, value: x});
    aLeftKeyFrames.push({frame: k2, value: x + (Math.random() - 0.5) * height * 4});
    aLeft.setKeys(aLeftKeyFrames);

    let aTop = new Animation("SpadesRoundSummaryPointTextAnimationTop", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let aTopKeyFrames = [];
    aTopKeyFrames.push({frame: k0, value: y});
    aTopKeyFrames.push({frame: k1, value: y});
    if(points < 0) {
      aTopKeyFrames.push({frame: k2 - 5, value: y - height * 2});
      aTopKeyFrames.push({frame: k2, value: y + height * 4});
    } else {
      aTopKeyFrames.push({frame: k2, value: y - height * 4});
    }
    aTop.setKeys(aTopKeyFrames);

    /*
    let easingFunction = new PowerEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEIN);
    a.setEasingFunction(easingFunction);
    */

    let aFade = new Animation("SpadesRoundSummaryPointTextAnimationFade", "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let fkeys = [];
    fkeys.push({frame: k0, value: 0.0 });
    fkeys.push({frame: k1 - 1, value: 0.0 });
    fkeys.push({frame: k1, value: 1.0 });
    fkeys.push({frame: k2, value: 0.0 });
    aFade.setKeys(fkeys);

    game.scene.beginDirectAnimation(pointText, [aLeft, aTop, aFade], 0, k2, false, 1.0, () => {
      pointText.dispose();
    });

    return pointText;
  }

  setBidDisplayMode(mode: BidDisplayMode | string) {
    for(let player of this.players)
      player.setBidDisplayMode(mode);
  }

  animateWonTricks(deltaTime: number) {
    // 1) Wait one Frame because there's no other way to get valid coordinates out of the GUI besides casting to any and calling a private function (also that function is render)
    // 2) Drop in won tricks for Seat 0, waiting for the previous animation to complete
    // 3) Drop in won tricks for Seat 1
    // 4) Create particles and score floaters for won tricks for Seat 0, waiting for a timer in between
    // 5) floaters for Seat 1
    // 6) Wait for floaters to finish animating and self destruct
    // 7) Drop in won tricks for Seat 2
    // 8) Drop in won tricks for Seat 3
    // 9) Floaters for Seat 2
    // 10) Floaters for Seat 3
    // 11) Wait for everything to stop
    // 12) Check for Game Over

    let seat = this.wonTricksSeat;
    let player = this.players[seat];
    let wonTricks = this.wonTricks[seat];
    let team = (seat === 0 || seat === 1) ? 0 : 1;

    // Wait for pip animation
    if(player && player.isAnimating())
      return;

    // Wait for floater animations
    if(WAIT_FOR_FLOATERS && this.wonTricksWait) {
      if(findGuiControl("SpadesRoundSummaryPointText"))
        return;
      this.wonTricksWait = false;
    }

    // Wait for timer
    if(this.wonTricksTimer > 0) {
      this.wonTricksTimer -= deltaTime;
      if(this.wonTricksTimer > 0)
        return;
      this.wonTricksTimer = 0;
    }

    if(this.wonTricksCheckGameOver) {
      this.wonTricksAnimating = false;
      if(this.gameOverMode)
        this.doGameOver();
      else
        this.onAllAnimationsDone();
      return;
    }

    this.wonTricksIndex += 1;

    // Skip 1 frame so we can get accurate positions from the player GUI objects
    if(this.wonTricksIndex === -1)
      return;

    // Always stop the particle systems so that they will stop when switching teams or finishing
    for(let particleSystem of this.teamParticleSystem)
      particleSystem.stop();

    if(this.wonTricksIndex > 0 && this.wonTricksIndex <= wonTricks.length) {
      let pipGlobal = player.getBidMarkerGlobalPosition(this.wonTricksIndex - 1);
      let emitter = this.teamParticleSystem[team].emitter as AbstractMesh;
      emitter.position = game.unproject(pipGlobal.x, pipGlobal.y);
      this.teamParticleSystem[team].manualEmitCount = 50;
      this.teamParticleSystem[team].start();

      let points = wonTricks.getPoints(this.wonTricksIndex - 1);
      let text = (points > 0 ? "+" : "") + points;

      let height = player.imageWidth * 0.25;
      let width = height * 2.5;

      let teamText = this.teamText[team];
      let x = teamText.leftInPixels + teamText.widthInPixels * 0.5;
      let y = teamText.topInPixels + teamText.heightInPixels * 0.5;

      let ox = 0;
      let oy = -height;

      this.createPointText(text, teamText, points, x + ox, y + oy, width, height);

      this.wonTricksTimer = FLOATER_DELAY;
    }

    if(this.wonTricksIndex >= wonTricks.wonTricks.length) {
      this.wonTricksIndex = 0;
      this.wonTricksSeat += 1;

      if(this.wonTricksSeat >= this.wonTricks.length) {
        this.wonTricksWait = true;
        this.wonTricksCheckGameOver = true;
        return;
      }

      // Pause between teams
      if(this.wonTricksSeat === 2) {
        this.wonTricksIndex = -1;
        this.wonTricksWait = true;
        return;
      }
    }

    seat = this.wonTricksSeat;
    player = this.players[seat];
    wonTricks = this.wonTricks[seat];
    team = (seat === 0 || seat === 1) ? 0 : 1;

    if(this.wonTricksIndex === 0 && (seat === 0 || seat === 2)) {
      // We just transitioned to a new team
      // Start their score counting up, trying to time it with the total length of the animations
      let sortedTeams = game.gameState.teams.slice().sort((a, b) => b.score - a.score);
      let score = sortedTeams[team].score;
      let teamText = this.teamText[team];
      let frames = (wonTricks.length + this.wonTricks[seat + 1].length) * SCORE_FRAMES_FACTOR; // Attempting to guess when all of the won tricks animations will complete
      teamText.animate(teamText.value, score, frames, game.scene);
    }

    if(this.wonTricksIndex >= wonTricks.length)
      return;

    let type = wonTricks.getType(this.wonTricksIndex);
    if(type !== "nil")
      player.setWon(wonTricks, this.wonTricksIndex + 1, true, WON_TRICK_TIMING);
  }

  stopAll() {
    this.animateUserStatusChanges = false;

    if(!this.wonTricksAnimating)
      return;

    this.wonTricksAnimating = false;

    for(let seat = 0 ; seat < 4; seat++) {
      let player = this.players[seat];
      let wonTricks = this.wonTricks[seat];

      player.setWon(wonTricks, -1, false, 0);
    }

    while(true) {
      let c = findGuiControl("SpadesRoundSummaryPointText");
      if(!c)
        break;

      // Explicitly stop the animations, disposing the control isn't doing this automatically
      let animateable = game.scene.getAnimatableByTarget(c);
      if(animateable)
        animateable.stop();

      c.dispose();
    }

    let sortedTeams = game.gameState.teams.slice().sort((a, b) => b.score - a.score);

    for(let team = 0; team < 2; team++) {
      this.teamParticleSystem[team].dispose();
      this.teamText[team].value = sortedTeams[team].score;
    }
    this.teamParticleSystem.length = 0;
  }

  onClick(animateGameOver = true) {
    if(!this.wonTricksAnimating)
      return;

    this.stopAll();

    if(this.gameOverMode)
      this.doGameOver(animateGameOver);
  }

  doGameOver(animate = true) {
    let classicEndLabel = findGuiControl("SpadesRoundSummaryClassicEndLabel");
    classicEndLabel.isVisible = false;

    let winnerLabel = findGuiControl("SpadesRoundSummaryWinnerLabel");
    winnerLabel.isVisible = true;

    // Wait to animate UserStatus
    this.animateUserStatusChanges = true;
    if(this.animateUserStatusChanges) {
      setTimeout(() => {
        (game.systems.get("UserStatusSystem") as UserStatusSystem).animateChanges(() => this.onAnimateUserStatusChangesDone());
      }, 1000);
    } else {
      this.onAllAnimationsDone();
    }

    // if the local player was a sub, then it won't affect there user status (ie won't lose heart or add to score)
    // so remind them why
    let localPlayer = game.gameState.getPlayer(game.rootState.user.id);
    if(localPlayer && localPlayer.status === PLAYER_STATE_SUB)
      toast("ResultsToast", `You joined mid game, the results did not affect you.`);

    // Stop here if we're not animating
    if(!animate)
      return;

    let particles = true;

    // Did the local player win?
    if(game.localSeat) {
      let localTeam = game.gameState.getTeamForSeat(game.localSeat);
      if(localTeam) {
        if(localTeam.place === 1) {
          // Won!
          game.soundSystem.play("goodReaction");
        } else {
          // Lost!
          game.soundSystem.play("badReaction");
          particles = false;
        }
      }
    }

    if(particles)
      game.argoParticleSystem.boom();
  }

  onAnimateUserStatusChangesDone() {
    // Show leaderboard in quick play game over.
    if(game.gameState.options.name === "quickPlay")
      (game.systems.get("LeaderboardSidebarSystem") as LeaderboardSidebarSystem).create();

    this.onAllAnimationsDone();
  }

  onAllAnimationsDone() {
    // Display a mini share pop up
    this.showAvatarSharePopUp(true);
  }

  showAvatarSharePopUp(onPlayer: boolean) {
    if(onPlayer && !SpadesRoundSummaryGUI.autoShowAvatarSharePopUp)
      return;

    // If there are no players, this was disposed
    if(!this.players.length)
      return;

    // If the SharePopUp is already showing, take no action
    if(findGuiControl("SharePopUp"))
      return;

    // Find the share button
    let shareButton = findGuiControl("SpadesRoundSummaryShare", this);
    if(!shareButton)
      return;

    // Hide the share button
    shareButton.isVisible = false;

    // Create a mini SharePopUp
    let pop = new SharePopUp("SharePopUp", SHARE_NAMES);
    pop.centerOnClick = true;
    pop.onDisposeObservable.add((popUp) => this.onAvatarSharePopUpDisposed(popUp as SharePopUp));
    game.guiTexture.addControl(pop);

    if(onPlayer) {
      // Find the local player's avatar
      let player = findGuiControl("SpadesRoundSummaryPlayer" + game.localSeat, this);
      if(!player)
        return;

      // Size the PopUp to the Player's height
      pop.setCustomPageHeight(player.heightInPixels);

      // Center the PopUp on the Player
      let left = player.leftInPixels + player.widthInPixels * 0.5 - pop.widthInPixels * 0.5;
      let top = player.topInPixels + player.heightInPixels * 0.5 - pop.heightInPixels * 0.5;
      pop.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      pop.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
      pop.left = left;
      pop.top = top;
    }
  }

  onAvatarSharePopUpDisposed(popUp: SharePopUp) {
    // If the mini popup was closed with the close button, don't auto show it anymore
    if(SpadesRoundSummaryGUI.autoShowAvatarSharePopUp &&  popUp.disposeReason === "close" && popUp.customPageHeight) {
      SpadesRoundSummaryGUI.autoShowAvatarSharePopUp = false;
    }

    // Find the share button
    let shareButton = findGuiControl("SpadesRoundSummaryShare", this);
    if(!shareButton)
      return;

    // Show the share button
    shareButton.isVisible = true;
  }

  onTimeOut() {
    // If there are no players, onTimeOut was triggered by dispose and should be ignored
    if(!this.players.length)
      return;

    // Auto trigger the continue button action
    this.onContinueObservable.notifyObservers(null);
  }

  newFrame(deltaTime: number) {
    if(this.wonTricksAnimating)
      this.animateWonTricks(deltaTime);
  }

  dispose() {
    this.onDoneObservable.clear();
    this.onContinueObservable.clear();

    this.stopAll();
    this.players.length = 0;
    this.teamText.length = 0;

    disposeGuiControl("SharePopUp");

    let timer = findGuiControl("SpadesRoundSummaryTimer");
    if(timer) {
      let animateable = game.scene.getAnimatableByTarget(timer);
      if(animateable)
        animateable.stop();
    }

    super.dispose();
  }
}
