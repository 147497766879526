import { mergeConfig } from "utils/Config";

import highlightHighUrl from "./highlight-high.mp3";
import highlightLowUrl from "./highlight-low.mp3";

import foomBurnUrl from "./foom-burn.mp3";
import heartbeatUrl from "./heartbeat.mp3";

mergeConfig({
  sounds: {
    highlightHigh: {url: highlightHighUrl, volume: 1.0},
    highlightLow: {url: highlightLowUrl, volume: 1.0},

    // UserStatusSystem.ts, losing a heart
    heartbeat: {url: heartbeatUrl, volume: 1.0},
    heartLost: {url: foomBurnUrl, volume: 0.5},
  },
});
