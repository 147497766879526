import { getParent, types } from "mobx-state-tree";

import { IGameState } from "states/game/GameState";
import { Facing, FACING_DOWN, IPieceState, PieceState } from "states/game/PieceState";
import { SeatState } from "states/game/SeatState";

import { logger } from "utils/logger";

// tslint:disable-next-line:variable-name
const PileState = types
  .model("PileState", {
    name: types.identifier, // types.identifier means this is a unique value to identify this object, and must be used as the map key, ie the key in the GameState.piles map
    type: types.string,
    seat: types.maybeNull(types.reference(SeatState)), // the seat that owns this pile, ie a hand pile in spades
    pieces: types.array(PieceState),
  })
  .views((self) => {

    /** returns piece if it's in this pile
     * @param pieceOrName can be either a piece in which case we verify the piece is in this pile then return it, or a name of a piece to search for
     */
    function getPiece(pieceOrName: IPieceState | string): IPieceState {
      if(typeof pieceOrName === "string")
        return self.pieces.find((piece) => piece.name === pieceOrName);
      else
        return self.pieces.find((piece) => piece === pieceOrName);
    }

    function getPieceByValue(value: number): IPieceState {
      return self.pieces.find((piece) => piece.value === value);
    }

    function getPieceIndex(pieceOrName: IPieceState | string): number {
      let piece = getPiece(pieceOrName);
      return self.pieces.findIndex((curPiece) => curPiece === piece);
    }

    function getTopPiece(): IPieceState {
      return self.pieces[self.pieces.length - 1];
    }

    /** returns true if this pile has a piece with suit */
    function hasSuit(suit: number) {
      return self.pieces.find((piece) => piece.suit() === suit) !== undefined;
    }

    /** return true if this pile only has pieces with suit */
    function onlyHasSuit(suit: number) {
      // For peculiar mathematical reasons relating to set theory, "every" always returns true if the array is empty, so we'll check that first
      return self.pieces.length && self.pieces.every((piece) => piece.suit() === suit);
    }

    /** return a list of all selected pieces in this pile */
    function getSelectedPieces() {
      return self.pieces.filter((piece) => piece.selected);
    }

    return { getPiece, getPieceByValue, getSelectedPieces, getTopPiece,  getPieceIndex, hasSuit, onlyHasSuit };
  })
  .views((self) => {
    /** returns array of IPieceStates starting from piece to end of pile, for example to pick up a stack of cards in klondike from the one the user clicked on    */
    function getPiecesFrom(fromPieceOrName: IPieceState | string): IPieceState[] {
      let fromPiece = self.getPiece(fromPieceOrName);
      let index = self.getPieceIndex(fromPiece);
      if(index < 0)
        return null;
      return self.pieces.slice(index);
    }

    return { getPiecesFrom };
  })
  .actions((self) => {

    function createPiece(name: string, value: number, facing: Facing) {
      let piece = PieceState.create({
        name: name,
        value: value,
        facing: facing,
      });
      self.pieces.push(piece);
    }

    function createCards() {
      // create cards in order, 1-52, initial value is same as id
      for (let id = 1; id <= 52; id++) {
        createPiece("piece" + id, id, FACING_DOWN); // id, value, facing
      }
    }

    /** shuffle values of cards in pile. It requires all 52 cards are in the pile */
    function shuffle() {
      if (self.pieces.length !== 52) {
        logger.warn("shuffle does not have all 52 cards in pile", { name: self.name, cardsInPile: self.pieces.length });
        return;
      }

      // we want the shuffle to be the same with the same seed, but we do not want the id/name of the piece to match the value
      // So basically we want to end up with pieces sorted by name, [piece0, piece1, piece2, ...] with random values, ie [2, 12, 34]
      // To do that we'll sort the pieces, copy their values to an array, shuffle array, assign values back to pieces, then replace pieces back into state
      // this will generate 52 remove patches followed by 52 add patches

      // our parent is the map, it's parent is the gameState
      let gameState = getParent(getParent(self)) as IGameState;

      // clone the pieces array
      let pieces = self.pieces.slice();

      // Sort the array so the same seed always produces the same shuffle
      // The localeCompare function ensures piece2 is sorted before piece10
      pieces.sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}));

      // copy piece values into a simple array, for now this is assuming a 52 card deck
      // The reason we can't get the values from the pieces is that on the client if they played an online game, and now
      // an online game the pieces may have been zeroed.
      let values: number[] = [];
      for (let value = 1; value < 53; value++)
        values.push(value);

      /* This would be a better way  to get values, but we need to be sure all the pieces have a value first.
      pieces.forEach((piece) => {
        values.push(piece.value);
      });
      values.sort();
      */

      // Shuffle the array of values
      for (let i = (values.length - 1); i > 0; i -= 1) {
        const randomIndex = Math.floor(gameState.rng() * (i + 1)); // XXX - This isn't uniform (https://github.com/ckknight/random-js)
        [values[i], values[randomIndex]] = [values[randomIndex], values[i]];
      }

      // DEBUG DECKS - no semicolons so lint fails on checkin (These are the same as SGameDeck.cpp, except reversed)
      // There are Debug menu entries for this under Start Game.  Leaving these here in case they're useful for other testing.
      // To make a new one: the way dealing works, the seat order is [3, 2, 2, 0].  The 1st 13 cards to go seat 3, the last 13 cards go to seat 0
      // MoonDeck
      //values = [13, 12, 11, 10, 9, 8, 46, 45, 44, 31, 30, 15, 3, 39, 38, 37, 36, 35, 34, 33, 32, 43, 42, 29, 28, 5, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 41, 2, 52, 51, 50, 49, 48, 47, 7, 6, 27, 4, 14, 1, 40]
      // SunDeck
      //values = [26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 52, 51, 50, 49, 48, 47, 46, 45, 44, 43, 42, 41, 40, 39, 38, 37, 36, 35, 34, 33, 32, 31, 30, 29, 28, 27]
      // TramDeck
      //values = [44, 43, 24, 23, 22, 21, 33, 32, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 31, 30, 3, 2, 1, 42, 41, 50, 49, 48, 47, 20, 19, 5, 4, 29, 28, 40, 39, 38, 37, 36, 35, 34, 46, 45, 26, 25, 52, 51, 27]

      // now assign those values to the pieces
      values.forEach((value: number, i: number) =>  {
        pieces[i].value = value;
      });

      // Finally, replace the pieces in the state
      self.pieces.replace(pieces);
    }

    return { createPiece, createCards, shuffle };
  });

// PileState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IPileState = typeof PileState.Type;

export { PileState, IPileState };
