import { Animatable } from "@babylonjs/core/Animations/animatable";
import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents";
import { PointerInfoPre } from "@babylonjs/core/Events/pointerEvents";
import { Observable } from "@babylonjs/core/Misc/observable";
import { Observer } from "@babylonjs/core/Misc/observable";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";

import { game } from "components/game/Game";
import { zIndex } from "components/utils/GUI";
import { GUIAnimationProxy } from "components/utils/GUIAnimationProxy";

/**
 * PopUp base class
 * Watches scene onPrePointerObservable
 * On a click not contained by the popup, disposes
 * Also watches for resizes and calls layout
 */
export class PopUp extends Rectangle {
  private resizeObserver: Observer<void>;
  private prePointerObserver: Observer<PointerInfoPre>;
  private scaleAnimation: Animatable = null;

  disposeOnMiss = true;

  onDisposeObservable: Observable<PopUp>;

  // How the PopUp was disposed (if you're handling onDisposeObservable): "close", "miss", "dispose"
  disposeReason = "dispose";

  constructor(name: string) {
    super(name);

    this.zIndex = zIndex.MENU;
    this.isPointerBlocker = true;

    this.onDisposeObservable = new Observable<PopUp>();

    // Layout on resize
    this.resizeObserver = game.onResizedObservable.add(() => this.onResized());

    // Dispose on pointer miss
    this.prePointerObserver = game.scene.onPrePointerObservable.add((info) => this.onPrePointer(info));
  }

  /** layout is called when the window is resized */
  layout() {
    this.stopAnimations();
  }

  /** dispose on a pointer miss */
  onPointerMiss() {
    if(!this.isVisible)
      return;

    // overridden by subclass
    if(this.disposeOnMiss)
      this.disposeWithReason("miss");
  }

  /** window resized event, calls layout */
  onResized() {
    this.layout();
  }

  /** process a pre-pointer event */
  onPrePointer(info: PointerInfoPre) {
    // We're only interested in pointer down events
    if(info.type !== PointerEventTypes.POINTERDOWN)
      return;

    // Get the current pointer position processed into GUI coordinates
    let pos = game.getGuiPointerCoordinates();

    // Check for a miss
    if(!this.contains(pos.x, pos.y))
      this.onPointerMiss();
  }

   /** Animate the button's scale, with an elastic bounce */
   animateScale(from: number, to: number, durationInSeconds: number) {
    if(this.scaleAnimation)
      this.scaleAnimation.stop();

    let proxy = new GUIAnimationProxy(this);
    return this.scaleAnimation = proxy.animateFloat("scale", from, to, durationInSeconds, false, true, game.scene);
   }

   stopAnimations() {
    if(this.scaleAnimation) {
      this.scaleAnimation.goToFrame(this.scaleAnimation.toFrame);
      this.scaleAnimation.stop();
      this.scaleAnimation = null;
    }
   }

   removePrePointerObserver() {
    if(this.prePointerObserver) {
      game.scene.onPrePointerObservable.remove(this.prePointerObserver);
      this.prePointerObserver = null;
    }
   }

  /** dispose and cleanup observables */
  dispose() {
    super.dispose();

    this.stopAnimations();

    this.removePrePointerObserver();

    if(this.resizeObserver) {
      game.onResizedObservable.remove(this.resizeObserver);
      this.resizeObserver = null;
    }

    if(this.onDisposeObservable.hasObservers()) {
      this.onDisposeObservable.notifyObservers(this);

      // Prevent further calls to onDisposeObservable if dispose gets called multiple times
      this.onDisposeObservable.clear();
    }
  }

  /** set disposeReason and close */
  disposeWithReason(disposeReason: string) {
    this.disposeReason = disposeReason;
    this.dispose();
  }
}
