import { getParent, types } from "mobx-state-tree";

import { config } from "utils/Config";

import { logger } from "@sentry/utils";
import { PAYMENT_TYPE_FACEBOOKIG, PaymentItemState } from "./PaymentItemState";
import { IUserState } from "./UserState";

export interface IPayment {
  type: string; // "facebook". should make enum
  paymentData: any; // json payment data from provider
  /*
    Facebook paymentData:
      developerPayload string? A developer-specified string, provided during the purchase of the product
      paymentID string The identifier for the purchase transaction
      productID string The product's game-specified identifier
      purchaseTime string Unix timestamp of when the purchase occurred
      purchaseToken string A token representing the purchase that may be used to consume the purchase
      signedRequest SignedPurchaseRequest Server-signed encoding of the purchase request
  */
}

// tslint:disable-next-line:variable-name
const PaymentsState = types
  .model("PaymentsState", {
    items: types.array(PaymentItemState),
  })
  .views((self) => {

    /** Searches payments */
    function getPayment(paymentId: string) {
      return self.items.find((item) => item.paymentId === paymentId);
    }

    /** returns true if this payment has been processed already. */
    function hasPayment(paymentID: string) {
      let item = getPayment(paymentID);
      if(item)
        return true;

      return false;
    }

    return { getPayment, hasPayment };
  })
  .actions((self) => {

    /** Processes a payment by looking up the offer it is for and adding/updating the inventory */
    function processPayment(userId: string, payment: IPayment) {
      if(!config.facebookAuth) {
        logger.error("Can't process payment because config.facebookAuth is not set.", { userId, payment });
        return;
      }

      // first check to make sure the signature is valid in production, and then get the paymentData from the signedRequest, to prevent user tamporing
      const signedRequest = payment.paymentData.signedRequest;
      if(process.env.NODE_ENV === "production" && !config.facebookAuth.isFBSignatureValid(signedRequest)) {
        logger.error("InventoryState.processPayment Facebook signature is not valid.", { userId, payment });
        return;
      }
      const paymentData = config.facebookAuth.getFBSignatureData(signedRequest);
      /* Example paymentData: {
          algorithm: 'HMAC-SHA256',
          amount: '2.99',
          currency: 'USD',
          developer_payload: null,
          is_consumed: false,
          issued_at: 1562875270,
          payment_id: '1553424834788402',
          product_id: 'insta_pass_1_month',
          purchase_time: 1562875265,
          purchase_token: '10216402520508844',
          quantity: '1',
          request_id: '',
          status: 'completed'
        }

        // And then another time I got fewer fields, and some Number instead of String:
        { algorithm: 'HMAC-SHA256',
          app_id: 2451495921534198,
          is_consumed: false,
          issued_at: 1563492957,
          payment_id: 1649758381821413,
          product_id: 'insta_pass_1_month',
          purchase_time: 1563492203,
          purchase_token: 10216453273777644
        }
      */

      // ignore it if we've already processed this payment
      if(self.hasPayment(paymentData.payment_id))
        return;

      // save payment info
      let item = PaymentItemState.create({ paymentId: String(paymentData.payment_id), paymentType: PAYMENT_TYPE_FACEBOOKIG, signedRequest: signedRequest });
      self.items.push(item);

      // insert offer into inventory
      let userState = getParent(self) as IUserState;
      userState.inventory.insertOffer(paymentData.product_id);
    }

    return { processPayment };
  });

// InventoryState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IPaymentsState = typeof PaymentsState.Type;

export { PaymentsState, IPaymentsState };
