import { applySnapshot, IJsonPatch, types } from "mobx-state-tree";

import { GAME_STATE_BID, GAME_STATE_CREATE, GAME_STATE_DEAL, GAME_STATE_GAME_OVER, GAME_STATE_PLAY, GAME_STATE_RESET, GAME_STATE_RESET_ROUND, GAME_STATE_ROUND_OVER, GAME_STATE_SHUFFLE, GAME_STATE_WAITING_FOR_PLAYERS, IGameStateErrorCallback, IPilesMinSnap } from "states/game/GameState";
import { FACING_DOWN, FACING_UP, IPieceState, PieceState } from "states/game/PieceState";
import { PilesGameState } from "states/game/PilesGameState";
import { IPileState } from "states/game/PileState";
import { ISeatState, SeatState } from "states/game/SeatState";
import { TrickGameOptionsState } from "states/game/TrickGameOptionsState";
import { TrickState } from "states/game/TrickState";
import { ApplySnapshotStage, IChannelPatch } from "states/state-sync/BaseStateSync";

import { logger } from "utils/logger";

// tslint:disable-next-line:variable-name
const TrickGameState = PilesGameState
  .props({
    dealer: types.maybeNull(types.reference(SeatState)), // reference to the dealer's seat
    seatLead: types.maybeNull(types.reference(SeatState)), // reference to the seat that lead the current trick
    round: types.maybeNull(types.number),
    tricks: types.array(TrickState),
    trumpSuit: types.maybeNull(types.number),

    // trumpSuitBroken is being renamed to protectedSuitBroken to make sense in hearts.
    // First stage is to set both trumpSuitBroken and protectedSuitBroken, to maintain backward compatibility.
    // Then once all clients have upgraded to the version with protectedSuitBroken, remove trumpSuitBroken.
    trumpSuitBroken: types.optional(types.boolean, false),
    protectedSuitBroken: types.optional(types.boolean, false),

    options: types.optional(TrickGameOptionsState, {}), // overrides options in GameState

    // When adding more properties be sure to review if they need to be copied in applySyncToServerSnapshot
  })
  .volatile((self) => ({
    // These are like rule options that aren't changeable by the user, they are changed by subclasses
    highScoreWins: true,  // used for calculating place, in spades highScoreWins, set false in hearts because low score wins

    // Default to partners, 2 teams with 2 players each.
    playersPerTeam: 2,
    teamCount: 2,
  }))
  .preProcessSnapshot((snapshot) => {
    // trumpSuitBroken was renamed to protectedSuitBroken, so we may need to import from the old name to new
    // remember to leave this in when we remove trumpSuitBroken so that old saved games will always be imported correctly
    return {
      ...snapshot,
      protectedSuitBroken: snapshot.trumpSuitBroken,
    };
  })
  .views((self) => {
    /** Checks if piece is a protected card, meaning it can't be played until that suit is broken. */
    function isProtectedPiece(piece: IPieceState) {
      // overridden by subclass, ie HeartsGameState overrides to return true if piece is a heart or Queen of Spades
      return false;
    }
    return { isProtectedPiece };
  })
  .views((self) => {
    /** returns true if the pile only has pieces that are protected, ie hearts and queen of spades in hearts */
    function onlyHasProtectedPieces(pile: IPileState) {
      return pile.pieces.length && pile.pieces.every((piece) => self.isProtectedPiece(piece));
    }
    return { onlyHasProtectedPieces };
  })
  .views((self) => {
    const superGetSeatTurnTimeout = self.getSeatTurnTimeout;

    function getLeadPiece(): IPieceState {
      let trick = self.getPile("trick");
      if(trick.pieces.length > 0)
        return trick.pieces[0];
      else
        return null;
    }

    function getTrickWinningPiece(): IPieceState {
      let trick = self.getPile("trick");

      let winningPiece = getLeadPiece();
      trick.pieces.forEach((piece) => {
        // piece can only win if it is same as lead suit, or trump suit
        if(piece.suit() === getLeadPiece().suit() || piece.suit() === self.trumpSuit)
        {
          // check if piece trumps current winning piece
          if((piece.suit() === self.trumpSuit && winningPiece.suit() !== self.trumpSuit))
            winningPiece = piece;
          // else check if piece rank is higher then winning
          else if(piece.rankAceHigh() > winningPiece.rankAceHigh() && piece.suit() === winningPiece.suit())
            winningPiece = piece;
        }
      });
      return winningPiece;
    }

    function getTrickWinner(): ISeatState {
      let winningPiece = getTrickWinningPiece();
      return winningPiece.seat;
    }

    /**
     * Returns 1, 2 or 3 if the given piece is currently the winning trump in the trick
     * The number returned indicates how many trumps have taken place in this trick, including the given piece
     * Returns 0 if trumps were lead, the piece is not a trump, the piece is the leader, or the piece is not winning
     */
    function getWinningTrumpCountForPiece(pieceOrName: IPieceState | string): number {
      let missing = false;
      let trick = self.getPile("trick");
      let piece = trick.getPiece(pieceOrName);
      if(!piece) {
        // If the given piece isn't in the trick, we'll try to figure out if it would be winning the trick if played next
        missing = true;

        // If it would be the lead, it's not trumping, also lead.suit will fail below, so check right away
        if(trick.pieces.length === 0)
          return 0;

        // Find the piece in whichever hand it's in
        piece = self.findPiece(pieceOrName);
        if(!piece)
          return 0;
      }

      // Make sure the piece is a trump
      if(piece.suit() !== self.trumpSuit)
        return 0;

      // If trumps were lead, the piece is not trumping anyone
      let lead = getLeadPiece();
      if(lead.suit() === self.trumpSuit)
        return 0;

      // If the piece lead, it's not trumping
      if(lead.name === piece.name)
        return 0;

      // Count the number of trumps that have occurred in the hand
      // At this point we know any trumps found are trumping the lead
      let trickWinner = lead;
      let trumps = 0;
      for(let trickPiece of trick.pieces) {
        if(trickPiece.suit() !== self.trumpSuit)
          continue;
        if(trickWinner.suit() === self.trumpSuit && trickPiece.rankAceHigh() <= trickWinner.rankAceHigh())
          continue;
        // The new piece is the new winner
        trickWinner = trickPiece;
        trumps += 1;
      }

      let winningPiece = getTrickWinningPiece();
      if(winningPiece.name === piece.name)
        return trumps;

      if(!missing)
        return 0;

      // At this point we're trying to determine if the card would be the winner if played
      // We know our card is a trump
      trumps += 1;

      // If the winning piece was not a trump, then we trump them regardless of value
      if(winningPiece.suit() !== self.trumpSuit)
        return trumps;

      // Otherwise it's a battle of trumps, see if ours is larger
      if(piece.rankAceHigh() > winningPiece.rankAceHigh())
        return trumps;

      return 0;
    }

    /**
     * Returns 1, 2, 3 or 4 if the given piece is currently the winning piece in the trick
     * The number returned indicates how many trumps have taken place in this trick, including the given piece
     * Returns 0 if trumps were lead, the piece is not a trump, the piece is the leader, or the piece is not winning
     */
    function getWinningCountForPiece(pieceOrName: IPieceState | string): number {
      let trick = self.getPile("trick");
      let winners = 1;
      let givenPiece = self.findPiece(pieceOrName);
      let givenPieceFound = false;

      // If the trick is empty, the given piece will be the lead
      if(trick.pieces.length === 0)
        return winners;

      let winningPiece = getLeadPiece();
      trick.pieces.forEach((piece) => {
        if(piece.name === givenPiece.name)
          givenPieceFound = true;

        // piece can only win if it is same as lead suit, or trump suit
        if(piece.suit() === getLeadPiece().suit() || piece.suit() === self.trumpSuit)
        {
          // check if piece trumps current winning piece
          if((piece.suit() === self.trumpSuit && winningPiece.suit() !== self.trumpSuit)) {
            winningPiece = piece;
            winners += 1;
          // else check if piece rank is higher then winning
          } else if(piece.rankAceHigh() > winningPiece.rankAceHigh() && piece.suit() === winningPiece.suit()) {
            winningPiece = piece;
            winners += 1;
          }
        }
      });

      // If we did not find the given piece, see if it would beat the current winner
      if(!givenPieceFound) {
        // See if the given piece is following suit and would win
        if(givenPiece.suit() === winningPiece.suit() && givenPiece.rankAceHigh() > winningPiece.rankAceHigh())
          return winners + 1;
        // See if the given piece is trumping the winner
        if(givenPiece.suit() === this.trumpSuit && winningPiece.suit() !== this.trumpSuit)
          return winners + 1;

        // Otherwise, the given piece would not win when played
        return 0;
      }

      // We have the winner and the count of winners, but is it our given piece?
      if(winningPiece.name !== givenPiece.name)
        return 0;

      // Return the count of winners
      return winners;
    }

    function canClickPiece(playerId: string, seatOrId: ISeatState | string, pileOrName: IPileState | string, pieceOrName: IPieceState | string, errorCallback?: IGameStateErrorCallback, logErrors = false): boolean {
      let seat = self.getSeat(seatOrId);
      let pile = self.getPile(pileOrName);
      let piece = pile.getPiece(pieceOrName);
      let leadPiece = getLeadPiece();

      if(self.status !== GAME_STATE_PLAY) {
        if(logErrors)
          logger.info("canClickPiece status error", { id: self.id, playerId, seatId: seat.id, pileName: pile.name, seatsTurn: self.seatsTurn, gameStatus: self.status });
        return false;
      }

      if(!piece) {
        if(logErrors)
          logger.info("canClickPiece piece not found in pile.", { id: self.id, playerId, seatId: seat.id, pileName: pile.name, pieceOrName});
        return false;
      }

      // be sure the current seat is only clicking on their own hand
      if(self.getPlayerSeat(playerId) !== seat || seat !== self.seatsTurn || pile.seat !== self.seatsTurn || pile.type !== "hand") {
        if(logErrors)
          logger.info("canClickPiece turn error", { id: self.id, playerId, seatId: seat.id, pileName: pile.name, seatsTurn: self.seatsTurn.id});
        return false;
      }

      // check if they are leading
      if(!leadPiece) {
        if(!self.isProtectedPiece(piece) || self.protectedSuitBroken || self.onlyHasProtectedPieces(pile)) {
          return true;
        } else {
          if(errorCallback)
            errorCallback(`You may not lead ${piece.suitName()}s until they are broken`);

          if(logErrors)
            logger.info("canClickPiece protectedSuitBroken not broken", { id: self.id, playerId, seatId: seat.id, isProtectedPiece: self.isProtectedPiece(piece), protectedSuitBroken: self.protectedSuitBroken, onlyHasProtectedPieces: self.onlyHasProtectedPieces(pile) });
          return false;
        }
      }

      // not leading, check if they're following suit, or can't follow suit
      if(leadPiece.suit() === piece.suit() || !pile.hasSuit(leadPiece.suit()))
        return true;

      if(errorCallback) {
        errorCallback("Follow the lead suit when possible");
        if(logErrors)
          logger.info("canClickPiece Not following lead", { id: self.id, playerId, seatId: seat.id, pileName: pile.name, leadSuit: leadPiece.suit(), hasSuit: pile.hasSuit(leadPiece.suit()) });
      }

      return false;
    }

    function canSetBid(playerId: string, seatOrId: ISeatState | string, bid: number) {
      let seat = self.getSeat(seatOrId);
      if(self.getPlayerSeat(playerId) === seat && self.status === GAME_STATE_BID && self.seatsTurn === seat && bid >= 0 && bid <= 13)
        return true;

      return false;
    }

    function hasAllBid() {
      // use find to check if any seats haven't bid yet
      if(self.seats.find((seat) => seat.bid === null))
        return false;
      return true;
    }

    function getSeatTurnTimeout(seatOrId: ISeatState | string) {
      // overriding GameState.getSeatTurnTimeout to adjust timeout
      let timeout = superGetSeatTurnTimeout(seatOrId); // first get default timeout

      // If there's no timeout, pass that on
      if(!timeout)
        return 0;

      // if this is the first card of the trick then add some extra time to account for the animation delay clearing the trick.
      let trickPile = self.getPile("trick");
      if(trickPile.pieces.length === 0 && self.status === GAME_STATE_PLAY)
        timeout += 1.25;

      return timeout;
    }

    /** returns the channel name to send a patch too. Returns null if it shouldn't be sent at all.
     * Send most patches to "public" channel, send card values in hand only to the player in that seat "#1234"  where 1234 is the user.id
     */
    function filterPatch(patch: IJsonPatch, reversePatch: IJsonPatch): IChannelPatch[] {
      // ignore all changes to the stock pile during reset and shuffling. Luckily the client doesn't care where the card is at when it animates the card to the hand
      // This is an optimization to reduce the number of patches sent when starting a game, and even more so when starting a new hand in classic
      // It ignores 156 patches when starting a new hand: 52 adds moving from waste to stock, 52 removes + 52 adds when shuffling
      if((self.status === GAME_STATE_RESET_ROUND || self.status === GAME_STATE_SHUFFLE) && patch.path.includes("/piles/stock/pieces/"))
        return [];

      let channelPatches: IChannelPatch[] = [];
      let channel = "public";

      // regexp to match patches for pieces in hands, ie /piles/hand0/pieces/1
      // note the (\/game|) below matches /game or nothing. This is because on the client the path will be /game/piles/...  on the server it'll be /piles/...
      const regExpValue = new RegExp(`^(\/game|)\/piles\/hand(\\d*)\/pieces\/(\\d*)(\/value|\/facing|\/selected|$)`);
      let params = regExpValue.exec(patch.path);
      if(params) {
        channel = null; // by default be sure we aren't sending value, facing, or selected to anyone.

        // Then if there is someone in that seat, send it to their channel.
        const seat = params[2]; // [2] is the value of the first (\\d*) from the regExp
        const seatState = self.getSeat(seat);
        if(seatState && seatState.player && !seatState.player.bot)
          channel = `#${seatState.player.id}`; // send to player's channel

        // always send remove patches as is to the public channel
        if(patch.op === "remove")
          channelPatches.push({channel: "public", patch, reversePatch});

        // if this is an add patch that contains facing, value, or selected, then it is likely from loading an offline snapshot
        // break it into multiple patches to send the add to the public channel, and the facing/value/selected to the seats private channel
        if(patch.op === "add") {
          const addPatch: IJsonPatch = {
            op: patch.op,
            path: patch.path,
            value: {
              name: patch.value.name,
              seat: patch.value.seat,
              facing: FACING_DOWN,
              value: 0,
            },
          };
          const addReversePatch: IJsonPatch = {
            op: "remove",
            path: patch.path,
          };
          channelPatches.push({channel: "public", patch: addPatch, reversePatch: addReversePatch});

          // Send Facing to private channel
          if(channel && patch.value.facing === FACING_UP) {
            const facingPatch: IJsonPatch = {
              op: "replace",
              path: patch.path + "/facing",
              value: FACING_UP,
            };
            const facingReversePatch: IJsonPatch = {
              op: "replace",
              path: patch.path + "/facing",
              value: FACING_DOWN,
            };
            channelPatches.push({channel, patch: facingPatch, reversePatch: facingReversePatch});
          }

          // Send Value to private channel
          if(channel && patch.value.value > 0) {
            const valuePatch: IJsonPatch = {
              op: "replace",
              path: patch.path + "/value",
              value: patch.value.value,
            };
            const valueReversePatch: IJsonPatch = {
              op: "replace",
              path: patch.path + "/value",
              value: 0,
            };
            channelPatches.push({channel, patch: valuePatch, reversePatch: valueReversePatch});
          }

          // Send selected to private channel
          if(channel && patch.value.selected) {
            const selectedPatch: IJsonPatch = {
              op: "replace",
              path: patch.path + "/selected",
              value: true,
            };
            const selectedReversePatch: IJsonPatch = {
              op: "replace",
              path: patch.path + "/selected",
              value: false,
            };
            channelPatches.push({channel, patch: selectedPatch, reversePatch: selectedReversePatch});
          }
        }
      }

      // if we should send patch to a channel, but it hasn't been added to channelPatches yet, add it now
      if(channel && !channelPatches.length)
        channelPatches.push({channel, patch, reversePatch});

      return channelPatches;
    }

    /** returns a mini snapshot of hand piles at start of game. Since this gets the pieces by seat it doesn't matter where the pieces
     * currently are, So this should work at any stage of the game
     * {
     *  h0: {p: [52, 40, 1, 50, 35, 7, 31, 22, 9, 16, 30, 33, 17]},
     * }
     * p is a list of piece values
     */
    function getMinPiles(): IPilesMinSnap {
      let minPiles: IPilesMinSnap = {};
      self.seats.forEach((seat) => {
        let seatPieces = self.findAllPiecesOwnedBySeat(seat);
        const pieceValues = seatPieces.map((piece) => piece.value);
        minPiles[`h${seat.id}`] = { p: pieceValues };
      });
      return minPiles;
    }

    /** Returns seat that shot the moon this round, or null */
    function getShootMoonSeat(): ISeatState {
      return null;
    }

    /** Returns seat that shot the sun this round, or null */
    function getShootSunSeat(): ISeatState {
      return null;
    }

    return { getLeadPiece, canClickPiece, filterPatch, getMinPiles, getSeatTurnTimeout, getTrickWinningPiece, getWinningCountForPiece, getTrickWinner, getWinningTrumpCountForPiece, canSetBid, hasAllBid, getShootMoonSeat, getShootSunSeat };
  })
  .actions( (self) => {
    function addTrick() {
      let trickState = TrickState.create({});
      self.tricks.push(trickState);
    }

    function setTrickLead(seatOrId: ISeatState | string) {
      let seat = self.getSeat(seatOrId);
      let trickState = self.tricks[self.tricks.length - 1];
      trickState.setSeatLead(seat);
    }

    function setTrickWinner(seatOrId: ISeatState | string) {
      let seat = self.getSeat(seatOrId);
      let trickState = self.tricks[self.tricks.length - 1];
      trickState.setSeatWinner(seat);
    }

    function setTrickPiece(seatOrId: ISeatState | string, piece: IPieceState) {
      let seat = self.getSeat(seatOrId);
      let trickState = self.tricks[self.tricks.length - 1];
      trickState.setPiece(seat, piece);
    }

    return { addTrick, setTrickLead, setTrickWinner, setTrickPiece };
  })
  .actions((self) => {
    function scoreTrick(seatWon: ISeatState) {
      // overridden by subclass
    }

    function scoreRound() {
      // overridden by subclass
    }

    function startRound() {
      // overridden by subclass

      logger.appendToGameLog("startRound");

      // Increment round here, so that it can be used to identify the start of a new round
      self.round += 1;

      // Advance Dealer
      self.dealer = self.getNextSeat(self.dealer);

      // Start the Round History
      self.addTrick();

      self.deal();
    }

    function doGameOver() {
      // first double check that game really is over
      if(!self.isGameOver())
        return;

      // sort teams by score (high score wins), slice() makes a copy of teams
      let sortedTeams = self.teams.slice().sort((a, b) => b.score - a.score);

      // if highScoreWins is false, then low score wins, so reverse list
      if(!self.highScoreWins)
        sortedTeams.reverse();

      // set place of each team
      let prevScore: number = null;
      let place = 0;
      sortedTeams.forEach((team, i) => {
        // increment place, but not if it was a tie with the previous teams score
        if(prevScore === null || team.score !== prevScore)
          place += 1;
        prevScore = team.score;
        team.place = place;
        logger.info("Team placed", { team: team.id, place: team.place} );

      });

      self.endGame();
    }

    function resetRound() {
      // only set status to GAME_STATE_RESET_ROUND if we aren't reseting during GAME_STATE_CREATE/GAME_STATE_RESET
      if(self.status !== GAME_STATE_RESET && self.status !== GAME_STATE_CREATE)
        self.setStatus(GAME_STATE_RESET_ROUND);
      self.seatsTurn = null;
      self.trumpSuitBroken = false;
      self.protectedSuitBroken = false;
      self.tricks.length = 0;
      let stock = self.getPile("stock");
      if (stock) {
          self.piles.forEach((pile) => {
          if (pile.name !== "stock") {
            let pieceNames: string[] = [];
            pile.pieces.forEach((piece) => {
              pieceNames.push(piece.name);
            });
            self.movePieces(pile.name, "stock", pieceNames, FACING_DOWN);
          }
        });
      }
    }

    return { scoreTrick, startRound, scoreRound, resetRound, doGameOver };
  })
  .actions((self) => {
    const superApplySyncToServerSnapshot = self.applySyncToServerSnapshot;
    const superInit = self.init;
    const superCreateState = self.createState;
    const superResetState = self.resetState;
    const superPrepForSnapShot = self.prepForSnapshot;
    const superStartGame = self.startGame;
    const superSetApplySnapshotStage = self.setApplySnapshotStage;

    // afterCreate is like a constructor, is called after values loaded
    function afterCreate() {
      self.allowUndo = false; // disable undo
    }

    /** creates piles, teams, seats, players */
    function init() {
      superInit();
      // create seats, teams, players and hands
      let seatCount = self.teamCount * self.playersPerTeam;
      self.createSeats(seatCount);
      self.createTeams(self.teamCount);
      for (let seatIndex = 0; seatIndex < seatCount; seatIndex++) {
        self.createPile("hand" + seatIndex, "hand", self.seats[seatIndex]);
        self.createPile("waste" + seatIndex, "waste", self.seats[seatIndex]);
      }

      // put seats on teams
      if(self.playersPerTeam === 1) {
        for (let seatIndex = 0; seatIndex < seatCount; seatIndex++) {
          self.teams[seatIndex].seats.push(self.seats[seatIndex].id);
        }
      }
      else if(self.playersPerTeam === 2) {
        // seats 0 and 2 are on team 0
        self.teams[0].seats.push(self.seats[0].id);
        self.teams[0].seats.push(self.seats[2].id);

        // seats 1 and 3 are on team 1
        self.teams[1].seats.push(self.seats[1].id);
        self.teams[1].seats.push(self.seats[3].id);
      }
      else
        throw new Error("More then 2 players per team is not supported yet.");

      // Create all piles needed for Spades
      self.createPile("stock", "stock");
      self.createPile("trick", "trick");

      // create a 52 card deck in stock
      self.getPile("stock").createCards();
    }

    function createState(snapshot: any): Promise<boolean> {
      // call base class createState,  but remove options first, so that we can create TrickGameOptionsState below.
      let options = snapshot.options;
      delete snapshot.options;
      let promise = superCreateState(snapshot);

      self.options = TrickGameOptionsState.create(options);

      // if minPiles is set then move those cards from stock to hands, skipping normal shuffling and dealing
      // This is used for playing handChallenges standAlone
      if(snapshot.minPiles) {
        self.setStatus(GAME_STATE_DEAL);
        const stock = self.getPile("stock");
        Object.keys(snapshot.minPiles).forEach((minPileName) => {
          const pileName = self.pileNameFromMin(minPileName); // expand h0 to hand0
          const hand = self.getPile(pileName);

          // move pieces one at a time from stock to hand
          snapshot.minPiles[minPileName].p.forEach((pieceValue: any) => {
            let piece = stock.getTopPiece();
            self.movePieces("stock", pileName, [piece], FACING_DOWN, hand.seat, 0);
            // get piece we just added and set it's facing and value, we do this seperately from the move
            // so that it will be ran through filterPatch so that the local player will only see their cards
            let handPiece = hand.getTopPiece();
            handPiece.setFacing(FACING_UP);
            handPiece.setValue(pieceValue);
          });
        });

        // if minPiles is set then we skip the normal game start, so setup dealer and prepare for first round
        self.dealer = self.getSeat(snapshot.dealerSeatId); //snapshot.dealer;
        self.round = 1;
        self.addTrick();
        self.fillEmptySeats(null); // auto add bots
      }
      else
        self.setStatus(GAME_STATE_WAITING_FOR_PLAYERS);
      return promise;
    }

    function setApplySnapshotStage(state: ApplySnapshotStage) {
      superSetApplySnapshotStage(state);

      if(self.status === GAME_STATE_ROUND_OVER) {
        // If on offline game was just loaded in GAME_STATE_ROUND_OVER, we need to check if the game is over or move on to the next round
        gameOverOrNextRound();
      }
    }

    function startGame() {
      superStartGame();
      // Choose a random dealer, startRound will advance this to the next seat
      self.dealer = self.getRandomSeat();
      self.round = 0;
      self.startRound();
    }

    /** moves all pieces back to stock */
    function resetState() {
      superResetState();
      self.dealer = null;
      self.round = null;
      self.resetRound();
    }

    function deal() {
      self.setStatus(GAME_STATE_SHUFFLE);
      let stock = self.getPile("stock");
      stock.shuffle();
      if (stock.pieces.length !== 52) {
        logger.warn("Can't deal because all 52 cards are not in the stock!", { name: self.name, cardsInPile: stock.pieces.length });
        throw new Error("Can't deal because there aren't 52 cards in stock");
      }
      self.setStatus(GAME_STATE_DEAL);

      // There is a self.dealer now, but it shouldn't matter here
      let values: number[] = [];

      // deal 13 cards to each hand. We save the pieces values, and then set to 0 so that other seats don't recieve the value
      for (let seatIndex = 0; seatIndex < 4; seatIndex++) {
        let seat = self.seats[seatIndex];
        for (let cardIndex = 0; cardIndex < 13; cardIndex++) {
          let piece = stock.pieces[stock.pieces.length - 1];
          values.push(piece.value); // temporarily save piece value
          // move pieces replacing their value with 0
          self.movePieces("stock", "hand" + seatIndex, [piece], FACING_DOWN, seat, 0);
        }
      }

      // Start a new patchSet for sending values. We need to do that to ensure the client waits for the values before getting any other state changes
      // do not incPatchSetId if offline though, because patchSetId will get out of sync with server, and isn't needed when playing offline
      if(!self.offline)
        self.incPatchSetId();

      // now apply the temporary values to the pieces. The patches these generate will only be sent to the user in that seat's private channel
      for (let seatIndex = 0; seatIndex < 4; seatIndex++) {
        let hand = self.getPile("hand" + seatIndex);
        for (let cardIndex = 0; cardIndex < 13; cardIndex++) {
          let piece = hand.pieces[cardIndex];
          piece.setFacing(FACING_UP);
          piece.setValue(values.shift());
        }
      }

      // Start another new patchset for same reason as above.
      if(!self.offline)
        self.incPatchSetId();
    }

    function doTrickWon() {
      let seatWonTrick = self.getTrickWinner();
      self.scoreTrick(seatWonTrick);
      self.setTrickWinner(seatWonTrick);

      // we need to do the rest of the trick won in a seperate action so that the tutorial
      // can pause inbetween the trick winner being set and the trick cards being moved to the waste
      self.queueAction({ name: "finishTrickWon"});
    }

    function finishTrickWon() {
      let trickPile = self.getPile("trick");
      let seatWonTrick = self.getTrickWinner();
      self.movePieces(trickPile, "waste" + seatWonTrick.id, trickPile.pieces);

      // if seat 0 has more cards then continue playing
      if(self.getPile("hand0").pieces.length) {
        self.addTrick();

        // winner of trick leads next trick
        self.seatLead = seatWonTrick;
        self.setTrickLead(seatWonTrick);

        // seatsTurn triggers things, so set it last
        self.setSeatsTurn(seatWonTrick);
      }
      else { // else the round/hand is over
        self.scoreRound();
        self.setStatus(GAME_STATE_ROUND_OVER);

        // reset everyones turn timeouts at the end of a round, so they'll get the full timeout on score summary and bidding
        self.players.forEach((player) => {
          player.resetMissedTurns();
        });

        // Queue gameOverOrNextRound to support pausing at round over
        // OfflineSystem saves the game at this poing, so what ever gameOverOrNextRound does may not be saved, and would need to be done the next time the game is loaded
        // self.setApplySnapshotStage handles that by also calling gameOverOrNextRound
        self.queueAction({name: "gameOverOrNextRound" });
      }
    }

    function gameOverOrNextRound() {
      // check if game is over, else continue to next round
      if(self.isGameOver()) {
        self.doGameOver();
      }
      else {
        // queue resetRound and startRound so that singleplayer offline games can pause them while on the round summary screen.
        // This fixes hand challenge share images being black because the state was reset before they were generated
        self.queueAction({ name: "resetRound"});
        self.queueAction({ name: "startRound"});
      }
    }

    function clickPiece(playerId: string, seatId: string, pileName: string, pieceName: string) {
      if(seatId === null)
        throw new Error("clickPiece seatId is not set" + JSON.stringify({ playerId, seatId, pileName, pieceName }));

      let pile = self.getPile(pileName);
      if(!pile)
        throw new Error("clickPiece pile not found" + JSON.stringify({ playerId, seatId, pileName, pieceName }));

      let piece = pile.getPiece(pieceName);
      if(!piece)
        throw new Error("clickPiece piece not found" + JSON.stringify({ playerId, seatId, pileName, pieceName }));

      // double check that it is a valid move
      if(!self.canClickPiece(playerId, seatId, pile, piece, undefined, true)) {
        // it is a pretty serious error if the user is trying to click a piece and they can't. So raise an exception
        // to be sure it gets attention on both server and client
        throw new Error("clickPiece can't click " + JSON.stringify({ playerId, seatId, pileName, pieceName }));
      }

      logger.appendToGameLog(`play,${seatId},${piece.value},${piece.valueShortName()}`);

      // move card to trick
      if (pile.type === "hand") {
        self.movePieces(pile, "trick", [piece]);
        self.setTrickPiece(seatId, piece);

        // check if trump suit was just broken
        if(self.isProtectedPiece(piece) && !self.protectedSuitBroken) {
          // we must set both trumpSuitBroken and protectedSuitBroken until we are confident there are no more old clients playing that don't support protectedSuitBroken
          self.trumpSuitBroken = true;
          self.protectedSuitBroken = true;
        }

        // check if the trick is over, else move turn to next seat
        let trickPile = self.getPile("trick");
        if(trickPile.pieces.length === 4) {
          // Set seatsTurn null immediatly, then queue doTrickWon action. This fixes the last card played in a trick animation when playing offline games
          self.seatsTurn = null;
          self.queueAction({ name: "doTrickWon"});
        }
        else
          self.nextSeatsTurn(); //self.queueAction({ name: "nextSeatsTurn"})
      }
    }

    function setBid(playerId: string, seatId: string, bid: number) {
      let seat = self.getSeat(seatId);
      if(!self.canSetBid(playerId, seat, bid)) {
        throw new Error("setBid can't set bid " + JSON.stringify({ playerId, seatId, bid, status: self.status, seatsTurn: self.seatsTurn }));
      }

      seat.setBid(bid);

      logger.appendToGameLog(`bid,${seatId},${bid}`);

      // if all has bid then change state to playing
      if(self.hasAllBid()) {
        self.seatsTurn = null;
        self.setStatus(GAME_STATE_PLAY);
        let nextSeat = self.getNextSeat(self.dealer);
        self.seatLead = nextSeat;
        self.setTrickLead(nextSeat);
        // Setting seatsTurn trigger things, so do it last
        self.setSeatsTurn(nextSeat);
      }
      else
        self.nextSeatsTurn();
    }

    /** prepares state for taking a snapshot for a specific player.
     * It removes the value and sets cards facedown for all other players.
     */
    function prepForSnapshot(playerId?: string) {
      superPrepForSnapShot(playerId);

      let playerSeat: ISeatState;

      if(playerId) {
        playerSeat = self.getPlayerSeat(playerId);

        for (let seat of self.seats) {
          if(seat !== playerSeat) {
            let pile = self.getPile("hand" + seat.id);
            for(let piece of pile.pieces) {
              piece.facing = FACING_DOWN;
              piece.value = 0;
            }
          }
        }
      }
    }

    /** apply snapshot from client to sync offline game */
    function applySyncToServerSnapshot(userId: string, snapshot: any): boolean {
      // super function will verify if it is ok to apply snapshot
      if(!superApplySyncToServerSnapshot(userId, snapshot))
        return false;

      if(snapshot.status !== GAME_STATE_GAME_OVER) {
        self.dealer = snapshot.dealer;
        self.seatLead = snapshot.seatLead;
        self.round = snapshot.round;
        self.trumpSuit = snapshot.trumpSuit;
        self.trumpSuitBroken = snapshot.trumpSuitBroken;
        self.protectedSuitBroken = snapshot.protectedSuitBroken;

        // merge in all of piles and tricks
        if(snapshot.piles)
          applySnapshot(self.piles, snapshot.piles);

        if(snapshot.tricks)
          applySnapshot(self.tricks, snapshot.tricks);
      }

      return true;
    }

    return { afterCreate, applySyncToServerSnapshot, doTrickWon, finishTrickWon, gameOverOrNextRound, init, createState, startGame, resetState, deal, clickPiece, setBid, prepForSnapshot, setApplySnapshotStage };
  });

// TrickGameState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type ITrickGameState = typeof TrickGameState.Type;

export { TrickGameState, ITrickGameState };
