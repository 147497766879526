import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { Scene } from "@babylonjs/core/scene";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Ellipse } from "@babylonjs/gui/2D/controls/ellipse";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { SoundAnimationTrigger } from "components/game/SoundSystem";
import { AnimatableRectangle } from "components/ui/controls/AnimatableRectangle";
import { zIndex } from "components/utils/GUI";
import { loadImageAsyncWithFallback } from "components/utils/Image";

/**
 * Modified BABYLON GUI Ellipse control to draw a capsule shape instead of an ellipse.
 */
class Capsule extends Ellipse {
  // Ellipse: https://github.com/BabylonJS/js/blob/master/gui/src/2D/controls/ellipse.ts
  // Control.drawEllipse: https://github.com/BabylonJS/js/blob/master/gui/src/2D/controls/control.ts

  /**
   * Modified BABYLON GUI Ellipse button to draw a capsule shape instead of an ellipse.
   */
  protected static drawCapsule(x: number, y: number, width: number, height: number, context: CanvasRenderingContext2D): void {
    let middle = width - height;

    context.beginPath();
    context.arc(x - middle * 0.5, y, height, 90 * Math.PI / 180, 270 * Math.PI / 180);
    context.arc(x + middle * 0.5, y, height, 270 * Math.PI / 180, 90 * Math.PI / 180);
    context.closePath();
  }

  /**
   * Override the protected Ellipse._localDraw.
   * This copies the original contents, but replacing drawEllipse with drawCapsule
   */
  protected _localDraw(context: CanvasRenderingContext2D): void {
    context.save();

    if (this.shadowBlur || this.shadowOffsetX || this.shadowOffsetY) {
        context.shadowColor = this.shadowColor;
        context.shadowBlur = this.shadowBlur;
        context.shadowOffsetX = this.shadowOffsetX;
        context.shadowOffsetY = this.shadowOffsetY;
    }

    Capsule.drawCapsule(this._currentMeasure.left + this._currentMeasure.width / 2, this._currentMeasure.top + this._currentMeasure.height / 2,
        this._currentMeasure.width / 2 - this.thickness / 2, this._currentMeasure.height / 2 - this.thickness / 2, context);

    if (this._background) {
        context.fillStyle = this._background;

        context.fill();
    }

    if (this.shadowBlur || this.shadowOffsetX || this.shadowOffsetY) {
        context.shadowBlur = 0;
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
    }

    if (this.thickness) {
        if (this.color) {
            context.strokeStyle = this.color;
        }
        context.lineWidth = this.thickness;

        context.stroke();
    }

    context.restore();
  }
}

/**
 * Modified BABYLON GUI Ellipse control to draw a partial arc instead of a full circle (or ellipse)
 * The elipse draws from startAngle on the right, counter clockwise to endAngle, both angles in radians
 */
class PartialEllipse extends Ellipse {
  // Ellipse: https://github.com/BabylonJS/js/blob/master/gui/src/2D/controls/ellipse.ts
  // Control.drawEllipse: https://github.com/BabylonJS/js/blob/master/gui/src/2D/controls/control.ts

  startAngle: number = 0;
  endAngle: number = 2 * Math.PI;

  protected static drawPartialEllipse(x: number, y: number, width: number, height: number, startAngle: number, endAngle: number, context: CanvasRenderingContext2D): void {
    context.translate(x, y);
    context.scale(width, height);

    context.beginPath();
    context.arc(0, 0, 1, startAngle, endAngle);

    context.scale(1 / width, 1 / height);
    context.translate(-x, -y);
  }

  /**
   * Override the protected Ellipse._localDraw.
   * This copies the original contents, but replacing drawEllipse with drawPartialEllipse
   */
  protected _localDraw(context: CanvasRenderingContext2D): void {
    context.save();

    if (this.shadowBlur || this.shadowOffsetX || this.shadowOffsetY) {
        context.shadowColor = this.shadowColor;
        context.shadowBlur = this.shadowBlur;
        context.shadowOffsetX = this.shadowOffsetX;
        context.shadowOffsetY = this.shadowOffsetY;
    }

    PartialEllipse.drawPartialEllipse(this._currentMeasure.left + this._currentMeasure.width / 2, this._currentMeasure.top + this._currentMeasure.height / 2,
        this._currentMeasure.width / 2 - this.thickness / 2, this._currentMeasure.height / 2 - this.thickness / 2,
        this.startAngle, this.endAngle,
        context);

    if (this._background) {
        context.fillStyle = this._background;

        context.fill();
    }

    if (this.shadowBlur || this.shadowOffsetX || this.shadowOffsetY) {
        context.shadowBlur = 0;
        context.shadowOffsetX = 0;
        context.shadowOffsetY = 0;
    }

    if (this.thickness) {
        if (this.color) {
            context.strokeStyle = this.color;
        }
        context.lineWidth = this.thickness;

        context.stroke();
    }

    context.restore();
  }
}

/** TextBlock displaying an integer with an animatable value */
class IntegerTextBlock extends TextBlock {
  animatable: Animatable;

  constructor(public name?: string, value: number = 0) {
    super(name);
    this.value = value;
  }

  get value() {
    return parseInt(this.text);
  }

  set value(value: number) {
    let text = "" + Math.floor(value);
    if(this.text !== text)
      this.text = text;
  }

  stopAnimations() {
    if(this.animatable) {
      this.animatable.stop();
      this.animatable = null;
    }
  }

  dispose() {
    this.stopAnimations();
    super.dispose();
  }

  animate(fromValue: number, toValue: number, frames: number, scene: Scene) {
    this.stopAnimations();

    let a = new Animation(this.name + "ValueAnimation", "value", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = frames;
    keyFrames.push({frame: k0, value: fromValue});
    keyFrames.push({frame: k1, value: toValue});
    a.setKeys(keyFrames);

    this.animatable = scene.beginDirectAnimation(this, [a], 0, k1, false);

    let v1 = fromValue;
    let v2 = toValue;
    let tone = "highlightHigh";
    if(toValue < fromValue) {
      v1 = toValue;
      v2 = fromValue;
      tone = "highlightLow";
    }

    let soundTrigger = new SoundAnimationTrigger(tone);
    soundTrigger.initValue(v1);

    let soundAnimation = new Animation("SoundTrigger", "value", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let soundKeys = [];
    soundKeys.push({ frame: 0, value: v1 });
    soundKeys.push({ frame: k1, value: v2});
    soundAnimation.setKeys(soundKeys);
    this.animatable.appendAnimations(soundTrigger, [soundAnimation]);

    return this.animatable;
  }
}

class TextDialog extends Rectangle {
  _textBlock: TextBlock;

  constructor(public name: string, width: number, height: number, text: string = "") {
    super(name);

    this.width = width + "px";
    this.height = height + "px";
    this.background = "white";
    this.color = "black";
    this.zIndex = zIndex.GAME_DIALOG;
    this.isPointerBlocker = true;
    this.onPointerClickObservable.add(() => this.dispose());

    let t = new TextBlock(name + "TextBlock");
    t.text = text;
    this.addControl(t);
    this._textBlock = t;
  }

  get textBlock() {
    return this._textBlock;
  }
}

/**
 * Workaround for NS_ERROR_NOT_AVAILABLE
 * In Image, if you change the source url before the previous url is loaded, for example:
 *   let image = new Image("image", url);
 *   image.source = differentUrl
 * Image will still be listening to the 1st url for onLoaded, and will set the "loaded" flag even though the current url is not loaded
 * This results in the unhelpful NS_ERROR_NOT_AVAILABLE error.
 * Here we try to be more careful about changing the source url
 *
 * Also, if you call setSource, you can provide a fallback image in case loading fails
 */
class ImageWithFallback extends Image {
  originalSource: string;
  loadId = 0;

  constructor(public name?: string, url: string = null, fallbackUrl: string = null) {
    super(name); // NOTE: this will call set source(null)

    if(url)
      this.setSource(url, fallbackUrl);
  }

  public set source(value: string) {
    this.setSource(value);
  }

  setSource(value: string, fallbackUrl?: string) {
    if(value === this.originalSource)
      return;

    this.originalSource = value;

    this.loadId += 1;
    let loadId = this.loadId;

    loadImageAsyncWithFallback(value, fallbackUrl).then((image) => {
      if(image === null || this.loadId !== loadId)
        return;

      this.domImage = image;
    });
  }

  dispose() {
    this.loadId = null;
    super.dispose();
  }
}

class ProgressBar extends Rectangle {
  bar: AnimatableRectangle;
  text: TextBlock;

  constructor(name: string) {
    super(name);

    this.background = "#3609cd";
    this.thickness = 0;

    this.bar = new AnimatableRectangle("UserStatusGUIUserLevelBarProgress");
    this.bar.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.bar.left = 0;
    this.bar.background = "#03b6dd";
    this.bar.thickness = 0;
    this.addControl(this.bar);

    this.text = new TextBlock("UserStatusGUIUserLevelBarProgressText");
    this.text.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.text.color = "white";
    this.addControl(this.text);
  }

  setProgress(progress: number) {
    this.bar.width = (this.widthInPixels * progress) + "px";
  }

  animateProgress(fromProgress: number, toProgress: number, scene: Scene): Animatable {
    let oldWidth = this.widthInPixels * fromProgress;
    let newWidth = this.widthInPixels * toProgress;
    if(!scene) {
      this.bar.width = newWidth + "px";
      return null;
    }

    let a = new Animation(this.name, "animatableWidth", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = 10;
    keyFrames.push({frame: k0, value: oldWidth});
    keyFrames.push({frame: k1, value: newWidth});
    a.setKeys(keyFrames);

    let animatable = scene.beginDirectAnimation(this.bar, [a], 0, k1, false);

    let v1 = oldWidth;
    let v2 = newWidth;

    let soundTrigger = new SoundAnimationTrigger("highlightHigh");
    soundTrigger.initValue(v1);

    let soundAnimation = new Animation("SoundTrigger", "value", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let soundKeys = [];
    soundKeys.push({ frame: 0, value: v1 });
    soundKeys.push({ frame: k1, value: v2});
    soundAnimation.setKeys(soundKeys);
    animatable.appendAnimations(soundTrigger, [soundAnimation]);

    return animatable;
  }
}

export { Capsule, PartialEllipse, IntegerTextBlock, TextDialog, ImageWithFallback, ProgressBar };
