import { types } from "mobx-state-tree";

import { StateSyncUserMiddleware } from "states/state-sync/StateSyncUserMiddleware";
import { UserState } from "./UserState";

// tslint:disable-next-line:variable-name
const UsersState = types
  .model("UsersState", {
    users: types.map(UserState),
  })
  .actions((self) => {
    let middlewares: {[index: string]: StateSyncUserMiddleware} = {}; // we have to maintain a list of StateSyncUserMiddleware's for each UserState in self.users

    /** look up a user by userId. If we haven't loaded that user from the server yet, it will do it. The initial UserState object returned will be empty. Subscribe to patches to get notification of data changing.
     * This is not used for getting the local user, it is used for loading other user details for things like leaderboard and PlayerInfoPopUp
     */
    function getUser(userId: string) {
      // first look for existin guser in self.users
      let user = self.users.get(userId);

      // doesn't exist so create one and load from server
      if(!user) {
        user = UserState.create({});
        middlewares[userId] = new StateSyncUserMiddleware(user);
        user.setId(userId); // setId will trigger the UserState to load itself from server
        self.users.set(user.id, user);
      }
      return user;
    }

    return { getUser };
  });

// UsersState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IUsersState = typeof UsersState.Type;

export { UsersState, IUsersState };
