import { types } from "mobx-state-tree";

export const PAYMENT_TYPE_FACEBOOKIG = "facebookIG";
export type PaymentTypeFacebookIG = typeof PAYMENT_TYPE_FACEBOOKIG;
//const paymentTypes = [PAYMENT_TYPE_FACEBOOKIG];

// tslint:disable-next-line:variable-name
const PaymentItemState = types
  .model("PaymentItemState", {
    paymentId: types.string,  // payment provider's id, not ours.
    paymentType: types.optional(types.enumeration("PaymentType", [PAYMENT_TYPE_FACEBOOKIG]), PAYMENT_TYPE_FACEBOOKIG),
    signedRequest: types.string,

    /* Example facebook paymentData in signedRequest: {
        algorithm: 'HMAC-SHA256',
        amount: '2.99',
        currency: 'USD',
        developer_payload: null,
        is_consumed: false,
        issued_at: 1562875270,
        payment_id: '1553424834788402',
        product_id: 'insta_pass_1_month',
        purchase_time: 1562875265,
        purchase_token: '10216402520508844',
        quantity: '1',
        request_id: '',
        status: 'completed'
      }
    */
  });

// PaymentItemState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IPaymentItemState = typeof PaymentItemState.Type;

export { PaymentItemState, IPaymentItemState };
