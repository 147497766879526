import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { PowerEase } from "@babylonjs/core/Animations/easing";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";

import { UpAndOverAnimation } from "components/game/animations/UpAndOverAnimation";
import { ANIMATION_MOVE_PIECE } from "components/game/AnimationSystem";
import { ArgoSystem } from "components/game/ArgoSystem";
import { SoundAnimationTrigger } from "components/game/SoundSystem";
import { TrickGame } from "components/game/TrickGame";
import { ITrickGameState } from "states/game/TrickGameState";

export class TrickGameAnimation extends ArgoSystem {
  upAndOverAnimation: UpAndOverAnimation;

  init() {
    this.upAndOverAnimation = this.game.systems.get("UpAndOverAnimation") as UpAndOverAnimation;
    this.game.animationSystem.registerAnimation(ANIMATION_MOVE_PIECE, (mesh) => this.buildAnimation(mesh));
  }

  buildAnimation(mesh: AbstractMesh) {
    let fromName = mesh.metadata.fromParentName;
    let toName = mesh.parent.name;

    if(fromName === "trick" && toName.substring(0, 5) === "waste") {
      // Special case the trick -> waste animation
      return this.buildTrickWasteAnimation(mesh);
    } else {
      let beginSound = "";
      let sparkle = false;
      let endSound = "";

      // We want to sparkle the card edge if the card is currently winning the trick
      if(fromName) {
        endSound = "playCard";
        if(toName === "trick") {
          let trickGameState = this.game.gameState as ITrickGameState;
          let winningCount = trickGameState.getWinningCountForPiece(mesh.name);
          if(winningCount > 0)
            endSound = "trickWinning" + winningCount;
          if(winningCount > 1)
              sparkle = true;
        }
      }

      let animatable = this.upAndOverAnimation.buildMovePieceAnimation(mesh, beginSound);

      if(sparkle || endSound) {
        animatable.onAnimationEndObservable.add( () => {
          if(sparkle)
            this.game.cardSystem.sparkleEdge(mesh);
          if(endSound)
            this.game.soundSystem.play(endSound);
        });
      }

      // When a card finishes playing to the trick, check for highlighting the winning card
      if(fromName && toName === "trick")
        animatable.onAnimationEndObservable.add(() => (this.game as TrickGame).onCardAnimatedToTrick());

      return animatable;
    }
}

  /**
   * Special animation for moving card from the Trick to a Waste pile
   * We'll scootch the cards towards the winning player, pause, then swoop down to the waste pile
   */
  buildTrickWasteAnimation(mesh: AbstractMesh): Animatable {
    //console.log("animate " + mesh.metadata.layoutGameStatus + " " + mesh.name + " from " + mesh.metadata.fromParentName + " " + mesh.absolutePosition + " to " + mesh.parent.name + " " + mesh.metadata.layoutPosition);
    let position = mesh.metadata.layoutPosition;

    let position0: Vector3 = mesh.position;
    let position1: Vector3 = position;

    let direction = position1.subtract(position0).normalize();

    let k0 = Math.floor(Math.random() * 3);
    let k1 = k0 + 30;
    let k2 = k1 + 10;

    let positionAnimation = new Animation("PositionMesh", "position", 30.0, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let positionKeys = [];

    if(k0)
      positionKeys.push({ frame: 0, value: position0 });
    positionKeys.push({ frame: k0, value: position0 });
    positionKeys.push({ frame: k1, value: position0 });
    positionKeys.push({ frame: k2, value: position1 });

    positionAnimation.setKeys(positionKeys);
    mesh.animations.push(positionAnimation);

    let easingFunction = new PowerEase();
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEIN);
    positionAnimation.setEasingFunction(easingFunction);

    let alphaAnimation = new Animation("FadeMesh", "visibility", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let alphaKeys = [];
    alphaKeys.push({ frame: 0, value: 1.0 });
    alphaKeys.push({ frame: k1, value: 1.0 });
    alphaKeys.push({ frame: k1 + 5, value: 1.0});
    alphaKeys.push({ frame: k2, value: 0.0 });
    alphaAnimation.setKeys(alphaKeys);
    mesh.animations.push(alphaAnimation);

    let speed = 1.0;

    //speed = 0.25;

    let animatable = this.game.animationSystem.begin(mesh, 0, k2, false, speed);

    // Create a second animation to play the whisk sound at the appropriate keyframe
    let soundTrigger = new SoundAnimationTrigger("trickWon");

    let soundAnimation = new Animation("SoundTrigger", "value", 30.0, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let soundKeys = [];
    soundKeys.push({ frame: 0, value: 0.0 });
    soundKeys.push({ frame: k1, value: 1.0});
    soundAnimation.setKeys(soundKeys);
    animatable.appendAnimations(soundTrigger, [soundAnimation]);

    // If the card was highlighted, remove the highlight
    if(this.game.cardSystem.isHighlighted(mesh))
      animatable.onAnimationEndObservable.add(() => this.game.cardSystem.setHighlighted(mesh, false));

    return animatable;
  }
}
