import { mergeConfig } from "utils/Config";

mergeConfig({
  gameOptions: {
    default: {}, // will be set by game/platform specific game.config.ts
    quickPlay: {
      name: "quickPlay",
      maxRounds: 1,
      multiplayer: true,
      allowFriends: true,
      allowAnyone: false,
      turnTimeoutPlay: 20,
      turnTimeoutNewRound: 45,
    },
    quickPlayStandAlone: {
      name: "quickPlayStandAlone",
      maxRounds: 1,
      multiplayer: false,
      standAlone: true,
      allowFriends: false,
      allowAnyone: false,
    },
    classicStandAlone: {
      name: "classicStandAlone",
      endScore: 500,
      multiplayer: false,
      standAlone: true,
      allowFriends: false,
      allowAnyone: false,
    },
    classic: {
      name: "classic",
      endScore: 100,
      multiplayer: true,
      allowFriends: true,
      allowAnyone: false,
      turnTimeoutPlay: 20,
      turnTimeoutNewRound: 45,
    },
    handChallenge: {
      name: "handChallenge",
      maxRounds: 1,
      multiplayer: false,
      standAlone: true,
      allowFriends: false,
      allowAnyone: false,
      turnTimeoutPlay: 20,
      turnTimeoutNewRound: 45,
    },
    tutorial: {
      name: "tutorial",
      maxRounds: 2,
      standAlone: true,
      multiplayer: false,
      allowFriends: false,
      allowAnyone: false,
    },
  },
});
