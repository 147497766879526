import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { config } from "utils/Config";

import { game } from "components/game/Game";
import { GUIAnimationProxy } from "components/utils/GUIAnimationProxy";

/**
 * FxButton
 * 3 layer button, background, middle, foreground, with a label below
 * The middle button has built-in support for animation
 * The height is 90% image, 10% label
 * The width must be set wide enough for the label text to fit
 */
export class FxButton extends Button {
  imageContainer: Rectangle;
  backgroundImage: Image;
  middleImage: Image;
  foregroundImage: Image;
  label: TextBlock;

  scaleAnimation: Animatable = null;
  middleImageCellAnimation: Animatable = null;
  middleImageRotationAnimation: Animatable = null;

  backgroundAspectRatio = 1.0;
  middleAspectRatio = 1.0;
  foregroundAspectRatio = 1.0;

  constructor(name: string) {
    super(name);

    //this.background = "magenta";
    this.thickness = 0;

    this.imageContainer = new Rectangle("InviteButton");
    this.imageContainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.imageContainer.thickness = 0;
    this.addControl(this.imageContainer);

    this.backgroundImage = new Image("InviteButtonBackground");
    this.imageContainer.addControl(this.backgroundImage);

    this.middleImage = new Image("InviteButtonMiddle");
    this.imageContainer.addControl(this.middleImage);

    this.foregroundImage = new Image("InviteButtonImage");
    this.imageContainer.addControl(this.foregroundImage);

    this.label = new TextBlock("InviteButtonLabel");
    this.label.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.label.fontFamily = config.fontFamily;
    this.label.fontWeight = config.fontWeight;
    this.label.text = "";
    this.addControl(this.label);

    this.layout();

    // Animations
    // The default pointerDownAnimation and pointerUpAnimation modify scale
    // It should be possible to mess things up and end up with the button scaled down permanently, but it also shouldn't be a big deal
    // If it is we can try to deal with it using custom animations

    // The default pointerEnterAnimation and pointerOutAnimation modify alpha
    // If pointerEnter happens while alpha is being animated (PlayerGUIObject animateIn, for),
    // Babylon.Button saves a partially transparent alpha then restors it on pointerOut
    // So we need a custom animation to compensate.  Note that Babylon.Button doesn't actually animate alpha, and neither do these functions
    this.pointerEnterAnimation = () => {
      if(this.alpha === 1.0)
        this.alpha = 0.9;
    };

    this.pointerOutAnimation = () => {
      // unconditionally set full alpha
      this.alpha = 1.0;
    };
  }

  /** Set the background image */
  setBackgroundImage(url: string, aspectRatio = 1.0) {
    this.backgroundImage.source = url;
    this.backgroundAspectRatio = aspectRatio;
    this.layout();
  }

  /** Set the middle image, optionally with animation parameters */
  setMiddleImage(url: string, aspectRatio = 1.0, cellWidth = 0, cellHeight = 0, cellCount = 0, durationInSeconds = 0) {
    this.middleImage.source = url;
    this.middleAspectRatio = aspectRatio;
    this.layout();

    if(this.middleImageCellAnimation) {
      this.middleImageCellAnimation.stop();
      this.middleImageCellAnimation = null;
    }

    if(cellWidth && cellHeight && cellCount && durationInSeconds) {
      this.middleImage.cellId = 0;
      this.middleImage.cellWidth = cellWidth;
      this.middleImage.cellHeight = cellWidth;

      let proxy = new GUIAnimationProxy(this.middleImage);
      this.middleImageCellAnimation = proxy.animateFloat("cellId", 0, cellCount - 1, durationInSeconds, true, false, game.scene);

      // Randomize the animation start
      this.middleImageCellAnimation.goToFrame(Math.floor(Math.random() * cellCount));
    }
  }

  /** Set the foreground image */
  setForegroundImage(url: string, aspectRatio = 1.0) {
    this.foregroundImage.source = url;
    this.foregroundAspectRatio = aspectRatio;
    this.layout();
  }

  /** Set the text for the label under the image */
  setLabel(text: string) {
    this.label.text = text;
  }

  /** Relayout the image & label based on the current FxButton size */
  layout() {
    let boxWidth = this.widthInPixels;
    let boxHeight = this.heightInPixels;

    let labelHeight = boxHeight * 0.1;

    let imageHeight = boxHeight - labelHeight;
    let bgWidth = imageHeight * this.backgroundAspectRatio;
    let midWidth = imageHeight * this.middleAspectRatio;
    let fgWidth = imageHeight * this.foregroundAspectRatio;

    this.imageContainer.width = bgWidth + "px";
    this.imageContainer.height = imageHeight + "px";

    this.backgroundImage.width = bgWidth + "px";
    this.backgroundImage.height = imageHeight + "px";

    this.middleImage.width = midWidth + "px";
    this.middleImage.height = imageHeight + "px";

    this.foregroundImage.width = fgWidth + "px";
    this.foregroundImage.height = imageHeight + "px";

    this.label.width = boxWidth + "px";
    this.label.height = labelHeight + "px";
    this.label.fontSize = (labelHeight * 0.8) + "px";
  }

  /** Animate rotating the middle 360 degrees */
  animateMiddleRotation(durationInSeconds: number, loop: boolean) {
    if(this.middleImageRotationAnimation)
      this.middleImageRotationAnimation.stop();

    let proxy = new GUIAnimationProxy(this.middleImage);
    return this.middleImageRotationAnimation = proxy.animateFloat("rotation", 0, Math.PI * 2.0, durationInSeconds, loop, false, game.scene);
   }

   /** Animate the button's scale, with an elastic bounce */
   animateScale(from: number, to: number, durationInSeconds: number) {
    if(this.scaleAnimation)
      this.scaleAnimation.stop();

    let proxy = new GUIAnimationProxy(this);
    return this.scaleAnimation = proxy.animateFloat("scale", from, to, durationInSeconds, false, true, game.scene);
   }

  /** Stop all animations (that FxButton knows about) */
  stopAnimations() {
    if(this.scaleAnimation) {
      this.scaleAnimation.stop();
      this.scaleAnimation = null;
    }

    if(this.middleImageCellAnimation) {
      this.middleImageCellAnimation.stop();
      this.middleImageCellAnimation = null;
    }

    if(this.middleImageRotationAnimation) {
      this.middleImageRotationAnimation.stop();
      this.middleImageRotationAnimation = null;
    }
  }

  /** dispose the control & remove circular references */
  dispose() {
    super.dispose();

    this.stopAnimations();

    this.imageContainer = null;
    this.backgroundImage = null;
    this.middleImage = null;
    this.foregroundImage = null;
    this.label = null;
  }
}
