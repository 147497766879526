import { applySnapshot, detach, types } from "mobx-state-tree";

import { EntryState, IEntryState } from "./EntryState";

// tslint:disable-next-line:variable-name
const LeaderboardState =  types
  .model("LeaderboardState", {
    name: types.string,
    entries: types.array(EntryState),
  })
  .actions((self) => {

    /** Imports array of leaderboard EntryStates */
    function importEntries(entrySnapshots: IEntryState[]) {
      // remove any entries that are not in the new snapshot
      for(let entry of self.entries) {
        if(!entrySnapshots.find((entrySnapshot) => entrySnapshot.serviceUserId === entry.serviceUserId))
          detach(entry);
      }

      // update/add new entries from snapshot
      for(let entrySnapshot of entrySnapshots) {
        let entry = self.entries.find((checkEntry) => checkEntry.serviceUserId === entrySnapshot.serviceUserId);
        if(entry)
          applySnapshot(entry, entrySnapshot);
        else {
          entry = EntryState.create(entrySnapshot);
          self.entries.push(entry);
        }
      }
    }

    return { importEntries };
  })
  .views((self) => {
    function getTopEntries(count: number, localUserId?: string) {
      // Find the top players
      let top: IEntryState[] = [null, null, null];
      let foundLocalPlayerInTop = false;
      for(let entry of self.entries) {
        if(entry.rank >= 1 && entry.rank <= count) {
          top[entry.rank - 1] = entry;
          if(localUserId && entry.userId === localUserId)
            foundLocalPlayerInTop = true;
        }
      }

      // If we didn't find the local player in the top, find the local entry and insert it into last place
      if(localUserId && !foundLocalPlayerInTop) {
        let index = count - 1;
        if(top[0] === null)
          index = 0;
        else if(top[1] === null)
          index = 1;

        // find the player's entry
        for(let entry of self.entries) {
          if(entry.userId === localUserId) {
            top[index] = entry;
            break;
          }
        }
      }

      return top;
    }

    return { getTopEntries };
  });

// LeaderboardState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type ILeaderboardState = typeof LeaderboardState.Type;

export { LeaderboardState, ILeaderboardState };
