import { PickingInfo } from "@babylonjs/core/Collisions/pickingInfo";
import { PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { VertexData } from "@babylonjs/core/Meshes/mesh.vertexData";
import { Scene } from "@babylonjs/core/scene";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Control } from "@babylonjs/gui/2D/controls/control";

/**
 * Creates a 1x1 3d square with an AdvancedDynamicTexture
 * If the texture is not square, the longest dimension will be 1.0, and the shortest dimension will be smaller to preserve aspect ratio
 */
export class Control3d extends Mesh {
  guiWidth = 512;
  guiHeight = 512;
  guiTexture: AdvancedDynamicTexture;

  constructor(name: string, scene: Scene = null, guiWidth = 512, guiHeight = 512) {
    super(name, scene);

    this.guiWidth = guiWidth;
    this.guiHeight = guiHeight;

    let width = 1.0;
    let height = 1.0;

    if(guiWidth > guiHeight) {
      // Landscape
      height = guiHeight / guiWidth;
    } else if(guiHeight > guiWidth) {
      // Portrait
      width = guiWidth / guiHeight;
    } else {
      // Square, leave it at 1.0 x 1.0
    }

    // Create geometry
    let vertexData = VertexData.CreatePlane({width, height});
    vertexData.applyToMesh(this, false);

    // Create GUI Texture
    this.guiTexture = new AdvancedDynamicTexture(name + "GuiTexture", this.guiWidth, this.guiHeight, this.getScene());

    // Create Material
    let material = new StandardMaterial(name + "Material", this.getScene());
    this.material = material;
    material.diffuseTexture = this.guiTexture;
    material.opacityTexture = this.guiTexture; // Needed to get smooth results with transparent textures
  }

  addControl(control: Control) {
    this.guiTexture.addControl(control);
  }

  onPointer(pointerInfo: PointerInfo, pickInfo: PickingInfo) {
    let pos = pickInfo.getTextureCoordinates();

    let x = Math.floor(pos.x * this.guiWidth);
    let y = Math.floor((1.0 - pos.y) * this.guiHeight);
    let type = pointerInfo.type;
    let pointerId = (pointerInfo.event as PointerEvent).pointerId;
    let buttonIndex = pointerInfo.event.button;

    // Call hidden public function!
    this.guiTexture.rootContainer._processPicking(x, y, pointerInfo, type, pointerId, buttonIndex);
  }

  dispose(doNotRecurse?: boolean, disposeMaterialAndTextures?: boolean) {
    super.dispose(doNotRecurse, disposeMaterialAndTextures);

    if(this.guiTexture) {
      this.guiTexture.dispose();
      this.guiTexture = null;
    }
  }
}
