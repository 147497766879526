import { Control } from "@babylonjs/gui/2D/controls/control";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";

import { game } from "components/game/Game";
import { ScrollBox } from "components/ui/controls/ScrollBox";
import { LeaderboardPlayer, LeaderboardPlayerWide } from "components/ui/LeaderboardPlayer";
import { getRootState } from "states/RootState";
import { config } from "utils/Config";

export class LeaderboardList extends ScrollBox {
  stack: StackPanel;
  leaderboardRoute: number;
  userRoute: number;
  highlightLocalUser = false;

  constructor(name: string, highlightLocaluser: boolean) {
    super(name);

    this.highlightLocalUser = highlightLocaluser;

    //this.background = "magenta";

    this.stack = new StackPanel(name + "StackPanel");
    this.stack.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.addControl(this.stack);

    this.update();

    this.leaderboardRoute = getRootState().router.addRoute("^\/leaderboards\/boards\/(\\d*)\/entries\/(\\d*)", (patch: any, reversePatch: any, params: any) => this.onLeaderboardEntryChanged(patch, reversePatch, params));
    this.userRoute = getRootState().router.addRoute("^\/users\/users\/", (patch: any, reversePatch: any, params: any) => this.onUserChanged(patch, reversePatch, params));
  }

  scrollToLocalUser() {
    let localUserId = getRootState().user.id;
    let lineHeight = 60 * game.getScale();

    let y = 0;
    for(let child of this.stack.children) {
      let player = child as LeaderboardPlayer;
      if(player.userId === localUserId) {
        this.centerOnPoint(0, y);
      }
      y += lineHeight;
    }
  }

  layout() {
    let lineHeight = 60 * game.getScale();

    for(let child of this.stack.children) {
      let player = child as LeaderboardPlayer;
      player.setLayoutHeight(lineHeight);
    }

    let width = lineHeight * 8;
    let height = lineHeight;

    if(this.stack.children.length) {
      let player = this.stack.children[0] as LeaderboardPlayer;
      width = player.widthInPixels;
      height = player.heightInPixels * this.stack.children.length;
    }

    this.stack.width = width + "px";
    this.stack.height = height + "px";
  }

  update() {
    let board = game.rootState.leaderboards.getLeaderboard(config.defaultLeaderboard);
    if(!board)
      return;

    // Sort by rank
    // Normally, the data from Facebook is sorted by rank, but if there's a change in rank, the order is not updated
    let sortedEntries = board.entries.slice();
    sortedEntries.sort((a, b) => a.rank - b.rank);

    let localUserId = getRootState().user.id;

    for(let playerIndex = 0; playerIndex < sortedEntries.length; playerIndex++) {
      let entry = sortedEntries[playerIndex];

      let player = this.stack.children[playerIndex] as LeaderboardPlayer;
      if(!player) {
        player = new LeaderboardPlayerWide("LeaderboardSidebarGUIPlayer_" + playerIndex);
        this.stack.addControl(player);
      }

      player.isVisible = true;
      player.setRank(entry.rank);
      player.setPlayerName(entry.name);
      player.setImage(entry.imageUrl);
      player.setScore(entry.score);
      player.setScoreColor("white");
      player.setLevel(0);
      player.setUserId(entry.userId);

      if(entry.userId) {
        let user = game.rootState.users.getUser(entry.userId);
        if(user)
          player.setLevel(user.xp.level);

        if(this.highlightLocalUser && entry.userId === localUserId) {
          player.setScoreColor(config.scoreHighlightColor, config.scoreHighlightShadowBlur, config.scoreHighlightShadowColor);
        }
      }
    }

    // If the player count went down for some reason, remove player objects
    while(this.stack.children.length > sortedEntries.length)
      this.stack.children[this.stack.children.length - 1].dispose();

    // We need to layout after an update to layout any newly created player objects
    this.layout();
  }

  onLeaderboardEntryChanged(patch: any, reversePatch: any, params: any) {
    this.update();
  }

  onUserChanged(patch: any, reversePatch: any, params: any) {
    this.update();
  }

  dispose() {
    super.dispose();
    this.stack = null;

    if(this.leaderboardRoute) {
      getRootState().router.removeRoute(this.leaderboardRoute);
      this.leaderboardRoute = null;
    }

    if(this.userRoute) {
      getRootState().router.removeRoute(this.userRoute);
      this.userRoute = null;
    }
  }
}
