import { Button } from "@babylonjs/gui/2D/controls/button";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { game } from "components/game/Game";
import { Screen } from "components/ui/controls/Screen";
import { findGuiControl } from "components/utils/GUI";
import { config } from "utils/Config";

/**
 * Base class for full screen GUI pop ups with a footer
 * The footer is designed to mirror the UserStatus bar at the top of the screen
 */
export class ScreenWithFooter extends Screen {
  center: Container;
  footer: Rectangle;
  footerBackground: Rectangle;
  footerPanel: StackPanel;

  constructor(name: string) {
    super(name);

    this.center = new Container(name + "Center");
    this.addControl(this.center);

    this.footer = new Rectangle(name + "Footer");
    this.footer.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.footer.thickness = 0;
    this.addControl(this.footer);

    this.footerBackground = new Rectangle(name + "FooterBackground");
    this.footerBackground.background = "black";
    this.footerBackground.thickness = 0;
    this.footerBackground.alpha = 0.5;
    this.footer.addControl(this.footerBackground);

    this.footerPanel = new StackPanel(name + "FooterPanel");
    this.footerPanel.isVertical = false;
    this.footerPanel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.footer.addControl(this.footerPanel);

    // Private layout because subclasses won't be ready yet
    this.privateLayout();
  }

  addDarkBackground() {
    let centerBackground = new Rectangle(name + "CenterBackground");
    centerBackground.background = "black";
    centerBackground.alpha = 0.25;
    centerBackground.thickness = 0;
    this.center.addControl(centerBackground);
  }

  addFooterButton(name: string, label: string, iconUrl: string, onClick: () => void) {
    // The Babylon GUI ImageButton doesn't layout like we want, so we'll make a blank button and add our own children to it
    let button = new Button(name);
    button.color = config.screenFooterButtonTextColor;
    button.background = config.screenFooterButtonBackgroundColor;
    button.thickness = 0;
    button.onPointerClickObservable.add(onClick);
    this.footerPanel.addControl(button);

    let stack = new StackPanel(name + "Stack");
    stack.isVertical = false;
    button.addControl(stack);

    let text = new TextBlock(name + "Text");
    text.fontFamily = config.fontFamily;
    text.fontWeight = config.fontWeight;
    text.text = label;
    stack.addControl(text);

    if(iconUrl) {
      let image = new Image(name + "Image", iconUrl);
      stack.addControl(image);
    }

    this.privateLayout();

    return button;
  }

  private privateLayout() {
    let menuBar = findGuiControl("menuBar");
    let menuBarHeight = menuBar ? menuBar.heightInPixels : 0;

    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height - menuBarHeight;

    let k = 8; // "k" is basically how many player boxes should fit vertically on the screen, so bigger k for smaller boxes
    let height = Math.min(guiWidth / k / 2, guiHeight / k);

    let footerWidth = guiWidth;
    let footerHeight = Math.floor(guiHeight / 7);

    let centerWidth = guiWidth;
    let centerHeight = guiHeight - footerHeight * 2;

    let buttonWidth = height * 2.8;
    let buttonHeight = height * 0.75;
    let buttonFontHeight = buttonHeight * 0.8;
    let buttonPadding = buttonHeight * 0.5;

    let imageWidth = buttonHeight;

    this.center.width = centerWidth + "px";
    this.center.height = centerHeight + "px";

    this.footer.width = footerWidth + "px";
    this.footer.height = footerHeight + "px";

    for(let button of this.footerPanel.children) {
      if(button instanceof Button) {
        let textBlock = findGuiControl(button.name + "Text", button) as TextBlock;
        let image = findGuiControl(button.name + "Image", button);

        let width = buttonWidth;

        // Babylon won't tell us the width of the text, but we can get it from the canvas context
        let ctx = game.guiTexture.getContext();
        ctx.font = config.fontWeight + " " + buttonFontHeight + "px " + config.fontFamily;
        let metrics = ctx.measureText(textBlock.text);

        textBlock.width = metrics.width + "px";
        textBlock.fontSize = buttonFontHeight + "px";

        width = metrics.width + buttonPadding * 4;

        if(image) {
          image.width = imageWidth + "px";
          image.height = imageWidth + "px";

          width += imageWidth;
        }

        button.width = width + "px";
        button.height = buttonHeight + "px";
        button.paddingLeft = buttonPadding + "px";
        button.paddingRight = buttonPadding + "px";
        button.cornerRadius = height / 8;
      }
    }
   }

  layout() {
    super.layout();
    this.privateLayout();
  }

  dispose() {
    super.dispose();

    this.center = null;

    this.footer = null;
    this.footerBackground = null;
    this.footerPanel = null;
    }
}
