import { Button } from "@babylonjs/gui/2D/controls/button";
import { Checkbox } from "@babylonjs/gui/2D/controls/checkbox";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import { config } from "utils/Config";

class Buttons {
    /**
     * Creates a new button made with an image and a text
     * The image is expected to be square
     * Text is placed to the right of the image
     * Text font size is 80% of the height
     * The button defaults to no outline and white text
     * @param name defines the name of the button
     * @param text defines the text of the button
     * @param imageUrl defines the url of the image
     * @param height sets the height for the button, image and text
     * @returns a new Button
     */
    public static CreateMenuImageButton(name: string, text: string, imageUrl: string, height: number): Button {
        let button = new Button(name);
        button.color = config.menuTextColor;
        button.thickness = 0;
        button.fontSize = (height * 0.8) + "px";
        button.height = height + "px";

        // Adding image
        let iconImage = new Image(name + "_icon", imageUrl);
        iconImage.width = height + "px";
        iconImage.height = height + "px";
        iconImage.stretch = Image.STRETCH_UNIFORM;
        iconImage.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        button.addControl(iconImage);

        // Adding text
        let textBlock = new TextBlock(name + "_button", text);
        textBlock.textWrapping = true;
        textBlock.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
        textBlock.left = (height + 5) + "px";
        button.addControl(textBlock);

        return button;
    }

    /**
     * Creates a new button made with a checkbox and a text
     * The checkbox is sized like CreateMenuImageButton
     * Text is placed to the right of the checkbox
     * Text font size is 80% of the height
     * The button defaults to no outline and white text
     * @param name defines the name of the button
     * @param text defines the text of the button
     * @param height sets the height for the button, image and text
     * @returns a new Button
     */
    public static CreateMenuCheckboxButton(name: string, text: string, isChecked: boolean, height: number, callback: (value: boolean) => void): Button {
      let button = new Button(name);
      button.color = config.menuTextColor;
      button.thickness = 0;
      button.fontSize = (height * 0.8) + "px";
      button.height = height + "px";

      let checkHeight = height * 0.75;
      let checkLeft = (height - checkHeight) * 0.5;

      // Adding checkbox
      let checkbox = new Checkbox(name + "_checkbox");
      checkbox.background = config.menuTextColor;
      checkbox.color = config.menuCheckboxCheckedColor;
      checkbox.left = checkLeft;
      checkbox.width = checkHeight + "px";
      checkbox.height = checkHeight + "px";
      checkbox.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      checkbox.isChecked = isChecked;
      checkbox.onIsCheckedChangedObservable.add(callback);
      button.addControl(checkbox);

      // Adding text
      let textBlock = new TextBlock(name + "_button", text);
      textBlock.textWrapping = true;
      textBlock.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      textBlock.left = (height + 5) + "px";
      button.addControl(textBlock);

      // The button takes over the pointer, so we'll toggle the button here
      button.onPointerUpObservable.add(() => checkbox.isChecked = !checkbox.isChecked);

      return button;
  }
}

export { Buttons };
