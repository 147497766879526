import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { ElasticEase } from "@babylonjs/core/Animations/easing";
import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents";
import { PointerInfo } from "@babylonjs/core/Events/pointerEvents";
import { MultiMaterial } from "@babylonjs/core/Materials/multiMaterial";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Axis } from "@babylonjs/core/Maths/math";
import { Color3 } from "@babylonjs/core/Maths/math";
import { Quaternion } from "@babylonjs/core/Maths/math";
import { Vector3 } from "@babylonjs/core/Maths/math";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Observer } from "@babylonjs/core/Misc/observable";
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture";
import { Ellipse } from "@babylonjs/gui/2D/controls/ellipse";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import { BaseCardMesh } from "components/game/BaseCardMesh";
import { game } from "components/game/Game";
import { Control3d } from "components/ui/meshes/Control3d";
import { config } from "utils/Config";

const CARD_BUTTON_SCALE = 2.0;
const CARD_BUTTON_IDLE_RANGE_Z = 0.2;

/**
 * 3d button made from the 3d card mesh
 * Note that it is automatically scaled and rotated opposite of the camera angle
 * The card button needs to be positioned to at least y = 1.0 to avoid cutting into the ground
 */
export class CardButton3d extends BaseCardMesh {
  name: string;
  cardBaseScale: number;
  idleRangeZ: number;
  onClick: () => void;
  onHelpClick: () => void;
  hoverExtension: AbstractMesh;
  gui: AdvancedDynamicTexture; // This is an optional gui for the main texture
  guiWidth: number;
  guiHeight: number;
  guiControl3d: Control3d; // This is an optional child 3d object with it's own gui
  helpButtonControl3d: Control3d;
  basePosition = new Vector3(0, 0, 0);
  hover = false;
  observer: Observer<PointerInfo>;
  animation: Animatable;

  constructor(name: string, texture: Texture, onClick: () => void) {
    super(name, game.guiRootTransformNode);

    this.cardBaseScale = CARD_BUTTON_SCALE;
    this.idleRangeZ = CARD_BUTTON_IDLE_RANGE_Z;

    this.onClick = onClick;

    // Allow us to detect mouse over on the mesh
    this.enablePointerMoveEvents = true;

    // Set the texture
    if(texture !== null)
      this.setCustomFaceTexture(texture);

    this.setRotation(0, 0, 0);
    this.resetScale();
  }

  addGUI() {
    this.guiWidth = 400;
    this.guiHeight = 512;
    this.gui = new AdvancedDynamicTexture(this.name + "GuiTexture", this.guiWidth, this.guiHeight, this.getScene());
    this.setCustomFaceTexture(this.gui);
  }

  addBottomGUI(name: string, guiWidth = 512, guiHeight = 512) {
    // Create a new 3d GUI control
    this.guiControl3d = new Control3d(name, this.getScene(), guiWidth, guiHeight);
    this.guiControl3d.parent = this;
    this.guiControl3d.layerMask = this.layerMask;

    this.guiControl3d.enablePointerMoveEvents = true;

    // Position it at the bottom of the card
    this.guiControl3d.position = new Vector3(0, - 0.64 - 0.5, 0);
  }

  addHelpButton(onClick: () => void) {
    this.onHelpClick = onClick;

    let width = 0.2;

    let x = 0.5 + width * 0.5;
    let y = 0.64 - width * 0.5;
    let z = -0.02;

    // Add an extension to the right side of the card so we don't lose mouse over when slipping off of the help button
    this.hoverExtension = MeshBuilder.CreatePlane(this.name + "HoverExtension", {width, height: 1.28}, this.getScene());
    this.hoverExtension.parent = this;
    this.hoverExtension.layerMask = this.layerMask;

    this.hoverExtension.enablePointerMoveEvents = true;

    this.hoverExtension.visibility = 0.0;

    this.hoverExtension.position.x = x - 0.01;
    this.hoverExtension.position.y = 0;
    this.hoverExtension.position.z = z + 0.01;

    // Add the Help Button
    this.helpButtonControl3d = new Control3d(this.name + "HelpButton", null, 64, 64);
    this.helpButtonControl3d.parent = this;
    this.helpButtonControl3d.layerMask = this.layerMask;

    this.helpButtonControl3d.enablePointerMoveEvents = true;

    this.helpButtonControl3d.visibility = 0.5;

    this.helpButtonControl3d.scaling.x = width;
    this.helpButtonControl3d.scaling.y = width;

    this.helpButtonControl3d.position.x = x;
    this.helpButtonControl3d.position.y = y;
    this.helpButtonControl3d.position.z = z;

    let circle = new Ellipse(this.name + "HelpButtonCircle");
    circle.width = "48px";
    circle.height = "48px";
    circle.color = "white";
    circle.thickness = 5;
    this.helpButtonControl3d.addControl(circle);

    let text = new TextBlock(this.name + "HelpButtonText");
    //text.fontFamily = config.fontFamily; // XXX - fontFamily "Saira" does not work here
    text.fontWeight = config.fontWeight;
    text.fontSize = "32px";
    text.text = "?";
    text.color = "white";
    circle.addControl(text);
  }

  registerPointerObserver() {
    if(this.observer)
      return;

    this.observer = this.getScene().onPointerObservable.add((pointerInfo) => {
      let newHover = false;

      let pickInfo = pointerInfo.pickInfo;

      if(pickInfo.hit) {
        let pickedName = pickInfo.pickedMesh.name;
        if(
          pickedName === this.name ||
          (this.helpButtonControl3d && pickedName === this.helpButtonControl3d.name) ||
          (this.hoverExtension && pickedName === this.hoverExtension.name) ||
          (this.guiControl3d && pickedName === this.guiControl3d.name)
          ) {
          newHover = true;

          if(this.guiControl3d && pickedName === this.guiControl3d.name)
            this.guiControl3d.onPointer(pointerInfo, pickInfo);

          if(pointerInfo.type === PointerEventTypes.POINTERDOWN) {
            if(pickedName === this.name) {
              if(this.onClick !== null)
                this.onClick();
            } else if(this.helpButtonControl3d && pickedName === this.helpButtonControl3d.name) {
              this.onHelpClick();
            }
          }
        }
      }

      if(newHover !== this.hover) {
        this.hover = newHover;
        let scale = this.hover ? this.cardBaseScale * 1.25 : this.cardBaseScale;
        this.animateScale(scale);
      }
    });
  }

  unregisterPointerObserver() {
    this.getScene().onPointerObservable.remove(this.observer);
    this.observer = undefined;
  }

  dispose(doNotRecurse?: boolean, disposeMaterialAndTextures = false) {
    this.stopAnimations();
    this.unregisterPointerObserver();

    // Cleanup some child objects
    if(this.gui) {
      this.gui.dispose();
      this.gui = null;
    }

    if(this.hoverExtension) {
      this.hoverExtension.dispose();
      this.hoverExtension = null;
    }

    if(this.guiControl3d) {
      this.guiControl3d.dispose();
      this.guiControl3d = null;
    }

    if(this.helpButtonControl3d) {
      this.helpButtonControl3d.dispose();
      this.helpButtonControl3d = null;
    }

    super.dispose(doNotRecurse, disposeMaterialAndTextures);
  }

  disableLighting() {
    let meshMaterial = this.material as MultiMaterial;
    let faceMaterial = meshMaterial.subMaterials[0] as StandardMaterial;

    // Disable lighting
    faceMaterial.disableLighting = true;

    // Which turns the texture black, emissive color works without lighting
    faceMaterial.emissiveColor = new Color3(1, 1, 1);
  }

  move(x: number, y: number, z: number) {
    this.position.set(x, y, z);
    this.basePosition.set(x, y, z);
  }

  setRotation(x: number, y: number, z: number) {
    x = x * Math.PI / 180;
    y = y * Math.PI / 180;
    z = z * Math.PI / 180;

    let q = new Quaternion();

    q.multiplyInPlace(Quaternion.RotationAxis(Axis.X, x));
    q.multiplyInPlace(Quaternion.RotationAxis(Axis.Y, y));
    q.multiplyInPlace(Quaternion.RotationAxis(Axis.Z, z));

    this.rotationQuaternion = q;
  }

  resetScale() {
    this.scaling = new Vector3(this.cardBaseScale, this.cardBaseScale, this.cardBaseScale);
  }

  stopAnimations() {
    if(this.animation) {
      this.animation.goToFrame(this.animation.toFrame);
      this.animation.stop();
      this.animation = null;
    }

    // Somehow this was null here
    if(this)
      this.animations = [];
  }

  slideIn(delay: number, onAnimationEnd: () => void = null) {
    this.stopAnimations();

    let bbWidth = game.guiRootTransformNodeBoundingBox.maximum.x - game.guiRootTransformNodeBoundingBox.minimum.x;

    let position1 = this.basePosition.clone();
    let position0 = this.basePosition.add(new Vector3(bbWidth + this.cardBaseScale * 0.5, 0, 0));

    this.position = position0;

    let frames = 30;

    // Keys
    let k0 = delay;
    let k1 = k0 + frames;

    let positionAnimation = new Animation("SlideInPositionMesh", "position", 30.0, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let positionKeys = [];
    if(k0 > 0)
      positionKeys.push({ frame: 0, value: position0 });
    positionKeys.push({ frame: k0, value: position0 });
    positionKeys.push({ frame: k1, value: position1 });
    positionAnimation.setKeys(positionKeys);
    this.animations = [positionAnimation];

    // Elasic easing overshoots, then springs back
    let easingFunction = new ElasticEase(1, 5);
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
    positionAnimation.setEasingFunction(easingFunction);

    let speed = 1.0;

    this.animation = this.getScene().beginAnimation(this, 0, k1, false, speed, () => {
      if(onAnimationEnd)
        onAnimationEnd();
      this.animateIdle();
    });
  }

  slideDown(delay: number, onAnimationEnd: () => void = null) {
    this.stopAnimations();

    let position0 = this.position.clone(); // Animate down from wherever we are in the idle animation
    let position1 = this.basePosition.add(new Vector3(0, -10, 0));

    // Keys
    let k0 = delay;
    let k1 = k0 + 15;

    let positionAnimation = new Animation("SlideDownPositionMesh", "position", 30.0, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let positionKeys = [];
    positionKeys.push({ frame: 0, value: position0 });
    positionKeys.push({ frame: k0, value: position0 });
    positionKeys.push({ frame: k1, value: position1 });
    positionAnimation.setKeys(positionKeys);
    this.animations = [positionAnimation];

    /*
    // Elasic easing overshoots, then springs back
    let easingFunction = new ElasticEase(1, 5);
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
    positionAnimation.setEasingFunction(easingFunction);
    */

    let speed = 1.0;

    this.animation = this.getScene().beginAnimation(this, 0, k1, false, speed, onAnimationEnd);
  }

  animateScale(newScaleFactor: number) {
    let oldScale = this.scaling.clone();

    this.stopAnimations();

    // somehow this was null here
    if(!this)
      return;

    let newScale = new Vector3(newScaleFactor, newScaleFactor, newScaleFactor);

    // Keys
    let k0 = 0;
    let k1 = k0 + 10;

    let scaleAnimation = new Animation("ScaleMesh", "scaling", 30.0, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let scaleKeys = [];
    scaleKeys.push({ frame: k0, value: oldScale });
    scaleKeys.push({ frame: k1, value: newScale });
    scaleAnimation.setKeys(scaleKeys);
    this.animations = [scaleAnimation];

    // Elasic easing overshoots, then springs back
    let easingFunction = new ElasticEase(1, 5);
    easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEOUT);
    scaleAnimation.setEasingFunction(easingFunction);

    let speed = 1.0;

    this.animation = this.getScene().beginAnimation(this, 0, k1, false, speed, () => this.animateIdle());
  }

  animateIdle() {
    this.stopAnimations();

    let z = this.idleRangeZ;
    let speedRange = 0.2;

    let position0 = this.basePosition.clone();
    let position1 = this.basePosition.add(new Vector3(0, 0, z));
    let position2 = this.basePosition.add(new Vector3(0, 0, -z));

    // Keys
    let k0 = 0;
    let k1 = k0 + 60;
    let k2 = k1 + 120;
    let k3 = k2 + 60;

    let positionAnimation = new Animation("IdlePositionMesh", "position", 30.0, Animation.ANIMATIONTYPE_VECTOR3, Animation.ANIMATIONLOOPMODE_CYCLE);
    let positionKeys = [];
    positionKeys.push({ frame: k0, value: position0 });
    positionKeys.push({ frame: k1, value: position1 });
    positionKeys.push({ frame: k2, value: position2 });
    positionKeys.push({ frame: k3, value: position0 });
    positionAnimation.setKeys(positionKeys);
    this.animations = [positionAnimation];

    let speed = 1.0 + Math.random() * speedRange - speedRange * 0.5;

    this.animation = this.getScene().beginAnimation(this, 0, k3, true, speed);
  }
}
