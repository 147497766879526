import { config, mergeConfig } from "utils/Config";

import { IHelpParams } from "components/ui/HelpSystem";
import { IOptionsState } from "states/game/OptionsState";

// XXX - Couldn't import these
//       I found a lot of information on importing the html as a text string, but not on importing a URL to the html
//       the "file-loader?esModule=false!" trick does not work with import
//       Adding "html" to the file-loader config in webpack.config.js breaks things (you get an html URL instead of a game)
// tslint:disable-next-line:no-var-requires
export let helpUrl = require("file-loader?esModule=false!extract-loader!html-loader!components/ui/help/help.html");

export interface IHelpConfig {
  type: string;
  message?: string;
  url?: string;
  maxViews?: number;
  delay?: number;
  showHelpParams?: IHelpParams; // help to show when user clicks Help button on toast.
  gameOptions?: IOptionsState;
  nextHelp?: string; // the name of the next help config to show.  only supported by type "tutorial"
  game?: any; // a game state to load
  pauseGame?: boolean; // pause patches after showing message, and unpause when message is cleared.  only supported by type "tutorial"
  extra?: any; // extra params to pass to template, ie when rendering help.html
}

mergeConfig({
  help: {
    myTurn_bid: {
      type: "toastWithTutorial",
      message: "Need to learn about Bidding?",
      maxViews: 3,
      delay: 15,
    },
    myTurn_play: {
      type: "toastWithTutorial",
      message: "Need to learn about Playing?",
      maxViews: 3,
      delay: 15,
    },
    main_quickPlay: {
      type: "modal",
      url: helpUrl,
      gameOptions: config.gameOptions.quickPlay,
    },
    main_classic: {
      type: "modal",
      url: helpUrl,
      gameOptions: config.gameOptions.classic,
    },
    main_classicStandAlone: {
      type: "modal",
      url: helpUrl,
      gameOptions: config.gameOptions.classicStandAlone,
    },
    main_handChallenge: {
      type: "modal",
      url: helpUrl,
      gameOptions: config.gameOptions.handChallenge,
    },
    main_tutorial: {
      type: "modal",
      url: helpUrl,
      gameOptions: config.gameOptions.classic,
    },

    tutorial: {
      type: "tutorial",
      maxViews: 1,
    },
  },
});
