import { TrickGameAI } from "ai/TrickGameAI";
import * as SSpadesGameStateAIModule from "games/spades/src/ai/SSpadesGameStateAI";
import { logger } from "utils/logger";

// Create a singleton c++ Spades AI
// For one thing, we re-initialize it from the game state on every use, so we don't need more than one
// For another thing, the c++ object must be manually deleted by calling obj.delete(), and there are no finalizers in javascript
// I Couldn't manage to make a working definition file, so spadesAI is type any
// It is possible spadesAI won't have been created yet when first needed
// so we'll try to wait for the emscripten module to be ready, but fallback on random if it takes too long
// on the server we can't wait and will fallback to random immediately

// How long to wait for the AI to be ready before giving up and falling back on random plays
let CREATE_AI_TIMEOUT_MS = 15 * 1000;

// We'll create a single spadesAI instance and re-use it
let spadesAI: any = null;

// Create the module - it will be doing some work, in particular compiling WASM in the background
// If WebAssembly is not supported there is an exception that breaks the game and leaveds a blank window:
//    abort(No WebAssembly support found. Build with -s WASM=0 to target JavaScript instead.)
// We could check beforehand with typeof WebAssembly !== "object" && false
// But then the AI would be playing randomly and we'd want to disable hints
// Currently a bit of script in index.html checks for WebAssembly and displays an error message
let sSpadesGameStateAIModule = SSpadesGameStateAIModule.default();

// Create the timout promise
let _timeoutId: any = null;
let timeoutPromise = new Promise<boolean>((resolve, reject) => {
  _timeoutId = setTimeout(() => {
    logger.info("SpadesAI - timeout creating instance");
    resolve(false);
    }, CREATE_AI_TIMEOUT_MS);
});

// Create the create Spades AI promise
let createStartTime = Date.now();
let spadesAIPromise = new Promise<boolean>((resolve, reject) => {
  // Wait for the module to be ready, then create the SSpadesGameStateAI instance;
  sSpadesGameStateAIModule.then((m: any) => {
    clearTimeout(_timeoutId);
    spadesAI = new m.SSpadesGameStateAI();
    logger.info("SpadesAI instance created", {time: Date.now() - createStartTime});
    resolve(true);
  });
});

export class SpadesGameAI extends TrickGameAI  {
  isAIReady() {
    return spadesAI !== null;
  }

  async waitForAIReady(timeout = true) {
    if(timeout)
      return Promise.race([spadesAIPromise, timeoutPromise]);
    else
      return spadesAIPromise;
  }

  getBid(stateJson?: string): number {
    if(stateJson === undefined)
      stateJson = this.getSnapshotJsonForAI();
    spadesAI.SetGameState(stateJson, -1);
    let bid = spadesAI.SelectBid();
    return bid;
  }

  getPlay(stateJson?: string): number {
    if(stateJson === undefined)
      stateJson = this.getSnapshotJsonForAI();
    spadesAI.SetGameState(stateJson, -1);
    let value = spadesAI.SelectPlay();
    return value;
  }
}
