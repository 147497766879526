/** TimersState manages a list of TimerState
 * When running without StateSync use startSimpleTimerProcessor to process timers periodically
 * When running with StateSync then StateSyncHelpers.ts handles calling processTimers action
 */
import { applyAction, destroy, getParent, getPath, IAnyStateTreeNode, ISerializedActionCall, types } from "mobx-state-tree";
import { v4 as uuid } from "uuid";

import { TimerState } from "./TimerState";

// tslint:disable-next-line:variable-name
const TimersState = types
  .model("TimersState", {
    timers: types.array(TimerState),
  })
  .actions((self) => {

    /**
     * add an action to be called on a timer
     * @param interval how many seconds until calling action
     * @param actionNode the mobx state tree node to do the action on
     * @param actionName the name of the action to call
     * @param args arguments to the action
     */
    function addTimer(interval: number, actionNode: IAnyStateTreeNode, actionName: string, args: any[]): string {
      // calculate date to trigger action
      let timeoutDate = new Date();
      timeoutDate.setSeconds(timeoutDate.getSeconds() + interval);

      // get path of node relative to our parent, ie "/game" would become just "" since game is our parent
      // we do this because we apply actions on our parent, and not on the rootState
      let path = getPath(actionNode).replace(getPath(getParent(self)), "");

      // encode action as a JSON string
      let action: ISerializedActionCall = {
        name: actionName,
        path: path,
        args: args,
      };

      let timer = TimerState.create({
        id: uuid(),
        interval: interval,
        next: timeoutDate,
        action: JSON.stringify(action),
      });

      self.timers.push(timer);
      return timer.id;
    }

    /** This needs to be called periodically to process timers and call actions */
    function processTimers() {
      let now = new Date();
      for(let timer of self.timers) {
        if(timer.next <= now) {
          let actionJson = timer.action;
          // destroy timer first, because the action might call removeTimer
          destroy(timer);
          let action = JSON.parse(actionJson);
          applyAction(getParent(self), action);
        }
      }
    }

    function removeTimer(timerId: string) {
      let foundTimer = self.timers.find((timer) => timer.id === timerId);
      if(foundTimer)
        destroy(foundTimer);
    }

    return { addTimer, processTimers, removeTimer };
  });

// TimerState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type ITimersState = typeof TimersState.Type;

/** this is a simple timer processor to use when not running on StateSync. It calls processTimers 4 times a second. */
export function startSimpleTimerProcessor(timersState: ITimersState) {
  window.setInterval(() => {
    timersState.processTimers();
  }, 250);
}

export { TimersState, ITimersState };
