import { Control } from "@babylonjs/gui/2D/controls/control";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";

import { ArgoSystem } from "components/game/ArgoSystem";
import { BidSystem } from "components/game/BidSystem";
import { AdStatus } from "components/ui/ad-system/AdParams";
import { Commands } from "components/ui/Commands";
import { ArgoButton } from "components/ui/controls/ArgoButton";
import { toast, zIndex } from "components/utils/GUI";
import { GAME_STATE_BID, GAME_STATE_PLAY } from "states/game/GameState";
import { ITrickGameState } from "states/game/TrickGameState";
import { ROOT_STATE_GAME } from "states/RootState";

import { SpadesGameAI } from "games/spades/src/ai/SpadesGameAI";

import hintIconOverlayInactiveUrl from "components/ui/icons/hint-ad-overlay-inactive.png";
import hintIconOverlayUrl from "components/ui/icons/hint-ad-overlay.png";
import hintIconInactiveUrl from "components/ui/icons/hint-inactive.png";
import hintIconUrl from "components/ui/icons/hint.png";

export class SpadesHintSystem extends ArgoSystem {
  hintButton: ArgoButton;
  delayNextEnable: boolean = false;
  enableHintTimerId: any = null;

  init() {
    this.rootState.router.addRoute("^\/status$", (patch: any, reversePatch: any, params: any) => this.onRootStatusChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/seatsTurn$", (patch: any, reversePatch: any, params: any) => this.onSeatsTurnChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/seats\/(\\d*)(\/[a-z]*)?\/player$", (patch: any, reversePatch: any, params: any) => this.onSeatPlayerChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/user\/inventory\/items\/", (patch: any, reversePatch: any, params: any) => this.onInventoryChanged(patch, reversePatch, params)); // there is purposely no $ terminator to match any change to inventory
    this.game.onShowGameObservable.add((show) => this.onShowGame(show));
  }

  createGUI() {
    this.hintButton = new ArgoButton("SpadesHintButton", null, hintIconUrl);
    this.hintButton.setInactiveIconUrl(hintIconInactiveUrl);
    this.hintButton.setOverlayIconUrl(hintIconOverlayUrl);
    this.hintButton.setInactiveOverlayIconUrl(hintIconOverlayInactiveUrl);
    this.hintButton.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.hintButton.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.hintButton.thickness = 0;
    this.hintButton.zIndex = zIndex.ABOVE_GAME;
    this.hintButton.onPointerClickObservable.add((info) => this.onClick(info));
    this.hintButton.isVisible = false;
    this.game.guiTexture.addControl(this.hintButton);

    this.layout();
  }

  layout() {
    if(!this.hintButton)
      return;

    let baseHeight = Math.floor(64 * this.game.getScale());

    this.hintButton.baseHeight = baseHeight;
  }

  updateVisibility() {
    if(!this.hintButton)
      return;

    let visible = false;
    let overlayVisible = false;
    let enabled = false;

    // We'll show the hint button if reward ads are supported, or we have an instaPass, and we're in a game, and the game is not multiplayer
    let adsSupported = this.rootState.rewardAdSupported;
    let haveInstaPass = this.rootState.user.inventory.hasItem("insta_pass");
    let inGame = this.rootState.status === ROOT_STATE_GAME;
    let isMultiplayer = this.rootState.game.getHumanPlayersInSeatsCnt() <= 1;
    if((adsSupported || haveInstaPass) && inGame && isMultiplayer) {
      // Show if reward ads are supported and we're in a game
      visible = true;

      // Show the overlay icon if we don't have an insta pass
      overlayVisible = !haveInstaPass;

      if(this.game.gameState.seatsTurn && this.game.localSeat) {
        let seatsTurn = this.game.gameState.seatsTurn.id;
        let localSeatTurn = (this.game.localSeat === seatsTurn);

        // Enable if it's the local seat's turn
        if(localSeatTurn)
          enabled = true;
      }
    }

    // But hide if the game is hidden
    if(!this.game.showingGame)
      visible = false;

    this.hintButton.isVisible = visible;
    this.hintButton.overlayIcon.isVisible = overlayVisible;
    this.hintButton.inactiveOverlayIcon.isVisible = overlayVisible;

    // The continue or play again button in the Game Summary screen is directly over the hint button
    // It is very easy to end up clicking both if there is no ad, or a delay before the ad appears
    // Note that a delay must be added to the fallback ad in AdSystem to see the problem when testing
    // As a workaround, if the game was just shown, we will add a short delay before enabling the hint button
    if(this.delayNextEnable && enabled) {
      this.delayNextEnable = false;
      if(!this.enableHintTimerId) {
        this.enableHintTimerId = setTimeout(() => this.hintButton.isEnabled = true, 3000);
      }
      return;
    }

    // Otherwise enable as usual
    this.clearEnableHintTimer();
    this.hintButton.isEnabled = enabled;
  }

  clearEnableHintTimer() {
    if(this.enableHintTimerId) {
      clearTimeout(this.enableHintTimerId);
      this.enableHintTimerId = null;
    }
  }

  onRootStatusChanged(patch: any, reversePatch: any, params: any) {
    this.updateVisibility();
  }

  onSeatsTurnChanged(patch: any, reversePatch: any, params: any) {
    this.updateVisibility();
  }

  onSeatPlayerChanged(patch: any, reversePatch: any, params: any) {
    this.updateVisibility();
  }

  onInventoryChanged(patch: any, reversePatch: any, params: any) {
    this.updateVisibility();
  }

  onShowGame(show: boolean) {
    this.delayNextEnable = show;
    this.updateVisibility();
  }

  onClick(info: Vector2WithInfo) {
    if(info.buttonIndex !== 0)
      return;

    // Free hints if they have the pass
    if(this.rootState.user.inventory.hasItem("insta_pass")) {
      this.doHint();
      return;
    }

    Commands.onShowAd("hintReward", true, (status: AdStatus) => {
      if(status === AdStatus.AD_STATUS_FINISHED)
        setTimeout(() => this.doHint(), 250); // delay 1/4 second after ad finishes before displaying hint
      else {
        let message = "Reward ad unavailable.";
        if(status === AdStatus.AD_STATUS_REWARD_NOT_COMPLETED)
          message = "Reward ad not completed.";
        if(this.rootState.paymentsSupported) {
          toast("HintAdUnavailable", `${message} Get an Insta Pass for unlimited hints!`, 0, 0, true, "Insta Pass", () => {
            this.game.adSystem.showSpecificAd("argo", "instaPass", null);
          });
        }
        else
          toast("HintAdUnavailable", message);
      }
    });
  }

  doHint() {
    let isLocalSeatsTurn = () => {
      if(!this.game.gameState.seatsTurn || !this.game.localSeat)
        return false;
      let seatsTurn = this.game.gameState.seatsTurn.id;
      return (this.game.localSeat === seatsTurn);
    };

    let ai = new SpadesGameAI(this.game.localSeat, this.game.gameState as ITrickGameState, null);

    if(this.game.gameState.status === GAME_STATE_BID) {
      ai.getAIBidAsync().then((aiBid) => {
        if(isLocalSeatsTurn() && this.game.gameState.status === GAME_STATE_BID)
          (this.game.systems.get("BidSystem") as BidSystem).setBidHint(aiBid);
      });
    } else if(this.game.gameState.status === GAME_STATE_PLAY) {
      ai.getAIPlayAsync().then((aiPlay) => {
        if(isLocalSeatsTurn() && this.game.gameState.status === GAME_STATE_PLAY)
          this.game.setPieceHint(aiPlay);
      });
    }
  }

  resized(): void {
    this.layout();
  }
}
