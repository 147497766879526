import { Control } from "@babylonjs/gui/2D/controls/control";

import { game } from "components/game/Game";
import { Commands } from "components/ui/Commands";
import { FxButton } from "components/ui/controls/FxButton";

import inviteBackgroundUrl from "components/game/avatars/invite_lg_bg.png";
import inviteForegroundUrl from "components/game/avatars/invite_lg_fg.png";
import inviteMiddleUrl from "components/game/avatars/rays.png";

/**
 */
export class InviteButton extends FxButton {
  constructor(name: string) {
    super(name);

    let seatId = game.localSeat || "0";
    let team = game.gameState.getTeamForSeat(seatId);
    let inviteSeatId = "2";

    // default the big invite button to invite to partner seat
    let partnerSeat = team.getPartnerSeat(seatId);
    if(partnerSeat)
      inviteSeatId = partnerSeat.id;

    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.setBackgroundImage(inviteBackgroundUrl, 706 / 582);
    this.setMiddleImage(inviteMiddleUrl);
    this.animateMiddleRotation(5.0, true);
    this.setForegroundImage(inviteForegroundUrl, 706 / 582);
    this.setLabel("XP Bonus and No ads when you play with Friends");
    this.label.color = "gold";
    this.label.outlineColor = "black";
    this.label.outlineWidth = 2;
    this.isPointerBlocker = true;
    this.onPointerClickObservable.add(() => Commands.onInvite({srcName: "invite_fx_button", seatId: inviteSeatId}));

    this.layout();
  }

  layout() {
    let guiHeight = game.guiTexture.getSize().height;

    let height = Math.floor(guiHeight / 3);
    let width = height * 2.5;

    this.width = width + "px";
    this.height = height + "px";

    super.layout();
  }

  show() {
    this.isVisible = true;
    this.animateScale(0.25, 1.0, 1.0);
  }

  hide() {
    this.animateScale(1.0, 0.25, 1.0).onAnimationEndObservable.add(() => this.isVisible = false);
  }
}
