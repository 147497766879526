/** BaseStateSync is the base model for states that need to synced to a service */

import { IJsonPatch, ISerializedActionCall, types } from "mobx-state-tree";

export const APPLY_SNAPSHOT_STAGE_START = "start";
export const APPLY_SNAPSHOT_STAGE_DONE = "done";
export type ApplySnapshotStage = typeof APPLY_SNAPSHOT_STAGE_START  | typeof APPLY_SNAPSHOT_STAGE_DONE;

// IPatchSet is what is returned from subscribing to patches
export interface IPatchSet {
  id: string; // id of the state this patch is for
  patchSetId: number; // the sequential id of this patchSet, used to detect out of order and duplicate patchSets
  patches: string[]; // is a list of strings of json patches like: "{\"op\":\"replace\",\"path\":\"/seats/0/bid\",\"value\":5}"
}

/** Contains action, and the state name and id to apply it to  */
export interface IStateActionCall {
  /** id of the state to apply action to */
  id: string;
  /** name of state, ie UserState, GameState */
  stateName: string;
  /** the action to apply  */
  action: ISerializedActionCall;
  /** delay in seconds to wait before applying action */
  delay?: number;
}

/** contains patch, reversePatch and name of channel to send it too */
export interface IChannelPatch {
  channel: string;
  patch: IJsonPatch;
  reversePatch: IJsonPatch;
}

// tslint:disable-next-line:variable-name
const BaseStateSync = types
  .model("BaseStateSync", {
    id: types.optional(types.string, ""), // id is a unique id used to identify game in server database, can't be identifier because then it can't be changed
    stateName: types.optional(types.string, "BaseState"), // name of this state, ie UserState, GameState. It is mostly used as part of the channel name, so be real careful changing or overriding it.
    // keep track of how many patch sets have been applied. Used to detect if the client is missing any patchSets.
    patchSetId: types.optional(types.integer, 0),
    pingId: types.optional(types.integer, 0), // id of the last ping sent/received
    //pingTime: types.optional(types.integer, 0), // the round trip time in seconds of the last ping
    version: types.optional(types.string, ""), // the version of the server that last saved this state.
    clientName: types.maybeNull(types.string),  // name of game and platform that created this state, ie spades_facebook_ig

    // set true if the client is in offline mode, ie the game was initially created on the server, but there was only 1 player in it.
    // So it will be played offline, then client reports result to server. this is different then options.standAlone, which means the game never touches the server
    offline: types.optional(types.boolean, false),

    // This gives clients something to watch for to react to a new snapshot being applied, applySnapshotStage shouldn't be in the snapshot being applied.
    applySnapshotStage: types.maybe(types.enumeration("ApplySnapshotStage", [APPLY_SNAPSHOT_STAGE_START, APPLY_SNAPSHOT_STAGE_DONE])),
  })
  .actions((self) => {

    function incPatchSetId() {
      self.patchSetId += 1;
    }

    function setApplySnapshotStage(state: ApplySnapshotStage) {
      self.applySnapshotStage = state;
    }

    /** prepares state for a snapshot for a specific player, ie TrickGameState sets other players cards facedown.
     *  playerId is optional, if not provided then assumes it's prepping state for server to save to database
     */
    function prepForSnapshot(playerId?: string) {
      // overridden by subclass (GameState, TrickGameState)
      self.version = process.env.VERSION;
    }

    function createState(snapshot: any): Promise<boolean> {
      // overridden by subclasses (GameState, TrickGameState) and replaced by StateSyncMiddleware to create state on server
      return new Promise<boolean> ((resolve, reject) => {reject(new Error("createState not implemented")); });
    }

    /** finds a state like the one in the partial snapshot, if one doesn't exist then create a new one.
     * For example GameState searches for a game with the same snapshot.options.name and snapshot.gameContextId to join.
     * It only uses the options.name to search by, the rest of the options are ignored for now until we support custom.
     * only works if we haveStateSync.
     */
    function findOrCreateState(snapshot: any): Promise<boolean> {
      // overridden StateSyncMiddleware to find an existing state/game, or create a new one
      return new Promise<boolean> ((resolve, reject) => {reject(new Error("findOrCreateState not implemented")); });
    }

    /** Gets state from service and stays synced to it, implemented by StateSyncMiddleware */
    function syncState(id: string): Promise<boolean> {
      // overridden StateSyncMiddleware
      return new Promise<boolean> ((resolve, reject) => {reject(new Error("syncState not implemented")); });
    }

    /** resetState resets things back to before a real state was loaded, but not quite all the way back to defaults, ie moves all pieces back to deck, unsubscribes from StateSync */
    function resetState() {
      // overridden by subclasses (GameState, TrickGameState, SolitaireGameState) and StateSyncMiddleware
      self.id = "";
      self.offline = false;
    }

    /** a super simple ping. */
    function ping(userId: string) {
      // StateSyncMiddleware will auto forward this ping action and record the time
      // Then when StateSyncMiddleware sees pingId being set it(basically the pong), it will log the difference in time.
      self.pingId += 1;
    }

    /** apply a snapshot from client made wtih getSyncToServerSnapshot. returns true if it's ok to apply snapshot, false if not */
    function applySyncToServerSnapshot(userId: string, snapshot: any): boolean {
      if(snapshot.id !== self.id || !self.offline)
        return false;
      return true;
    }

    function setClientName(userId: string, clientName: string) {
      if(userId === self.id)
        self.clientName = clientName;
    }

    return { applySyncToServerSnapshot, createState, findOrCreateState, incPatchSetId, ping, prepForSnapshot, resetState, setApplySnapshotStage, setClientName, syncState };
  }).views((self) => {

    /** returns a list of patches and channels each patch should be sent to. It will possibly break up a patch into multiple patches.
     * For Example when loading an offline snapshot of spades, it will break up the add piece to hand patch, into a public add, and private patches for setting facing and value
     * This is also used by RootState on the client in offline games to only process public and player specific patches locally.
     * null - send to no one
     * "public" - send on public chanlel
     * "#1234" - send to user 1234's channel
     */
    function filterPatch(patch: IJsonPatch, reversePatch: IJsonPatch): IChannelPatch[] {
      // overriden by TrickGameState
      return [{channel: "public", patch, reversePatch}];
    }

    /** Gets a partial snapshot of current state for sending to the server. It should contain as least as possible */
    function getSyncToServerSnapshot(): any {
      // overridden by subclasses to add more properties
      return {
        id: self.id,
      };
    }

    return { filterPatch, getSyncToServerSnapshot };
  });

// BaseStateSync is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IBaseStateSync = typeof BaseStateSync.Type;

export { BaseStateSync, IBaseStateSync };
