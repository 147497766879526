import { Animatable } from "@babylonjs/core/Animations/animatable";
import { Animation } from "@babylonjs/core/Animations/animation";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Observer } from "@babylonjs/core/Misc/observable";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { Image } from "@babylonjs/gui/2D/controls/image";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";
import { Vector2WithInfo } from "@babylonjs/gui/2D/math2D";
import { Measure } from "@babylonjs/gui/2D/measure";

import { game } from "components/game/Game";

import { PopUp } from "components/ui/controls/PopUp";
import { GUIAnimationProxy } from "components/utils/GUIAnimationProxy";

//import backgroundNinePatch from "components/ui/toast/toast-background-9p.png";

/**
 * Slide up a temporary message
 * @param displayTime - Set custom time to show message for, 0 is default, -1 is sticky/don't auto hide toast, or allow it to be clicked away.
 * @param buttonLabel - If provided puts a button on the right side of toast
 * @param buttonCB - a callback function to call when the button is clicked
 */
export class ToastPopUp extends PopUp {
  private animateInOutAnimation: Animatable = null;
  customWidth = 0;
  backgroundImage: Image;
  textBlock: TextBlock;
  button: Button;
  layoutX = 0;
  layoutY = 0;
  onPointerClickObserver: Observer<Vector2WithInfo>;
  buttonOnPointerClickObserver: Observer<Vector2WithInfo>;
  displayTimeTimerId: any = null;
  proxy: GUIAnimationProxy;

  constructor(name: string, message: string, customWidth = 0, displayTimeSeconds = 0, clickToDispose = true, buttonLabel?: string, buttonCB?: () => any) {
    super(name);

    // Create an animation proxy
    this.proxy = new GUIAnimationProxy(this);

    this.customWidth = customWidth;

    this.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.background = "black";
    this.thickness = 0;
    this.isPointerBlocker = true;

    this.disposeOnMiss = clickToDispose;
    if(clickToDispose)
      // Clicks on the Toast
      this.onPointerClickObserver = this.onPointerClickObservable.add( () => this.animateOut() );

    // Add Background
    /*
    this.backgroundImage = new Image(name + "BackgroundImage", backgroundNinePatch);
    this.backgroundImage.populateNinePatchSlicesFromImage = true;
    this.backgroundImage.stretch = Image.STRETCH_NINE_PATCH;
    this.addControl(this.backgroundImage);

    if(!this.backgroundImage.isLoaded)
      this.backgroundImage.onImageLoadedObservable.addOnce(() => this.layout());
    */

    // Add Text
    this.textBlock = new TextBlock(name + "TextBlock");
    this.textBlock.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.textBlock.color = "white";
    this.textBlock.text = message;
    this.textBlock.textWrapping = true;
    this.addControl(this.textBlock);

    // Add a button, if requested
    if(buttonLabel) {
      this.button = Button.CreateSimpleButton(name + "Button", buttonLabel);
      this.button.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
      this.button.color = "white";
      this.buttonOnPointerClickObserver = this.button.onPointerClickObservable.add(() => {
        if(buttonCB)
          buttonCB();
        this.animateOut();
      });
      this.addControl(this.button);
    }

    // Default display time
    if(!displayTimeSeconds) {
      displayTimeSeconds = 4;
      // if message is more then 30 chars long, or there is a button label, then show for longer.
      if(message.length > 30 || buttonLabel)
        displayTimeSeconds = 7;
    }

    // Self destruct, if requested
    if(displayTimeSeconds > 0)
      this.displayTimeTimerId = setTimeout(() => this.animateOut(), displayTimeSeconds * 1000);
}

  init() {
    // Layout needs to happen after the PopUp is added to it's parent
    this.layout();
    this.animateIn();
  }

  layout() {
    super.layout();

    let guiWidth = game.guiTexture.getSize().width;
    let guiHeight = game.guiTexture.getSize().height;

    let defaultWidth = guiWidth;

    let height = Math.floor(30 * game.getScale());
    let width = this.customWidth || defaultWidth;

    let border = height * 0.5;

    let textWidth = width - border * 2;
    let textHeight = Math.floor(height);
    let buttonWidth = height * 4;
    if(this.button)
      textWidth -= buttonWidth;

    // save single line textHeight for button
    let buttonTextHeight = textHeight;

    // Set the TextBlock size early so computeExpectedHeight works
    this.textBlock.width = textWidth + "px";
    this.textBlock.fontSize = (height * 0.8) + "px";

    // Check for multiple lines
    let measure = new Measure(0, 0, guiWidth, guiHeight);
    let context = game.guiTexture.getContext();
    (this.textBlock as any)._layout(measure, context); // Workaround for Babylon GUI not updating things until rendered
    textHeight = Math.floor(this.textBlock.computeExpectedHeight());

    this.textBlock.left = border;
    this.textBlock.height = textHeight + "px";

    height = textHeight + border * 2;

    // If the height is less than the background image height, Babylon will overlap them badly
    if(this.backgroundImage && this.backgroundImage.isLoaded && this.backgroundImage.domImage)
      height = Math.max(height, this.backgroundImage.domImage.height);

    let x = 0;
    let y = guiHeight - height;

    this.layoutX = x;
    this.layoutY = y;

    this.left = x;
    this.top = y;
    this.width = width + "px";
    this.height = height + "px";

    if(this.button) {
      this.button.left = -border;
      this.button.width = buttonWidth + "px"; // need to figure out resize to text
      this.button.height = (buttonTextHeight * 0.9) + "px";
      this.button.fontSize = (buttonTextHeight * 0.8) + "px";
    }
  }

  animateIn() {
    let guiHeight = game.guiTexture.getSize().height;
    let moveTime = 0.1;

    let from = new Vector2(this.layoutX, guiHeight);
    let to = new Vector2(this.layoutX, this.layoutY);

    this.proxy.position = from;

    let a = new Animation(name + "AnimateIn", "position", 30, Animation.ANIMATIONTYPE_VECTOR2, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = k0 + 30 * moveTime;
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    a.setKeys(keyFrames);

    this.animateInOutAnimation = game.scene.beginDirectAnimation(this.proxy, [a], 0, k1);
  }

  animateOut() {
    if(this.name.endsWith("AnimatingOut"))
      return;

    let oldLeft = this.leftInPixels;
    let oldTop = this.topInPixels;

    this.stopAnimations();

    this.name += "AnimatingOut";

    let guiHeight = game.guiTexture.getSize().height;
    let moveTime = 0.1;

    this.top = oldTop + "px";

    let from = new Vector2(oldLeft, oldTop);
    let to = new Vector2(this.layoutX, guiHeight);

    this.proxy.position = from;

    let a = new Animation(name + "AnimateIn", "position", 30, Animation.ANIMATIONTYPE_VECTOR2, Animation.ANIMATIONLOOPMODE_CONSTANT);
    let keyFrames = [];
    let k0 = 0;
    let k1 = k0 + 30 * moveTime;
    keyFrames.push({frame: k0, value: from});
    keyFrames.push({frame: k1, value: to});
    a.setKeys(keyFrames);

    this.animateInOutAnimation = game.scene.beginDirectAnimation(this.proxy, [a], 0, k1);
    this.animateInOutAnimation.onAnimationEndObservable.add( () => this.dispose() );
  }

  onPointerMiss() {
    this.removePrePointerObserver();
    this.animateOut();
  }

  stopAnimations() {
    super.stopAnimations();

    if(this.animateInOutAnimation) {
      this.animateInOutAnimation.goToFrame(this.animateInOutAnimation.toFrame);
      this.animateInOutAnimation.stop();
      this.animateInOutAnimation = null;
    }
  }

  dispose() {
    super.dispose();

    // Stop Listening to clicks
    if(this.onPointerClickObserver) {
      this.onPointerClickObservable.remove(this.onPointerClickObserver);
      this.onPointerClickObserver = null;
    }

    if(this.buttonOnPointerClickObserver) {
      this.button.onPointerClickObservable.remove(this.buttonOnPointerClickObserver);
      this.buttonOnPointerClickObserver = null;
    }

    // Stop any displayTime timer
    if(this.displayTimeTimerId != null) {
      clearTimeout(this.displayTimeTimerId);
      this.displayTimeTimerId = null;
    }
  }
}
