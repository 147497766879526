import { getParent, getRoot, types } from "mobx-state-tree";
import semverCompare from "semver-compare";

import { IRootState } from "states/RootState";
import { IUserState } from "./UserState";

export const META_GAME_INPROGRESS = "inprogress";
export const META_GAME_OFFER_LIFE = "offer_life"; //notify client to show a message box offering to watch an ad or buy an insta pass to unlock a life.
export const META_GAME_OVER = "game_over";
export type MetaGameStatus = typeof META_GAME_INPROGRESS | typeof META_GAME_OFFER_LIFE | typeof META_GAME_OVER;

const MAX_UNLOCKED_LIVE = 1;

// tslint:disable-next-line:variable-name
const MetaGameState = types
  .model("MetaGameState", {
    lives: types.optional(types.number, 3),
    unlockedLives: types.optional(types.number, 0), // number of lives unlocked this game by watching ads, or having Insta Pass
    score: types.optional(types.number, 0),
    status: types.optional(types.enumeration("Status", [META_GAME_INPROGRESS, META_GAME_OFFER_LIFE, META_GAME_OVER]), META_GAME_INPROGRESS),
  })
  .actions((self) => {
    function addToScore(userId: string, score: number) {
      self.score += score;
    }

    function loseLife(userId: string) {
      if(self.lives > 0)
        self.lives -= 1;

      // Check if they're out of lives.
      if(!self.lives)  {
        // If they haven't unlocked a life yet and the client supports it set status to META_GAME_OFFER_LIFE to give user a chance to watch ad/buy insta pass, else end meta game now
        if(self.unlockedLives === 0)
          self.status = META_GAME_OFFER_LIFE;
        else
          endMetaGame(userId);
      }
    }

    /** Ends current meta game if at 0 lives, and auto starts a new game */
    function endMetaGame(userId: string) {
      if(self.lives <= 0) {
        self.status = META_GAME_OVER;

        // Check if the user has the Insta Pass that gives an extra life
        let userState = getParent(self) as IUserState;
        if(userState.inventory.hasItem("insta_pass"))
          self.unlockedLives = 1;
        else
          self.unlockedLives = 0;

        self.lives = 3 + self.unlockedLives;
        self.score = 0;
        self.status = META_GAME_INPROGRESS;
      }
    }

    /** unlock an extra life, usually by watching an ad */
    function unlockLife(userId: string) {
      if(self.unlockedLives < MAX_UNLOCKED_LIVE) {
        self.lives += 1;
        self.unlockedLives += 1;
        self.status = META_GAME_INPROGRESS; // be sure the status is META_GAME_INPROGRESS, if they lost their last heart it could be META_GAME_OFFER_LIFE
      }
    }

    return { addToScore, endMetaGame, loseLife, unlockLife };
  });

// MetaGameState is not a type, it is an instance of a IModelType, so we can do the following to get a type to use with typescript
type IMetaGameState = typeof MetaGameState.Type;

export { MetaGameState, IMetaGameState };
