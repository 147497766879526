import { IFileRequest } from "@babylonjs/core/Misc/fileRequest";
import { Tools } from "@babylonjs/core/Misc/tools";
import { WebRequest } from "@babylonjs/core/Misc/webRequest";

import * as Mustache from "mustache";

import { config } from "utils/Config";

import { IHelpConfig } from "../../../help-config";

import { ArgoSystem } from "components/game/ArgoSystem";
import { Commands } from "components/ui/Commands";
import { TutorialPopUp } from "components/ui/controls/TutorialPopUp";
import { disposeGuiControl, toast } from "components/utils/GUI";
import { GAME_STATE_BID, GAME_STATE_GAME_OVER, GAME_STATE_PLAY, GAME_STATE_ROUND_OVER } from "states/game/GameState";
import { IOptionsState } from "states/game/OptionsState";
import { ROOT_STATE_HOME_SCREEN } from "states/RootState";
import { logger } from "utils/logger";

// tslint:disable-next-line:no-var-requires
let helpModalUrl = require("file-loader?esModule=false!extract-loader!html-loader!components/ui/modal-dialogs/help-modal.html");

// parameters needed to show help
export interface IHelpParams {
  helpKey?: string; // the help item from help-config.ts to show, ie myTurn_bid
  page?: string; // the page to turn to in the main modal help, ie bid
  gameOptions?: IOptionsState; // the game options to show help for. Help will be customized to these
  always?: boolean; // means that help should always be shown immediatly. Otherwise check if help should be shown following rules in help config
  extra?: any; // other values to use when rendering help templates/messages ie {{extra.leadSuit}}
}

export class HelpSystem extends ArgoSystem {
  fileRequest: IFileRequest;
  helpDelayTimeOut: any; // handle to timeout to show help on a delay
  helpParams: IHelpParams;
  lastToastName: string;
  tutorialHistory: string[] = [];  // a list of the previous tutorial message, used for going back
  curTutorialHistory: number;  // the index of the current tutorial help message we're showing in tutorialHistory
  curTutorialHelpKey: string;  // keep track of the current tutorial help message being shown
  tutorialPopUp: TutorialPopUp; // the current tutorialPopUp box being shown
  autoShowTutorial: boolean = true; // automatically show tutorial popup messages, otherwise messages do get set to tutorialPopUp, but doesn't animateOut
  pausedGame: boolean = false; // keep track if we called this.rootState.game.pausePatchQueue();

  init() {
    Commands.onHelpObservable.add((params: IHelpParams) => this.onHelp(params));

    this.rootState.router.addRoute("^\/status$", (patch: any, reversePatch: any, params: any) => this.onRootStatusChanged(patch, reversePatch, params));

    this.rootState.router.addRoute("^\/game\/seatsTurn$", (patch: any, reversePatch: any, params: any) => this.onSeatsTurnChanged(patch, reversePatch, params));
    this.rootState.router.addRoute("^\/game\/status$", (patch: any, reversePatch: any, params: any) => this.onStatusChanged(patch, reversePatch, params));
  }

  /** Called in response to Commands.onHelp */
  onHelp(params: IHelpParams) {
    if(!params || !params.helpKey || params.always)
      this.showHelp(params);
    else
      this.maybeShowHelp(params);
  }

  /** The user is requesting to go back a message in the tutorial/help message */
  onHelpBack() {
    if(this.curTutorialHistory > 0 && this.tutorialPopUp) {
      this.curTutorialHistory--;
      this.updateTutorialMessage();
    }
  }

  /** The user is requesting to go forward a message in the tutorial/help message */
  onHelpForward() {
    if(this.curTutorialHistory < this.tutorialHistory.length - 1 && this.tutorialPopUp) {
      this.curTutorialHistory++;
      this.updateTutorialMessage();
    }
  }

  /** User clicked show tutorial button in game to reshow last tutorial message */
  onHelpShow() {
    this.autoShowTutorial = true; // assume user wants to auto see the rest of tutorial now.
    if(this.tutorialPopUp)
      this.tutorialPopUp.animateIn();
  }

  /** Update the message on the TutorialPopUp and toggle visibility of back, forward and continue */
  updateTutorialMessage() {
    const showBack = this.curTutorialHistory > 0; // only show back if we can go back
    const showForward = this.curTutorialHistory < this.tutorialHistory.length - 1; // only show forward if there is more messages in history to show
    this.tutorialPopUp.setMessage(this.tutorialHistory[this.curTutorialHistory], this.autoShowTutorial, showBack, showForward);
  }

  /** Continue button was clicked in tutorial box.
   *  @param skipRestOfTutorial means the user clicked the skip button and is ending the tutorial. So don't show next help message
   */
  onHelpContinue(skipRestOfTutorial: boolean) {
    if(skipRestOfTutorial)
      this.autoShowTutorial = false;

    if(!this.tutorialPopUp)
      return;

    // If the user went back at all, continue advances them to the last message in the history
    if(this.curTutorialHistory < this.tutorialHistory.length - 1) {
      this.curTutorialHistory = this.tutorialHistory.length - 1;
      this.updateTutorialMessage();
      return;
    }

    // if we paused the game be sure to unpause when the user continues
    if(this.pausedGame) {
      this.pausedGame = false;
      this.rootState.game.unpausePatchQueue();
    }

    // if there is a nextHelp set show it, otherwise animateOut
    let helpConfig: IHelpConfig = config.help[this.curTutorialHelpKey];
    if(helpConfig && helpConfig.nextHelp)
      this.showHelp({ helpKey: helpConfig.nextHelp });
    else
      this.curTutorialHelpKey = null;

    if(!helpConfig || !helpConfig.nextHelp || skipRestOfTutorial)
      this.tutorialPopUp.animateOut(false);
  }

  /** resets the save list of tutorial help messages */
  resetTutorialHistory() {
    this.tutorialHistory = [];
    this.curTutorialHistory = null;
    this.autoShowTutorial = true;

    if(this.pausedGame) {
      this.pausedGame = false;
      this.rootState.game.unpausePatchQueue();
    }
  }

  onRootStatusChanged(patch: any, reversePatch: any, params: any) {
    // reset tutorial history when ever we enter or leave a game.
    this.resetTutorialHistory();
    if(this.tutorialPopUp) {
      this.tutorialPopUp.animateOut(true); // animateOut and dispose
      this.tutorialPopUp = null; // set it null now instead of waiting for dispose, so that if maybeShowHelp below shows new help, the animateOut above won't mistakingly dispose it
    }

    // Show some help about the home screen the first time the user sees it. Ie introduce Quick Play in spades.
    if(patch.value === ROOT_STATE_HOME_SCREEN)
      this.maybeShowHelp({helpKey: "homeScreen"});
  }

  onSeatsTurnChanged(patch: any, reversePatch: any, params: any) {
    // cancel any pending help messages when turn changes.
    clearTimeout(this.helpDelayTimeOut);
  }

  onStatusChanged(patch: any, reversePatch: any, params: any) {
    this.clearToastHelp();
  }

  /** cancels any Toast help messages that are currently shown, or waiting to be shown */
  clearToastHelp() {
    // cancel any pending help messages when status changes.
    clearTimeout(this.helpDelayTimeOut);
    if(this.lastToastName) {
      disposeGuiControl(this.lastToastName);
      this.lastToastName = null;
    }
  }

  /** checks to see if we have help to show for this helpKey, and if the user has already seen it enough times. */
  maybeShowHelp(params: IHelpParams = {}) {
    let helpConfig: IHelpConfig = config.help[params.helpKey];
    if(helpConfig && this.rootState.user.help.shouldShow(params.helpKey)) {
      this.clearToastHelp();
      if(helpConfig.delay)
        this.helpDelayTimeOut = setTimeout(() => { this.showHelp(params); }, helpConfig.delay * 1000);
      else
        this.showHelp(params);
    }
  }

  /** show help specified in params, if it is not provided then show main help for current game mode, ie main_quickPlay */
  showHelp(params: IHelpParams = {}) {
    // default to the page for the current game status they're in, ie if they're in the bid status, go to page on bidding
    let page: string;
    if(this.game.gameState.status === GAME_STATE_BID || this.game.gameState.status === GAME_STATE_PLAY  )
      page = this.game.gameState.status;
    else if(this.game.gameState.status === GAME_STATE_ROUND_OVER || this.game.gameState.status === GAME_STATE_GAME_OVER)
      page = "score";

    this.helpParams = {
      // default params
      helpKey: `main_${this.game.gameState.options.name}`, // default help item if params.helpKey is not set
      page: page,

      // override defaults with those passed in
      ...params,
    };
    const helpKey = this.helpParams.helpKey;

    // get config for helpKey
    let helpConfig: IHelpConfig = config.help[helpKey];
    if(!helpConfig) {
      logger.info("No Help for key", { helpKey });
      return;
    }

    // merge in extra template params from helpConfig
    this.helpParams = {
      ...this.helpParams,
      extra: {
        ...this.helpParams.extra,
        ...helpConfig.extra,
      },
    };

    // if caller didn't pass in gameOptions, then use default from config. The help may be customized with these options
    if(!this.helpParams.gameOptions)
      this.helpParams.gameOptions = helpConfig.gameOptions;

    let message = "";
    if(helpConfig.message) {
      message = Mustache.render(helpConfig.message, this.helpParams);
      this.tutorialHistory.push(message);
      this.curTutorialHistory = this.tutorialHistory.length - 1;
    }

    // show the help in the format specified by type
    if(helpConfig.type === "toast" || helpConfig.type === "toastWithHelp" || helpConfig.type === "toastWithTutorial" || helpConfig.type === "tutorial") {
      if(helpConfig.type === "toastWithHelp")
        toast(helpKey, message, 0, 30, true, "Help", () => { Commands.onHelp(helpConfig.showHelpParams); });
      else if(helpConfig.type === "toastWithTutorial")
        toast(helpKey, message, 0, 30, true, "Teach Me", () => { Commands.onEnableTutorial(true); });
      else if(helpConfig.type === "toast")
        toast(helpKey, message, 0, 30, true, undefined, undefined);
      else if(helpConfig.type === "tutorial") {
        if(!this.tutorialPopUp) {
          this.tutorialPopUp = new TutorialPopUp("TutorialPopUp", () => this.onHelpBack(), () => this.onHelpForward(), (skipRestOfTutorial) => this.onHelpContinue(skipRestOfTutorial), () => this.onHelpShow());
          this.game.guiTexture.addControl(this.tutorialPopUp);
          this.tutorialPopUp.init(); // Init needed after adding to parent
          this.tutorialPopUp.onDisposeObservable.add((popUp) => {
            if(this.tutorialPopUp === popUp)
              this.tutorialPopUp = null;

            // Be sure game is unpaused when tutorialPopUp is disposed
            if(this.pausedGame) {
              this.pausedGame = false;
              this.rootState.game.unpausePatchQueue();
            }
          });
        }
        this.curTutorialHelpKey = this.helpParams.helpKey;
        this.updateTutorialMessage();
        // pauseGame pauses the patch router while the help is up. This is for tutorials so the game doesn't keep going
        // while we're trying to explain something
        if(helpConfig.pauseGame && this.autoShowTutorial && !this.pausedGame && !this.game.debugAutoPlayLocalSeat) {
          this.pausedGame = true;
          this.rootState.game.pausePatchQueue();
        }

        // if the tutorial is currently hidden, then go ahead and recursively show the rest of the help for this helpKey
        // This is so that the help will be in the history if they show tutorial again.
        if(!this.autoShowTutorial && helpConfig.nextHelp) {
          this.showHelp({ helpKey: helpConfig.nextHelp });
        }
      } else {
        throw new Error(`showHelp invalid type: ${helpConfig.type}`);
      }

      this.lastToastName = helpKey;
    }
    else if(helpConfig.type === "modal") {
      // modal downloads html file from url, then shows in a modal box.
      // Cancel any previous request
      this.cancelRequest();

      // Download the help contents
      this.fileRequest = Tools.LoadFile(helpConfig.url, (data: string) => this.onLoaded(data), undefined, undefined, undefined, (request, exception) => this.onLoadError(request, exception));
    }

    // only keep track of seen cnt if the help has a maxViews
    if(helpConfig.maxViews)
      this.rootState.user.help.incSeenCnt(this.rootState.user.id, helpKey);
  }

  cancelRequest() {
    if(this.fileRequest) {
      this.fileRequest.abort();
      this.fileRequest = null;
    }
  }

  onLoaded(data: string) {
    this.fileRequest = null;

    // run it through mustache, used to change to helpParams.page in main help.html
    let html = Mustache.render(data, this.helpParams);

    this.game.modalDialogSystem.showModal(helpModalUrl, {
      title: "Help",
      body: html,
    });
  }

  onLoadError(request: WebRequest, exception: any) {
    this.fileRequest = null;

    this.game.modalDialogSystem.showModal(helpModalUrl, {
      title: "Help",
      body: '<div class="alert alert-danger" role="alert">ERROR</div>',
    });
  }

  resized(): void {
  }
}
