import { Animation } from "@babylonjs/core/Animations/animation";
import { EasingFunction } from "@babylonjs/core/Animations/easing";
import { PowerEase } from "@babylonjs/core/Animations/easing";
import { PointerEventTypes } from "@babylonjs/core/Events/pointerEvents";
import { Vector2 } from "@babylonjs/core/Maths/math";
import { Button } from "@babylonjs/gui/2D/controls/button";
import { Container } from "@babylonjs/gui/2D/controls/container";
import { Control } from "@babylonjs/gui/2D/controls/control";
import { MultiLine } from "@babylonjs/gui/2D/controls/multiLine";
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle";
import { StackPanel } from "@babylonjs/gui/2D/controls/stackPanel";
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock";

import { game } from "components/game/Game";
import { ToastPopUp } from "components/ui/controls/ToastPopUp";
import { logger } from "utils/logger";

// control.zIndex layer definitions
enum zIndex {
  GAME_UI, // This is the default zIndex, players, bid meters, etc., above the game scene, but the bottom of the GUI
  GAME_DIALOG, // Things to appear above the game UI, such as the bid box
  ABOVE_GAME_SPACER, // Things to appear between the game and everything above it, like a "pointer miss" control
  ABOVE_GAME, // Things to appear above all game elements, including dialogs, such as the leaderboard sidebar or user status bar
  MENU_SPACER, // Spacer above everything but menu
  MENU, // Pop up menu, above everything else
  ABOVE_ALL, // Above all game elements, even the MENU
  DEBUG_GUI,
}

/**
 * Find a Babylon GUI control by name, optionally starting at a given parent object
 */
function findGuiControl(name: string, parent: Control | Container = null): Control {
    // It's possible we got called before the guiTexture is even initialized
    if(!game.guiTexture)
      return null;

    // Default to the rootContainer
    if(!parent)
        parent  = game.guiTexture.rootContainer;

    // Control's don't have children
    // We have to check !Container because Containers are Controls
    if(!(parent instanceof Container))
        return null;

    // Cast to Container
    let container = parent as Container;

    // Search the children
    for(let child of container.children) {
        // Found?
        if(child.name === name)
            return child;

        // Recursively search children
        child = findGuiControl(name, child);
        if(child)
            return child;
    }

    return null;
}

/**
 * Find a Babylon GUI control by type, optionally starting at a given parent object, returns a list of controls
 * EX:   let controls = findGuiControlsByType(Button);
 */
function findGuiControlsByType(controlType: typeof Control, parent: Control | Container = null): Control[] {
  let controls: Control[] = [];

  // It's possible we got called before the guiTexture is even initialized
  if(!game.guiTexture)
    return controls;

  // Default to the rootContainer
  if(!parent)
      parent  = game.guiTexture.rootContainer;

  // Control's don't have children
  // We have to check !Container because Containers are Controls
  if(!(parent instanceof Container))
      return controls;

  // Cast to Container
  let container = parent as Container;

  // Search the children
  for(let child of container.children) {
      // Found?
      if(child instanceof controlType)
          controls.push(child);

      // Recursively search children
      let childControls = findGuiControlsByType(controlType, child);
      controls.push(...childControls);
  }

  return controls;
}

/**
 * Dispose a Babylon GUI control by name, optionally starting the search at a given parent object
 */
function disposeGuiControl(name: string, parent: Control | Container = null) {
  let control = findGuiControl(name, parent);
  if(!control)
    return false;

  control.dispose();
  return true;
}

function dumpGuiControlTree(control: Control = null, depth = 0): void {
  // It's possible we got called before the guiTexture is even initialized
  if(!game.guiTexture)
    return;

  if(!control)
    control = game.guiTexture.rootContainer;

  // Log this item
  // tslint:disable-next-line:no-console
  console.log(`${"  ".repeat(depth)} ${control.name}`);

  // Control's don't have children
  // We have to check !Container because Containers are Controls
  if(!(control instanceof Container))
      return;

  let container = control as Container;

  // Search the children
  for(let child of container.children)
    dumpGuiControlTree(child, depth + 1);
}

/**
 * Slide up a temporary message
 * @param displayTime - Set custom time to show message for, 0 is default, -1 is sticky/don't auto hide toast, or allow it to be clicked away.
 * @param buttonLabel - If provided puts a button on the right side of toast
 * @param buttonCB - a callback function to call when the button is clicked
 */
function toast(name: string, message: string, customWidth = 0, displayTime = 0, clickToDispose = true, buttonLabel?: string, buttonCB?: () => any): ToastPopUp {
  // Remove any previous toast
  // Note that it will often not be found because the click that resulted in this toast already dismissed it
  // In this case, replacing a toast of the same name, we actually do want to dispose and not animateOut
  disposeGuiControl(name);
  disposeGuiControl(name + "AnimatingOut");

  // Create the new Toast pop up
  let pop = new ToastPopUp(name, message, customWidth, displayTime, clickToDispose, buttonLabel, buttonCB);
  game.guiTexture.addControl(pop);
  pop.init(); // Init needed after adding to parent
  return pop;
}

function disposeToast(name: string) {
  // todo animate offscreen then dispose
  disposeGuiControl(name);
}

/** Simple pop up menu at mouse position, items is both label and value */
function popUpMenu(name: string, items: string[], onselect: (item: string) => void) {
  /* Future Idea
    Item could be an object {label, value, callback} or a string
    If a string, label=value=string
    If no callback, the (would be optional) passed in callback will be called with the value
  */

  let pointerMissName = name + "PointerMiss";
  disposeGuiControl(name);
  disposeGuiControl(pointerMissName);

  // create a "pointer miss" layer
  // This has to be independent of the menuPopUp so UserStatus and LeaderboardSidebar will interleve properly
  let pointerMiss = new Container(pointerMissName);
  pointerMiss.zIndex = zIndex.MENU_SPACER;
  pointerMiss.isPointerBlocker = true;
  pointerMiss.onPointerDownObservable.add(() => {
    disposeGuiControl(name);
    disposeGuiControl(pointerMissName);
  });
  game.guiTexture.addControl(pointerMiss);

  let guiWidth = game.guiTexture.getSize().width;
  let guiHeight = game.guiTexture.getSize().height;

  let height = 30 * game.getScale();
  let width = height * 10;

  let left = game.scene.pointerX;
  if(left + width > guiWidth)
    left = guiWidth - width;

  let top = game.scene.pointerY;
  if(top + height * items.length > guiHeight)
    top = guiHeight - (height * items.length);

  let menu = new StackPanel(name);
  menu.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
  menu.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
  menu.adaptWidthToChildren = true;
  menu.left = left;
  menu.top = top;
  menu.background = "#287996";
  menu.zIndex = zIndex.MENU;
  game.guiTexture.addControl(menu);

  for(let item of items) {
    let button = Button.CreateSimpleButton(name + item, item);
    button.width = width + "px";
    button.height = height + "px";
    button.fontSize = (height * 0.8) + "px";
    button.color = "white";
    button.onPointerClickObservable.add(() => {
      disposeGuiControl(name);
      disposeGuiControl(pointerMissName);
      onselect(item);
    });
    menu.addControl(button);
  }
}

/**
 * Create a GUI Rectangle centered at x, y
 */
function debugGUIBox(name: string, x: number, y: number, width= 50, height= 50, background = "white") {
  disposeGuiControl(name);

  let r = new Rectangle(name);
  r.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
  r.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
  r.left = x - width * 0.5;
  r.top = y - height * 0.5;
  r.width = width + "px";
  r.height = height + "px";
  r.background = background;
  r.zIndex = zIndex.DEBUG_GUI;
  game.guiTexture.addControl(r);

  return r;
}

/**
 * Create a GUI Line, optionally with an arrow
 */
function debugGUILineEmpty(name: string, color = "white") {
  disposeGuiControl(name);

  let lines = new MultiLine(name);
  lines.color = color;
  lines.zIndex = zIndex.DEBUG_GUI;

  game.guiTexture.addControl(lines);

  return lines;
}

function debugGUILine(name: string, x0: number, y0: number, x1: number, y1: number, color = "white", arrow: number = 0) {
  let lines = debugGUILineEmpty(name, color);

  lines.add({x: x0, y: y0});
  lines.add({x: x1, y: y1});

  if(arrow) {
    // https://stackoverflow.com/questions/10316180/how-to-calculate-the-coordinates-of-a-arrowhead-based-on-the-arrow
    let beg = new Vector2(x0, y0);
    let end = new Vector2(x1, y1);
    let dir = end.subtract(beg).normalize(); // Direction Vector
    let ort = new Vector2(-dir.y, dir.x); // Orthogonal Vector
    let h = arrow * Math.sqrt(3); // 60 degrees
    let w = arrow * 0.5; // Size of arrow (* 0.5 to make a narrower arrow that's less ambiguous than an equilateral triangle)
    let A = end.subtract(dir.scale(h).add(ort.scale(w)));
    let B = end.subtract(dir.scale(h).subtract(ort.scale(w)));
    lines.add({x: A.x, y: A.y});
    lines.add({x: B.x, y: B.y});
    lines.add({x: x1, y: y1});
  }

  return lines;
}

function debugMessageBox(msg: string) {
  if(!game || !game.guiTexture)
    return;

  let r = new Rectangle("debugMessageBox");
  r.background = "white";
  r.isPointerBlocker = true;
  game.guiTexture.addControl(r);

  let t = new TextBlock("debugMessageBoxText");
  t.text = msg;
  r.addControl(t);

  r.onPointerClickObservable.add(() => r.dispose());
}

/**
 * Create a self-destructing text floater
 */
function floatText(name: string, text: string, background: string, color: string, x: number, y: number, width: number, height: number, drop = false ) {
  x -= width * 0.5;
  y -= height * 0.5;

  let r = new Rectangle(name);
  r.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
  r.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
  r.left = x;
  r.top = y;
  r.width = width + "px";
  r.height = height + "px";
  r.background = background;
  r.thickness = 0;
  r.cornerRadius = height * 0.25;
  game.guiTexture.addControl(r);

  let t = new TextBlock(name);
  t.fontSize = (height * 0.8) + "px";
  t.color = color;
  t.text = text;
  r.addControl(t);

  let k0 = 0;
  let k1 = k0 + 30;

  let a = new Animation(name + "FloatAnimation", "top", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
  let keyFrames = [];
  if(drop) {
    keyFrames.push({frame: k0, value: y});
    keyFrames.push({frame: k0 + 5, value: y - height});
    keyFrames.push({frame: k1, value: y + height * 4});
  } else {
    keyFrames.push({frame: k0, value: y});
    keyFrames.push({frame: k1, value: y - height * 4});
  }
  a.setKeys(keyFrames);

  let easingFunction = new PowerEase();
  easingFunction.setEasingMode(EasingFunction.EASINGMODE_EASEIN);
  a.setEasingFunction(easingFunction);

  let f = new Animation(name + "FloatFadeAnimation", "alpha", 30, Animation.ANIMATIONTYPE_FLOAT, Animation.ANIMATIONLOOPMODE_CONSTANT);
  let fkeys = [];
  fkeys.push({frame: k0, value: 1.0 });
  fkeys.push({frame: k1, value: 0.0 });
  f.setKeys(fkeys);

  game.scene.beginDirectAnimation(r, [a, f], 0, k1, false, 1.0, () => {
    r.dispose();
  });
}

function getControlGlobalPosition(control: Control) {
  // Babylon JS gives us global -> local, but not local -> global
  // So we'll get global (0, 0) -> local and use that as an offset to get global coordinates
  // This returns the negative offsets to move the top left corner of the bidMeter to global (0, 0)
  let localCoordinatesForZero = control.getLocalCoordinates(new Vector2(0, 0));

  let x = localCoordinatesForZero.x ? -localCoordinatesForZero.x : 0;
  let y = localCoordinatesForZero.y ? -localCoordinatesForZero.y : 0;

  return new Vector2(x, y);
}

/** prompts the user to reload/refresh the browser. Used after an error occurs that might be fixed by a reload. */
function promptReload(msg = "Something went wrong, try Reloading.") {
  game.modalDialogSystem.showOkCancel("Uh oh", msg, "Reload", "Continue").then((response: any) => {
    if(response.button === "ok")
      logger.safeReloadBrowser();
  });
}

export { findGuiControl, findGuiControlsByType, disposeGuiControl, dumpGuiControlTree, toast, disposeToast, popUpMenu, debugGUIBox, debugGUILine, debugGUILineEmpty, debugMessageBox, floatText, getControlGlobalPosition, promptReload, zIndex };
