import { config, mergeConfig } from "utils/Config";
import "../../argo.config"; // merges root argo.config.ts
import "../../src/components/game/avatars/argo.config";
import "../../src/components/game/sounds/cards/argo.config";
import "../../src/components/game/sounds/gui/argo.config";
import "../../src/components/game/sounds/music/argo.config";
import "../../src/components/game/sounds/reactions/argo.config";
import "../../src/components/game/sounds/trick-game/argo.config";
import "./catalog-config"; // merge in item catalog
import "./game-config"; // merge in gameOptions, rules
import "./help-config"; // merge in help

import { types } from "mobx-state-tree";

import { Spades } from "./src/Spades";
import { SpadesGameState } from "./src/SpadesGameState";

import { Commands } from "components/ui/Commands";
import { IHomeScreenButton } from "components/ui/HomeScreenSystem";

import trickGameDeckUrl from "components/game/decks/trick-game.png";
import offerLifePopUpBackgroundUrl from "components/ui/ad-system/ads/offer-life-pop-up/offer-life-heart-background.png";

// home screen cards
import classicTextureUrl from "components/ui/home-screen/classic.png";
import quickPlayTextureUrl from "components/ui/home-screen/quick_play.png";

mergeConfig({
  longName: "Insta Spades",
  shortName: "Spades",

  gameClass: Spades,
  rootStates: {
    game: types.optional(SpadesGameState, {}),
  },

  defaultDeckUrl: trickGameDeckUrl,

  // Camera Position
  cameraTarget: [0, 0, 0], // Smaller values move the game up on the screen
  cameraAngle: 40.0,
  guiTransformNodeDistance: -2.2, // The distance (usually negative) from the origin towards the camera to place the guiRootTransformNode so that it does not intersect the game

  paddingTop: 0.0,
  paddingBottom: -0.65,
  paddingLeft: -0.5,
  paddingRight: -0.5,

  // Piles
  pileHasMarkerTexture: false,

  autoRunTutorial: true,
  haveStateSync: false,
  offlineSingleplayer: true, // go offline if game only has 1 player in it.
  onClickPreventPlayUntilTurnChanges: true,

  showTeamsOnUserStatus: true, // Show Team Names, Scores and Bags on the User Status bar at the top of the screen.

  // Menu Colors
  menuTextColor: "white",
  menuBackgroundColor: "#3609cd",
  menuCheckboxCheckedColor: "#09a1cd",

  // Pop Up Button colors (stand alone center screen buttons, like Start Game and Pass)
  // The text color is also the outline color
  popUpButtonTextColor: "white",
  popUpButtonBackgroundColor: "#3609cd",
  popUpButtonUnderneathTextColor: "white",

  // Screen Footer Buttons
  screenFooterButtonTextColor: "white",
  screenFooterButtonBackgroundColor: "#01003a",

  offerLifePopUpBackgroundUrl,

  // Home Screen Buttons
  homeScreenButtons: [
    /*{
      name: "quickPlay",
      imageUrl: quickPlayTextureUrl,
      onClick: () => { Commands.onNewGame( {options: { ...config.gameOptions.quickPlay }}); },
    },*/
    {
      name: "classic",
      imageUrl: classicTextureUrl,
      onClick: () => { Commands.onNewGame( {options: { ...config.gameOptions.classic }}); },
    },
  ] as IHomeScreenButton[],
});

if(process.env.NODE_ENV === "production") {
  mergeConfig( {
    // Socket Cluster
    scHost: "spades-api.instagames.gg",  // used to be fb.instaspades.com
  });
}
