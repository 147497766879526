import { mergeConfig } from "utils/Config";
import "../argo.config"; // merges freecell/argo.config.ts

import { GameAnalyticsCom } from "components/utils/GameAnalyticsCom";

import { init } from "utils/init";

mergeConfig({
});

if(process.env.NODE_ENV === "production") {
  mergeConfig({
    // create GameAnalyticsCom with GameKey and SecretKey
    analytics: new GameAnalyticsCom("c61db8cd51ffe7bb921fe420d48e69fa", "62f6604c06aa2c7c126477fdc40a506ed0a0fa4f"),
  });
}

init();
