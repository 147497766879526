import { IStartup } from "components/game/Game";
import { IShareObject, ShareSystem } from "components/ui/ShareSystem";
import { loadImageAsync, loadImageAsyncWithFallback } from "components/utils/Image";
import { spadesPieceValueCompare } from "components/utils/PieceSortFunctions";
import { ITrickGameState } from "states/game/TrickGameState";
import { config } from "utils/Config";

import pawn0AvatarUrl from "components/game/avatars/pawn0.png";
import shareBidBackgroundUrl from "components/ui/share/facebook-share-bid.jpg";
import shareBackgroundUrl from "components/ui/share/share-background.jpg";

/** Create Spades specific share objects */
export class SpadesShareSystem extends ShareSystem {
  _makeShareObject(shareName: string): Promise<IShareObject> {
    switch(shareName) {
      case "bid":
        return this.makeShareBidObject();
      case "bid-alt":
        return this.makeShareBidAltObject();
      default:
        return super._makeShareObject(shareName);
    }
  }

  private async makeShareBidObject(): Promise<IShareObject> {
    const AVATAR_WIDTH = 240;

    const TEXT_X = 275;
    const TEXT_Y = 70;
    const TEXT_LINE_HEIGHT = 22;
    const TEXT_COLOR = "#ffffff";

    let imageURL = this.game.rootState.user.imageUrl || pawn0AvatarUrl;

    if(this.game.localSeat === null)
      return null;

    let localSeat = this.game.gameState.getSeat(this.game.localSeat);
    if(localSeat.bid === null)
      return null;

    let bid = "" + localSeat.bid;
    if(bid === "0")
      bid = "Nil";

    // get the hands from the current game and setup an IGameStart for a Hand Challenge
    let trickGameState = this.game.gameState as ITrickGameState;
    let minPiles = trickGameState.getMinPiles();
    const startupData: IStartup = {
      srcType: "share",
      srcName: "hand_1",
      startupAction: "start-game",
      gameStart: {
        options: { name: config.gameOptions.handChallenge.name },
        minPiles: minPiles,
        seatId: localSeat.id,
        dealerSeatId: trickGameState.dealer.id,
      },
    };

    // get cards for share image from minPiles
    let cards = minPiles[`h${localSeat.id}`].p;
    cards.sort(spadesPieceValueCompare);

    let avatarImage = await loadImageAsyncWithFallback(imageURL, pawn0AvatarUrl);
    if(!avatarImage)
      return null;

    let backgroundImage = await loadImageAsync(shareBackgroundUrl);
    if(!backgroundImage)
      return null;

    let context = this.getCanvasContext();

    // Background
    context.drawImage(backgroundImage, 0, 0);

    // Avatar
    context.drawImage(avatarImage, 0, 0, AVATAR_WIDTH, AVATAR_WIDTH);

    // Hand of Cards
    await this.drawHand(context, cards);

    // Text
    context.save();
    context.font = `${config.fontWeight} ${TEXT_LINE_HEIGHT}px ${config.fontFamily}`;
    context.fillStyle = TEXT_COLOR;
    context.fillText(`I Bid ${bid}.`, TEXT_X, TEXT_Y);
    context.fillText("What would you have bid?", TEXT_X, TEXT_Y + TEXT_LINE_HEIGHT * 1);
    context.fillText("Click to play this hand.", TEXT_X, TEXT_Y + TEXT_LINE_HEIGHT * 3);
    context.restore();

    // Get the canvas result into a png
    let image = this.contextToDataURL(context, "bid");

    if(image === null)
      return null;

    return {
      image,
      text: "What would you have bid? Click to play this hand.",
      data: startupData,
    };
  }

  private async makeShareBidAltObject(): Promise<IShareObject> {
    const AVATAR_X = 290;
    const AVATAR_Y = 51;
    const AVATAR_WIDTH = 95;

    const TEXT_X = 290;
    const TEXT_Y = 105;
    const TEXT_LINE_HEIGHT = 22;
    const TEXT_COLOR = "#ffffff";

    let imageURL = this.game.rootState.user.imageUrl || pawn0AvatarUrl;

    if(this.game.localSeat === null)
      return null;

    let localSeat = this.game.gameState.getSeat(this.game.localSeat);
    if(localSeat.bid === null)
      return null;

    let bid = localSeat.bid;

    // get the hands from the current game and setup an IGameStart for a Hand Challenge
    let trickGameState = this.game.gameState as ITrickGameState;
    let minPiles = trickGameState.getMinPiles();
    const startupData: IStartup = {
      srcType: "share",
      srcName: "hand_2",
      startupAction: "start-game",
      gameStart: {
        options: { name: config.gameOptions.handChallenge.name },
        minPiles: minPiles,
        seatId: localSeat.id,
        dealerSeatId: trickGameState.dealer.id,
      },
    };

    // get cards for share image from minPiles
    let cards = minPiles[`h${localSeat.id}`].p;
    cards.sort(spadesPieceValueCompare);

    let avatarImage = await loadImageAsyncWithFallback(imageURL, pawn0AvatarUrl);
    if(!avatarImage)
      return null;

    let backgroundImage = await loadImageAsync(shareBidBackgroundUrl);
    if(!backgroundImage)
      return null;

    let context = this.getCanvasContext();

    // Background
    context.drawImage(backgroundImage, 0, 0);

    // Draw Avatar in a circle
    context.save();
    context.beginPath();
    context.arc(AVATAR_X, AVATAR_Y, AVATAR_WIDTH * 0.5, 0, Math.PI * 2, true);
    context.clip();
    context.drawImage(avatarImage, AVATAR_X - AVATAR_WIDTH * 0.5, AVATAR_Y - AVATAR_WIDTH * 0.5, AVATAR_WIDTH, AVATAR_WIDTH);
    context.stroke();
    context.restore();

    // Hand of Cards
    await this.drawHand(context, cards);

    // Text
    context.save();
    context.font = `${config.fontWeight} ${TEXT_LINE_HEIGHT}px ${config.fontFamily}`;
    context.fillStyle = TEXT_COLOR;
    context.textAlign = "center";
    context.fillText("Check out this Spades hand!", TEXT_X, TEXT_Y + TEXT_LINE_HEIGHT * 1);
    context.fillText("How would you bid it?", TEXT_X, TEXT_Y + TEXT_LINE_HEIGHT * 2);
    context.restore();

    // Get the canvas result into a png
    let image = this.contextToDataURL(context, "bid-alt");

    if(image === null)
      return null;

    return {
      image,
      text: "Check out this Spades hand! How would you bid it?",
      data: startupData,
    };
  }
}
